import React, { useContext, useMemo } from 'react';
import {
	Box,
	makeStyles,
	Tab,
	Tabs,
	ThemeProvider,
	Grid,
} from '@material-ui/core';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAbility } from '@casl/react';
import { getColoredTheme } from '../../styles/palettes';

// Components
import ScreenTitleComponent from '../../components/ScreenTitleComponent/ScreenTitleComponent';
import ActionCardComponent, {
	ActionCardProps,
} from '../../components/ActionCardComponent';
import FallbackComponent from '../../components/FallbackComponent';

// Views / Layouts
import YoungPeopleView from '../../views/YoungPeopleView';
import ActivityLogView from '../../views/ActivityLogView';
import RoomsView from '../../views/RoomsView';
import CreateCoachingSessionView from '../../views/CreateCoachingSessionView';
import CreateSpinSessionView from '../../views/CreateSpinSessionView';
import FilesLayout from '../../layouts/FilesLayoutLayout';
import TasksTabLayout from '../../layouts/TasksTabLayout';
import WhereaboutServiceDashboardView from '../../views/WhereaboutServiceDashboardView';
import AlertView from '../../views/AlertView';

// Context
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { FormType } from '../../graphql/types';
import { Can, AbilityContext } from '../../contexts/CanContext';

// Query
import { GetHouseQueryHookResult } from './query.generated';
import HouseProfileView from '../../views/HouseDashboardView';
import { OrderByArg } from '../../graphql/types';

// Utils
import theme from '../../styles/theme';
import AuthContext from '../../contexts/authContext';

export type HouseScreenProps = {
	data: GetHouseQueryHookResult['data'];
	loading: GetHouseQueryHookResult['loading'];
	error: GetHouseQueryHookResult['error'];
	tab: number;
	houseId: string;
	onChangeTab: (event: React.ChangeEvent<{}>, newValue: number) => void;
};

const useStyles = makeStyles((theme) => ({
	wrapper: {
		paddingLeft: theme.spacing('xl3'),
		paddingRight: theme.spacing('xl3'),
	},
	tabs: {
		paddingBottom: theme.spacing('xl2'),
	},
	topWrapper: {
		display: 'flex',
		gap: theme.spacing('md'),
		'@media screen and (max-width: 960px)': {
			flexDirection: 'column',
		},
	},
	actionsWrapper: {
		width: '50%',
		'@media screen and (max-width: 960px)': {
			width: '100%',
			order: 2,
		},
	},
	whereaboutsWrapper: {
		width: '50%',
		'@media screen and (max-width: 960px)': {
			width: '100%',
			order: 1,
		},
	},
}));

const HouseScreen: React.FC<HouseScreenProps> = (props) => {
	const classes = useStyles();
	const { open } = useContext(ModalContext);
	const ability = useAbility(AbilityContext);
	const { user } = useContext(AuthContext);

	let { path } = useRouteMatch();

	const house = props.data?.getHouse;

	const houseId = house?.id;
	const houseTheme = getColoredTheme(house?.color ?? undefined);

	const actions: ActionCardProps[] = useMemo(() => {
		if (props.data?.getHouse?.isMoveOutHouse) {
			return [
				{
					actionLabel: 'General Note',
					variant: 'select',
					onClick: () => {
						open({
							type: ModalType.FORM,
							args: { type: FormType.LogNote, houseId },
						});
					},
				},
			];
		}

		const options: ActionCardProps[] = [
			{
				actionLabel: 'Kid Recording',
				variant: 'select',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.LogActivity, houseId: props.houseId },
					});
				},
			},
			{
				actionLabel: 'SPIN Session',
				variant: 'select',
				onClick: () => {
					open({
						type: ModalType.JSX,
						content: <CreateSpinSessionView houseId={props.houseId} />,
					});
				},
			},
			{
				actionLabel: 'Coaching Session',
				variant: 'select',
				onClick: () => {
					open({
						type: ModalType.JSX,
						content: <CreateCoachingSessionView houseId={props.houseId} />,
					});
				},
			},
			{
				actionLabel: 'Record Incident',
				variant: 'select',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.LogIncident, houseId: props.houseId },
					});
				},
			},
			{
				actionLabel: 'General Note',
				variant: 'select',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.LogNote, houseId: props.houseId },
					});
				},
			},
			{
				actionLabel: 'Kid Payment',
				variant: 'select',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.LogKidPayment, houseId: props.houseId },
					});
				},
			},
			{
				actionLabel: 'Professional Contact',
				variant: 'select',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.LogContact, houseId: props.houseId },
					});
				},
			},
		];

		if (ability.can('create', 'ManagerOnDutyLog')) {
			options.push({
				actionLabel: 'Person on Call Log',
				variant: 'select',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.LogManagerOnDuty, houseId: props.houseId },
					});
				},
			});
		}

		return options;
	}, [props.data?.getHouse?.isMoveOutHouse, user]);

	return (
		<Box minHeight="77.5vh" paddingBottom="48px">
			<FallbackComponent
				hasError={Boolean(props.error)}
				errorTitle="An Error Occurred"
				errorCopy="We had a little trouble fetching the house. If the problem persists, please contact your system administrator."
				isLoading={Boolean(props.loading)}
			>
				{house && (
					<Can I="read" this={house}>
						<ThemeProvider theme={houseTheme}>
							<Box display="grid" gridRowGap={theme.spacing('xl')}>
								<ScreenTitleComponent
									title={house.title ?? 'Unknown House'}
									subTitle="House"
								/>
								<Box className={classes.topWrapper}>
									<Box className={classes.actionsWrapper}>
										<Grid container spacing={2}>
											<AlertView houseId={houseId ?? ''} />
										</Grid>
									</Box>
									{props.data?.getHouse?.isMoveOutHouse ? null : (
										<Box className={classes.whereaboutsWrapper}>
											<WhereaboutServiceDashboardView />
										</Box>
									)}
								</Box>
								<Box>
									<Grid container spacing={2}>
										{actions.map((action) => {
											return (
												<Grid item sm={6} md={3}>
													<ActionCardComponent {...action} />
												</Grid>
											);
										})}
									</Grid>
								</Box>
								<Box>
									<Tabs
										className={classes.tabs}
										value={props.tab}
										indicatorColor="primary"
										textColor="primary"
										onChange={props.onChangeTab}
										aria-label="house tabs"
									>
										<Tab label="Dashboard" />
										<Tab label="Activity" />
										<Tab label="Tasks" />
										<Tab label="Kids" />
										<Tab label="Rooms" />
										<Tab label="Files" />
									</Tabs>
									<Switch>
										<Route exact path={`${path}/dashboard`}>
											<HouseProfileView />
										</Route>
										<Route exact path={`${path}/activity`}>
											<ActivityLogView houseId={houseId} />
										</Route>
										<Route exact path={`${path}/tasks`}>
											{houseId && <TasksTabLayout houseId={houseId} />}
										</Route>
										<Route exact path={`${path}/kids`}>
											<YoungPeopleView sortBy={OrderByArg.Asc} />
										</Route>
										<Route exact path={`${path}/rooms`}>
											<RoomsView />
										</Route>
										<Route exact path={`${path}/files`}>
											{houseId && <FilesLayout hideFolders houseId={houseId} />}
										</Route>
										<Redirect to={`${path}/dashboard`} />
									</Switch>
								</Box>
							</Box>
						</ThemeProvider>
					</Can>
				)}
			</FallbackComponent>
		</Box>
	);
};

export default HouseScreen;
