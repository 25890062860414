import { GetYoungPersonQueryHookResult } from '../query.generated';
import { Detail } from '../../../components/DetailsCardComponent';
import { AboutMeData } from '../../../types';

const AboutMeDetailsCard = (args: GetYoungPersonQueryHookResult['data']) => {
	const data: AboutMeData | undefined = JSON.parse(
		args?.getYoungPerson?.aboutMe
	);

	const aboutMeDetails: Detail[] = [
		{
			id: 'triggers',
			title: 'My Triggers',
			copy: data?.triggers ?? '-',
		},
		{
			id: 'improveMood',
			title: 'Improves My Mood',
			copy: data?.improveMood ?? '-',
		},
		{
			id: 'needSupportWith',
			title: 'Support Needed',
			copy: data?.needSupportWith ?? '-',
		},
		{
			id: 'likes',
			title: 'Likes',
			copy: data?.likes ?? '-',
		},
		{
			id: 'dislikes',
			title: 'Dislikes',
			copy: data?.dislikes ?? '-',
		},
	];

	return aboutMeDetails;
};

export default AboutMeDetailsCard;
