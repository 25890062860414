import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { appConfig } from '../../aws-exports';
import { AvatarUserType } from '../../graphql/types';

import { useGetUploadUrlMutation } from './query.generated';

/**
 * A React hook for uploading avatars for a young person, or a user.
 * @property {number} uploadProgress - The progress of the upload. Defaults to 0.
 * @property getUploadUrl - The upload function
 */
const useAvatar = () => {
	const [uploadProgress, setUploadProgress] = useState(0);
	const [fetchUrl] = useGetUploadUrlMutation();
	const { enqueueSnackbar } = useSnackbar();

	const getAvatarURL = (
		key: string | null | undefined
	): string | null | undefined => {
		if (!key) {
			return null;
		}

		const url = `https://${appConfig.avatar_url}/${key}`;
		return url;
	};

	const getUploadUrl = async (
		userId: string,
		file: File,
		userType: AvatarUserType
	): Promise<string | null | undefined> => {
		if (!file.type) {
			throw new Error('No file type found');
		}

		const { data } = await fetchUrl({
			variables: { userId: userId, fileType: file.type, userType: userType },
		});
		const url = data?.createUploadAvatarUrl?.url;
		return url;
	};

	/**
	 * Handles getting the upload url and then uploading the url
	 * @desc
	 * @param {string} userId - This can be any userId including a youngPerson id.
	 * @param {File} file - This is the image file.
	 */
	const upload = async (
		userId: string,
		file: File,
		userType: AvatarUserType
	) => {
		try {
			const url = await getUploadUrl(userId, file, userType);

			if (!url) {
				throw new Error('No URL found');
			}

			const res = await axios.put(url, file, {
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);

					setUploadProgress(percentCompleted);
				},
				headers: {
					'Content-Type': file.type,
				},
			});
			if (res.status === 200) {
				enqueueSnackbar('File uploaded successfully', {
					variant: 'success',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			}
		} catch (error) {
			enqueueSnackbar('Sorry something went wrong', {
				variant: 'error',
				anchorOrigin: { vertical: 'top', horizontal: 'right' },
			});
		}
	};

	return {
		upload,
		uploadProgress,
		getUploadUrl,
		getAvatarURL,
	};
};

export default useAvatar;
