import React, { useContext } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTitle } from 'react-use';

// Components
import ScreenTitleComponent from '../../components/ScreenTitleComponent';
import List from '../../components/ListComponent';
import ActionCardComponent from '../../components/ActionCardComponent';

// Views
import TasksView from '../../views/TasksView';
import AlertView from '../../views/AlertView';
import ActivityLogView from '../../views/ActivityLogView';
import CreateCoachingSessionView from '../../views/CreateCoachingSessionView';
import CreateSpinSessionView from '../../views/CreateSpinSessionView';

// Contexts
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { FormType, OrderByArg } from '../../graphql/types';

export type AdminDashboardScreenProps = {};

const AdminDashboardScreen: React.FC<AdminDashboardScreenProps> = (props) => {
	useTitle('STELLA | Dashboard');
	const theme = useTheme();
	const { open } = useContext(ModalContext);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box
			display="flex"
			flexDirection="column"
			width="100%"
			gridRowGap={theme.spacing('xl')}
		>
			<ScreenTitleComponent title="STELLA" subTitle="Your Dashboard" />
			<List size="md">
				<ActionCardComponent
					actionLabel="Kid Recording"
					variant="select"
					onClick={() => {
						open({
							type: ModalType.FORM,
							args: {
								type: FormType.LogActivity,
							},
						});
					}}
				/>
				<ActionCardComponent
					actionLabel="SPIN Session"
					variant="select"
					onClick={() => {
						open({
							type: ModalType.JSX,
							content: <CreateSpinSessionView />,
						});
					}}
				/>
				<ActionCardComponent
					actionLabel="Coaching Session"
					variant="select"
					onClick={() => {
						open({
							type: ModalType.JSX,
							content: <CreateCoachingSessionView />,
						});
					}}
				/>
				<ActionCardComponent
					actionLabel="Record Incident"
					variant="select"
					onClick={() => {
						open({
							type: ModalType.FORM,
							args: {
								type: FormType.LogIncident,
							},
						});
					}}
				/>
				<ActionCardComponent
					actionLabel="General Note"
					variant="select"
					onClick={() => {
						open({
							type: ModalType.FORM,
							args: {
								type: FormType.LogNote,
							},
						});
					}}
				/>
				<ActionCardComponent
					actionLabel="Kid Payment"
					variant="select"
					onClick={() => {
						open({
							type: ModalType.FORM,
							args: {
								type: FormType.LogKidPayment,
							},
						});
					}}
				/>
			</List>
			{/* Style props here stop the grid blowing out of the container, causing incorrect
      spacing between column items on screen. V5 allows you to configure the column only
      which would avoid this issue */}
			<Grid
				container
				spacing={isMobile ? 0 : 10}
				style={{
					width: '100%',
					marginLeft: 0,
					marginTop: 0,
					marginBottom: 0,
				}}
			>
				<Grid
					item
					xs={12}
					md={6}
					style={{
						paddingTop: 0,
						paddingLeft: 0,
						paddingBottom: isMobile ? '3rem' : undefined,
					}}
				>
					<AlertView />
				</Grid>
				<Grid item xs={12} md={6} style={{ paddingTop: 0, paddingRight: 0 }}>
					<TasksView
						filter={{ completedAt: { equals: null } }}
						sort={{ dueDate: OrderByArg.Desc, createdAt: OrderByArg.Desc }}
						limit={10}
					/>
				</Grid>
			</Grid>
			<ActivityLogView />
		</Box>
	);
};

export default AdminDashboardScreen;
