import { useAbility } from '@casl/react';
import {
	AppBar,
	Box,
	createStyles,
	makeStyles,
	Theme,
	useMediaQuery,
	useTheme,
} from '@material-ui/core';
import {
	Dashboard,
	Face,
	LocationCityOutlined,
	PeopleAltOutlined,
	Settings,
	Equalizer,
} from '@material-ui/icons';
import React, { useContext } from 'react';

import Logo from '../../assets/fabric-square-logo.png';
import AuthContext from '../../contexts/authContext';
import { AbilityContext } from '../../contexts/CanContext';
import AccountPopupView from '../../views/AccountPopupView';
import HouseSelectorView from '../../views/HouseSelectorView';
import NavigationIcon, { NavigationIconProps } from './NavigationIcon';
import NavNotificationView from './NavNotificationView';

// Components
// Views
// Images
export type NavigationViewProps = {};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			'@media print': {
				display: 'none',
			},
			paddingTop: theme.spacing('xs'),
			paddingBottom: theme.spacing('xs'),
			paddingLeft: theme.spacing('lg'),
			paddingRight: theme.spacing('lg'),
			backgroundColor: `${
				process.env.REACT_APP_ENVIRONMENT === 'dev'
					? 'rgb(255, 244, 229)'
					: theme.palette.background.paper
			}`,
			boxShadow: theme.shadows[2],
			position: 'relative',
			'@media screen and (max-width: 960px)': {
				display: 'flex',
				flexDirection: 'column',
				padding: theme.spacing('xs'),
			},
		},
		wrapper: {
			'@media screen and (max-width: 960px)': {
				display: 'flex',
				flexDirection: 'column',
			},
		},
		logo: {
			height: '45px',
			borderRadius: '5px',
			boxShadow: theme.shadows[3],
		},
		homeLink: {
			display: 'flex',
			alignItems: 'center',
			gap: theme.spacing('xs'),
		},
		icon: {
			color: theme.palette.grey[700],
		},
	})
);

const NavigationView: React.FC<NavigationViewProps> = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const { user, cognitoUser } = useContext(AuthContext);
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
	const ability = useAbility(AbilityContext);

	// TODO: Based on the usergroup change the links available to them
	let links: NavigationIconProps[] = [
		{
			location: '/',
			icon: Dashboard,
			tooltip: 'Main Dashboard',
		},
	];
	const isAdmin = user?.role === 'ADMIN';
	console.log('🚀 ~ file: NavigationView.tsx ~ line 92 ~ user', user);

	const housesOnUser =
		cognitoUser?.attributes?.['custom:houseId']?.split('|||');

	const canSwitchHouse =
		user?.role === 'ADMIN' ||
		user?.role === 'HOUSE_MANAGER' ||
		(housesOnUser?.length ?? 0) > 1;

	if (isAdmin) {
		links = [
			{
				location: '/admin',
				icon: Dashboard,
				tooltip: 'Main Dashboard',
			},
			{
				location: '/admin/homes',
				icon: LocationCityOutlined,
				tooltip: 'Homes',
			},
			{
				location: '/admin/users',
				icon: PeopleAltOutlined,
				tooltip: 'Users',
			},
			{
				location: '/admin/kids',
				icon: Face,
				tooltip: 'Kids',
			},
			{
				location: '/admin/reports',
				icon: Equalizer,
				tooltip: 'Reports',
			},
			{
				location: '/admin/settings',
				icon: Settings,
				tooltip: 'Settings',
			},
		];
	}
	return (
		<AppBar
			position="static"
			elevation={2}
			component="nav"
			className={classes.root}
		>
			<Box
				display="grid"
				gridTemplateColumns={isMobile ? '100%' : '1fr auto 1fr'}
				gridRowGap={'1rem'}
				maxWidth={'100%'}
				alignItems="center"
				className={classes.wrapper}
			>
				<Box display="flex" alignItems="center" paddingRight="1rem">
					<a className={classes.homeLink} href="/">
						<img className={classes.logo} src={Logo} alt="Fabric logo" />
						{process.env.REACT_APP_ENVIRONMENT === 'dev' ? (
							<h1>Staging</h1>
						) : null}
					</a>
					<ul>
						{links.map((link) => (
							<NavigationIcon {...link} />
						))}
					</ul>
				</Box>
				<Box width="min-content" justifySelf={'flex-end'}>
					{canSwitchHouse && <HouseSelectorView />}
				</Box>
				<Box display="flex" justifyContent="flex-end" alignItems="center">
					<NavNotificationView />
					<AccountPopupView />
				</Box>
			</Box>
		</AppBar>
	);
};

export default NavigationView;
