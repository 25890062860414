import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ListMyNotificationsQueryVariables = Types.Exact<{
	limit?: Types.Maybe<Types.Scalars['Int']>;
	filter?: Types.Maybe<Types.NotificationWhereFilterInput>;
}>;

export type ListMyNotificationsQuery = {
	__typename?: 'Query';
	listNotifications?:
		| Array<
				| {
						__typename?: 'Notification';
						id: string;
						title: string;
						createdAt?: any | null | undefined;
						readAt?: any | null | undefined;
						houseId?: string | null | undefined;
						youngPersonId?: string | null | undefined;
						taskId?: string | null | undefined;
						userId?: string | null | undefined;
						task?:
							| {
									__typename?: 'Task';
									id: string;
									houseId?: string | null | undefined;
									youngPersonId?: string | null | undefined;
									title: string;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type NotifyMeSubscriptionVariables = Types.Exact<{
	[key: string]: never;
}>;

export type NotifyMeSubscription = {
	__typename?: 'Subscription';
	onCreatedManyNotifications?:
		| { __typename?: 'BatchPayload'; count?: number | null | undefined }
		| null
		| undefined;
};

export type MarkAsReadMutationVariables = Types.Exact<{
	readAt: Types.Scalars['AWSDateTime'];
	id: Types.Scalars['String'];
}>;

export type MarkAsReadMutation = {
	__typename?: 'Mutation';
	updateNotification?:
		| {
				__typename?: 'Notification';
				id: string;
				readAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type MarkAllAsReadMutationVariables = Types.Exact<{
	readAt: Types.Scalars['AWSDateTime'];
	ids: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;

export type MarkAllAsReadMutation = {
	__typename?: 'Mutation';
	updateManyNotifications?:
		| { __typename?: 'BatchPayload'; count?: number | null | undefined }
		| null
		| undefined;
};

export const ListMyNotificationsDocument = gql`
	query ListMyNotifications(
		$limit: Int
		$filter: NotificationWhereFilterInput
	) {
		listNotifications(
			take: $limit
			where: $filter
			orderBy: { createdAt: DESC }
		) {
			id
			title
			createdAt
			readAt
			houseId
			youngPersonId
			task {
				id
				houseId
				youngPersonId
				title
			}
			taskId
			userId
		}
	}
`;

/**
 * __useListMyNotificationsQuery__
 *
 * To run a query within a React component, call `useListMyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListMyNotificationsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListMyNotificationsQuery,
		ListMyNotificationsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		ListMyNotificationsQuery,
		ListMyNotificationsQueryVariables
	>(ListMyNotificationsDocument, options);
}
export function useListMyNotificationsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListMyNotificationsQuery,
		ListMyNotificationsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ListMyNotificationsQuery,
		ListMyNotificationsQueryVariables
	>(ListMyNotificationsDocument, options);
}
export type ListMyNotificationsQueryHookResult = ReturnType<
	typeof useListMyNotificationsQuery
>;
export type ListMyNotificationsLazyQueryHookResult = ReturnType<
	typeof useListMyNotificationsLazyQuery
>;
export type ListMyNotificationsQueryResult = Apollo.QueryResult<
	ListMyNotificationsQuery,
	ListMyNotificationsQueryVariables
>;
export const NotifyMeDocument = gql`
	subscription NotifyMe {
		onCreatedManyNotifications {
			count
		}
	}
`;

/**
 * __useNotifyMeSubscription__
 *
 * To run a query within a React component, call `useNotifyMeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotifyMeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifyMeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotifyMeSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		NotifyMeSubscription,
		NotifyMeSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		NotifyMeSubscription,
		NotifyMeSubscriptionVariables
	>(NotifyMeDocument, options);
}
export type NotifyMeSubscriptionHookResult = ReturnType<
	typeof useNotifyMeSubscription
>;
export type NotifyMeSubscriptionResult =
	Apollo.SubscriptionResult<NotifyMeSubscription>;
export const MarkAsReadDocument = gql`
	mutation MarkAsRead($readAt: AWSDateTime!, $id: String!) {
		updateNotification(where: { id: $id }, data: { readAt: $readAt }) {
			id
			readAt
		}
	}
`;
export type MarkAsReadMutationFn = Apollo.MutationFunction<
	MarkAsReadMutation,
	MarkAsReadMutationVariables
>;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      readAt: // value for 'readAt'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkAsReadMutation(
	baseOptions?: Apollo.MutationHookOptions<
		MarkAsReadMutation,
		MarkAsReadMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<MarkAsReadMutation, MarkAsReadMutationVariables>(
		MarkAsReadDocument,
		options
	);
}
export type MarkAsReadMutationHookResult = ReturnType<
	typeof useMarkAsReadMutation
>;
export type MarkAsReadMutationResult =
	Apollo.MutationResult<MarkAsReadMutation>;
export type MarkAsReadMutationOptions = Apollo.BaseMutationOptions<
	MarkAsReadMutation,
	MarkAsReadMutationVariables
>;
export const MarkAllAsReadDocument = gql`
	mutation MarkAllAsRead($readAt: AWSDateTime!, $ids: [String!]!) {
		updateManyNotifications(
			where: { id: { in: $ids } }
			data: { readAt: $readAt }
		) {
			count
		}
	}
`;
export type MarkAllAsReadMutationFn = Apollo.MutationFunction<
	MarkAllAsReadMutation,
	MarkAllAsReadMutationVariables
>;

/**
 * __useMarkAllAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllAsReadMutation, { data, loading, error }] = useMarkAllAsReadMutation({
 *   variables: {
 *      readAt: // value for 'readAt'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkAllAsReadMutation(
	baseOptions?: Apollo.MutationHookOptions<
		MarkAllAsReadMutation,
		MarkAllAsReadMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		MarkAllAsReadMutation,
		MarkAllAsReadMutationVariables
	>(MarkAllAsReadDocument, options);
}
export type MarkAllAsReadMutationHookResult = ReturnType<
	typeof useMarkAllAsReadMutation
>;
export type MarkAllAsReadMutationResult =
	Apollo.MutationResult<MarkAllAsReadMutation>;
export type MarkAllAsReadMutationOptions = Apollo.BaseMutationOptions<
	MarkAllAsReadMutation,
	MarkAllAsReadMutationVariables
>;
