import dayjs from 'dayjs';

// Query
import {
	// Get Log
	GetActivityLogQuery,
	GetActivityLogQueryVariables,
	// Update Mutation
	UpdateActivityLogMutation,
	UpdateActivityLogMutationVariables,
} from '../../../../graphql/types';
import {
	LogFragmentFragmentDoc,
	UpdateActivityLogDocument,
	GetActivityLogDocument,
} from '../../../../graphql/hooks';

// Create Location Log
import {
	CreateLocationLogMutation,
	CreateLocationLogMutationVariables,
	CreateLocationLogDocument,
} from './query.generated';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { LogLocationArgs } from '../../types';
import { LogType } from '../../../../graphql/types';
import { LocationLogData } from '../../../../types';

export type LogLocationFormValues = {
	note: string;
	incidentNumber?: string;
};

const PreFetch = async (
	args: LogLocationArgs,
	client: ApolloClient<object>
) => {
	let log: ApolloQueryResult<GetActivityLogQuery> | undefined = undefined;

	if (args.id) {
		log = await client.query<GetActivityLogQuery, GetActivityLogQueryVariables>(
			{
				query: GetActivityLogDocument,
				variables: {
					id: args.id,
				},
			}
		);

		if (!log.data.getLog || log.error)
			throw new Error('Pre-fetched Data Failed');
	}

	return {
		log: log?.data.getLog ?? undefined,
	};
};

type Data = {
	log: GetActivityLogQuery['getLog'] | undefined;
};

const FormFields = (
	args: LogLocationArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const formData: LocationLogData | undefined = data.log?.data
		? JSON.parse(data.log?.data)
		: undefined;

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'note',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Note:',
				multiline: true,
				rowsMax: 5,
				defaultValue: formData?.note ?? '',
			},
			validation: {
				required: true,
			},
		},
	];

	if (args.updatedStatusType === 'MISSING') {
		fields.push({
			id: 'incidentNumber',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Police Incident Report Number:',
				defaultValue: formData?.incidentNumber ?? '',
			},
			validation: {},
		});
	}
	return [fields];
};

const GenerateOnSubmit = async (
	args: LogLocationArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: LogLocationFormValues): Promise<any> => {
		if (args.id) {
			const updateLocation = await client.mutate<
				UpdateActivityLogMutation,
				UpdateActivityLogMutationVariables
			>({
				mutation: UpdateActivityLogDocument,
				variables: {
					id: args.id ?? '',
					data: {
						data: JSON.stringify({
							note: formValues.note,
							incidentNumber: formValues.incidentNumber,
						}),
					},
				},
			});
			return updateLocation;
		} else {
			const createLog = await client.mutate<
				CreateLocationLogMutation,
				CreateLocationLogMutationVariables
			>({
				mutation: CreateLocationLogDocument,
				variables: {
					logData: {
						type: LogType.Location,
						youngPersonId: args.youngPersonId ?? '',
						createdBy: userId,
						data: JSON.stringify({
							note: formValues.note,
							status: args.updatedStatusType,
							incidentNumber: formValues.incidentNumber,
						}),
					},
					locationStatusId: args.updatedStatusId ?? '',
					youngPersonId: args.youngPersonId ?? '',
					missingAt:
						args.updatedStatusType === 'MISSING' ? dayjs().toISOString() : null,
				},
				refetchQueries: [LogFragmentFragmentDoc, 'ListLogs'],
			});

			return createLog;
		}
	};
	return onSubmit;
};

const LogLocationForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default LogLocationForm;
