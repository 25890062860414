import { Redirect } from 'react-router';

import { useGetMeQuery } from '../../graphql/hooks';
import { Role } from '../../graphql/types';
import AdminHomeScreen from './AdminDashboardScreen';

type AdminHomeScreenContainerProps = {};

const AdminHomeScreenContainer: React.FC<AdminHomeScreenContainerProps> = (
	props
) => {
	const { data, loading } = useGetMeQuery();

	const role = data?.me?.role;

	if (!loading && role) {
		if (role !== Role.Admin) {
			return <Redirect to="/" />;
		}
	}

	return <AdminHomeScreen />;
};

export default AdminHomeScreenContainer;
