import { Theme, createTheme } from '@material-ui/core';
import tinyColor from 'tinycolor2';
import { theme } from './theme';

// TODO: Override yellow to use default text colour
const generatePale = (color: string) =>
	tinyColor(color).lighten(40).toHexString();

const createColoredTheme = (main: string): Theme => {
	return createTheme({
		...theme,
		palette: {
			...theme.palette,
			primary: {
				main,
				pale: generatePale(main),
			},
		},
	});
};

export type ColorLabel =
	| 'Green'
	| 'Yellow'
	| 'Orange'
	| 'Purple'
	| 'Blue'
	| 'Coral'
	| 'Pink';

type Color = {
	label: ColorLabel;
	main: string;
};

export const colors: Color[] = [
	{
		label: 'Green',
		main: '#3BB472',
	},
	{
		label: 'Yellow',
		// main: '#EFED10',
		main: '#ffb125',
		// main: '#fdcf8a',
	},
	{
		label: 'Orange',
		main: '#F1870B',
	},
	{
		label: 'Purple',
		main: '#7420D8',
	},
	{
		label: 'Blue',
		main: '#1E73F4',
	},
	{
		label: 'Pink',
		main: '#F120A7',
	},
	{
		label: 'Coral',
		main: '#E25751',
	},
];

const coloredThemes = colors.map((color) => ({
	label: color.label,
	main: color.main,
	theme: createColoredTheme(color.main),
}));

export const getColoredTheme = (mainColor?: string) => {
	if (!mainColor) return theme;
	return (
		coloredThemes.find((theme) => theme.main === mainColor)?.theme ?? theme
	);
};
