import * as React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, createStyles, TextField, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		search: {
			background: 'transparent',
			border: '2px solid #C4C4C4',
			borderRadius: theme.spacing(4),
			height: 'min-content',
			minWidth: theme.spacing(38),
			width: '100%',
			'& input': {
				padding: theme.spacing(1.5),
				paddingLeft: 'unset',
			},
			'& fieldset': {
				border: 'none',
			},
			'& [class*="MuiOutlinedInput-notchedOutline"]': {
				borderRadius: '0',
				borderColor: 'transparent',
			},
			'&&& [class*="MuiOutlinedInput-root"][class*="Mui-focused"] [class*="MuiOutlinedInput-notchedOutline"]':
				{
					border: 'none',
				},
		},
		searchIcon: {
			color: theme.palette.grey[900],
			marginRight: theme.spacing(2),
		},
	})
);

export type SearchComponentProps = {
	onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
};

const SearchComponent: React.FC<SearchComponentProps> = (props) => {
	const styles = useStyles();

	return (
		<TextField
			disabled={props.disabled}
			className={styles.search}
			variant="outlined"
			type="search"
			onChange={props.onSearch}
			placeholder="Search"
			InputProps={{
				startAdornment: <SearchIcon className={styles.searchIcon} />,
			}}
		/>
	);
};

export default SearchComponent;
