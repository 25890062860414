import React from 'react';
import { ApolloError } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
import HouseIcon from '@material-ui/icons/House';

// Components
import CardWithAdornmentComponent from '../../components/CardWithAdornmentComponent';
import FallbackComponent, {
	ErrorType,
} from '../../components/FallbackComponent';
import ViewPaginationComponent from '../../components/ViewPaginationComponent';
import ListResourceGridComponent from '../../components/ListResourceGridComponent';

// Layouts
import AdminLayout from '../../layouts/AdminLayout';

// Query
import { ListHousesWithYoungPeopleQueryHookResult } from './query.generated';
import { FormType } from '../../graphql/types';

export type AdminListHousesScreenProps = {
	loading?: boolean;
	error?: ApolloError;
	data?: ListHousesWithYoungPeopleQueryHookResult['data'];
	setSearchTerm: (value: string) => void;
	setCurrentPage: (value: number) => void;
	pageCount: number;
	currentPage: number;
};

const AdminListHousesScreen: React.FC<AdminListHousesScreenProps> = (props) => {
	const houses = props.data?.listHouses ?? [];
	const history = useHistory();

	const goToHouse = (houseId: string) => {
		history.push(`/homes/${houseId}`);
	};

	const onSearch = (event: any) => {
		props.setSearchTerm(event?.currentTarget?.value);
	};

	return (
		<ErrorBoundary
			FallbackComponent={() => (
				<FallbackComponent hasError errorType={ErrorType.NOT_FOUND} />
			)}
		>
			<AdminLayout
				title="Houses"
				loading={props.loading}
				emptyTitle="No homes found"
				showEmpty={houses.length < 1}
				onSearch={onSearch}
				createForm={FormType.HouseCreate}
			>
				<ListResourceGridComponent>
					{houses.map((house) => {
						return (
							<CardWithAdornmentComponent
								avatarIcon={<HouseIcon />}
								avatarAlt={house?.title ?? 'A STELLA house'}
								adornmentColor={house?.color ?? undefined}
								onClick={() => goToHouse(house?.id ?? '')}
								label={house?.title ?? ''}
								subLabel={house?.address ?? ''}
							/>
						);
					})}
				</ListResourceGridComponent>
				<ViewPaginationComponent
					count={props.pageCount}
					page={props.currentPage}
					handleChange={props.setCurrentPage}
					copy={`Displaying ${props.data?.listHouses?.length ?? 0} of ${
						props.data?.countHouses ?? 0
					} Houses`}
				/>
			</AdminLayout>
		</ErrorBoundary>
	);
};

export default AdminListHousesScreen;
