export const capitalizeFirstLetter = (value: string): string => {
	return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const prettifyCaseEnum = (value: string) => {
	const split = value.split('_').map((string) => {
		return capitalizeFirstLetter(string);
	});

	return split.join(' ');
};
