import AuthContext from '../../contexts/authContext';
import HouseSelectorView from './HouseSelectorView';
import { useListHousesQuery } from './query.generated';
import { useContext } from 'react';

type HouseSelectorViewContainerProps = {};

const HouseSelectorViewContainer: React.FC<HouseSelectorViewContainerProps> = (
	props
) => {
	const { data, loading, error } = useListHousesQuery({
		variables: {
			where: {
				preMoveInHouse: { equals: false },
			},
		},
	});

	return <HouseSelectorView data={data} loading={loading} error={error} />;
};

export default HouseSelectorViewContainer;
