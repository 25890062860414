import React from 'react';
import {
	List,
	ListItem,
	createStyles,
	makeStyles,
	Theme,
} from '@material-ui/core';

const useStyles = makeStyles<Theme, { size: 'sm' | 'md' }>((theme) =>
	createStyles({
		listItem: {
			margin: 'unset',
			flex: 1,
		},
		mdList: {
			display: 'grid',
			gridTemplateColumns: 'repeat(auto-fill, minmax(275px, 1fr))',
			rowGap: theme.spacing('sm'),
			columnGap: theme.spacing('lg'),
			width: '100%',
		},
		smList: {
			display: 'flex',
			flexDirection: 'column',
			margin: 'unset',
			padding: 'unset',
			rowGap: theme.spacing('xs2'),
			'& [class*="MuiListItem-root"]': {
				padding: 'unset',
			},
		},
	})
);

type ListProps = {
	size: 'sm' | 'md';
	divider?: boolean;
};

const ListComponent: React.FC<ListProps> = (props) => {
	const styles = useStyles({ size: props.size });

	return (
		<List className={props.size === 'md' ? styles.mdList : styles.smList}>
			{React.Children.map(props.children, (child) => {
				return (
					<ListItem
						dense
						disableGutters
						className={styles.listItem}
						divider={props.divider}
					>
						{child}
					</ListItem>
				);
			})}
		</List>
	);
};

export default ListComponent;
