import { FormComponentProps } from '../../components/FormComponent';
import { FormFieldTypes } from '../../components/FormFieldsComponent';

const FormBase = (
	onSubmit: FormComponentProps['onSubmit']
): FormComponentProps => {
	return {
		title: 'Welcome',
		fields: [
			{
				id: 'username',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Email:',
					type: 'email',
					autoComplete: 'email',
				},
				validation: {
					required: true,
				},
			},
			{
				id: 'password',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Password:',
					type: 'password',
					autoComplete: 'current-password',
				},
				validation: {
					required: true,
					pattern: {
						value:
							/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\[\]{}()\\\/"'<>|~`=+#?!@$:%^&*\-_;.,]).{8,}$/,
						message:
							'Password require 1 lower and upper case letter, 1 number and 1 special character.',
					},
					defaultErrorMessage: 'Please enter a valid password.',
				},
			},
		],
		successCopy: 'You have successfully logged in.',
		submitCopy: 'Login',
		onSubmit,
	};
};

export default FormBase;
