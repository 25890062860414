import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { useOccupancyChartQuery } from './query.generated';
import OccupancyChartView from './OccupancyChartView';

// Helpers
import { dateDifference, dateRange, getGranularity } from './helpers';

// Types
import { ChartData, TableData, TableLogData } from './types';
import { intersection } from 'lodash';

export type OccupancyChartViewContainerProps = {
	id: string;
};

const OccupancyChartViewContainer: React.FC<OccupancyChartViewContainerProps> =
	(props) => {
		const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
		const [endDate, setEndDate] = useState(dayjs());
		const [tableData, setTableData] = useState<TableData[]>([]);
		const [tableLogData, setTableLogData] = useState<TableLogData[]>([]);
		const [chartData, setChartData] = useState<ChartData[]>([]);
		const [labels, setLabels] = useState<ChartData['occupancy']>([]);

		const { data, error, loading } = useOccupancyChartQuery({
			variables: {
				id: props.id,
				startDate: dayjs(startDate).format('YYYY-MM-DDThh:mm:ss.sssZ'),
				endDate: dayjs(endDate).format('YYYY-MM-DDThh:mm:ss.sssZ'),
			},
		});

		useEffect(() => {
			if (error) return;
			if (loading) return;

			// Get the granularity of the dates needed to display in the chart
			const granularity = getGranularity(startDate, endDate);

			// Create an array for each interval of the granularity
			const occupancyBase: ChartData['occupancy'] = [];
			let tempDate = startDate;

			while (tempDate.isBefore(endDate)) {
				occupancyBase.push({
					startDate: tempDate.format('DD/MM/YYYY'),
					endDate:
						granularity === 'day'
							? tempDate.format('DD/MM/YYYY')
							: tempDate
									.add(1, granularity)
									.subtract(1, 'day')
									.format('DD/MM/YYYY'),
					occupied: 0,
				});
				tempDate = tempDate.add(1, granularity);
			}

			// Create the temp array to store the data needed for the table and charts
			const tempChart: ChartData[] = [];
			const tempTable: TableData[] = [];
			const tempLogTable: TableLogData[] = [];

			data?.listRooms?.forEach((room) => {
				let tempOccupancy: ChartData['occupancy'] = JSON.parse(
					JSON.stringify(occupancyBase)
				) as ChartData['occupancy'];

				let totalDaysOccupied = 0;

				data.listOccupancyLogs
					?.filter((log) => log?.room?.name === room?.name)
					.forEach((log) => {
						// If the end date is null then use the end date set by the user
						const logEndDate = log?.endDate ? dayjs(log?.endDate) : endDate;
						const logStartDate = log?.startDate
							? dayjs(log?.startDate)
							: undefined;

						if (!logStartDate) throw new Error('Log start date is undefined');

						// Get the difference between the start and end date in days, using the filter start date if the startDate is before the filter start date
						const logDateDifference = dateDifference(
							logStartDate,
							logEndDate,
							startDate
						);

						totalDaysOccupied += logDateDifference;

						// Got through the tempOccupancy array and add the occupied value to the correct date
						tempOccupancy = tempOccupancy.map((occupancy) => {
							const occupancyStartDate = dayjs(
								occupancy.startDate,
								'DD/MM/YYYY'
							);
							const occupancyEndDate = dayjs(occupancy.endDate, 'DD/MM/YYYY');

							// Check if the occupancy date is between the log dates
							if (
								(logStartDate.isBefore(occupancyEndDate) ||
									logStartDate.isSame(occupancyEndDate)) &&
								(logEndDate.isAfter(occupancyStartDate) ||
									logEndDate.isSame(occupancyStartDate))
							) {
								const logDates = dateRange(logStartDate, logEndDate);
								const occupancyDates = dateRange(
									occupancyStartDate,
									occupancyEndDate
								);
								const days = intersection(logDates, occupancyDates).length;
								return {
									...occupancy,
									occupied: days ?? 1,
								};
							}

							return occupancy;
						});

						// Add the data to the Log Table
						tempLogTable.push({
							startDate: logStartDate.format('DD/MM/YYYY'),
							endDate: log?.endDate
								? dayjs(log.endDate).format('DD/MM/YYYY')
								: 'Ongoing',
							room: log?.room?.name ?? '',
							youngPerson: log?.youngPerson?.name ?? '',
							daysOfOccupancy: logDateDifference,
						});
					});

				tempTable.push({
					room: room?.name ?? '',
					occupiedFor: totalDaysOccupied,
					unoccupiedFor: dateDifference(startDate, endDate) - totalDaysOccupied,
				});

				tempChart.push({
					roomName: room?.name ?? '',
					color: room?.color ?? '',
					occupancy: tempOccupancy,
				});
			});

			setTableLogData(tempLogTable);
			setTableData(tempTable);
			setChartData(tempChart);
			setLabels(occupancyBase);
		}, [data, error, loading]);

		return (
			<OccupancyChartView
				tableData={tableData}
				tableLogData={tableLogData}
				chartData={chartData}
				labels={labels}
				error={!!error}
				loading={loading}
				startDate={dayjs(startDate).format('YYYY-MM-DD')}
				endDate={dayjs(endDate).format('YYYY-MM-DD')}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
			/>
		);
	};

export default OccupancyChartViewContainer;
