import React, { useState } from 'react';

// Component
import ModalContentComponent from '../components/ModalContentComponent';
import FormModalLayout from '../layouts/FormModalLayout';
import UploadModalLayout, {
	UploadModalLayoutProps,
} from '../layouts/UploadModalLayout';

// Types
import { FormArgs } from '../hooks/useForm/types';
import { FormModalLayoutProps } from '../layouts/FormModalLayout/FormModalLayout';

type OpenModalConfig = {
	warnOnEscape?: boolean;
};

type ModalContextProps = {
	isOpen: boolean;
	open: (modal: Modal, config?: OpenModalConfig) => void;
	reOpen: () => void;
	close: () => void;
	content: React.ReactNode | null;
	shouldWarnOnEscape: boolean;
	setShouldWarnOnEscape: (shouldWarn: boolean) => void;
};

export type Modal = FormModal | UploadModal | JsxModal;

type FormModal = FormModalLayoutProps & {
	type: ModalType.FORM;
};

type UploadModal = {
	type: ModalType.UPLOAD;
	props: UploadModalLayoutProps;
};

type JsxModal = {
	type: ModalType.JSX;
	content: React.ReactNode;
};

export enum ModalType {
	'FORM',
	'UPLOAD',
	'JSX',
}

const defaultValue = {
	isOpen: false,
	open: () => console.log('open'),
	close: () => console.log('close'),
	reOpen: () => console.log('reOpen'),
	content: null,
	shouldWarnOnEscape: false,
	setShouldWarnOnEscape: () => undefined,
};

const ModalContext = React.createContext<ModalContextProps>(defaultValue);

const ModalContextProvider: React.FC = ({ children }) => {
	// States
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [content, setContent] = useState<React.ReactNode | null>();
	// if user edits a form and presses escape, the modal closes and progress
	// is lost -- this tells the drawer component to show a warning dialog
	// before closing
	const [shouldWarnOnEscape, setShouldWarnOnEscape] = useState(false);

	const open = async (modal: Modal, config?: OpenModalConfig) => {
		if (config?.warnOnEscape) {
			setShouldWarnOnEscape(true);
		}
		setIsOpen(true);

		switch (modal.type) {
			case ModalType.JSX:
				setContent(modal.content);
				break;
			case ModalType.UPLOAD:
				setContent(<UploadModalLayout {...modal.props} />);
				break;
			case ModalType.FORM:
				setContent(<FormModalLayout {...{ ...modal, type: undefined }} />);
				break;
			default:
				setContent(
					<ModalContentComponent modalTitle={{ title: '' }} isEmpty={true} />
				);
		}
	};

	const reOpen = () => {
		close();
		setIsOpen(true);
	};

	const close = () => {
		setIsOpen(false);
		setContent(null);
	};

	const handleSetWarnOnEscape = (shouldWarn: boolean) => {
		if (shouldWarn !== shouldWarnOnEscape) {
			setShouldWarnOnEscape(shouldWarn);
		}
	};

	return (
		<ModalContext.Provider
			value={{
				isOpen,
				open,
				close,
				reOpen,
				content,
				shouldWarnOnEscape,
				setShouldWarnOnEscape: handleSetWarnOnEscape,
			}}
		>
			{children}
		</ModalContext.Provider>
	);
};

export { ModalContextProvider };

export default ModalContext;
