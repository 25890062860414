import React, { useEffect, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	createStyles,
	Theme,
	makeStyles,
	Checkbox,
	CheckboxProps,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			marginBottom: theme.spacing('sm'),
			textAlign: 'left',
			width: '100%',
		},
		errorMessage: {
			color: theme.palette.error.main,
			marginBottom: theme.spacing(2),
		},
		checkLabel: {
			position: 'relative',
			transform: 'scale(0.75) !important',
			textAlign: 'left',
			marginBottom: '8px',
		},
		hiddenInput: {
			display: 'none',
		},
		check: {
			width: 'fit-content',
		},
	})
);

export type CheckboxComponentProps = {
	config: Omit<CheckboxProps, 'value' | 'required'>;
	label: string;
	validation?: RegisterOptions;
	error?: any;
	formField?: any;
	id: string;
};

const CheckboxComponent: React.FC<CheckboxComponentProps> = (props) => {
	const classes = useStyles();
	const [value, setValue] = useState(props.config.defaultChecked);
	const label = `${props.label}${props.validation?.required ? '*' : ''}`;

	// Watch out for changes to the value and update the current value if it changes. Normally a change to the external value indicates a change of page
	useEffect(() => {
		// To avoid a loop, check if the value has actually changed
		if (props.formField?.value !== value) {
			setValue(props.formField?.value);
		}
	}, [props.formField]);

	const onChange = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		value: any
	) => {
		setValue(value);

		// DO NOT TOUCH!!!!
		if (props.formField.onChange) {
			const customEvent = {
				...event,
				target: {
					value,
				},
			};
			props.formField.onChange(customEvent);
		}
	};

	return (
		<FormControl
			className={classes.wrapper}
			variant="standard"
			error={!!props.error ?? true}
		>
			<InputLabel className={classes.checkLabel}>{label}</InputLabel>
			{/* @ts-ignore */}
			<Checkbox className={classes.check} onChange={onChange} checked={value} />

			{props.error ? (
				<FormHelperText>{props.error.message}</FormHelperText>
			) : null}
		</FormControl>
	);
};

export default CheckboxComponent;
