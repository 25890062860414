import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type UserDetailsFragment = {
	__typename?: 'User';
	id: string;
	firstName: string;
	lastName: string;
	phone?: string | null | undefined;
	address?: string | null | undefined;
	email: any;
	houseId?: string | null | undefined;
	disabled?: boolean | null | undefined;
	role: Types.Role;
};

export type GetUserQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
}>;

export type GetUserQuery = {
	__typename?: 'Query';
	getUser?:
		| {
				__typename?: 'User';
				id: string;
				firstName: string;
				lastName: string;
				phone?: string | null | undefined;
				address?: string | null | undefined;
				email: any;
				houseId?: string | null | undefined;
				disabled?: boolean | null | undefined;
				role: Types.Role;
		  }
		| null
		| undefined;
};

export type UpdateUserMutationVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
	data: Types.UserUpdateInput;
}>;

export type UpdateUserMutation = {
	__typename?: 'Mutation';
	updateUser?:
		| {
				__typename?: 'User';
				id: string;
				firstName: string;
				lastName: string;
				phone?: string | null | undefined;
				address?: string | null | undefined;
				email: any;
				houseId?: string | null | undefined;
				disabled?: boolean | null | undefined;
				role: Types.Role;
		  }
		| null
		| undefined;
};

export const UserDetailsFragmentDoc = gql`
	fragment UserDetails on User {
		id
		firstName
		lastName
		phone
		address
		email
		houseId
		disabled
		role
	}
`;
export const GetUserDocument = gql`
	query GetUser($id: String) {
		getUser(where: { id: $id }) {
			...UserDetails
		}
	}
	${UserDetailsFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
	baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
		GetUserDocument,
		options
	);
}
export function useGetUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
		GetUserDocument,
		options
	);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
	GetUserQuery,
	GetUserQueryVariables
>;
export const UpdateUserDocument = gql`
	mutation UpdateUser($id: String, $data: UserUpdateInput!) {
		updateUser(where: { id: $id }, data: $data) {
			...UserDetails
		}
	}
	${UserDetailsFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
	UpdateUserMutation,
	UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateUserMutation,
		UpdateUserMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
		UpdateUserDocument,
		options
	);
}
export type UpdateUserMutationHookResult = ReturnType<
	typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
	Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
	UpdateUserMutation,
	UpdateUserMutationVariables
>;
