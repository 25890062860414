import React from 'react';
import {
	Box,
	CircularProgress,
	createStyles,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			height: '100vh',
			width: '100vw',
			alignItems: 'center',
			justifyContent: 'center',
		},
		contentWrapper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'column',
			gap: '20px',
		},
	})
);

export type FullScreenLoadingComponentProps = {};

const FullScreenLoadingComponent: React.FC<FullScreenLoadingComponentProps> = (
	props
) => {
	const classes = useStyles();

	return (
		<Box className={classes.wrapper}>
			<Box className={classes.contentWrapper}>
				<Typography variant="h2">Getting things ready...</Typography>
				<CircularProgress />
			</Box>
		</Box>
	);
};

export default FullScreenLoadingComponent;
