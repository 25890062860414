import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Location from 'aws-sdk/clients/location';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, TextFieldProps } from '@material-ui/core';

type LocationInputComponentProps = TextFieldProps & {};

const LocationInputComponent: React.FC<LocationInputComponentProps> = (
	props
) => {
	const [client, setClient] = useState<Location>();
	const [results, setResults] = useState<
		Location.SearchPlaceIndexForTextResponse['Results']
	>([]);

	useEffect(() => {
		if (!client) createClient();
	}, [client]);

	// This will create a AWS Location Services client needed to run the search queries
	const createClient = async () => {
		// Grab the users auth
		try {
			const credentials = await Auth.currentCredentials();
			const client = new Location({
				credentials,
				region: 'eu-west-1',
			});
			setClient(client);
		} catch (error) {
			console.log('Error! ', error);
		}
	};

	// Search for a location
	const onSearch = async (place: string) => {
		try {
			if (!client) throw new Error('No Client');
			const data = await client
				.searchPlaceIndexForText({
					IndexName: 'StellaLocationSearch',
					FilterCountries: ['GBR'],
					Text: place,
				})
				.promise();

			setResults(data.Results);
		} catch (error) {
			console.log('error', error);
		}
	};

	const onChangeAutoComplete = (
		event: any,
		value: Location.SearchForTextResult
	) => {
		if (props.onChange)
			props.onChange({
				target: {
					// @ts-expect-error
					value: {
						Label: value.Place.Label,
						Point: value.Place.Geometry.Point,
					},
				},
			});
	};

	return (
		<Autocomplete
			options={results}
			getOptionLabel={(option) => option.Place.Label ?? 'Unknown'}
			// @ts-expect-error
			onChange={onChangeAutoComplete}
			renderInput={(params) => {
				return (
					<TextField
						{...props}
						{...params}
						onChange={(e) => {
							onSearch(e.target.value);
						}}
					/>
				);
			}}
		/>
	);
};

export default LocationInputComponent;
