import { FormComponentProps } from '../../components/FormComponent';
import { FormFieldTypes } from '../../components/FormFieldsComponent';

const FormBase = (
	onSubmit: FormComponentProps['onSubmit']
): FormComponentProps => {
	return {
		// title: 'Authorise Account',
		fields: [
			{
				id: 'code',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Code:',
				},
				validation: {
					required: true,
				},
			},
		],
		// successCopy: "You're account has been successfully authorised..",
		// submitCopy: 'Authorise',
		// onSubmit,
		// @ts-ignore
		control: null,
	};
};

export default FormBase;
