import { useParams } from 'react-router-dom';

import { useGetYoungPersonAdminTasksQuery } from './query.generated';
import TaskGroupProgressView from './TaskGroupProgressView';

type TaskGroupProgressViewContainerProps = {
	youngPersonId?: string;
};

type Params = {
	youngPersonId?: string;
};

const TaskGroupProgressViewContainer: React.FC<TaskGroupProgressViewContainerProps> =
	(props) => {
		const { youngPersonId } = useParams<Params>();
		const id = props.youngPersonId ?? youngPersonId ?? '';

		const { data, loading, error } = useGetYoungPersonAdminTasksQuery({
			variables: { youngPersonId: id },
		});

		if (error) {
			console.log(`error`, error);
		}

		return (
			<TaskGroupProgressView data={data} loading={loading} error={error} />
		);
	};

export default TaskGroupProgressViewContainer;
