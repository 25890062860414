import React, { useContext, useEffect } from 'react';
import AuthContext from '../../contexts/authContext';
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import ReadTaskView from '../ReadTaskView';
import { usePagination } from '../../hooks';
import {
	ListTasksQueryVariables,
	useListTasksQuery,
	useOnYoungPersonTaskMutatedSubscription,
	useOnHouseTaskMutatedSubscription,
} from './query.generated';
import TasksView from './TasksView';

interface TasksViewContainerProps {
	title?: string;
	filter?: ListTasksQueryVariables['where'];
	sort?: ListTasksQueryVariables['orderBy'];
	limit?: ListTasksQueryVariables['take'];
}

const TasksViewContainer = (props: TasksViewContainerProps) => {
	const { user } = useContext(AuthContext);
	const { open } = useContext(ModalContext);
	const {
		skip,
		take,
		currentPage,
		handlePaginate,
		pageCount,
		setCount,
		isFirstPage,
		isLastPage,
	} = usePagination({ take: 5, count: 0 });

	// Setup filters based on props
	const privateFilter: ListTasksQueryVariables['where'] = props.filter
		?.isPrivate?.equals
		? {
				...props.filter,
				createdUserId: { equals: user?.id },
		  }
		: props.filter;

	useOnYoungPersonTaskMutatedSubscription({
		variables: {
			youngPersonId: props.filter?.youngPersonId?.equals,
		},
	});

	useOnHouseTaskMutatedSubscription({
		variables: {
			houseId: props.filter?.houseId?.equals,
		},
	});

	const { data, loading, error } = useListTasksQuery({
		variables: {
			where: privateFilter,
			orderBy: props.sort,
			take,
			skip,
		},
	});

	useEffect(() => {
		setCount(data?.countTasks ?? 0);
	}, [data, setCount]);

	const onOpenTask = (id: string) => {
		if (id) {
			open({ type: ModalType.JSX, content: <ReadTaskView id={id} /> });
		}
	};

	return (
		<TasksView
			onOpenTask={onOpenTask}
			data={data}
			loading={loading}
			error={error}
			countTasks={data?.countTasks ?? 0}
			pageCount={pageCount}
			currentPage={currentPage}
			handlePaginate={handlePaginate}
			isFirstPage={isFirstPage}
			isLastPage={isLastPage}
			title={props.title}
		/>
	);
};

export default TasksViewContainer;
