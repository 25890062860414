import { GetYoungPersonQueryHookResult } from '../query.generated';
import { CardDetail } from '../../../components/DetailsCardComponent';

const NextOfKinDetailsCard = (args: GetYoungPersonQueryHookResult['data']) => {
	const nextOfKin: CardDetail[] =
		args?.getYoungPerson?.nextOfKin?.map((kin) => {
			return {
				id: kin.id,
				canEdit: true,
				canDelete: true,
				title: `${kin.name}:`,
				values: [
					{
						id: `${kin.id}Relation`,
						title: 'Relation:',
						copy: kin.relation,
					},
					{
						id: `${kin.id}Phone`,
						title: 'Phone:',
						copy: kin.phone,
					},
					{
						id: `${kin.id}Contactable`,
						title: 'Allowed to Contact:',
						copy: kin.contactable ? 'Yes' : 'No',
					},
					{
						id: `${kin.id}Visitable`,
						title: 'Allowed to Visit:',
						copy: kin.visitable ? 'Yes' : 'No',
					},
					{
						id: `${kin.id}Note`,
						title: 'Note:',
						copy: kin.note ?? '-',
					},
				],
			};
		}) ?? [];

	return nextOfKin;
};

export default NextOfKinDetailsCard;
