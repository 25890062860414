import { ApolloError } from '@apollo/client';
import React, { useContext } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';

// Query
import { ListRoomsQuery } from './query.generated';

// Contexts
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { Can } from '../../contexts/CanContext';

// Components
import FallbackComponent from '../../components/FallbackComponent';
import CardWithAdornmentComponent from '../../components/CardWithAdornmentComponent';
import ListComponent from '../../components/ListComponent';
import AddButtonComponent from '../../components/AddButtonComponent';
import { FormType } from '../../graphql/types';

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'flex-end',
		paddingBottom: theme.spacing('md'),
	},
}));

export type RoomsViewProps = {
	error?: ApolloError;
	loading: boolean;
	data: ListRoomsQuery['listRooms'];
	houseId?: string;
};

const RoomsView: React.FC<RoomsViewProps> = (props) => {
	const classes = useStyles();
	const { open } = useContext(ModalContext);
	const rooms = props.data ?? [];

	return (
		<Box display="flex" flexDirection="column">
			<Can I="create" a="Room">
				<Box className={classes.header}>
					<AddButtonComponent
						onClick={() => {
							if (!props.houseId) return;
							open({
								type: ModalType.FORM,
								args: {
									type: FormType.Room,
									houseId: props.houseId,
								},
							});
						}}
					/>
				</Box>
			</Can>
			<Box minHeight="7.5rem">
				<FallbackComponent
					hasError={Boolean(props.error)}
					isLoading={Boolean(props.loading)}
					loadingType="skeletonGridSm"
					isEmpty={rooms.length < 1}
					emptyTitle="No Rooms"
					emptyCopy="To create the first room for this house click the '+' icon."
					errorTitle={'An Error Occurred'}
					errorCopy="There was an error fetching the rooms. If the problem persists, please contact your service administrator."
				>
					<ListComponent size="md">
						{rooms.map(
							(item) =>
								item && (
									<CardWithAdornmentComponent
										key={item.id}
										label={item.name ?? ''}
										subLabel={''}
										adornmentColor={item.color ?? undefined}
										avatarSrc={''}
										avatarAlt={`room profile picture`}
										onClick={() => {
											open({
												type: ModalType.FORM,
												args: {
													type: FormType.Room,
													roomId: item.id,
												},
											});
										}}
									/>
								)
						)}
					</ListComponent>
				</FallbackComponent>
			</Box>
		</Box>
	);
};

export default RoomsView;
