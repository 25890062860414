import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type CreateAlertMutationVariables = Types.Exact<{
	data: Types.NotificationCreateInput;
}>;

export type CreateAlertMutation = {
	__typename?: 'Mutation';
	createNotification?:
		| {
				__typename?: 'Notification';
				id: string;
				title: string;
				severity?: Types.SeverityLevel | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				readAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export const CreateAlertDocument = gql`
	mutation CreateAlert($data: NotificationCreateInput!) {
		createNotification(data: $data) {
			id
			title
			severity
			houseId
			createdAt
			readAt
		}
	}
`;
export type CreateAlertMutationFn = Apollo.MutationFunction<
	CreateAlertMutation,
	CreateAlertMutationVariables
>;

/**
 * __useCreateAlertMutation__
 *
 * To run a mutation, you first call `useCreateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertMutation, { data, loading, error }] = useCreateAlertMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAlertMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateAlertMutation,
		CreateAlertMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CreateAlertMutation, CreateAlertMutationVariables>(
		CreateAlertDocument,
		options
	);
}
export type CreateAlertMutationHookResult = ReturnType<
	typeof useCreateAlertMutation
>;
export type CreateAlertMutationResult =
	Apollo.MutationResult<CreateAlertMutation>;
export type CreateAlertMutationOptions = Apollo.BaseMutationOptions<
	CreateAlertMutation,
	CreateAlertMutationVariables
>;
