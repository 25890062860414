import React, { useState, useEffect } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';
import dayjs from 'dayjs';

// Components
import FormFieldsComponent, {
	FormFieldTypes,
} from '../../components/FormFieldsComponent';

// Types
import { FormField } from '../../components/FormFieldsComponent/FormFieldsComponent';
import { FormListYoungPeopleQuery } from '../../graphql/types';
import { TrmLevels } from '../../types';

type StepOneProps = {
	youngPeopleData: FormListYoungPeopleQuery['listYoungPeople'];
	control: Control;
	watch: UseFormWatch<object>;
};

const TrmText: React.FC<{ level: number }> = (props) => {
	let copy: string = '';

	if (props.level === 1) {
		copy = `FABRIC Kid may be demonstrating challenging behaviours and may have a poor sleep pattern. There may be issues around their relationships with their peers and others professionals. They may have a poor diet and irregular meal times. Work with FABRIC Kid to bring consistency and encourage them to spend more time caring for themselves. Promote importance of a stable home, routine, boundaries and engagement in support. Establish & use anchor points to promote this.`;
	} else if (props.level === 1.5) {
		copy = `FABRIC Kid has begun to make small changes around their presentation and are beginning to engage in some support. They have spoken about their intention to engage in support and have begun to talk about their behaviours. Kids will begin to talk with ‘change’ language and may talk about certain things such as the future if this wasn’t a barrier. They are getting ready and considering their lives without barriers, we need to spot this and encourage.`;
	} else if (props.level === 2) {
		copy = `FABRIC Kid has begun to engage more frequently in support around the house and is seems open to making relationships with 1 or 2 selected team members. Routines and sleep patterns may have improved a little and the FABRIC Kid may be smiling more. It is important that you maintain consistent boundaries to ensure a trusting relationship and so a stable secure base can be developed with adults. However still expect some outbursts & the need for ongoing support, particularly with peer relationships.`;
	} else if (props.level === 2.5) {
		copy = `FABRIC Kid is progressing well and there are more conversations around their trauma or early life. FABRIC Kids may begin to seek advice or information around other services that can support regarding trauma. They may begin to ask more specific questions or talk in more detail. Be prepared to do lots of listening. Don’t rush to ‘fix’ things or offer quick advice. Lean in, listen hard & empathise. Record verbatim what Fabric Kids share and consult with Home manager re any possible safeguarding referrals needed.`;
	} else if (props.level === 3 || props.level === 3.5) {
		copy = `FABRIC Kid engaging in support sessions in FABRIC Home. They may have or should consider external support with specialised bereavement or specialist therapeutic professionals.  FABRIC Kid begins to process past experiences and may choose to honour these; e.g. by marking key anniversaries. FABRIC Kid susceptible to drop down to lower levels post disclosure due to stress and being in new territory. This drop should never be considered a rejection or failed attempt at support; rather it should be seen and treated as a ‘blip’ or ‘hiccup’. Ensure if the Fabric Kid slips down to a previous level that all staff know and the support offered matches the new lower level. Things will probably improve again quickly.`;
	} else if (props.level === 4 || props.level === 4.5) {
		copy = `Increased insight into behaviours and ability to regulate emotions much improved. Conversations may refer back to how things used to be for them as a contrast to how they are now. Ability to participate in other types of support such as behaviour specific interventions (e.g. anger management and or consequential thinking) or things like restorative approaches or CBT.`;
	} else if (props.level === 5 || props.level === 5.5) {
		copy = `FABRIC Kid feels sense of achievement and begins to take part in structuring goals and things to do during free time. FABRIC Kid begins to look at employment, education and training opportunities and may need support to access these. Support begins to feel more as and when needed and is aimed at supporting decision making process. FABRIC Kid accepts their potential and abilities, but may need help if things go a bit wrong or they get wobbly due to a lack of confidence – remember: this is new territory! Notice and help Fabric Kids to identify and celebrate the small (or big!) wins they achieve.`;
	} else if (props.level === 6) {
		copy = `FABRIC Kid achieving their goals with confidence and with limited support while understanding that support will be there for times of need. Let them know how to ask for help or how to check back in with you if they need to. Emphasise that you want to know about their achievements as well as being available to call on if they need support.`;
	} else {
		copy =
			"The Trauma Recovery Model is a road map to help professionals care for and guide troubled people towards recovery. It's a composite model; combing theories of child development, attachment and neuroscience with hands on practitioner skills. It provides practical guidelines for knowing which interventions to use and when. This means practitioners understand the psychological needs that underpin behaviours and can identify the types of interventions that best address those needs. Working in the TRM way puts relationship building and therapeutic interaction first, mediating the impact of trauma. This paves the way for interventions that are tailored and sequenced in a way that really can make a difference. When selecting the TRM Level please refer to your training and the model itself. Remember the TRM is not a static or fixed model and levels will vary depending on what is occurring in a young persons life.";
	}

	return (
		<Box style={{ marginTop: '12px' }}>
			<Typography variant="h4" style={{ marginBottom: '8px' }}>
				TRM Level Description:{' '}
			</Typography>
			<Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
				{copy}
			</Typography>
		</Box>
	);
};

const StepOne: React.FC<StepOneProps> = (props) => {
	const [trmLevel, setTrmLevel] = useState<number>(0);
	const [formFields, setFormFields] = useState<FormField[]>([
		{
			id: 'date',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Date:',
				defaultValue: dayjs(Date.now()).format('YYYY-MM-DD'),
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'trmLevel',
			type: FormFieldTypes.SELECT,
			label: 'TRM Level:',
			options: TrmLevels.map((level) => {
				return {
					copy: level.label,
					value: level.value,
				};
			}),
			validation: {
				required: true,
			},
			config: {},
		},
		{
			id: 'type',
			type: FormFieldTypes.SELECT,
			label: 'Spin Type:',
			options: [
				{
					copy: 'Initial Spin',
					value: 'Initial',
				},
				{
					copy: 'Review Spin',
					value: 'Review',
				},
				{
					copy: 'Unscheduled Spin',
					value: 'Unscheduled',
				},
			],
			validation: {
				required: true,
			},
			config: {},
		},
	]);

	useEffect(() => {
		if (props.youngPeopleData?.length === 0) return;
		const youngPeopleOptions = props.youngPeopleData?.map((youngPerson) => {
			return {
				value: youngPerson?.id ?? '',
				copy: youngPerson?.name ?? '',
			};
		});

		setFormFields([
			{
				id: 'youngPersonId',
				type: FormFieldTypes.SELECT,
				options: youngPeopleOptions ?? [],
				label: 'Select Kid',
				config: {},
			},
			...formFields,
		]);
	}, [props.youngPeopleData]);

	const watchForm = props.watch();

	useEffect(() => {
		const subscription = props.watch((value, { name, type }) => {
			if (name !== 'trmLevel') return;
			// @ts-expect-error
			if (type === 'change') setTrmLevel(value.trmLevel);
		});
		return () => subscription.unsubscribe();
	}, [watchForm]);

	return (
		<>
			<FormFieldsComponent control={props.control} fields={formFields} />
			<TrmText level={trmLevel} />
		</>
	);
};

export default StepOne;
