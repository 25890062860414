import { ApolloClient } from '@apollo/client';
import { legalStatuses } from '../../../../utils/legalStatus';

// Query
import {
	// Get Details
	GetYoungPersonLegalDetailsDocument,
	GetYoungPersonLegalDetailsQuery,
	GetYoungPersonLegalDetailsQueryVariables,
	// Update Details Mutation
	UpdateYoungPersonProfileDocument,
	UpdateYoungPersonProfileMutation,
	UpdateYoungPersonProfileMutationVariables,
} from './query.generated';

// Types
import { YpLegalDetailsArgs } from '../../types';
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';

type FormValues = {
	niNumber: string;
	registeredGP: string;
	registeredDentist: string;
	registeredOptician: string;
	nhsNumber: string;
	socialWorker: string;
	legalStatus: string;
	localAuthority: string;
	ypAdvisor: string;
};

const PreFetch = async (
	args: YpLegalDetailsArgs,
	client: ApolloClient<object>
) => {
	const details = await client.query<
		GetYoungPersonLegalDetailsQuery,
		GetYoungPersonLegalDetailsQueryVariables
	>({
		query: GetYoungPersonLegalDetailsDocument,
		variables: {
			youngPersonId: args.youngPersonId ?? '',
		},
	});

	if (!details.data.getYoungPerson || details.error)
		throw new Error('Pre-fetched Data Failed.');

	return details.data.getYoungPerson;
};

const FormFields = (
	args: YpLegalDetailsArgs,
	data: GetYoungPersonLegalDetailsQuery['getYoungPerson']
): FormFieldsComponentProps['fields'][] => {
	// TODO: Need to add actual validation to these forms
	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'legalStatus',
			type: FormFieldTypes.SELECT,
			label: 'Legal Status:',
			options: legalStatuses.map((status) => {
				return {
					copy: status.value,
					value: status.code,
				};
			}),
			config: {
				defaultValue: data?.legalStatus ?? '',
			},
		},
		{
			id: 'localAuthority',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Local Authority:',
				defaultValue: data?.localAuthority ?? '',
			},
		},
		{
			id: 'niNumber',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'National Insurance Number:',
				defaultValue: data?.niNumber ?? '',
			},
		},
		{
			id: 'nhsNumber',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'NHS Number:',
				defaultValue: data?.nhsNumber ?? '',
			},
		},
		{
			id: 'registeredGP',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Registered GP Details:',
				defaultValue: data?.registeredGP ?? '',
				multiline: true,
				rows: 4,
			},
		},
		{
			id: 'registeredDentist',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Registered Dentist Details:',
				defaultValue: data?.registeredDentist ?? '',
				multiline: true,
				rows: 4,
			},
		},
		{
			id: 'registeredOptician',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Registered Optician Details:',
				defaultValue: data?.registeredOptician ?? '',
				multiline: true,
				rows: 4,
			},
		},

		{
			id: 'socialWorker',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Social Worker Details:',
				defaultValue: data?.socialWorker ?? '',
				multiline: true,
				rows: 4,
			},
		},
		{
			id: 'ypAdvisor',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Young Person Advisor Details:',
				defaultValue: data?.ypAdvisor ?? '',
				multiline: true,
				rows: 4,
			},
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: YpLegalDetailsArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: FormValues): Promise<any> => {
		const updateYp = await client.mutate<
			UpdateYoungPersonProfileMutation,
			UpdateYoungPersonProfileMutationVariables
		>({
			mutation: UpdateYoungPersonProfileDocument,
			variables: {
				youngPersonId: args.youngPersonId ?? '',
				data: formValues,
			},
		});

		return updateYp;
	};

	return onSubmit;
};

const YoungPersonLegalDetailsForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default YoungPersonLegalDetailsForm;
