import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type HouseLocationDetailsQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
}>;

export type HouseLocationDetailsQuery = {
	__typename?: 'Query';
	getHouse?:
		| {
				__typename?: 'House';
				id: string;
				lat?: string | null | undefined;
				long?: string | null | undefined;
				address?: string | null | undefined;
				phone?: string | null | undefined;
		  }
		| null
		| undefined;
};

export const HouseLocationDetailsDocument = gql`
	query HouseLocationDetails($id: String) {
		getHouse(where: { id: $id }) {
			id
			lat
			long
			address
			phone
		}
	}
`;

/**
 * __useHouseLocationDetailsQuery__
 *
 * To run a query within a React component, call `useHouseLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseLocationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHouseLocationDetailsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		HouseLocationDetailsQuery,
		HouseLocationDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		HouseLocationDetailsQuery,
		HouseLocationDetailsQueryVariables
	>(HouseLocationDetailsDocument, options);
}
export function useHouseLocationDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		HouseLocationDetailsQuery,
		HouseLocationDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		HouseLocationDetailsQuery,
		HouseLocationDetailsQueryVariables
	>(HouseLocationDetailsDocument, options);
}
export type HouseLocationDetailsQueryHookResult = ReturnType<
	typeof useHouseLocationDetailsQuery
>;
export type HouseLocationDetailsLazyQueryHookResult = ReturnType<
	typeof useHouseLocationDetailsLazyQuery
>;
export type HouseLocationDetailsQueryResult = Apollo.QueryResult<
	HouseLocationDetailsQuery,
	HouseLocationDetailsQueryVariables
>;
