import { ApolloClient } from '@apollo/client';
import { legalStatuses } from '../../../../utils/legalStatus';

// Query
import {
	// Update Details Mutation
	UpdateYoungPersonMoveOutMutation,
	UpdateYoungPersonMoveOutMutationVariables,
	UpdateYoungPersonMoveOutDocument,
	// Get Move Out ID
	GetMoveOutHouseDocument,
	GetMoveOutHouseQuery,
	GetMoveOutHouseQueryVariables,
} from './query.generated';

// Types
import { YpMoveOutArgs } from '../../types';
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import dayjs from 'dayjs';

type FormValues = {
	date: string;
	note: string;
};

const PreFetch = async (args: YpMoveOutArgs, client: ApolloClient<object>) => {
	return {};
};

const FormFields = (
	args: YpMoveOutArgs,
	data: {}
): FormFieldsComponentProps['fields'][] => {
	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'date',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Move out Date:',
				InputLabelProps: {
					shrink: true,
				},
				defaultValue: dayjs().format('YYYY-MM-DD'),
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'note',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Reason for Leaving:',
				multiline: true,
				rows: 4,
			},
			validation: {
				required: false,
			},
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: YpMoveOutArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: FormValues): Promise<any> => {
		const house = await client.query<
			GetMoveOutHouseQuery,
			GetMoveOutHouseQueryVariables
		>({
			query: GetMoveOutHouseDocument,
			variables: {
				ypId: args.youngPersonId ?? '',
			},
		});

		if (!house.data.getYoungPerson?.house?.moveOutHouseId)
			throw new Error('No move out house found');

		const updateYoungPerson = await client.mutate<
			UpdateYoungPersonMoveOutMutation,
			UpdateYoungPersonMoveOutMutationVariables
		>({
			mutation: UpdateYoungPersonMoveOutDocument,
			variables: {
				ypId: args.youngPersonId ?? '',
				date: dayjs(formValues.date).toISOString(),
				note: formValues.note,
				houseId: house.data?.getYoungPerson?.house?.moveOutHouseId ?? '',
			},
		});

		return updateYoungPerson;
	};
	return onSubmit;
};

const YoungPersonMoveRooms = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default YoungPersonMoveRooms;
