import * as React from 'react';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface FallbackIconContainerProps {
	title?: string;
	copy?: string;
}

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		iconContainer: {
			'& > svg': {
				maxWidth: '14rem',
				maxHeight: '14rem',
			},
		},
	})
);

const FallbackIconContainer: React.FC<FallbackIconContainerProps> = (props) => {
	const styles = useStyles();

	return <Box className={styles.iconContainer}>{props.children}</Box>;
};

export default FallbackIconContainer;
