import React, { useContext } from 'react';
import { Box, useMediaQuery, Link, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import { ApolloError } from '@apollo/client';

// Components
import List from '../../components/ListComponent';
import Select from '../../components/SelectComponent';
import ListTitle from '../../components/ListTitleComponent';
import ListTitleContainerComponent from '../../components/ListTitleContainerComponent/ListTitleContainerComponent';
import ListFooterComponent from '../../components/ListFooterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import ActivityCardComponent, {
	ActivityCardComponentProps,
} from '../../components/ActivityCardComponent';
import FallbackComponent from '../../components/FallbackComponent';

// Views
import ActivityLogModalView from '../LogModalView';

// Utils
import { prettifyCaseEnum } from '../../utils/text';
import { useParams } from 'react-router';

// Contexts
import ModalContext, { ModalType } from '../../contexts/ModalContext';

// Types
import { ListLogsQuery } from './query.generated';
import { LogType } from '../../graphql/types';
import theme from '../../styles/theme';
import { TrmLevels } from '../../types';
import {
	DurationFilter,
	TypeFilter,
	filterItems,
} from './ActivityLogViewContainer';

type Type = LogType | 'all';

export type ActivityLogViewProps = {
	loading: boolean;
	error?: ApolloError;
	data: ListLogsQuery['listLogs'];
	numberOfPages: number;
	currentPage: number;
	onPaginationChange: (value: number) => void;
	reduceUi?: boolean;
	changeType: (value: LogType | 'all') => void;
	changeSearchTerm: (value: any) => void;
	changeDuration: (value: string) => void;
	durationFilter: DurationFilter;
	typeFilter: TypeFilter;
	showFullLog?: boolean;
};

const ActivityLogView: React.FC<ActivityLogViewProps> = (props) => {
	const { open } = useContext(ModalContext);

	const filterIsActive = [
		props.durationFilter.value,
		props.typeFilter.value,
	].some((val) => val !== 'all');

	const youngPersonId = useParams<{ youngPersonId?: string }>()?.youngPersonId;

	const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box>
			<Box
				display="flex"
				flexDirection={isMobile && 'column'}
				justifyContent="space-between"
				width="100%"
				paddingBottom={theme.spacing('md')}
				data-item="activity-log-header"
			>
				{!props.reduceUi && (
					<ListTitleContainerComponent>
						<ListTitle label="Activity Log" />
					</ListTitleContainerComponent>
				)}
				<Box
					display="flex"
					width="100%"
					flexDirection={isMobile && 'column'}
					style={{
						gridGap: `${theme.spacing('xs')} ${theme.spacing(
							isTablet ? 'xs2' : 'xs'
						)}`,
					}}
				>
					<Select
						label={props.durationFilter.label}
						value={props.durationFilter.value}
						items={filterItems.duration}
						onSelect={(event) => {
							props.changeDuration(event.target.value as string);
						}}
					/>

					<Select
						label={props.typeFilter.label}
						value={props.typeFilter.value}
						items={filterItems.type}
						onSelect={(event) => {
							props.changeType(event.target.value as Type);
						}}
					/>
					{props.showFullLog && (
						<Box
							width="100%"
							display="flex"
							justifyContent="flex-end"
							alignItems={'center'}
						>
							<Link
								style={{ cursor: 'pointer' }}
								href={`/activities/${youngPersonId}`}
							>
								<Typography variant="h4">View Full Log</Typography>
							</Link>
						</Box>
					)}
				</Box>
			</Box>
			<FallbackComponent
				isLoading={Boolean(props.loading)}
				loadingType="skeletonListSm"
				isEmpty={props.data?.length === 0}
				emptyTitle={
					filterIsActive ? 'No Matching Activities' : 'No Activities Logged'
				}
				emptyCopy={
					filterIsActive
						? 'To find an activity log try changing the filters.'
						: 'Try adding an activity and coming back later.'
				}
				hasError={Boolean(props.error)}
				errorTitle="An Error Occurred"
				errorCopy="We had a little trouble fetching the activity logs. If the problem persists please contact your service administrator."
			>
				<List size="sm">
					{props.data?.map((item) => {
						let description = 'No Data';
						let severity: ActivityCardComponentProps['severity'] = 'info';
						let date = dayjs(item?.createdAt).format('DD/MM/YYYY');
						let type =
							item?.type === LogType.MangerOnDuty
								? 'Person on Duty'
								: prettifyCaseEnum(item?.type ?? '');

						const data = JSON.parse(item?.data);

						switch (item?.type) {
							case LogType.Activity:
								description = data?.note ?? 'Unable to find Description';
								severity = 'info';
								date = dayjs(data.date).format('DD/MM/YYYY');
								break;
							case LogType.Note:
								description = data?.note ?? 'Unable to find Description';
								severity = 'info';
								date = dayjs(data.date).format('DD/MM/YYYY');
								break;
							case LogType.Location:
								description = data?.note ?? 'Unable to find Description';
								severity = 'warning';
								type = `${type} - ${prettifyCaseEnum(data?.status)}`;
								break;
							case LogType.Contact:
								description = data?.note ?? 'Unable to find Description';
								severity = 'info';
								type = `Professional Contact`;
								break;
							case LogType.CoachingSession:
								description = `Has ${
									data.status === 'noShow' ? 'not turned up to' : 'turned up to'
								} a Coaching Session covering "${data.subCategory}".`;
								severity = 'success';
								break;
							case LogType.SpinSession:
								let trmLevel = TrmLevels.filter(
									(level) => level.value === data.trmLevel
								);

								description = `${
									item.youngPerson?.name
								} has a SPIN session on the ${date} and had a TRM level of ${
									trmLevel[0]?.label ?? 'Unknown'
								}.`;
								severity = 'success';
								break;
							case LogType.Incident:
								description = data?.note ?? 'Unable to find Description';
								severity = 'error';
								date = dayjs(data.date).format('DD/MM/YYYY');
								break;
							case LogType.KidPayment:
								description = `Has paid ${data?.amountPayed} on ${dayjs(
									data.date
								).format('DD/MM/YYYY')}`;
								severity = 'info';
								date = dayjs(data.date).format('DD/MM/YYYY');
								break;
							case LogType.MangerOnDuty:
								description = data.reason;
								severity = 'info';
								date = dayjs(data.date).format('DD/MM/YYYY');
								break;
							case LogType.RiskAssessment:
								description = `${item.youngPerson?.name} has had their risk assessment updated.`;
								severity = 'info';
								date = dayjs(data.date).format('DD/MM/YYYY');
								break;
						}

						return (
							<ActivityCardComponent
								user={item?.youngPerson?.name ?? ''}
								date={date}
								description={description}
								type={type}
								house={
									props.reduceUi
										? undefined
										: item?.youngPerson?.house?.title ?? ''
								}
								severity={severity}
								handleClick={() => {
									open({
										type: ModalType.JSX,
										content: <ActivityLogModalView logId={item?.id ?? ''} />,
									});
								}}
							/>
						);
					})}
				</List>
			</FallbackComponent>
			<ListFooterComponent>
				{props.numberOfPages > 1 && (
					<PaginationComponent
						count={props.numberOfPages}
						handleChange={props.onPaginationChange}
						page={props.currentPage}
					/>
				)}
			</ListFooterComponent>
		</Box>
	);
};

export default ActivityLogView;
