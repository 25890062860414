import React, { useEffect, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';
import { Auth } from 'aws-amplify';
import { AuthState } from '@aws-amplify/ui-components';

import { User } from '../../graphql/types';
import {
	CognitoUser,
	AuthoriseProps,
	LoginProps,
	ResetPasswordProps,
	ForgotPasswordProps,
	ResendAuthoriseTokenProps,
	UpdateUserProps,
	ChangePasswordProps,
	ChallengeChangePasswordProps,
} from './types';
import { useGetMeLazyQuery } from './query.generated';
import { useApolloClient } from '@apollo/client';
import { useEffectOnce } from 'react-use';

type AuthContextProps = {
	user: User | undefined;
	cognitoUser: CognitoUser | undefined;
	loading: boolean;
	currentState: AuthState;
	authorise: (props: AuthoriseProps) => void;
	login: (props: LoginProps) => void;
	resetPassword: (props: ResetPasswordProps) => void;
	forgotPassword: (props: ForgotPasswordProps) => void;
	resendAuthoriseToken: (props: ResendAuthoriseTokenProps) => void;
	updateUser: (props: UpdateUserProps) => void;
	changePassword: (props: ChangePasswordProps) => void;
	challengeChangePassword: (props: ChallengeChangePasswordProps) => void;
	logout: () => void;
};

const defaultValue = {
	user: undefined,
	cognitoUser: undefined,
	currentState: AuthState.SignedOut,
	loading: false,
	authorise: () => console.log('authorise'),
	login: () => console.log('login'),
	logout: () => console.log('logout'),
	resetPassword: () => console.log('reset password'),
	forgotPassword: () => console.log('forgot password'),
	resendAuthoriseToken: () => console.log('forgot password'),
	updateUser: () => console.log('forgot password'),
	changePassword: () => console.log('forgot password'),
	challengeChangePassword: () => console.log('forgot password'),
};

const AuthContext = React.createContext<AuthContextProps>(defaultValue);

const AuthContextProvider: React.FC = ({ children }) => {
	const [authState, setAuthState] = useState<AuthState>(AuthState.SignedOut);
	const client = useApolloClient();
	// const [group, setGroup] = useState<CognitoGroup | undefined | null>(null);
	const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
	// Will be used to log users in after they complete certain actions
	const [tempEmail, setTempEmail] = useState<string | undefined>(undefined);
	const [tempPassword, setTempPassword] = useState<string | undefined>(
		undefined
	);

	const [fetchUserData, { data, loading, error }] = useGetMeLazyQuery();
	const [startingUp, setStartingUp] = useState(true);

	useEffectOnce(() => {
		loadExistingCognitoUser();
	});

	const loadExistingCognitoUser = async () => {
		try {
			const user = await Auth.currentAuthenticatedUser();

			if (user) {
				unstable_batchedUpdates(() => {
					setCognitoUser(user);
					setAuthState(AuthState.SignedIn);
					fetchUserData();
				});
			}
		} catch {
			console.log('Cannot loadExistingCognitoUser at the moment');
		} finally {
			if (startingUp) {
				setStartingUp(false);
			}
		}
	};

	/**
	 * Signs out the user and clears DataStore
	 * @return void
	 */
	const logout = async () => {
		await Auth.signOut();

		unstable_batchedUpdates(() => {
			setCognitoUser(undefined);
			setAuthState(AuthState.SignedOut);
			setTempEmail(undefined);
			setTempPassword(undefined);
		});
		try {
			await client.clearStore();
		} catch (error) {
			console.log("Couldn't reset the store", error);
		}
	};

	/**
	 * Allows the user to login
	 * @param props LoginProps
	 * @return void
	 */
	const login = async (props: LoginProps) => {
		try {
			const user = await Auth.signIn(props);
			if (!user) throw new Error('No user found');
			if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
				unstable_batchedUpdates(() => {
					setAuthState(AuthState.confirmingSignUpCustomFlow);
					setCognitoUser(user);
					setTempEmail(props.username);
				});
				throw new Error('User Change Password');
			}
			await loadExistingCognitoUser();
			unstable_batchedUpdates(() => {
				setTempEmail('');
				setTempPassword('');
			});
		} catch (error) {
			console.error(error);

			// This is to handle the first time an invited user has logged in and force them to update their password
			// @ts-expect-error
			if (error.message === 'User Change Password') {
				throw error;
			}

			let errorMessage = 'Something went wrong, try again later.';
			// @ts-expect-error
			switch (error?.code) {
				case 'InvalidParameterException':
					errorMessage = 'Please enter valid credentials.';
					break;
				case 'NotAuthorizedException':
					errorMessage =
						'Email Address and Password did not match our records.';
					break;
				case 'UserNotFoundException':
					errorMessage = 'No user was found with that email address.';
					break;
				case 'UserNotConfirmedException':
					unstable_batchedUpdates(() => {
						setTempEmail(props.username);
						setTempPassword(props.password);
						setAuthState(AuthState.VerifyContact);
					});
					// This needs to throw the error so that the login form can also manage this state
					throw error;
			}

			throw new Error(errorMessage);
		}
	};

	const challengeChangePassword = async (
		props: ChallengeChangePasswordProps
	) => {
		try {
			await Auth.completeNewPassword(cognitoUser, props.newPassword);
			setCognitoUser(undefined);

			await login({
				username: tempEmail ?? '',
				password: props.newPassword,
			});
		} catch (error) {
			console.log('ERROR!', error);
			let errorMessage = 'Something went wrong, try again later.';

			// @ts-expect-error
			switch (error?.code) {
				case 'NotAuthorizedException':
					// @ts-expect-error
					errorMessage = error.message;
					break;
			}
			throw new Error(errorMessage);
		}
	};

	/**
	 * Send Forgotten password email to user
	 * @param props ForgotPasswordProps
	 * @return void
	 */
	const forgotPassword = async (props: ForgotPasswordProps) => {
		try {
			await Auth.forgotPassword(props.email);
			setTempEmail(props.email);
		} catch (error) {
			console.error(error);
			let errorMessage = 'Something went wrong, try again later.';
			// @ts-expect-error
			switch (error?.code) {
				case 'NotAuthorizedException':
					errorMessage =
						'It looks like your account needs to be looked at by an administrator. Please get in touch with support.';
					break;
				case 'UserNotFoundException':
					errorMessage =
						'Apologies, no user with that email address could be found.';
					break;
				case 'InvalidParameterException':
					errorMessage =
						'Apologies, no user with that email address could be found.';
					break;
			}
			throw new Error(errorMessage);
		}
	};

	/**
	 * Reset the users password using the code that was sent to them
	 * @param props ResetPasswordProps
	 * @return void
	 */
	const resetPassword = async (props: ResetPasswordProps) => {
		try {
			await Auth.forgotPasswordSubmit(
				tempEmail ?? props.email ?? '',
				props.code,
				props.password
			);
			await login({
				username: tempEmail ?? props.email ?? '',
				password: props.password,
			});
		} catch (error) {
			console.error(error);
			let errorMessage = 'Something went wrong, try again later.';
			// @ts-expect-error
			switch (error?.code) {
				case 'UserNotFoundException':
					errorMessage =
						'Apologies, no user with that email address could be found.';
					break;
				case 'CodeMismatchException':
					errorMessage =
						'The code entered does not match the one that was sent via email.';
					break;
			}

			throw new Error(errorMessage);
		} finally {
			setTempEmail(undefined);
		}
	};

	/**
	 * Update the users cognito parameters
	 * @param props
	 */
	const updateUser = async (props: UpdateUserProps) => {
		try {
			const currentUser = await Auth.currentUserPoolUser();
			await Auth.updateUserAttributes(currentUser, props);
		} catch (error) {
			console.error(error);
			let errorMessage = 'Something went wrong, try again later.';
			// @ts-expect-error
			switch (error?.code) {
				case 'AliasExistsException':
					errorMessage =
						'The email you have provided is inuse on another account.';
					break;
				case 'PasswordResetRequiredException':
					errorMessage = 'Password Reset is required, please contact support.';
					break;
				case 'UserNotConfirmedException':
					errorMessage =
						'Your account has not been confirmed, please contact support.';
					break;
				case 'UserNotFoundException':
					errorMessage = 'Your account was not found, please contact support.';
					break;
			}

			throw new Error(errorMessage);
		}
	};

	const changePassword = async (props: ChangePasswordProps) => {
		try {
			// Grab the current user sessions
			const currentUser = await Auth.currentUserPoolUser();

			await Auth.changePassword(
				currentUser,
				props.oldPassword,
				props.newPassword
			);
		} catch (error) {
			console.error(error);
			let errorMessage = 'Something went wrong, try again later.';
			// @ts-expect-error
			switch (error?.code) {
				case 'InvalidPasswordException':
					errorMessage = 'Apologies, the password you have entered is invalid.';
					break;
				case 'PasswordResetRequiredException':
					errorMessage = 'Password Reset is required, please contact support.';
					break;
				case 'UserNotConfirmedException':
					errorMessage =
						'Your account has not been confirmed, please contact support.';
					break;
				case 'UserNotFoundException':
					errorMessage = 'Your account was not found, please contact support.';
					break;
			}

			throw new Error(errorMessage);
		}
	};

	/**
	 * Verifies the code that was sent to the users email
	 * @param props AuthorizeProps
	 * @return void
	 */
	const authorise = async (props: AuthoriseProps) => {
		try {
			await Auth.confirmSignUp(tempEmail ?? props.email ?? '', props.code);
			await login({
				username: tempEmail ?? props.email ?? '',
				password: tempPassword ?? props.password ?? '',
			});
		} catch (error) {
			console.error(error);
			let errorMessage = 'Something went wrong, try again later.';
			// @ts-expect-error
			switch (error?.code) {
				case 'CodeMismatchException':
					errorMessage =
						'That Authorisation Code seems to be incorrect, try again.';
					break;
			}
			throw new Error(errorMessage);
		} finally {
			unstable_batchedUpdates(() => {
				setTempEmail(undefined);
				setTempPassword(undefined);
			});
		}
	};

	const resendAuthoriseToken = async (props: ResendAuthoriseTokenProps) => {
		try {
			await Auth.resendSignUp(tempEmail ?? props.email ?? '');
		} catch (error) {
			console.error(error);
			let errorMessage = 'Something went wrong, try again later.';

			// TODO: Need to go through the possible errors and add friendly copy to handle the errors

			throw new Error(errorMessage);
		}
	};
	return (
		<AuthContext.Provider
			value={{
				user: data?.me ?? undefined,
				cognitoUser: cognitoUser,
				loading: loading || startingUp,
				currentState: authState,
				resendAuthoriseToken,
				updateUser,
				login,
				logout,
				authorise,
				forgotPassword,
				resetPassword,
				changePassword,
				challengeChangePassword,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContextProvider };

export default AuthContext;
