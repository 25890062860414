// Query
import {
	// Get Details
	GetYoungPersonsMedicalConditionDocument,
	GetYoungPersonsMedicalConditionQuery,
	GetYoungPersonsMedicalConditionQueryVariables,
	// Update Details Mutation
	UpdateMedicalConditionDocument,
	UpdateMedicalConditionMutation,
	UpdateMedicalConditionMutationVariables,
	// Create
	CreateMedicalConditionDocument,
	CreateMedicalConditionMutation,
	CreateMedicalConditionMutationVariables,
} from './query.generated';
import { YoungPersonFragmentDoc } from '../../../../graphql/hooks';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient } from '@apollo/client';
import { YpMedicalConditionArgs } from '../../types';

type FormValues = {
	title: string;
	symptoms: string;
	triggers: string;
	medication: string;
	administrationDetails: string;
	inEmergency: string;
};

const PreFetch = async (
	args: YpMedicalConditionArgs,
	client: ApolloClient<object>
) => {
	if (!args.medicalConditionId) return undefined;

	const details = await client.query<
		GetYoungPersonsMedicalConditionQuery,
		GetYoungPersonsMedicalConditionQueryVariables
	>({
		query: GetYoungPersonsMedicalConditionDocument,
		variables: {
			medicalConditionId: args.medicalConditionId ?? '',
		},
	});

	if (!details.data.getMedicalCondition || details.error)
		throw new Error('Pre-fetched Data Failed');

	return details.data.getMedicalCondition;
};

type Data =
	| GetYoungPersonsMedicalConditionQuery['getMedicalCondition']
	| undefined;

const FormFields = (
	args: YpMedicalConditionArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'title',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Condition Name:',
				defaultValue: data?.title ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'symptoms',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Symptoms:',
				defaultValue: data?.symptoms ?? '',
				maxRows: 4,
				multiline: true,
			},
		},
		{
			id: 'triggers',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Triggers:',
				defaultValue: data?.triggers ?? '',
				maxRows: 4,
				multiline: true,
			},
		},
		{
			id: 'medication',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Medication:',
				defaultValue: data?.medication ?? '',
				maxRows: 4,
				multiline: true,
			},
		},
		{
			id: 'administrationDetails',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Medication Administration Details:',
				defaultValue: data?.administrationDetails ?? '',
				maxRows: 4,
				multiline: true,
			},
		},
		{
			id: 'inEmergency',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'In Case of Emergency:',
				defaultValue: data?.inEmergency ?? '',
				maxRows: 4,
				multiline: true,
			},
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: YpMedicalConditionArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: FormValues): Promise<any> => {
		if (args.medicalConditionId) {
			const updateMedicalCondition = await client.mutate<
				UpdateMedicalConditionMutation,
				UpdateMedicalConditionMutationVariables
			>({
				mutation: UpdateMedicalConditionDocument,
				variables: {
					medicalConditionId: args.medicalConditionId ?? '',
					data: formValues,
				},
			});
			return updateMedicalCondition;
		} else {
			const createMedicalCondition = await client.mutate<
				CreateMedicalConditionMutation,
				CreateMedicalConditionMutationVariables
			>({
				mutation: CreateMedicalConditionDocument,
				variables: {
					data: {
						...formValues,
						youngPersonId: args.youngPersonId ?? '',
						createdBy: userId,
					},
				},
				refetchQueries: [YoungPersonFragmentDoc, 'GetYoungPerson'],
			});

			return createMedicalCondition;
		}
	};
	return onSubmit;
};

const MedicalConditionForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default MedicalConditionForm;
