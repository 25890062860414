import * as React from 'react';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import { createStyles, makeStyles, Theme, withTheme } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ChevronRight from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		alert: {
			'@media print': {
				'& *': {
					background: 'white',
					color: 'black',
					display: 'block',
				},
			},
			width: '100%',
			padding: 0,
			margin: 0,
			'& [class*="MuiAlert-message"]': {
				'@media print': {
					display: 'block',
				},
				display: 'flex',
				width: '100%',
				gap: theme.spacing('sm'),
				padding: theme.spacing('xs'),
				justifyContent: 'space-between',
				alignItems: 'flex-start',
			},
			overflowY: 'auto',
		},
		text: {
			maxWidth: theme.spacing(20),
			flex: 1,
			textAlign: 'left',
		},
		description: {
			flex: 3,
			whiteSpace: 'pre-wrap',
		},
		icon: {
			color: 'inherit',
		},
		bold: {
			fontWeight: 600,
		},
	})
);

export type ActivityCardComponentProps = {
	date: string;
	type: string;
	description: string;
	user: string;
	house?: string;
	severity: AlertProps['severity'];
	handleClick: () => void;
};

const ActivityCardComponent: React.FC<ActivityCardComponentProps> = (props) => {
	const styles = useStyles();

	return (
		<Alert severity={props.severity} className={styles.alert} icon={false}>
			<Typography className={styles.text} component="span">
				{props.date}
			</Typography>
			<Typography className={`${styles.text} ${styles.bold}`} component="span">
				{props.type}
			</Typography>
			<Typography className={styles.description} component="span">
				{props.description}
			</Typography>
			<Typography className={styles.text} component="span">
				{props.user}
			</Typography>
			{props.house ? (
				<Typography className={styles.text} component="span">
					{props.house}
				</Typography>
			) : null}
			<IconButton size="small" onClick={props.handleClick}>
				<ChevronRight className={styles.icon} />
			</IconButton>
		</Alert>
	);
};

export default ActivityCardComponent;
