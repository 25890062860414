import {
	createStyles,
	Grid,
	makeStyles,
	Theme,
	Typography,
	Box,
} from '@material-ui/core';
import React from 'react';

export type DictionaryComponentProps = {
	label?: string;
	value?: string;
	isFullWidth?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			marginBottom: theme.spacing('xs'),
		},
		bold: {
			fontWeight: theme.typography.fontWeightBold,
		},
	})
);

const DictionaryComponent: React.FC<DictionaryComponentProps> = (props) => {
	const styles = useStyles();
	const column = props.isFullWidth ? 12 : 4;

	return (
		<Grid container className={styles.container}>
			<Grid item sm={column}>
				<Typography className={styles.bold} variant="body1">
					{props.label}
				</Typography>
			</Grid>
			{props.isFullWidth ? (
				<Box paddingTop={0.75}>
					<Typography color="textSecondary" style={{ whiteSpace: 'pre-wrap' }}>
						{props.value}
					</Typography>
				</Box>
			) : (
				<Grid item md={column}>
					<Typography
						color={props.isFullWidth ? 'textSecondary' : 'textPrimary'}
						style={{ whiteSpace: 'pre-wrap' }}
					>
						{props.value}
					</Typography>
				</Grid>
			)}
		</Grid>
	);
};

export default DictionaryComponent;
