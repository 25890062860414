import * as React from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Check from '@material-ui/icons/Check';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';

const useStyles = makeStyles<Theme, { checked: boolean }>((theme) =>
	createStyles({
		root: {
			// width: theme.spacing(35),
			width: '100%',
			flexShrink: 0,
			flexGrow: 1,
			padding: `${theme.spacing('md')} ${theme.spacing('sm')}`,
			borderRadius: theme.shape.borderRadius,
			cursor: 'pointer',
			backgroundColor: (props) =>
				props.checked ? theme.palette.grey[100] : '#fff',
			'&:active': {
				opacity: 0.6,
			},
		},
		label: {
			paddingLeft: theme.spacing('xs2'),
			paddingRight: theme.spacing('xs2'),
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
		},
		icon: {
			backgroundColor: (props) =>
				props.checked
					? theme.palette.primary.pale
					: theme.palette.secondary.pale,
		},
	})
);

export type SelectCardProps = {
	label: string;
	onClick?: () => void;
	checked: boolean;
};

const SelectCardComponent: React.FC<SelectCardProps> = (props) =>
	props.checked ? (
		<SelectCardChecked {...props} />
	) : (
		<SelectCardUnchecked {...props} />
	);

const SelectCardUnchecked: React.FC<SelectCardProps> = (props) => {
	const styles = useStyles({ checked: false });
	const theme = useTheme();

	return (
		<Card elevation={2} className={styles.root} onClick={props.onClick}>
			<Box
				display="flex"
				justifyContent="flex-start"
				alignItems="center"
				gridColumnGap={theme.spacing('xs')}
			>
				<IconButton
					size="small"
					color="secondary"
					className={styles.icon}
					onClick={props.onClick}
				>
					<ChevronRight />
				</IconButton>
				<Typography variant="h3" className={styles.label}>
					{props.label}
				</Typography>
			</Box>
		</Card>
	);
};

const SelectCardChecked: React.FC<SelectCardProps> = (props) => {
	const styles = useStyles({ checked: true });
	const theme = useTheme();

	return (
		<Card elevation={0} className={styles.root} onClick={props.onClick}>
			<Box
				display="flex"
				justifyContent="flex-start"
				alignItems="center"
				gridColumnGap={theme.spacing('xs')}
			>
				<IconButton
					size="small"
					color="primary"
					className={styles.icon}
					onClick={props.onClick}
				>
					<Check />
				</IconButton>
				<Typography variant="h3" className={styles.label}>
					{props.label}
				</Typography>
			</Box>
		</Card>
	);
};

export default SelectCardComponent;
