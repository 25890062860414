import { ApolloClient } from '@apollo/client';
import dayjs from 'dayjs';

import {
	FormFieldsComponentProps,
	FormFieldTypes,
} from '../../../../components/FormFieldsComponent';
import { ListTasksDocument } from '../../../../graphql/hooks';
import { RepeatOption, TaskCreateInput } from '../../../../graphql/types';
import { TaskCreateArgs } from '../../types';
import { Typography } from '@material-ui/core';
import {
	// Create
	CreateNewTaskDocument,
	CreateNewTaskMutation,
	CreateNewTaskMutationVariables,
	// Update
	UpdateTaskDocument,
	UpdateTaskMutation,
	UpdateTaskMutationVariables,
	// House
	GetTaskCreateFormDataByHouseIdDocument,
	GetTaskCreateFormDataByHouseIdQuery,
	GetTaskCreateFormDataByHouseIdQueryVariables,
	// YoungPerson
	GetTaskCreateFormDataByYoungPersonIdDocument,
	GetTaskCreateFormDataByYoungPersonIdQuery,
	GetTaskCreateFormDataByYoungPersonIdQueryVariables,
} from './query.generated';

import { GetTaskWithUserDocument } from '../../../../graphql/hooks';

import {
	GetTaskWithUserQuery,
	GetTaskWithUserQueryVariables,
} from '../../../../graphql/types';

export type TaskCreateFormValues = {} & TaskCreateInput;

const PreFetch = async (args: TaskCreateArgs, client: ApolloClient<object>) => {
	let houseData: any = undefined;
	let youngPersonData: any = undefined;
	let taskData: any = undefined;
	if (args.houseId) {
		const { data, error } = await client.query<
			GetTaskCreateFormDataByHouseIdQuery,
			GetTaskCreateFormDataByHouseIdQueryVariables
		>({
			query: GetTaskCreateFormDataByHouseIdDocument,
			variables: {
				houseId: args.houseId,
			},
		});
		if (!data.getHouse || error) throw new Error('Pre-fetched Data Failed');
		houseData = data.getHouse;
	}

	if (args.youngPersonId) {
		const { data, error } = await client.query<
			GetTaskCreateFormDataByYoungPersonIdQuery,
			GetTaskCreateFormDataByYoungPersonIdQueryVariables
		>({
			query: GetTaskCreateFormDataByYoungPersonIdDocument,
			variables: {
				youngPersonId: args.youngPersonId,
			},
		});
		if (!data.getYoungPerson || error)
			throw new Error('Pre-fetched Data Failed');
		youngPersonData = data.getYoungPerson;
	}

	if (args.taskId) {
		const { data, error } = await client.query<
			GetTaskWithUserQuery,
			GetTaskWithUserQueryVariables
		>({
			query: GetTaskWithUserDocument,
			variables: {
				id: args.taskId,
			},
		});
		if (!data.getTask || error) throw new Error('Pre-fetched Data Failed');
		taskData = data.getTask;
	}

	return {
		youngPerson: youngPersonData,
		houses: houseData,
		task: taskData,
	};
};

type Data = {
	youngPerson?: GetTaskCreateFormDataByYoungPersonIdQuery['getYoungPerson'];
	houses?: GetTaskCreateFormDataByHouseIdQuery['getHouse'];
	task?: GetTaskWithUserQuery['getTask'];
};

const FormFields = (
	args: TaskCreateArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const youngPeopleOptions = data.houses?.youngPeople?.map?.((yp) => ({
		value: yp.id,
		copy: yp.name,
	}));

	const userOptions = data.houses?.users?.map((user) => ({
		value: user.id,
		copy: user.name ?? '',
	}));

	// const params = useParams<Params>();

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'title',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Title:',
				defaultValue: data.task?.title ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'description',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Description:',
				defaultValue: data.task?.description ?? '',
			},
			validation: {
				required: false,
			},
		},
		{
			id: 'dueDate',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Due Date:',
				defaultValue: data.task?.dueDate
					? dayjs(data.task?.dueDate).format('YYYY-MM-DD')
					: dayjs().format('YYYY-MM-DD'),
			},
			validation: {
				required: false,
			},
		},
		{
			id: 'youngPersonId',
			type: FormFieldTypes.SELECT,
			label: 'Kid:',
			config: {
				defaultValue: args.youngPersonId ?? data.task?.youngPerson?.id,
				disabled: !!args.youngPersonId,
				hidden: args.youngPersonId !== undefined,
			},
			options:
				youngPeopleOptions ??
				(data.youngPerson
					? [{ value: data.youngPerson.id, copy: data.youngPerson.name }]
					: []),
		},
		{
			id: 'assignedUserId',
			type: FormFieldTypes.SELECT,
			label: 'Assign to:',
			config: {
				defaultValue: data.task?.assignedTo?.id ?? undefined,
				// disabled: !!params.youngPersonId,
			},
			options: userOptions ?? [],
		},
		{
			id: 'text',
			type: FormFieldTypes.CUSTOM,
			render: (
				<Typography
					style={{
						textAlign: 'left',
						width: '100%',
						marginBottom: '12px',
						marginTop: '-6px',
					}}
				>
					If left empty, all users in this house will be assigned.
				</Typography>
			),
		},
		{
			id: 'repeat',
			type: FormFieldTypes.SELECT,
			label: 'Repeat:',
			config: {
				label: 'Repeat:',
				defaultValue: data?.task?.repeat,
			},
			validation: {
				required: false,
			},
			options: [
				{
					value: undefined,
					copy: 'None',
				},
				{
					value: RepeatOption.Daily,
					copy: 'Daily',
				},
				{
					value: RepeatOption.Weekly,
					copy: 'Weekly',
				},
				{
					value: RepeatOption.Monthly,
					copy: 'Monthly',
				},
				{
					value: RepeatOption.Yearly,
					copy: 'Yearly',
				},
			],
		},
		{
			id: 'repeatEnd',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Repeat end date:',
				defaultValue:
					dayjs(data.task?.repeatEnd).format('YYYY-MM-DD') ?? undefined,
			},
			validation: {
				required: false,
			},
		},
		// TODO: Doesn't work commented out for now until we figure out how to handle this
		// {
		// 	id: 'isPrivate',
		// 	type: FormFieldTypes.SELECT,
		// 	label: 'Private:',
		// 	config: {
		// 		defaultValue: data.task?.isPrivate ?? 0,
		// 	},
		// 	options: [
		// 		{ copy: 'No', value: 0 },
		// 		{ copy: 'Yes', value: 1 },
		// 	],
		// },
		{
			id: 'isAdmin',
			type: FormFieldTypes.SELECT,
			label: 'Only Admins:',
			config: {
				defaultValue: data.task?.isAdmin ?? 0,
			},
			options: [
				{ copy: 'No', value: 0 },
				{ copy: 'Yes', value: 1 },
			],
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: TaskCreateArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: TaskCreateFormValues): Promise<any> => {
		if (args.taskId) {
			const createTask = await client.mutate<
				UpdateTaskMutation,
				UpdateTaskMutationVariables
			>({
				mutation: UpdateTaskDocument,
				awaitRefetchQueries: true,
				refetchQueries: [ListTasksDocument, 'ListTasks'],
				variables: {
					id: args.taskId,
					data: {
						...formValues,
						houseId: formValues.houseId ?? args.houseId,
						isPrivate: false,
						isAdmin: formValues.isAdmin ? true : false,
						youngPersonId: formValues.youngPersonId,
						dueDate:
							formValues.dueDate && formValues.dueDate !== 'Invalid Date'
								? dayjs(formValues.dueDate).toISOString()
								: undefined,
						repeatEnd:
							formValues.repeatEnd && formValues.repeatEnd !== 'Invalid Date'
								? dayjs(formValues.repeatEnd).toISOString()
								: undefined,
					},
				},
			});

			return createTask;
		} else {
			const createTask = await client.mutate<
				CreateNewTaskMutation,
				CreateNewTaskMutationVariables
			>({
				mutation: CreateNewTaskDocument,
				awaitRefetchQueries: true,
				refetchQueries: [ListTasksDocument, 'ListTasks'],
				variables: {
					data: {
						...formValues,
						houseId: args?.houseId ?? formValues.houseId,
						createdUserId: userId,
						isPrivate: formValues.isPrivate ? true : false,
						isAdmin: formValues.isAdmin ? true : false,
						youngPersonId:
							args?.youngPersonId ?? formValues.youngPersonId ?? undefined,
						dueDate: formValues.dueDate
							? dayjs(formValues.dueDate).toISOString()
							: undefined,
						repeatEnd: formValues.repeatEnd
							? dayjs(formValues.repeatEnd).toISOString()
							: undefined,
					},
				},
			});

			return createTask;
		}
	};

	return onSubmit;
};

const TaskCreateForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default TaskCreateForm;
