// Query
import {
	CreateHouseDocument,
	CreateHouseMutation,
	CreateHouseMutationVariables,
} from './query.generated';
import { ListHousesWithYoungPeopleDocument } from '../../../../graphql/hooks';
import { colors } from '../../../../styles/palettes';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient } from '@apollo/client';
import { CreateHouseArgs } from '../../types';

export type CreateHouseFormValues = {
	title: string;
	color: string;
	location: Location;
	phone: string;
};

type Location = {
	Label: string;
	Point: Number[];
};

const PreFetch = async (
	args: CreateHouseArgs,
	client: ApolloClient<object>
) => {
	return {};
};

type Data = {};

const FormFields = (
	args: CreateHouseArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'title',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'House Name:',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'phone',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Phone:',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'location',
			type: FormFieldTypes.LOCATION,
			config: {
				label: 'Location',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'color',
			type: FormFieldTypes.SELECT,
			label: 'House Colour:',
			config: {},
			validation: {
				required: true,
			},
			options: colors.map((color) => ({
				value: color.main,
				copy: color.label,
			})),
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: CreateHouseArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: CreateHouseFormValues): Promise<any> => {
		const createMoveOutHouse = await client.mutate<
			CreateHouseMutation,
			CreateHouseMutationVariables
		>({
			mutation: CreateHouseDocument,
			variables: {
				data: {
					...{ ...formValues, location: undefined },
					title: `Moved Out - ${formValues.title}`,
					isMoveOutHouse: true,
					address: formValues.location.Label,
					lat: formValues.location.Point[1].toString(),
					long: formValues.location.Point[0].toString(),
					// TODO: [STLL-309] Need to add the organization ID
					organisationId: '616eaa99767556b7290a0cf3',
				},
			},
			refetchQueries: [{ query: ListHousesWithYoungPeopleDocument }],
		});
		const createHouse = await client.mutate<
			CreateHouseMutation,
			CreateHouseMutationVariables
		>({
			mutation: CreateHouseDocument,
			variables: {
				data: {
					...{ ...formValues, location: undefined },
					address: formValues.location.Label,
					lat: formValues.location.Point[1].toString(),
					long: formValues.location.Point[0].toString(),
					// TODO: [STLL-309] Need to add the organization ID
					organisationId: '616eaa99767556b7290a0cf3',
					moveOutHouseId: createMoveOutHouse.data?.createHouse?.id ?? undefined,
				},
			},
			refetchQueries: [{ query: ListHousesWithYoungPeopleDocument }],
		});

		return createHouse;
	};
	return onSubmit;
};

const LogIncidentForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default LogIncidentForm;
