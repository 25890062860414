import React from 'react';

import { useContext } from 'react';
import { useParams } from 'react-router';

// Contexts
import AuthContext from '../../contexts/authContext';
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { FormType } from '../../graphql/types';

// Query
import {
	useCompleteTaskMutation,
	useDeleteTaskMutation,
	useGetTaskWithUserQuery,
	useUnCompleteTaskMutation,
} from './query.generated';

// Views
import ReadTaskView from './ReadTaskView';

// Components
import DialogDeleteWarningComponent from '../../components/DialogDeleteWarningComponent';

type ReadTaskViewContainerProps = {
	id: string;
};
type Params = {
	taskId: string;
};
const ReadTaskViewContainer: React.FC<ReadTaskViewContainerProps> = (props) => {
	const { taskId } = useParams<Params>();
	const { user } = useContext(AuthContext);
	const { open, close } = useContext(ModalContext);
	const [deleteWarningIsOpen, setDeleteWarningIsOpen] = React.useState(false);

	const { data, loading, error } = useGetTaskWithUserQuery({
		variables: {
			id: taskId ?? props.id,
		},
	});

	const [completeTask] = useCompleteTaskMutation();
	const [unCompleteTask] = useUnCompleteTaskMutation();
	const [deleteTask] = useDeleteTaskMutation();

	const onComplete = async () => {
		if (!user?.id) {
			return;
		}

		if (!data?.getTask?.id) {
			return;
		}

		if (data.getTask.completedAt) {
			await unCompleteTask({
				variables: {
					taskId: data.getTask.id,
				},
			});
		} else {
			await completeTask({
				variables: {
					taskId: data.getTask.id,
					completedBy: user?.id,
					completedAt: new Date().toISOString(),
				},
			});
		}
	};

	const onEditClick = () => {
		close();
		if (data?.getTask?.id) {
			open(
				{
					type: ModalType.FORM,
					args: {
						type: FormType.TaskCreate,
						taskId: data?.getTask?.id,
						houseId: data?.getTask?.houseId ?? undefined,
					},
				},
				{ warnOnEscape: true }
			);
		}
	};

	const onDeleteClick = async () => {
		setDeleteWarningIsOpen(true);
	};

	const tryDeleteTask = async () => {
		try {
			if (!data?.getTask?.id) return;
			await deleteTask({ variables: { taskId: data?.getTask?.id } });
			close();
		} catch (e) {
			console.error(e);
		} finally {
			setDeleteWarningIsOpen(false);
		}
	};

	return (
		<>
			<ReadTaskView
				data={data}
				loading={loading}
				error={error}
				onComplete={onComplete}
				onEditClick={onEditClick}
				onDeleteClick={onDeleteClick}
			/>
			<DialogDeleteWarningComponent
				isOpen={deleteWarningIsOpen}
				onDelete={tryDeleteTask}
				onGoBack={() => setDeleteWarningIsOpen(false)}
			/>
		</>
	);
};

export default ReadTaskViewContainer;
