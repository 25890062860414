import { useContext, useEffect } from 'react';
import { useParams } from 'react-router';
import { usePagination } from '../../hooks/usePagination';
import { useGetYoungPeopleAndLocationStatusLazyQuery } from '../../graphql/hooks';
import { YoungPersonLocationStatusType, FormType } from '../../graphql/types';
import { useUpdateYpStatusMutation } from './query.generated';
import YoungPersonWhereaboutView from './YoungPersonWhereaboutView';
import { LocationFilterType } from '../../types';
import ModalContext, { ModalType } from '../../contexts/ModalContext';

type YoungPersonWhereaboutViewContainerProps = {
	locationFilter?: LocationFilterType;
	limit?: number;
	skip?: number;
};

type Params = {
	houseId?: string;
};

const YoungPersonWhereaboutViewContainer: React.FC<YoungPersonWhereaboutViewContainerProps> =
	(props) => {
		const params = useParams<Params>();
		const modal = useContext(ModalContext);

		const {
			currentPage,
			take,
			skip,
			handlePaginate,
			pageCount,
			isFirstPage,
			isLastPage,
			setCount,
		} = usePagination({
			take: 4,
			count: 0,
		});

		const [fetchYp, { data, loading, error }] =
			useGetYoungPeopleAndLocationStatusLazyQuery({
				variables: {
					take,
					skip,
					where: {
						houseId: { equals: params.houseId ?? undefined },
						locationStatusId: props.locationFilter?.value
							? { in: [props.locationFilter?.value] }
							: undefined,
					},
				},
			});

		useEffect(() => {
			if (!data?.countYoungPeople) return;

			setCount(data.countYoungPeople);
		}, [data]);

		const [updateLocationStatus] = useUpdateYpStatusMutation();

		useEffect(() => {
			fetchYp({
				variables: {
					take,
					skip,
					where: {
						houseId: { equals: params.houseId ?? undefined },
						locationStatusId: props.locationFilter?.value
							? { in: [props.locationFilter?.value] }
							: undefined,
					},
				},
			});
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [props.locationFilter, props.limit, props.skip, params.houseId, skip]);

		if (error) {
			console.log(`error.message`, error.message);
		}

		const handleOnChangeStatus = async (
			youngPersonId: string,
			statusId: string
		) => {
			if (!youngPersonId || !statusId) return;

			const newStatus = data?.listYoungPersonLocationStatuses?.find(
				(status) => status?.id === statusId
			);
			const yp = data?.listYoungPeople?.find((yp) => yp?.id === youngPersonId);

			// Don't trigger a status change if there is no change in their status
			if (newStatus === yp?.locationStatus) return;

			// If the young person changes to Out / Unauthorized / Authorized open to make a note of this before changing status
			if (
				newStatus?.type === YoungPersonLocationStatusType.Out ||
				newStatus?.type === YoungPersonLocationStatusType.Authorised ||
				newStatus?.type === YoungPersonLocationStatusType.Unauthorised ||
				newStatus?.type === YoungPersonLocationStatusType.Missing ||
				yp?.locationStatus?.title === 'Missing'
			) {
				return modal.open({
					type: ModalType.FORM,
					args: {
						type: FormType.LogLocation,
						youngPersonId: yp?.id,
						updatedStatusId: newStatus?.id ?? '',
						updatedStatusType: newStatus?.type ?? '',
					},
				});
			}
			// If the user is missing log the time
			let missingAt = null;

			await updateLocationStatus({
				variables: {
					id: youngPersonId,
					locationStatusId: statusId,
					missingAt,
				},
				optimisticResponse: {
					updateYoungPerson: {
						...yp,
						id: youngPersonId,
						name: yp?.name ?? '',
						locationStatusId: statusId,
						locationStatus: newStatus,
						missingAt,
					},
				},
			});
		};

		return (
			<YoungPersonWhereaboutView
				data={data}
				loading={loading}
				error={error}
				currentPage={currentPage}
				pageCount={pageCount}
				youngPeopleCount={data?.countYoungPeople ?? 0}
				handleOnChangeStatus={handleOnChangeStatus}
				handlePaginate={handlePaginate}
				isFirstPage={isFirstPage}
				isLastPage={isLastPage}
			/>
		);
	};

export default YoungPersonWhereaboutViewContainer;
