import React from 'react';
import { FormType, YoungPersonStatus } from '../../graphql/types';
import AdminLayout from '../../layouts/AdminLayout';
import YoungPeopleView from '../../views/YoungPeopleView';

export type AdminListYoungPeopleScreenProps = {};

type FilterStatus = YoungPersonStatus | 'All Statuses';

type FilterStatusOptions = {
	value: FilterStatus;
	label: string;
}[];

const AdminListYoungPeopleScreen: React.FC<AdminListYoungPeopleScreenProps> = (
	props
) => {
	const [searchTerm, setSearchTerm] = React.useState('');
	const [filter, setFilter] = React.useState<FilterStatus>('All Statuses');

	const onSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
		setSearchTerm(e.target.value);

	const onFilter = (
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: FilterStatus;
		}>
	) => setFilter(e.target.value);

	const filters: FilterStatusOptions = [
		{ value: 'All Statuses', label: 'Select Status' },
		{ value: YoungPersonStatus.InHome, label: 'In Home' },
		{ value: YoungPersonStatus.MovedOut, label: 'Moved Out' },
		{ value: YoungPersonStatus.MovingOut, label: 'Moving Out' },
		{ value: YoungPersonStatus.New, label: 'New' },
		{ value: YoungPersonStatus.Declined, label: 'Declined' },
	];

	return (
		<AdminLayout
			onSearch={onSearch}
			title="Kids"
			filterOptions={filters}
			onFilter={onFilter}
			filter={filter}
			createForm={FormType.YpCreate}
		>
			<YoungPeopleView
				where={{
					name: { contains: searchTerm },
					status: { equals: filter === 'All Statuses' ? undefined : filter },
				}}
			/>
		</AdminLayout>
	);
};

export default AdminListYoungPeopleScreen;
