import * as React from 'react';
import { useParams } from 'react-router';
import ActivityLogView from '../../views/ActivityLogView/ActivityLogViewContainer';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Button, Typography } from '@material-ui/core';
import { useGetYoungPersonBasicDetailsQuery } from '../../hooks/useForm/forms/ypBasicDetails/query.generated';
import FallbackComponent from '../../components/FallbackComponent';
import type { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		screenContainer: {
			'@media print': {
				breakInside: 'avoid',
				'& [data-item="activity-log-header"], button': {
					display: 'none',
				},
			},
		},
		screenHeader: {
			paddingBottom: '16px',
		},
		screenSubheader: {
			paddingBottom: '32px',
		},
		printButton: {
			marginTop: '32px',
			float: 'right',
		},
	})
);

export const ActivityLogScreen: React.FC = (props) => {
	const { youngPersonId } = useParams<{ youngPersonId: string }>();
	const { data, loading, error } = useGetYoungPersonBasicDetailsQuery({
		variables: { youngPersonId },
	});

	const styles = useStyles();

	return (
		<Box className={styles.screenContainer}>
			<Typography
				variant="h1"
				color="textSecondary"
				className={styles.screenHeader}
			>
				Young person activity logs
			</Typography>
			<Typography variant="h2" className={styles.screenSubheader}>
				{data?.getYoungPerson?.name ?? ''}
			</Typography>
			<Button
				className={styles.printButton}
				variant="outlined"
				onClick={() => {
					window?.print();
				}}
			>
				Print Logs
			</Button>
			<ActivityLogView youngPersonId={youngPersonId} disablePagination />
		</Box>
	);
};

export default ActivityLogScreen;
