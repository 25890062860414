import React from 'react';
import CardWithAdornmentComponent from '../CardWithAdornmentComponent';

export type FormCardComponentProps = {
	label: string;
	sublabel: string;
	avatarSrc: string;
};

const FormCardComponent: React.FC<FormCardComponentProps> = ({
	label,
	sublabel,
	avatarSrc,
}) => {
	return (
		<CardWithAdornmentComponent
			label={label}
			subLabel={sublabel}
			avatarAlt={label}
			avatarSrc={avatarSrc}
			disableShadow
			disablePadding
			useSublabelForFallback
		/>
	);
};

export default FormCardComponent;
