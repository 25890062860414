import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type UpdateLocationStatusMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	data: Types.YoungPersonLocationStatusUpdateInput;
}>;

export type UpdateLocationStatusMutation = {
	__typename?: 'Mutation';
	updateYoungPersonLocationStatus?:
		| {
				__typename?: 'YoungPersonLocationStatus';
				id: string;
				title: string;
				priority?: number | null | undefined;
				type: Types.YoungPersonLocationStatusType;
				updatedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export const UpdateLocationStatusDocument = gql`
	mutation UpdateLocationStatus(
		$id: String!
		$data: YoungPersonLocationStatusUpdateInput!
	) {
		updateYoungPersonLocationStatus(where: { id: $id }, data: $data) {
			id
			title
			priority
			type
			updatedAt
		}
	}
`;
export type UpdateLocationStatusMutationFn = Apollo.MutationFunction<
	UpdateLocationStatusMutation,
	UpdateLocationStatusMutationVariables
>;

/**
 * __useUpdateLocationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateLocationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationStatusMutation, { data, loading, error }] = useUpdateLocationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLocationStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateLocationStatusMutation,
		UpdateLocationStatusMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UpdateLocationStatusMutation,
		UpdateLocationStatusMutationVariables
	>(UpdateLocationStatusDocument, options);
}
export type UpdateLocationStatusMutationHookResult = ReturnType<
	typeof useUpdateLocationStatusMutation
>;
export type UpdateLocationStatusMutationResult =
	Apollo.MutationResult<UpdateLocationStatusMutation>;
export type UpdateLocationStatusMutationOptions = Apollo.BaseMutationOptions<
	UpdateLocationStatusMutation,
	UpdateLocationStatusMutationVariables
>;
