import { Box, useTheme } from '@material-ui/core';

import * as React from 'react';
import AccordionDividerComponent from '../../components/AccordionDividerComponent';

import AddButtonComponent from '../../components/AddButtonComponent';

import SearchComponent from '../../components/SearchComponent';
import { Can } from '../../contexts/CanContext';
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import FilesView from '../../views/FilesView';

// Components
export type FilesLayoutLayoutProps = {
	youngPersonId?: string;
	houseId: string;
	hideFolders?: boolean;
};

const FilesLayout: React.FC<FilesLayoutLayoutProps> = (props) => {
	const theme = useTheme();
	const { open } = React.useContext(ModalContext);

	const [folders, setSetFolders] = React.useState<string[]>();
	const [openFolders, setOpenFolders] = React.useState<string[]>([]);

	const openUploadModal = () => {
		open({
			type: ModalType['UPLOAD'],
			props: { youngPersonId: props.youngPersonId, houseId: props.houseId },
		});
	};

	return (
		<Box width="100%">
			<Box
				display="flex"
				justifyContent="space-between"
				paddingBottom={theme.spacing('xl')}
			>
				<Can I="create" a="File">
					<AddButtonComponent
						onClick={() => {
							openUploadModal?.();
						}}
					/>
				</Can>
			</Box>
			<FilesView
				onFetchedFolders={setSetFolders}
				youngPersonId={props.youngPersonId}
				houseId={props.houseId}
			/>

			{!props.hideFolders &&
				folders?.map((folder) => {
					return (
						<AccordionDividerComponent
							revealMode="below"
							onOpen={() => setOpenFolders([...openFolders, folder])}
							onClose={() =>
								setOpenFolders(openFolders.filter((f) => f !== folder))
							}
							label={folder.replace('/', '').toUpperCase()}
						>
							{openFolders.includes(folder) && (
								<FilesView
									key={folder}
									folderKey={folder}
									houseId={props.houseId}
									youngPersonId={props.youngPersonId}
								/>
							)}
						</AccordionDividerComponent>
					);
				})}
		</Box>
	);
};

export default FilesLayout;
