import React from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Typography } from '@material-ui/core';
import {
	createStyles,
	makeStyles,
	Theme,
	Box,
	IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export type AlertComponentProps = {
	severity: AlertProps['severity'];
	variant: AlertProps['variant'];
	label: string;
	icon?: React.ReactNode;
	subLabel?: string;
	dismissible?: boolean;
	onDismiss?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		alert: {
			width: '100%',
			alignItems: 'center',
			'& [class*="MuiAlert-message"]': {
				width: '100%',
			},
		},
		contentWrapper: {
			display: 'flex',
			width: '100%',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		textWrapper: {
			display: 'flex',
			flexDirection: 'column',
		},
		icon: {
			color: theme.palette.error.contrastText,
		},
	})
);

export const AlertComponent: React.FC<AlertComponentProps> = ({
	severity,
	variant,
	label,
	icon = null,
	subLabel,
	dismissible,
	onDismiss,
}) => {
	const styles = useStyles();

	return (
		<MuiAlert
			severity={severity}
			variant={variant}
			className={styles.alert}
			icon={icon}
		>
			<Box className={styles.contentWrapper}>
				<Box className={styles.textWrapper}>
					<Typography variant="body1">{label}</Typography>
					{subLabel && <Typography variant="caption">{subLabel}</Typography>}
				</Box>
				{dismissible && (
					<IconButton
						size="small"
						onClick={() => {
							if (onDismiss) onDismiss();
						}}
					>
						<CloseIcon className={styles.icon} fontSize="small" />
					</IconButton>
				)}
			</Box>
		</MuiAlert>
	);
};

export default AlertComponent;
