import {
	createStyles,
	makeStyles,
	Paper,
	Theme,
	Typography,
} from '@material-ui/core';
import { Add, CheckCircle } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';

import { FileType, FormType } from '../../graphql/types';

export type AutomatedTaskCardComponentProps = {
	title: string;
	fileType?: FileType;
	formType?: FormType;
	isComplete?: boolean;
	onClick: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: theme.shape.borderRadius,
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.palette.grey[200],
			padding: theme.spacing(4),
			display: 'flex',
			alignItems: 'center',
			'&:hover': {
				cursor: 'pointer',
				backgroundColor: theme.palette.grey[50],
			},
		},
		rootCompleted: {
			backgroundColor: theme.palette.grey[100],
		},
		button: {
			borderRadius: 100,
			padding: theme.spacing(0),
			marginRight: theme.spacing(2),
		},
		icon: {
			marginRight: theme.spacing(2),
		},
		completedIcon: {
			color: theme.palette.primary.main,
		},
		subTitle: {
			color: theme.palette.grey[500],
		},
	})
);
const AutomatedTaskCardComponent: React.FC<AutomatedTaskCardComponentProps> = (
	props
) => {
	const classes = useStyles();

	let subtitle = 'Task';
	if (props.fileType) subtitle = 'File Upload';
	if (props.formType) subtitle = 'Form';
	if (props.isComplete) subtitle = 'Completed';

	return (
		<Paper
			onClick={props?.onClick}
			variant="outlined"
			className={clsx(classes.root, props.isComplete && classes.rootCompleted)}
		>
			{props.isComplete ? (
				<CheckCircle
					className={clsx(
						classes.icon,
						props.isComplete && classes.completedIcon
					)}
					fontSize={'large'}
				/>
			) : (
				<Add className={classes.icon} fontSize={'small'} />
			)}

			<div>
				<Typography variant="subtitle1">{props.title}</Typography>

				<Typography variant="caption" className={classes.subTitle}>
					{subtitle}
				</Typography>
			</div>
		</Paper>
	);
};

export default AutomatedTaskCardComponent;
