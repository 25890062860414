import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type BasicUserFragment = {
	__typename?: 'User';
	id: string;
	name?: string | null | undefined;
	email: any;
	avatar?: string | null | undefined;
	firstName: string;
	lastName: string;
	role: Types.Role;
};

export type BasicTaskFragment = {
	__typename?: 'Task';
	id: string;
	isAdmin?: boolean | null | undefined;
	isAutomated?: boolean | null | undefined;
	title: string;
	description?: string | null | undefined;
	group?: Types.TaskGroup | null | undefined;
	youngPersonId?: string | null | undefined;
	completedAt?: any | null | undefined;
	repeat?: Types.RepeatOption | null | undefined;
	dueDate?: any | null | undefined;
	isPrivate?: boolean | null | undefined;
	createdAt?: any | null | undefined;
	deletedAt?: any | null | undefined;
	assignedTo?:
		| {
				__typename?: 'User';
				id: string;
				name?: string | null | undefined;
				email: any;
				avatar?: string | null | undefined;
				firstName: string;
				lastName: string;
				role: Types.Role;
		  }
		| null
		| undefined;
	completedBy?:
		| {
				__typename?: 'User';
				id: string;
				name?: string | null | undefined;
				email: any;
				avatar?: string | null | undefined;
				firstName: string;
				lastName: string;
				role: Types.Role;
		  }
		| null
		| undefined;
	createdBy?:
		| {
				__typename?: 'User';
				id: string;
				name?: string | null | undefined;
				email: any;
				avatar?: string | null | undefined;
				firstName: string;
				lastName: string;
				role: Types.Role;
		  }
		| null
		| undefined;
};

export type ListTasksQueryVariables = Types.Exact<{
	where?: Types.Maybe<Types.TaskWhereFilterInput>;
	orderBy?: Types.Maybe<
		| Array<Types.Maybe<Types.TaskOrderByInput>>
		| Types.Maybe<Types.TaskOrderByInput>
	>;
	skip?: Types.Maybe<Types.Scalars['Int']>;
	take?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type ListTasksQuery = {
	__typename?: 'Query';
	countTasks?: number | null | undefined;
	listTasks?:
		| Array<
				| {
						__typename?: 'Task';
						id: string;
						isAdmin?: boolean | null | undefined;
						isAutomated?: boolean | null | undefined;
						title: string;
						description?: string | null | undefined;
						group?: Types.TaskGroup | null | undefined;
						youngPersonId?: string | null | undefined;
						completedAt?: any | null | undefined;
						repeat?: Types.RepeatOption | null | undefined;
						dueDate?: any | null | undefined;
						isPrivate?: boolean | null | undefined;
						createdAt?: any | null | undefined;
						deletedAt?: any | null | undefined;
						assignedTo?:
							| {
									__typename?: 'User';
									id: string;
									name?: string | null | undefined;
									email: any;
									avatar?: string | null | undefined;
									firstName: string;
									lastName: string;
									role: Types.Role;
							  }
							| null
							| undefined;
						completedBy?:
							| {
									__typename?: 'User';
									id: string;
									name?: string | null | undefined;
									email: any;
									avatar?: string | null | undefined;
									firstName: string;
									lastName: string;
									role: Types.Role;
							  }
							| null
							| undefined;
						createdBy?:
							| {
									__typename?: 'User';
									id: string;
									name?: string | null | undefined;
									email: any;
									avatar?: string | null | undefined;
									firstName: string;
									lastName: string;
									role: Types.Role;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type OnHouseTaskMutatedSubscriptionVariables = Types.Exact<{
	houseId?: Types.Maybe<Types.Scalars['String']>;
}>;

export type OnHouseTaskMutatedSubscription = {
	__typename?: 'Subscription';
	onMutatedTask?:
		| {
				__typename?: 'Task';
				id: string;
				ref?: string | null | undefined;
				completedAt?: any | null | undefined;
				description?: string | null | undefined;
				fileType?: Types.FileType | null | undefined;
				youngPersonId?: string | null | undefined;
				title: string;
				formType?: Types.FormType | null | undefined;
				group?: Types.TaskGroup | null | undefined;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				isPrivate?: boolean | null | undefined;
				dueDate?: any | null | undefined;
				repeat?: Types.RepeatOption | null | undefined;
				repeatEnd?: any | null | undefined;
				houseId?: string | null | undefined;
				assignedUserId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type OnYoungPersonTaskMutatedSubscriptionVariables = Types.Exact<{
	youngPersonId?: Types.Maybe<Types.Scalars['String']>;
}>;

export type OnYoungPersonTaskMutatedSubscription = {
	__typename?: 'Subscription';
	onMutatedTask?:
		| {
				__typename?: 'Task';
				id: string;
				ref?: string | null | undefined;
				completedAt?: any | null | undefined;
				description?: string | null | undefined;
				fileType?: Types.FileType | null | undefined;
				youngPersonId?: string | null | undefined;
				title: string;
				formType?: Types.FormType | null | undefined;
				group?: Types.TaskGroup | null | undefined;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				isPrivate?: boolean | null | undefined;
				dueDate?: any | null | undefined;
				repeat?: Types.RepeatOption | null | undefined;
				repeatEnd?: any | null | undefined;
				houseId?: string | null | undefined;
				assignedUserId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type CompleteTaskMutationVariables = Types.Exact<{
	taskId: Types.Scalars['String'];
	completedAt: Types.Scalars['AWSDateTime'];
	completedBy: Types.Scalars['String'];
}>;

export type CompleteTaskMutation = {
	__typename?: 'Mutation';
	updateTask?:
		| {
				__typename?: 'Task';
				id: string;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				title: string;
				description?: string | null | undefined;
				group?: Types.TaskGroup | null | undefined;
				youngPersonId?: string | null | undefined;
				completedAt?: any | null | undefined;
				repeat?: Types.RepeatOption | null | undefined;
				dueDate?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				createdAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UnCompleteTaskMutationVariables = Types.Exact<{
	taskId: Types.Scalars['String'];
}>;

export type UnCompleteTaskMutation = {
	__typename?: 'Mutation';
	updateTask?:
		| {
				__typename?: 'Task';
				id: string;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				title: string;
				description?: string | null | undefined;
				group?: Types.TaskGroup | null | undefined;
				youngPersonId?: string | null | undefined;
				completedAt?: any | null | undefined;
				repeat?: Types.RepeatOption | null | undefined;
				dueDate?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				createdAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const BasicUserFragmentDoc = gql`
	fragment BasicUser on User {
		id
		name
		email
		avatar
		firstName
		lastName
		role
	}
`;
export const BasicTaskFragmentDoc = gql`
	fragment BasicTask on Task {
		id
		isAdmin
		isAutomated
		title
		description
		group
		youngPersonId
		completedAt
		repeat
		dueDate
		isPrivate
		assignedTo {
			...BasicUser
		}
		completedBy {
			...BasicUser
		}
		createdBy {
			...BasicUser
		}
		createdAt
		deletedAt
	}
	${BasicUserFragmentDoc}
`;
export const ListTasksDocument = gql`
	query ListTasks(
		$where: TaskWhereFilterInput = { isAdmin: { equals: false } }
		$orderBy: [TaskOrderByInput] = [{ createdAt: ASC }]
		$skip: Int
		$take: Int = 10
	) {
		listTasks(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
			...BasicTask
		}
		countTasks(where: $where)
	}
	${BasicTaskFragmentDoc}
`;

/**
 * __useListTasksQuery__
 *
 * To run a query within a React component, call `useListTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListTasksQuery(
	baseOptions?: Apollo.QueryHookOptions<ListTasksQuery, ListTasksQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ListTasksQuery, ListTasksQueryVariables>(
		ListTasksDocument,
		options
	);
}
export function useListTasksLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListTasksQuery,
		ListTasksQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ListTasksQuery, ListTasksQueryVariables>(
		ListTasksDocument,
		options
	);
}
export type ListTasksQueryHookResult = ReturnType<typeof useListTasksQuery>;
export type ListTasksLazyQueryHookResult = ReturnType<
	typeof useListTasksLazyQuery
>;
export type ListTasksQueryResult = Apollo.QueryResult<
	ListTasksQuery,
	ListTasksQueryVariables
>;
export const OnHouseTaskMutatedDocument = gql`
	subscription onHouseTaskMutated($houseId: String) {
		onMutatedTask(houseId: $houseId) {
			id
			ref
			completedAt
			description
			fileType
			youngPersonId
			title
			formType
			group
			isAdmin
			isAutomated
			isPrivate
			dueDate
			repeat
			repeatEnd
			houseId
			assignedUserId
			createdAt
			updatedAt
			deletedAt
		}
	}
`;

/**
 * __useOnHouseTaskMutatedSubscription__
 *
 * To run a query within a React component, call `useOnHouseTaskMutatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnHouseTaskMutatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnHouseTaskMutatedSubscription({
 *   variables: {
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useOnHouseTaskMutatedSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		OnHouseTaskMutatedSubscription,
		OnHouseTaskMutatedSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		OnHouseTaskMutatedSubscription,
		OnHouseTaskMutatedSubscriptionVariables
	>(OnHouseTaskMutatedDocument, options);
}
export type OnHouseTaskMutatedSubscriptionHookResult = ReturnType<
	typeof useOnHouseTaskMutatedSubscription
>;
export type OnHouseTaskMutatedSubscriptionResult =
	Apollo.SubscriptionResult<OnHouseTaskMutatedSubscription>;
export const OnYoungPersonTaskMutatedDocument = gql`
	subscription onYoungPersonTaskMutated($youngPersonId: String) {
		onMutatedTask(youngPersonId: $youngPersonId) {
			id
			ref
			completedAt
			description
			fileType
			youngPersonId
			title
			formType
			group
			isAdmin
			isAutomated
			isPrivate
			dueDate
			repeat
			repeatEnd
			houseId
			assignedUserId
			createdAt
			updatedAt
			deletedAt
		}
	}
`;

/**
 * __useOnYoungPersonTaskMutatedSubscription__
 *
 * To run a query within a React component, call `useOnYoungPersonTaskMutatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnYoungPersonTaskMutatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnYoungPersonTaskMutatedSubscription({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useOnYoungPersonTaskMutatedSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		OnYoungPersonTaskMutatedSubscription,
		OnYoungPersonTaskMutatedSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		OnYoungPersonTaskMutatedSubscription,
		OnYoungPersonTaskMutatedSubscriptionVariables
	>(OnYoungPersonTaskMutatedDocument, options);
}
export type OnYoungPersonTaskMutatedSubscriptionHookResult = ReturnType<
	typeof useOnYoungPersonTaskMutatedSubscription
>;
export type OnYoungPersonTaskMutatedSubscriptionResult =
	Apollo.SubscriptionResult<OnYoungPersonTaskMutatedSubscription>;
export const CompleteTaskDocument = gql`
	mutation CompleteTask(
		$taskId: String!
		$completedAt: AWSDateTime!
		$completedBy: String!
	) {
		updateTask(
			data: {
				completedAt: $completedAt
				completedBy: { connect: { id: $completedBy } }
			}
			where: { id: $taskId }
		) {
			...BasicTask
		}
	}
	${BasicTaskFragmentDoc}
`;
export type CompleteTaskMutationFn = Apollo.MutationFunction<
	CompleteTaskMutation,
	CompleteTaskMutationVariables
>;

/**
 * __useCompleteTaskMutation__
 *
 * To run a mutation, you first call `useCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTaskMutation, { data, loading, error }] = useCompleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      completedAt: // value for 'completedAt'
 *      completedBy: // value for 'completedBy'
 *   },
 * });
 */
export function useCompleteTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CompleteTaskMutation,
		CompleteTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CompleteTaskMutation,
		CompleteTaskMutationVariables
	>(CompleteTaskDocument, options);
}
export type CompleteTaskMutationHookResult = ReturnType<
	typeof useCompleteTaskMutation
>;
export type CompleteTaskMutationResult =
	Apollo.MutationResult<CompleteTaskMutation>;
export type CompleteTaskMutationOptions = Apollo.BaseMutationOptions<
	CompleteTaskMutation,
	CompleteTaskMutationVariables
>;
export const UnCompleteTaskDocument = gql`
	mutation UnCompleteTask($taskId: String!) {
		updateTask(
			data: { completedAt: null, completedBy: { disconnect: true } }
			where: { id: $taskId }
		) {
			...BasicTask
		}
	}
	${BasicTaskFragmentDoc}
`;
export type UnCompleteTaskMutationFn = Apollo.MutationFunction<
	UnCompleteTaskMutation,
	UnCompleteTaskMutationVariables
>;

/**
 * __useUnCompleteTaskMutation__
 *
 * To run a mutation, you first call `useUnCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unCompleteTaskMutation, { data, loading, error }] = useUnCompleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUnCompleteTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UnCompleteTaskMutation,
		UnCompleteTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UnCompleteTaskMutation,
		UnCompleteTaskMutationVariables
	>(UnCompleteTaskDocument, options);
}
export type UnCompleteTaskMutationHookResult = ReturnType<
	typeof useUnCompleteTaskMutation
>;
export type UnCompleteTaskMutationResult =
	Apollo.MutationResult<UnCompleteTaskMutation>;
export type UnCompleteTaskMutationOptions = Apollo.BaseMutationOptions<
	UnCompleteTaskMutation,
	UnCompleteTaskMutationVariables
>;
