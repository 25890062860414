// Query
import {
	// Get Details
	YoungPersonRiskAssessmentQuery,
	YoungPersonRiskAssessmentQueryVariables,
	YoungPersonRiskAssessmentDocument,
	UpdateYoungPersonRiskAssessmentMutationVariables,
	UpdateYoungPersonRiskAssessmentMutation,
	UpdateYoungPersonRiskAssessmentDocument,
} from './query.generated';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient } from '@apollo/client';
import { YpRiskAssessment } from '../../types';
import { RiskAssessmentData, Risk } from '../../../../types';
import { RiskAssessmentQuestions, RiskAssessmentID } from './config';

export type RiskAssessmentFormValues = {
	note: string;
};

const PreFetch = async (
	args: YpRiskAssessment,
	client: ApolloClient<object>
) => {
	const riskAssessment = await client.query<
		YoungPersonRiskAssessmentQuery,
		YoungPersonRiskAssessmentQueryVariables
	>({
		query: YoungPersonRiskAssessmentDocument,
		variables: {
			id: args.youngPersonId,
		},
	});

	if (!riskAssessment.data.getYoungPerson || riskAssessment.error)
		throw new Error('Pre-fetched Data Failed');

	return riskAssessment.data;
};

const FormFields = (
	args: YpRiskAssessment,
	data: YoungPersonRiskAssessmentQuery
): FormFieldsComponentProps['fields'][] => {
	const riskAssessment: RiskAssessmentData = JSON.parse(
		data.getYoungPerson?.riskAssessment
	);

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'note',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Additional Notes:',
				multiline: true,
				rows: 4,
				defaultValue: riskAssessment?.note ?? '',
			},
			validation: {
				required: true,
			},
		},
	];

	const stepOneQuestions = RiskAssessmentQuestions.filter(
		(question) => question.step === 1
	);
	const stepTwoQuestions = RiskAssessmentQuestions.filter(
		(question) => question.step === 2
	);

	const stepOne: FormFieldsComponentProps['fields'] = stepOneQuestions.map(
		(question) => {
			let defaultValue = '';
			const existingValue = riskAssessment?.risks.filter(
				(risk) => risk.id === question.id
			);
			if (existingValue?.length === 1) defaultValue = existingValue[0].value;

			return {
				id: question.id,
				type: FormFieldTypes.RADIO,
				buttons: [
					{
						label: 'Low',
						value: 'LOW',
					},
					{
						label: 'Medium',
						value: 'MEDIUM',
					},
					{
						label: 'High',
						value: 'HIGH',
					},
				],
				config: {
					defaultValue,
				},
				validation: {
					required: true,
				},
				label: question.question,
			};
		}
	);

	const stepTwo: FormFieldsComponentProps['fields'] = stepTwoQuestions.map(
		(question) => {
			let defaultValue = '';
			const existingValue = riskAssessment?.risks.filter(
				(risk) => risk.id === question.id
			);
			if (existingValue?.length === 1) defaultValue = existingValue[0].value;
			return {
				id: question.id,
				type: FormFieldTypes.RADIO,
				buttons: [
					{
						label: 'Low',
						value: 'LOW',
					},
					{
						label: 'Medium',
						value: 'MEDIUM',
					},
					{
						label: 'High',
						value: 'HIGH',
					},
				],
				config: {
					defaultValue,
				},
				validation: {
					required: true,
				},
				label: question.question,
			};
		}
	);

	return [stepOne, stepTwo, fields];

	// return fields;
};

const GenerateOnSubmit = async (
	args: YpRiskAssessment,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (
		formValues: RiskAssessmentFormValues
	): Promise<any> => {
		// Go through all the returned data and turn it into a array of Risks
		const keys = Object.keys(formValues);
		const risks: Risk[] = [];

		keys.forEach((key) => {
			if (key === 'note') return;
			risks.push({
				id: key as RiskAssessmentID,
				// @ts-expect-error
				value: formValues[key],
			});
		});

		// Create Risk Assessment Object to be stored on YoungPerson
		const riskAssessment: RiskAssessmentData = {
			risks,
			updatedAt: Date.now().toString(),
			updatedBy: userId,
			note: formValues.note,
		};

		const logChange = await client.mutate<
			UpdateYoungPersonRiskAssessmentMutation,
			UpdateYoungPersonRiskAssessmentMutationVariables
		>({
			mutation: UpdateYoungPersonRiskAssessmentDocument,
			variables: {
				youngPersonId: args.youngPersonId ?? '',
				riskAssessment: JSON.stringify(riskAssessment),
				createdBy: userId,
			},
		});

		return logChange;
	};

	return onSubmit;
};

const YpRiskAssessmentForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default YpRiskAssessmentForm;
