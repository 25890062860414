import React, { useState, useContext, useEffect } from 'react';
import {
	createStyles,
	makeStyles,
	Theme,
	Button,
	Grid,
	Paper,
	Box,
} from '@material-ui/core';
import { useTitle } from 'react-use';
import { useSnackbar } from 'notistack';

// Forms
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ForgotPasswordForm';
import RestPasswordForm from './RestPasswordForm';
import AuthoriseForm from './AuthoriseForm';
import ChallengePasswordForm from './ChallengePassword';

// Images
import logo from '../../assets/fabricLogo.jpg';
import kids from '../../assets/loginImage.jpg';

// Components
import FormComponent from '../../components/FormComponent';

// Contexts
import AuthContext from '../../contexts/authContext';

// Types
import {
	LoginProps as LoginFormValues,
	ForgotPasswordProps as ForgotPasswordFormValues,
	ResetPasswordProps as ResetPasswordFormValues,
	AuthoriseProps as AuthoriseFormValues,
	ChallengeChangePasswordProps as ChallengeChangeFormValues,
} from '../../contexts/authContext/types';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			minHeight: '660px',
			overflow: 'hidden',
			height: '100vh',
		},
		splitContainer: {
			height: '100%',
		},
		leftSection: {
			height: '100%',
			'@media screen and (max-width: 960px)': {
				display: 'none',
			},
		},
		mainImg: {
			width: '100%',
			height: '100%',
			objectFit: 'cover',
			objectPosition: 'center',
		},
		logo: {
			maxWidth: '200px',
		},
		paper: {
			height: '100%',
			borderRadius: '0px',
			paddingLeft: theme.spacing(12),
			paddingRight: theme.spacing(12),
			paddingTop: theme.spacing(6),
			paddingBottom: theme.spacing(6),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-between',
			'@media screen and (max-width: 960px)': {
				width: '100vw',
			},
		},
		topSection: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.warning.main,
		},
	})
);

export type LoginScreenProps = {};

enum CurrentView {
	'LOGIN',
	'FORGOT_PASSWORD',
	'RESET_PASSWORD',
	'AUTHORISE',
	'CHANGE_PASSWORD',
}

const LoginScreen: React.FC<LoginScreenProps> = (props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const {
		login,
		forgotPassword,
		resetPassword,
		authorise,
		resendAuthoriseToken,
		challengeChangePassword,
	} = useContext(AuthContext);
	const [pageTitle, setPageTitle] = useState<string>('FABRIC | Login');
	const [currentView, setCurrentView] = useState<CurrentView>(
		CurrentView.LOGIN
	);
	useTitle(pageTitle);

	useEffect(() => {
		let title = 'STELLA';
		switch (currentView) {
			case CurrentView.LOGIN:
				title = 'STELLA | Login';
				break;
			case CurrentView.FORGOT_PASSWORD:
				title = 'STELLA | Forgot Password';
				break;
			case CurrentView.RESET_PASSWORD:
				title = 'STELLA | Reset Password';
				break;
			case CurrentView.AUTHORISE:
				title = 'STELLA | Authorise';
				break;
			case CurrentView.CHANGE_PASSWORD:
				title = 'STELLA | Change Password';
				break;
		}

		setPageTitle(title);
	}, [currentView]);

	const onLoginClick = (values: LoginFormValues): Promise<string> => {
		return new Promise(async (resolve, reject) => {
			try {
				await login(values);
				resolve('All Works!');
			} catch (error) {
				// If the error is UserNotConfirmedException then change the view to authorise the user
				// @ts-expect-error
				if (error.code === 'UserNotConfirmedException')
					return setCurrentView(CurrentView.AUTHORISE);

				// @ts-expect-error
				if (error.message === 'User Change Password')
					return setCurrentView(CurrentView.CHANGE_PASSWORD);

				reject(error);
			}
		});
	};

	const onForgotPasswordClick = (
		values: ForgotPasswordFormValues
	): Promise<string> => {
		return new Promise(async (resolve, reject) => {
			try {
				await forgotPassword(values);
				setCurrentView(CurrentView.RESET_PASSWORD);
				resolve('All Works');
			} catch (error) {
				// @ts-expect-error
				enqueueSnackbar(error.message, {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			}
		});
	};

	const onResetPasswordClick = (
		values: ResetPasswordFormValues
	): Promise<string> => {
		return new Promise(async (resolve, reject) => {
			try {
				await resetPassword(values);
				resolve('All Works');
			} catch (error) {
				// @ts-expect-error
				enqueueSnackbar(error.message, {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			}
		});
	};

	const onChallengePasswordClick = (
		values: ChallengeChangeFormValues
	): Promise<string> => {
		return new Promise(async (resolve, reject) => {
			try {
				await challengeChangePassword(values);
				resolve('All Works');
			} catch (error) {
				// @ts-expect-error
				enqueueSnackbar(error.message, {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			}
		});
	};

	const onAuthorisePasswordClick = (
		values: AuthoriseFormValues
	): Promise<string> => {
		return new Promise(async (resolve, reject) => {
			try {
				await authorise(values);
				resolve('All Works');
			} catch (error) {
				reject(error);
			}
		});
	};

	const onSecondaryActionClick = async () => {
		switch (currentView) {
			case CurrentView.LOGIN:
				setCurrentView(CurrentView.FORGOT_PASSWORD);
				break;
			case CurrentView.FORGOT_PASSWORD:
				setCurrentView(CurrentView.LOGIN);
				break;
			case CurrentView.AUTHORISE:
				try {
					await resendAuthoriseToken({});
				} catch (error) {
					// @ts-expect-error
					enqueueSnackbar(error.message, {
						variant: 'error',
						anchorOrigin: { vertical: 'top', horizontal: 'right' },
					});
				}
				break;
		}
	};

	let formProps = undefined;
	let secondaryActionCopy = undefined;
	switch (currentView) {
		case CurrentView.LOGIN:
			// @ts-expect-error
			formProps = LoginForm(onLoginClick);
			secondaryActionCopy = 'Forgot Password';
			break;
		case CurrentView.FORGOT_PASSWORD:
			// @ts-expect-error
			formProps = ForgotPasswordForm(onForgotPasswordClick);
			secondaryActionCopy = 'Login';
			break;
		case CurrentView.AUTHORISE:
			// @ts-expect-error
			formProps = AuthoriseForm(onAuthorisePasswordClick);
			secondaryActionCopy = 'Resend Code';
			break;
		case CurrentView.RESET_PASSWORD:
			// @ts-expect-error
			formProps = RestPasswordForm(onResetPasswordClick);
			secondaryActionCopy = undefined;
			break;
		case CurrentView.CHANGE_PASSWORD:
			// @ts-expect-error
			formProps = ChallengePasswordForm(onChallengePasswordClick);
			secondaryActionCopy = undefined;
			break;
	}

	return (
		<Grid container className={classes.container}>
			<Grid item md={6} className={classes.leftSection}>
				<img className={classes.mainImg} src={kids} alt="Kids" />
			</Grid>
			<Grid item md={6} className={classes.splitContainer}>
				<Paper className={classes.paper}>
					<Box className={classes.topSection}>
						<img className={classes.logo} src={logo} alt="Logo" />
						{process.env.REACT_APP_ENVIRONMENT === 'dev' ? (
							<h1>Staging</h1>
						) : null}
					</Box>
					<FormComponent {...formProps} />
					{secondaryActionCopy ? (
						<Button onClick={onSecondaryActionClick}>
							{secondaryActionCopy}
						</Button>
					) : (
						// This is to trick the flex to think there is an element to space everything evenly
						<div></div>
					)}
				</Paper>
			</Grid>
		</Grid>
	);
};

export default LoginScreen;
