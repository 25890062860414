import { useContext } from 'react';
import ModalContext from '../../contexts/ModalContext';
import useFileDownload from '../../hooks/useFileDownload';
import { useDeleteFileMutation, useGetFileQuery } from './query.generated';
import ReadFileView from './ReadFileView';
import { useSnackbar } from 'notistack';

type ReadFileViewContainerProps = {
	fileKey: string;
};

const ReadFileViewContainer: React.FC<ReadFileViewContainerProps> = (props) => {
	const { data, loading, error } = useGetFileQuery({
		variables: { key: props.fileKey },
	});
	const { enqueueSnackbar } = useSnackbar();

	const [deleteFileMutation] = useDeleteFileMutation();

	const { close } = useContext(ModalContext);

	const { downloadFile } = useFileDownload();

	const onComplete = async () => {
		if (data && data.getFile?.key) {
			await downloadFile(data?.getFile?.key);
		}
		close();

		return true;
	};

	const onEdit = () => {
		close();
	};

	const onDelete = async () => {
		try {
			const r = await deleteFileMutation({ variables: { key: props.fileKey } });
			enqueueSnackbar('File deleted successfully.', {
				variant: 'success',
				anchorOrigin: { vertical: 'top', horizontal: 'right' },
			});
			close();
		} catch (error) {
			enqueueSnackbar('Could not delete file', {
				variant: 'error',
				anchorOrigin: { vertical: 'top', horizontal: 'right' },
			});
		}
	};

	return (
		<ReadFileView
			loading={loading}
			data={data}
			error={error}
			onComplete={onComplete}
			onEdit={onEdit}
			onDelete={onDelete}
		/>
	);
};

export default ReadFileViewContainer;
