import React from 'react';
import { ApolloError } from '@apollo/client';
import { Box } from '@material-ui/core';

// Components
import MapCardComponent from '../../components/MapCardComponent';
import FallbackComponent from '../../components/FallbackComponent';
import { HouseLocationDetailsQuery } from './query.generated';
import ListTitleComponent from '../../components/ListTitleComponent';
import ListTitleContainerComponent from '../../components/ListTitleContainerComponent';

export type HouseDetailsViewProps = {
	error?: ApolloError;
	loading?: boolean;
	data?: HouseLocationDetailsQuery;
};

const HouseDetailsView: React.FC<HouseDetailsViewProps> = (props) => {
	const parseCoord = (coord: string | null | undefined) => {
		if (!coord) return null;
		const parsed = parseFloat(coord);
		if (!Number(parsed)) return null;
		return parsed;
	};

	const coords = {
		lat: parseCoord(props.data?.getHouse?.lat),
		long: parseCoord(props.data?.getHouse?.long),
	};

	const hasMap =
		!props.loading &&
		props.data?.getHouse?.lat &&
		props.data.getHouse?.long &&
		props.data?.getHouse?.address;

	/* For skeleton loader */
	const MAP_CARD_HEIGHT = '30.75rem';

	return (
		<Box minHeight={MAP_CARD_HEIGHT}>
			<ListTitleContainerComponent>
				<ListTitleComponent label="House Details" />
			</ListTitleContainerComponent>
			<FallbackComponent
				hasError={Boolean(props.error)}
				errorTitle="An error occurred"
				errorCopy="There was an issue fetching the house details. Please contact your system administrator if the problem persists."
				isLoading={Boolean(props.loading)}
				loadingType="skeletonCard"
			>
				{hasMap && (
					<MapCardComponent
						tel={props.data?.getHouse?.phone ?? ''}
						address={props.data?.getHouse?.address ?? ''}
						coords={coords}
					/>
				)}
			</FallbackComponent>
		</Box>
	);
};

export default HouseDetailsView;
