import { ApolloClient } from '@apollo/client';

// Query
import {
	// Get Details
	GetYoungPersonAboutMeDetailsDocument,
	GetYoungPersonAboutMeDetailsQuery,
	GetYoungPersonAboutMeDetailsQueryVariables,
} from './query.generated';

import {
	// Update Details Mutation
	UpdateYoungPersonProfileDocument,
} from '../../../../graphql/hooks';

import {
	UpdateYoungPersonProfileMutation,
	UpdateYoungPersonProfileMutationVariables,
} from '../../../../graphql/types';

// Types
import { YpAboutMeArgs } from '../../types';
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';

type FormValues = {
	triggers: string;
	improveMood: string;
	needSupportWith: string;
	likes: string;
	dislikes: string;
};

const PreFetch = async (args: YpAboutMeArgs, client: ApolloClient<object>) => {
	const details = await client.query<
		GetYoungPersonAboutMeDetailsQuery,
		GetYoungPersonAboutMeDetailsQueryVariables
	>({
		query: GetYoungPersonAboutMeDetailsDocument,
		variables: {
			youngPersonId: args.youngPersonId ?? '',
		},
	});

	if (!details.data.getYoungPerson || details.error)
		throw new Error('Pre-fetched Data Failed.');

	return details.data.getYoungPerson;
};

const FormFields = (
	args: YpAboutMeArgs,
	data: GetYoungPersonAboutMeDetailsQuery['getYoungPerson']
): FormFieldsComponentProps['fields'][] => {
	const aboutMe = JSON.parse(data?.aboutMe);

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'triggers',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Triggers:',
				defaultValue: aboutMe?.triggers ?? '',
				multiline: true,
				rows: 4,
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'improveMood',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Improves my Mood:',
				defaultValue: aboutMe?.improveMood ?? '',
				multiline: true,
				rows: 4,
			},
			validation: {
				required: true,
			},
		},

		{
			id: 'needSupportWith',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Need Support With:',
				defaultValue: aboutMe?.needSupportWith ?? '',
				multiline: true,
				rows: 4,
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'likes',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Likes:',
				defaultValue: aboutMe?.likes ?? '',
				multiline: true,
				rows: 4,
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'dislikes',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Dislikes:',
				defaultValue: aboutMe?.dislikes ?? '',
				multiline: true,
				rows: 4,
			},
			validation: {
				required: true,
			},
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: YpAboutMeArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: FormValues): Promise<any> => {
		const updateYp = await client.mutate<
			UpdateYoungPersonProfileMutation,
			UpdateYoungPersonProfileMutationVariables
		>({
			mutation: UpdateYoungPersonProfileDocument,
			variables: {
				youngPersonId: args.youngPersonId ?? '',
				data: {
					aboutMe: JSON.stringify(formValues),
				},
			},
		});

		return updateYp;
	};

	return onSubmit;
};

const YoungPersonAboutMeForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default YoungPersonAboutMeForm;
