import React, { useContext, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import {
	Grid,
	Box,
	makeStyles,
	Tab,
	Tabs,
	Theme,
	ThemeProvider,
} from '@material-ui/core';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { getColoredTheme } from '../../styles/palettes';

// Components
import ScreenTitleComponent from '../../components/ScreenTitleComponent/ScreenTitleComponent';
import UserCardComponent from '../../components/UserCardComponent';
import WhereaboutStatusCardComponent from '../../components/WhereaboutStatusCardComponent';
import ActionCardComponent from '../../components/ActionCardComponent';

// Context
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { Can } from '../../contexts/CanContext';

// Views & Layouts
import TasksTabLayout from '../../layouts/TasksTabLayout';
import AlertView from '../../views/AlertView';
import TaskGroupProgressView from '../../views/TaskGroupProgressView';
import TaskGroupView from '../../views/TaskGroupView';
import ActivityLogView from '../../views/ActivityLogView';
import YoungPersonProfileView from '../../views/YoungPersonProfileView';
import YoungPersonAssignHouseModelView from '../../views/YoungPersonAssignHouseModalView';
import CreateCoachingSessionView from '../../views/CreateCoachingSessionView';
import CreateSpinSessionView from '../../views/CreateSpinSessionView';

// Query
import { FormType, YoungPersonStatus } from '../../graphql/types';
import { GetYoungPersonByIdQueryHookResult } from './query.generated';
import FallbackComponent from '../../components/FallbackComponent';
import FilesLayout from '../../layouts/FilesLayoutLayout';

// Utils
import theme from '../../styles/theme';
import AuthContext from '../../contexts/authContext';

type YoungPersonScreenProps = {
	data: GetYoungPersonByIdQueryHookResult['data'];
	loading?: boolean;
	error?: ApolloError;
	tab: number;
	onChangeTab: (event: React.ChangeEvent<{}>, newValue: number) => void;
	onChangeLocationStatus: (statusId: string) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: 'unset',
	},
	tabs: {
		'&': {
			minWidth: 'min-content',
			minHeight: theme.spacing(4),
			marginBottom: theme.spacing(4),
		},
	},

	actionContainer: {
		marginTop: theme.spacing(4),
		marginBottom: theme.spacing(4),
	},

	actionButton: {
		background: theme.palette.grey[300],
		padding: theme.spacing(2),
		width: '100%',
	},

	whereaboutContainer: {
		padding: theme.spacing(4),
	},
	alertContainer: {
		width: '100%',
	},
	whereaboutIcon: {
		marginRight: theme.spacing(1),
	},
	actionWrapper: {
		[theme.breakpoints.down('sm')]: {
			flex: 1,
		},
	},
}));

type Action = {
	label: string;
	onClick: () => void;
};

const YoungPersonScreen: React.FC<YoungPersonScreenProps> = (props) => {
	const classes = useStyles();
	const { path } = useRouteMatch();
	const { open } = useContext(ModalContext);
	const { user } = useContext(AuthContext);
	const { enqueueSnackbar } = useSnackbar();

	const youngPerson = props.data?.getYoungPerson;
	const youngPersonId = youngPerson?.id;
	const houseId = youngPerson?.house?.id;

	const handleOnLocationStatusChange = (
		ypId: string | undefined,
		statusId: string
	) => {
		if (!statusId) {
			return enqueueSnackbar('Sorry could not update status, missing id', {
				variant: 'error',
				anchorOrigin: { vertical: 'top', horizontal: 'right' },
			});
		}

		props.onChangeLocationStatus(statusId);
	};

	let actions: Action[] = [];

	if (props.data?.getYoungPerson?.status === YoungPersonStatus.New) {
		actions = [
			{
				label: 'Move In',
				onClick: () => {
					open({
						type: ModalType.JSX,
						content: (
							<YoungPersonAssignHouseModelView youngPersonId={youngPersonId} />
						),
					});
				},
			},
		];
	} else if (props.data?.getYoungPerson?.status === YoungPersonStatus.InHome) {
		actions = [
			{
				label: 'Kid Recording',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.LogActivity, youngPersonId: youngPersonId },
					});
				},
			},
			{
				label: 'SPIN Session',
				onClick: () => {
					open({
						type: ModalType.JSX,
						content: <CreateSpinSessionView youngPersonId={youngPersonId} />,
					});
				},
			},
			{
				label: 'Coaching Session',
				onClick: () => {
					open({
						type: ModalType.JSX,
						content: <CreateCoachingSessionView houseId={houseId} />,
					});
				},
			},
			{
				label: 'Record Incident',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.LogIncident, youngPersonId: youngPersonId },
					});
				},
			},
			{
				label: 'General Note',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.LogNote, youngPersonId: youngPersonId },
					});
				},
			},
			{
				label: 'Kid Payment',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: {
							type: FormType.LogKidPayment,
							youngPersonId: youngPersonId,
						},
					});
				},
			},
			{
				label: 'Professional Contact',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: {
							type: FormType.LogContact,
							youngPersonId: youngPersonId,
						},
					});
				},
			},
		];

		if (user?.role === 'ADMIN' || user?.role === 'HOUSE_MANAGER') {
			actions.push({
				label: 'Move Out',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.YpMoveOut, youngPersonId: youngPersonId },
					});
				},
			});
		}
	} else if (youngPerson?.status === 'MOVED_OUT') {
		actions = [
			{
				label: 'General Note',
				onClick: () => {
					open({
						type: ModalType.FORM,
						args: { type: FormType.LogIncident, youngPersonId: youngPersonId },
					});
				},
			},
		];
	}

	const whereAboutOptions = props.data?.listYoungPersonLocationStatuses?.map(
		(item) => ({ label: item?.title, value: item?.id })
	);
	const missingAt = youngPerson?.missingAt;
	const whereaboutSubtitle = missingAt
		? `Marked as missing ${dayjs(missingAt).fromNow()}`
		: 'Whereabout status';

	const youngPersonTheme = getColoredTheme(
		props.data?.getYoungPerson?.room?.color ?? undefined
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<FallbackComponent
			isEmpty={!youngPerson}
			emptyTitle="No young person found"
			isLoading={Boolean(props.loading)}
			hasError={Boolean(props.error)}
			errorCopy="We had a little trouble finding the young person. Please contact the system administrator if the problem persists."
		>
			{youngPerson && (
				<Can I="read" this={youngPerson}>
					<ThemeProvider theme={youngPersonTheme}>
						<Box paddingBottom="3rem">
							<ScreenTitleComponent
								title={youngPerson?.name ?? ''}
								subTitle={youngPerson?.house?.title ?? ''}
							/>
						</Box>
						<Grid container spacing={8}>
							<Grid item md={7}>
								<UserCardComponent
									houseName={youngPerson?.room?.name ?? 'No Room'}
									dob={dayjs(youngPerson?.dateOfBirth).format('DD/MM/YYYY')}
									tel={youngPerson?.phone ?? 'Unknown'}
									avatarSrc={
										youngPerson?.avatar ??
										`https://avatars.dicebear.com/api/avataaars/${youngPersonId}.svg`
									}
									userId={youngPersonId ?? ''}
									color={youngPerson?.room?.color ?? '#FFFFFF'}
									roomId={youngPerson?.room?.id ?? ''}
									houseId={youngPerson?.house?.id ?? ''}
									user={youngPerson}
								/>
								<Grid container spacing={4} className={classes.actionContainer}>
									{actions.map((action) => {
										return (
											<Grid className={classes.actionWrapper} item md={6}>
												<ActionCardComponent
													variant="button"
													actionLabel={action.label ?? ''}
													onClick={action.onClick}
												/>
											</Grid>
										);
									})}
								</Grid>
							</Grid>
							<Grid className={classes.alertContainer} item md={5}>
								{youngPerson.status !== 'MOVED_OUT' ? (
									<>
										<WhereaboutStatusCardComponent
											title={youngPerson?.locationStatus?.title}
											subtitle={whereaboutSubtitle}
											items={whereAboutOptions}
											loading={props.loading}
											type={youngPerson?.locationStatus?.type}
											onChangeStatus={handleOnLocationStatusChange}
											kidId={youngPersonId ?? ''}
											hasShadow
											hasHorizontalPadding
										/>
										<Box paddingTop={theme.spacing(6)}>
											{youngPersonId && (
												<AlertView
													youngPersonId={youngPersonId}
													hideTitle
													hideName
												/>
											)}
										</Box>
									</>
								) : null}
							</Grid>
						</Grid>

						<Tabs
							value={props.tab}
							indicatorColor="primary"
							textColor="primary"
							onChange={props.onChangeTab}
							aria-label="Choose between displaying the young person's details and files."
							className={classes.tabs}
						>
							<Tab label="Tasks" />
							<Tab label="Details" />
							<Tab label="Activity" />
							<Tab label="Files" />
						</Tabs>
						<Switch>
							<Route exact path={`${path}/tasks`}>
								{<TaskGroupProgressView />}
								{youngPersonId && !props.loading && (
									<TasksTabLayout
										youngPersonId={youngPersonId}
										houseId={houseId}
										title={`${youngPerson.name}'${
											youngPerson?.name.substring(
												youngPerson.name.length - 1
											) === 's'
												? ''
												: 's'
										} Tasks`}
									/>
								)}
							</Route>
							<Route exact path={`${path}/details`}>
								<YoungPersonProfileView youngPersonId={youngPersonId} />
							</Route>
							<Route exact path={`${path}/activity`}>
								<ActivityLogView showFullLog youngPersonId={youngPersonId} />
							</Route>
							<Route exact path={`${path}/files`}>
								{youngPersonId && houseId && (
									<FilesLayout
										youngPersonId={youngPersonId}
										houseId={houseId}
									/>
								)}
							</Route>
							<Route exact path={`${path}/tasks/group/:group`}>
								<TaskGroupView />
							</Route>
							<Redirect to={`${path}/tasks`} />
						</Switch>
					</ThemeProvider>
				</Can>
			)}
		</FallbackComponent>
	);
};

export default YoungPersonScreen;
