// Query
import {
	CreateRoomDocument,
	CreateRoomMutation,
	CreateRoomMutationVariables,
	UpdateRoomDocument,
	UpdateRoomMutation,
	UpdateRoomMutationVariables,
	GetRoomDocument,
	GetRoomQuery,
	GetRoomQueryVariables,
} from './query.generated';
import { colors } from '../../../../styles/palettes';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient } from '@apollo/client';
import { RoomArgs } from '../../types';

export type RoomFormValues = {
	name: string;
	color: string;
};

const PreFetch = async (args: RoomArgs, client: ApolloClient<object>) => {
	if (args.roomId) {
		const room = await client.query<GetRoomQuery, GetRoomQueryVariables>({
			query: GetRoomDocument,
			variables: {
				id: args.roomId,
			},
		});

		if (room.error) {
			throw new Error(room.error.message);
		}

		return room.data.getRoom;
	}

	return undefined;
};

type Data = GetRoomQuery['getRoom'] | undefined;

const FormFields = (
	args: RoomArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'name',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Room Name:',
				defaultValue: data?.name ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'color',
			type: FormFieldTypes.SELECT,
			label: 'Room Colour:',
			config: {
				defaultValue: data?.color ?? '',
			},
			validation: {
				required: true,
			},
			options: colors.map((color) => ({
				value: color.main,
				copy: color.label,
			})),
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: RoomArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: RoomFormValues): Promise<any> => {
		if (args.roomId) {
			const updateRoom = await client.mutate<
				UpdateRoomMutation,
				UpdateRoomMutationVariables
			>({
				mutation: UpdateRoomDocument,
				variables: {
					id: args.roomId,
					data: {
						...formValues,
					},
				},
			});

			return updateRoom;
		} else {
			const createRoom = await client.mutate<
				CreateRoomMutation,
				CreateRoomMutationVariables
			>({
				mutation: CreateRoomDocument,
				variables: {
					data: {
						...formValues,
						houseId: args.houseId ?? '',
					},
				},
			});

			return createRoom;
		}
	};
	return onSubmit;
};

const RoomForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default RoomForm;
