import * as React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import IconButton, { IconButtonProps } from '@material-ui/core/IconButton';

export type MenuItemType = { label?: string; value?: string };

export type PopupMenuProps = {
	items?: MenuItemType[];
	onSelect?: (item: MenuItemType['value']) => void;
	buttonSize?: IconButtonProps['size'];
	loading?: boolean;
};

// to generate unique ids
let count = 0;

/** Popup component. Designed to work with IconButton. Children is the icon passed to the button.
 * Items have label and value prop -- label is displayed, value it passed to the onSelect callback.
 */

const PopupMenuComponent: React.FC<PopupMenuProps> = (props) => {
	const id = React.useRef(`popup-menu-${count}`);
	count++;
	const [anchorEl, setAnchorEl] = React.useState<
		React.MouseEvent['currentTarget'] | null
	>(null);

	const handleClick = (event: React.MouseEvent) => {
		setAnchorEl(event.currentTarget);
	};

	const handleSelect = (value?: MenuItemType['value']) => {
		handleClose();
		if (value) {
			props.onSelect?.(value);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			<IconButton
				disabled={props.loading}
				aria-controls={id.current}
				aria-haspopup="true"
				onClick={handleClick}
				size={props.buttonSize ?? 'small'}
			>
				{props.children}
			</IconButton>
			<Menu
				id={id.current}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{props.items?.map?.((item, i) => (
					<MenuItem
						key={id + '-item-' + i}
						onClick={() => handleSelect(item.value)}
					>
						{item.label}
					</MenuItem>
				))}
			</Menu>
		</Box>
	);
};

export default PopupMenuComponent;
