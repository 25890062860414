import * as React from 'react';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		divider: {
			display: 'inline-block',
			flex: 1,
		},
		squaredButton: {
			color: theme.palette.text.disabled,
			flex: 1,
		},
		roundedButton: {
			color: theme.palette.primary.main,
			backgroundColor: theme.palette.primary.pale,
			margin: `0.5rem 1.5rem`,
		},
	})
);

export type AccordionDividerProps = {
	label: string;
	expandedLabel?: string;
	defaultOpen?: boolean;
	onOpen?: () => void;
	onClose?: () => void;
	buttonType?: 'squared' | 'rounded';
	revealMode?: 'above' | 'below';
};

export const AccordionDividerComponent: React.FC<AccordionDividerProps> = (
	props
) => {
	const styles = useStyles();
	const theme = useTheme();
	const [isExpanded, setIsExpanded] = React.useState(false);

	const handleClick = () => {
		if (!isExpanded) {
			props?.onOpen?.();
		} else {
			props.onClose?.();
		}
		setIsExpanded(!isExpanded);
	};

	const Button = props.buttonType === 'rounded' ? RoundedButton : SquaredButton;

	const label = (() => {
		if (props.expandedLabel !== undefined && isExpanded)
			return props.expandedLabel;
		return props.label;
	})();

	return (
		<>
			{props.revealMode === 'above' && isExpanded && props.children}
			<Box
				width="100%"
				display="flex"
				justifyContent="flex-start"
				alignItems="center"
				mt={4}
			>
				<Divider className={styles.divider} />
				<Button
					label={label}
					handleClick={handleClick}
					isExpanded={isExpanded}
				/>
				<Divider className={styles.divider} />
			</Box>
			<Collapse in={isExpanded && props.revealMode === 'below'}>
				<Box
					paddingTop={theme.spacing('sm')}
					paddingBottom={theme.spacing('sm')}
				>
					{props.children}
				</Box>
			</Collapse>
		</>
	);
};

type ButtonProps = {
	handleClick: () => void;
	isExpanded: boolean;
	label: string;
	expandedLabel?: string;
};

const SquaredButton: React.FC<ButtonProps> = ({
	handleClick,
	isExpanded,
	label,
}) => {
	const styles = useStyles();

	return (
		<Button
			variant="text"
			onClick={handleClick}
			endIcon={isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
			className={styles.squaredButton}
		>
			<Typography
				variant="subtitle2"
				color="inherit"
				style={{ textTransform: 'none' }}
			>
				{label}
			</Typography>
		</Button>
	);
};

const RoundedButton: React.FC<ButtonProps> = ({
	handleClick,
	isExpanded,
	label,
	expandedLabel,
}) => {
	const styles = useStyles();

	return (
		<Box display="flex" alignItems="center" flexDirection="column">
			{/* hidden to center button */}
			{label && (
				<Typography
					aria-hidden="true"
					style={{ textTransform: 'none', visibility: 'hidden' }}
				>
					{label}
				</Typography>
			)}
			<IconButton
				onClick={handleClick}
				size="small"
				className={styles.roundedButton}
			>
				{isExpanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
			</IconButton>
			{label && (
				<Typography
					variant="body2"
					color="textPrimary"
					style={{ textTransform: 'none', opacity: 0.2 }}
				>
					{label}
				</Typography>
			)}
		</Box>
	);
};

export default AccordionDividerComponent;
