import { YoungPersonLocationStatusType } from '../graphql/types';

export const whereAboutIconColors: {
	[K in YoungPersonLocationStatusType]:
		| 'inherit'
		| 'error'
		| 'disabled'
		| 'action'
		| 'primary'
		| 'secondary'
		| undefined;
} = {
	[YoungPersonLocationStatusType.Missing]: 'error',
	[YoungPersonLocationStatusType.Unauthorised]: 'error',
	[YoungPersonLocationStatusType.Authorised]: 'secondary',
	[YoungPersonLocationStatusType.Home]: 'primary',
	[YoungPersonLocationStatusType.Out]: 'secondary',
	[YoungPersonLocationStatusType.Custom]: 'secondary',
};
