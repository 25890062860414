import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	makeStyles,
	Theme,
	createStyles,
	IconButton,
	Tooltip,
} from '@material-ui/core';

export type NavigationIconProps = {
	location: string;
	icon: any;
	tooltip: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			color: theme.palette.grey[500],
		},
	})
);

const NavigationIcon: React.FC<NavigationIconProps> = (props) => {
	const classes = useStyles();
	const history = useHistory();

	const goto = (url: string) => {
		history.push(url);
	};

	return (
		<Tooltip title={props.tooltip}>
			<IconButton
				color="primary"
				component="li"
				onClick={() => goto(props.location)}
			>
				<props.icon fontSize={'small'} className={classes.icon} />
			</IconButton>
		</Tooltip>
	);
};

export default NavigationIcon;
