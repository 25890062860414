export const createInitialsFallback = (name: string): string[] => {
	if (!name) {
		return [''];
	}

	if (name.length === 0) {
		return [''];
	}

	const splitName = name.split(' ').slice(0, 2);

	return splitName.map((str) => str?.[0]?.toUpperCase());
};

export default createInitialsFallback;
