import React from 'react';
import { UnfoldMore } from '@material-ui/icons';

import { Can } from '../../contexts/CanContext';
import { YoungPersonLocationStatusType } from '../../graphql/types';
import PopupMenuComponent, { MenuItemType } from '../PopupMenuComponent';
import InfoCard from '../InfoCardComponent';
import { createInitialsFallback } from '../../utils';

export type WhereaboutStatusCardComponentProps = {
	avatar?: string;
	title?: string;
	subtitle?: string;
	items?: MenuItemType[];
	type?: YoungPersonLocationStatusType;
	loading?: boolean;
	onChangeStatus: (id: string, statusId: string) => void;
	statusIcon?: React.ReactNode;
	kidId?: string;
	hasShadow?: boolean;
	hasHorizontalPadding?: boolean;
	hasLink?: boolean;
};

const WhereaboutStatusCardComponent: React.FC<WhereaboutStatusCardComponentProps> =
	(props) => {
		const renderPopup = () => (
			<Can I="update" a="YoungPerson">
				<PopupMenuComponent
					items={props.items}
					onSelect={(status) =>
						props.onChangeStatus(props.kidId ?? '', status ?? '')
					}
				>
					<UnfoldMore />
				</PopupMenuComponent>
			</Can>
		);

		return (
			<InfoCard
				hasShadow={props.hasShadow}
				defaultBackgroundColor="white"
				disableHorizontalPadding={!props.hasHorizontalPadding}
			>
				<InfoCard.Avatar avatarAlt={props.title} avatarUrl={props.avatar}>
					{createInitialsFallback(props.title ?? '')}
				</InfoCard.Avatar>
				<InfoCard.LabelContainer>
					<InfoCard.Label
						trailingIcon={renderPopup()}
						labelIcon={props.statusIcon}
						disablePadding
						linkUrl={props.hasLink ? `/kids/${props.kidId}` : undefined}
					>
						{props.title}
					</InfoCard.Label>
					<InfoCard.SubLabel>{props.subtitle}</InfoCard.SubLabel>
				</InfoCard.LabelContainer>
			</InfoCard>
		);
	};

export default WhereaboutStatusCardComponent;
