import * as React from 'react';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		root: {
			minWidth: 'min-content',
			flexShrink: 0,
			padding: `${theme.spacing('xs')} ${theme.spacing('xs')}`,
			paddingLeft: theme.spacing('sm'),
			borderRadius: theme.shape.borderRadius,
			cursor: 'pointer',
			backgroundColor: theme.palette.grey[100],
		},
		label: {
			paddingRight: theme.spacing('xs2'),
		},
		icon: {
			color: theme.palette.grey[800],
		},
	})
);

export type ButtonCardProps = {
	label: string;
	onClick?: () => void;
};

const ButtonCardComponent: React.FC<ButtonCardProps> = (props) => {
	const styles = useStyles();

	return (
		<Card elevation={0} className={styles.root} onClick={props.onClick}>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Typography variant="h3" className={styles.label}>
					{props.label}
				</Typography>
				<IconButton size="small" className={styles.icon}>
					<ChevronRight />
				</IconButton>
			</Box>
		</Card>
	);
};

export default ButtonCardComponent;
