import React, { useContext } from 'react';

// Components
import ModalComponent from '../../components/ModalComponent';

// Context
import ModalContext from '../../contexts/ModalContext';

export type ModalLayoutProps = {};

const ModalLayout: React.FC<ModalLayoutProps> = (props) => {
	const { isOpen, close, content } = useContext(ModalContext);

	return (
		<ModalComponent isOpen={isOpen} onClose={close}>
			{content}
		</ModalComponent>
	);
};

export default ModalLayout;
