import React from 'react';
import Grid from '@material-ui/core/Grid';

export type ListResourceGridComponentProps = {};

const ListResourceGridComponent: React.FC<ListResourceGridComponentProps> = (
	props
) => {
	return (
		<Grid container spacing={4}>
			{React.Children.map(props.children, (child) =>
				child ? (
					<Grid item xs={12} sm={6} md={6} lg={4}>
						{child}
					</Grid>
				) : null
			)}
		</Grid>
	);
};

export default ListResourceGridComponent;
