import {
	createStyles,
	FormControl,
	makeStyles,
	MenuItem,
	Select,
	Theme,
} from '@material-ui/core';
import React from 'react';
import { ListLocationStatusesQueryHookResult } from './query.generated';
import { LocationFilterType } from '../../types';

export type YoungPersonLocationStatusSelectViewProps = {
	onFilterByLocation: (locationFilter: LocationFilterType) => void;
	locationFilter?: LocationFilterType;
	data: ListLocationStatusesQueryHookResult['data'];
	loading?: ListLocationStatusesQueryHookResult['loading'];
	error: ListLocationStatusesQueryHookResult['error'];
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(1),
			minWidth: 120,
			maxWidth: 300,
		},
		chips: {
			display: 'flex',
			flexWrap: 'wrap',
		},
		chip: {
			margin: 2,
		},
		noLabel: {
			marginTop: theme.spacing(3),
		},
	})
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const YoungPersonLocationStatusSelectView: React.FC<YoungPersonLocationStatusSelectViewProps> =
	(props) => {
		const classes = useStyles();

		const defaultFilter = {
			label: 'All',
			value: undefined,
		};

		const locationFilters: LocationFilterType[] = [
			defaultFilter,
			...(props.data?.listYoungPersonLocationStatuses?.map((filter) => {
				return {
					label: filter?.title,
					value: filter?.id,
				};
			}) ?? []),
		];

		const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
			const current = props.locationFilter?.value;
			const value = event.target.value as string;
			const filter =
				value &&
				value !== current &&
				locationFilters.find((filter) => filter.label === value);

			if (filter) {
				props.onFilterByLocation(filter);
			}
		};

		return (
			<FormControl className={classes.formControl}>
				<Select
					labelId="select-whereabouts-status-label"
					id="select-whereabouts-status"
					value={props.locationFilter?.label ?? defaultFilter.label}
					onChange={handleChange}
					MenuProps={MenuProps}
				>
					{locationFilters.map((status) => (
						<MenuItem key={status?.value} value={status?.label}>
							{status?.label}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	};

export default YoungPersonLocationStatusSelectView;
