import React from 'react';
import YoungPersonPickerView from './YoungPersonPickerView';

// Hooks
import { useListYoungPeopleQuery } from '../../graphql/hooks';

type YoungPersonPickerViewContainerProps = {
	houseId: string;
};

const YoungPersonPickerViewContainer: React.FC<YoungPersonPickerViewContainerProps> =
	(props) => {
		const { data, loading, error } = useListYoungPeopleQuery({
			fetchPolicy: 'cache-first',
			variables: {
				where: {
					houseId: { equals: props.houseId },
				},
			},
		});
		return (
			<YoungPersonPickerView
				youngPersons={data?.listYoungPeople ?? []}
				loading={loading}
				error={error}
			/>
		);
	};

export default YoungPersonPickerViewContainer;
