import * as React from 'react';

export type UseObserveKeyPressConfig = [
	keys: KeyboardEvent['key'][],
	condition?: 'every' | 'some'
];

export type UseObserveKeyPressType = (
	...args: UseObserveKeyPressConfig
) => boolean;

/** Tracks the press status of 1 or more keys.
 * Accepts (1) a required 'keys' array, and (2) an optional 'condition'
 * of either 'every' (default) or 'some'.
 * If 'some', true is returned only if at least one of the keys is pressed.
 * If 'every', true is returned only if all are pressed.
 * If an empty array is passed as the keys array, false is returned.
 *
 * @example
 *
 * const allPressed = useObserveKeyPress(['a', 'b'])
 * // => true if 'a' and 'b' are pressed;
 *
 * const somePressed = useObserveKeyPress(['a', 'b'], 'some')
 * // => true if 'a' or 'b' are pressed.
 *
 */

export const useObserveKeyPress: UseObserveKeyPressType = (
	keys,
	condition = 'every'
) => {
	const [pressed, setPressed] = React.useState<Set<string>>(new Set());

	const downHandler = React.useCallback(
		({ key }) => {
			pressed.add(key);
			setPressed(new Set(pressed));
		},
		[pressed]
	);

	const upHandler = React.useCallback(
		({ key }) => {
			pressed.delete(key);
			setPressed(new Set(pressed));
		},
		[pressed]
	);

	React.useEffect(() => {
		window.addEventListener('keydown', downHandler);
		window.addEventListener('keyup', upHandler);
		return () => {
			window.removeEventListener('keydown', downHandler);
			window.removeEventListener('keyup', upHandler);
		};
	}, [downHandler, upHandler]);

	return keys.length && condition === 'every'
		? keys.every((key) => pressed.has(key))
		: keys.some((key) => pressed.has(key));
};

export default useObserveKeyPress;
