import React from 'react';
import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';
import { Parser } from 'json2csv';
import FileSaver from 'file-saver';

import ExportDataModalView from './ExportDataModalView';

// Query
import { useGetYoungPersonForCoachingQuery } from '../../graphql/hooks';
import {
	ExportTrmDataDocument,
	ExportLogDataDocument,
} from './query.generated';

// Types
import { SpinSessionData } from '../../types';

// Fields to export
const trmFields = [
	'date',
	'type',
	'performedBy',
	'trmLevel',
	'spinType',
	'behavior',
];
const logFields = [
	'date',
	'type',
	'performedBy',
	'note',
	// Kid Payment Fields
	'amountPayed',
	// Coaching Session Fields
	'coachingSessionCategory',
	'coachingSessionSubCategory',
	'coachingSessionStatus',
	'coachingSessionActions',
	'coachingSessionConcerns',
	'coachingSessionSuggestions',
	'coachingSessionNotes',
	// Location Fields
	'locationStatus',
	// Incident Fields
	'policeReportNumber',
];

type ExportDataModalViewControllerProps = {
	youngPersonId?: string;
};

const ExportDataModalViewController: React.FC<ExportDataModalViewControllerProps> =
	(props) => {
		const client = useApolloClient();
		const { data, error, loading } = useGetYoungPersonForCoachingQuery({
			variables: {
				id: props.youngPersonId ?? '',
			},
		});

		const exportData = async (
			startDate: string,
			endDate: string,
			type: 'trm' | 'logs'
		) => {
			// Get the data based on if the user is exporting TRM or Logs
			const query =
				type === 'trm' ? ExportTrmDataDocument : ExportLogDataDocument;
			const res = await client.query({
				query,
				variables: {
					id: props.youngPersonId ?? '',
					startDate: dayjs(startDate).format('YYYY-MM-DDThh:mm:ss.sssZ'),
					endDate: dayjs(endDate).format('YYYY-MM-DDThh:mm:ss.sssZ'),
				},
			});

			if (res.error) throw new Error(res.error.message);
			// Map the data to a format ready for the CSV Export
			const jsonData = res.data?.listLogs.map((log: any) => {
				if (type === 'trm') {
					const logData = JSON.parse(log?.data) as SpinSessionData;
					return {
						date: dayjs(logData.date).format('DD/MM/YYYY'),
						trmLevel: parseFloat(logData.trmLevel),
						performedBy: log?.createdByUser?.name ?? 'Unknown User',
						spinType: logData.type,
						behavior: JSON.stringify(logData.spin),
					};
				} else {
					const logData = JSON.parse(log?.data);

					return {
						date: dayjs(logData.date).format('DD/MM/YYYY'),
						performedBy: log?.createdByUser?.name ?? 'Unknown User',
						type: log.type,
						note: log.type !== 'COACHING_SESSION' ? logData.note ?? '' : '',
						// Kids Payments
						amountPayed: logData.amountPayed ?? '',
						// Coaching Session Data
						coachingSessionCategory: logData.category ?? '',
						coachingSessionSubCategory:
							logData.subCategory ?? logData.customSubCategory ?? '',
						coachingSessionStatus:
							log.type === 'COACHING_SESSION' ? logData.status : '',
						coachingSessionActions: logData.actions ?? '',
						coachingSessionConcerns: logData.concerns ?? '',
						coachingSessionSuggestions: logData.suggestions ?? '',
						coachingSessionNotes:
							log.type === 'COACHING_SESSION' ? logData.note : '',
						// Location Data
						locationStatus: log.type === 'LOCATION' ? logData.status : '',
						// Incident Data
						policeReportNumber:
							log.type === 'INCIDENT' ? logData.incidentNumber ?? '' : '',
					};
				}
			});

			// Based on if they want log data or trm data the correct fields will be passed through
			const parser = new Parser({
				fields: type === 'trm' ? trmFields : logFields,
			});
			const csvData = await parser.parse(jsonData);
			const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
			FileSaver.saveAs(
				blob,
				`${type}_export_${props.youngPersonId}_${dayjs().format(
					'DD-MM-YYYY'
				)}.csv`
			);
		};

		return (
			<ExportDataModalView
				data={data?.getYoungPerson}
				error={!!error}
				loading={loading}
				exportData={exportData}
			/>
		);
	};

export default ExportDataModalViewController;
