import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { useTrmGraphDataQuery } from './query.generated';
import TrmChatView from './TrmChatView';

// Types
import { ChartData, TableData } from './types';
import { LogType } from '../../graphql/types';
import { SpinSessionData } from '../../types';

export type TrmChatViewContainerProps = {
	id: string;
};

const TrmChatViewContainer: React.FC<TrmChatViewContainerProps> = (props) => {
	const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
	const [endDate, setEndDate] = useState(dayjs());
	const [tableData, setTableData] = useState<TableData[]>([]);
	const [chartData, setChartData] = useState<ChartData[]>([]);

	const { data, error, loading } = useTrmGraphDataQuery({
		variables: {
			id: props.id,
			startDate: dayjs(startDate).format('YYYY-MM-DDThh:mm:ss.sssZ'),
			endDate: dayjs(endDate).format('YYYY-MM-DDThh:mm:ss.sssZ'),
		},
	});

	useEffect(() => {
		if (error) return;
		if (loading) return;

		const tempChart: ChartData[] = [];
		const tempTable: TableData[] = [];

		data?.listLogs?.forEach((log) => {
			const logData = JSON.parse(log?.data) as SpinSessionData;
			const date = dayjs(logData.date).format('DD/MM/YYYY');
			const trmLevel = parseFloat(logData.trmLevel);

			tempChart.push({
				date,
				trmLevel,
			});

			tempTable.push({
				date,
				trmLevel,
				user: log?.createdByUser?.name ?? 'Unknown User',
			});
		});

		setChartData(tempChart);
		setTableData(tempTable);
	}, [data, error, loading]);

	return (
		<TrmChatView
			tableData={tableData}
			chartData={chartData}
			error={!!error}
			loading={loading}
			startDate={dayjs(startDate).format('YYYY-MM-DD')}
			endDate={dayjs(endDate).format('YYYY-MM-DD')}
			setStartDate={setStartDate}
			setEndDate={setEndDate}
		/>
	);
};

export default TrmChatViewContainer;
