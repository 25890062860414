import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetHouseQueryVariables = Types.Exact<{
	houseId: Types.Scalars['String'];
}>;

export type GetHouseQuery = {
	__typename?: 'Query';
	getHouse?:
		| {
				__typename?: 'House';
				id: string;
				title: string;
				address?: string | null | undefined;
				color?: string | null | undefined;
				isMoveOutHouse?: boolean | null | undefined;
		  }
		| null
		| undefined;
};

export const GetHouseDocument = gql`
	query GetHouse($houseId: String!) {
		getHouse(where: { id: $houseId }) {
			id
			title
			address
			color
			isMoveOutHouse
		}
	}
`;

/**
 * __useGetHouseQuery__
 *
 * To run a query within a React component, call `useGetHouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseQuery({
 *   variables: {
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useGetHouseQuery(
	baseOptions: Apollo.QueryHookOptions<GetHouseQuery, GetHouseQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetHouseQuery, GetHouseQueryVariables>(
		GetHouseDocument,
		options
	);
}
export function useGetHouseLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetHouseQuery,
		GetHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetHouseQuery, GetHouseQueryVariables>(
		GetHouseDocument,
		options
	);
}
export type GetHouseQueryHookResult = ReturnType<typeof useGetHouseQuery>;
export type GetHouseLazyQueryHookResult = ReturnType<
	typeof useGetHouseLazyQuery
>;
export type GetHouseQueryResult = Apollo.QueryResult<
	GetHouseQuery,
	GetHouseQueryVariables
>;
