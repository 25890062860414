import React from 'react';
import { Typography, Box } from '@material-ui/core';
import EmptyStringComponent from '../EmptyStringComponent';

export const InfoCardComponent: React.FC = ({ children }) => {
	return (
		<Box>
			<Typography variant="body2" color="textSecondary">
				{children ?? <EmptyStringComponent />}
			</Typography>
		</Box>
	);
};

export default InfoCardComponent;
