import { Select, MenuItem } from '@material-ui/core';
import React, { useState } from 'react';
import { ListUsersForSelectQueryHookResult } from './query.generated';

export type UserSelectViewProps = {
	onOpen: () => void;
	onSelect: (userId: string) => void;
	data: ListUsersForSelectQueryHookResult['data'];
	loading: ListUsersForSelectQueryHookResult['loading'];
	error: ListUsersForSelectQueryHookResult['error'];
};

const UserSelectView: React.FC<UserSelectViewProps> = (props) => {
	const [selectedUserId, setselectedUserId] = useState<string>();

	const users = props.data?.listUsers ?? [];

	const handleOnSelect = (e: React.ChangeEvent<{ value: unknown }>) => {
		const userId = e.target.value;
		if (typeof userId === 'string') {
			props.onSelect?.(userId);

			setselectedUserId(userId);
		}
	};

	return (
		<Select
			onChange={handleOnSelect}
			value={selectedUserId}
			onOpen={props.onOpen}
		>
			<MenuItem value="all">All</MenuItem>
			{props.loading && (
				<MenuItem disabled value="all">
					fetching users...
				</MenuItem>
			)}

			{users.map((user) => {
				return (
					<MenuItem key={user?.id} value={user?.id}>
						{user?.firstName} {user?.lastName}
					</MenuItem>
				);
			})}
		</Select>
	);
};

export default UserSelectView;
