import { ApolloError } from '@apollo/client';
import {
	createStyles,
	FormControl,
	makeStyles,
	MenuItem,
	Theme,
} from '@material-ui/core';
import Select from '../../components/SelectComponent';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { ListHousesQueryHookResult } from './query.generated';

export type HouseSelectorViewProps = {
	data: ListHousesQueryHookResult['data'];
	loading?: boolean;
	error?: ApolloError;
};

type Params = {
	houseId?: string;
};

const HouseSelectorView: React.FC<HouseSelectorViewProps> = (props) => {
	const { houseId } = useParams<Params>();
	const history = useHistory();
	const [value, setValue] = React.useState(houseId ?? 'placeholder');

	const houses = props.data?.listHouses ?? [];

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const id = event.target.value as string;
		setValue(id);
		if (id !== 'placeholder') {
			history.push(`/homes/${id}`);
		} else {
			history.push('/admin/homes');
		}
	};

	if (props.loading) {
		return <Skeleton variant="text" />;
	}

	const items = [
		{ label: 'Houses', value: 'placeholder' },
		...houses.map((house) => ({
			value: house?.id ?? '',
			label: house?.title ?? '',
		})),
	];

	return (
		<Select
			id="fabric-house-selector"
			value={value}
			onSelect={handleChange}
			disabled={houses.length < 2}
			items={items}
			placeholder="Houses"
			label="Houses"
		/>
	);
};

export default HouseSelectorView;
