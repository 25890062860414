import React from 'react';
import { Box, Divider } from '@material-ui/core';
import ModalContentComponent from '../../components/ModalContentComponent';

import DictionaryComponent from '../../components/DictionaryComponent';
import { GetFileQueryResult } from './query.generated';
import { SecondaryItemType } from '../../components/ModalTitleComponent';

export type ReadFileViewProps = {
	data: GetFileQueryResult['data'];
	loading: GetFileQueryResult['loading'];
	error: GetFileQueryResult['error'];
	isOpen?: boolean;
	onComplete: () => void;
	onEdit: () => void;
	onDelete: () => void;
};

const ReadFileView: React.FC<ReadFileViewProps> = (props) => {
	const file = props.data?.getFile;
	const fileName = file?.key?.split('/').pop() ?? '';
	return (
		<ModalContentComponent
			modalButtons={{
				primaryAction: {
					label: 'Download File',
					onClick: props.onComplete,
				},
			}}
			modalTitle={{
				title: fileName,
				secondaryItem: {
					type: SecondaryItemType.ACTIONS,
					options: [
						{
							label: 'Rename',
							onClick: props.onEdit,
						},
						{
							label: 'Delete',
							onClick: props.onDelete,
						},
					],
				},
			}}
			isLoading={props.loading}
			isEmpty={!file}
		>
			<Divider />
			<Box marginY={4} />
			{/* <CardWithAdornmentComponent
				elevation={0}
				adornmentColor="blue"
				label={file?.title ?? ''}
				subLabel={file?.title ?? ''}
				avatarSrc={file?.createdBy?.avatar ?? ''}
			/> */}
			<Box marginY={4} />

			<Box marginY={4} />
			<DictionaryComponent isFullWidth label="File Name" value={fileName} />
			<Box marginY={4} />
			{/* <DictionaryComponent
				label="Assigned to:"
				value={constructName(task?.assignedTo)}
			/>
			<DictionaryComponent
				label="Due date:"
				value={constructDate(task?.dueDate)}
			/>

			<DictionaryComponent
				label="Repeats:"
				value={task?.repeat ?? 'No repeat'}
			/>
			<DictionaryComponent
				label="Completed by:"
				value={constructName(task?.completedBy)}
			/>
			<DictionaryComponent
				label="Completed at:"
				value={constructDate(task?.completedAt)}
			/>
			<DictionaryComponent
				label="Created by:"
				value={constructName(task?.createdBy)}
			/>
			<DictionaryComponent
				label="Created at:"
				value={constructDate(task?.createdAt)}
			/> */}
		</ModalContentComponent>
	);
};

export default ReadFileView;
