import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import dayjs from 'dayjs';

// Views
import AlertView from './AlertView';

// Hooks / Contexts
import { usePagination } from '../../hooks/usePagination';

// Queries
import {
	useListAlertsQuery,
	ListAlertsDocument,
	ListAlertsQueryVariables,
	DismissAlertDocument,
	DismissAlertMutation,
	DismissAlertMutationVariables,
} from './listAlerts.generated';

// Types
import { OrderByArg, SeverityLevel } from '../../graphql/types';
type AlertViewContainerProps = {
	youngPersonId?: string;
	houseId?: string;
	hideTitle?: boolean;
	hideName?: boolean;
	limit?: number;
};

const AlertViewContainer: React.FC<AlertViewContainerProps> = (props) => {
	const client = useApolloClient();
	const [skip, setSkip] = useState<number>(0);
	const take = 5;

	const whereFilters: ListAlertsQueryVariables['where'] = {
		AND: [
			{ readAt: { equals: null } },
			{ userId: { equals: null } },
			{ severity: { in: [SeverityLevel.High, SeverityLevel.Medium] } },
		],
	};

	// Add the extra filters if this is component is on the YP profile
	if (props.youngPersonId) {
		whereFilters?.AND?.push({
			youngPerson: {
				id: {
					equals: props.youngPersonId,
				},
			},
		});
	}

	// Add the extra filters if this is component is on the house dashboard
	if (props.houseId) {
		whereFilters?.AND?.push({
			OR: [
				{
					youngPerson: {
						house: {
							id: {
								equals: props.houseId,
							},
						},
					},
				},
				{
					house: {
						id: { equals: props.houseId },
					},
				},
			],
		});
	}

	const { data, loading, error } = useListAlertsQuery({
		variables: {
			where: whereFilters,
			take,
			skip,
			orderBy: [{ severity: OrderByArg.Asc }, { createdAt: OrderByArg.Desc }],
		},
	});

	const {
		currentPage,
		isFirstPage,
		isLastPage,
		setCount,
		handlePaginate: paginationHandlePaginate,
	} = usePagination({
		count: 0,
		take,
	});

	const handlePaginate = (i: number) => {
		setSkip(i);
		paginationHandlePaginate(i);
	};

	useEffect(() => {
		if (!data?.countNotifications) return;

		setCount(data?.countNotifications);
	}, [data]);

	const dismissAlert = async (alertId: string) => {
		const variables: DismissAlertMutationVariables = {
			id: alertId,
			date: dayjs().format('YYYY-MM-DDThh:mm:ss.sssZ'),
		};

		try {
			await client.mutate<DismissAlertMutation>({
				mutation: DismissAlertDocument,
				variables,
				refetchQueries: [ListAlertsDocument, 'listNotifications'],
			});
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<AlertView
			data={data}
			loading={loading}
			error={error}
			hideTitle={props.hideTitle}
			currentCount={data?.listNotifications?.length ?? 0}
			totalCount={data?.countNotifications ?? 0}
			currentPage={currentPage}
			handlePaginate={handlePaginate}
			isFirstPage={isFirstPage}
			isLastPage={isLastPage}
			hideName={props.hideName}
			dismissAlert={dismissAlert}
			houseId={props.houseId}
		/>
	);
};

export default AlertViewContainer;
