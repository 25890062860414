import React, { useState, useEffect, useContext } from 'react';
import dayjs from 'dayjs';

import ActivityLogView from './ActivityLogView';

// Query
import { LogType, LogWhereFilterInput } from '../../graphql/types';
import { useListLogsQuery } from './query.generated';

// Contexts / Hooks
import { usePagination } from '../../hooks';
import ModalContext from '../../contexts/ModalContext';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../contexts/CanContext';

export type ActivityLogViewContainerProps = {
	houseId?: string;
	youngPersonId?: string;
	disablePagination?: boolean;
	showFullLog?: boolean;
};

type Filter<T> = { label: string; value: T };
export type DurationFilter = Filter<string>;
export type TypeFilter = Filter<LogType | 'all'>;

type FiltersType = {
	duration: DurationFilter[];
	type: TypeFilter[];
};

export const filterItems: FiltersType = {
	duration: [
		{ label: 'Show: All Time', value: 'all' },
		{ label: 'Show: Last 7 days', value: '7' },
		{ label: 'Show: Last 14 days', value: '14' },
		{ label: 'Show: Last 30 days', value: '30' },
	],
	type: [
		{ label: 'Type: All', value: 'all' },
		{ label: 'Type: Activity', value: LogType.Activity },
		{ label: 'Type: Incident', value: LogType.Incident },
		{
			label: 'Type: Coaching Session',
			value: LogType.CoachingSession,
		},
		{ label: 'Type: SPIN Session', value: LogType.SpinSession },
		{ label: 'Type: Note', value: LogType.Note },
		{ label: 'Type: Location', value: LogType.Location },
		{ label: 'Type: Risk Assessment', value: LogType.RiskAssessment },
	],
};

const initialFilterValues = {
	duration: filterItems.duration[0],
	type: filterItems.type[0],
};

const ActivityLogViewContainer: React.FC<ActivityLogViewContainerProps> = (
	props
) => {
	const [type, setType] = useState<TypeFilter>(initialFilterValues.type);
	const ability = useAbility(AbilityContext);
	const [duration, setDuration] = useState<DurationFilter>(
		initialFilterValues.duration
	);
	const [search, setSearch] = useState<string>('');
	const { isOpen: isModalOpen } = useContext(ModalContext);

	const _take = props.disablePagination ? 10000 : 5;

	const {
		skip,
		currentPage,
		handlePaginate,
		pageCount,
		setCount,
		setCurrentPage,
	} = usePagination({ take: _take, count: 0 });

	// For the full screen log
	const _skip = (() => {
		const fallback = 0;
		if (props.disablePagination) return fallback;
		return skip ?? fallback;
	})();

	useEffect(() => {
		if (window.location.search) {
			const queries = window.location.search.split('&');

			queries.forEach((query) => {
				if (query.includes('type')) {
					const value = query.split('=')[1];
					if (value === 'RISK_ASSESSMENT') {
						setType({
							label: 'Type: Risk Assessment',
							value: LogType.RiskAssessment,
						});
					}
				}
			});
		}
	}, []);

	const handleChangeDurationFilter = (value: string) => {
		const filter = filterItems.duration.find(
			(filter) => filter.value === value
		);
		if (filter) {
			setDuration(filter);
		}
	};

	const handleChangeTypeFilter = (value: LogType | 'all') => {
		const filter = filterItems.type.find((filter) => filter.value === value);
		if (filter) {
			setType(filter);
		}
	};

	const buildWhereFilter = React.useCallback(() => {
		const filters: LogWhereFilterInput[] = [];

		if (props.youngPersonId) {
			filters.push({ youngPersonId: { equals: props.youngPersonId } });
		}

		if (props.houseId) {
			filters.push({
				OR: [
					{
						youngPerson: { houseId: { equals: props.houseId } },
					},
					{
						houseId: { equals: props.houseId },
					},
				],
			});
		}

		if (type.value !== 'all') {
			filters.push({ type: { equals: type.value } });
		}

		if (ability.cannot('update', 'ManagerOnDutyLog')) {
			filters.push({ type: { not: { equals: LogType.MangerOnDuty } } });
		}

		if (duration.value !== 'all') {
			try {
				const date = dayjs()
					.subtract(parseInt(duration.value), 'day')
					.toISOString();
				filters.push({ createdAt: { gte: date } });
			} catch (error) {
				console.log('Invalid duration');
			}
		}

		if (filters.length === 1) {
			return filters[0];
		}

		// Based on if there are multiple filters we need to add AND and pass through the array
		if (filters.length === 1) {
			return filters[0];
		} else if (filters.length > 1) {
			return { AND: filters };
		} else {
			return undefined;
		}
	}, [props.youngPersonId, props.houseId, type.value, duration]);

	const [where, setWhere] = useState<LogWhereFilterInput | undefined>(
		buildWhereFilter()
	);

	const { data, error, loading, refetch } = useListLogsQuery({
		variables: {
			skip: _skip,
			take: _take,
			where: where,
		},
	});

	useEffect(() => {
		setCount(data?.countLogs ?? 0);
	}, [data, setCount]);

	useEffect(() => {
		const filter = buildWhereFilter();
		setWhere(filter);
		setCurrentPage(1);
	}, [type, search, duration, buildWhereFilter]);

	useEffect(() => {
		if (!isModalOpen && !loading) refetch();
	}, [isModalOpen]);

	const reduceUi = !!(props.houseId || props.youngPersonId);

	return (
		<ActivityLogView
			data={data?.listLogs}
			numberOfPages={pageCount}
			currentPage={currentPage}
			error={error}
			loading={loading}
			onPaginationChange={handlePaginate}
			reduceUi={reduceUi}
			changeSearchTerm={setSearch}
			changeType={handleChangeTypeFilter}
			changeDuration={handleChangeDurationFilter}
			durationFilter={duration}
			typeFilter={type}
			showFullLog={props.showFullLog}
		/>
	);
};

export default ActivityLogViewContainer;
