import React, { useEffect, useContext } from 'react';
import RoomsView from './RoomsView';
import { useListRoomsQuery } from './query.generated';
import ModalContext from '../../contexts/ModalContext';
import { useParams } from 'react-router-dom';

type RoomsViewContainerProps = {};

type Params = {
	houseId?: string;
};

const RoomsViewContainer: React.FC<RoomsViewContainerProps> = (props) => {
	const params = useParams<Params>();
	const houseId = params.houseId;
	const { isOpen } = useContext(ModalContext);

	const { data, loading, error, refetch } = useListRoomsQuery({
		variables: { houseId: houseId },
	});

	console.log('error', error);

	useEffect(() => {
		if (!isOpen && !loading) refetch();
	}, [isOpen]);

	return (
		<RoomsView
			data={data?.listRooms}
			loading={loading}
			error={error}
			houseId={houseId}
		/>
	);
};

export default RoomsViewContainer;
