import { createTheme, Theme } from '@material-ui/core';
import { SpacingArgumentExtension } from '../createSpacing';

const spacing = (factor: number | SpacingArgumentExtension): string => {
	if (factor === 'xs3') return spacing(1);
	if (factor === 'xs2') return spacing(1.5);
	if (factor === 'xs') return spacing(2);
	if (factor === 'sm') return spacing(3);
	if (factor === 'md') return spacing(4);
	if (factor === 'lg') return spacing(5);
	if (factor === 'xl') return spacing(6);
	if (factor === 'xl2') return spacing(7);
	if (factor === 'xl3') return spacing(8);
	if (factor === 'xl4') return spacing(10);

	return `${0.5 * factor}rem`;
};

const fonts = {
	primary: 'Lato, sans-serif',
};

const shadows = {
	card: '0px 8px 24px -8px rgba(0,0,0,0.25)',
	icon: '0px 2px 4px 0px rgba(0,0,0,0.10)',
	dropdown: [
		'0px 8px 10px 1px rgba(0,0,0,0.14)',
		'0px 3px 14px 2px rgba(0,0,0,0.12)',
		'0px 5px 5px -3px rgba(0,0,0,0.20)',
	].join(';'),
	drawer: '0px 8px 48px 0px rgba(0,0,0,0.10)',
};

export const theme: Theme = createTheme({
	overrides: {
		MuiButton: {
			contained: {
				borderRadius: 100,
			},
		},
		MuiTab: {
			root: {
				minWidth: 'unset',
				padding: 0,
				marginRight: spacing(3),
				'@media (min-width: 0px)': {
					minWidth: 0,
				},
				underline: {
					disableRipple: true,
					disableTouchRipple: true,
				},
			},
		},
		MuiTabs: {
			root: {
				minWidth: 'min-content',
				minHeight: 'unset',
				disableTouchRipple: true,
				disableRipple: true,
				"& [class*='MuiTouchRipple-root']": {
					visibility: 'hidden',
				},
			},
		},
		MuiPopover: {
			paper: {
				borderRadius: spacing(1),
				boxShadow: shadows.dropdown,
			},
		},
	},
	palette: {
		text: {
			primary: '#111111',
		},
		primary: {
			main: '#0AB472',
			contrastText: '#fff',
			pale: '#E6F7F0',
		},
		secondary: {
			main: '#741FD8',
			contrastText: '#ffcc00',
			pale: '#F1E8FB',
		},
		info: {
			main: '#1E73F4',
		},
		background: {
			default: '#fdfdfd',
		},
		contrastThreshold: 1,
		tonalOffset: 0.1,
	},
	typography: {
		h1: {
			fontSize: '2rem',
			fontFamily: fonts.primary,
			fontWeight: 'bold',
		},
		h2: {
			fontSize: '1.25rem',
			fontFamily: fonts.primary,
			fontWeight: 'bold',
		},
		h3: {
			fontSize: '1rem',
			fontFamily: fonts.primary,
			fontWeight: 'bold',
		},
		h4: {
			fontSize: '0.875rem',
			fontFamily: fonts.primary,
			fontWeight: 'bold',
		},
		body1: {
			fontSize: '0.875rem',
			fontFamily: fonts.primary,
		},
		body2: {
			fontSize: '0.75rem',
			fontFamily: fonts.primary,
		},
		caption: {
			fontFamily: fonts.primary,
		},
	},
	shape: {
		borderRadius: 8,
	},
	spacing,
} as const);

theme.shadows[2] = shadows.card;
theme.shadows[3] = shadows.icon;
theme.shadows[4] = shadows.dropdown;

export default theme;
