import * as React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

interface FallbackCopyProps {
	title?: string;
	copy?: string;
}

const FallbackCopyComponent = (props: FallbackCopyProps) => {
	return (
		<Box
			textAlign={'center'}
			display="flex"
			flexDirection={'column'}
			gridGap={'0.25rem'}
			maxWidth="20rem"
		>
			{props.title && (
				<Typography color="textSecondary" variant="h6">
					{props.title}
				</Typography>
			)}
			{props.copy && (
				<Typography color="textSecondary">{props.copy}</Typography>
			)}
		</Box>
	);
};

export default FallbackCopyComponent;
