// Query
import {
	CheckUserWithEmailDocument,
	CheckUserWithEmailQueryVariables,
	CheckUserWithEmailQuery,
	InviteUserDocument,
	InviteUserMutation,
	InviteUserMutationVariables,
} from './query.generated';

import { ListHouseIdsDocument } from '../../../../graphql/hooks';
import {
	ListHouseIdsQuery,
	ListHouseIdsQueryVariables,
	Role,
} from '../../../../graphql/types';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient } from '@apollo/client';
import { InviteUserArgs } from '../../types';

export type InviteUserFormValues = {
	firstName: string;
	lastName: string;
	role: Role;
	email: string;
	houseId: string;
};

const PreFetch = async (args: InviteUserArgs, client: ApolloClient<object>) => {
	const houses = await client.query<
		ListHouseIdsQuery,
		ListHouseIdsQueryVariables
	>({
		query: ListHouseIdsDocument,
	});

	if (!houses.data.listHouses || houses.error)
		throw new Error('Pre-fetched Data Failed');

	return houses.data;
};

type Data = ListHouseIdsQuery;

const FormFields = (
	args: InviteUserArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const houseOptions = data.listHouses?.map((house) => {
		return { value: house?.id ?? '', copy: house?.title ?? '' };
	});

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'firstName',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'First Name:',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'lastName',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Last Name:',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'role',
			type: FormFieldTypes.SELECT,
			label: 'Role:',
			config: {},
			validation: {
				required: true,
			},
			options: [
				{
					value: Role.Admin,
					copy: 'Admin',
				},
				{
					value: Role.HouseManager,
					copy: 'House Manager',
				},
				{
					value: Role.SeniorUser,
					copy: 'Senior Support Worker',
				},
				{
					value: Role.User,
					copy: 'Support Worker',
				},
			],
		},
		{
			id: 'email',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Email:',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'houseId',
			type: FormFieldTypes.SELECT,
			label: 'House:',
			config: {},
			validation: {
				required: true,
			},
			options: houseOptions ?? [],
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: InviteUserArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: InviteUserFormValues): Promise<any> => {
		const data = {
			// Lowercase the email as it's not case sensitive
			...{ ...formValues, email: formValues.email.toLowerCase() },
			name: `${formValues.firstName} ${formValues.lastName}`,
			// TODO: [STLL-244] Need to add the organization ID
			organisationId: '616eaa99767556b7290a0cf3',
		};

		// Check if a user with that email address exists.
		const checkUser = await client.query<
			CheckUserWithEmailQuery,
			CheckUserWithEmailQueryVariables
		>({
			query: CheckUserWithEmailDocument,
			variables: {
				email: formValues.email,
			},
		});

		if (checkUser.data.getUser?.id)
			throw new Error('A user with this email has already been invited.');

		const inviteUser = await client.mutate<
			InviteUserMutation,
			InviteUserMutationVariables
		>({
			mutation: InviteUserDocument,
			variables: {
				data,
			},
		});

		return inviteUser;
	};
	return onSubmit;
};

const InviteUserForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default InviteUserForm;
