import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import ReportingScreen from './ReportingScreen';

// Create react component importing ReportingScreen
const ReportingScreenContainer: React.FC = () => {
	const [tab, setTab] = useState(0);

	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTab(newValue);
	};

	return <ReportingScreen tab={tab} onChangeTab={handleTabChange} />;
};

export default ReportingScreenContainer;
