import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

// Components
import ModalContentComponent, {
	ModalContentComponentProps,
} from '../../components/ModalContentComponent';

// Contexts
import ModalContext from '../../contexts/ModalContext';

// Form Steps
import StepOneForm from './StepOne';
import StepTwoForm from './StepTwo';

// Types
import { FormListYoungPeopleQuery } from '../../graphql/types';
import { SpinSessionData, InitialData } from './types';
import { ErrorType } from '../../components/FallbackComponent';
import { SecondaryItemType } from '../../components/ModalTitleComponent';

type CreateSpinSessionViewProps = {
	onFormSubmit: (
		initialData: InitialData,
		spinData: SpinSessionData[]
	) => Promise<any>;
	pageLoading: boolean;
	error: boolean;
	youngPeopleData: FormListYoungPeopleQuery['listYoungPeople'];
};

const CreateSpinSessionView: React.FC<CreateSpinSessionViewProps> = (props) => {
	// Handle Form Data State
	const [spinData, setSpinData] = useState<SpinSessionData[]>([]);
	const [initialData, setInitialData] = useState<InitialData>();

	// Handle Steps / Modal State
	const [step, setStep] = useState<number>(1);
	const [maxStep, setMaxStep] = useState<number>(2);
	const [loading, setLoading] = useState<boolean>(false);
	const [primaryAction, setPrimaryAction] = useState<string>('Next');

	// Initialize Form
	const { handleSubmit, reset, control, watch } = useForm({
		mode: 'onBlur',
		shouldFocusError: true,
	});

	const { close } = useContext(ModalContext);

	// Listen for changes to the form data
	const watchFields = watch('anotherRound', false);

	/* Handle changing the primary action value between Next and Submit based on if they
  continue to add more rounds or not */
	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			if (name !== 'anotherRound') return;
			if (value.anotherRound === true) setPrimaryAction('Next');
			else setPrimaryAction('Submit');
		});
		return () => subscription.unsubscribe();
	}, [watchFields]);

	// Setup the modal header
	const modalHeader: ModalContentComponentProps['modalTitle'] = {
		title: 'Spin Session',
		secondaryItem: {
			type: SecondaryItemType.TEXT,
			copy: `Step ${step} of ${maxStep}`,
		},
	};

	// Setup the buttons
	const modalButtons: ModalContentComponentProps['modalButtons'] = {
		primaryAction: {
			label: primaryAction,
			disabled: loading || props.pageLoading,
			loading: loading,
			onClick: handleSubmit(async (data) => {
				setLoading(true);
				// If on step 1 then just move the user to step 2
				if (step === 1) {
					setInitialData(data as InitialData);
					reset();
					setStep(2);
					setPrimaryAction('Submit');
				} else {
					// Check if the user asked for another around of spin to then reset the form
					if (data.anotherRound) {
						setSpinData([...spinData, data as SpinSessionData]);
						reset();
						setStep(step + 1);
						setMaxStep(maxStep + 1);
						setPrimaryAction('Submit');
					} else {
						// Submit the form if they have not requested for another round of Spin
						setLoading(true);
						try {
							await props.onFormSubmit(initialData as InitialData, [
								...spinData,
								data as SpinSessionData,
							]);

							close();
						} catch (error) {}
					}
				}
				setLoading(false);
			}),
		},
	};

	return (
		<ModalContentComponent
			modalTitle={modalHeader}
			modalButtons={modalButtons}
			isLoading={props.pageLoading}
			error={
				props.error
					? {
							title: 'An Error Occurred',
							copy: 'There was an issue loading this form, please try again later.',
							type: ErrorType.WARNING,
					  }
					: undefined
			}
		>
			{step === 1 ? (
				<StepOneForm
					youngPeopleData={props.youngPeopleData}
					control={control}
					watch={watch}
				/>
			) : (
				/* Key is in place to force remounting the react component to fully 
        reset the field values */
				<StepTwoForm control={control} watch={watch} key={step} />
			)}
		</ModalContentComponent>
	);
};

export default CreateSpinSessionView;
