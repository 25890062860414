import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type SimpleRoomFragment = {
	__typename?: 'Room';
	id: string;
	name: string;
	color: string;
	youngPerson?: { __typename: 'YoungPerson'; name: string } | null | undefined;
};

export type CreateRoomMutationVariables = Types.Exact<{
	data: Types.RoomCreateInput;
}>;

export type CreateRoomMutation = {
	__typename?: 'Mutation';
	createRoom?:
		| {
				__typename?: 'Room';
				id: string;
				name: string;
				color: string;
				youngPerson?:
					| { __typename: 'YoungPerson'; name: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UpdateRoomMutationVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
	data: Types.RoomUpdateInput;
}>;

export type UpdateRoomMutation = {
	__typename?: 'Mutation';
	updateRoom?:
		| {
				__typename?: 'Room';
				id: string;
				name: string;
				color: string;
				youngPerson?:
					| { __typename: 'YoungPerson'; name: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type GetRoomQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
}>;

export type GetRoomQuery = {
	__typename?: 'Query';
	getRoom?:
		| {
				__typename?: 'Room';
				id: string;
				name: string;
				color: string;
				youngPerson?:
					| { __typename: 'YoungPerson'; name: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const SimpleRoomFragmentDoc = gql`
	fragment SimpleRoom on Room {
		id
		name
		color
		youngPerson {
			__typename
			name
		}
	}
`;
export const CreateRoomDocument = gql`
	mutation CreateRoom($data: RoomCreateInput!) {
		createRoom(data: $data) {
			...SimpleRoom
		}
	}
	${SimpleRoomFragmentDoc}
`;
export type CreateRoomMutationFn = Apollo.MutationFunction<
	CreateRoomMutation,
	CreateRoomMutationVariables
>;

/**
 * __useCreateRoomMutation__
 *
 * To run a mutation, you first call `useCreateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomMutation, { data, loading, error }] = useCreateRoomMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRoomMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateRoomMutation,
		CreateRoomMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CreateRoomMutation, CreateRoomMutationVariables>(
		CreateRoomDocument,
		options
	);
}
export type CreateRoomMutationHookResult = ReturnType<
	typeof useCreateRoomMutation
>;
export type CreateRoomMutationResult =
	Apollo.MutationResult<CreateRoomMutation>;
export type CreateRoomMutationOptions = Apollo.BaseMutationOptions<
	CreateRoomMutation,
	CreateRoomMutationVariables
>;
export const UpdateRoomDocument = gql`
	mutation UpdateRoom($id: String, $data: RoomUpdateInput!) {
		updateRoom(where: { id: $id }, data: $data) {
			...SimpleRoom
		}
	}
	${SimpleRoomFragmentDoc}
`;
export type UpdateRoomMutationFn = Apollo.MutationFunction<
	UpdateRoomMutation,
	UpdateRoomMutationVariables
>;

/**
 * __useUpdateRoomMutation__
 *
 * To run a mutation, you first call `useUpdateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomMutation, { data, loading, error }] = useUpdateRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRoomMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateRoomMutation,
		UpdateRoomMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UpdateRoomMutation, UpdateRoomMutationVariables>(
		UpdateRoomDocument,
		options
	);
}
export type UpdateRoomMutationHookResult = ReturnType<
	typeof useUpdateRoomMutation
>;
export type UpdateRoomMutationResult =
	Apollo.MutationResult<UpdateRoomMutation>;
export type UpdateRoomMutationOptions = Apollo.BaseMutationOptions<
	UpdateRoomMutation,
	UpdateRoomMutationVariables
>;
export const GetRoomDocument = gql`
	query GetRoom($id: String) {
		getRoom(where: { id: $id }) {
			...SimpleRoom
		}
	}
	${SimpleRoomFragmentDoc}
`;

/**
 * __useGetRoomQuery__
 *
 * To run a query within a React component, call `useGetRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoomQuery(
	baseOptions?: Apollo.QueryHookOptions<GetRoomQuery, GetRoomQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetRoomQuery, GetRoomQueryVariables>(
		GetRoomDocument,
		options
	);
}
export function useGetRoomLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetRoomQuery, GetRoomQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetRoomQuery, GetRoomQueryVariables>(
		GetRoomDocument,
		options
	);
}
export type GetRoomQueryHookResult = ReturnType<typeof useGetRoomQuery>;
export type GetRoomLazyQueryHookResult = ReturnType<typeof useGetRoomLazyQuery>;
export type GetRoomQueryResult = Apollo.QueryResult<
	GetRoomQuery,
	GetRoomQueryVariables
>;
