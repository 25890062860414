import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { HouseCardFragmentDoc } from '../../../../screens/AdminListHousesScreen/query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type CreateHouseMutationVariables = Types.Exact<{
	data: Types.HouseCreateInput;
}>;

export type CreateHouseMutation = {
	__typename?: 'Mutation';
	createHouse?:
		| {
				__typename?: 'House';
				id: string;
				title: string;
				address?: string | null | undefined;
				color?: string | null | undefined;
				preMoveInHouse?: boolean | null | undefined;
		  }
		| null
		| undefined;
};

export const CreateHouseDocument = gql`
	mutation CreateHouse($data: HouseCreateInput!) {
		createHouse(data: $data) {
			...HouseCard
		}
	}
	${HouseCardFragmentDoc}
`;
export type CreateHouseMutationFn = Apollo.MutationFunction<
	CreateHouseMutation,
	CreateHouseMutationVariables
>;

/**
 * __useCreateHouseMutation__
 *
 * To run a mutation, you first call `useCreateHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHouseMutation, { data, loading, error }] = useCreateHouseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateHouseMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateHouseMutation,
		CreateHouseMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<CreateHouseMutation, CreateHouseMutationVariables>(
		CreateHouseDocument,
		options
	);
}
export type CreateHouseMutationHookResult = ReturnType<
	typeof useCreateHouseMutation
>;
export type CreateHouseMutationResult =
	Apollo.MutationResult<CreateHouseMutation>;
export type CreateHouseMutationOptions = Apollo.BaseMutationOptions<
	CreateHouseMutation,
	CreateHouseMutationVariables
>;
