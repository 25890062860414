import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { YoungPersonWithLocationStatusFragmentDoc } from '../../../../views/YoungPersonWhereaboutView/query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type CreateLocationLogMutationVariables = Types.Exact<{
	logData: Types.LogCreateInput;
	locationStatusId: Types.Scalars['String'];
	youngPersonId: Types.Scalars['String'];
	missingAt?: Types.Maybe<Types.Scalars['AWSDateTime']>;
}>;

export type CreateLocationLogMutation = {
	__typename?: 'Mutation';
	createLog?: { __typename?: 'Log'; id: string } | null | undefined;
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				avatar?: string | null | undefined;
				locationStatusId?: string | null | undefined;
				missingAt?: any | null | undefined;
				locationStatus?:
					| {
							__typename?: 'YoungPersonLocationStatus';
							id: string;
							title: string;
							type: Types.YoungPersonLocationStatusType;
							priority?: number | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const CreateLocationLogDocument = gql`
	mutation createLocationLog(
		$logData: LogCreateInput!
		$locationStatusId: String!
		$youngPersonId: String!
		$missingAt: AWSDateTime
	) {
		createLog(data: $logData) {
			id
		}
		updateYoungPerson(
			where: { id: $youngPersonId }
			data: { locationStatusId: $locationStatusId, missingAt: $missingAt }
		) {
			...YoungPersonWithLocationStatus
		}
	}
	${YoungPersonWithLocationStatusFragmentDoc}
`;
export type CreateLocationLogMutationFn = Apollo.MutationFunction<
	CreateLocationLogMutation,
	CreateLocationLogMutationVariables
>;

/**
 * __useCreateLocationLogMutation__
 *
 * To run a mutation, you first call `useCreateLocationLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationLogMutation, { data, loading, error }] = useCreateLocationLogMutation({
 *   variables: {
 *      logData: // value for 'logData'
 *      locationStatusId: // value for 'locationStatusId'
 *      youngPersonId: // value for 'youngPersonId'
 *      missingAt: // value for 'missingAt'
 *   },
 * });
 */
export function useCreateLocationLogMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateLocationLogMutation,
		CreateLocationLogMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CreateLocationLogMutation,
		CreateLocationLogMutationVariables
	>(CreateLocationLogDocument, options);
}
export type CreateLocationLogMutationHookResult = ReturnType<
	typeof useCreateLocationLogMutation
>;
export type CreateLocationLogMutationResult =
	Apollo.MutationResult<CreateLocationLogMutation>;
export type CreateLocationLogMutationOptions = Apollo.BaseMutationOptions<
	CreateLocationLogMutation,
	CreateLocationLogMutationVariables
>;
