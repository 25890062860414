import React, { useEffect, useContext } from 'react';
import { filterFalsy } from '../../utils';
import useFileDownload from '../../hooks/useFileDownload';
import useUploadFile from '../../hooks/useUploadFile';
import FilesView, { handleUploadParams } from './FilesView';
import {
	useGetHouseFilesLazyQuery,
	useGetYoungPersonFilesLazyQuery,
} from './query.generated';
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { File as ClientFile, Maybe } from '../../graphql/types';
import ReadFileView from '../ReadFileView';

type FilesViewContainerProps = {
	youngPersonId?: string;
	houseId?: string;
	folderKey?: string;
	onFetchedFolders?: (folders: string[]) => void;
};

const FilesViewContainer: React.FC<FilesViewContainerProps> = (props) => {
	const { open } = useContext(ModalContext);

	const [fetchYoungPersonFiles, { data: youngPersonData, loading }] =
		useGetYoungPersonFilesLazyQuery();

	const { upload, uploadProgress } = useUploadFile();

	const [fetchHouseFiles, { data: houseData, loading: houseLoading }] =
		useGetHouseFilesLazyQuery();

	useEffect(() => {
		if (props.youngPersonId && props.houseId) {
			// Fetch the young person files
			fetchYoungPersonFiles({
				variables: {
					youngPersonId: props.youngPersonId,
					houseId: props.houseId,
					folderKey: props.folderKey,
				},
			});
		} else if (props.houseId) {
			// Fetch the house files
			fetchHouseFiles({
				variables: { houseId: props.houseId, folderKey: props.folderKey },
			});
		}
	}, [
		props.folderKey,
		props.houseId,
		props.youngPersonId,
		fetchHouseFiles,
		fetchYoungPersonFiles,
	]);

	const youngPersonFiles = youngPersonData?.listYoungPersonFiles?.files;

	const houseFiles = houseData?.listHouseFiles?.files;

	const dirty = youngPersonFiles ?? houseFiles ?? [];
	const sanitised = filterFalsy(dirty);
	const folders: Set<string> = new Set();

	youngPersonData?.listYoungPersonFiles?.folders?.forEach((folder) => {
		if (folder) {
			folders.add(folder.replace('/', ''));
		}
	});

	houseData?.listHouseFiles?.folders?.forEach((folder) => {
		if (folder) {
			folder.replace('/', '');
			folders.add(folder.replace('/', ''));
		}
	});

	useEffect(() => {
		if (folders.size > 0) {
			props?.onFetchedFolders?.(Array.from(folders));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [folders]);

	const { downloadFile } = useFileDownload();

	const handleDownload = async (fileKey?: Maybe<string>) => {
		if (fileKey) {
			await downloadFile(fileKey);
		}
	};

	const handleUpload = async ({ fileType, file }: handleUploadParams) => {
		if (props.youngPersonId && props.houseId) {
			upload({
				houseId: props.houseId,
				fileType: fileType,
				file: file,
				youngPersonId: props.youngPersonId,
			});
		} else if (props.houseId) {
			upload({ houseId: props.houseId, fileType: fileType, file: file });
		}
	};

	const onViewFile = (fileKey: string) => {
		if (fileKey) {
			open({
				type: ModalType.JSX,
				content: <ReadFileView fileKey={fileKey} />,
			});
		}
	};

	const openUploadModal = () => {
		open({
			type: ModalType['UPLOAD'],
			props: { youngPersonId: props.youngPersonId, houseId: props.houseId },
		});
	};

	const files: ClientFile[] = sanitised.map((file) => {
		return {
			...file,

			key: file.key?.split('/').pop() ?? '',
		};
	});

	return (
		<FilesView
			files={files}
			loading={loading || houseLoading}
			handleDownload={handleDownload}
			uploadProgress={uploadProgress}
			openUploadModal={openUploadModal}
			onViewFile={onViewFile}
			handleUpload={handleUpload}
			houseId={props.houseId}
			youngPersonId={props.youngPersonId}
		/>
	);
};

export default React.memo(FilesViewContainer);
