import AccountPopupView from './AccountPopupView';

type AccountPopupViewContainerProps = {};

const AccountPopupViewContainer: React.FC<AccountPopupViewContainerProps> = (
	props
) => {
	return <AccountPopupView />;
};

export default AccountPopupViewContainer;
