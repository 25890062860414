// Global types (types not confined to one component) go here
import { RiskAssessmentID } from '../hooks/useForm/forms/ypRiskAssessment/config';
import {
	CoachingSession,
	SharedCoachingData,
} from '../views/CreateCoachingSessionView/types';

export type CognitoUser = {};

export type ActivityLogData = {
	note: string;
	date: string;
};

export type LocationLogData = {
	note: string;
	date: string;
	incidentNumber?: string;
};

export type KidPaymentLogData = {
	date: string;
	amountPayed: string;
};

export type IncidentLogData = {
	note: string;
	date: string;
	incidentNumber?: string;
	witnesses?: string;
	location: string;
	category: string[];
	contacted: string[];
};

export type ContactLogData = {
	note: string;
	date: string;
	type: string;
	to: string | undefined;
	from: string | undefined;
};

export type NoteLogData = {
	note: string;
	date: string;
};

export type ManagerOnDutyLogData = {
	managerId: string;
	date: string;
	reason: string;
	adviceGiven?: string;
	note?: string;
};

export type SpinSessionData = {
	date: string;
	trmLevel: string;
	type: 'initial' | 'review' | 'unscheduled';
	spin: SpinSession[];
};

export type CoachingSessionData = Omit<CoachingSession, 'youngPersonId'> &
	SharedCoachingData;

export type Risk = {
	id: RiskAssessmentID;
	value: 'HIGH' | 'MEDIUM' | 'LOW';
};

export type RiskAssessmentData =
	| {
			risks: Risk[];
			note: string;
			updatedBy: string;
			updatedAt: string;
	  }
	| undefined;

export type AboutMeData = {
	triggers: string;
	improveMood: string;
	needSupportWith: string;
	likes: string;
	dislikes: string;
};

export type LocationFilterType = {
	label?: string;
	value?: string;
};

export const SpinSessionQuestions = [
	'Presenting Behaviour',
	'Intervention',
	'Need',
	'What’s working well',
	'What’s not working well',
	'What needs to happen',
];

export type SpinSession = {
	[K in typeof SpinSessionQuestions[number]]: string;
};

export const TrmLevels = [
	{
		label: '1',
		value: 1,
	},
	{
		label: '1/2',
		value: 1.5,
	},
	{
		label: '2',
		value: 2,
	},
	{
		label: '2/3',
		value: 2.5,
	},
	{
		label: '3',
		value: 3,
	},
	{
		label: '3/4',
		value: 3.5,
	},
	{
		label: '4',
		value: 4,
	},
	{
		label: '4/5',
		value: 4.5,
	},
	{
		label: '5',
		value: 5,
	},
	{
		label: '6',
		value: 6,
	},
];
