import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles<Theme, { color: 'primary' | 'secondary' }>(
	(theme) =>
		createStyles({
			button: {
				backgroundColor: ({ color }) => theme.palette[color].main,
				color: theme.palette.grey[50],
				boxShadow: theme.shadows[6],
				// our buttons have slightly larger icons
				// with slightly less padding
				padding: '8px',
				fontSize: '27px',
				'&:hover': {
					backgroundColor: ({ color }) => theme.palette[color].dark,
				},
				aspectRatio: '1 / 1',
			},
		})
);

export type AddButtonComponentProps = {
	onClick?: React.MouseEventHandler;
	disabled?: boolean;
	color?: 'primary' | 'secondary';
};

export const AddButtonComponent: React.FC<AddButtonComponentProps> = ({
	color = 'primary',
	...props
}) => {
	const styles = useStyles({ color });

	return (
		<IconButton
			className={styles.button}
			onClick={props?.onClick}
			disabled={props.disabled}
		>
			<AddIcon fontSize="inherit" />
		</IconButton>
	);
};

export default AddButtonComponent;
