import React, { useContext, useState } from 'react';
import { useAbility } from '@casl/react';
import dayjs from 'dayjs';
import {
	Box,
	Button,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';

// Components
import ModalContentComponent from '../../components/ModalContentComponent';
import FormCardComponent from '../../components/FormCardComponent';
import {
	SecondaryItemType,
	SecondaryItem,
	ModalTitleComponentProps,
} from '../../components/ModalTitleComponent';

// Contexts
import { AbilityContext } from '../../contexts/CanContext';
import ModalContext, { ModalType } from '../../contexts/ModalContext';

// Utils
import { prettifyCaseEnum } from '../../utils/text';

// Types / Queries
import { ApolloError } from '@apollo/client';
import { GetStaffMemberQuery } from './query.generated';
import { FormType } from '../../graphql/types';
import { ErrorType } from '../../components/FallbackComponent';

export type UserModalViewProps = {
	data: GetStaffMemberQuery['getUser'];
	revokeUser: () => void;
	loading: boolean;
	error?: ApolloError;
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing('md'),
		},
		contentWrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing('xs2'),
		},
		copyWrapper: {
			display: 'flex',
			alignItems: 'center',
		},
		title: {
			width: '40%',
			fontWeight: 'bold',
		},
	})
);

const UserModalView: React.FC<UserModalViewProps> = (props) => {
	const classes = useStyles();
	const ability = useAbility(AbilityContext);
	const { open: openModal, close: closeModal } = useContext(ModalContext);
	const [openPopup, setOpenPopup] = useState<boolean>(false);

	// Setup the Secondary action shown in the top bar that the user can perform based
	// on their permission level
	const secondaryItem: SecondaryItem | undefined = ability.can('update', 'User')
		? {
				type: SecondaryItemType.ACTIONS,
				options: [
					{
						label: 'Edit',
						onClick: () => {
							openModal({
								type: ModalType.FORM,
								args: {
									type: FormType.UserUpdate,
									userId: props.data?.id,
									isAdmin: true,
								},
							});
						},
					},
					{
						label: 'Revoke',
						onClick: () => {
							setOpenPopup(true);
						},
					},
				],
		  }
		: undefined;

	const modalTitle: ModalTitleComponentProps = {
		title: `View User`,
		secondaryItem: secondaryItem,
	};

	// Prepare the error object for the modal just in case there is an error getting the data
	const modalError = props.error
		? {
				title: 'Unable to get this user',
				copy: 'There was an issue trying to load this user, please try again later',
				type: ErrorType.WARNING,
		  }
		: undefined;

	// Prepare the array that will display all the user data in the modal
	const dataToDisplay = [
		{
			title: 'Email:',
			value: props.data?.email ?? '',
		},
		{
			title: 'Phone:',
			value: props.data?.phone ?? '',
		},
		{
			title: 'Address:',
			value: props.data?.address ?? '',
		},
		{
			title: 'Role:',
			value: props.data?.role ? prettifyCaseEnum(props.data?.role) : '',
		},
		{
			title: 'Date created:',
			value:
				dayjs(props.data?.createdAt).format('DD/MM/YYYY') ?? 'Unknown Date',
		},
	];

	const onRevokeAccessClick = async () => {
		try {
			await props.revokeUser();
			setOpenPopup(false);
			closeModal();
		} catch (error) {
			console.error('Error', error);
		}
	};
	const onPopupClose = () => {
		setOpenPopup(false);
	};

	return (
		<ModalContentComponent
			modalTitle={modalTitle}
			isLoading={props.loading}
			error={modalError}
		>
			<Box className={classes.wrapper}>
				<FormCardComponent
					label={props.data?.name ?? ''}
					sublabel={props.data?.house?.title ?? ''}
					avatarSrc={props.data?.avatar ?? ''}
				/>

				<Box className={classes.contentWrapper}>
					{dataToDisplay.map((item, index) => (
						<Box key={index} className={classes.copyWrapper}>
							<Typography className={classes.title} variant="body1">
								{item.title}
							</Typography>
							<Typography variant="body1">{item.value}</Typography>
						</Box>
					))}
				</Box>
			</Box>
			<Dialog open={openPopup} onClose={onPopupClose}>
				<DialogTitle>Revoke User?</DialogTitle>
				<DialogContent>
					<Typography variant="body1">
						Are you sure you want to revoke access to Stella for{' '}
						{props.data?.name}?
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={onPopupClose}>Cancel</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={onRevokeAccessClick}
					>
						Revoke Access
					</Button>
				</DialogActions>
			</Dialog>
		</ModalContentComponent>
	);
};

export default UserModalView;
