import UserModalView from './UserModalView';
import { useApolloClient } from '@apollo/client';

// Query
import { useGetStaffMemberQuery } from './query.generated';
import {
	UpdateUserDocument,
	UserWithHouseFragmentDoc,
} from '../../graphql/hooks';
import {
	UpdateUserMutation,
	UpdateUserMutationVariables,
} from '../../graphql/types';

type UserModalViewContainerProps = {
	userId: string;
};

const UserModalViewContainer: React.FC<UserModalViewContainerProps> = (
	props
) => {
	const apolloClient = useApolloClient();
	const { data, error, loading } = useGetStaffMemberQuery({
		variables: {
			id: props.userId,
		},
	});

	const revokeUser = async () => {
		const res = await apolloClient.mutate<
			UpdateUserMutation,
			UpdateUserMutationVariables
		>({
			mutation: UpdateUserDocument,
			variables: {
				id: props.userId,
				data: {
					disabled: true,
				},
			},
			refetchQueries: ['listUsers', UserWithHouseFragmentDoc],
		});
	};

	return (
		<UserModalView
			data={data?.getUser}
			error={error}
			loading={loading}
			revokeUser={revokeUser}
		/>
	);
};

export default UserModalViewContainer;
