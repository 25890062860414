import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { YoungPersonProfileFragmentDoc } from '../../../../views/YoungPersonProfileView/query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type YoungPersonBasicDetailsFragment = {
	__typename?: 'YoungPerson';
	id: string;
	name: string;
	preferredName?: string | null | undefined;
	email?: any | null | undefined;
	phone: string;
	dateOfBirth: any;
	gender: string;
	mostRecentAddress?: string | null | undefined;
	physicalDescription?: string | null | undefined;
	ethnicity?: string | null | undefined;
	spokenLanguage?: string | null | undefined;
	safePlace?: string | null | undefined;
	religion?: string | null | undefined;
};

export type GetYoungPersonBasicDetailsQueryVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
}>;

export type GetYoungPersonBasicDetailsQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				physicalDescription?: string | null | undefined;
				ethnicity?: string | null | undefined;
				spokenLanguage?: string | null | undefined;
				safePlace?: string | null | undefined;
				religion?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type UpdateYoungPersonProfileMutationVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
	data: Types.YoungPersonUpdateInput;
}>;

export type UpdateYoungPersonProfileMutation = {
	__typename?: 'Mutation';
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				ethnicity?: string | null | undefined;
				spokenLanguage?: string | null | undefined;
				safePlace?: string | null | undefined;
				religion?: string | null | undefined;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				registeredOptician?: string | null | undefined;
				registeredDentist?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				photoConcept?: boolean | null | undefined;
				riskAssessment?: any | null | undefined;
				legalStatus?: string | null | undefined;
				ypAdvisor?: string | null | undefined;
				localAuthority?: string | null | undefined;
				physicalDescription?: string | null | undefined;
				aboutMe?: any | null | undefined;
				status?: Types.YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				nextOfKin?:
					| Array<{
							__typename?: 'NextOfKin';
							id: string;
							name: string;
							relation: string;
							phone: string;
							youngPersonId: string;
							contactable?: boolean | null | undefined;
							visitable?: boolean | null | undefined;
							note?: string | null | undefined;
					  }>
					| null
					| undefined;
				medicalConditions?:
					| Array<{
							__typename?: 'MedicalCondition';
							id: string;
							title: string;
							medication?: string | null | undefined;
							administrationDetails?: string | null | undefined;
							symptoms?: string | null | undefined;
							triggers?: string | null | undefined;
							inEmergency?: string | null | undefined;
							youngPersonId: string;
					  }>
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const YoungPersonBasicDetailsFragmentDoc = gql`
	fragment YoungPersonBasicDetails on YoungPerson {
		id
		name
		preferredName
		email
		phone
		dateOfBirth
		gender
		mostRecentAddress
		physicalDescription
		ethnicity
		spokenLanguage
		safePlace
		religion
	}
`;
export const GetYoungPersonBasicDetailsDocument = gql`
	query GetYoungPersonBasicDetails($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			...YoungPersonBasicDetails
		}
	}
	${YoungPersonBasicDetailsFragmentDoc}
`;

/**
 * __useGetYoungPersonBasicDetailsQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonBasicDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonBasicDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonBasicDetailsQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonBasicDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYoungPersonBasicDetailsQuery,
		GetYoungPersonBasicDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetYoungPersonBasicDetailsQuery,
		GetYoungPersonBasicDetailsQueryVariables
	>(GetYoungPersonBasicDetailsDocument, options);
}
export function useGetYoungPersonBasicDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYoungPersonBasicDetailsQuery,
		GetYoungPersonBasicDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetYoungPersonBasicDetailsQuery,
		GetYoungPersonBasicDetailsQueryVariables
	>(GetYoungPersonBasicDetailsDocument, options);
}
export type GetYoungPersonBasicDetailsQueryHookResult = ReturnType<
	typeof useGetYoungPersonBasicDetailsQuery
>;
export type GetYoungPersonBasicDetailsLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonBasicDetailsLazyQuery
>;
export type GetYoungPersonBasicDetailsQueryResult = Apollo.QueryResult<
	GetYoungPersonBasicDetailsQuery,
	GetYoungPersonBasicDetailsQueryVariables
>;
export const UpdateYoungPersonProfileDocument = gql`
	mutation UpdateYoungPersonProfile(
		$youngPersonId: String!
		$data: YoungPersonUpdateInput!
	) {
		updateYoungPerson(where: { id: $youngPersonId }, data: $data) {
			...YoungPersonProfile
		}
	}
	${YoungPersonProfileFragmentDoc}
`;
export type UpdateYoungPersonProfileMutationFn = Apollo.MutationFunction<
	UpdateYoungPersonProfileMutation,
	UpdateYoungPersonProfileMutationVariables
>;

/**
 * __useUpdateYoungPersonProfileMutation__
 *
 * To run a mutation, you first call `useUpdateYoungPersonProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoungPersonProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoungPersonProfileMutation, { data, loading, error }] = useUpdateYoungPersonProfileMutation({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateYoungPersonProfileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateYoungPersonProfileMutation,
		UpdateYoungPersonProfileMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UpdateYoungPersonProfileMutation,
		UpdateYoungPersonProfileMutationVariables
	>(UpdateYoungPersonProfileDocument, options);
}
export type UpdateYoungPersonProfileMutationHookResult = ReturnType<
	typeof useUpdateYoungPersonProfileMutation
>;
export type UpdateYoungPersonProfileMutationResult =
	Apollo.MutationResult<UpdateYoungPersonProfileMutation>;
export type UpdateYoungPersonProfileMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateYoungPersonProfileMutation,
		UpdateYoungPersonProfileMutationVariables
	>;
