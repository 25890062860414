import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Add, Edit, Delete } from '@material-ui/icons';

export type DetailsCardIconComponentProps = {
	type: 'add' | 'edit' | 'delete';
	onClick: () => void;
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		button: {
			backgroundColor: theme.palette.primary.pale,
			color: theme.palette.primary.dark,
			boxShadow: theme.shadows[6],
		},
	})
);

const DetailsCardIconComponent: React.FC<DetailsCardIconComponentProps> = (
	props
) => {
	const styles = useStyles();

	const Icon = (() => {
		if (props.type === 'add') return Add;
		if (props.type === 'edit') return Edit;
		if (props.type === 'delete') return Delete;
		// default
		return Add;
	})();

	return (
		<IconButton size="small" onClick={props.onClick} className={styles.button}>
			<Icon />
		</IconButton>
	);
};

export default DetailsCardIconComponent;
