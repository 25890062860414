import React, { useContext, useState } from 'react';
import {
	Box,
	IconButton,
	Dialog,
	DialogContent,
	DialogActions,
	DialogContentText,
	DialogTitle,
	Button,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

// Components
import AlertComponent from '../../components/AlertComponent';
import ListComponent from '../../components/ListComponent';
import ListTitleComponent from '../../components/ListTitleComponent';
import ListTitleContainerComponent from '../../components/ListTitleContainerComponent';
import FallbackComponent from '../../components/FallbackComponent';
import ListFooterComponent from '../../components/ListFooterComponent';
import PaginationCountFeedbackComponent from '../../components/PaginationCountFeedbackComponent';
import PaginationControlsComponent from '../../components/PaginationControlsComponent';

// Queries
import { ListAlertsQueryHookResult } from './listAlerts.generated';
import { FormType } from '../../graphql/types';

// Context
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { Can } from '../../contexts/CanContext';

// Types
import { SeverityLevel } from '../../graphql/types';
export type AlertViewProps = {
	data: ListAlertsQueryHookResult['data'];
	loading: ListAlertsQueryHookResult['loading'];
	error: ListAlertsQueryHookResult['error'];
	hideTitle?: boolean;
	hideName?: boolean;
	currentCount: number;
	totalCount: number;
	currentPage: number;
	isFirstPage: boolean;
	isLastPage: boolean;
	houseId?: string;
	handlePaginate: (i: number) => void;
	dismissAlert: (id: string) => void;
};

const AlertView: React.FC<AlertViewProps> = (props) => {
	const hasAlerts = Boolean(props.data?.listNotifications?.length);
	const [selectedAlert, setSelectedAlert] = useState<string | undefined>();
	const { open } = useContext(ModalContext);

	const onAlertClick = () => {
		open({
			type: ModalType.FORM,
			args: { type: FormType.CreateAlert, houseId: props.houseId },
		});
	};

	const onDialogClose = () => {
		setSelectedAlert(undefined);
	};

	const Alerts = () => (
		<Can I="update" a="Notification" passThrough>
			{(allowed: boolean) => (
				<Box flexGrow={1}>
					<ListComponent size="sm">
						{props.data?.listNotifications?.map((alert) => {
							return (
								<AlertComponent
									key={alert?.id}
									label={alert?.title ?? ''}
									subLabel={
										!props.hideName
											? alert?.youngPerson?.name ?? undefined
											: undefined
									}
									severity={
										alert?.severity === SeverityLevel.High ? 'error' : 'warning'
									}
									variant="filled"
									dismissible={allowed}
									onDismiss={() => {
										setSelectedAlert(alert?.id);
									}}
								/>
							);
						})}
					</ListComponent>
				</Box>
			)}
		</Can>
	);

	return (
		<>
			<Box
				height="100%"
				width="100%"
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
			>
				{!props.hideTitle && (
					<ListTitleContainerComponent>
						<ListTitleComponent label="Alerts" />
						<Can I="create" a="Notification">
							<IconButton onClick={onAlertClick} size="small">
								<AddIcon fontSize="small" />
							</IconButton>
						</Can>
					</ListTitleContainerComponent>
				)}
				<FallbackComponent
					hideIcon
					hasError={!!props.error}
					errorTitle={'An Error Occurred'}
					errorCopy={
						'We had a little trouble fetching the alerts. Please contact the system administrator if the problem persists.'
					}
					isLoading={!!props.loading}
					isEmpty={!hasAlerts}
					emptyTitle="No Alerts"
					emptyCopy="If there should be an alert, try creating one. Otherwise, kick back and relax."
				>
					<Alerts />
				</FallbackComponent>
				<ListFooterComponent>
					<PaginationCountFeedbackComponent
						listLength={props.totalCount}
						current={props.currentCount}
						loading={props.loading}
						empty={!hasAlerts}
						suffix="Alerts"
					/>
					<PaginationControlsComponent
						handleClickNext={() => props.handlePaginate(props.currentPage + 1)}
						handleClickPrev={() => props.handlePaginate(props.currentPage - 1)}
						disabled={(() => {
							if ((props.isFirstPage && props.isLastPage) || props.loading)
								return 'both';
							if (props.isFirstPage) return 'prev';
							if (props.isLastPage) return 'next';
							return;
						})()}
					/>
				</ListFooterComponent>
			</Box>
			<Dialog open={!!selectedAlert} onClose={onDialogClose}>
				<DialogTitle>Do you want to dismiss this alert?</DialogTitle>
				<DialogContent>
					<DialogContentText>
						By dismissing this alert, this will be dismissed for everyone on the
						STELLA platform. Are you sure you want to do this?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onDialogClose} color="primary">
						Close
					</Button>
					<Button
						onClick={async () => {
							await props.dismissAlert(selectedAlert ?? '');
							onDialogClose();
						}}
						variant="contained"
						color="primary"
					>
						Dismiss Alert
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AlertView;
