import * as React from 'react';
import ButtonCardComponent from '../ButtonCardComponent';
import SelectCardComponent from '../SelectCardComponent';

export type ActionCardProps = {
	actionLabel: string;
	onClick?: () => void;
	variant: 'select' | 'button';
};

const ActionCardComponent: React.FC<ActionCardProps> = (props) => {
	const CardComponent =
		props.variant === 'select' ? SelectCardComponent : ButtonCardComponent;

	// action cards have no 'checked' state, but can have the radio style
	return (
		<CardComponent
			onClick={props.onClick}
			checked={false}
			label={props.actionLabel}
		/>
	);
};

export default ActionCardComponent;
