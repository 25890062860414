import * as React from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import useAvatar from '../../hooks/useAvatar';
import useIsHovered from '../../hooks/useIsHovered';
import { AvatarUserType } from '../../graphql/types';
import CloudUpload from '@material-ui/icons/CloudUpload';

export type AvatarComponentProps = {
	youngPersonId: string;
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		wrapper: {
			width: '100%',
			height: '100%',
			position: 'relative',
		},
		overlay: {
			position: 'absolute',
			display: 'grid',
			placeItems: 'center',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			opacity: 0,
			background: '#E0E0E0',
			borderRadius: '50%',
			'&[data-visible=true]': {
				opacity: 0.75,
				transition: 'opacity 0.5s ease',
			},
			pointerEvents: 'none',
			transition: 'opacity 0.5s ease',
		},
		label: {
			cursor: 'pointer',
		},
	})
);

/** Nests children in label which control a visually hidden input */

let count = 0;

const AvatarUploadComponent: React.FC<AvatarComponentProps> = (props) => {
	const styles = useStyles();
	const labelRef = React.useRef<HTMLLabelElement>(null);
	const overlayRef = React.useRef<HTMLDivElement>(null);
	const labelIsHovered = useIsHovered<HTMLLabelElement>(labelRef);
	const overlayIsHovered = useIsHovered<HTMLDivElement>(overlayRef);
	const showOverlay = labelIsHovered || overlayIsHovered;

	React.useEffect(() => {
		count++;
	}, []);

	const { upload } = useAvatar();

	const handleUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		if (!file) return;
		upload(props.youngPersonId, file, AvatarUserType.YoungPerson);
	};

	const id = `avatar-upload-form-${count}`;

	return (
		<Box>
			<VisuallyHidden>
				<input
					id={id}
					type="file"
					accept=".jpg, .png"
					onChange={handleUpload}
				/>
			</VisuallyHidden>
			{/* label handles click events for the hidden input */}
			<Box className={styles.wrapper}>
				<Box>
					<label ref={labelRef} htmlFor={id} className={styles.label}>
						{props.children}
					</label>
				</Box>
				<Box
					//@ts-expect-error
					ref={overlayRef}
					className={styles.overlay}
					data-visible={showOverlay}
				>
					<CloudUpload fontSize="large" />
				</Box>
			</Box>
		</Box>
	);
};

export default AvatarUploadComponent;
