import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import {
	BasicUserFragmentDoc,
	BasicTaskFragmentDoc,
} from '../TasksView/query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetTaskWithUserQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetTaskWithUserQuery = {
	__typename?: 'Query';
	getTask?:
		| {
				__typename?: 'Task';
				id: string;
				ref?: string | null | undefined;
				dueDate?: any | null | undefined;
				title: string;
				description?: string | null | undefined;
				createdAt?: any | null | undefined;
				repeat?: Types.RepeatOption | null | undefined;
				repeatEnd?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				isAdmin?: boolean | null | undefined;
				houseId?: string | null | undefined;
				completedAt?: any | null | undefined;
				youngPerson?:
					| { __typename?: 'YoungPerson'; id: string; name: string }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type CompleteTaskMutationVariables = Types.Exact<{
	taskId: Types.Scalars['String'];
	completedAt: Types.Scalars['AWSDateTime'];
	completedBy: Types.Scalars['String'];
}>;

export type CompleteTaskMutation = {
	__typename?: 'Mutation';
	updateTask?:
		| {
				__typename?: 'Task';
				id: string;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				title: string;
				description?: string | null | undefined;
				group?: Types.TaskGroup | null | undefined;
				youngPersonId?: string | null | undefined;
				completedAt?: any | null | undefined;
				repeat?: Types.RepeatOption | null | undefined;
				dueDate?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				createdAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UnCompleteTaskMutationVariables = Types.Exact<{
	taskId: Types.Scalars['String'];
}>;

export type UnCompleteTaskMutation = {
	__typename?: 'Mutation';
	updateTask?:
		| {
				__typename?: 'Task';
				id: string;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				title: string;
				description?: string | null | undefined;
				group?: Types.TaskGroup | null | undefined;
				youngPersonId?: string | null | undefined;
				completedAt?: any | null | undefined;
				repeat?: Types.RepeatOption | null | undefined;
				dueDate?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				createdAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type DeleteTaskMutationVariables = Types.Exact<{
	taskId: Types.Scalars['String'];
}>;

export type DeleteTaskMutation = {
	__typename?: 'Mutation';
	deleteTask?: { __typename?: 'Task'; title: string } | null | undefined;
};

export const GetTaskWithUserDocument = gql`
	query GetTaskWithUser($id: String!) {
		getTask(where: { id: $id }) {
			id
			ref
			dueDate
			title
			description
			createdAt
			repeat
			repeatEnd
			isPrivate
			isAdmin
			houseId
			youngPerson {
				id
				name
			}
			createdBy {
				...BasicUser
			}
			completedAt
			completedBy {
				...BasicUser
			}
			assignedTo {
				...BasicUser
			}
		}
	}
	${BasicUserFragmentDoc}
`;

/**
 * __useGetTaskWithUserQuery__
 *
 * To run a query within a React component, call `useGetTaskWithUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskWithUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskWithUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskWithUserQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetTaskWithUserQuery,
		GetTaskWithUserQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetTaskWithUserQuery, GetTaskWithUserQueryVariables>(
		GetTaskWithUserDocument,
		options
	);
}
export function useGetTaskWithUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetTaskWithUserQuery,
		GetTaskWithUserQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetTaskWithUserQuery,
		GetTaskWithUserQueryVariables
	>(GetTaskWithUserDocument, options);
}
export type GetTaskWithUserQueryHookResult = ReturnType<
	typeof useGetTaskWithUserQuery
>;
export type GetTaskWithUserLazyQueryHookResult = ReturnType<
	typeof useGetTaskWithUserLazyQuery
>;
export type GetTaskWithUserQueryResult = Apollo.QueryResult<
	GetTaskWithUserQuery,
	GetTaskWithUserQueryVariables
>;
export const CompleteTaskDocument = gql`
	mutation CompleteTask(
		$taskId: String!
		$completedAt: AWSDateTime!
		$completedBy: String!
	) {
		updateTask(
			data: {
				completedAt: $completedAt
				completedBy: { connect: { id: $completedBy } }
			}
			where: { id: $taskId }
		) {
			...BasicTask
		}
	}
	${BasicTaskFragmentDoc}
`;
export type CompleteTaskMutationFn = Apollo.MutationFunction<
	CompleteTaskMutation,
	CompleteTaskMutationVariables
>;

/**
 * __useCompleteTaskMutation__
 *
 * To run a mutation, you first call `useCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTaskMutation, { data, loading, error }] = useCompleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      completedAt: // value for 'completedAt'
 *      completedBy: // value for 'completedBy'
 *   },
 * });
 */
export function useCompleteTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CompleteTaskMutation,
		CompleteTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CompleteTaskMutation,
		CompleteTaskMutationVariables
	>(CompleteTaskDocument, options);
}
export type CompleteTaskMutationHookResult = ReturnType<
	typeof useCompleteTaskMutation
>;
export type CompleteTaskMutationResult =
	Apollo.MutationResult<CompleteTaskMutation>;
export type CompleteTaskMutationOptions = Apollo.BaseMutationOptions<
	CompleteTaskMutation,
	CompleteTaskMutationVariables
>;
export const UnCompleteTaskDocument = gql`
	mutation UnCompleteTask($taskId: String!) {
		updateTask(
			data: { completedAt: null, completedBy: { disconnect: true } }
			where: { id: $taskId }
		) {
			...BasicTask
		}
	}
	${BasicTaskFragmentDoc}
`;
export type UnCompleteTaskMutationFn = Apollo.MutationFunction<
	UnCompleteTaskMutation,
	UnCompleteTaskMutationVariables
>;

/**
 * __useUnCompleteTaskMutation__
 *
 * To run a mutation, you first call `useUnCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unCompleteTaskMutation, { data, loading, error }] = useUnCompleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUnCompleteTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UnCompleteTaskMutation,
		UnCompleteTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UnCompleteTaskMutation,
		UnCompleteTaskMutationVariables
	>(UnCompleteTaskDocument, options);
}
export type UnCompleteTaskMutationHookResult = ReturnType<
	typeof useUnCompleteTaskMutation
>;
export type UnCompleteTaskMutationResult =
	Apollo.MutationResult<UnCompleteTaskMutation>;
export type UnCompleteTaskMutationOptions = Apollo.BaseMutationOptions<
	UnCompleteTaskMutation,
	UnCompleteTaskMutationVariables
>;
export const DeleteTaskDocument = gql`
	mutation DeleteTask($taskId: String!) {
		deleteTask(where: { id: $taskId }) {
			title
		}
	}
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
	DeleteTaskMutation,
	DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteTaskMutation,
		DeleteTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
		DeleteTaskDocument,
		options
	);
}
export type DeleteTaskMutationHookResult = ReturnType<
	typeof useDeleteTaskMutation
>;
export type DeleteTaskMutationResult =
	Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
	DeleteTaskMutation,
	DeleteTaskMutationVariables
>;
