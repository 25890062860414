import React from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import {
	createStyles,
	makeStyles,
	Paper,
	Theme,
	Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		icon: {
			fontSize: '32px',
			color: theme.palette.primary.main,
			marginBottom: theme.spacing(2),
		},
		uploadTemplate: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'space-around',
			borderStyle: 'dashed',
			padding: theme.spacing('md'),
			width: '100%',
			cursor: 'pointer',
		},
	})
);

export type UploadFileComponentProps = {
	onClick?: () => void;
	uploadCopy?: string;
	htmlFor?: string;
	hasFile?: boolean;
	fileName?: string;
};

const UploadFileComponent: React.FC<UploadFileComponentProps> = (props) => {
	const classes = useStyles();

	return (
		<label htmlFor={props.htmlFor}>
			<Paper
				className={classes.uploadTemplate}
				variant="outlined"
				onClick={props.onClick}
				elevation={0}
			>
				<PublishIcon className={classes.icon} />
				<Typography variant="body1" color="textSecondary">
					{props.uploadCopy ?? !props.hasFile
						? 'Click here to upload file'
						: props.fileName || 'File selected'}
				</Typography>
			</Paper>
		</label>
	);
};

export default UploadFileComponent;
