import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';

import { useKidReportSnapshotQuery } from './query.generated';
import ReportKidSnapshotView from './ReportKidSnapshotView';

// Types
import { Stats } from './types';
import { LogType } from '../../graphql/types';

export type ReportKidSnapshotViewContainerProps = {
	id: string;
};

const ReportKidSnapshotViewContainer: React.FC<ReportKidSnapshotViewContainerProps> =
	(props) => {
		const [startDate, setStartDate] = useState(dayjs().subtract(1, 'month'));
		const [endDate, setEndDate] = useState(dayjs());
		const [stats, setStats] = useState<Stats>();

		const { data, error, loading } = useKidReportSnapshotQuery({
			variables: {
				id: props.id,
				startDate: dayjs(startDate).format('YYYY-MM-DDThh:mm:ss.sssZ'),
				endDate: dayjs(endDate).format('YYYY-MM-DDThh:mm:ss.sssZ'),
			},
		});

		useEffect(() => {
			if (error) return;
			if (loading) return;

			const tempStats: Stats = {
				incidentCount: data?.countLogs ?? 0,
				missingCount: 0,
				unauthorizedCount: 0,
				authorizedCount: 0,
				lifeSkillSessions: 0,
			};

			data?.listLogs?.forEach((log) => {
				// Check if the log is a location log to then find out the type
				if (log?.type === LogType.Location) {
					const data = JSON.parse(log?.data);
					// Check if the log is a location log to then find out the type of it
					if (data.status === 'AUTHORISED') tempStats.authorizedCount++;
					if (data.status === 'UNAUTHORISED') tempStats.unauthorizedCount++;
					if (data.status === 'MISSING') tempStats.missingCount++;
				} else if (log?.type === LogType.CoachingSession) {
					tempStats.lifeSkillSessions++;
				}
			});
			setStats(tempStats);
		}, [data, error, loading]);

		return (
			<ReportKidSnapshotView
				data={stats}
				error={!!error}
				loading={loading}
				startDate={dayjs(startDate).format('YYYY-MM-DD')}
				endDate={dayjs(endDate).format('YYYY-MM-DD')}
				setStartDate={setStartDate}
				setEndDate={setEndDate}
			/>
		);
	};

export default ReportKidSnapshotViewContainer;
