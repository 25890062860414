import * as React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

export type ScreenTitleProps = {
	title: string;
	subTitle: string;
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		root: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing('xs'),
		},
	})
);

const ScreenTitleComponent: React.FC<ScreenTitleProps> = (props) => {
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography color="textPrimary" variant="h1">
				{props.title}
			</Typography>
			<Typography variant="body1" color="textSecondary">
				{props.subTitle}
			</Typography>
		</Box>
	);
};

export default ScreenTitleComponent;
