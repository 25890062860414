import { GetYoungPersonQueryHookResult } from '../query.generated';
import { CardDetail } from '../../../components/DetailsCardComponent';

const MedicalDetailsCard = (args: GetYoungPersonQueryHookResult['data']) => {
	const medicalDetails: CardDetail[] =
		args?.getYoungPerson?.medicalConditions?.map((condition, index) => {
			return {
				id: condition.id,
				canEdit: true,
				canDelete: true,
				title: condition.title,
				values: [
					{
						id: `${condition.id}Symptoms`,
						title: 'Symptoms:',
						copy: condition.symptoms ?? 'N/A',
					},
					{
						id: `${condition.id}Triggers`,
						title: 'Triggers:',
						copy: condition.triggers ?? 'N/A',
					},
					{
						id: `${condition.id}Medication`,
						title: 'Medication:',
						copy: condition.medication ?? 'N/A',
					},
					{
						id: `${condition.id}AdministrationDetails`,
						title: 'Administration Details:',
						copy: condition.administrationDetails ?? 'N/A',
					},
					{
						id: `${condition.id}InEmergency`,
						title: 'Incase of Emergency:',
						copy: condition.inEmergency ?? 'N/A',
					},
				],
			};
		}) ?? [];
	return medicalDetails;
};

export default MedicalDetailsCard;
