import React from 'react';

import {
	Box,
	Typography,
	IconButton,
	Menu,
	MenuItem,
	Theme,
	createStyles,
	makeStyles,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			padding: theme.spacing('lg'),
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		secondaryText: {
			color: 'lightGray',
		},
		title: {
			display: 'flex',
			alignItems: 'center',
			gap: theme.spacing('xs'),
		},
	})
);

export type ModalTitleComponentProps = {
	title: string;
	onBackClick?: () => void;
	secondaryItem?: SecondaryItem;
};

export type SecondaryItem = SecondaryActions | SecondaryText;

type SecondaryActions = {
	type: SecondaryItemType.ACTIONS;
	options: Option[];
};

type SecondaryText = {
	type: SecondaryItemType.TEXT;
	copy: string;
};

export enum SecondaryItemType {
	'ACTIONS' = 'actions',
	'TEXT' = 'text',
}

type Option = {
	label: string;
	onClick?: () => void;
};

const SecondaryMenu: React.FC<{ options: Option[] }> = ({ options }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<IconButton
				aria-controls="simple-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{options.map((option) => {
					const onClick = () => {
						handleClose();
						if (option.onClick) option.onClick();
					};
					return (
						<MenuItem key={option.label} onClick={onClick}>
							{option.label}
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};

const ModalTitleComponent: React.FC<ModalTitleComponentProps> = (props) => {
	const classes = useStyles();

	return (
		<Box className={classes.wrapper}>
			<Box className={classes.title}>
				{props.onBackClick ? (
					<IconButton onClick={props.onBackClick} size="small">
						<ArrowBackIcon />
					</IconButton>
				) : null}
				<Typography variant="h3">{props.title}</Typography>
			</Box>
			{props.secondaryItem?.type === SecondaryItemType.ACTIONS ? (
				<SecondaryMenu options={props.secondaryItem.options} />
			) : null}
			{props.secondaryItem?.type === SecondaryItemType.TEXT ? (
				<Typography className={classes.secondaryText} variant="body1">
					{props.secondaryItem.copy}
				</Typography>
			) : null}
		</Box>
	);
};

export default ModalTitleComponent;
