import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetUploadUrlMutationVariables = Types.Exact<{
	userId: Types.Scalars['String'];
	fileType: Types.Scalars['String'];
	userType: Types.AvatarUserType;
}>;

export type GetUploadUrlMutation = {
	__typename?: 'Mutation';
	createUploadAvatarUrl?:
		| {
				__typename?: 'UrlResponse';
				url: string;
				statusCode?: number | null | undefined;
		  }
		| null
		| undefined;
};

export const GetUploadUrlDocument = gql`
	mutation GetUploadURL(
		$userId: String!
		$fileType: String!
		$userType: AvatarUserType!
	) {
		createUploadAvatarUrl(
			input: { userId: $userId, fileType: $fileType, userType: $userType }
		) {
			url
			statusCode
		}
	}
`;
export type GetUploadUrlMutationFn = Apollo.MutationFunction<
	GetUploadUrlMutation,
	GetUploadUrlMutationVariables
>;

/**
 * __useGetUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUploadUrlMutation, { data, loading, error }] = useGetUploadUrlMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      fileType: // value for 'fileType'
 *      userType: // value for 'userType'
 *   },
 * });
 */
export function useGetUploadUrlMutation(
	baseOptions?: Apollo.MutationHookOptions<
		GetUploadUrlMutation,
		GetUploadUrlMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		GetUploadUrlMutation,
		GetUploadUrlMutationVariables
	>(GetUploadUrlDocument, options);
}
export type GetUploadUrlMutationHookResult = ReturnType<
	typeof useGetUploadUrlMutation
>;
export type GetUploadUrlMutationResult =
	Apollo.MutationResult<GetUploadUrlMutation>;
export type GetUploadUrlMutationOptions = Apollo.BaseMutationOptions<
	GetUploadUrlMutation,
	GetUploadUrlMutationVariables
>;
