import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { Box } from '@material-ui/core';

// Components
import ModalContentComponent from '../../components/ModalContentComponent';
import FormCardComponent from '../../components/FormCardComponent';
import FormFieldsComponent, {
	FormFieldTypes,
} from '../../components/FormFieldsComponent';

// Types
import { GetYoungPersonForCoachingQuery } from '../../graphql/types';

type ExportDataModalViewProps = {
	data: GetYoungPersonForCoachingQuery['getYoungPerson'];
	loading: boolean;
	error: boolean;
	exportData: (
		startDate: string,
		endDate: string,
		type: 'trm' | 'logs'
	) => void;
};

const ExportDataModalView: React.FC<ExportDataModalViewProps> = (props) => {
	const [formLoading, setFormLoading] = useState<boolean>(false);
	const { handleSubmit, control } = useForm({
		mode: 'onBlur',
		shouldFocusError: true,
	});

	const formFields = useMemo(() => {
		return [
			{
				id: 'startDate',
				type: FormFieldTypes.DATE_PICKER,
				validation: {
					required: true,
				},
				config: {
					label: 'Start Date',
					defaultValue: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
				},
			},
			{
				id: 'endDate',
				type: FormFieldTypes.DATE_PICKER,
				validation: {
					required: true,
				},
				config: {
					label: 'End Date',
					defaultValue: dayjs().format('YYYY-MM-DD'),
				},
			},
			{
				id: 'downloadType',
				type: FormFieldTypes.SELECT,
				label: 'Data to Export',
				validation: {
					required: true,
				},
				options: [
					{
						value: 'trm',
						copy: 'TRM Data',
					},
					{
						value: 'logs',
						copy: 'Logs',
					},
				],
			},
		];
	}, []);

	const onSubmit = () => {
		setFormLoading(true);
		handleSubmit(async (data) => {
			try {
				await props.exportData(data.startDate, data.endDate, data.downloadType);
			} catch (e) {
				console.log(e);
			}
			setFormLoading(false);
		})();
	};

	return (
		<ModalContentComponent
			modalTitle={{ title: 'Export Data' }}
			modalButtons={{
				primaryAction: {
					label: 'Export',
					disabled: false,
					loading: formLoading,
					onClick: onSubmit,
				},
			}}
			isLoading={props.loading}
		>
			<FormCardComponent
				label={props.data?.name ?? ''}
				sublabel={props.data?.house?.title ?? ''}
				avatarSrc={
					props.data?.avatar ??
					`https://avatars.dicebear.com/api/avataaars/${props.data?.id}.svg`
				}
			/>
			<Box sx={{ marginTop: '24px' }}>
				{/* @ts-ignore */}
				<FormFieldsComponent fields={formFields} control={control} />
			</Box>
		</ModalContentComponent>
	);
};

export default ExportDataModalView;
