import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Button,
	DialogActions,
} from '@material-ui/core';

export type DeleteDialogProps = {
	open: boolean;
	onClose: () => void;
	onDelete: () => void;
};

const DeleteDialog: React.FC<DeleteDialogProps> = (props) => {
	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle>Delete Log?</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to delete this log? This action can not be
					undone.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onClose}>Cancel</Button>
				<Button onClick={props.onDelete} color="primary" variant="contained">
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DeleteDialog;
