import React from 'react';
import { useTheme } from '@material-ui/core';
import { HomeIconComponent, NoFilesIconComponent } from '../icons';
import FallbackContainer from './common/FallbackContainer';
import FallbackCopy from './common/FallbackCopy';
import FallbackIconContainer from './common/FallbackIconContainer';

export enum EmptyType {
	FILES = 'files',
	HOME = 'home',
}

export type EmptyComponentProps = {
	title?: string;
	copy?: string;
	type?: EmptyType;
	hideIcon?: boolean;
};

export const EmptyComponent: React.FC<EmptyComponentProps> = (props) => {
	const theme = useTheme();

	let Icon;

	if (!props.hideIcon) {
		switch (props.type) {
			case 'home':
				Icon = HomeIconComponent;
				break;
			case 'files':
				Icon = NoFilesIconComponent;
				break;
			default:
				Icon = NoFilesIconComponent;
		}
	}
	return (
		<FallbackContainer>
			<FallbackIconContainer>
				{Icon && <Icon color={theme.palette.primary.main} />}
			</FallbackIconContainer>
			<FallbackCopy title={props.title} copy={props.copy} />
		</FallbackContainer>
	);
};

export default EmptyComponent;
