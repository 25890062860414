import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type YoungPersonAboutMeFragment = {
	__typename?: 'YoungPerson';
	id: string;
	aboutMe?: any | null | undefined;
};

export type GetYoungPersonAboutMeDetailsQueryVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
}>;

export type GetYoungPersonAboutMeDetailsQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				aboutMe?: any | null | undefined;
		  }
		| null
		| undefined;
};

export const YoungPersonAboutMeFragmentDoc = gql`
	fragment YoungPersonAboutMe on YoungPerson {
		id
		aboutMe
	}
`;
export const GetYoungPersonAboutMeDetailsDocument = gql`
	query GetYoungPersonAboutMeDetails($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			...YoungPersonAboutMe
		}
	}
	${YoungPersonAboutMeFragmentDoc}
`;

/**
 * __useGetYoungPersonAboutMeDetailsQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonAboutMeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonAboutMeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonAboutMeDetailsQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonAboutMeDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYoungPersonAboutMeDetailsQuery,
		GetYoungPersonAboutMeDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetYoungPersonAboutMeDetailsQuery,
		GetYoungPersonAboutMeDetailsQueryVariables
	>(GetYoungPersonAboutMeDetailsDocument, options);
}
export function useGetYoungPersonAboutMeDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYoungPersonAboutMeDetailsQuery,
		GetYoungPersonAboutMeDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetYoungPersonAboutMeDetailsQuery,
		GetYoungPersonAboutMeDetailsQueryVariables
	>(GetYoungPersonAboutMeDetailsDocument, options);
}
export type GetYoungPersonAboutMeDetailsQueryHookResult = ReturnType<
	typeof useGetYoungPersonAboutMeDetailsQuery
>;
export type GetYoungPersonAboutMeDetailsLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonAboutMeDetailsLazyQuery
>;
export type GetYoungPersonAboutMeDetailsQueryResult = Apollo.QueryResult<
	GetYoungPersonAboutMeDetailsQuery,
	GetYoungPersonAboutMeDetailsQueryVariables
>;
