import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetYpTasksByGroupQueryVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
	group?: Types.Maybe<Types.TaskGroup>;
}>;

export type GetYpTasksByGroupQuery = {
	__typename?: 'Query';
	listTasks?:
		| Array<
				| {
						__typename?: 'Task';
						id: string;
						title: string;
						group?: Types.TaskGroup | null | undefined;
						fileType?: Types.FileType | null | undefined;
						formType?: Types.FormType | null | undefined;
						completedAt?: any | null | undefined;
						youngPerson?:
							| {
									__typename?: 'YoungPerson';
									id: string;
									houseId?: string | null | undefined;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type MutatedYoungPersonTasksSubscriptionVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
}>;

export type MutatedYoungPersonTasksSubscription = {
	__typename?: 'Subscription';
	onMutatedTask?:
		| {
				__typename?: 'Task';
				id: string;
				ref?: string | null | undefined;
				completedAt?: any | null | undefined;
				description?: string | null | undefined;
				fileType?: Types.FileType | null | undefined;
				youngPersonId?: string | null | undefined;
				title: string;
				formType?: Types.FormType | null | undefined;
				group?: Types.TaskGroup | null | undefined;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				isPrivate?: boolean | null | undefined;
				dueDate?: any | null | undefined;
				repeat?: Types.RepeatOption | null | undefined;
				repeatEnd?: any | null | undefined;
				houseId?: string | null | undefined;
				assignedUserId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export const GetYpTasksByGroupDocument = gql`
	query GetYPTasksByGroup($youngPersonId: String!, $group: TaskGroup) {
		listTasks(
			where: {
				group: { equals: $group }
				youngPersonId: { equals: $youngPersonId }
			}
		) {
			id
			title
			group
			fileType
			formType
			youngPerson {
				id
				houseId
			}
			completedAt
		}
	}
`;

/**
 * __useGetYpTasksByGroupQuery__
 *
 * To run a query within a React component, call `useGetYpTasksByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYpTasksByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYpTasksByGroupQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useGetYpTasksByGroupQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYpTasksByGroupQuery,
		GetYpTasksByGroupQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetYpTasksByGroupQuery,
		GetYpTasksByGroupQueryVariables
	>(GetYpTasksByGroupDocument, options);
}
export function useGetYpTasksByGroupLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYpTasksByGroupQuery,
		GetYpTasksByGroupQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetYpTasksByGroupQuery,
		GetYpTasksByGroupQueryVariables
	>(GetYpTasksByGroupDocument, options);
}
export type GetYpTasksByGroupQueryHookResult = ReturnType<
	typeof useGetYpTasksByGroupQuery
>;
export type GetYpTasksByGroupLazyQueryHookResult = ReturnType<
	typeof useGetYpTasksByGroupLazyQuery
>;
export type GetYpTasksByGroupQueryResult = Apollo.QueryResult<
	GetYpTasksByGroupQuery,
	GetYpTasksByGroupQueryVariables
>;
export const MutatedYoungPersonTasksDocument = gql`
	subscription MutatedYoungPersonTasks($youngPersonId: String!) {
		onMutatedTask(youngPersonId: $youngPersonId) {
			id
			ref
			completedAt
			description
			fileType
			youngPersonId
			title
			formType
			group
			isAdmin
			isAutomated
			isPrivate
			dueDate
			repeat
			repeatEnd
			houseId
			assignedUserId
			createdAt
			updatedAt
			deletedAt
		}
	}
`;

/**
 * __useMutatedYoungPersonTasksSubscription__
 *
 * To run a query within a React component, call `useMutatedYoungPersonTasksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMutatedYoungPersonTasksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMutatedYoungPersonTasksSubscription({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useMutatedYoungPersonTasksSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		MutatedYoungPersonTasksSubscription,
		MutatedYoungPersonTasksSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		MutatedYoungPersonTasksSubscription,
		MutatedYoungPersonTasksSubscriptionVariables
	>(MutatedYoungPersonTasksDocument, options);
}
export type MutatedYoungPersonTasksSubscriptionHookResult = ReturnType<
	typeof useMutatedYoungPersonTasksSubscription
>;
export type MutatedYoungPersonTasksSubscriptionResult =
	Apollo.SubscriptionResult<MutatedYoungPersonTasksSubscription>;
