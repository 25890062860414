import React from 'react';
import {
	Grid,
	Typography,
	createStyles,
	makeStyles,
	Theme,
	Box,
	Link,
	Card,
	CardContent,
	Accordion,
	AccordionDetails,
	AccordionSummary,
} from '@material-ui/core';
import AccordionDividerComponent from '../AccordionDividerComponent';
import DetailsCardIconComponent from '../DetailsCardIconComponent';
import FallbackComponent from '../FallbackComponent';
import { ExpandMore } from '@material-ui/icons';

export type DetailsCardComponentProps = SingleDetails | AccordionDetailsProps;

type CardAccordionComponentProps = CardDetail & {
	onEditClick?: AccordionDetailsProps['onEditClick'];
	onDeleteClick?: AccordionDetailsProps['onDeleteClick'];
};

type HeaderLink = {
	href: string;
	text: string;
};

type DefaultProps = {
	title: string;
	viewAll?: boolean;
	noDataTitle?: string;
	noDataCopy?: string;
	headerLink?: HeaderLink;
};

type SingleDetails = DefaultProps & {
	type: DetailsType.SINGLE;
	canEdit?: boolean;
	onEditClick?: () => void;
	details: Detail[];
	revealedCount?: number;
};

type AccordionDetailsProps = DefaultProps & {
	type: DetailsType.ACCORDION;
	canAdd?: boolean;
	onAddClick: () => void;
	onEditClick: (id: string) => void;
	onDeleteClick: (id: string) => void;
	revealedCount?: number;
	details: CardDetail[];
};

export type Detail = {
	id: string;
	title: string;
	copy: string;
};

export type CardDetail = {
	id: string;
	title: string;
	values: Detail[];
	canEdit?: boolean;
	canDelete?: boolean;
};

export enum DetailsType {
	'SINGLE' = 'single',
	'ACCORDION' = 'accordion',
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			padding: theme.spacing('md'),
			display: 'flex',
			flexDirection: 'column',
			minWidth: theme.spacing(30),
			boxShadow: theme.shadows[2],
		},
		cardHeader: {
			display: 'flex',
			gap: '12px',
			justifyContent: 'space-between',
			alignItems: 'center',
			marginBottom: '16px',
		},
		cardHeaderText: {
			display: 'flex',
			gap: '12px',
			alignItems: 'center',
		},
		cardContent: {
			padding: 'unset',
			margin: 'unset',
			'&:last-child': {
				padding: 'unset',
			},
		},
		detailWrapper: {
			marginBottom: theme.spacing(2),
			flex: '1',
		},
		detailsCard: {
			marginBottom: theme.spacing(4),
			borderBottom: 'solid 1px',
			borderColor: theme.palette.text.primary,
		},
		valueWrapper: {
			marginBottom: theme.spacing(1),
		},
		iconWrapper: {
			textAlign: 'right',
		},
		warningIcon: {
			color: theme.palette.warning.main,
			fontSize: '50px',
		},
		noDataWrapper: {
			textAlign: 'center',
		},
		noDataIcon: {
			maxWidth: '200px',
			width: '100%',
			padding: theme.spacing(3),
		},
		upperWrapper: {
			marginBottom: theme.spacing(1),
			justifyContent: 'center',
			alignItems: 'center',
		},
		accordion: {
			padding: '0px;',
			boxShadow: 'initial',
		},
		accordionSummary: {
			padding: '0px',
		},
		accordionDetails: {
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			backgroundColor: theme.palette.background.default,
		},
		accordionIcon: {
			width: '48px',
		},
		accordionContent: {
			width: 'calc(100% - 96px)',
		},
	})
);

const CardAccordionComponent: React.FC<CardAccordionComponentProps> = (
	props
) => {
	const classes = useStyles();

	return (
		<Accordion className={classes.accordion}>
			<AccordionSummary
				className={classes.accordionSummary}
				expandIcon={<ExpandMore />}
				aria-controls={`${props.title}-content`}
				id={props.title}
			>
				<Typography>{props.title}</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.accordionDetails}>
				<Grid container>
					<Grid item className={classes.accordionContent}>
						{props.values.map((value) => {
							return (
								<Box className={classes.valueWrapper}>
									<Typography component="h6" variant="subtitle2">
										{value.title}
									</Typography>
									<Typography variant="body2">{value.copy}</Typography>
								</Box>
							);
						})}
					</Grid>
					<Grid className={classes.accordionIcon} item>
						{props.canEdit ? (
							<DetailsCardIconComponent
								type="edit"
								onClick={() => {
									if (props.onEditClick) props.onEditClick(props.id);
								}}
							/>
						) : null}
					</Grid>
					<Grid className={classes.accordionIcon} item>
						{props.canDelete ? (
							<DetailsCardIconComponent
								type="delete"
								onClick={() => props.onDeleteClick?.(props.id)}
							/>
						) : null}
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

const SingleDetailsComponent: React.FC<Detail> = (props) => {
	const classes = useStyles();

	return (
		<Box className={classes.detailWrapper}>
			<Typography component="p">{props.copy}</Typography>
			<Typography component="h6" variant="caption" color="textSecondary">
				{props.title}
			</Typography>
		</Box>
	);
};

const AccordionDetailsList: React.FC<{
	details: CardDetail[];
	onEditClick: (id: string) => void;
	onDeleteClick: (id: string) => void;
}> = (props) => (
	<>
		{props.details.map((detail) => (
			<CardAccordionComponent
				key={detail.id}
				{...{
					...detail,
					onEditClick: props.onEditClick,
					onDeleteClick: props.onDeleteClick,
				}}
			/>
		))}
	</>
);

const SingleDetailsList: React.FC<{ details: Detail[] }> = (props) => (
	<>
		{props.details.map((detail) => (
			<SingleDetailsComponent key={detail.id} {...detail} />
		))}
	</>
);

const DetailsCardComponent: React.FC<DetailsCardComponentProps> = (props) => {
	const classes = useStyles();

	/**
	 * Based on the Type of the Card render the main action on the card
	 */
	const RenderMainAction = () => {
		switch (props.type) {
			case DetailsType.SINGLE:
				return props.canEdit && props.onEditClick ? (
					<DetailsCardIconComponent type="edit" onClick={props.onEditClick} />
				) : null;
			case DetailsType.ACCORDION:
				return props.canAdd ? (
					<DetailsCardIconComponent type="add" onClick={props.onAddClick} />
				) : null;
		}
	};

	const alwaysRevealedDetails = props.revealedCount ?? 7;

	const shouldDisplayRevealAll = props.details.length > alwaysRevealedDetails;

	const DetailsList = (() => {
		if (props.type === DetailsType.ACCORDION)
			return () => (
				<>
					<AccordionDetailsList
						details={props.details.filter((_, i) => i < alwaysRevealedDetails)}
						onDeleteClick={props.onDeleteClick}
						onEditClick={props.onEditClick}
					/>
					{shouldDisplayRevealAll && (
						<AccordionDividerComponent
							buttonType="rounded"
							label="View All"
							expandedLabel="View Fewer"
							revealMode="above"
						>
							<AccordionDetailsList
								details={props.details.filter(
									(_, i) => i >= alwaysRevealedDetails
								)}
								onDeleteClick={props.onDeleteClick}
								onEditClick={props.onEditClick}
							/>
						</AccordionDividerComponent>
					)}
				</>
			);
		return () => (
			<>
				<SingleDetailsList
					details={props.details.filter((_, i) => i < alwaysRevealedDetails)}
				/>
				{shouldDisplayRevealAll && (
					<AccordionDividerComponent
						buttonType="rounded"
						label="View All"
						expandedLabel="View Fewer"
						revealMode="above"
					>
						<SingleDetailsList
							details={props.details.filter(
								(_, i) => i >= alwaysRevealedDetails
							)}
						/>
					</AccordionDividerComponent>
				)}
			</>
		);
	})();

	return (
		<Card className={classes.card}>
			{/* <CardHeader
				action={}
				title={props.title}
				titleTypographyProps={{
					variant: 'h3',
					component: 'h3',
				}}
			/> */}
			<Box className={classes.cardHeader}>
				<Box className={classes.cardHeaderText}>
					<Typography variant="h3" component="h3">
						{props.title}
					</Typography>
					{props.headerLink ? (
						<Link href={props.headerLink.href}>{props.headerLink.text}</Link>
					) : null}
				</Box>
				<RenderMainAction />
			</Box>
			<CardContent className={classes.cardContent}>
				<FallbackComponent
					isEmpty={!props?.details.length}
					emptyTitle={`No Details Recorded`}
					emptyCopy={
						props.noDataCopy ||
						`No recorded details exist of the type "${props.title}".`
					}
				>
					<DetailsList />
				</FallbackComponent>
			</CardContent>
		</Card>
	);
};

export default DetailsCardComponent;
