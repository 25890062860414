import React from 'react';

// Components
import { Box, Typography, useTheme } from '@material-ui/core';
import { Home, ExitToApp } from '@material-ui/icons';
import WhereaboutsStatusCardComponent from '../../components/WhereaboutStatusCardComponent';
import PaginationCountFeedbackComponent from '../../components/PaginationCountFeedbackComponent';
import PaginationControlsComponent from '../../components/PaginationControlsComponent';
import List from '../../components/ListComponent';
import ListFooterComponent from '../../components/ListFooterComponent';
import FallbackComponent from '../../components/FallbackComponent';
// Queries
import { YoungPersonLocationStatusType } from '../../graphql/types';
import { GetYoungPeopleAndLocationStatusQueryHookResult } from './query.generated';

// Utils
import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';

export type YoungPersonWhereaboutViewProps = {
	data: GetYoungPeopleAndLocationStatusQueryHookResult['data'];
	loading: GetYoungPeopleAndLocationStatusQueryHookResult['loading'];
	error: GetYoungPeopleAndLocationStatusQueryHookResult['error'];
	handleOnChangeStatus: (id: string, statusId: string) => void;
	handlePaginate: (page: number) => void;
	youngPeopleCount: number;
	currentPage: number;
	pageCount: number;
	isFirstPage: boolean;
	isLastPage: boolean;
};

const YoungPersonWhereaboutView: React.FC<YoungPersonWhereaboutViewProps> = (
	props
) => {
	const theme = useTheme();

	const statuses =
		props.data?.listYoungPersonLocationStatuses?.map((item) => ({
			label: item?.title,
			value: item?.id,
		})) ?? [];

	if (props.error) {
		return (
			<div>
				<Typography variant="h3">Sorry something went wrong</Typography>
			</div>
		);
	}

	const generateIcon = (status?: YoungPersonLocationStatusType) => {
		if (status === 'HOME') return <Home fontSize="small" color="primary" />;
		if (status === 'MISSING')
			return <ExitToApp fontSize="small" color="error" />;
		if (status === 'UNAUTHORISED')
			return (
				<ExitToApp fontSize="small" htmlColor={theme.palette.warning.main} />
			);
		if (status === 'AUTHORISED')
			return (
				<ExitToApp fontSize="small" htmlColor={theme.palette.success.main} />
			);
		// default or custom
		return null;
	};

	const kids = orderBy(
		props.data?.listYoungPeople,
		['locationStatus.priority'],
		['desc']
	);

	return (
		<Box minHeight="20rem">
			<FallbackComponent
				isLoading={props.loading}
				loadingType="skeletonCard"
				isEmpty={kids.length < 1}
				emptyTitle="No Whereabouts"
				emptyCopy="Try assigning a whereabouts status to a kid and come back later."
				hasError={props.error}
				errorTitle="An Error Occurred"
				errorCopy="There was an error fetching the whereabouts statuses. If the problem persists, please contact your system administrator."
			>
				<Box>
					<List size="sm" divider>
						{kids.map((item) => (
							<WhereaboutsStatusCardComponent
								avatar={
									item?.avatar ??
									`https://avatars.dicebear.com/api/avataaars/${item?.id}.svg`
								}
								title={item?.name}
								subtitle={(() => {
									const status = item?.locationStatus?.title;
									const isMissing = status === 'Missing';
									return `${status} ${
										isMissing ? `since ${dayjs(item?.missingAt).fromNow()}` : ''
									}`;
								})()}
								statusIcon={generateIcon(item?.locationStatus?.type)}
								kidId={item?.id ?? ''}
								onChangeStatus={props.handleOnChangeStatus}
								items={statuses ?? []}
							/>
						))}
					</List>
					<ListFooterComponent>
						<PaginationCountFeedbackComponent
							listLength={props.youngPeopleCount ?? 0}
							current={props.data?.listYoungPeople?.length ?? 0}
						/>
						<PaginationControlsComponent
							handleClickNext={() =>
								props.handlePaginate(props.currentPage + 1)
							}
							handleClickPrev={() =>
								props.handlePaginate(props.currentPage - 1)
							}
							disabled={(() => {
								if (props.isFirstPage && props.isLastPage) return 'both';
								if (props.isFirstPage) return 'prev';
								if (props.isLastPage) return 'next';
								return;
							})()}
						/>
					</ListFooterComponent>
				</Box>
			</FallbackComponent>
		</Box>
	);
};

export default YoungPersonWhereaboutView;
