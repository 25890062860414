import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { YoungPersonProfileFragmentDoc } from '../../../../views/YoungPersonProfileView/query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type YoungPersonLegalDetailsFragment = {
	__typename?: 'YoungPerson';
	id: string;
	niNumber?: string | null | undefined;
	registeredGP?: string | null | undefined;
	registeredOptician?: string | null | undefined;
	registeredDentist?: string | null | undefined;
	nhsNumber?: string | null | undefined;
	socialWorker?: string | null | undefined;
	legalStatus?: string | null | undefined;
	ypAdvisor?: string | null | undefined;
	localAuthority?: string | null | undefined;
};

export type GetYoungPersonLegalDetailsQueryVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
}>;

export type GetYoungPersonLegalDetailsQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				registeredOptician?: string | null | undefined;
				registeredDentist?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				legalStatus?: string | null | undefined;
				ypAdvisor?: string | null | undefined;
				localAuthority?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type UpdateYoungPersonProfileMutationVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
	data: Types.YoungPersonUpdateInput;
}>;

export type UpdateYoungPersonProfileMutation = {
	__typename?: 'Mutation';
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				ethnicity?: string | null | undefined;
				spokenLanguage?: string | null | undefined;
				safePlace?: string | null | undefined;
				religion?: string | null | undefined;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				registeredOptician?: string | null | undefined;
				registeredDentist?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				photoConcept?: boolean | null | undefined;
				riskAssessment?: any | null | undefined;
				legalStatus?: string | null | undefined;
				ypAdvisor?: string | null | undefined;
				localAuthority?: string | null | undefined;
				physicalDescription?: string | null | undefined;
				aboutMe?: any | null | undefined;
				status?: Types.YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				nextOfKin?:
					| Array<{
							__typename?: 'NextOfKin';
							id: string;
							name: string;
							relation: string;
							phone: string;
							youngPersonId: string;
							contactable?: boolean | null | undefined;
							visitable?: boolean | null | undefined;
							note?: string | null | undefined;
					  }>
					| null
					| undefined;
				medicalConditions?:
					| Array<{
							__typename?: 'MedicalCondition';
							id: string;
							title: string;
							medication?: string | null | undefined;
							administrationDetails?: string | null | undefined;
							symptoms?: string | null | undefined;
							triggers?: string | null | undefined;
							inEmergency?: string | null | undefined;
							youngPersonId: string;
					  }>
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const YoungPersonLegalDetailsFragmentDoc = gql`
	fragment YoungPersonLegalDetails on YoungPerson {
		id
		niNumber
		registeredGP
		registeredOptician
		registeredDentist
		nhsNumber
		socialWorker
		legalStatus
		ypAdvisor
		localAuthority
	}
`;
export const GetYoungPersonLegalDetailsDocument = gql`
	query GetYoungPersonLegalDetails($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			...YoungPersonLegalDetails
		}
	}
	${YoungPersonLegalDetailsFragmentDoc}
`;

/**
 * __useGetYoungPersonLegalDetailsQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonLegalDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonLegalDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonLegalDetailsQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonLegalDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYoungPersonLegalDetailsQuery,
		GetYoungPersonLegalDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetYoungPersonLegalDetailsQuery,
		GetYoungPersonLegalDetailsQueryVariables
	>(GetYoungPersonLegalDetailsDocument, options);
}
export function useGetYoungPersonLegalDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYoungPersonLegalDetailsQuery,
		GetYoungPersonLegalDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetYoungPersonLegalDetailsQuery,
		GetYoungPersonLegalDetailsQueryVariables
	>(GetYoungPersonLegalDetailsDocument, options);
}
export type GetYoungPersonLegalDetailsQueryHookResult = ReturnType<
	typeof useGetYoungPersonLegalDetailsQuery
>;
export type GetYoungPersonLegalDetailsLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonLegalDetailsLazyQuery
>;
export type GetYoungPersonLegalDetailsQueryResult = Apollo.QueryResult<
	GetYoungPersonLegalDetailsQuery,
	GetYoungPersonLegalDetailsQueryVariables
>;
export const UpdateYoungPersonProfileDocument = gql`
	mutation UpdateYoungPersonProfile(
		$youngPersonId: String!
		$data: YoungPersonUpdateInput!
	) {
		updateYoungPerson(where: { id: $youngPersonId }, data: $data) {
			...YoungPersonProfile
		}
	}
	${YoungPersonProfileFragmentDoc}
`;
export type UpdateYoungPersonProfileMutationFn = Apollo.MutationFunction<
	UpdateYoungPersonProfileMutation,
	UpdateYoungPersonProfileMutationVariables
>;

/**
 * __useUpdateYoungPersonProfileMutation__
 *
 * To run a mutation, you first call `useUpdateYoungPersonProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoungPersonProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoungPersonProfileMutation, { data, loading, error }] = useUpdateYoungPersonProfileMutation({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateYoungPersonProfileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateYoungPersonProfileMutation,
		UpdateYoungPersonProfileMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UpdateYoungPersonProfileMutation,
		UpdateYoungPersonProfileMutationVariables
	>(UpdateYoungPersonProfileDocument, options);
}
export type UpdateYoungPersonProfileMutationHookResult = ReturnType<
	typeof useUpdateYoungPersonProfileMutation
>;
export type UpdateYoungPersonProfileMutationResult =
	Apollo.MutationResult<UpdateYoungPersonProfileMutation>;
export type UpdateYoungPersonProfileMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateYoungPersonProfileMutation,
		UpdateYoungPersonProfileMutationVariables
	>;
