import { Notification } from '../graphql/types';

const constructNotificationLink = (
	notification?: Notification | undefined | null
): string | null => {
	if (!notification) {
		return null;
	}

	if (notification.youngPersonId) {
		return `/kids/${notification.youngPersonId}`;
	}

	if (notification.houseId) {
		return `/homes/${notification.houseId}`;
	}

	if (notification.task && notification.taskId) {
		if (notification.task.youngPersonId) {
			return `/kids/${notification.task.youngPersonId}/tasks/${notification.taskId}`;
		}
		if (notification.task.houseId) {
			return `/homes/${notification.task.houseId}/tasks/${notification.taskId}`;
		}
	}

	if (notification.userId) {
		return `/users/${notification.userId}`;
	}

	return null;
};

export default constructNotificationLink;
