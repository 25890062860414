// Query
import {
	// Get Details
	GetNextOfKinDocument,
	GetNextOfKinQuery,
	GetNextOfKinQueryVariables,
	// Update Details Mutation
	UpdateNextOfKinDocument,
	UpdateNextOfKinMutation,
	UpdateNextOfKinMutationVariables,
	// Create
	CreateNextOfKinDocument,
	CreateNextOfKinMutation,
	CreateNextOfKinMutationVariables,
} from './query.generated';
import { YoungPersonFragmentDoc } from '../../../../graphql/hooks';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient } from '@apollo/client';
import { YpNextOfKinArgs } from '../../types';

export type LogIncidentFormValues = {
	name: string;
	relation: string;
	phone: string;
	visitable?: boolean;
	contactable?: boolean;
	note?: string;
};

const PreFetch = async (
	args: YpNextOfKinArgs,
	client: ApolloClient<object>
) => {
	if (!args.nextOfKinId) return undefined;

	const details = await client.query<
		GetNextOfKinQuery,
		GetNextOfKinQueryVariables
	>({
		query: GetNextOfKinDocument,
		variables: {
			nextOfKinId: args.nextOfKinId ?? '',
		},
	});

	if (!details.data.getNextOfKin || details.error)
		throw new Error('Pre-fetched Data Failed');

	return details.data.getNextOfKin;
};

type Data = GetNextOfKinQuery['getNextOfKin'] | undefined;

const FormFields = (
	args: YpNextOfKinArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'name',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Name:',
				defaultValue: data?.name ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'relation',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Relationship:',
				defaultValue: data?.relation ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'phone',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Phone:',
				defaultValue: data?.phone ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'contactable',
			type: FormFieldTypes.CHECKBOX,
			label: 'Allowed to Contact:',
			config: {
				defaultChecked: data?.contactable ?? false,
				// defaultValue: data?.contactable ?? false,
			},
			validation: {},
		},
		{
			id: 'visitable',
			type: FormFieldTypes.CHECKBOX,
			label: 'Allowed to Visit:',
			config: {
				defaultChecked: data?.visitable ?? false,
				// defaultValue: data?.visitable ?? false as boolean,
			},
			validation: {},
		},
		{
			id: 'note',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Note:',
				defaultValue: data?.note ?? '',
				multiline: true,
				maxRows: 4,
			},
			validation: {
				required: true,
			},
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: YpNextOfKinArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: LogIncidentFormValues): Promise<any> => {
		if (args.nextOfKinId) {
			const updateActivity = await client.mutate<
				UpdateNextOfKinMutation,
				UpdateNextOfKinMutationVariables
			>({
				mutation: UpdateNextOfKinDocument,
				variables: {
					nextOfKinId: args.nextOfKinId ?? '',
					data: formValues,
				},
			});
			return updateActivity;
		} else {
			const createActivity = await client.mutate<
				CreateNextOfKinMutation,
				CreateNextOfKinMutationVariables
			>({
				mutation: CreateNextOfKinDocument,
				variables: {
					data: {
						...formValues,
						youngPersonId: args.youngPersonId ?? '',
						createdBy: userId,
					},
				},
				refetchQueries: [YoungPersonFragmentDoc, 'GetYoungPerson'],
			});

			return createActivity;
		}
	};
	return onSubmit;
};

const NextOfKinForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default NextOfKinForm;
