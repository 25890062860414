import React, { useState, useEffect, useContext } from 'react';
import { ApolloError } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

// Components
import ModalContentComponent from '../../components/ModalContentComponent';
import FormFieldsComponent, {
	FormFieldsComponentProps,
	FormFieldTypes,
} from '../../components/FormFieldsComponent';

// Context
import ModalContext from '../../contexts/ModalContext';

import { ListHousesSelectQuery, ListRoomsSelectQuery } from './query.generated';
import { ErrorType } from '../../components/FallbackComponent';
import { YoungPersonStatus } from '../../graphql/types';

export type YoungPersonAssignHouseModalViewProps = {
	loading: boolean;
	error: ApolloError | undefined;
	houses: ListHousesSelectQuery['listHouses'];
	rooms: ListRoomsSelectQuery['listRooms'];
	onFormSubmit: (values: FormValues) => void;
};

export type FormValues = {
	room?: string;
	house?: string;
	moveInDate?: string;
	status: YoungPersonStatus;
};

const YoungPersonAssignHouseModalView: React.FC<YoungPersonAssignHouseModalViewProps> =
	(props) => {
		const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
		const [formFields, setFormFields] = useState<
			FormFieldsComponentProps['fields']
		>([
			{
				id: 'status',
				type: FormFieldTypes.SELECT,
				label: 'Status:',
				config: {},
				validation: { required: true },
				options: [
					{
						value: YoungPersonStatus.InHome,
						copy: 'Approved',
					},
					{
						value: YoungPersonStatus.Declined,
						copy: 'Declined',
					},
				],
			},
		]);

		const { handleSubmit, control, watch, setValue } = useForm({
			mode: 'onBlur',
			shouldFocusError: true,
		});

		const { close } = useContext(ModalContext);
		const { enqueueSnackbar } = useSnackbar();

		useEffect(() => {
			const subscription = watch((value, { name, type }) => {
				if (name === 'status' && value.status === 'IN_HOME') addHouseField();
				if (name === 'status' && value.status === 'DECLINED') removeFields();
				if (name === 'house') addRoomField(value.house);
			});
			return () => subscription.unsubscribe();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [watch, props.houses, formFields, props.rooms]);

		const addHouseField = () => {
			const tempFields: FormFieldsComponentProps['fields'] = [
				...formFields,
				{
					id: 'moveInDate',
					type: FormFieldTypes.DATE_PICKER,
					config: {
						label: 'Move In Date:',
						InputLabelProps: {
							shrink: true,
						},
					},
					validation: {
						required: true,
					},
				},
				{
					id: 'house',
					type: FormFieldTypes.SELECT,
					label: 'House:',
					config: {},
					validation: { required: true },
					options:
						props.houses?.map((house) => {
							return {
								value: house?.id ?? '',
								copy: house?.title ?? '',
							};
						}) ?? [],
				},
			];

			setFormFields(tempFields);
		};

		const addRoomField = (houseId: string) => {
			const tempFields = formFields.filter((field) => field.id !== 'room');
			setValue('room', undefined);

			const rooms = props.rooms?.filter((room) => room?.houseId === houseId);

			tempFields.push({
				id: 'room',
				type: FormFieldTypes.SELECT,
				label: 'Room:',
				config: {},
				validation: { required: true },
				options:
					rooms?.map((room) => {
						return {
							value: room?.id ?? '',
							copy: room?.name ?? '',
						};
					}) ?? [],
			});
			setFormFields(tempFields);
		};

		const removeFields = () => {
			const tempFields = [...formFields];
			setValue('house', undefined);
			setValue('room', undefined);
			setFormFields(tempFields.filter((field) => field.id === 'status'));
		};

		const modalTitle = {
			title: 'Move in',
		};

		const onFormSubmit = async (formValues: FormValues) => {
			setFormSubmitted(true);
			try {
				await props.onFormSubmit(formValues);
				enqueueSnackbar('Form was successfully submitted.', {
					variant: 'success',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
				close();
			} catch (error) {
				enqueueSnackbar('Sorry something went wrong.', {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			}
			setFormSubmitted(false);
		};

		const modalButton = {
			primaryAction: {
				label: 'submit',
				disabled: formSubmitted,
				loading: formSubmitted,
				onClick: async () => {
					handleSubmit(onFormSubmit)();
				},
			},
		};

		const error = props.error
			? {
					title: 'An Error Occurred',
					copy: 'There was an issue loading this form, please try again later.',
					type: ErrorType.WARNING,
			  }
			: undefined;

		return (
			<ModalContentComponent
				modalTitle={modalTitle}
				modalButtons={modalButton}
				isLoading={props.loading}
				error={error}
			>
				<form>
					<FormFieldsComponent fields={formFields} control={control} />
				</form>
			</ModalContentComponent>
		);
	};

export default YoungPersonAssignHouseModalView;
