export enum RiskAssessmentID {
	RISK_SUICIDE = 'RISK_SUICIDE',
	RISK_AGGRESSIVE = 'RISK_AGGRESSIVE',
	RISK_ARSON = 'RISK_ARSON',
	RISK_SELF_NEGLECT = 'RISK_SELF_NEGLECT',
	RISK_IGNORE_MEDICAL = 'RISK_IGNORE_MEDICAL',
	RISK_MENTAL_HEALTH = 'RISK_MENTAL_HEALTH',
	RISK_SUBSTANCE = 'RISK_SUBSTANCE',
	RISK_ABUSE = 'RISK_ABUSE',
	RISK_PHYSICAL = 'RISK_PHYSICAL',
	RISK_BEHAVIOR = 'RISK_BEHAVIOR',
	RISK_HYGIENE = 'RISK_HYGIENE',
	RISK_ENVIRONMENTAL = 'RISK_ENVIRONMENTAL',
	RISK_YOUNG_PERSON = 'RISK_YOUNG_PERSON',
	RISK_OLDER_PERSON = 'RISK_OLDER_PERSON',
	RISK_CHILDREN = 'RISK_CHILDREN',
	RISK_WOMEN = 'RISK_WOMEN',
	RISK_MEN = 'RISK_MEN',
	RISK_FAMILY = 'RISK_FAMILY',
	RISK_REGIONS = 'RISK_REGIONS',
	RISK_RACE = 'RISK_RACE',
	RISK_LGB = 'RISK_LGB',
	RISK_TRANSGENDER = 'RISK_TRANSGENDER',
	RISK_DISABILITIES = 'RISK_DISABILITIES',
	RISK_STAFF = 'RISK_STAFF',
}

export type Question = {
	id: RiskAssessmentID;
	question: string;
	step: number;
};

export const RiskAssessmentQuestions: Question[] = [
	{
		id: RiskAssessmentID.RISK_SUICIDE,
		question: 'Risk of suicide or deliberate self harm',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_AGGRESSIVE,
		question: 'Risk of aggressive behaviour/violence',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_ARSON,
		question: 'Risk of suicide or deliberate self harm',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_SELF_NEGLECT,
		question: 'Risk of self neglect or accidental self harm',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_IGNORE_MEDICAL,
		question:
			'Risk of non-compliance with professional medical advice/treatment',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_MENTAL_HEALTH,
		question: 'Risk due to mental ill health',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_SUBSTANCE,
		question: 'Risk due to alcohol or substance misuse',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_ABUSE,
		question: 'Risk of abuse by others',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_PHYSICAL,
		question: 'Risk due to physical/medical condition',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_HYGIENE,
		question: 'Risk to the health of others (for example hygiene risk)',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_ENVIRONMENTAL,
		question: 'Risk due to environmental factors',
		step: 1,
	},
	{
		id: RiskAssessmentID.RISK_YOUNG_PERSON,
		question: 'A risk towards Young adults',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_OLDER_PERSON,
		question: 'A risk towards Older people',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_CHILDREN,
		question: 'A risk towards Children',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_WOMEN,
		question: 'A risk towards Women',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_MEN,
		question: 'A risk towards Men',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_FAMILY,
		question: 'A risk towards Family members',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_REGIONS,
		question: 'A risk towards Religious groups',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_RACE,
		question:
			'A risk towards Any ethnic or racial groups (please specify in notes section)',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_LGB,
		question: 'A risk towards Lesbian, gay or bisexual people',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_TRANSGENDER,
		question: 'A risk towards Transgendered people',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_DISABILITIES,
		question: 'A risk towards People with disabilities',
		step: 2,
	},
	{
		id: RiskAssessmentID.RISK_STAFF,
		question: 'A risk towards Staff',
		step: 2,
	},
];
