import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { FormType } from '../../graphql/types';
import {
	useGetYoungPersonQuery,
	YoungPersonProfileSubscriptionDocument,
	MedicalConditionsSubscriptionDocument,
	NextOfKinSubscriptionDocument,
	useDeleteNextOfKinMutation,
	useDeleteMedicalConditionMutation,
	YoungPersonProfileFragmentDoc,
} from './query.generated';

import YoungPersonProfileView, { PopupContent } from './YoungPersonProfileView';

type YoungPersonProfileViewContainerProps = {
	youngPersonId?: string;
};
type Params = {
	youngPersonId?: string;
	houseId: string;
};

const YoungPersonProfileViewContainer: React.FC<YoungPersonProfileViewContainerProps> =
	(props) => {
		const params = useParams<Params>();
		const [popupContent, setPopupContent] = useState<
			PopupContent | undefined
		>();
		const [openPopup, setOpenPopup] = useState(false);
		const { open, isOpen } = useContext(ModalContext);

		const ypId = props.youngPersonId ?? params.youngPersonId ?? '';

		const {
			data,
			loading,
			error,
			refetch,
			subscribeToMore = () => null,
		} = useGetYoungPersonQuery({
			errorPolicy: 'all',
			variables: { youngPersonId: ypId },
		});

		useEffect(() => {
			if (!isOpen && !loading) refetch();
		}, [isOpen]);

		/*
		 * This useEffect handles watching for changes on the YP profile, and can also detect changes when the values of a Medical Condition or Next of Kin but DOES NOT trigger when one of those is added or deleted
		 */
		useEffect(() => {
			subscribeToMore({
				document: YoungPersonProfileSubscriptionDocument,
				variables: {
					id: ypId,
				},
			});
			subscribeToMore({
				document: MedicalConditionsSubscriptionDocument,
				variables: {
					id: ypId,
				},
			});
			subscribeToMore({
				document: NextOfKinSubscriptionDocument,
				variables: {
					id: ypId,
				},
			});
		});

		if (error) {
			console.log(error);
		}

		const onActionClick = async (
			formType: FormType,
			actionType: 'ADD' | 'EDIT' | 'DELETE',
			id?: string
		) => {
			if (!data?.getYoungPerson) return;

			if (actionType === 'DELETE') {
				const type =
					formType === FormType.YpNextOfKin
						? 'Next of Kin'
						: 'Medical Condition';

				setOpenPopup(true);
				setPopupContent({
					title: `Are you sure?`,
					copy: `Are you sure you want to delete this ${type}`,
					primaryAction: {
						copy: 'Delete',
						onClick: async () => {
							switch (formType) {
								case FormType.YpNextOfKin:
									await deleteNextOfKin(id ?? '');
									break;
								case FormType.YpMedicalDetails:
									await deleteMedicalCondition(id ?? '');
									break;
							}

							setPopupContent(undefined);
							setOpenPopup(false);
						},
					},
					secondaryAction: {
						copy: 'Close',
						onClick: () => {
							setOpenPopup(false);
						},
					},
				});
			}

			if (actionType === 'ADD' || actionType === 'EDIT') {
				let formArgs: object = { youngPersonId: data?.getYoungPerson?.id };
				if (formType === FormType.YpMedicalDetails && actionType === 'EDIT') {
					formArgs = {
						youngPersonId: data?.getYoungPerson?.id,
						medicalConditionId: id,
					};
				}
				if (formType === FormType.YpNextOfKin && actionType === 'EDIT') {
					formArgs = {
						youngPersonId: data?.getYoungPerson?.id,
						nextOfKinId: id,
					};
				}

				open({
					type: ModalType.FORM,
					args: {
						// @ts-expect-error
						type: formType as FormType,
						...formArgs,
					},
				});
			}
		};

		const [deleteNextOfKinMutation] = useDeleteNextOfKinMutation();
		const [deleteMedicalConditionMutation] =
			useDeleteMedicalConditionMutation();

		const deleteNextOfKin = async (id: string) => {
			await deleteNextOfKinMutation({
				variables: {
					id,
				},
				refetchQueries: [YoungPersonProfileFragmentDoc, 'GetYoungPerson'],
			});
		};

		const deleteMedicalCondition = async (id: string) => {
			return await deleteMedicalConditionMutation({
				variables: {
					id,
				},
				refetchQueries: [YoungPersonProfileFragmentDoc, 'GetYoungPerson'],
			});
		};

		return (
			<YoungPersonProfileView
				openPopup={openPopup}
				popupContent={popupContent}
				data={data}
				loading={loading}
				error={error}
				onActionClick={onActionClick}
				deleteNextOfKin={deleteNextOfKin}
				deleteMedicalCondition={deleteMedicalCondition}
			/>
		);
	};

export default YoungPersonProfileViewContainer;
