import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type YoungPersonProfileFragment = {
	__typename?: 'YoungPerson';
	id: string;
	name: string;
	preferredName?: string | null | undefined;
	email?: any | null | undefined;
	phone: string;
	dateOfBirth: any;
	gender: string;
	mostRecentAddress?: string | null | undefined;
	ethnicity?: string | null | undefined;
	spokenLanguage?: string | null | undefined;
	safePlace?: string | null | undefined;
	religion?: string | null | undefined;
	niNumber?: string | null | undefined;
	registeredGP?: string | null | undefined;
	registeredOptician?: string | null | undefined;
	registeredDentist?: string | null | undefined;
	nhsNumber?: string | null | undefined;
	socialWorker?: string | null | undefined;
	photoConcept?: boolean | null | undefined;
	riskAssessment?: any | null | undefined;
	legalStatus?: string | null | undefined;
	ypAdvisor?: string | null | undefined;
	localAuthority?: string | null | undefined;
	physicalDescription?: string | null | undefined;
	aboutMe?: any | null | undefined;
	status?: Types.YoungPersonStatus | null | undefined;
	houseId?: string | null | undefined;
	createdAt?: any | null | undefined;
	updatedAt?: any | null | undefined;
	deletedAt?: any | null | undefined;
	nextOfKin?:
		| Array<{
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
				youngPersonId: string;
				contactable?: boolean | null | undefined;
				visitable?: boolean | null | undefined;
				note?: string | null | undefined;
		  }>
		| null
		| undefined;
	medicalConditions?:
		| Array<{
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				inEmergency?: string | null | undefined;
				youngPersonId: string;
		  }>
		| null
		| undefined;
};

export type GetYoungPersonQueryVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
}>;

export type GetYoungPersonQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				ethnicity?: string | null | undefined;
				spokenLanguage?: string | null | undefined;
				safePlace?: string | null | undefined;
				religion?: string | null | undefined;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				registeredOptician?: string | null | undefined;
				registeredDentist?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				photoConcept?: boolean | null | undefined;
				riskAssessment?: any | null | undefined;
				legalStatus?: string | null | undefined;
				ypAdvisor?: string | null | undefined;
				localAuthority?: string | null | undefined;
				physicalDescription?: string | null | undefined;
				aboutMe?: any | null | undefined;
				status?: Types.YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				nextOfKin?:
					| Array<{
							__typename?: 'NextOfKin';
							id: string;
							name: string;
							relation: string;
							phone: string;
							youngPersonId: string;
							contactable?: boolean | null | undefined;
							visitable?: boolean | null | undefined;
							note?: string | null | undefined;
					  }>
					| null
					| undefined;
				medicalConditions?:
					| Array<{
							__typename?: 'MedicalCondition';
							id: string;
							title: string;
							medication?: string | null | undefined;
							administrationDetails?: string | null | undefined;
							symptoms?: string | null | undefined;
							triggers?: string | null | undefined;
							inEmergency?: string | null | undefined;
							youngPersonId: string;
					  }>
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type DeleteNextOfKinMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type DeleteNextOfKinMutation = {
	__typename?: 'Mutation';
	deleteNextOfKin?:
		| {
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				youngPersonId: string;
		  }
		| null
		| undefined;
};

export type DeleteMedicalConditionMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type DeleteMedicalConditionMutation = {
	__typename?: 'Mutation';
	deleteMedicalCondition?:
		| {
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				inEmergency?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				youngPersonId: string;
		  }
		| null
		| undefined;
};

export type YoungPersonProfileSubscriptionSubscriptionVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type YoungPersonProfileSubscriptionSubscription = {
	__typename?: 'Subscription';
	onMutatedYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				photoConcept?: boolean | null | undefined;
				status?: Types.YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type NextOfKinSubscriptionSubscriptionVariables = Types.Exact<{
	[key: string]: never;
}>;

export type NextOfKinSubscriptionSubscription = {
	__typename?: 'Subscription';
	onMutatedNextOfKin?:
		| {
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
		  }
		| null
		| undefined;
};

export type MedicalConditionsSubscriptionSubscriptionVariables = Types.Exact<{
	[key: string]: never;
}>;

export type MedicalConditionsSubscriptionSubscription = {
	__typename?: 'Subscription';
	onMutatedMedicalCondition?:
		| {
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				inEmergency?: string | null | undefined;
		  }
		| null
		| undefined;
};

export const YoungPersonProfileFragmentDoc = gql`
	fragment YoungPersonProfile on YoungPerson {
		id
		name
		preferredName
		email
		phone
		dateOfBirth
		gender
		mostRecentAddress
		ethnicity
		spokenLanguage
		safePlace
		religion
		niNumber
		registeredGP
		registeredOptician
		registeredDentist
		nhsNumber
		socialWorker
		photoConcept
		riskAssessment
		legalStatus
		ypAdvisor
		localAuthority
		physicalDescription
		aboutMe
		status
		houseId
		createdAt
		updatedAt
		deletedAt
		nextOfKin {
			id
			name
			relation
			phone
			youngPersonId
			contactable
			visitable
			note
		}
		medicalConditions {
			id
			title
			medication
			administrationDetails
			symptoms
			triggers
			inEmergency
			youngPersonId
		}
	}
`;
export const GetYoungPersonDocument = gql`
	query GetYoungPerson($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			...YoungPersonProfile
		}
	}
	${YoungPersonProfileFragmentDoc}
`;

/**
 * __useGetYoungPersonQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYoungPersonQuery,
		GetYoungPersonQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetYoungPersonQuery, GetYoungPersonQueryVariables>(
		GetYoungPersonDocument,
		options
	);
}
export function useGetYoungPersonLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYoungPersonQuery,
		GetYoungPersonQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetYoungPersonQuery, GetYoungPersonQueryVariables>(
		GetYoungPersonDocument,
		options
	);
}
export type GetYoungPersonQueryHookResult = ReturnType<
	typeof useGetYoungPersonQuery
>;
export type GetYoungPersonLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonLazyQuery
>;
export type GetYoungPersonQueryResult = Apollo.QueryResult<
	GetYoungPersonQuery,
	GetYoungPersonQueryVariables
>;
export const DeleteNextOfKinDocument = gql`
	mutation DeleteNextOfKin($id: String!) {
		deleteNextOfKin(where: { id: $id }) {
			id
			name
			relation
			phone
			createdAt
			updatedAt
			deletedAt
			youngPersonId
		}
	}
`;
export type DeleteNextOfKinMutationFn = Apollo.MutationFunction<
	DeleteNextOfKinMutation,
	DeleteNextOfKinMutationVariables
>;

/**
 * __useDeleteNextOfKinMutation__
 *
 * To run a mutation, you first call `useDeleteNextOfKinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNextOfKinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNextOfKinMutation, { data, loading, error }] = useDeleteNextOfKinMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNextOfKinMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteNextOfKinMutation,
		DeleteNextOfKinMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		DeleteNextOfKinMutation,
		DeleteNextOfKinMutationVariables
	>(DeleteNextOfKinDocument, options);
}
export type DeleteNextOfKinMutationHookResult = ReturnType<
	typeof useDeleteNextOfKinMutation
>;
export type DeleteNextOfKinMutationResult =
	Apollo.MutationResult<DeleteNextOfKinMutation>;
export type DeleteNextOfKinMutationOptions = Apollo.BaseMutationOptions<
	DeleteNextOfKinMutation,
	DeleteNextOfKinMutationVariables
>;
export const DeleteMedicalConditionDocument = gql`
	mutation DeleteMedicalCondition($id: String!) {
		deleteMedicalCondition(where: { id: $id }) {
			id
			title
			medication
			administrationDetails
			symptoms
			triggers
			inEmergency
			createdAt
			updatedAt
			deletedAt
			youngPersonId
		}
	}
`;
export type DeleteMedicalConditionMutationFn = Apollo.MutationFunction<
	DeleteMedicalConditionMutation,
	DeleteMedicalConditionMutationVariables
>;

/**
 * __useDeleteMedicalConditionMutation__
 *
 * To run a mutation, you first call `useDeleteMedicalConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMedicalConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMedicalConditionMutation, { data, loading, error }] = useDeleteMedicalConditionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMedicalConditionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteMedicalConditionMutation,
		DeleteMedicalConditionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		DeleteMedicalConditionMutation,
		DeleteMedicalConditionMutationVariables
	>(DeleteMedicalConditionDocument, options);
}
export type DeleteMedicalConditionMutationHookResult = ReturnType<
	typeof useDeleteMedicalConditionMutation
>;
export type DeleteMedicalConditionMutationResult =
	Apollo.MutationResult<DeleteMedicalConditionMutation>;
export type DeleteMedicalConditionMutationOptions = Apollo.BaseMutationOptions<
	DeleteMedicalConditionMutation,
	DeleteMedicalConditionMutationVariables
>;
export const YoungPersonProfileSubscriptionDocument = gql`
	subscription YoungPersonProfileSubscription($id: String!) {
		onMutatedYoungPerson(id: $id) {
			id
			name
			preferredName
			email
			phone
			dateOfBirth
			gender
			mostRecentAddress
			niNumber
			registeredGP
			nhsNumber
			socialWorker
			photoConcept
			status
			houseId
			createdAt
			updatedAt
			deletedAt
		}
	}
`;

/**
 * __useYoungPersonProfileSubscriptionSubscription__
 *
 * To run a query within a React component, call `useYoungPersonProfileSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useYoungPersonProfileSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYoungPersonProfileSubscriptionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useYoungPersonProfileSubscriptionSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		YoungPersonProfileSubscriptionSubscription,
		YoungPersonProfileSubscriptionSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		YoungPersonProfileSubscriptionSubscription,
		YoungPersonProfileSubscriptionSubscriptionVariables
	>(YoungPersonProfileSubscriptionDocument, options);
}
export type YoungPersonProfileSubscriptionSubscriptionHookResult = ReturnType<
	typeof useYoungPersonProfileSubscriptionSubscription
>;
export type YoungPersonProfileSubscriptionSubscriptionResult =
	Apollo.SubscriptionResult<YoungPersonProfileSubscriptionSubscription>;
export const NextOfKinSubscriptionDocument = gql`
	subscription NextOfKinSubscription {
		onMutatedNextOfKin {
			id
			name
			relation
			phone
		}
	}
`;

/**
 * __useNextOfKinSubscriptionSubscription__
 *
 * To run a query within a React component, call `useNextOfKinSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNextOfKinSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextOfKinSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNextOfKinSubscriptionSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		NextOfKinSubscriptionSubscription,
		NextOfKinSubscriptionSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		NextOfKinSubscriptionSubscription,
		NextOfKinSubscriptionSubscriptionVariables
	>(NextOfKinSubscriptionDocument, options);
}
export type NextOfKinSubscriptionSubscriptionHookResult = ReturnType<
	typeof useNextOfKinSubscriptionSubscription
>;
export type NextOfKinSubscriptionSubscriptionResult =
	Apollo.SubscriptionResult<NextOfKinSubscriptionSubscription>;
export const MedicalConditionsSubscriptionDocument = gql`
	subscription MedicalConditionsSubscription {
		onMutatedMedicalCondition {
			id
			title
			medication
			administrationDetails
			symptoms
			triggers
			inEmergency
		}
	}
`;

/**
 * __useMedicalConditionsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useMedicalConditionsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMedicalConditionsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalConditionsSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMedicalConditionsSubscriptionSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		MedicalConditionsSubscriptionSubscription,
		MedicalConditionsSubscriptionSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		MedicalConditionsSubscriptionSubscription,
		MedicalConditionsSubscriptionSubscriptionVariables
	>(MedicalConditionsSubscriptionDocument, options);
}
export type MedicalConditionsSubscriptionSubscriptionHookResult = ReturnType<
	typeof useMedicalConditionsSubscriptionSubscription
>;
export type MedicalConditionsSubscriptionSubscriptionResult =
	Apollo.SubscriptionResult<MedicalConditionsSubscriptionSubscription>;
