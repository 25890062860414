import React from 'react';
import { Control, UseFormWatch } from 'react-hook-form';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

// Components
import FormCardComponent from '../../components/FormCardComponent';
import FormFieldsComponent, {
	FormFieldTypes,
} from '../../components/FormFieldsComponent';
import {
	ErrorComponent,
	LoadingComponent,
} from '../../components/FallbackComponent/components';

// Query
import { useGetYoungPersonForCoachingQuery } from './query.generated';

// Types
import { FormField } from '../../components/FormFieldsComponent/FormFieldsComponent';

type StepTwoFormProps = {
	youngPersonId: string;
	control: Control;
	watch: UseFormWatch<object>;
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing('md'),
		},
	})
);

const StepTwoForm: React.FC<StepTwoFormProps> = (props) => {
	const classes = useStyles();

	const formFields: FormField[] = [
		{
			id: 'status',
			type: FormFieldTypes.SELECT,
			validation: {
				required: true,
			},
			label: 'Session Status',
			options: [
				{
					value: 'scheduled',
					copy: 'Scheduled',
				},
				{
					value: 'unscheduled',
					copy: 'Unscheduled',
				},
				{
					value: 'noShow',
					copy: 'No Show',
				},
			],
			config: {},
		},
		{
			id: 'note',
			type: FormFieldTypes.INPUT,
			validation: {
				required: true,
			},
			config: {
				label: 'Note',
				multiline: true,
				rowsMax: 5,
			},
		},
		{
			id: 'actions',
			type: FormFieldTypes.INPUT,
			validation: {
				required: true,
			},
			config: {
				label: 'Actions',
				multiline: true,
				rowsMax: 5,
			},
		},
		{
			id: 'concerns',
			type: FormFieldTypes.INPUT,
			validation: {
				required: true,
			},
			config: {
				label: 'Concerns',
				multiline: true,
				rowsMax: 5,
			},
		},
		{
			id: 'suggestions',
			type: FormFieldTypes.INPUT,
			validation: {
				required: true,
			},
			config: {
				label: 'Suggestions',
				multiline: true,
				rowsMax: 5,
			},
		},
	];

	const { data, error, loading } = useGetYoungPersonForCoachingQuery({
		variables: {
			id: props.youngPersonId,
		},
	});

	if (loading) {
		return <LoadingComponent />;
	}

	if (error) {
		return <ErrorComponent />;
	}

	const youngPerson = data?.getYoungPerson;

	return (
		<Box className={classes.wrapper}>
			<FormCardComponent
				label={youngPerson?.name ?? ''}
				sublabel={youngPerson?.house?.title ?? ''}
				avatarSrc={
					youngPerson?.avatar ??
					`https://avatars.dicebear.com/api/avataaars/${props.youngPersonId}.svg`
				}
			/>
			<FormFieldsComponent control={props.control} fields={formFields} />
		</Box>
	);
};

export default StepTwoForm;
