import React from 'react';
import Slider from '@material-ui/core/Slider';
import { Typography, Box } from '@material-ui/core';

export type SliderComponentProps = {
	onChange: (val: string) => void;
	values: string[];
	label: string;
	className?: string;
};

/** Custom slider. Accepts an array of string values, and returns the relevant value onChange, rather than numeric values. */

let counter = 0;

const SliderComponent: React.FC<SliderComponentProps> = (props) => {
	const midIndex = Math.floor(props.values.length / 2);
	const [currentIndex, setCurrentIndex] = React.useState(midIndex);

	React.useEffect(() => {
		counter++;
	}, []);

	const handleChange = (
		event: React.ChangeEvent<{}>,
		newIndex: number | number[]
	) => {
		// because it can return a range
		if (Array.isArray(newIndex)) return;
		setCurrentIndex(newIndex);
		const value = props.values[newIndex];
		props.onChange(value);
	};

	const label_id = `slider-counter-${counter}`;

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				paddingBottom="0.25rem"
			>
				<Typography variant="caption" id={label_id}>
					{props.label}
				</Typography>
				<Typography variant="caption" color="textSecondary">
					{props.values[currentIndex]}
				</Typography>
			</Box>
			<Slider
				className={props.className}
				onChange={handleChange}
				value={currentIndex}
				marks
				aria-labelledby={label_id}
				step={1}
				min={0}
				max={props.values.length - 1}
				valueLabelDisplay="off"
			/>
		</Box>
	);
};

export default SliderComponent;
