import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetMoveOutHouseQueryVariables = Types.Exact<{
	ypId: Types.Scalars['String'];
}>;

export type GetMoveOutHouseQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				house?:
					| { __typename?: 'House'; moveOutHouseId?: string | null | undefined }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UpdateYoungPersonMoveOutMutationVariables = Types.Exact<{
	ypId: Types.Scalars['String'];
	date: Types.Scalars['AWSDateTime'];
	note: Types.Scalars['String'];
	houseId: Types.Scalars['String'];
}>;

export type UpdateYoungPersonMoveOutMutation = {
	__typename?: 'Mutation';
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				moveOutNote?: string | null | undefined;
				moveOutDate?: any | null | undefined;
				houseId?: string | null | undefined;
		  }
		| null
		| undefined;
	updateRoom?:
		| {
				__typename?: 'Room';
				id: string;
				youngPerson?:
					| { __typename?: 'YoungPerson'; id: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const GetMoveOutHouseDocument = gql`
	query getMoveOutHouse($ypId: String!) {
		getYoungPerson(where: { id: $ypId }) {
			house {
				moveOutHouseId
			}
		}
	}
`;

/**
 * __useGetMoveOutHouseQuery__
 *
 * To run a query within a React component, call `useGetMoveOutHouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoveOutHouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoveOutHouseQuery({
 *   variables: {
 *      ypId: // value for 'ypId'
 *   },
 * });
 */
export function useGetMoveOutHouseQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetMoveOutHouseQuery,
		GetMoveOutHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetMoveOutHouseQuery, GetMoveOutHouseQueryVariables>(
		GetMoveOutHouseDocument,
		options
	);
}
export function useGetMoveOutHouseLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetMoveOutHouseQuery,
		GetMoveOutHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetMoveOutHouseQuery,
		GetMoveOutHouseQueryVariables
	>(GetMoveOutHouseDocument, options);
}
export type GetMoveOutHouseQueryHookResult = ReturnType<
	typeof useGetMoveOutHouseQuery
>;
export type GetMoveOutHouseLazyQueryHookResult = ReturnType<
	typeof useGetMoveOutHouseLazyQuery
>;
export type GetMoveOutHouseQueryResult = Apollo.QueryResult<
	GetMoveOutHouseQuery,
	GetMoveOutHouseQueryVariables
>;
export const UpdateYoungPersonMoveOutDocument = gql`
	mutation updateYoungPersonMoveOut(
		$ypId: String!
		$date: AWSDateTime!
		$note: String!
		$houseId: String!
	) {
		updateYoungPerson(
			where: { id: $ypId }
			data: {
				moveOutDate: $date
				moveOutNote: $note
				status: MOVED_OUT
				houseId: $houseId
			}
		) {
			id
			moveOutNote
			moveOutDate
			houseId
		}
		updateRoom(where: { youngPersonId: $ypId }, data: { youngPersonId: null }) {
			id
			youngPerson {
				id
			}
		}
	}
`;
export type UpdateYoungPersonMoveOutMutationFn = Apollo.MutationFunction<
	UpdateYoungPersonMoveOutMutation,
	UpdateYoungPersonMoveOutMutationVariables
>;

/**
 * __useUpdateYoungPersonMoveOutMutation__
 *
 * To run a mutation, you first call `useUpdateYoungPersonMoveOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoungPersonMoveOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoungPersonMoveOutMutation, { data, loading, error }] = useUpdateYoungPersonMoveOutMutation({
 *   variables: {
 *      ypId: // value for 'ypId'
 *      date: // value for 'date'
 *      note: // value for 'note'
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useUpdateYoungPersonMoveOutMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateYoungPersonMoveOutMutation,
		UpdateYoungPersonMoveOutMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UpdateYoungPersonMoveOutMutation,
		UpdateYoungPersonMoveOutMutationVariables
	>(UpdateYoungPersonMoveOutDocument, options);
}
export type UpdateYoungPersonMoveOutMutationHookResult = ReturnType<
	typeof useUpdateYoungPersonMoveOutMutation
>;
export type UpdateYoungPersonMoveOutMutationResult =
	Apollo.MutationResult<UpdateYoungPersonMoveOutMutation>;
export type UpdateYoungPersonMoveOutMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateYoungPersonMoveOutMutation,
		UpdateYoungPersonMoveOutMutationVariables
	>;
