import React, { useContext } from 'react';
import {
	makeStyles,
	createStyles,
	useTheme,
	Theme,
	Paper,
	Avatar,
	Box,
	Link,
	IconButton,
} from '@material-ui/core';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';

// Components
import AvatarUploadComponent from '../AvatarUploadComponent';
import DetailsBlockComponent, {
	DetailsBlockContainer,
} from '../DetailsBlockComponent';

import YoungPersonMoveRoomView from '../../views/YoungPersonMoveRoomView';

// Context
import { Can } from '../../contexts/CanContext';
import ModalContext, { ModalType } from '../../contexts/ModalContext';

// Types
import { FormType } from '../../graphql/types';

export type UserCardComponentProps = {
	userId: string;
	roomId: string;
	houseId: string;
	houseName: string;
	color: string;
	dob: string;
	tel: string;
	avatarSrc?: string;
	user: any;
};

const UserCardComponent: React.FC<UserCardComponentProps> = (props) => {
	const useStyles = makeStyles<Theme>((theme) =>
		createStyles({
			root: {
				backgroundColor: theme.palette.primary.pale,
				padding: theme.spacing('lg'),
				width: '100%',
				boxSizing: 'border-box',
				position: 'relative',
			},
			avatar: {
				width: theme.spacing(24),
				height: theme.spacing(24),
				borderWidth: theme.spacing('xs3'),
				borderColor: '#FFFFFF',
				borderStyle: 'solid',
				boxSizing: 'border-box',
			},
			editButton: {
				position: 'absolute',
				top: theme.spacing('md'),
				right: theme.spacing('md'),
			},
		})
	);

	const styles = useStyles();
	const theme = useTheme<Theme>();
	const { open } = useContext(ModalContext);

	const onRoomClick = () => {
		open({
			type: ModalType.JSX,
			content: <YoungPersonMoveRoomView />,
		});
	};

	return (
		<Paper className={styles.root}>
			<Box
				display="flex"
				justifyContent="flex-start"
				gridGap={theme.spacing('lg')}
			>
				<Box display="grid" alignItems="center" justifyItems="center">
					<AvatarUploadComponent youngPersonId={props.userId}>
						<Avatar src={props.avatarSrc} className={styles.avatar} />
					</AvatarUploadComponent>
				</Box>
				<DetailsBlockContainer>
					<DetailsBlockComponent label={'Room'}>
						{props.houseName}
					</DetailsBlockComponent>
					<DetailsBlockComponent label={'Date of Birth'}>
						{props.dob}
					</DetailsBlockComponent>
					<DetailsBlockComponent label={'Contact No.'}>
						<Link color="inherit" href={`tel:${props.tel}`}>
							{props.tel}
						</Link>
					</DetailsBlockComponent>
				</DetailsBlockContainer>
			</Box>
			<Can I="update" this={props.user}>
				<IconButton
					className={styles.editButton}
					color="primary"
					aria-label="Change Rooms"
					onClick={onRoomClick}
				>
					<MeetingRoomIcon />
				</IconButton>
			</Can>
		</Paper>
	);
};

export default UserCardComponent;
