import React from 'react';
import { Box } from '@material-ui/core';
import FallbackComponent from '../../components/FallbackComponent';
import FileCardComponent from '../../components/FileCardComponent';
import ListComponent from '../../components/ListComponent';
import { File as ClientFile, FileType, Maybe } from '../../graphql/types';

// Utils
import { prettifyCaseEnum } from '../../utils/text';

export type handleUploadParams = {
	fileType: FileType;
	file: File;
};

export type FilesViewProps = {
	files: ClientFile[];
	loading: boolean;
	handleDownload: (key?: Maybe<string>) => void;
	onViewFile: (fileKey: string) => void;
	uploadProgress?: number;
	handleUpload: (params: handleUploadParams) => void;
	houseId?: string;
	youngPersonId?: string;
	openUploadModal?: () => void;
};

const FilesView: React.FC<FilesViewProps> = (props) => {
	return (
		<Box minHeight="6.5rem">
			<FallbackComponent
				isEmpty={props.files.length < 1}
				emptyTitle="No files"
				emptyCopy="Try importing a file and come back later."
				isLoading={Boolean(props.loading)}
				loadingType="skeletonGridSm"
			>
				<ListComponent size="md">
					{props.files.map((file) => (
						<FileCardComponent
							key={file.id}
							onClick={() => file?.id && props.onViewFile(file?.id)}
							label={prettifyCaseEnum(file?.key ?? '')}
							isUploaded={false} //TODO: handle this
						/>
					))}
				</ListComponent>
			</FallbackComponent>
		</Box>
	);
};

export default FilesView;
