import * as React from 'react';
import {
	EmptyComponent,
	LoadingComponent,
	ErrorComponent,
	ErrorType,
	EmptyType,
	LoadingType,
} from './components';

export type FallbackProps = {
	/* error */
	hasError?: boolean;
	errorTitle?: string;
	errorCopy?: string;
	errorType?: ErrorType;
	/* loading */
	isLoading?: boolean;
	loadingTitle?: string;
	loadingCopy?: string;
	loadingType?: LoadingType;
	/* empty */
	isEmpty?: boolean;
	emptyTitle?: string;
	emptyCopy?: string;
	emptyType?: EmptyType;
	hideIcon?: boolean;
};

export const FallbackComponent: React.FC<FallbackProps> = (props) => {
	if (props.hasError)
		return (
			<ErrorComponent
				hideIcon={props.hideIcon}
				type={props.errorType}
				title={props.errorTitle}
				copy={props.errorCopy}
			/>
		);
	if (props.isLoading)
		return (
			<LoadingComponent
				type={props.loadingType}
				title={props.loadingTitle}
				copy={props.loadingCopy}
			/>
		);
	if (props.isEmpty)
		return (
			<EmptyComponent
				hideIcon={props.hideIcon}
				type={props.emptyType}
				title={props.emptyTitle}
				copy={props.emptyCopy}
			/>
		);
	return <>{props.children}</>;
};

export default FallbackComponent;
