import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type KidReportSnapshotQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
	startDate?: Types.Maybe<Types.Scalars['AWSDateTime']>;
	endDate?: Types.Maybe<Types.Scalars['AWSDateTime']>;
}>;

export type KidReportSnapshotQuery = {
	__typename?: 'Query';
	countLogs?: number | null | undefined;
	listLogs?:
		| Array<
				| { __typename?: 'Log'; type: Types.LogType; data: any }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const KidReportSnapshotDocument = gql`
	query KidReportSnapshot(
		$id: String
		$startDate: AWSDateTime
		$endDate: AWSDateTime
	) {
		listLogs(
			where: {
				AND: [
					{ youngPerson: { id: { equals: $id } } }
					{ createdAt: { gte: $startDate } }
					{ createdAt: { lte: $endDate } }
					{
						OR: [
							{ type: { equals: LOCATION } }
							{ type: { equals: COACHING_SESSION } }
						]
					}
				]
			}
			take: 1000
		) {
			type
			data
		}
		countLogs(
			where: {
				AND: [
					{ youngPerson: { id: { equals: $id } } }
					{ createdAt: { gte: $startDate } }
					{ createdAt: { lte: $endDate } }
					{ type: { equals: INCIDENT } }
				]
			}
		)
	}
`;

/**
 * __useKidReportSnapshotQuery__
 *
 * To run a query within a React component, call `useKidReportSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useKidReportSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKidReportSnapshotQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useKidReportSnapshotQuery(
	baseOptions?: Apollo.QueryHookOptions<
		KidReportSnapshotQuery,
		KidReportSnapshotQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		KidReportSnapshotQuery,
		KidReportSnapshotQueryVariables
	>(KidReportSnapshotDocument, options);
}
export function useKidReportSnapshotLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		KidReportSnapshotQuery,
		KidReportSnapshotQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		KidReportSnapshotQuery,
		KidReportSnapshotQueryVariables
	>(KidReportSnapshotDocument, options);
}
export type KidReportSnapshotQueryHookResult = ReturnType<
	typeof useKidReportSnapshotQuery
>;
export type KidReportSnapshotLazyQueryHookResult = ReturnType<
	typeof useKidReportSnapshotLazyQuery
>;
export type KidReportSnapshotQueryResult = Apollo.QueryResult<
	KidReportSnapshotQuery,
	KidReportSnapshotQueryVariables
>;
