import { useListLocationStatusesQuery } from './query.generated';
import YoungPersonLocationStatusSelectView from './YoungPersonLocationStatusSelectView';
import { LocationFilterType } from '../../types';

type YoungPersonLocationStatusSelectViewContainerProps = {
	onFilterByLocation: (filter: LocationFilterType) => void;
	locationFilter?: LocationFilterType;
};

const YoungPersonLocationStatusSelectViewContainer = (
	props: YoungPersonLocationStatusSelectViewContainerProps
) => {
	const { data, loading, error } = useListLocationStatusesQuery();

	return (
		<YoungPersonLocationStatusSelectView
			onFilterByLocation={props.onFilterByLocation}
			locationFilter={props.locationFilter}
			data={data}
			loading={loading}
			error={error}
		/>
	);
};

export default YoungPersonLocationStatusSelectViewContainer;
