import React, { useEffect, useContext } from 'react';

import { Badge, Box, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Notifications } from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import NotificationComponent from '../../components/NotificationComponent';
import { useListMyNotificationsQuery } from '../../graphql/hooks';
import constructNotificationLink from '../../utils/constructNotificaitonLink';
import {
	useMarkAllAsReadMutation,
	useMarkAsReadMutation,
	useNotifyMeSubscription,
} from './ListMyNotifications.generated';
import AuthContext from '../../contexts/authContext';

type NavNotificationViewProps = {};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		typography: {
			padding: theme.spacing(2),
		},
		top: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: theme.spacing(2),
		},
	})
);

const NavNotificationView = (props: NavNotificationViewProps) => {
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(AuthContext);

	const { data, loading, error, refetch } = useListMyNotificationsQuery({
		variables: {
			limit: 10,
			filter: {
				userId: { equals: user?.id ?? undefined },
			},
		},
	});

	const { data: subData } = useNotifyMeSubscription();
	const count = subData?.onCreatedManyNotifications?.count ?? 0;
	useEffect(() => {
		if (count > 0) {
			refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [count]);

	const [markAllAsRead] = useMarkAllAsReadMutation();
	const [markAsRead] = useMarkAsReadMutation();

	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const notifications =
		data?.listNotifications?.filter((notification) => !notification?.readAt) ??
		[];

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMarkAsRead = async (id: string) => {
		const readAt = new Date().toISOString();
		try {
			await markAsRead({
				optimisticResponse: {
					updateNotification: {
						id,
						readAt,
					},
				},
				variables: {
					id,
					readAt,
				},
			});
			// await refetch();
		} catch (error) {
			console.log(`error`, error);
			enqueueSnackbar('Could not mark as read', {
				variant: 'error',
				anchorOrigin: { vertical: 'top', horizontal: 'right' },
			});
		}
	};

	const handleMarkAllAsRead = async () => {
		try {
			const ids: string[] = notifications.flatMap((not) =>
				not?.id ? not.id : []
			);

			if (!ids || ids.length < 1) {
				return;
			}

			await markAllAsRead({
				variables: {
					ids: ids,
					readAt: new Date().toISOString(),
				},
			});
			await refetch();
		} catch (error) {
			console.log(`error`, error);
		}
	};

	if (error) {
		console.log(`error.message`, error.message);
	}

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div>
			<Button aria-describedby={id} variant="text" onClick={handleClick}>
				<Badge badgeContent={notifications.length} color="primary">
					<NotificationsIcon />
				</Badge>
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<Box className={classes.top}>
					<Typography variant="h3">Notifications</Typography>
					<Button
						disabled={notifications.length === 0}
						onClick={handleMarkAllAsRead}
						variant="text"
					>
						Mark all as read
					</Button>
				</Box>
				{error && <Typography>Sorry something went wrong</Typography>}
				{loading && <CircularProgress />}
				{notifications.length === 0 && (
					<Box
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							marginBottom: 20,
						}}
					>
						<Typography variant="h4">Nothing to see here</Typography>
					</Box>
				)}
				{notifications.map((notification) => {
					const to = constructNotificationLink(notification);

					const formattedDate = dayjs(notification?.createdAt).fromNow();
					return (
						<NotificationComponent
							to={to}
							label={notification?.title ?? ''}
							subLabel={formattedDate}
							avatarAlt="notification"
							avatarIcon={<Notifications />}
							notificationId={notification?.id}
							onMarkAsRead={handleMarkAsRead}
						/>
					);
				})}
			</Popover>
		</div>
	);
};

export default NavNotificationView;
