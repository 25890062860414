import { ApolloError } from '@apollo/client';
import { useAbility } from '@casl/react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	createStyles,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import dayjs from 'dayjs';
import React, { useContext } from 'react';

import { ErrorType } from '../../components/FallbackComponent';
import FormCardComponent from '../../components/FormCardComponent';
import ModalContentComponent from '../../components/ModalContentComponent';
import {
	ModalTitleComponentProps,
	SecondaryItemType,
} from '../../components/ModalTitleComponent';
import { AbilityContext } from '../../contexts/CanContext';
import ModalContext from '../../contexts/ModalContext';
import { Subjects } from '../../defineAbility';
import { LogType } from '../../graphql/types';
import { RiskAssessmentQuestions } from '../../hooks/useForm/forms/ypRiskAssessment/config';
import {
	CoachingSessionData,
	ContactLogData,
	KidPaymentLogData,
	RiskAssessmentData,
	SpinSession,
	TrmLevels,
} from '../../types';
import { prettifyCaseEnum } from '../../utils/text';
import DeleteDialog from './DeleteDialog';
import { Log } from './types';

// Components
// Contexts
// Utils
// Types
export type ActivityLogModalViewProps = {
	data: Log;
	title: string;
	onEditClick: () => void;
	onDeleteClick: () => void;
	loading: boolean;
	error?: ApolloError;
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing('md'),
		},
		contentWrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: '4px',
			width: '100%',
		},
		copyWrapper: {
			display: 'flex',
			alignItems: 'center',
			gap: theme.spacing('md'),
			width: '100%',
		},
		title: {
			width: '40%',
			fontWeight: 'bold',
		},
		contentTitle: {
			width: '100%',
			fontWeight: 'bold',
		},
		accordion: {
			padding: '0px;',
			boxShadow: 'initial',
		},
		accordionWrapper: {},
		accordionSummary: {
			padding: '0px',
		},
		accordionDetails: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing('sm'),
		},
	})
);

const ActivityLogModalView: React.FC<ActivityLogModalViewProps> = (props) => {
	const classes = useStyles();
	const ability = useAbility(AbilityContext);
	const { close } = useContext(ModalContext);
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

	const onDeleteClick = async () => {
		setOpenDeleteDialog(false);
		await props.onDeleteClick();
		close();
	};

	let options = [];
	let type: Subjects;

	switch (props.data?.type) {
		case LogType.Incident:
			type = 'IncidentReport';
			break;
		case LogType.Activity:
			type = 'ActivityLog';
			break;
		case LogType.SpinSession:
			type = 'SpinSession';
			break;
		case LogType.RiskAssessment:
			type = 'RiskAssessment';
			break;
		default:
			type = 'None';
	}

	if (ability.can('update', type)) {
		options.push({
			label: 'Edit',
			onClick: props.onEditClick,
		});
	}

	if (ability.can('delete', type)) {
		options.push({
			label: 'Delete',
			onClick: () => setOpenDeleteDialog(true),
		});
	}

	const modalTitle: ModalTitleComponentProps = {
		title: props.title,
		secondaryItem:
			options.length > 0
				? {
						type: SecondaryItemType.ACTIONS,
						options: options,
				  }
				: undefined,
	};

	const modalError = props.error
		? {
				title: 'Unable to get the Log',
				copy: 'There was an issue trying to load this log, please try again later',
				type: ErrorType.WARNING,
		  }
		: undefined;

	const dataToDisplay = [
		{
			title: 'House:',
			value: props.data?.youngPerson?.house?.title ?? 'Unknown House',
		},
		{
			title: 'Created by:',
			value: props.data?.createdByUser?.name ?? 'Unknown User',
		},
		{
			title: 'Date created:',
			value:
				dayjs(props.data?.createdAt).format('DD/MM/YYYY') ?? 'Unknown Date',
		},
	];

	const generic = [LogType.Activity, LogType.Note];

	const LogContent = () => {
		// @ts-expect-error
		if (generic.includes(props.data?.type)) {
			return (
				<MultiLineComponent
					title="Notes"
					copy={props.data?.data?.note ?? '-'}
				/>
			);
		} else if (props.data?.type === LogType.SpinSession) {
			const spinSessions: SpinSession[] = props.data?.data.spin ?? [];

			const trm = TrmLevels.filter(
				(level) => level.value === props.data?.data.trmLevel
			);

			return (
				<>
					<Box>
						<InlineComponent title="TRM Level" copy={trm[0]?.label ?? '-'} />
						<InlineComponent
							title="Session Type"
							copy={props.data.data.type ?? '-'}
						/>
					</Box>
					<Box>
						{spinSessions.map((session, index) => {
							return (
								<Accordion className={classes.accordion} key={index}>
									<AccordionSummary
										className={classes.accordionSummary}
										expandIcon={<ExpandMore />}
										aria-controls={`${props.title}-content`}
									>
										<Typography variant="h3">{`Behaviour ${
											index + 1
										}`}</Typography>
									</AccordionSummary>
									<AccordionDetails className={classes.accordionDetails}>
										{Object.keys(session).map((key) => {
											return (
												<MultiLineComponent
													title={key}
													copy={session[key] ?? '-'}
												/>
											);
										})}
									</AccordionDetails>
								</Accordion>
							);
						})}
					</Box>
				</>
			);
		} else if (props.data?.type === LogType.CoachingSession) {
			const data: CoachingSessionData = props.data?.data;

			return (
				<>
					<MultiLineComponent title="Category" copy={data?.category ?? '-'} />
					<MultiLineComponent
						title="Sub-Category"
						copy={data?.subCategory ?? '-'}
					/>
					<MultiLineComponent title="Status" copy={data?.status ?? '-'} />
					<MultiLineComponent title="Note" copy={data?.note ?? '-'} />
					<MultiLineComponent title="Concerns" copy={data?.concerns ?? '-'} />
					<MultiLineComponent title="Actions" copy={data?.actions ?? '-'} />
				</>
			);
		} else if (props.data?.type === LogType.KidPayment) {
			const data: KidPaymentLogData = props.data?.data;

			return (
				<Box>
					<InlineComponent
						title="Date Paid"
						copy={dayjs(data?.date).format('DD/MM/YYYY') ?? '-'}
					/>
					<InlineComponent
						title="Amount Paid"
						copy={`${data?.amountPayed.toString()}` ?? '-'}
					/>
				</Box>
			);
		} else if (props.data?.type === LogType.Contact) {
			const data: ContactLogData = props.data?.data;

			return (
				<Box>
					<InlineComponent
						title="Date"
						copy={dayjs(data?.date).format('DD/MM/YYYY') ?? '-'}
					/>
					<InlineComponent title="Type" copy={data.type ?? '-'} />
					<InlineComponent title="To" copy={data.to ?? '-'} />
					<InlineComponent title="From" copy={data.from ?? '-'} />
					<MultiLineComponent
						title="Notes"
						copy={props.data?.data?.note ?? '-'}
					/>
				</Box>
			);
		} else if (props.data?.type === LogType.Incident) {
			return (
				<>
					<InlineComponent
						title="Location:"
						copy={props.data?.data?.location ?? '-'}
					/>
					<InlineComponent
						title="Category:"
						copy={props.data?.data?.category?.join(', ') ?? '-'}
					/>
					<InlineComponent
						title="Witnesses:"
						copy={props.data?.data?.witnesses ?? '-'}
					/>
					<InlineComponent
						title="Contacted:"
						copy={props.data?.data?.contacted?.category?.join(', ') ?? '-'}
					/>
					<InlineComponent
						title="Police Incident Report Number"
						copy={props.data?.data?.incidentNumber ?? '-'}
					/>
					<MultiLineComponent
						title="Notes"
						copy={props.data?.data?.note ?? '-'}
					/>
				</>
			);
		} else if (props.data?.type === LogType.Location) {
			return (
				<>
					<MultiLineComponent
						title="Notes"
						copy={props.data?.data?.note ?? '-'}
					/>
					<MultiLineComponent
						title="Police Incident Report Number"
						copy={props.data?.data?.incidentNumber ?? '-'}
					/>
				</>
			);
		} else if (props.data?.type === LogType.MangerOnDuty) {
			return (
				<>
					<MultiLineComponent
						title="Reason for Callout"
						copy={props.data?.data?.reason ?? '-'}
					/>
					<MultiLineComponent
						title="Advice Given"
						copy={props.data?.data?.adviceGiven ?? '-'}
					/>
					<MultiLineComponent
						title="Note"
						copy={props.data?.data?.note ?? '-'}
					/>
					<InlineComponent
						title="Manager:"
						copy={props.data?.data?.managerName ?? '-'}
					/>
				</>
			);
		} else if (props.data?.type === LogType.RiskAssessment) {
			const data: RiskAssessmentData = props.data?.data;

			return (
				<>
					<MultiLineComponent title="Notes" copy={data?.note ?? '-'} />
					{data?.risks.map((risk, index) => {
						const title = RiskAssessmentQuestions.filter(
							(question) => question.id === risk.id
						)[0];

						return (
							<MultiLineComponent
								key={index}
								title={title.question ?? 'Unknown Question'}
								copy={prettifyCaseEnum(risk.value)}
							/>
						);
					})}
				</>
			);
		}
		return null;
	};
	const avatar =
		props.data?.youngPerson?.avatar ??
		`https://avatars.dicebear.com/api/avataaars/${props.data?.youngPerson?.id}.svg`;

	return (
		<>
			<ModalContentComponent
				modalTitle={modalTitle}
				isLoading={props.loading}
				error={modalError}
			>
				<Box className={classes.wrapper}>
					<FormCardComponent
						label={props.data?.youngPerson?.name ?? ''}
						sublabel={props.data?.youngPerson?.house?.title ?? ''}
						avatarSrc={avatar}
					/>

					<LogContent />

					<Box className={classes.contentWrapper}>
						{dataToDisplay.map((item, index) => (
							<InlineComponent
								key={index}
								title={item.title}
								copy={item.value}
							/>
						))}
					</Box>
				</Box>
			</ModalContentComponent>
			<DeleteDialog
				open={openDeleteDialog}
				onClose={() => {
					setOpenDeleteDialog(false);
				}}
				onDelete={onDeleteClick}
			/>
		</>
	);
};

const MultiLineComponent = (props: { title: string; copy: string }) => {
	const classes = useStyles();

	return (
		<Box className={classes.contentWrapper}>
			<Typography className={classes.contentTitle} variant="body1">
				{props.title}:
			</Typography>
			<Typography variant="body1" style={{ whiteSpace: 'pre-wrap' }}>
				{decodeURIComponent(props.copy)}
			</Typography>
		</Box>
	);
};

const InlineComponent = (props: { title: string; copy: string }) => {
	const classes = useStyles();

	return (
		<Box className={classes.copyWrapper}>
			<Typography className={classes.title} variant="body1">
				{props.title}
			</Typography>
			<Typography variant="body1">{props.copy}</Typography>
		</Box>
	);
};

export default ActivityLogModalView;
