import {
	Box,
	CircularProgress,
	CircularProgressProps,
	createStyles,
	IconButton,
	makeStyles,
	Paper,
	Grid,
	Theme,
	Typography,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import FallbackComponent from '../../components/FallbackComponent';
import { TaskGroup } from '../../graphql/types';
import { GetYoungPersonAdminTasksQueryResult } from './query.generated';

export type TaskGroupProgressViewProps = {
	data: GetYoungPersonAdminTasksQueryResult['data'];
	error?: GetYoungPersonAdminTasksQueryResult['error'];
	loading: GetYoungPersonAdminTasksQueryResult['loading'];
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		grid: {
			[theme.breakpoints.up('md')]: {
				marginBottom: theme.spacing(8),
			},
			[theme.breakpoints.up('lg')]: {
				'& > :nth-child(odd)': {
					paddingRight: theme.spacing(2),
				},
				'& > :nth-child(even)': {
					paddingLeft: theme.spacing(2),
				},
			},
		},
		cardWrapper: {
			display: 'flex',
			[theme.breakpoints.down('lg')]: {
				paddingBottom: theme.spacing(4),
			},
		},
		cardRoot: {
			flex: 1,
			borderRadius: theme.shape.borderRadius,
			border: 'none',
			boxShadow: theme.shadows[2],
			padding: theme.spacing(6),
		},
		rootCompleted: {
			backgroundColor: theme.palette.grey[100],
		},
	})
);

function CircularProgressWithLabel(
	props: CircularProgressProps & { value: number }
) {
	return (
		<Box position="relative" display="inline-flex" marginRight={'24px'}>
			<CircularProgress
				size={120}
				thickness={2}
				variant="determinate"
				color="primary"
				{...props}
			/>
			<Box
				top={0}
				left={0}
				bottom={0}
				right={0}
				position="absolute"
				display="flex"
				alignItems="center"
				justifyContent="center"
			>
				<Typography
					variant="h6"
					component="span"
					color="primary"
				>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	);
}

const TaskGroupProgressView: React.FC<TaskGroupProgressViewProps> = (props) => {
	const tasks = props.data?.listTasks ?? [];
	const classes = useStyles();
	const history = useHistory();
	const grouped = _.groupBy(tasks, 'group');
	const groups = Object.keys(grouped);

	const result = groups.map((group) => {
		return {
			group: group,
			totalNumberOfTasks: grouped?.[group].length,
			numberOfCompletedTasks: grouped?.[group].filter(
				(task) => !!task?.completedAt
			).length,
			numberOfUnCompleteTasks: grouped[group].filter(
				(task) => !task?.completedAt
			).length,
		};
	});

	const goToTasks = (group: string) => {
		history.push(`tasks/group/${group}`);
	};

	return (
		<FallbackComponent isLoading={props.loading}>
			<Grid container component="section" sm={12} className={classes.grid}>
				{result.map((group) => {
					const progress =
						(group.numberOfCompletedTasks / group.totalNumberOfTasks) * 100;

					let groupName = '';

					switch (group.group) {
						case TaskGroup.MoveIn:
							groupName = 'Move in process';
							break;
						case TaskGroup.PreMoveIn:
							groupName = 'Pre-Move in process';
							break;
						case TaskGroup.MoveOut:
							groupName = 'Move out process';
							break;
						default:
							groupName = 'Group';
							break;
					}
					const subtitle = `${group.numberOfCompletedTasks} / ${group.totalNumberOfTasks} tasks completed.`;

					// If all the tasks are completed then there is no need to show the card anymore
					if (group.numberOfCompletedTasks === group.totalNumberOfTasks)
						return null;

					return (
						<Grid
							item
							xs={12}
							md={12}
							lg={6}
							wrap="nowrap"
							className={classes.cardWrapper}
						>
							<Paper variant="outlined" className={classes.cardRoot}>
								<Box id="chart" display="flex" alignItems="center">
									<CircularProgressWithLabel value={progress} />
									<Box
										display="flex"
										flexDirection={'column'}
										gridRowGap={'0.25rem'}
									>
										<Typography component="h3" variant="h6">
											{groupName}
										</Typography>
										<Typography variant="caption" color="textSecondary">
											{subtitle}
										</Typography>
										<Box paddingTop="0.5rem">
											<IconButton
												onClick={() => goToTasks(group.group)}
												color="primary"
												aria-label="go to tasks"
												size="small"
											>
												<ChevronRight />
											</IconButton>
										</Box>
									</Box>
								</Box>
							</Paper>
						</Grid>
					);
				})}
			</Grid>
		</FallbackComponent>
	);
};

export default TaskGroupProgressView;
