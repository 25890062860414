import { ApolloError } from '@apollo/client';
import { useTheme } from '@material-ui/core';
import { useContext } from 'react';

// Components
import CardWithAdornmentComponent from '../../components/CardWithAdornmentComponent';
import ListResourceGridComponent from '../../components/ListResourceGridComponent';
import Box from '@material-ui/core/Box';
import PaginationCountFeedbackComponent from '../../components/PaginationCountFeedbackComponent/PaginationCountFeedbackComponent';
import PaginationComponent from '../../components/PaginationComponent';
import FallbackComponent from '../../components/FallbackComponent';
// Contexts
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { FormType } from '../../graphql/types';

// Layouts
import AdminLayout from '../../layouts/AdminLayout';
import UserModalView from '../../views/UserModalView';

import { ListUsersWithHouseQueryHookResult } from './query.generated';

export type AdminListUsersScreenProps = {
	data?: ListUsersWithHouseQueryHookResult['data'];
	loading?: boolean;
	error?: ApolloError;
	onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onPaginate: (page: number) => void;
	count: number;
	currentPage: number;
	pageCount: number;
};

const AdminListUsersScreen: React.FC<AdminListUsersScreenProps> = (props) => {
	const { open } = useContext(ModalContext);
	const users = props.data?.listUsers ?? [];
	const theme = useTheme();

	return (
		<AdminLayout
			emptyTitle="Users View"
			title="Staff"
			onSearch={props.onSearch}
			createForm={FormType.InviteUser}
		>
			<FallbackComponent
				isLoading={props.loading}
				loadingType="skeletonGridMd"
				isEmpty={!users.length}
				emptyTitle="No Staff"
				emptyCopy="Try adding a staff member and come back later"
				hasError={!!props.error}
				errorTitle="An Error Occurred"
				errorCopy="There was an error fetching the staff. If the problem persists, please contact your service administrator."
			>
				<ListResourceGridComponent>
					{users.map((user) => {
						return (
							<CardWithAdornmentComponent
								key={user?.id}
								label={user?.name ?? ''}
								avatarAlt={user?.name ?? 'A fabric user profile image'}
								adornmentColor={user?.house?.color ?? undefined}
								subLabel={user?.house?.title ?? ''}
								onClick={() =>
									open({
										type: ModalType.JSX,
										content: <UserModalView userId={user?.id ?? ''} />,
									})
								}
							/>
						);
					})}
				</ListResourceGridComponent>
				<Box
					display="flex"
					justifyContent="space-between"
					paddingTop={theme.spacing('md')}
				>
					<PaginationComponent
						count={props.pageCount}
						handleChange={(i: number) => props.onPaginate(i)}
					/>
					<PaginationCountFeedbackComponent
						current={props.data?.listUsers?.length ?? 0}
						listLength={props.count}
						suffix="Staff"
					/>
				</Box>
			</FallbackComponent>
		</AdminLayout>
	);
};

export default AdminListUsersScreen;
