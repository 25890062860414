import React from 'react';
import { Box, Divider } from '@material-ui/core';
import dayjs from 'dayjs';

// Components
import FormCardComponent from '../../components/FormCardComponent';
import DictionaryComponent from '../../components/DictionaryComponent';
import ModalContentComponent from '../../components/ModalContentComponent';
import { SecondaryItemType } from '../../components/ModalTitleComponent';

// Query
import { GetTaskWithUserQueryHookResult } from './query.generated';

// Styles
import theme from '../../styles/theme';

export type ReadTaskViewProps = {
	data: GetTaskWithUserQueryHookResult['data'];
	loading: GetTaskWithUserQueryHookResult['loading'];
	error: GetTaskWithUserQueryHookResult['error'];
	isOpen?: boolean;
	onComplete: () => void;
	onEditClick: () => void;
	onDeleteClick: () => void;
};

type Task = NonNullable<GetTaskWithUserQueryHookResult['data']>['getTask'];
type BasicUser = NonNullable<Task>['createdBy'];

const constructName = (user: BasicUser): string => {
	if (user?.name) {
		return user.name;
	}

	if (!user?.firstName && !user?.lastName) {
		return 'No User';
	}

	return `${user?.firstName} ${user?.lastName}`;
};

const constructDate = (date: string | null | undefined) => {
	if (!date) {
		return 'No date';
	}

	return dayjs(date).format('DD / MM / YYYY');
};

const ReadTaskView: React.FC<ReadTaskViewProps> = (props) => {
	const task = props?.data?.getTask;

	const label = task?.completedAt ? 'Mark as incomplete' : 'Mark as complete';

	const onPrimaryAction = task?.completedAt
		? props.onComplete
		: props.onComplete;
	return (
		<ModalContentComponent
			modalButtons={{
				primaryAction: {
					label: label,
					onClick: onPrimaryAction,
				},
			}}
			modalTitle={{
				title: task?.title ?? '',
				secondaryItem: {
					type: SecondaryItemType.ACTIONS,
					options: [
						{
							label: 'Edit',
							onClick: props.onEditClick,
						},
						{
							label: 'Delete',
							onClick: props.onDeleteClick,
						},
					],
				},
			}}
			isLoading={props.loading}
			isEmpty={!task}
		>
			<Box paddingBottom={theme.spacing('lg')}>
				<Divider />
			</Box>
			<FormCardComponent
				label={task?.title ?? ''}
				sublabel={constructName(task?.createdBy)}
				avatarSrc={task?.createdBy?.avatar ?? ''}
			/>
			<Box paddingBottom={theme.spacing('md')} paddingTop={theme.spacing('lg')}>
				<DictionaryComponent
					isFullWidth
					label="Description:"
					value={task?.description ?? ''}
				/>
			</Box>
			<DictionaryComponent
				label="Assigned to:"
				value={constructName(task?.assignedTo)}
			/>
			<DictionaryComponent
				label="Due date:"
				value={constructDate(task?.dueDate)}
			/>
			<DictionaryComponent
				label="Repeats:"
				value={task?.repeat ?? 'No repeat'}
			/>
			<DictionaryComponent
				label="Completed by:"
				value={constructName(task?.completedBy)}
			/>
			<DictionaryComponent
				label="Completed at:"
				value={constructDate(task?.completedAt)}
			/>
			<DictionaryComponent
				label="Date Created:"
				value={constructDate(task?.createdAt)}
			/>
			<DictionaryComponent
				label="Created by:"
				value={constructName(task?.createdBy)}
			/>
		</ModalContentComponent>
	);
};

export default ReadTaskView;
