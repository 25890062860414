import React, { useEffect, useState } from 'react';
import { RegisterOptions } from 'react-hook-form';
import {
	ToggleButton,
	ToggleButtonGroup,
	ToggleButtonProps,
	ToggleButtonGroupProps,
} from '@material-ui/lab';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	createStyles,
	Theme,
	makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			marginBottom: theme.spacing('sm'),
			textAlign: 'left',
			width: '100%',
		},
		errorMessage: {
			color: theme.palette.error.main,
			marginBottom: theme.spacing(2),
		},
		radioLabel: {
			position: 'relative',
			transform: 'scale(0.75) !important',
			textAlign: 'left',
			marginBottom: '8px',
		},
		hiddenInput: {
			display: 'none',
		},
	})
);

type Button = ToggleButtonProps & {
	label: string;
};

export type RadioButtonsComponentProps = {
	buttons: Button[];
	config: Omit<ToggleButtonGroupProps, 'value' | 'required'>;
	label: string;
	validation: RegisterOptions;
	error?: any;
	formField?: any;
	id: string;
};

const RadioButtonsComponent: React.FC<RadioButtonsComponentProps> = (props) => {
	const classes = useStyles();
	const [value, setValue] = useState(props.config.defaultValue);
	const label = `${props.label}${props.validation?.required ? '*' : ''}`;

	// Watch out for changes to the value and update the current value if it changes. Normally a change to the external value indicates a change of page
	useEffect(() => {
		// To avoid a loop, check if the value has actually changed
		if (props.formField?.value !== value) {
			setValue(props.formField?.value);
		}
	}, [props.formField]);

	const onChange = (
		event: React.MouseEvent<HTMLElement, MouseEvent>,
		value: any
	) => {
		setValue(value);

		// DO NOT TOUCH!!!!
		if (props.formField.onChange) {
			const customEvent = {
				...event,
				target: {
					value,
				},
			};
			props.formField.onChange(customEvent);
		}
	};

	return (
		<FormControl
			className={classes.wrapper}
			variant="standard"
			error={!!props.error ?? true}
		>
			<InputLabel className={classes.radioLabel}>{label}</InputLabel>
			<ToggleButtonGroup
				{...props.config}
				// color={props.error ? 'error' : 'primary'}
				exclusive
				size="small"
				value={value}
				onChange={onChange}
			>
				{props.buttons.map((button) => (
					<ToggleButton {...{ ...button, label: undefined }}>
						{button.label}
					</ToggleButton>
				))}
			</ToggleButtonGroup>

			{props.error ? (
				<FormHelperText>{props.error.message}</FormHelperText>
			) : null}
		</FormControl>
	);
};

export default RadioButtonsComponent;
