import { Detail } from '../../../components/DetailsCardComponent';
import { RiskAssessmentQuestions } from '../../../hooks/useForm/forms/ypRiskAssessment/config';
import { RiskAssessmentData } from '../../../types';
import { prettifyCaseEnum } from '../../../utils/text';

const RiskAssessmentCard = (args: RiskAssessmentData) => {
	// Loop through the questions and see if details have been added about them

	const riskAssessment: Detail[] = RiskAssessmentQuestions.map((question) => {
		// Check in the data that was past through if the risk has a value
		const data = args?.risks?.filter((risk) => risk.id === question.id);
		let copy = '-';

		if (data?.length === 1) {
			copy = data[0].value;
		}

		return {
			id: question.id as string,
			title: question.question,
			copy: prettifyCaseEnum(copy),
		};
	});

	return [
		{
			id: 'note',
			title: 'Note',
			copy: args?.note ?? '-',
		},
		...riskAssessment,
	];
};

export default RiskAssessmentCard;
