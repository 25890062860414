import React from 'react';
import { Drawer, createStyles, makeStyles, Theme } from '@material-ui/core';
import DialogCloseWarningComponent from '../DialogCloseWarningComponent';
import ModalContext from '../../contexts/ModalContext';
import useObserveKeyPress from '../../hooks/useObserveKeyPress';

export type ModalComponentProps = {
	isOpen: boolean;
	onClose: () => void;
	closeCopy?: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		drawer: {
			position: 'fixed',
			'@media print': {
				position: 'initial',
			},
			'& > [class*="MuiPaper-root"]': {
				width: '100%',
				maxWidth: '500px',
				'@media print': {
					maxWidth: 'initial !important',
					height: '100% !important',
					display: 'fixed !important',
					position: 'relative !important',
					boxShadow: 'initial',
					'& > div': {
						height: 'auto',
					},
					'& [data-component="content-wrapper"]': {
						height: '100%',
						paddingBottom: '48px',
					},
					'& [data-component="modal-buttons"]': {
						display: 'none',
					},
				},
			},
		},
	})
);

const ModalComponent: React.FC<ModalComponentProps> = (props) => {
	const classes = useStyles();
	const escapeIsPressed = useObserveKeyPress(['Escape']);
	const [dialogIsOpen, setDialogIsOpen] = React.useState(false);
	const { shouldWarnOnEscape } = React.useContext(ModalContext);

	const shouldShowDialog =
		(shouldWarnOnEscape && escapeIsPressed && props.isOpen) || dialogIsOpen;

	React.useEffect(() => {
		if (shouldShowDialog && !dialogIsOpen) {
			setDialogIsOpen(true);
		}
	}, [shouldShowDialog, dialogIsOpen]);

	const handleGoBack = () => {
		setDialogIsOpen(false);
	};

	const handleCloseDialog = () => {
		setDialogIsOpen(false);
		props.onClose();
	};

	return (
		<Drawer
			className={classes.drawer}
			anchor="right"
			open={props.isOpen}
			style={{ position: 'initial' }}
			onClose={props.onClose}
			disableEscapeKeyDown={shouldWarnOnEscape}
			hideBackdrop
		>
			{shouldShowDialog && (
				<DialogCloseWarningComponent
					isOpen={dialogIsOpen}
					onClose={handleCloseDialog}
					onGoBack={handleGoBack}
				/>
			)}
			{props.children}
		</Drawer>
	);
};

export default ModalComponent;
