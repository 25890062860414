import React from 'react';
import FallbackComponent from '../../components/FallbackComponent';
import { Line } from 'react-chartjs-2';
import dayjs, { Dayjs } from 'dayjs';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
} from 'chart.js';
import {
	Card,
	Box,
	Typography,
	TableHead,
	Table,
	TableRow,
	TableCell,
	TableBody,
	createStyles,
	makeStyles,
	Theme,
	TextField,
} from '@material-ui/core';

import { ChartData, TableData } from './types';

export type TrmChatViewProps = {
	chartData?: ChartData[];
	tableData?: TableData[];
	error: boolean;
	loading: boolean;
	startDate: string;
	endDate: string;
	setStartDate: (date: Dayjs) => void;
	setEndDate: (date: Dayjs) => void;
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		card: {
			padding: theme.spacing(2),
		},
		cardHeader: {
			marginBottom: theme.spacing(2),
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		filters: {
			display: 'flex',
			gap: theme.spacing(2),
		},
	})
);

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip
);

const TrmChatView: React.FC<TrmChatViewProps> = (props) => {
	const classes = useStyles();

	// Chart Options
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
			},
		},
	};

	// Chart Data
	const lineChartData = {
		labels: props.chartData?.map((data) => data.date),
		datasets: [
			{
				label: 'TRM Level',
				data: props.chartData?.map((data) => data.trmLevel ?? 0),
				borderColor: 'rgb(255, 99, 132)',
				backgroundColor: 'rgba(255, 99, 132, 0.5)',
			},
		],
	};

	return (
		<Card className={classes.card}>
			<Box className={classes.cardHeader}>
				<Typography variant="h2" component="h3">
					TRM Level
				</Typography>
				<Box className={classes.filters}>
					<TextField
						type="date"
						variant="standard"
						InputLabelProps={{ shrink: true }}
						label="Start Date"
						value={props.startDate}
						onChange={(event) => {
							props.setStartDate(dayjs(event.target.value));
						}}
					/>
					<TextField
						type="date"
						variant="standard"
						InputLabelProps={{ shrink: true }}
						label="End Date"
						value={props.endDate}
						onChange={(event) => {
							props.setEndDate(dayjs(event.target.value));
						}}
					/>
				</Box>
			</Box>
			<FallbackComponent
				isLoading={props.loading}
				hasError={props.error}
				isEmpty={props.chartData?.length === 0}
				loadingType="circularProgress"
				errorTitle={'Error getting TRM Levels'}
				errorCopy={
					'There seems to be an issue getting the TRM Levels, please try again later.'
				}
				emptyTitle={'No TRM Levels'}
				emptyCopy={'Try changing the start and end date.'}
			>
				<Line options={options} data={lineChartData} />
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Date</TableCell>
							<TableCell>TRM Level</TableCell>
							<TableCell>Reporter</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.tableData?.map((row) => (
							<TableRow>
								<TableCell>{row.date}</TableCell>
								<TableCell>{row.trmLevel}</TableCell>
								<TableCell>{row.user}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</FallbackComponent>
		</Card>
	);
};

export default TrmChatView;
