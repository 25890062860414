import React, { useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useListEmptyRoomsQuery } from '../../graphql/hooks';
import { useListHousesQuery } from '../../graphql/hooks';
import { useUpdateYoungPersonHouseMutation } from '../../graphql/hooks';
import { useUpdateYoungPersonRoomMutation } from '../../graphql/hooks';
import { useGetYoungPersonLocationQuery } from '../../graphql/hooks';
import FormFieldsComponent from '../../components/FormFieldsComponent';
import { FormFieldTypes } from '../../components/FormFieldsComponent/FormFieldsComponent';
import { useSnackbar } from 'notistack';

// Components
import ModalContentComponent, {
	ModalContentComponentProps,
} from '../../components/ModalContentComponent';

// Contexts
import ModalContext from '../../contexts/ModalContext';

// Types
import { ErrorType } from '../../components/FallbackComponent';
import { useLocation } from 'react-router';

const YoungPersonMoveRoomView: React.FC = (props) => {
	const location = useLocation();
	const [selectedHouseId, setSelectedHouseId] = useState<string>();
	const [updateRoom] = useUpdateYoungPersonRoomMutation();
	const [updateHouse] = useUpdateYoungPersonHouseMutation();
	const { enqueueSnackbar } = useSnackbar();

	const youngPersonId = location?.pathname.split('/')[2];

	const {
		data: youngPersonData,
		refetch: refetchYoungPersons,
		loading: youngPersonLoading,
	} = useGetYoungPersonLocationQuery({
		variables: { youngPersonId },
	});

	const {
		data: { listHouses } = { listHouses: [] },
		loading: housesLoading,
		error: housesError,
		refetch: refetchHouse,
	} = useListHousesQuery();

	const {
		data: { listRooms } = { listRooms: [] },
		loading: roomLoading,
		error: roomError,
		refetch: refetchRooms,
	} = useListEmptyRoomsQuery({
		variables: { houseId: selectedHouseId ?? '' },
	});

	React.useEffect(() => {
		if (youngPersonData?.getYoungPerson?.houseId) {
			setSelectedHouseId(youngPersonData?.getYoungPerson?.houseId);
		}
	}, [youngPersonData?.getYoungPerson?.houseId]);

	React.useEffect(() => {
		refetchRooms({ houseId: selectedHouseId });
	}, [selectedHouseId, refetchRooms]);

	const { handleSubmit, reset, control } = useForm({
		mode: 'onBlur',
		shouldFocusError: true,
	});

	const { close } = useContext(ModalContext);

	// Setup the modal header
	const modalHeader: ModalContentComponentProps['modalTitle'] = {
		title: 'Move Young Person',
	};

	const modalButtons: ModalContentComponentProps['modalButtons'] = {
		primaryAction: {
			label: 'Submit',
			disabled: roomLoading || housesLoading,
			loading: roomLoading || housesLoading,
			onClick: handleSubmit(async (data) => {
				const oldHouseId = youngPersonData?.getYoungPerson?.houseId;
				const oldRoomId = youngPersonData?.getYoungPerson?.room?.id;

				const updatedRoomId = data?.roomId;
				const updatedHouseId = data?.houseId;

				try {
					// TODO: Move out of room before house query
					if (updatedHouseId && updatedHouseId !== oldHouseId) {
						if (oldRoomId) {
							const room = await updateRoom({
								variables: {
									roomId: oldRoomId,
									data: {
										youngPersonId: null,
									},
								},
							});
						}
						const house = await updateHouse({
							variables: {
								youngPersonId,
								data: {
									houseId: data?.houseId,
								},
							},
						});
					}
					if (updatedRoomId && updatedRoomId !== oldRoomId) {
						// TODO: Move out of room before add new
						if (oldRoomId) {
							await updateRoom({
								variables: {
									roomId: oldRoomId,
									data: {
										youngPersonId: null,
									},
								},
							});
						}
						const room = await updateRoom({
							variables: {
								roomId: updatedRoomId,
								data: {
									youngPersonId,
								},
							},
						});
					}
					enqueueSnackbar('Successfully moved young person', {
						variant: 'success',
					});
					reset();
					close();
				} catch (e) {
					enqueueSnackbar('An error occurred', {
						variant: 'error',
					});
					console.error(e);
				} finally {
					refetchYoungPersons();
					refetchHouse();
					refetchRooms();
				}
			}),
		},
	};

	return (
		<ModalContentComponent
			modalTitle={modalHeader}
			modalButtons={modalButtons}
			isLoading={roomLoading || housesLoading || youngPersonLoading}
			error={
				roomError || housesError
					? {
							title: 'An Error Occurred',
							copy: 'There was an issue loading this form, please try again later.',
							type: ErrorType.WARNING,
					  }
					: undefined
			}
		>
			{youngPersonData && (
				<FormFieldsComponent
					control={control}
					fields={[
						{
							id: 'houseId',
							type: FormFieldTypes.SELECT,
							label: 'House:',
							options:
								listHouses?.map((house) => {
									return {
										copy: house?.title ?? '',
										value: house?.id ?? '',
									};
								}) ?? [],
							config: {
								defaultValue: youngPersonData?.getYoungPerson?.houseId,
								onClick: (e) => {
									// @ts-ignore
									const val = e?.target?.value as string | undefined;
									console.log(val);
									if (val) {
										setSelectedHouseId(val);
									}
								},
							},
						},
					]}
				/>
			)}
			{youngPersonData && !roomLoading && (
				<FormFieldsComponent
					control={control}
					fields={[
						{
							id: 'roomId',
							type: FormFieldTypes.SELECT,
							label: 'Room:',
							options:
								listRooms?.map((room) => {
									return {
										copy: room?.name ?? '',
										value: room?.id ?? '',
									};
								}) ?? [],
							config: {
								defaultValue: youngPersonData?.getYoungPerson?.room?.id,
							},
						},
					]}
				/>
			)}
		</ModalContentComponent>
	);
};

export default YoungPersonMoveRoomView;
