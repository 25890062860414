import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	createStyles,
	makeStyles,
	Theme,
	Box,
	Paper,
	Typography,
	Button,
	Avatar,
} from '@material-ui/core';
import { KeyboardArrowDown, ExitToApp } from '@material-ui/icons';

// Contexts
import AuthContext from '../../contexts/authContext';
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { FormType } from '../../graphql/types';

import { prettifyCaseEnum } from '../../utils/text';

export type AccountPopupViewProps = {};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			position: 'relative',
		},
		paper: {
			zIndex: 100,
			position: 'absolute',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			width: '270px',
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5),
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			right: '0px',
		},
		avatar: {
			height: '80px',
			width: '80px',
			marginBottom: theme.spacing(2),
		},
		bottomText: {
			marginBottom: theme.spacing(2),
		},
		manageAccountButton: {
			marginBottom: theme.spacing(2),
		},
	})
);

const AccountPopupView: React.FC<AccountPopupViewProps> = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const [open, setOpen] = useState<Boolean>(false);

	const { logout, user, changePassword } = useContext(AuthContext);
	const { open: openModal } = useContext(ModalContext);

	const onManageAccountClick = () => {
		setOpen(false);
		openModal({
			type: ModalType.FORM,
			args: {
				type: FormType.UserUpdate,
				userId: user?.id,
				changePassword,
			},
		});
	};

	const onLogoutClick = async () => {
		await logout();
		history.push('/');
	};

	return (
		<Box className={classes.container}>
			<Button endIcon={<KeyboardArrowDown />} onClick={() => setOpen(!open)}>
				{user?.name}
			</Button>
			{open ? (
				<Paper className={classes.paper} elevation={4}>
					<Avatar className={classes.avatar} />
					<Typography component="h6" variant="h6">
						{user?.name}
					</Typography>
					<Typography className={classes.bottomText}>
						{prettifyCaseEnum(user?.role ?? '')}
					</Typography>
					<Button
						className={classes.manageAccountButton}
						variant="contained"
						color="primary"
						onClick={onManageAccountClick}
					>
						Manage Account
					</Button>
					<Button onClick={onLogoutClick} startIcon={<ExitToApp />}>
						Sign Out
					</Button>
				</Paper>
			) : null}
		</Box>
	);
};

export default AccountPopupView;
