import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { YoungPersonProfileFragmentDoc } from '../../../../views/YoungPersonProfileView/query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type HouseIdDetailsFragment = {
	__typename?: 'House';
	id: string;
	title: string;
};

export type ListHouseIdsQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ListHouseIdsQuery = {
	__typename?: 'Query';
	listHouses?:
		| Array<
				{ __typename?: 'House'; id: string; title: string } | null | undefined
		  >
		| null
		| undefined;
};

export type CreateYoungPersonProfileMutationVariables = Types.Exact<{
	data: Types.YoungPersonCreateInput;
}>;

export type CreateYoungPersonProfileMutation = {
	__typename?: 'Mutation';
	createYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				ethnicity?: string | null | undefined;
				spokenLanguage?: string | null | undefined;
				safePlace?: string | null | undefined;
				religion?: string | null | undefined;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				registeredOptician?: string | null | undefined;
				registeredDentist?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				photoConcept?: boolean | null | undefined;
				riskAssessment?: any | null | undefined;
				legalStatus?: string | null | undefined;
				ypAdvisor?: string | null | undefined;
				localAuthority?: string | null | undefined;
				physicalDescription?: string | null | undefined;
				aboutMe?: any | null | undefined;
				status?: Types.YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				nextOfKin?:
					| Array<{
							__typename?: 'NextOfKin';
							id: string;
							name: string;
							relation: string;
							phone: string;
							youngPersonId: string;
							contactable?: boolean | null | undefined;
							visitable?: boolean | null | undefined;
							note?: string | null | undefined;
					  }>
					| null
					| undefined;
				medicalConditions?:
					| Array<{
							__typename?: 'MedicalCondition';
							id: string;
							title: string;
							medication?: string | null | undefined;
							administrationDetails?: string | null | undefined;
							symptoms?: string | null | undefined;
							triggers?: string | null | undefined;
							inEmergency?: string | null | undefined;
							youngPersonId: string;
					  }>
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const HouseIdDetailsFragmentDoc = gql`
	fragment HouseIdDetails on House {
		id
		title
	}
`;
export const ListHouseIdsDocument = gql`
	query ListHouseIds {
		listHouses(
			where: {
				preMoveInHouse: { equals: false }
				isMoveOutHouse: { not: { equals: true } }
			}
		) {
			...HouseIdDetails
		}
	}
	${HouseIdDetailsFragmentDoc}
`;

/**
 * __useListHouseIdsQuery__
 *
 * To run a query within a React component, call `useListHouseIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHouseIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHouseIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListHouseIdsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListHouseIdsQuery,
		ListHouseIdsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ListHouseIdsQuery, ListHouseIdsQueryVariables>(
		ListHouseIdsDocument,
		options
	);
}
export function useListHouseIdsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListHouseIdsQuery,
		ListHouseIdsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ListHouseIdsQuery, ListHouseIdsQueryVariables>(
		ListHouseIdsDocument,
		options
	);
}
export type ListHouseIdsQueryHookResult = ReturnType<
	typeof useListHouseIdsQuery
>;
export type ListHouseIdsLazyQueryHookResult = ReturnType<
	typeof useListHouseIdsLazyQuery
>;
export type ListHouseIdsQueryResult = Apollo.QueryResult<
	ListHouseIdsQuery,
	ListHouseIdsQueryVariables
>;
export const CreateYoungPersonProfileDocument = gql`
	mutation CreateYoungPersonProfile($data: YoungPersonCreateInput!) {
		createYoungPerson(data: $data) {
			...YoungPersonProfile
		}
	}
	${YoungPersonProfileFragmentDoc}
`;
export type CreateYoungPersonProfileMutationFn = Apollo.MutationFunction<
	CreateYoungPersonProfileMutation,
	CreateYoungPersonProfileMutationVariables
>;

/**
 * __useCreateYoungPersonProfileMutation__
 *
 * To run a mutation, you first call `useCreateYoungPersonProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateYoungPersonProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createYoungPersonProfileMutation, { data, loading, error }] = useCreateYoungPersonProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateYoungPersonProfileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateYoungPersonProfileMutation,
		CreateYoungPersonProfileMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CreateYoungPersonProfileMutation,
		CreateYoungPersonProfileMutationVariables
	>(CreateYoungPersonProfileDocument, options);
}
export type CreateYoungPersonProfileMutationHookResult = ReturnType<
	typeof useCreateYoungPersonProfileMutation
>;
export type CreateYoungPersonProfileMutationResult =
	Apollo.MutationResult<CreateYoungPersonProfileMutation>;
export type CreateYoungPersonProfileMutationOptions =
	Apollo.BaseMutationOptions<
		CreateYoungPersonProfileMutation,
		CreateYoungPersonProfileMutationVariables
	>;
