import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type CreateDownloadLinkMutationVariables = Types.Exact<{
	fileKey: Types.Scalars['String'];
}>;

export type CreateDownloadLinkMutation = {
	__typename?: 'Mutation';
	createDownloadUrl?:
		| {
				__typename?: 'UrlResponse';
				url: string;
				statusCode?: number | null | undefined;
		  }
		| null
		| undefined;
};

export const CreateDownloadLinkDocument = gql`
	mutation CreateDownloadLink($fileKey: String!) {
		createDownloadUrl(fileKey: $fileKey) {
			url
			statusCode
		}
	}
`;
export type CreateDownloadLinkMutationFn = Apollo.MutationFunction<
	CreateDownloadLinkMutation,
	CreateDownloadLinkMutationVariables
>;

/**
 * __useCreateDownloadLinkMutation__
 *
 * To run a mutation, you first call `useCreateDownloadLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDownloadLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDownloadLinkMutation, { data, loading, error }] = useCreateDownloadLinkMutation({
 *   variables: {
 *      fileKey: // value for 'fileKey'
 *   },
 * });
 */
export function useCreateDownloadLinkMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateDownloadLinkMutation,
		CreateDownloadLinkMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CreateDownloadLinkMutation,
		CreateDownloadLinkMutationVariables
	>(CreateDownloadLinkDocument, options);
}
export type CreateDownloadLinkMutationHookResult = ReturnType<
	typeof useCreateDownloadLinkMutation
>;
export type CreateDownloadLinkMutationResult =
	Apollo.MutationResult<CreateDownloadLinkMutation>;
export type CreateDownloadLinkMutationOptions = Apollo.BaseMutationOptions<
	CreateDownloadLinkMutation,
	CreateDownloadLinkMutationVariables
>;
