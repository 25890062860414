import { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { useSnackbar } from 'notistack';

// View
import ActivityLogModalView from './LogModalView';

// Context
import ModalContext, { ModalType } from '../../contexts/ModalContext';

// Query
import { useGetLogQuery } from './query.generated';

// Types
import { Log } from './types';
import { FormType, LogType } from '../../graphql/types';
import { DeleteLogDocument } from '../../graphql/hooks';

type ActivityLogModalViewContainerProps = {
	logId: string;
	houseId?: string;
};

const ActivityLogModalViewContainer: React.FC<ActivityLogModalViewContainerProps> =
	(props) => {
		const { open, close } = useContext(ModalContext);
		const { enqueueSnackbar } = useSnackbar();
		const client = useApolloClient();
		const { data, error, loading } = useGetLogQuery({
			variables: {
				id: props.logId,
			},
		});

		const type: LogType | undefined = data?.getLog?.type;
		let title: string = '';
		let formType: FormType | undefined = undefined;

		switch (type) {
			case LogType.Activity:
				title = 'Activity Log';
				formType = FormType.LogActivity;
				break;
			case LogType.Incident:
				title = 'Incident Log';
				formType = FormType.LogIncident;
				break;
			case LogType.SpinSession:
				title = 'Spin Session';
				formType = FormType.LogSpin;
				break;
			case LogType.CoachingSession:
				title = 'Coaching Session';
				formType = FormType.LogCoaching;
				break;
			case LogType.MangerOnDuty:
				title = 'Person on Call Log';
				formType = FormType.LogManagerOnDuty;
				break;
			case LogType.Location:
				title = 'Location Log';
				formType = FormType.LogLocation;
				break;
			case LogType.Note:
				title = 'General Note';
				formType = FormType.LogNote;
				break;
			case LogType.KidPayment:
				title = 'Kid Payment';
				formType = FormType.LogKidPayment;
				break;
		}

		const onEditClick = () => {
			close();
			if (formType)
				open({
					type: ModalType.FORM,
					args: {
						// @ts-expect-error
						type: formType as FormType,
						id: props.logId,
						houseId: props.houseId,
					},
				});
		};
		const onDeleteClick = async () => {
			try {
				await client.mutate({
					mutation: DeleteLogDocument,
					variables: {
						id: props.logId,
					},
				});

				enqueueSnackbar('Log deleted successfully', {
					variant: 'success',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			} catch (error) {
				enqueueSnackbar(
					'Sorry something went wrong deleting this log, please try again later.',
					{
						variant: 'success',
						anchorOrigin: { vertical: 'top', horizontal: 'right' },
					}
				);
			}
		};

		// @ts-expect-error
		const formData: Log = data?.getLog?.data
			? {
					...data?.getLog,
					type,
					data: JSON.parse(data?.getLog?.data),
			  }
			: undefined;

		return (
			<ActivityLogModalView
				data={formData}
				onEditClick={onEditClick}
				onDeleteClick={onDeleteClick}
				error={error}
				loading={loading}
				title={title}
			/>
		);
	};

export default ActivityLogModalViewContainer;
