import { useParams } from 'react-router-dom';
import HouseDashboardView from './HouseDashboardView';

type HouseProfileViewContainerProps = {};

type Params = {
	youngPersonId?: string;
	houseId: string;
};

const HouseDashboardViewContainer: React.FC<HouseProfileViewContainerProps> = (
	props
) => {
	const params = useParams<Params>();

	return <HouseDashboardView houseId={params.houseId} />;
};

export default HouseDashboardViewContainer;
