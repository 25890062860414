import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

import { useCreateFileUrlMutation } from '../../graphql/hooks';
import { FileType } from '../../graphql/types';

type UploadParams = {
	fileType: FileType;
	fileName?: string;
	houseId: string;
	file: File;
	youngPersonId?: string;
	folderName?: string;
};

export enum UploadState {
	IDLE,
	LOADING,
	ERROR,
	SUCCESS,
}

const useUploadFile = () => {
	const [uploadProgress, setUploadProgress] = useState(0);
	const [fetchUrl] = useCreateFileUrlMutation();
	const { enqueueSnackbar } = useSnackbar();
	const [uploadState, setUploadState] = useState(UploadState.IDLE);

	const upload = async (params: UploadParams) => {
		setUploadState(UploadState.LOADING);
		try {
			const { data } = await fetchUrl({
				variables: {
					fileType: params.fileType,
					houseId: params.houseId,
					youngPersonId: params.youngPersonId,
					fileName: params.fileName,
					folderName: params.folderName,
				},
			});

			const url = data?.createFileUploadUrl?.url;

			if (!url) {
				throw new Error('No URL found');
			}

			const res = await axios.put(url, params.file, {
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);

					setUploadProgress(percentCompleted);
				},
				headers: {
					'Content-Type': params.file.type,
				},
			});
			if (res.status === 200) {
				setUploadState(UploadState.SUCCESS);
				enqueueSnackbar('File uploaded successfully.', {
					variant: 'success',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			}
			console.log('status', res.status);
		} catch (error) {
			console.log('error', error);
			setUploadState(UploadState.ERROR);
			enqueueSnackbar('Sorry something went wrong.', {
				variant: 'error',
				anchorOrigin: { vertical: 'top', horizontal: 'right' },
			});
		} finally {
			setUploadState(UploadState.IDLE);
		}
	};

	return {
		uploadProgress,
		upload,
		uploadState,
	};
};

export default useUploadFile;
