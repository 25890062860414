import { Card, Grid, Typography } from '@material-ui/core';
import React from 'react';

import YoungPersonLocationStatusTable from './YoungPersonLocationStatusTable';

export type AdminOrgSettingsScreenProps = {};

const AdminOrgSettingsScreen: React.FC<AdminOrgSettingsScreenProps> = (
	props
) => {
	return (
		<Grid md container>
			<Grid item md={4}>
				<Grid
					direction="column"
					justifyContent="space-between"
					style={{ padding: 8 }}
				>
					<Typography
						variant="h2"
						color="textPrimary"
						style={{ marginBottom: 20, marginTop: 20 }}
					>
						Whereabout Statuses
					</Typography>
				</Grid>
				<Card title="Whereabout status">
					<YoungPersonLocationStatusTable />
				</Card>
			</Grid>
		</Grid>
	);
};

export default AdminOrgSettingsScreen;
