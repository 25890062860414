import { Container, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { CSS_VARS } from '../../styles/globals';
import NavigationView from '../../views/NavigationView';
import ModalContext from '../../contexts/ModalContext';

const HEADER_HEIGHT = '6.125rem';

const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.grey[500],
		justifyContent: 'center',
		overflowY: 'hidden',
		height: '100%',
	},
	rootModalOpen: {
		color: theme.palette.grey[500],
		justifyContent: 'center',
		overflowY: 'hidden',
		height: '100%',
		'@media print': {
			display: 'none !important',
		},
	},
	container: {
		paddingBottom: theme.spacing('xl'),
		paddingTop: theme.spacing('xl'),
		paddingLeft: theme.spacing('md'),
		paddingRight: theme.spacing('md'),
		maxWidth: '1500px',
		minHeight: `calc(100vh - ${HEADER_HEIGHT})`,
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: theme.palette.background.default,
	},
}));

const MainLayoutComponent: React.FC = (props) => {
	const classes = useStyles();
	const { isOpen } = useContext(ModalContext);

	return (
		<div
			role="presentation"
			className={isOpen ? classes.rootModalOpen : classes.root}
			style={{ ...CSS_VARS }}
		>
			<NavigationView />
			<Container component="main" className={classes.container}>
				{props?.children ? props.children : <div />}
			</Container>
		</div>
	);
};

export default MainLayoutComponent;
