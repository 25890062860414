import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ListLocationStatusesQueryVariables = Types.Exact<{
	[key: string]: never;
}>;

export type ListLocationStatusesQuery = {
	__typename?: 'Query';
	listYoungPersonLocationStatuses?:
		| Array<
				| {
						__typename?: 'YoungPersonLocationStatus';
						id: string;
						title: string;
						type: Types.YoungPersonLocationStatusType;
						priority?: number | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const ListLocationStatusesDocument = gql`
	query ListLocationStatuses {
		listYoungPersonLocationStatuses {
			id
			title
			type
			priority
		}
	}
`;

/**
 * __useListLocationStatusesQuery__
 *
 * To run a query within a React component, call `useListLocationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLocationStatusesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListLocationStatusesQuery,
		ListLocationStatusesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		ListLocationStatusesQuery,
		ListLocationStatusesQueryVariables
	>(ListLocationStatusesDocument, options);
}
export function useListLocationStatusesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListLocationStatusesQuery,
		ListLocationStatusesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ListLocationStatusesQuery,
		ListLocationStatusesQueryVariables
	>(ListLocationStatusesDocument, options);
}
export type ListLocationStatusesQueryHookResult = ReturnType<
	typeof useListLocationStatusesQuery
>;
export type ListLocationStatusesLazyQueryHookResult = ReturnType<
	typeof useListLocationStatusesLazyQuery
>;
export type ListLocationStatusesQueryResult = Apollo.QueryResult<
	ListLocationStatusesQuery,
	ListLocationStatusesQueryVariables
>;
