import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import { UserDetailsFragmentDoc } from '../../hooks/useForm/forms/userUpdate/query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetStaffMemberQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
}>;

export type GetStaffMemberQuery = {
	__typename?: 'Query';
	getUser?:
		| {
				__typename?: 'User';
				name?: string | null | undefined;
				avatar?: string | null | undefined;
				role: Types.Role;
				createdAt?: any | null | undefined;
				id: string;
				firstName: string;
				lastName: string;
				phone?: string | null | undefined;
				address?: string | null | undefined;
				email: any;
				houseId?: string | null | undefined;
				disabled?: boolean | null | undefined;
				house?:
					| {
							__typename?: 'House';
							title: string;
							color?: string | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const GetStaffMemberDocument = gql`
	query GetStaffMember($id: String) {
		getUser(where: { id: $id }) {
			...UserDetails
			name
			avatar
			role
			createdAt
			house {
				title
				color
			}
		}
	}
	${UserDetailsFragmentDoc}
`;

/**
 * __useGetStaffMemberQuery__
 *
 * To run a query within a React component, call `useGetStaffMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStaffMemberQuery(
	baseOptions?: Apollo.QueryHookOptions<
		GetStaffMemberQuery,
		GetStaffMemberQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetStaffMemberQuery, GetStaffMemberQueryVariables>(
		GetStaffMemberDocument,
		options
	);
}
export function useGetStaffMemberLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetStaffMemberQuery,
		GetStaffMemberQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetStaffMemberQuery, GetStaffMemberQueryVariables>(
		GetStaffMemberDocument,
		options
	);
}
export type GetStaffMemberQueryHookResult = ReturnType<
	typeof useGetStaffMemberQuery
>;
export type GetStaffMemberLazyQueryHookResult = ReturnType<
	typeof useGetStaffMemberLazyQuery
>;
export type GetStaffMemberQueryResult = Apollo.QueryResult<
	GetStaffMemberQuery,
	GetStaffMemberQueryVariables
>;
