import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ListHousesQueryVariables = Types.Exact<{
	where?: Types.Maybe<Types.HouseWhereFilterInput>;
}>;

export type ListHousesQuery = {
	__typename?: 'Query';
	listHouses?:
		| Array<
				{ __typename?: 'House'; id: string; title: string } | null | undefined
		  >
		| null
		| undefined;
};

export const ListHousesDocument = gql`
	query ListHouses($where: HouseWhereFilterInput) {
		listHouses(orderBy: { title: ASC }, where: $where) {
			id
			title
		}
	}
`;

/**
 * __useListHousesQuery__
 *
 * To run a query within a React component, call `useListHousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHousesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListHousesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListHousesQuery,
		ListHousesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ListHousesQuery, ListHousesQueryVariables>(
		ListHousesDocument,
		options
	);
}
export function useListHousesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListHousesQuery,
		ListHousesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ListHousesQuery, ListHousesQueryVariables>(
		ListHousesDocument,
		options
	);
}
export type ListHousesQueryHookResult = ReturnType<typeof useListHousesQuery>;
export type ListHousesLazyQueryHookResult = ReturnType<
	typeof useListHousesLazyQuery
>;
export type ListHousesQueryResult = Apollo.QueryResult<
	ListHousesQuery,
	ListHousesQueryVariables
>;
