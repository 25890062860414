import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core';

export type DialogDeleteWarningProps = {
	isOpen: boolean;
	onGoBack?: () => void;
	onDelete?: () => void;
};

const DialogDeleteWarning: React.FC<DialogDeleteWarningProps> = ({
	isOpen,
	onGoBack,
	onDelete,
}) => (
	<Dialog open={isOpen}>
		<DialogTitle>Are you sure you want to delete this item?</DialogTitle>
		<DialogContent>
			<DialogContentText>This action cannot be undone.</DialogContentText>
			<DialogActions>
				<Button variant="text" onClick={onGoBack}>
					Go Back
				</Button>
				<Button variant="outlined" color="primary" onClick={onDelete}>
					Delete
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
);

export default DialogDeleteWarning;
