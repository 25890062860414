import { FormComponentProps } from '../../components/FormComponent';
import { FormFieldTypes } from '../../components/FormFieldsComponent';

const FormBase = (
	onSubmit: FormComponentProps['onSubmit']
): FormComponentProps => {
	return {
		title: 'Forgot Password',
		fields: [
			{
				id: 'email',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Email:',
					type: 'email',
					autoComplete: 'email',
				},
				validation: {
					required: true,
				},
			},
		],
		successCopy: 'An Email has been sent to reset your password.',
		submitCopy: 'Send Email',
		onSubmit,
	};
};

export default FormBase;
