import React from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

// Components
import PaginationComponent, {
	PaginationComponentProps,
} from '../PaginationComponent';

const useStyles = makeStyles((theme: Theme) => ({
	wrapper: {
		paddingTop: theme.spacing('lg'),
		paddingBottom: theme.spacing('lg'),
		display: 'flex',
		alignContent: 'center',
		justifyContent: 'space-between',
	},
}));

export type ViewPaginationComponentProps = PaginationComponentProps & {
	copy: string;
};

const ViewPaginationComponent: React.FC<ViewPaginationComponentProps> = (
	props
) => {
	const classes = useStyles();

	return (
		<Box className={classes.wrapper}>
			<PaginationComponent {...{ ...props, copy: undefined }} />
			<Typography variant="body2">{props.copy}</Typography>
		</Box>
	);
};

export default ViewPaginationComponent;
