import React from 'react';
import {
	createStyles,
	makeStyles,
	Theme,
	ListItemAvatar,
	Avatar,
} from '@material-ui/core';

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		avatar: {
			marginRight: theme.spacing('sm'),
		},
		avatarContainer: {
			display: 'grid',
			placeItems: 'center',
		},
	})
);

type IconCardAvatarProps = {
	avatarUrl?: string;
	avatarAlt?: string;
};

/** If you are passing an Icon, pass through children, otherwise pass an avatarUrl and avatarSrc */

export const InfoCardAvatarComponent: React.FC<IconCardAvatarProps> = ({
	avatarAlt,
	avatarUrl,
	...props
}) => {
	const styles = useStyles();

	return (
		<ListItemAvatar className={styles.avatarContainer}>
			<Avatar alt={avatarAlt} src={avatarUrl} className={styles.avatar}>
				{props.children}
			</Avatar>
		</ListItemAvatar>
	);
};

export default InfoCardAvatarComponent;
