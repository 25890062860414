import { useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/client';

// Forms
import LogActivityForm from './forms/logActivity';
import LogIncidentForm from './forms/logIncident';
import YpCreateForm from './forms/ypCreate';
import YpBasicDetails from './forms/ypBasicDetails';
import YpLegalDetails from './forms/ypLegalDetails';
import YpMoveRooms from './forms/ypMoveRoom';
import YoungPersonAboutMeForm from './forms/ypAboutMe';
import NextOfKinForm from './forms/nextOfKin';
import MedicalConditionForm from './forms/medicalCondition';
import YpRiskAssessmentForm from './forms/ypRiskAssessment';
import HouseCreateForm from './forms/houseCreate';
import UserUpdateForm from './forms/userUpdate';
import RoomForm from './forms/room';
import InviteUserForm from './forms/inviteUser';
import LogNoteForm from './forms/logNote';
import LogLocationForm from './forms/logLocation';
import LogKidPaymentForm from './forms/logKidPayment';
import CreateAlertForm from './forms/createAlert';
import ManagerOnDutyForm from './forms/managerOnDuty';
import YpMoveOut from './forms/ypMoveOut';
import LogContact from './forms/logContact';

// Context
import AuthContext from '../../contexts/authContext';

// Types
import { FormType } from '../../graphql/types';
import { FormArgs } from './types';
import { FormFieldsComponentProps } from '../../components/FormFieldsComponent/FormFieldsComponent';
import TaskCreateForm from './forms/taskCreate/taskCreate';

const forms = [
	{
		type: FormType.YpCreate,
		form: YpCreateForm,
		title: 'Create Kid',
	},
	{
		type: FormType.LogActivity,
		form: LogActivityForm,
		title: 'Log Kid Recording',
	},
	{
		type: FormType.LogNote,
		form: LogNoteForm,
		title: 'General Note',
	},
	{
		type: FormType.YpMoveOut,
		form: YpMoveOut,
		title: 'Move Out',
	},
	{
		type: FormType.LogLocation,
		form: LogLocationForm,
		title: 'Kid Location',
	},
	{
		type: FormType.LogIncident,
		form: LogIncidentForm,
		title: 'Log Incident',
	},
	{
		type: FormType.YpBasicDetails,
		form: YpBasicDetails,
		title: 'Update Basic Details',
	},
	{
		type: FormType.YpLegalDetails,
		form: YpLegalDetails,
		title: 'Update Legal Details',
	},
	{
		type: FormType.YpNextOfKin,
		form: NextOfKinForm,
		title: 'Contacts',
	},
	{
		type: FormType.YpAboutMe,
		form: YoungPersonAboutMeForm,
		title: 'About Me',
	},
	{
		type: FormType.YpMedicalDetails,
		form: MedicalConditionForm,
		title: 'Medical Condition',
	},
	{
		type: FormType.TaskCreate,
		form: TaskCreateForm,
		title: 'Create Task',
	},
	{
		type: FormType.YpRiskAssessment,
		form: YpRiskAssessmentForm,
		title: 'Risk Assessment',
	},
	{
		type: FormType.HouseCreate,
		form: HouseCreateForm,
		title: 'Create House',
	},
	{
		type: FormType.UserUpdate,
		form: UserUpdateForm,
		title: 'Update User',
	},
	{
		type: FormType.LogManagerOnDuty,
		form: ManagerOnDutyForm,
		title: 'Person on Call Log',
	},
	{
		type: FormType.LogContact,
		form: LogContact,
		title: 'Professional Contact',
	},
	{
		type: FormType.Room,
		form: RoomForm,
		title: 'Manage Room',
	},
	{
		type: FormType.InviteUser,
		form: InviteUserForm,
		title: 'Invite User',
	},
	{
		type: FormType.LogKidPayment,
		form: LogKidPaymentForm,
		title: 'Kid Payment',
	},
	{
		type: FormType.CreateAlert,
		form: CreateAlertForm,
		title: 'Create Alert',
	},
];

export type FormFields = FormFieldsComponentProps['fields'][];

type DataOutput = {
	onSubmit: (formValue: object) => Promise<any>;
	fields: FormFields;
	defaultValues: object;
	title: string;
};

const getFormConfig = (type: FormType) => {
	const configs = forms.filter((form) => form.type === type);

	if (configs.length !== 1) throw new Error('Form Config could not be found!');

	return configs[0];
};

const useForm = (formArgs: FormArgs) => {
	const apolloClient = useApolloClient();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | undefined>(undefined);
	const [title, setTitle] = useState<string>('');
	const [form, setForm] = useState<DataOutput | undefined>(undefined);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		// Get the form config
		getForm(formArgs);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getForm(form: FormArgs) {
		try {
			// Get Config Object for each form
			const config = getFormConfig(form.type);
			setTitle(config.title);
			// @ts-expect-error
			const preFetch = await config.form.preFetch(form, apolloClient);
			// @ts-expect-error
			const fields = config.form.formFields(form, preFetch);

			const onSubmit = await config.form.generateOnSubmit(
				// @ts-expect-error
				form,
				apolloClient,
				user?.id
			);

			setForm({
				fields,
				// @ts-expect-error
				onSubmit,
				title: config.title,
			});
			setLoading(false);
		} catch (error) {
			if (error instanceof Error) {
				setError(error);
				setLoading(false);
			}
		}
	}

	return { title, form, loading, error };
};

export default useForm;
