import React from 'react';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import LocationInputComponent from '../LocationInputComponent';
import CustomSlider from '../SliderComponent/SliderComponent';
import {
	Box,
	TextField,
	TextFieldProps,
	Select,
	SelectProps,
	FormControl,
	FormHelperText,
	MenuItem,
	InputLabel,
	Divider,
	createStyles,
	Theme,
	makeStyles,
	Typography,
	SliderProps,
} from '@material-ui/core';

import RadioButtonsComponent, {
	RadioButtonsComponentProps,
} from '../RadioButtonsComponent';
import CheckboxComponent, {
	CheckboxComponentProps,
} from '../CheckboxComponent';

export type FormFieldsComponentProps = {
	fields: FormField[];
	control: Control;
};

export type FormField =
	| InputField
	| DatePickerField
	| SelectField
	| RadioField
	| DividerField
	| SliderField
	| CustomComponent
	| CheckboxField
	| LocationField;

export enum FormFieldTypes {
	'INPUT' = 'input',
	'DATE_PICKER' = 'datePicker',
	'SELECT' = 'select',
	'RADIO' = 'radio',
	'DIVIDER' = 'divider',
	'SLIDER' = 'slider',
	'LOCATION' = 'location',
	'CHECKBOX' = 'checkbox',
	'CUSTOM' = 'custom',
}

type InputField = FormFieldBase & {
	type: FormFieldTypes.INPUT;
	config?: Omit<TextFieldProps, 'error' | 'helperText' | 'value' | 'required'>;
};

type DatePickerField = FormFieldBase & {
	type: FormFieldTypes.DATE_PICKER;
	config?: Omit<TextFieldProps, 'value' | 'required'>;
};

type SelectField = FormFieldBase & {
	type: FormFieldTypes.SELECT;
	options: Option[];
	config: Omit<SelectProps, 'value' | 'required'>;
	label: string;
};

type SliderField = FormFieldBase & {
	type: FormFieldTypes.SLIDER;
	config: Omit<SliderProps, 'value' | 'required'>;
	label: string;
	values: string[];
};

type LocationField = FormFieldBase & {
	type: FormFieldTypes.LOCATION;
	config?: Omit<TextFieldProps, 'error' | 'helperText' | 'value' | 'required'>;
};

type RadioField = FormFieldBase &
	RadioButtonsComponentProps & {
		type: FormFieldTypes.RADIO;
	};

type CheckboxField = FormFieldBase &
	CheckboxComponentProps & {
		type: FormFieldTypes.CHECKBOX;
	};

type DividerField = FormFieldBase & {
	type: FormFieldTypes.DIVIDER;
	label: string;
};

type CustomComponent = FormFieldBase & {
	type: FormFieldTypes.CUSTOM;
	render: React.ReactNode;
};

type FormFieldBase = {
	id: string;
	validation?: RegisterOptions & {
		defaultErrorMessage?: string;
	};
};

type Option = {
	value: string | number | readonly string[] | undefined;
	copy: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			textAlign: 'center',
			'& [class*="MuiTextField-root"]': {
				marginBottom: theme.spacing(2),
				width: '100%',
			},
		},
		errorMessage: {
			color: theme.palette.error.main,
			marginBottom: theme.spacing(2),
		},
		textField: {
			marginBottom: `${theme.spacing('sm')} !important`,
		},
		selectWrapper: {
			width: '100%',
			marginBottom: theme.spacing('sm'),
		},

		select: {
			textAlign: 'left',
		},
		sliderWrapper: {
			width: '100%',
			marginBottom: theme.spacing('xs2'),
		},
		radioGroup: {
			display: 'none',
		},
		radioLabel: {
			position: 'relative',
			transform: 'scale(0.75) !important',
			textAlign: 'left',
			marginBottom: '8px',
		},
		dividerWrapper: {
			paddingTop: theme.spacing('md'),
			paddingBottom: theme.spacing('xs'),
		},
		dividerLabel: {
			marginTop: '-12px',
			backgroundColor: theme.palette.background.paper,
			width: 'fit-content',
			marginLeft: 'auto',
			marginRight: 'auto',
			padding: '0 4px',
		},
	})
);

const FormFieldsComponent: React.FC<FormFieldsComponentProps> = (props) => {
	const classes = useStyles();

	const renderFormItem = (field: FormField) => {
		let label = '';
		let errorMessage = '';

		switch (field.type) {
			case FormFieldTypes.INPUT:
				return (
					<Controller
						// They change there value to being never when defaultValues is passed through in the useForm hook, however name is required for the Controller to work...
						name={field.id}
						control={props.control}
						rules={field.validation}
						defaultValue={field.config?.defaultValue}
						render={({ field: formField, fieldState: { error } }) => {
							const hasError = !!error;
							if (hasError) {
								errorMessage = error?.message ?? '';
								if (errorMessage === '')
									errorMessage =
										field.validation?.defaultErrorMessage ?? 'Incorrect Value';
							} else {
								errorMessage = '';
							}

							return (
								<TextField
									className={classes.textField}
									variant="standard"
									error={hasError}
									helperText={errorMessage}
									required={Boolean(field.validation?.required)}
									{...field.config}
									{...formField}
								/>
							);
						}}
					></Controller>
				);
			case FormFieldTypes.DATE_PICKER:
				return (
					<Controller
						// They change there value to being never when defaultValues is passed through in the useForm hook, however name is required for the Controller to work...
						name={field.id}
						control={props.control}
						rules={field.validation}
						defaultValue={field.config?.defaultValue}
						render={({ field: formField, fieldState: { error } }) => {
							const hasError = !!error;
							if (hasError) {
								errorMessage = error?.message ?? '';
								if (errorMessage === '')
									errorMessage =
										field.validation?.defaultErrorMessage ?? 'Incorrect Value';
							} else {
								errorMessage = '';
							}

							return (
								<TextField
									type="date"
									variant="standard"
									InputLabelProps={{ shrink: true }}
									className={classes.textField}
									error={hasError}
									helperText={errorMessage}
									required={Boolean(field.validation?.required)}
									{...field.config}
									{...formField}
								/>
							);
						}}
					></Controller>
				);
			case FormFieldTypes.SELECT:
				label = `${field.label}${field.validation?.required ? '*' : ''}`;

				return (
					<Controller
						// They change there value to being never when defaultValues is passed through in the useForm hook, however name is required for the Controller to work...
						name={field.id}
						control={props.control}
						rules={field.validation}
						defaultValue={field.config?.defaultValue}
						render={({ field: formField, fieldState: { error } }) => {
							const hasError = !!error;
							if (hasError) {
								errorMessage = error?.message ?? '';
								if (errorMessage === '')
									errorMessage =
										field.validation?.defaultErrorMessage ?? 'Incorrect Value';
							} else {
								errorMessage = '';
							}

							return (
								<FormControl
									className={classes.selectWrapper}
									variant="standard"
									error={hasError}
								>
									<InputLabel>{label}</InputLabel>
									<Select
										className={classes.select}
										required={Boolean(field.validation?.required)}
										{...field.config}
										{...formField}
										onChange={(e) => {
											field?.config?.onChange?.(e, null);
											formField?.onChange?.(e);
										}}
										label={label}
									>
										{field.options.map((option, i) => {
											return (
												<MenuItem
													key={`form-field-option-${i}`}
													value={option.value}
												>
													{option.copy}
												</MenuItem>
											);
										})}
									</Select>
									{error ? (
										<FormHelperText>{errorMessage}</FormHelperText>
									) : null}
								</FormControl>
							);
						}}
					></Controller>
				);
			case FormFieldTypes.SLIDER:
				label = `${field.label}${field.validation?.required ? '*' : ''}`;
				return (
					<Controller
						// They change there value to being never when defaultValues is passed through in the useForm hook, however name is required for the Controller to work...
						name={field.id}
						control={props.control}
						rules={field.validation}
						defaultValue={field.config?.defaultValue}
						render={({ field: formField, fieldState: { error } }) => {
							const hasError = !!error;
							if (hasError) {
								errorMessage = error?.message ?? '';
								if (errorMessage === '')
									errorMessage =
										field.validation?.defaultErrorMessage ?? 'Incorrect Value';
							} else {
								errorMessage = '';
							}

							return (
								<FormControl
									className={classes.sliderWrapper}
									variant="standard"
									error={hasError}
								>
									<CustomSlider
										values={field.values}
										label={label}
										{...field.config}
										{...formField}
									/>
									{error ? (
										<FormHelperText>{errorMessage}</FormHelperText>
									) : null}
								</FormControl>
							);
						}}
					></Controller>
				);
			case FormFieldTypes.RADIO:
				return (
					<Controller
						name={field.id}
						control={props.control}
						rules={field.validation}
						defaultValue={field.config?.defaultValue}
						render={({ field: formField, fieldState: { error } }) => (
							<RadioButtonsComponent
								{...{ ...field, formField, error, type: undefined }}
							/>
						)}
					/>
				);
			case FormFieldTypes.CHECKBOX:
				return (
					<Controller
						name={field.id}
						control={props.control}
						rules={field.validation}
						defaultValue={field.config.defaultChecked}
						render={({ field: formField, fieldState: { error } }) => (
							<CheckboxComponent
								{...{
									...field,
									formField,
									error,
									type: undefined,
								}}
							/>
						)}
					/>
				);
			case FormFieldTypes.LOCATION:
				return (
					<Controller
						// They change there value to being never when defaultValues is passed through in the useForm hook, however name is required for the Controller to work...
						name={field.id}
						control={props.control}
						rules={field.validation}
						defaultValue={field.config?.defaultValue}
						render={({ field: formField, fieldState: { error } }) => {
							const hasError = !!error;
							if (hasError) {
								errorMessage = error?.message ?? '';
								if (errorMessage === '')
									errorMessage =
										field.validation?.defaultErrorMessage ?? 'Incorrect Value';
							} else {
								errorMessage = '';
							}

							return (
								<LocationInputComponent
									variant="standard"
									error={hasError}
									helperText={errorMessage}
									required={Boolean(field.validation?.required)}
									{...field.config}
									{...formField}
								/>
							);
						}}
					></Controller>
				);
			case FormFieldTypes.DIVIDER:
				return (
					<Box className={classes.dividerWrapper}>
						<Divider />
						<Typography className={classes.dividerLabel}>
							{field.label}
						</Typography>
					</Box>
				);
			case FormFieldTypes.CUSTOM:
				return field.render;
		}
	};

	return (
		<Box className={classes.wrapper}>
			{props.fields.map((field) => {
				return renderFormItem(field);
			})}
		</Box>
	);
};

export default FormFieldsComponent;
