import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Query
import {
	ListYoungPeopleQueryVariables,
	useListYoungPeopleQuery,
} from './query.generated';
import YoungPeopleView from './YoungPeopleView';

// Hooks / Contexts
import ModalContext from '../../contexts/ModalContext';
import { usePagination } from '../../hooks';

type YoungPeopleViewContainerProps = {
	where?: ListYoungPeopleQueryVariables['where'];
	sortBy?: ListYoungPeopleQueryVariables['sortBy'];
};

type Params = {
	houseId?: string;
};

const YoungPeopleViewContainer: React.FC<YoungPeopleViewContainerProps> = (
	props
) => {
	const params = useParams<Params>();
	const houseId = params.houseId;
	const { isOpen: isModalOpen } = useContext(ModalContext);
	const [skip, setSkip] = useState<number>(0);
	const take = 12;

	const { data, loading, error, refetch } = useListYoungPeopleQuery({
		variables: {
			where: props.where ?? { houseId: { equals: houseId } },
			sortBy: props.sortBy,
			skip,
			take,
		},
		fetchPolicy: 'network-only',
	});

	// If the modal closes then we want to refetch the data as we assume a YP has been created
	useEffect(() => {
		if (!isModalOpen && !loading) {
			refetch();
		}
	}, [isModalOpen]);

	useEffect(() => {
		if (!loading) {
			setCount(data?.countYoungPeople ?? 0);
		}
	}, [data, loading]);

	const {
		currentPage,
		handlePaginate: hookHandlePaginate,
		pageCount,
		setCount,
	} = usePagination({ take, count: data?.countYoungPeople ?? 0 });

	const handlePaginate = (page: number) => {
		hookHandlePaginate(page);
		setSkip((page - 1) * take);
	};

	return (
		<YoungPeopleView
			data={data}
			loading={loading}
			error={error}
			handlePaginate={handlePaginate}
			count={data?.countYoungPeople ?? 0}
			pageCount={pageCount}
			currentPage={currentPage}
		/>
	);
};

export default YoungPeopleViewContainer;
