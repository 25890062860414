import { useSnackbar } from 'notistack';
import { useState } from 'react';

import { FileType } from '../../graphql/types';
import { useCreateDownloadLinkMutation } from './query.generated';

const useFileDownload = () => {
	const [downloadFileMutaiton] = useCreateDownloadLinkMutation();
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setloading] = useState(false);

	const downloadFile = async (key: string) => {
		try {
			setloading(true);
			const { data } = await downloadFileMutaiton({
				variables: { fileKey: key },
			});

			if (data?.createDownloadUrl?.url) {
				const win = window.open(data?.createDownloadUrl?.url, '_blank');
				if (win) {
					win.focus();
				}
			} else {
				enqueueSnackbar('hmmm there was trouble getting the link', {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
			}
		} catch (error) {
			enqueueSnackbar('Sorry something went wrong downloading this file', {
				variant: 'error',
				anchorOrigin: { vertical: 'top', horizontal: 'right' },
			});
		} finally {
			setloading(false);
		}
	};

	const downloadTemplate = async (fileType: FileType) => {
		// TODO: [STLL-294] Need to find a better way to handle file . types
		const templateKey = `templates/${fileType}.docx`;

		await downloadFile(templateKey);
	};

	return {
		downloadFile,
		downloadTemplate,
		loading,
	};
};

export default useFileDownload;
