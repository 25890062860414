import cdkConfig from './cdk-exports.json';
require('dotenv').config();

const devApp = cdkConfig?.['dev-fabric-app'];
const prodApp = cdkConfig?.['production-fabric-app'];

const app =
	process.env.REACT_APP_ENVIRONMENT === 'production' ? prodApp : devApp;

const awsConfig = {
	aws_project_region: app.AwsRegion,
	aws_cognito_region: app.AwsRegion,
	aws_cognito_identity_pool_id:
		app.UserServiceStackawscognitoidentitypoolid8111D3D1,
	aws_user_pools_id: app.UserServiceStackawsuserpoolsid6AD66680,
	aws_user_pools_web_client_id: app.ApiStackawsuserpoolswebclientidB9E98065,
	aws_appsync_graphqlEndpoint: app.ApiStackawsappsyncgraphqlEndpointF0D5E196,
	aws_appsync_region: app.AwsRegion,
	aws_appsync_authenticationType:
		app.ApiStackawsappsyncauthenticationTypeE807BC4F,
};

export const appConfig = {
	avatar_url: app['FileServiceStackfabricavatarstackavatardomain3C25CF98'],
};

export default awsConfig;
