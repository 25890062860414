import * as React from 'react';
import { createStyles, makeStyles, Theme, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		container: {
			gap: theme.spacing('xs'),
			width: 'max-content',
		},
		buttonIcon: {
			color: theme.palette.text.primary,
			padding: 'unset',
			margin: 'unset',
			'button:disabled &': {
				opacity: 0.25,
			},
		},
	})
);

export type PaginationControlsProps = {
	disabled?: 'prev' | 'next' | 'both';
	handleClickPrev: () => void;
	handleClickNext: () => void;
};

/* Pagination controls, accepts children which will be rendered between the two icons. */

const PaginationControlsComponent: React.FC<PaginationControlsProps> = (
	props
) => {
	const styles = useStyles();

	return (
		<Grid container className={styles.container}>
			<IconButton
				size="small"
				disabled={props.disabled && props.disabled !== 'next'}
				onClick={props.handleClickPrev}
			>
				<ChevronLeft className={styles.buttonIcon} />
			</IconButton>
			{props.children}
			<IconButton
				size="small"
				disabled={props.disabled && props.disabled !== 'prev'}
				onClick={props.handleClickNext}
			>
				<ChevronRight className={styles.buttonIcon} />
			</IconButton>
		</Grid>
	);
};

export default PaginationControlsComponent;
