import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type CreateSpinSessionMutationVariables = Types.Exact<{
	data: Types.LogCreateInput;
}>;

export type CreateSpinSessionMutation = {
	__typename?: 'Mutation';
	createLog?: { __typename?: 'Log'; id: string } | null | undefined;
};

export const CreateSpinSessionDocument = gql`
	mutation CreateSpinSession($data: LogCreateInput!) {
		createLog(data: $data) {
			id
		}
	}
`;
export type CreateSpinSessionMutationFn = Apollo.MutationFunction<
	CreateSpinSessionMutation,
	CreateSpinSessionMutationVariables
>;

/**
 * __useCreateSpinSessionMutation__
 *
 * To run a mutation, you first call `useCreateSpinSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpinSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpinSessionMutation, { data, loading, error }] = useCreateSpinSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSpinSessionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateSpinSessionMutation,
		CreateSpinSessionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CreateSpinSessionMutation,
		CreateSpinSessionMutationVariables
	>(CreateSpinSessionDocument, options);
}
export type CreateSpinSessionMutationHookResult = ReturnType<
	typeof useCreateSpinSessionMutation
>;
export type CreateSpinSessionMutationResult =
	Apollo.MutationResult<CreateSpinSessionMutation>;
export type CreateSpinSessionMutationOptions = Apollo.BaseMutationOptions<
	CreateSpinSessionMutation,
	CreateSpinSessionMutationVariables
>;
