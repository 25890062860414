import * as Apollo from '@apollo/client';
import { Button, Grid, IconButton, styled } from '@material-ui/core';
import { ChevronLeft, Refresh } from '@material-ui/icons';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

// Components
import AutomatedTaskCardComponent from '../../components/AutomatedTaskCardComponent';
import FallbackComponent from '../../components/FallbackComponent';

// Context
import AuthContext from '../../contexts/authContext';
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import { useCompleteTaskMutation } from '../../graphql/hooks';

// Queries
import { FileType, FormType, TaskGroup } from '../../graphql/types';
import { GetYpTasksByGroupQueryHookResult } from './query.generated';

type Data = GetYpTasksByGroupQueryHookResult['data'];
type Tasks = NonNullable<Data>['listTasks'];

export type TaskGroupViewProps = {
	data: Data;
	loading?: boolean;
	error?: Apollo.ApolloError;
	onDownloadTemplate: (fileType: FileType) => void;
	youngPersonId: string;
	refetch?: GetYpTasksByGroupQueryHookResult['refetch'];
};

const ButtonContainer = styled('div')({
	display: 'flex',
	justifyContent: 'space-between',

	marginBottom: '1rem',
});

const TaskGroupView: React.FC<TaskGroupViewProps> = (props) => {
	const tasks: Tasks = props.data?.listTasks ?? [];
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const [completeTask] = useCompleteTaskMutation();

	const { open } = useContext(ModalContext);

	const group = tasks?.[0]?.group;

	let buttonCopy = '';
	switch (group) {
		case TaskGroup.MoveIn:
			buttonCopy = 'Move in';
			break;
		case TaskGroup.PreMoveIn:
			buttonCopy = 'Pre-Move in';
			break;
		case TaskGroup.MoveOut:
			buttonCopy = 'Move out';
			break;
		default:
			buttonCopy = 'Back';
			break;
	}

	return (
		<FallbackComponent
			hasError={Boolean(props.error)}
			errorTitle="An Error Occurred"
			errorCopy="We had a little trouble fetching this group. If the problem persists, please contact your system administrator."
		>
			<ButtonContainer>
				<Button onClick={() => history.goBack()}>
					<ChevronLeft />
					{buttonCopy}
				</Button>
				<IconButton
					onClick={() => {
						props.refetch?.();
					}}
				>
					<Refresh />
				</IconButton>
			</ButtonContainer>

			<Grid
				container
				direction="row"
				justify="flex-start"
				alignItems="center"
				spacing={3}
			>
				{tasks.map((task) => {
					return (
						<Grid key={task?.id} item xs={12} md={8} lg={4}>
							<AutomatedTaskCardComponent
								onClick={() => {
									if (task?.completedAt) return;

									if (task?.fileType)
										open({
											type: ModalType.UPLOAD,
											props: {
												fileType: task.fileType,
												youngPersonId: task.youngPerson?.id,
												houseId: task?.youngPerson?.houseId ?? '',
												groupName: task.group,
											},
										});
									if (task?.formType)
										open({
											type: ModalType.FORM,
											args: {
												// @ts-expect-error
												type: task?.formType as FormType,
												youngPersonId: task?.youngPerson?.id ?? '',
											},
											onCompleteCallback: async () => {
												try {
													await completeTask({
														variables: {
															taskId: task.id,
															completedAt: new Date().toISOString(),
															completedBy: user?.id ?? '',
														},
													});
												} catch (error) {
													console.log('error', error);
												}
											},
										});
								}}
								isComplete={!!task?.completedAt}
								title={task?.title ?? ''}
								fileType={task?.fileType ?? undefined}
								formType={task?.formType ?? undefined}
							/>
						</Grid>
					);
				})}
			</Grid>
		</FallbackComponent>
	);
};

export default TaskGroupView;
