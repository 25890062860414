import React from 'react';
import FallbackComponent from '../../components/FallbackComponent';
import dayjs, { Dayjs } from 'dayjs';

import {
	Card,
	Box,
	Typography,
	TableHead,
	Table,
	TableRow,
	TableCell,
	TableBody,
	createStyles,
	makeStyles,
	Theme,
	TextField,
} from '@material-ui/core';

import { Stats } from './types';

export type ReportKidSnapshotViewProps = {
	data?: Stats;
	error: boolean;
	loading: boolean;
	startDate: string;
	endDate: string;
	setStartDate: (date: Dayjs) => void;
	setEndDate: (date: Dayjs) => void;
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		card: {
			padding: theme.spacing(2),
		},
		cardHeader: {
			marginBottom: theme.spacing(2),
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		filters: {
			display: 'flex',
			gap: theme.spacing(2),
		},
	})
);

const ReportKidSnapshotView: React.FC<ReportKidSnapshotViewProps> = (props) => {
	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<Box className={classes.cardHeader}>
				<Typography variant="h2" component="h3">
					Stats
				</Typography>
				<Box className={classes.filters}>
					<TextField
						type="date"
						variant="standard"
						InputLabelProps={{ shrink: true }}
						label="Start Date"
						value={props.startDate}
						onChange={(event) => {
							props.setStartDate(dayjs(event.target.value));
						}}
					/>
					<TextField
						type="date"
						variant="standard"
						InputLabelProps={{ shrink: true }}
						label="End Date"
						value={props.endDate}
						onChange={(event) => {
							props.setEndDate(dayjs(event.target.value));
						}}
					/>
				</Box>
			</Box>
			<FallbackComponent
				isLoading={props.loading}
				loadingType="circularProgress"
				hasError={props.error}
				errorTitle={'Unable'}
				errorCopy={'Error Message'}
			>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Stat</TableCell>
							<TableCell>Count</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell>Incidents Reported</TableCell>
							<TableCell>{props.data?.incidentCount}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell> Missing </TableCell>
							<TableCell>{props.data?.missingCount}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell> Night Out ( Authorised ) </TableCell>
							<TableCell>{props.data?.authorizedCount}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell> Night Out ( UnAuthorised ) </TableCell>
							<TableCell>{props.data?.unauthorizedCount}</TableCell>
						</TableRow>
						<TableRow>
							<TableCell> Coaching Sessions</TableCell>
							<TableCell>{props.data?.lifeSkillSessions}</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</FallbackComponent>
		</Card>
	);
};

export default ReportKidSnapshotView;
