import shortId from 'shortid';

// Query
import {
	CreateAlertDocument,
	CreateAlertMutation,
	CreateAlertMutationVariables,
} from './query.generated';
import {
	FormListYoungPeopleDocument,
	ListAlertsDocument,
} from '../../../../graphql/hooks';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient } from '@apollo/client';
import { CreateAlertArgs } from '../../types';
import {} from '../../../../graphql/types';
import {
	FormListYoungPeopleQuery,
	FormListYoungPeopleQueryVariables,
	SeverityLevel,
} from '../../../../graphql/types';

type Data = {
	youngPerson: FormListYoungPeopleQuery['listYoungPeople'];
};

export type CreateAlertFormValues = {
	title: string;
	houseId: string;
	youngPersonId: string;
	severity: SeverityLevel;
};

const PreFetch = async (
	args: CreateAlertArgs,
	client: ApolloClient<object>
) => {
	const where = args.houseId
		? {
				house: {
					id: {
						equals: args.houseId,
					},
				},
		  }
		: {};

	const yp = await client.query<
		FormListYoungPeopleQuery,
		FormListYoungPeopleQueryVariables
	>({
		query: FormListYoungPeopleDocument,
		variables: {
			where,
		},
	});

	if (!yp.data.listYoungPeople || yp.error)
		throw new Error('Pre-fetched Data Failed');

	return {
		youngPerson: yp.data.listYoungPeople,
	};
};

const FormFields = (
	args: CreateAlertArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const options = data.youngPerson?.map((yp: any) => {
		return { value: yp?.id ?? '', copy: yp?.name ?? '' };
	});

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'youngPersonId',
			type: FormFieldTypes.SELECT,
			label: 'Kid:',
			config: {},
			validation: {
				required: true,
			},
			options: options ?? [],
		},
		{
			id: 'title',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Title:',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'severity',
			type: FormFieldTypes.SELECT,
			label: 'Severity:',
			config: {},
			validation: {
				required: true,
			},
			options: [
				{
					value: SeverityLevel.Medium,
					copy: 'Medium',
				},
				{
					value: SeverityLevel.High,
					copy: 'High',
				},
			],
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: CreateAlertArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: CreateAlertFormValues): Promise<any> => {
		const createHouse = await client.mutate<
			CreateAlertMutation,
			CreateAlertMutationVariables
		>({
			mutation: CreateAlertDocument,
			variables: {
				data: {
					...formValues,
					ref: shortId(),
				},
			},
			refetchQueries: [ListAlertsDocument, 'listNotifications'],
		});

		return createHouse;
	};
	return onSubmit;
};

const LogIncidentForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default LogIncidentForm;
