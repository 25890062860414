import * as React from 'react';
import Box from '@material-ui/core/Box';
import { theme } from '../../styles/theme';

export const ListFooterComponent: React.FC = (props) => (
	<Box
		component="footer"
		display="flex"
		justifyContent="space-between"
		alignItems="center"
		marginTop={theme.spacing('sm')}
	>
		{props.children}
	</Box>
);

export default ListFooterComponent;
