import React, { useContext, useEffect, useState } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';

import FormFieldsComponent, {
	FormFieldTypes,
} from '../../components/FormFieldsComponent';
import { FormField } from '../../components/FormFieldsComponent/FormFieldsComponent';
import { SpinSessionQuestions } from '../../types';

// Components
// Types
type StepTwoProps = {
	control: Control;
	watch: UseFormWatch<object>;
};

const StepTwo: React.FC<StepTwoProps> = (props) => {
	const questions: FormField[] = SpinSessionQuestions.map((question) => {
		return {
			id: question,
			type: FormFieldTypes.INPUT,
			config: {
				label: `${question}:`,
				defaultValue: '',
			},
			validation: {
				required: true,
			},
		};
	});

	const [formFields, setFormFields] = useState<FormField[]>([
		...questions,
		{
			id: 'anotherRound',
			type: FormFieldTypes.CHECKBOX,
			label: 'Add Another Behaviour',
			config: {},
			validation: {},
		},
	]);

	return <FormFieldsComponent control={props.control} fields={formFields} />;
};

export default StepTwo;
