import React, { useState, useContext, useMemo } from 'react';
import { useForm } from 'react-hook-form';

// Components
import ModalContentComponent, {
	ModalContentComponentProps,
} from '../../components/ModalContentComponent';

// Contexts
import ModalContext from '../../contexts/ModalContext';

// Form Steps
import StepOneForm from './StepOneForm';
import StepTwoForm from './StepTwoForm';

// Types
import { FormListYoungPeopleQuery } from '../../graphql/types';
import { SharedCoachingData, CoachingSession } from './types';
import { ErrorType } from '../../components/FallbackComponent';
import { SecondaryItemType } from '../../components/ModalTitleComponent';

type CreateCoachingSessionViewProps = {
	onFormSubmit: (
		sharedData: SharedCoachingData | undefined,
		coachingData: CoachingSession[]
	) => Promise<any>;
	pageLoading: boolean;
	error: boolean;
	youngPeopleData: FormListYoungPeopleQuery['listYoungPeople'];
};

const CreateCoachingSessionView: React.FC<CreateCoachingSessionViewProps> = (
	props
) => {
	const [youngPeople, setYoungPeople] = useState<string[]>([]);
	const [coachingData, setCoachingData] = useState<CoachingSession[]>([]);
	const [sharedData, setSharedData] = useState<
		SharedCoachingData | undefined
	>();
	const [step, setStep] = useState<number>(1);
	const [maxStep, setMaxStep] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(false);
	const { handleSubmit, reset, control, watch } = useForm({
		mode: 'onBlur',
		shouldFocusError: true,
	});

	const { close } = useContext(ModalContext);

	// Setup the modal header
	const modalHeader: ModalContentComponentProps['modalTitle'] = {
		title: 'Coaching Session',
		secondaryItem: {
			type: SecondaryItemType.TEXT,
			copy: `Step ${step} of ${maxStep}`,
		},
	};

	// Setup the buttons
	const modalButtons: ModalContentComponentProps['modalButtons'] =
		useMemo(() => {
			return {
				primaryAction: {
					label: step !== 1 && step === maxStep ? 'Submit' : 'Next',
					disabled: loading || props.pageLoading,
					loading: loading,
					onClick: handleSubmit(async (data) => {
						setLoading(true);

						// If we are on stepOne then grab the general data and the yp that were involved.
						if (step === 1) {
							const yp = [];

							for (var key in data) {
								if (key.includes('youngPerson')) yp.push(data[key]);
							}

							setYoungPeople(yp);
							setSharedData({
								category: data['category'],
								subCategory:
									data['category'] === 'Other'
										? data['customSubCategory']
										: data['subCategory'],
								date: data['date'],
							});
							setStep(2);
							setMaxStep(yp.length + 1);
							reset();
						} else if (step === maxStep) {
							await props.onFormSubmit(sharedData, [
								...coachingData,
								{
									...(data as Omit<CoachingSession, 'youngPersonId'>),
									youngPersonId: youngPeople[step - 2],
								},
							]);

							setStep(1);
							setMaxStep(1);
							setYoungPeople([]);
							setCoachingData([]);
							setSharedData(undefined);
							close();
						} else {
							const temp = [...coachingData];

							temp.push({
								...(data as Omit<CoachingSession, 'youngPersonId'>),
								youngPersonId: youngPeople[step - 2],
							});

							setStep(step + 1);
							setCoachingData(temp);
						}

						reset();
						setLoading(false);
					}),
				},
			};
		}, [step, maxStep, loading, props.pageLoading]);

	return (
		<ModalContentComponent
			modalTitle={modalHeader}
			modalButtons={modalButtons}
			isLoading={props.pageLoading}
			error={
				props.error
					? {
							title: 'An Error Occurred',
							copy: 'There was an issue loading this form, please try again later.',
							type: ErrorType.WARNING,
					  }
					: undefined
			}
		>
			{step === 1 ? (
				<StepOneForm
					youngPeopleData={props.youngPeopleData}
					control={control}
					watch={watch}
					reset={reset}
				/>
			) : (
				<StepTwoForm
					youngPersonId={youngPeople[step - 2]}
					control={control}
					watch={watch}
				/>
			)}
		</ModalContentComponent>
	);
};

export default CreateCoachingSessionView;
