import { ApolloClient } from '@apollo/client';
import dayjs from 'dayjs';

// Query
import {
	// Get Details
	GetYoungPersonBasicDetailsDocument,
	GetYoungPersonBasicDetailsQuery,
	GetYoungPersonBasicDetailsQueryVariables,
	// Update Details Mutation
	UpdateYoungPersonProfileDocument,
	UpdateYoungPersonProfileMutation,
	UpdateYoungPersonProfileMutationVariables,
} from './query.generated';

// Types
import { YpBasicDetailsArgs } from '../../types';
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';

type FormValues = {
	name: string;
	preferredName: string;
	gender: string;
	email: string;
	phone: string;
	dob: string;
	mostRecentAddress?: string;
	physicalDescription?: string;
	ethnicity?: string;
	spokenLanguage?: string;
	safePlace?: string;
	religion?: string;
};

const PreFetch = async (
	args: YpBasicDetailsArgs,
	client: ApolloClient<object>
) => {
	const details = await client.query<
		GetYoungPersonBasicDetailsQuery,
		GetYoungPersonBasicDetailsQueryVariables
	>({
		query: GetYoungPersonBasicDetailsDocument,
		variables: {
			youngPersonId: args.youngPersonId ?? '',
		},
	});

	if (!details.data.getYoungPerson || details.error)
		throw new Error('Pre-fetched Data Failed.');

	return details.data.getYoungPerson;
};

const FormFields = (
	args: YpBasicDetailsArgs,
	data: GetYoungPersonBasicDetailsQuery['getYoungPerson']
): FormFieldsComponentProps['fields'][] => {
	// TODO: Need to add actual validation to these forms
	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'name',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Name:',
				defaultValue: data?.name ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'preferredName',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Preferred Name:',
				defaultValue: data?.preferredName ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'preferredName',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Preferred Name:',
				defaultValue: data?.gender ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'email',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Email:',
				defaultValue: data?.email ?? '',
			},
		},
		{
			id: 'phone',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Phone:',
				defaultValue: data?.phone ?? '',
			},
			validation: {
				required: true,
			},
		},

		{
			id: 'dob',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Date of Birth:',
				defaultValue: dayjs(data?.dateOfBirth).format('YYYY-MM-DD') ?? '',
				InputLabelProps: {
					shrink: true,
				},
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'mostRecentAddress',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Most Recent Address:',
				defaultValue: data?.mostRecentAddress ?? '',
				multiline: true,
				rows: 4,
			},
		},
		{
			id: 'physicalDescription',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Appearance:',
				defaultValue: data?.physicalDescription ?? '',
				multiline: true,
				rows: 4,
			},
		},
		{
			id: 'ethnicity',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Ethnicity:',
				defaultValue: data?.ethnicity ?? '',
			},
		},
		{
			id: 'religion',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Religion:',
				defaultValue: data?.religion ?? '',
			},
		},
		{
			id: 'spokenLanguage',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Spoken Language:',
				defaultValue: data?.spokenLanguage ?? '',
			},
		},
		{
			id: 'safePlace',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Where I go when I am missing:',
				defaultValue: data?.safePlace ?? '',
				multiline: true,
				rows: 4,
			},
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: YpBasicDetailsArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: FormValues): Promise<any> => {
		const data = {
			...{ ...formValues, dob: undefined },
			dateOfBirth: dayjs(formValues.dob).toISOString(),
		};
		console.log('data', data);

		const updateYp = await client.mutate<
			UpdateYoungPersonProfileMutation,
			UpdateYoungPersonProfileMutationVariables
		>({
			mutation: UpdateYoungPersonProfileDocument,
			variables: {
				youngPersonId: args.youngPersonId ?? '',
				data,
			},
		});

		return updateYp;
	};

	return onSubmit;
};

const YoungPersonBasicDetailsForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default YoungPersonBasicDetailsForm;
