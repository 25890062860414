import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { useTitle } from 'react-use';

import HouseScreen from './HouseScreen';
import { useGetHouseQuery } from './query.generated';

type HouseScreenContainerProps = {};
type QueryParams = { houseId: string };

const HouseScreenContainer: React.FC<HouseScreenContainerProps> = () => {
	const { houseId } = useParams<QueryParams>();
	let { url } = useRouteMatch();
	const href = window.location.href;

	const history = useHistory();
	const { data, loading, error } = useGetHouseQuery({ variables: { houseId } });

	useTitle(`STELLA | ${data?.getHouse?.title}` ?? 'STELLA | House');

	/**
	 * Manages the changes in the tab order to update the history when the user clicks on the tab
	 * @param _event
	 * @param newValue
	 * @returns
	 */
	const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
		switch (newValue) {
			case 0:
				return history.push(`${url}/dashboard`);
			case 1:
				return history.push(`${url}/activity`);
			case 2:
				return history.push(`${url}/tasks`);
			case 3:
				return history.push(`${url}/kids`);
			case 4:
				return history.push(`${url}/rooms`);
			case 5:
				return history.push(`${url}/files`);
		}
	};

	let defaultTab: number = 0;

	if (href.includes('dashboard')) {
		defaultTab = 0;
	} else if (href.includes('activity')) {
		defaultTab = 1;
	} else if (href.includes('tasks')) {
		defaultTab = 2;
	} else if (href.includes('kids')) {
		defaultTab = 3;
	} else if (href.includes('rooms')) {
		defaultTab = 4;
	} else if (href.includes('files')) {
		defaultTab = 5;
	}

	return (
		<HouseScreen
			onChangeTab={handleTabChange}
			data={data}
			loading={loading}
			error={error}
			tab={defaultTab}
			houseId={houseId}
		/>
	);
};

export default HouseScreenContainer;
