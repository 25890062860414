import * as React from 'react';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListTitleComponent from '../../components/ListTitleComponent';
import ListTitleContainerComponent from '../../components/ListTitleContainerComponent';
import {
	OrderByArg,
	TaskOrderByInput,
	TaskWhereFilterInput,
} from '../../graphql/types';
import UserSelectView from '../../views/UserSelectView';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export enum SortValues {
	CREATED_AT_ASC = 'createdAtAsc',
	CREATED_AT_DESC = 'createdAtDesc',
	DUE_DATE_ASC = 'dueDateAsc',
	DUE_DATE_DESC = 'dueDateDesc',
}

export type FilterTasksLayoutProps = {
	onSelectSortBy: (value: TaskOrderByInput) => void;
	onSelectFilter: (value: TaskWhereFilterInput) => void;
	filtersValue?: TaskWhereFilterInput;
	sortByValues?: TaskOrderByInput;
	houseId?: string;
};

export const FilterTasksLayout: React.FC<FilterTasksLayoutProps> = (props) => {
	const [sortValue, setSortValue] = React.useState<SortValues>(
		SortValues.DUE_DATE_DESC
	);

	const [showCompleted, setShowCompleted] = React.useState<boolean>(
		!!props.filtersValue?.completedAt?.equals
	);

	const [hidePrivate, setHidePrivate] = React.useState<boolean>(
		!!props.filtersValue?.isPrivate?.equals
	);

	const handleSelectSortBy = (value: SortValues) => {
		if (value === SortValues.DUE_DATE_ASC) {
			props.onSelectSortBy({ dueDate: OrderByArg.Asc });
		}
		if (value === SortValues.DUE_DATE_DESC) {
			props.onSelectSortBy({ dueDate: OrderByArg.Desc });
		}

		if (value === SortValues.CREATED_AT_ASC) {
			props.onSelectSortBy({ createdAt: OrderByArg.Asc });
		}

		if (value === SortValues.CREATED_AT_DESC) {
			props.onSelectSortBy({ createdAt: OrderByArg.Desc });
		}

		if (typeof value === 'string') {
			setSortValue(value);
		}
	};

	const handleShowCompletedTasks = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked === true) {
			props.onSelectFilter({
				...props.filtersValue,
				completedAt: undefined,
			});
			setShowCompleted(true);
		} else {
			props.onSelectFilter({
				...props.filtersValue,
				completedAt: { equals: null },
			});
			setShowCompleted(false);
		}
	};

	const handleShowPrivateTasks = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked) {
			props.onSelectFilter({
				...props.filtersValue,
				isPrivate: { equals: true },
			});
			setHidePrivate(true);
		} else {
			props.onSelectFilter({
				...props.filtersValue,
				isPrivate: undefined,
			});
			setHidePrivate(false);
		}
	};

	return (
		<Box width="100%">
			<ListTitleContainerComponent>
				<ListTitleComponent label="Filter Tasks" />
			</ListTitleContainerComponent>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="stretch"
				gridRowGap="20px"
			>
				<FormControl>
					<InputLabel>Sort By</InputLabel>
					<Select
						value={sortValue}
						onChange={(e) => handleSelectSortBy(e.target.value as SortValues)}
					>
						<MenuItem value={SortValues.DUE_DATE_ASC}>
							Due Date - Ascending
						</MenuItem>
						<MenuItem value={SortValues.DUE_DATE_DESC}>
							Due Date - Descending
						</MenuItem>
						<MenuItem value={SortValues.CREATED_AT_ASC}>
							Created At - Ascending
						</MenuItem>
						<MenuItem value={SortValues.CREATED_AT_DESC}>
							Created At - Descending
						</MenuItem>
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel>Assigned to</InputLabel>
					<UserSelectView
						houseId={props.houseId}
						onSelect={(userId) => {
							userId === 'all'
								? props.onSelectFilter({})
								: props.onSelectFilter({ assignedUserId: { equals: userId } });
						}}
					/>
				</FormControl>
				<FormControlLabel
					checked={showCompleted}
					control={
						<Checkbox
							onChange={handleShowCompletedTasks}
							name="show completed Tasks"
							color="primary"
						/>
					}
					label="Show completed Tasks"
				/>

				<FormControlLabel
					checked={hidePrivate}
					control={
						<Checkbox
							onChange={handleShowPrivateTasks}
							name="hide private Tasks"
							color="primary"
						/>
					}
					label="Hide Private Tasks"
				/>
			</Box>
		</Box>
	);
};

export default FilterTasksLayout;
