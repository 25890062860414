import React from 'react';
import {
	Box,
	Button,
	createStyles,
	Theme,
	makeStyles,
} from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			alignItems: 'flex-start',
			marginBottom: theme.spacing('sm'),
		},
	})
);

type AddAnotherButtonProps = {
	disabled?: boolean;
	onClick: () => void;
};

const AddAnotherButton: React.FC<AddAnotherButtonProps> = (props) => {
	const classes = useStyles();

	return (
		<Box className={classes.wrapper}>
			<Button
				color="primary"
				startIcon={<PersonAddIcon />}
				onClick={props.onClick}
				disabled={props.disabled}
			>
				Add Another
			</Button>
		</Box>
	);
};

export default AddAnotherButton;
