import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ListAdminUsersQueryVariables = Types.Exact<{
	[key: string]: never;
}>;

export type ListAdminUsersQuery = {
	__typename?: 'Query';
	listUsers?:
		| Array<
				| { __typename?: 'User'; id: string; name?: string | null | undefined }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const ListAdminUsersDocument = gql`
	query ListAdminUsers {
		listUsers(
			where: {
				AND: [
					{ role: { equals: ADMIN } }
					{ hideAccount: { not: { equals: true } } }
				]
			}
		) {
			id
			name
		}
	}
`;

/**
 * __useListAdminUsersQuery__
 *
 * To run a query within a React component, call `useListAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminUsersQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListAdminUsersQuery,
		ListAdminUsersQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ListAdminUsersQuery, ListAdminUsersQueryVariables>(
		ListAdminUsersDocument,
		options
	);
}
export function useListAdminUsersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListAdminUsersQuery,
		ListAdminUsersQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ListAdminUsersQuery, ListAdminUsersQueryVariables>(
		ListAdminUsersDocument,
		options
	);
}
export type ListAdminUsersQueryHookResult = ReturnType<
	typeof useListAdminUsersQuery
>;
export type ListAdminUsersLazyQueryHookResult = ReturnType<
	typeof useListAdminUsersLazyQuery
>;
export type ListAdminUsersQueryResult = Apollo.QueryResult<
	ListAdminUsersQuery,
	ListAdminUsersQueryVariables
>;
