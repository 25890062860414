import { Paper, useTheme, Box } from '@material-ui/core';
import React, { useState } from 'react';
import ListTitleComponent from '../../components/ListTitleComponent';
import ListTitleContainerComponent from '../../components/ListTitleContainerComponent';
import YoungPersonLocationStatusSelectView from '../YoungPersonLocationStatusSelectView';
import YoungPersonWhereaboutView from '../YoungPersonWhereaboutView';
import { LocationFilterType } from '../../types';

const WhereaboutServiceDashboardView: React.FC = () => {
	const [locationFilter, setLocationFilter] = useState<LocationFilterType>();
	const theme = useTheme();

	return (
		<Paper elevation={2}>
			<Box padding={`${theme.spacing('sm')} ${theme.spacing('md')}`}>
				<ListTitleContainerComponent>
					<ListTitleComponent label="Whereabouts status" />
					<YoungPersonLocationStatusSelectView
						onFilterByLocation={setLocationFilter}
						locationFilter={locationFilter}
					/>
				</ListTitleContainerComponent>
				<YoungPersonWhereaboutView locationFilter={locationFilter} />
			</Box>
		</Paper>
	);
};

export default WhereaboutServiceDashboardView;
