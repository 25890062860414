import * as React from 'react';
import { useTheme } from '@material-ui/core';

export type NotFoundIconProps = {
	color: string;
	className?: string;
};

export const NotFoundIconComponent: React.FC<NotFoundIconProps> = (props) => {
	const theme = useTheme();

	return (
		<svg
			className={props.className}
			xmlns="http://www.w3.org/2000/svg"
			data-name="Layer 1"
			viewBox="0 0 1074.392 584.231"
		>
			<ellipse
				cx="540.64346"
				cy="549.3094"
				fill="#f2f2f2"
				rx="527.5"
				ry="34.9216"
			/>
			<path
				fill="#2f2e41"
				d="M520.42569 167.00974c-85.94407 0-147.651 55.13938-147.651 183.79791 0 145.813 61.70691 184.41057 147.651 184.41057s151.327-42.27352 151.327-184.41057c-.00005-151.9396-65.38293-183.79791-151.327-183.79791Zm.56495 319.80837c-59.52686 0-90.62592-34.92288-90.62592-135.9163 0-89.11185 32.37209-136.10461 91.899-136.10461s91.899 30.86774 91.899 136.10461c-.00009 98.44736-33.64522 135.9163-93.17208 135.9163Z"
			/>
			<path
				fill={props.color}
				d="M321.31131 433.51671H285.777V328.87733a20.95585 20.95585 0 0 0-20.95583-20.95583h-8.32638a20.95585 20.95585 0 0 0-20.95586 20.95585v104.63936H135.30885a11.96327 11.96327 0 0 1-10.57763-17.552l106.0824-200.78034a20.95585 20.95585 0 0 0-9.58038-28.73887l-6.26231-2.9572a20.95585 20.95585 0 0 0-27.4293 9.07005L58.16016 434.5909a28.41578 28.41578 0 0 0-3.35584 13.39612 28.41583 28.41583 0 0 0 28.41584 28.41583h152.31877v66.16727a25.119 25.119 0 0 0 25.119 25.119h.00005a25.119 25.119 0 0 0 25.119-25.119v-66.16723h35.53428a21.44307 21.44307 0 0 0 21.44307-21.44307 21.44307 21.44307 0 0 0-21.44302-21.44311Zm657.99652 0h-35.53428V328.87733a20.95585 20.95585 0 0 0-20.95586-20.95583h-8.32638a20.95585 20.95585 0 0 0-20.95586 20.95585v104.63936H793.30537a11.96326 11.96326 0 0 1-10.57763-17.552l106.08239-200.7803a20.95586 20.95586 0 0 0-9.58037-28.73891l-6.26231-2.9572a20.95586 20.95586 0 0 0-27.42931 9.07005L716.15668 434.5909a28.41578 28.41578 0 0 0-3.35584 13.39612 28.41583 28.41583 0 0 0 28.41583 28.41583h152.31878v66.16727a25.119 25.119 0 0 0 25.119 25.119 25.119 25.119 0 0 0 25.119-25.119v-66.16723h35.53428a21.44307 21.44307 0 0 0 21.44307-21.44307 21.44307 21.44307 0 0 0-21.44297-21.44311Z"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M331.11387 421.2635h-35.53429V316.62414a20.95585 20.95585 0 0 0-20.95585-20.95586h-8.32638a20.95586 20.95586 0 0 0-20.95586 20.95586V421.2635H145.11141a11.96327 11.96327 0 0 1-10.57763-17.552l106.08239-200.78028a20.95586 20.95586 0 0 0-9.58037-28.73893l-6.26231-2.9572a20.95586 20.95586 0 0 0-27.42931 9.07L67.96272 422.3377a28.41582 28.41582 0 0 0-3.35584 13.39613 28.41583 28.41583 0 0 0 28.41583 28.41583h152.31878v66.16727a25.119 25.119 0 0 0 25.119 25.119h.00005a25.119 25.119 0 0 0 25.119-25.119V464.1497h35.53429a21.44307 21.44307 0 0 0 21.44307-21.44307 21.44307 21.44307 0 0 0-21.44303-21.44313Zm666.57375 0h-35.53428V316.62414a20.95586 20.95586 0 0 0-20.95586-20.95586h-8.32638a20.95586 20.95586 0 0 0-20.95586 20.95586V421.2635H811.68516a11.96327 11.96327 0 0 1-10.57763-17.552l106.08239-200.78028a20.95586 20.95586 0 0 0-9.58037-28.73893l-6.26231-2.9572a20.95586 20.95586 0 0 0-27.42931 9.07L734.53647 422.3377a28.41582 28.41582 0 0 0-3.35584 13.39613 28.41583 28.41583 0 0 0 28.41583 28.41583h152.31878v66.16727a25.119 25.119 0 0 0 25.119 25.119 25.119 25.119 0 0 0 25.119-25.119V464.1497h35.53428a21.44307 21.44307 0 0 0 21.44307-21.44307 21.44307 21.44307 0 0 0-21.44297-21.44313ZM540.0308 155.98187c-85.94407 0-147.651 55.13937-147.651 183.79791 0 145.813 61.70691 184.41057 147.651 184.41057s151.327-42.27352 151.327-184.41057c-.00005-151.93961-65.38293-183.79791-151.327-183.79791Zm.565 319.80836c-59.52686 0-90.62592-34.92287-90.62592-135.91629 0-89.11185 32.37209-136.10461 91.899-136.10461s91.899 30.86774 91.899 136.10461c-.00014 98.44735-33.64527 135.91629-93.17213 135.91629Z"
			/>
			<circle cx="471.14108" cy="18.25044" r="12.90118" fill="#2f2e41" />
			<ellipse
				cx="502.60736"
				cy="46.88476"
				fill="#2f2e41"
				rx="36.18622"
				ry="46.88476"
			/>
			<path
				d="M502.60736 79.60969c-18.1276 0-33.1413-17.27052-35.77576-39.80484a60.9759 60.9759 0 0 0-.41046 7.07991c0 25.89373 16.20114 46.88476 36.18622 46.88476s36.18623-20.991 36.18623-46.88476a60.9759 60.9759 0 0 0-.41046-7.07991c-2.63446 22.53432-17.64813 39.80484-35.77577 39.80484Z"
				opacity=".1"
			/>
			<path
				fill="#3f3d56"
				d="M576.24219 184.18876c-.77711 3.19345-4.12792 5.751-7.83881 7.53791-7.80188 3.75682-17.4253 4.87788-26.7597 5.25418a45.17622 45.17622 0 0 1-7.1445-.132 20.5371 20.5371 0 0 1-12.25052-5.63141 1.68086 1.68086 0 0 1 .04371-2.84388c4.9694-5.45888 13.2622-8.80605 21.61613-11.21609 6.3344-1.82743 17.3813-6.56089 24.29013-5.9221 6.69181.61877 9.02477 8.92125 8.04356 12.95339Z"
			/>
			<path
				d="M576.24219 184.18876c-.77711 3.19345-4.12792 5.751-7.83881 7.53791-7.80188 3.75682-17.4253 4.87788-26.7597 5.25418a45.17622 45.17622 0 0 1-7.1445-.132 20.5371 20.5371 0 0 1-12.25052-5.63141 1.68086 1.68086 0 0 1 .04371-2.84388c4.9694-5.45888 13.2622-8.80605 21.61613-11.21609 6.3344-1.82743 17.3813-6.56089 24.29013-5.9221 6.69181.61877 9.02477 8.92125 8.04356 12.95339Z"
				opacity=".1"
			/>
			<path
				fill="#3f3d56"
				d="M477.04386 160.3214a19.76967 19.76967 0 0 0-1.1987 15.07476 26.33914 26.33914 0 0 0 8.82921 12.49683c10.09467 8.09163 23.98784 9.20512 36.92477 9.09278a284.6495 284.6495 0 0 0 33.90525-2.32384 40.53788 40.53788 0 0 0 11.00143-2.55442c4.22242-1.82679 7.93282-5.17756 9.436-9.5257s.43625-9.67246-3.13383-12.57428c-3.13686-2.54969-7.46265-2.9004-11.49775-3.14289l-23.08764-1.38745c2.281-2.30839 5.31816-3.614 8.09586-5.29216 3.68523-2.22642 6.13358-5.96455 8.81312-9.33471a129.00143 129.00143 0 0 1 13.4386-13.817c.75138 4.31038 3.4782 7.8499 6.68733 10.824s6.90841 5.36845 10.2439 8.20013c8.0786 6.85838 13.89583 16.1669 22.39215 22.50043a43.82885 43.82885 0 0 0 16.04862-8.0122l-3.30209-5.98141a3.94 3.94 0 0 0-1.24459-1.55282c-.93465-.575-2.13975-.27872-3.225-.44144-2.90082-.435-4.16771-3.784-5.306-6.48737-3.12491-7.42173-9.108-13.17993-14.21783-19.40381a98.00854 98.00854 0 0 1-9.99577-14.72284c-1.71652-3.10162-3.288-6.33107-5.61746-9.00321s-5.59358-4.773-9.1385-4.78051c-3.13222-.00662-6.02122 1.58355-8.71422 3.18308a230.47679 230.47679 0 0 0-23.63018 16.09894c-3.94376 3.0617-7.86306 6.29645-12.48933 8.17393-1.94748.79035-4.00044 1.33052-5.86924 2.29223-3.27313 1.6844-5.75721 4.53435-8.43128 7.06415-9.53757 9.02315-22.59569 14.86931-35.71683 15.3368Z"
			/>
			<path
				fill="#fbbebe"
				d="M525.3197 96.09801a23.77444 23.77444 0 0 1-1.73379 8.03335 10.04492 10.04492 0 0 1-5.76772 5.57269 12.37513 12.37513 0 0 1-5.62306.18249 10.88232 10.88232 0 0 1-4.58151-1.56071c-2.16484-1.48837-3.24415-4.14413-3.63748-6.74325-.39333-2.596-.21714-5.24857-.46885-7.86342a42.94439 42.94439 0 0 0-1.202-6.25549c-.16993-.68282-.343-1.36248-.51294-2.04216-.16674-.67967-.33037-1.35935-.48141-2.039-.13847-.63878-.26745-1.28068-.37761-1.92574-.09123-.54436-.173-1.09189-.23285-1.64255a18.42329 18.42329 0 0 0-.80867-4.81118 14.60727 14.60727 0 0 0-1.68659-2.854c-.28635-.40906-.56326-.81811-.81815-1.24292a5.88984 5.88984 0 0 1-.97226-3.74763 3.286 3.286 0 0 1 .14788-.601c.02516-.07552.05347-.151.085-.2234a1.80187 1.80187 0 0 0 1.23031-1.14859 3.43341 3.43341 0 0 0-.14788-1.77783 11.31808 11.31808 0 0 0-.95974-2.28761c-.2643-.47829-1.16108-1.34046-1.16738-1.888-.0126-1.10132 2.13972-1.98867 3.01134-2.42291a16.79623 16.79623 0 0 1 8.59657-1.74323c1.90369.129 3.9679.71428 5.0189 2.30962.944 1.438.81807 3.30081 1.22085 4.97169a1.47068 1.47068 0 0 0 .29892.66393 1.34135 1.34135 0 0 0 .73948.33982 4.54948 4.54948 0 0 0 1.416.05666h.00315a2.93138 2.93138 0 0 0 .37128-.05351 4.957 4.957 0 0 0 2.03271-.8779q.58531-.15576 1.18-.25488a.25112.25112 0 0 0 .04725-.00945c1.57646 4.97482 1.781 10.30836 3.07111 15.37444.63874 2.52044 1.55442 5.00943 1.6834 7.60225.00945.11327.0126.2297.01575.34612.0189.83386-.04717 1.674-.0126 2.50472a6.981 6.981 0 0 0 .12591 1.1139 15.61121 15.61121 0 0 0 .52546 1.74325l.00945.02831c.05977.18251.11643.36817.16363.55381.03457.1353.06607.26747.09127.40277l.00311.00943a14.93754 14.93754 0 0 1 .10376 4.20711Z"
			/>
			<circle cx="503.23669" cy="44.99678" r="18.56511" fill="#fbbebe" />
			<path
				fill="#fbbebe"
				d="M621.10311 146.14828a30.445 30.445 0 0 0-5.236-14.10317q.72216 4.29513 1.44748 8.58714a3.214 3.214 0 0 1-3.36688-1.03523 10.33663 10.33663 0 0 1-1.76529-3.27565 67.46571 67.46571 0 0 0-8.2095-14.73567c-11.81876-.98489-23.50223-5.88418-33.89555-11.59532-10.39643-5.708-20.12582-12.5519-30.38382-18.50217a43.57346 43.57346 0 0 0-5.54436-2.832c-3.20954-1.287-6.81242-1.95406-9.85526-3.46759-.2045-.1007-.409-.20767-.61043-.31781a12.57834 12.57834 0 0 1-1.94459-1.30584 10.34363 10.34363 0 0 1-.93139-.8559 20.35115 20.35115 0 0 1-3.55886-5.95341c-1.63308-3.61232-2.21524-7.97041-3.84517-11.58274a11.20292 11.20292 0 0 1 2.50156-1.76525h.00315c.13213-.06924.2643-.13532.39962-.19824a11.9404 11.9404 0 0 1 2.00437-.73317q.58531-.15576 1.18-.25488a.25112.25112 0 0 0 .04725-.00945 11.56564 11.56564 0 0 1 5.49085.43424c2.58652.87477 4.76711 2.62115 6.94148 4.27313a114.02006 114.02006 0 0 1 10.14787 8.04908c1.79357 1.718 3.4298 3.606 5.35868 5.16676a42.14393 42.14393 0 0 0 5.05662 3.35116q15.65613 9.32658 31.31525 18.65005c3.53365 2.1051 7.07046 4.21019 10.52553 6.438 5.24855 3.38578 10.30828 7.05474 15.36493 10.72057q4.46978 3.23787 8.93647 6.47889a9.72771 9.72771 0 0 1 2.533 2.3411 8.4724 8.4724 0 0 1 1.12337 3.433 31.3874 31.3874 0 0 1-1.23038 14.60037Z"
			/>
			<path
				fill={props.color}
				d="M529.92326 110.0596c-1.25235 5.61674-6.92888 9.012-9.89617 13.94586-3.68784 6.12335-2.18378 13.241-.79922 20.25484q-3.79485 3.27095-7.59285 6.54186c-1.39708 1.19886-2.79417 2.404-4.29827 3.46444a57.35064 57.35064 0 0 1-6.85966 3.93956q-3.3606 1.72752-6.72119 3.45814a32.1282 32.1282 0 0 1-6.57961 2.78793c-4.41473 1.13278-9.10318.33982-13.4707-.97232a6.08761 6.08761 0 0 1-1.47264-.601 2.39351 2.39351 0 0 1-.69854-.63248 3.91067 3.91067 0 0 1-.44365-2.53933c.44365-7.35052 2.24036-14.54686 4.03081-21.68971a85.2598 85.2598 0 0 1 3.84832-12.57708 85.0766 85.0766 0 0 1 5.41538-10.151 68.36751 68.36751 0 0 1 7.92948-11.51353 18.47881 18.47881 0 0 0 3.67525-4.73882c1.11706-2.54876.686-5.472.91252-8.24732a17.14844 17.14844 0 0 1 1.63312-6.0069v-.00315a17.09326 17.09326 0 0 1 1.74321-2.88232q.45788 1.06671.91568 2.13027.30209.69855.59783 1.394.38706.89679.7678 1.78728 1.09973 2.55823 2.19637 5.11327a21.58968 21.58968 0 0 0 3.33538 5.944 6.49923 6.49923 0 0 0 11.12337-.85275 21.26125 21.26125 0 0 0 2.27185-6.0132 19.21547 19.21547 0 0 0 .25175-7.83509c-.75835-5.00945-2.88862-10.12585-4.43678-14.77972a14.94511 14.94511 0 0 1-1.07927-4.871 3.35144 3.35144 0 0 1 .05662-.56011c.00945-.04719.0189-.09754.02834-.14473a11.9404 11.9404 0 0 1 2.00437-.73317q.58531-.15576 1.18-.25488 2.04378 11.06355 4.09377 22.12709c.0315.17307.0661.34613.09756.52234.19509 1.05726.39333 2.11454.61358 3.16865.19828.95657.41223 1.91.65137 2.85715l.00945.02831c.08182.321.16678.63877.2549.95658l.00311.00943c.2423.86848.5129 1.73065.81811 2.58024 1.85024 5.11955 5.07554 10.27373 3.88925 15.58837Z"
			/>
			<path
				fill="#3f3d56"
				d="M605.26744 188.99257a6.58269 6.58269 0 0 0 .61 3.14328c1.16192 2.12353 3.94981 2.60625 6.36228 2.80484a188.37688 188.37688 0 0 0 42.2657-1.28774 4.88565 4.88565 0 0 0 2.15136-.66766c1.98985-1.39509.76329-4.7951-1.40951-5.88355s-4.75126-.82614-7.1353-1.29748a22.47912 22.47912 0 0 1-6.67794-2.89617q-7.25234-4.16669-14.293-8.68808c-2.79453-1.79464-6.09272-3.70993-9.23987-2.64587-8.52516 2.884-12.68983 8.22201-12.63372 17.41843Z"
			/>
			<path
				fill="#fbbebe"
				d="M501.38332 82.98917v.00315c-.022.13215-.04406.26116-.07237.39018-.0346.214-.07551.43108-.11642.645-.39018 1.99812-.86847 3.98678-1.41913 5.96287-1.5104 5.45939-3.53366 10.83069-5.54121 16.12332q-8.08055 21.28692-16.16423 42.577c-1.35936 3.57457-2.71554 7.15228-4.26054 10.65448-.516 1.16741-1.04782 2.34424-1.57647 3.53368-1.89427 4.25737-3.713 8.65322-4.31716 13.18436a27.44976 27.44976 0 0 0-.19194 9.04027c.60416 2.97042 2.40718 5.8716 5.22969 6.96977 1.37823.53808 3.35113 1.25865 2.97355 2.69037-.2045.78665-1.09817 1.17055-1.90057 1.3027a7.31234 7.31234 0 0 1-5.966-1.718c-1.50725-1.33732-2.66518-3.41725-4.66959-3.64065-1.38767-.151-2.66518.67966-3.93643 1.26178-5.18564 2.36942-11.22719.71114-16.674-.9723.42794-2.20579 2.64318-3.65953 4.84267-4.10006 2.19949-.44367 4.47449-.129 6.718-.18879a3.50958 3.50958 0 0 0 2.04216-.52549 3.70545 3.70545 0 0 0 1.10132-1.88169 78.96356 78.96356 0 0 0 3.21273-13.14661c.7237-4.66645 1.02581-9.40527 2.05787-14.01507.80241-3.59661 2.0422-7.07991 3.10572-10.61044a224.68238 224.68238 0 0 0 5.0598-22.07674 78.02019 78.02019 0 0 0 1.42543-9.36751c.17935-2.6117.09438-5.236.34609-7.83826a60.8877 60.8877 0 0 1 2.11141-9.99683q1.44427-5.34769 2.88547-10.68911c1.42544-5.2706 2.95465-10.74572 6.567-14.84264a13.96159 13.96159 0 0 1 10.02834-4.78915 9.8819 9.8819 0 0 1 2.13027.22969c.11639.02831.23285.05664.34923.0881a8.63447 8.63447 0 0 1 2.17437.89995c1.11388-.708 1.68025-.45942 2.41974.63246a6.97319 6.97319 0 0 1 .88107 3.79485 52.42378 52.42378 0 0 1-.85587 10.41536Z"
			/>
			<path
				fill={props.color}
				d="m502.60736 87.1616-.0472.04719-.25486.25488-2.5299 2.52675-1.23976-5.20767-4.25109-17.854a9.8819 9.8819 0 0 1 2.13027.22969 3.286 3.286 0 0 1 .14788-.601l.20135.68911 1.44118 4.90245 2.72811 9.30773.45 1.53241v.00315Z"
			/>
			<path
				fill="#2f2e41"
				d="M518.66123 30.2028a12.58165 12.58165 0 0 1-3.70049 8.89583 12.31392 12.31392 0 0 1-1.36008 1.17634 12.52812 12.52812 0 0 1-7.53567 2.52415H490.969a12.5902 12.5902 0 0 1 0-25.18037h15.096a12.62919 12.62919 0 0 1 12.59623 12.58405Z"
			/>
			<circle cx="532.81499" cy="18.25044" r="12.90118" fill="#2f2e41" />
			<path
				d="M532.50033 5.34927c-.15825 0-.31505.00628-.472.01193a12.89776 12.89776 0 0 1 0 25.77849c.15694.00565.31374.01193.472.01193a12.901175 12.901175 0 1 0 0-25.80235Zm-61.35925 0c.15825 0 .31505.00628.472.01193a12.89776 12.89776 0 0 0 0 25.77849c-.157.00565-.31375.01193-.472.01193a12.90118 12.90118 0 0 1 0-25.80235Zm42.45958 34.9257a12.52812 12.52812 0 0 1-7.53567 2.52415H490.969a12.52833 12.52833 0 0 1-7.53574-2.52415Z"
				opacity=".1"
			/>
			<path
				fill="#fbbebe"
				d="M611.08558 133.75592s3.25228 9.37161 6.229 6.87633l-2.37258-12.24982Z"
			/>
			<path
				fill={theme.palette.primary.main}
				d="M1006.86381 419.54964a20.81252 20.81252 0 1 0 2.7716-39.91524l.52093 10.7122-5.06814-9.18045a20.734 20.734 0 0 0-10.68367 11.72261 20.40847 20.40847 0 0 0-1.19713 5.62986 20.80856 20.80856 0 0 0 13.65641 21.03102Z"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M1031.94116 543.79306c-1.78906-9.11027 5.9633-17.1868 13.62086-22.43651s16.605-10.40779 19.21775-19.31684c3.755-12.80387-7.43-24.52981-16.13564-34.64176a125.30044 125.30044 0 0 1-16.52359-24.55738c-1.81107-3.5325-3.47558-7.22528-3.95221-11.16626-.68641-5.67546 1.13693-11.32309 2.9739-16.73673q9.17925-27.05169 19.62843-53.65005"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M1007.72093 416.7917a20.81252 20.81252 0 1 0 2.7716-39.91524l.52093 10.7122-5.06815-9.18045a20.734 20.734 0 0 0-10.68366 11.72261 20.40847 20.40847 0 0 0-1.19713 5.62986 20.80855 20.80855 0 0 0 13.65641 21.03102Z"
			/>
			<path
				fill={theme.palette.primary.main}
				d="M1029.39736 357.58816a20.78819 20.78819 0 0 1 14.97993-13.19764l1.71361 10.18378 3.177-10.69566a20.81 20.81 0 1 1-19.87057 13.70952Z"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M1030.54018 353.9109a20.7882 20.7882 0 0 1 14.97993-13.19763l1.71361 10.18378 3.177-10.69567a20.81 20.81 0 1 1-19.87057 13.70952Z"
			/>
			<path
				fill={theme.palette.primary.main}
				d="M1044.99074 467.60435a20.81 20.81 0 0 0 18.419-37.02267l-2.44121 8.21926-1.73105-10.30382a.36183.36183 0 0 0-.053-.0201 20.81113 20.81113 0 1 0-14.1938 39.12733Z"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M1045.981 463.87967A20.81 20.81 0 0 0 1064.4 426.857l-2.44121 8.21926-1.73105-10.30382a.3621.3621 0 0 0-.053-.0201 20.81113 20.81113 0 1 0-14.1938 39.12733Z"
			/>
			<path
				fill={theme.palette.primary.main}
				d="M1023.32382 502.16698a20.80131 20.80131 0 1 0 4.01058-16.29737l9.27267 13.95654-12.66994-7.40768a20.61638 20.61638 0 0 0-.61331 9.74851Z"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M1024.18094 499.40904a20.80131 20.80131 0 1 0 4.01058-16.29737l9.27267 13.95655-12.66994-7.40769a20.61626 20.61626 0 0 0-.61331 9.74851Z"
			/>
			<path
				fill={theme.palette.primary.main}
				d="M9.00746 470.24875a13.67421 13.67421 0 1 0 1.821-26.225l.34227 7.03811-3.32987-6.03172a13.62263 13.62263 0 0 0-7.01936 7.702 13.40883 13.40883 0 0 0-.78654 3.69893 13.6716 13.6716 0 0 0 8.9725 13.81768Z"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M25.48374 551.87894c-1.17545-5.98561 3.918-11.292 8.94915-14.7412s10.90978-6.8381 12.62642-12.69151c2.46711-8.41238-4.88167-16.11653-10.60142-22.76027a82.32442 82.32442 0 0 1-10.85629-16.13465 22.20962 22.20962 0 0 1-2.59668-7.33643c-.451-3.72888.747-7.43947 1.95391-10.99634q6.03093-17.77346 12.89623-35.24906"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M9.57061 468.43673a13.6742 13.6742 0 1 0 1.821-26.225l.34227 7.03812-3.32988-6.03177a13.62262 13.62262 0 0 0-7.01936 7.702A13.40912 13.40912 0 0 0 .5981 454.619a13.67158 13.67158 0 0 0 8.97251 13.81773Z"
			/>
			<path
				fill={theme.palette.primary.main}
				d="M23.81241 429.53893a13.65822 13.65822 0 0 1 9.84209-8.67109l1.12587 6.69093 2.08737-7.02725a13.67252 13.67252 0 1 1-13.05533 9.00741Z"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M24.56327 427.1229a13.65822 13.65822 0 0 1 9.84209-8.67108l1.12587 6.69093 2.08737-7.02725a13.67252 13.67252 0 1 1-13.05533 9.0074Z"
			/>
			<path
				fill={theme.palette.primary.main}
				d="M34.05755 501.82157A13.67255 13.67255 0 0 0 46.15919 477.497l-1.60392 5.4002-1.13733-6.76979a.238.238 0 0 0-.0348-.0132 13.67329 13.67329 0 1 0-9.32559 25.70736Z"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M34.70814 499.37439a13.67255 13.67255 0 0 0 12.10164-24.32457l-1.60392 5.4002-1.13733-6.7698a.238.238 0 0 0-.0348-.0132 13.67329058 13.67329058 0 1 0-9.32559 25.70737Z"
			/>
			<path
				fill={theme.palette.primary.main}
				d="M19.822 524.52985a13.66684 13.66684 0 1 0 2.635-10.70767l6.09231 9.16971-8.32438-4.867a13.54535 13.54535 0 0 0-.40293 6.40496Z"
			/>
			<path
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				d="M20.38513 522.71783a13.66684 13.66684 0 1 0 2.635-10.70767l6.09231 9.16971-8.32439-4.867a13.54535 13.54535 0 0 0-.40292 6.40496Z"
			/>
			<ellipse cx="480.946" cy="319.1155" fill="#2f2e41" rx="17" ry="22" />
			<ellipse cx="573.446" cy="319.6155" fill="#2f2e41" rx="17" ry="22" />
			<path
				fill="#2f2e41"
				d="M560.446 384.6155c0 9.94-13.88 18-31 18s-31-8.06-31-18c0-8.61 10.41-15.81 24.32-17.57a50.10353 50.10353 0 0 1 6.68-.43 50.69869 50.69869 0 0 1 11.13 1.2c11.62 2.59 19.87 9.14 19.87 16.8Z"
			/>
			<ellipse
				cx="484.946"
				cy="314.1155"
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				rx="17"
				ry="22"
			/>
			<ellipse
				cx="577.446"
				cy="314.6155"
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				rx="17"
				ry="22"
			/>
			<ellipse
				cx="533.446"
				cy="379.6155"
				fill="none"
				stroke="#3f3d56"
				stroke-miterlimit="10"
				rx="31"
				ry="18"
			/>
			<path
				fill="#fff"
				d="M540.946 369.3155a4.93658 4.93658 0 0 1-1.32 3.392 4.33873 4.33873 0 0 1-3.18 1.408h-10a4.66433 4.66433 0 0 1-4.5-4.8 4.90458 4.90458 0 0 1 .82-2.74134 47.02 47.02 0 0 1 6.68-.45866 47.66454 47.66454 0 0 1 11.13 1.28 5.06656 5.06656 0 0 1 .37 1.92Z"
			/>
			<circle cx="484.946" cy="308.1155" r="5" fill="#fff" />
			<circle cx="577.946" cy="308.1155" r="5" fill="#fff" />
			<circle
				cx="582.946"
				cy="355.1155"
				r="5"
				fill={props.color}
				opacity=".3"
			/>
			<circle
				cx="460.946"
				cy="355.1155"
				r="5"
				fill={props.color}
				opacity=".3"
			/>
		</svg>
	);
};

export default NotFoundIconComponent;
