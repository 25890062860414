import React, { useState } from 'react';
import { ApolloError } from '@apollo/client';
import {
	createStyles,
	FormControl,
	makeStyles,
	MenuItem,
	InputLabel,
	Select,
	Theme,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { ListYoungPeopleQuery } from '../../graphql/types';

export type YoungPersonPickerViewProps = {
	youngPersons: ListYoungPeopleQuery['listYoungPeople'];
	loading: boolean;
	error?: ApolloError;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			width: '100%',
		},
		select: {},
	})
);

const YoungPersonPickerView: React.FC<YoungPersonPickerViewProps> = (props) => {
	const classes = useStyles();
	const [value, setValue] = useState('');

	if (props.loading) {
		return <Skeleton variant="text" />;
	}

	const youngPersons = props.youngPersons ?? [];

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const id = event.target.value as string;
		setValue(id);
	};

	return (
		<FormControl className={classes.formControl}>
			<InputLabel>Kid</InputLabel>
			<Select
				defaultValue={value}
				labelId={value}
				id="young-person-selector"
				value={value}
				onChange={handleChange}
				className={classes.select}
			>
				{youngPersons.map((youngPerson, i) => {
					return (
						<MenuItem key={`yp-selector-${i}`} value={youngPerson?.id}>
							{youngPerson?.name}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};

export default YoungPersonPickerView;
