import React, { useContext, useState } from 'react';
import { Box, Divider, useTheme, useMediaQuery } from '@material-ui/core';
import { useParams } from 'react-router';
import AddButtonComponent from '../../components/AddButtonComponent';
import ListComponent from '../../components/ListComponent';
import SearchComponent from '../../components/SearchComponent';
import { Can } from '../../contexts/CanContext';
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import {
	FormType,
	OrderByArg,
	TaskOrderByInput,
	TaskWhereFilterInput,
} from '../../graphql/types';
import TasksView from '../../views/TasksView';
import FilterTasksLayout from '../FilterTasksLayout';

interface TasksTabLayoutProps {
	youngPersonId?: string;
	houseId?: string;
	title?: string;
}

const defaultSort: TaskOrderByInput = {
	dueDate: OrderByArg.Desc,
};

type Params = {
	houseId?: string;
	youngPersonId?: string;
};

const TasksTabLayout: React.FC<TasksTabLayoutProps> = (props) => {
	const { open } = useContext(ModalContext);
	const params = useParams<Params>();
	const theme = useTheme();
	const [sort, setSort] = useState<TaskOrderByInput>(defaultSort);

	const houseId = props.houseId ?? params.houseId ?? undefined;
	const youngPersonId =
		props.youngPersonId ?? params.youngPersonId ?? undefined;

	const [filters, setFilters] = useState<TaskWhereFilterInput>({
		youngPersonId: { equals: youngPersonId },
		houseId: { equals: houseId },
		title: { contains: '' },
		completedAt: { equals: null },
		isPrivate: undefined,
	});

	const handleOnCreateTask = () => {
		open({
			type: ModalType.FORM,
			args: {
				type: FormType.TaskCreate,
				houseId: houseId,
				youngPersonId: youngPersonId,
			},
		});
	};

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Box
			width="100%"
			display="flex"
			alignItems="stretch"
			flexDirection={isMobile && 'column'}
		>
			<Box flex="2" width="100%">
				<Box
					paddingBottom={theme.spacing(4)}
					justifyContent="space-between"
					alignItems="center"
					display="flex"
				>
					<Box>
						<SearchComponent
							onSearch={(e) =>
								setFilters({ ...filters, title: { contains: e.target.value } })
							}
						/>
					</Box>
					<Can I="create" a="Task">
						<AddButtonComponent onClick={handleOnCreateTask} />
					</Can>
				</Box>
				<ListComponent size="sm">
					<TasksView
						filter={filters}
						sort={sort}
						limit={30}
						title={props.title}
					/>
				</ListComponent>
			</Box>
			<Box paddingRight={theme.spacing('lg')} paddingLeft={theme.spacing('lg')}>
				<Divider orientation="vertical" />
			</Box>
			<Box flex="1" width="100%" paddingRight={theme.spacing('lg')}>
				<FilterTasksLayout
					onSelectSortBy={setSort}
					onSelectFilter={setFilters}
					filtersValue={filters}
					sortByValues={sort}
					houseId={props.houseId}
				/>
			</Box>
		</Box>
	);
};

export default TasksTabLayout;
