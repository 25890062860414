import * as React from 'react';
import { Box } from '@material-ui/core';

type FallbackContainerProps = {
	screenHeight?: boolean;
};

export const FallbackContainerComponent: React.FC<FallbackContainerProps> = (
	props
) => (
	<Box
		width="100%"
		height="100%"
		minHeight="inherit"
		display="flex"
		flex="1"
		padding="2rem"
		flexDirection="column"
		gridRowGap={'1rem'}
		justifyContent="center"
		alignItems="center"
		style={{ background: '#fafafa' }}
	>
		{props.children}
	</Box>
);

export default FallbackContainerComponent;
