import React from 'react';
import FallbackComponent from '../../components/FallbackComponent';
import { Line } from 'react-chartjs-2';
import dayjs, { Dayjs } from 'dayjs';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip,
	ChartOptions,
} from 'chart.js';
import {
	Card,
	Box,
	Typography,
	TableHead,
	Table,
	TableRow,
	TableCell,
	TableBody,
	createStyles,
	makeStyles,
	Theme,
	TextField,
} from '@material-ui/core';

import { ChartData, TableData, TableLogData } from './types';

export type TrmChatViewProps = {
	chartData?: ChartData[];
	tableData?: TableData[];
	tableLogData?: TableLogData[];
	error: boolean;
	loading: boolean;
	startDate: string;
	endDate: string;
	setStartDate: (date: Dayjs) => void;
	setEndDate: (date: Dayjs) => void;
	labels: ChartData['occupancy'];
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		card: {
			padding: theme.spacing(2),
		},
		cardHeader: {
			marginBottom: theme.spacing(2),
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		filters: {
			display: 'flex',
			gap: theme.spacing(2),
		},
	})
);

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Tooltip
);

const TrmChatView: React.FC<TrmChatViewProps> = (props) => {
	const classes = useStyles();

	// Chart Options
	const options: ChartOptions<'line'> = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top',
			},
			tooltip: {
				mode: 'index',
				callbacks: {
					beforeTitle: () => 'Number of occupied days between',
				},
			},
		},
		scales: {
			y: {
				stacked: true,
				title: {
					display: true,
					text: 'Number of occupied days',
				},
			},
		},
		// interaction: {
		// 	mode: 'nearest',
		// 	axis: 'x',
		// 	intersect: false,
		// },
	};

	// Chart Data
	const lineChartData = {
		labels: props.labels.map((label) => {
			if (label.startDate === label.endDate) {
				return label.startDate;
			} else {
				return `${label.startDate} - ${label.endDate}`;
			}
		}),
		datasets: props.chartData
			? props.chartData?.map((data) => {
					return {
						label: data.roomName,
						borderColor: data.color,
						backgroundColor: data.color,
						data: data.occupancy.map((occupancy) => occupancy.occupied),
						fill: true,
					};
			  })
			: [],
	};

	return (
		<Card className={classes.card}>
			<Box className={classes.cardHeader}>
				<Typography variant="h2" component="h3">
					Room Occupancy
				</Typography>
				<Box className={classes.filters}>
					<TextField
						type="date"
						variant="standard"
						InputLabelProps={{ shrink: true }}
						label="Start Date"
						value={props.startDate}
						onChange={(event) => {
							props.setStartDate(dayjs(event.target.value, 'YYYY-MM-DD'));
						}}
					/>
					<TextField
						type="date"
						variant="standard"
						InputLabelProps={{ shrink: true }}
						label="End Date"
						value={props.endDate}
						onChange={(event) => {
							props.setEndDate(dayjs(event.target.value, 'YYYY-MM-DD'));
						}}
					/>
				</Box>
			</Box>
			<FallbackComponent
				isLoading={props.loading}
				hasError={props.error}
				isEmpty={props.tableData?.length === 0}
				loadingType="circularProgress"
				errorTitle={'Error getting TRM Levels'}
				errorCopy={
					'There seems to be an issue getting the TRM Levels, please try again later.'
				}
				emptyTitle={'No Occupancy Logs'}
				emptyCopy={'Try changing the start and end date.'}
			>
				<Line options={options} data={lineChartData} />
				<Box marginTop="9px">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Room</TableCell>
								<TableCell>Number of Days Occupied</TableCell>
								<TableCell>Number of Day Unoccupied</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.tableData?.map((row) => (
								<TableRow>
									<TableCell>{row.room}</TableCell>
									<TableCell>{row.occupiedFor}</TableCell>
									<TableCell>{row.unoccupiedFor}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>

				<Box marginTop="48px">
					<Typography variant="h2" component="h3">
						Occupancy Logs
					</Typography>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Room</TableCell>
								<TableCell>Young Person</TableCell>
								<TableCell>Start Date</TableCell>
								<TableCell>End Date</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{props.tableLogData?.map((row) => (
								<TableRow>
									<TableCell>{row.room}</TableCell>
									<TableCell>{row.youngPerson}</TableCell>
									<TableCell>{row.startDate}</TableCell>
									<TableCell>{row.endDate}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Box>
			</FallbackComponent>
		</Card>
	);
};

export default TrmChatView;
