import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability';
import type { CognitoUser } from './contexts/authContext/types';

import {
	House,
	Role,
	Task,
	User as DbUser,
	YoungPerson,
	File,
	Room,
} from './graphql/types';

type Actions = 'create' | 'read' | 'update' | 'delete' | 'export';

// Models
type UserModel = Pick<DbUser, '__typename' | 'id' | 'role' | 'houseId'>;
type HouseModel = Pick<House, '__typename' | 'id' | 'users' | 'organisationId'>;
type YpModel = Pick<YoungPerson, '__typename' | 'id' | 'houseId'>;
type TaskModel = Pick<Task, '__typename' | 'id' | 'createdUserId' | 'isAdmin'>;
type FileModel = Pick<File, '__typename' | 'id' | 'key' | 'lastModified'>;
type RoomModel = Pick<Room, '__typename' | 'id' | 'houseId'>;

export type Subjects =
	| UserModel
	| 'User'
	| HouseModel
	| 'House'
	| YpModel
	| 'YoungPerson'
	| TaskModel
	| 'Task'
	| FileModel
	| 'File'
	| RoomModel
	| 'Room'
	| 'IncidentReport'
	| 'ActivityLog'
	| 'SpinSession'
	| 'CoachingSession'
	| 'Reports'
	| 'Notification'
	| 'ManagerOnDutyLog'
	| 'RiskAssessment'
	| 'None';

type User = {
	dbUser?: DbUser;
	cognitoUser?: CognitoUser;
};

export type AppAbilityType = Ability<[Actions, Subjects]>;

const AppAbility = Ability as AbilityClass<AppAbilityType>;

export default function defineAbilityFor(user?: User) {
	const { can, build, cannot } = new AbilityBuilder(AppAbility);

	/*
    Due to the house manager being able to access the moved out house for the house they have been 
    assigned to, I have added this to the cognito user ONLY as it would require the least amount of changes 
    to the code as houseId on the API User is of type ObjectID  
  */
	const houseIds =
		user?.cognitoUser?.attributes['custom:houseId']?.split('|||') ?? [];

	switch (user?.dbUser?.role) {
		case Role.Admin: {
			// House
			can('create', 'House');
			can('read', 'House');
			can('update', 'House');

			// YoungPerson
			can('create', 'YoungPerson');
			can('read', 'YoungPerson');
			can('update', 'YoungPerson');

			// Task
			can('create', 'Task');
			can('read', 'Task');
			can('update', 'Task');
			can('delete', 'Task');

			// Room
			can('create', 'Room');
			can('read', 'Room');
			can('update', 'Room');
			can('delete', 'Room');

			// File
			can('create', 'File');
			can('read', 'File');
			can('update', 'File');
			can('delete', 'File');

			// Incident Report
			can('create', 'IncidentReport');
			can('read', 'IncidentReport');
			can('update', 'IncidentReport');
			can('delete', 'IncidentReport');

			// Activity Log
			can('create', 'ActivityLog');
			can('read', 'ActivityLog');
			can('update', 'ActivityLog');
			can('delete', 'ActivityLog');

			// Spin Session
			can('create', 'SpinSession');
			can('read', 'SpinSession');
			can('update', 'SpinSession');
			can('delete', 'SpinSession');

			// Coaching Session
			can('create', 'CoachingSession');
			can('read', 'CoachingSession');
			can('update', 'CoachingSession');
			can('delete', 'CoachingSession');

			// User
			can('create', 'User');
			can('read', 'User');
			can('update', 'User');
			can('delete', 'User');

			// Report
			can('export', 'Reports');
			can('read', 'Reports');

			// Notification
			can('create', 'Notification');
			can('update', 'Notification');
			can('delete', 'Notification');

			// ManagerOnDutyLog
			can('create', 'ManagerOnDutyLog');
			can('update', 'ManagerOnDutyLog');
			can('delete', 'ManagerOnDutyLog');

			// RiskAssessment
			can('create', 'RiskAssessment');
			cannot('update', 'RiskAssessment');
			can('delete', 'RiskAssessment');

			break;
		}
		case Role.HouseManager: {
			console.log('House Manager');
			console.log('houseId', houseIds);
			// House
			houseIds?.forEach((houseId) => {
				can('read', 'House', { id: houseId });
			});
			cannot('create', 'House');
			cannot('update', 'House');

			// YoungPerson
			houseIds?.forEach((houseId) => {
				can('read', 'YoungPerson', { houseId });
				can('update', 'YoungPerson', { houseId });
			});
			cannot('create', 'YoungPerson');
			cannot('delete', 'YoungPerson');

			// Task
			houseIds?.forEach((houseId) => {
				can('create', 'Task', { houseId });
				can('read', 'Task', { houseId });
				can('update', 'Task', { houseId });
			});

			// Admin Tasks
			cannot('read', 'Task', { isAdmin: false });
			cannot('update', 'Task', { isAdmin: false });
			cannot('delete', 'Task', { isAdmin: false });

			// File
			houseIds?.forEach((houseId) => {
				can('create', 'File', { houseId });
				can('read', 'File', { houseId });
				can('update', 'File', { houseId });
				can('delete', 'File', { houseId });
			});

			// Room
			houseIds?.forEach((houseId) => {
				can('read', 'Room', { houseId });
			});

			cannot('create', 'Room');
			cannot('delete', 'Room');
			cannot('update', 'Room');

			// Incident Report
			can('create', 'IncidentReport');
			can('read', 'IncidentReport');
			can('update', 'IncidentReport');
			can('delete', 'IncidentReport');

			// Activity Log
			can('create', 'ActivityLog');
			can('read', 'ActivityLog');
			can('update', 'ActivityLog');
			can('delete', 'ActivityLog');

			// Spin Session
			can('create', 'SpinSession');
			can('read', 'SpinSession');
			can('update', 'SpinSession');
			can('delete', 'SpinSession');

			// Coaching Session
			can('create', 'CoachingSession');
			can('read', 'CoachingSession');
			can('update', 'CoachingSession');
			can('delete', 'CoachingSession');

			// User
			can('read', 'User');
			can('update', 'User', { userId: user?.dbUser?.id });
			cannot('create', 'User');
			cannot('delete', 'User');

			// Notification
			houseIds?.forEach((houseId) => {
				can('delete', 'Notification', { houseId });
			});
			can('create', 'Notification');
			can('update', 'Notification');

			// ManagerOnDutyLog
			can('create', 'ManagerOnDutyLog');
			can('update', 'ManagerOnDutyLog');
			can('delete', 'ManagerOnDutyLog');

			// RiskAssessment
			can('create', 'RiskAssessment');
			cannot('update', 'RiskAssessment');
			can('delete', 'RiskAssessment');

			break;
		}
		case Role.SeniorUser: {
			// House
			can('read', 'House', {
				id: {
					$in: houseIds,
				},
			});
			cannot('create', 'House');
			cannot('update', 'House');

			// YoungPerson
			can('read', 'YoungPerson', {
				houseId: {
					$in: houseIds,
				},
			});
			can('update', 'YoungPerson', {
				houseId: {
					$in: houseIds,
				},
			});
			cannot('create', 'YoungPerson');
			cannot('delete', 'YoungPerson');

			// Task
			can('create', 'Task', {
				houseId: {
					$in: houseIds,
				},
			});
			can('read', 'Task', {
				houseId: {
					$in: houseIds,
				},
			});
			can('update', 'Task', {
				houseId: {
					$in: houseIds,
				},
			});

			// Admin Tasks
			cannot('read', 'Task', { isAdmin: false });
			cannot('update', 'Task', { isAdmin: false });
			cannot('delete', 'Task', { isAdmin: false });

			// File
			can('create', 'File', {
				houseId: {
					$in: houseIds,
				},
			});
			can('read', 'File', {
				houseId: {
					$in: houseIds,
				},
			});
			can('update', 'File', {
				houseId: {
					$in: houseIds,
				},
			});
			can('delete', 'File', {
				houseId: {
					$in: houseIds,
				},
			});

			// Room
			can('read', 'Room', {
				houseId: {
					$in: houseIds,
				},
			});
			cannot('create', 'Room');
			cannot('delete', 'Room');
			cannot('update', 'Room');

			// Incident Report
			can('create', 'IncidentReport');
			can('read', 'IncidentReport');
			can('update', 'IncidentReport');
			can('delete', 'IncidentReport');

			// Activity Log
			can('create', 'ActivityLog');
			can('read', 'ActivityLog');
			can('update', 'ActivityLog');
			can('delete', 'ActivityLog');

			// Spin Session
			can('create', 'SpinSession');
			can('read', 'SpinSession');
			can('update', 'SpinSession');
			can('delete', 'SpinSession');

			// Coaching Session
			can('create', 'CoachingSession');
			can('read', 'CoachingSession');
			can('update', 'CoachingSession');
			can('delete', 'CoachingSession');

			// User
			can('read', 'User');
			can('update', 'User', { userId: user?.dbUser?.id });
			cannot('create', 'User');
			cannot('delete', 'User');

			// Notification
			can('create', 'Notification');
			can('update', 'Notification', {
				houseId: {
					$in: houseIds,
				},
			});
			can('delete', 'Notification', {
				houseId: {
					$in: houseIds,
				},
			});

			// ManagerOnDutyLog
			can('create', 'ManagerOnDutyLog');
			can('update', 'ManagerOnDutyLog');
			can('delete', 'ManagerOnDutyLog');

			// RiskAssessment
			can('create', 'RiskAssessment');
			cannot('update', 'RiskAssessment');
			can('delete', 'RiskAssessment');

			break;
		}
		case Role.User: {
			// House
			can('read', 'House', {
				id: {
					$in: houseIds,
				},
			});
			cannot('create', 'House');
			cannot('update', 'House');

			// YoungPerson
			can('read', 'YoungPerson', {
				houseId: {
					$in: houseIds,
				},
			});
			can('update', 'YoungPerson', {
				houseId: {
					$in: houseIds,
				},
			});
			cannot('create', 'YoungPerson');
			cannot('delete', 'YoungPerson');

			// Task
			can('create', 'Task', {
				houseId: {
					$in: houseIds,
				},
			});
			can('read', 'Task', {
				houseId: {
					$in: houseIds,
				},
			});
			can('update', 'Task', {
				houseId: {
					$in: houseIds,
				},
			});

			// Admin Tasks
			cannot('read', 'Task', { isAdmin: false });
			cannot('update', 'Task', { isAdmin: false });
			cannot('delete', 'Task', { isAdmin: false });

			// File
			can('create', 'File', {
				houseId: {
					$in: houseIds,
				},
			});
			can('read', 'File', {
				houseId: {
					$in: houseIds,
				},
			});
			can('update', 'File', {
				houseId: {
					$in: houseIds,
				},
			});

			// Room
			can('read', 'Room', {
				houseId: {
					$in: houseIds,
				},
			});
			cannot('create', 'Room');
			cannot('delete', 'Room');
			cannot('update', 'Room');

			// Incident Report
			can('create', 'IncidentReport');
			can('read', 'IncidentReport');
			can('update', 'IncidentReport');
			cannot('delete', 'IncidentReport');

			// Activity Log
			can('create', 'ActivityLog');
			can('read', 'ActivityLog');
			cannot('update', 'ActivityLog');
			cannot('delete', 'ActivityLog');

			// Spin Session
			cannot('create', 'SpinSession');
			cannot('read', 'SpinSession');
			cannot('update', 'SpinSession');
			cannot('delete', 'SpinSession');

			// Coaching Session
			can('create', 'CoachingSession');
			can('read', 'CoachingSession');
			can('update', 'CoachingSession');
			cannot('delete', 'CoachingSession');

			// User
			can('read', 'User');
			can('update', 'User', { userId: user?.dbUser?.id });
			cannot('create', 'User');
			cannot('delete', 'User');

			// Report
			cannot('export', 'Reports');
			cannot('read', 'Reports');

			// Notification
			can('create', 'Notification');
			cannot('update', 'Notification');
			cannot('delete', 'Notification', {
				houseId: {
					$in: houseIds,
				},
			});

			// ManagerOnDutyLog
			cannot('create', 'ManagerOnDutyLog');
			cannot('update', 'ManagerOnDutyLog');
			cannot('delete', 'ManagerOnDutyLog');

			// RiskAssessment
			can('create', 'RiskAssessment');
			cannot('update', 'RiskAssessment');
			cannot('delete', 'RiskAssessment');
			break;
		}
	}

	return build({
		detectSubjectType: (object) => object?.__typename ?? 'User',
	});
}
