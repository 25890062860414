import dayjs from 'dayjs';

// Query
import {
	// Get Activity
	GetActivityLogDocument,
	GetActivityLogQuery,
	GetActivityLogQueryVariables,
	// List YoungPerson
	FormListYoungPeopleDocument,
	FormListYoungPeopleQuery,
	FormListYoungPeopleQueryVariables,
	// Create Mutation
	CreateActivityLogDocument,
	CreateActivityLogMutation,
	CreateActivityLogMutationVariables,
	// Update Mutation
	UpdateActivityLogDocument,
	UpdateActivityLogMutation,
	UpdateActivityLogMutationVariables,
} from './query.generated';
import { LogFragmentFragmentDoc } from '../../../../graphql/hooks';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { LogActivityArgs } from '../../types';
import { LogType } from '../../../../graphql/types';
import { ActivityLogData } from '../../../../types';

export type LogActivityFormValues = {
	note: string;
	youngPersonId?: string;
	date: string;
	reopen?: boolean;
};

const PreFetch = async (
	args: LogActivityArgs,
	client: ApolloClient<object>
) => {
	let log: ApolloQueryResult<GetActivityLogQuery> | undefined = undefined;
	let yp: ApolloQueryResult<FormListYoungPeopleQuery> | undefined = undefined;

	if (args.id) {
		log = await client.query<GetActivityLogQuery, GetActivityLogQueryVariables>(
			{
				query: GetActivityLogDocument,
				variables: {
					id: args.id,
				},
			}
		);

		if (!log.data.getLog || log.error)
			throw new Error('Pre-fetched Data Failed');
	}

	if (!args.youngPersonId) {
		const where = args.houseId
			? {
					house: {
						id: {
							equals: args.houseId,
						},
					},
			  }
			: {};

		yp = await client.query<
			FormListYoungPeopleQuery,
			FormListYoungPeopleQueryVariables
		>({
			query: FormListYoungPeopleDocument,
			variables: {
				where,
			},
		});

		if (!yp.data.listYoungPeople || yp.error)
			throw new Error('Pre-fetched Data Failed');
	}

	return {
		activity: log?.data.getLog ?? undefined,
		youngPerson: yp?.data.listYoungPeople ?? undefined,
	};
};

type Data = {
	activity: GetActivityLogQuery['getLog'] | undefined;
	youngPerson: FormListYoungPeopleQuery['listYoungPeople'] | undefined;
};

const FormFields = (
	args: LogActivityArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const formData: ActivityLogData | undefined = data.activity?.data
		? JSON.parse(data.activity?.data)
		: undefined;

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'date',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Date:',
				defaultValue:
					dayjs(formData?.date).format('YYYY-MM-DD') ??
					dayjs(Date.now()).format('YYYY-MM-DD'),
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'note',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Note:',
				multiline: true,
				rowsMax: 5,
				defaultValue: formData?.note ?? '',
			},
			validation: {
				required: true,
			},
		},
	];

	if (!args.youngPersonId && !args.id) {
		const options = data.youngPerson?.map((yp) => {
			return { value: yp?.id ?? '', copy: yp?.name ?? '' };
		});

		fields.unshift({
			id: 'youngPersonId',
			type: FormFieldTypes.SELECT,
			label: 'Kid:',
			config: {},
			validation: {
				required: true,
			},
			options: options ?? [],
		});
	}

	return [fields];
};

const GenerateOnSubmit = async (
	args: LogActivityArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: LogActivityFormValues): Promise<any> => {
		if (args.id) {
			const updateActivity = await client.mutate<
				UpdateActivityLogMutation,
				UpdateActivityLogMutationVariables
			>({
				mutation: UpdateActivityLogDocument,
				variables: {
					id: args.id ?? '',
					data: {
						data: JSON.stringify({
							note: formValues.note,
							date: dayjs(formValues.date).toISOString(),
						}),
					},
				},
			});
			return updateActivity;
		} else {
			const createActivity = await client.mutate<
				CreateActivityLogMutation,
				CreateActivityLogMutationVariables
			>({
				mutation: CreateActivityLogDocument,
				variables: {
					data: {
						type: LogType.Activity,
						youngPersonId: args.youngPersonId ?? formValues.youngPersonId ?? '',
						createdBy: userId,
						data: JSON.stringify({
							note: formValues.note,
							date: dayjs(formValues.date).toISOString(),
						}),
					},
				},
				refetchQueries: [LogFragmentFragmentDoc, 'ListLogs'],
			});

			return createActivity;
		}
	};
	return onSubmit;
};

const LogActivityForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default LogActivityForm;
