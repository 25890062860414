import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type YoungPersonFragment = {
	__typename?: 'YoungPerson';
	id: string;
	status?: Types.YoungPersonStatus | null | undefined;
	house?: { __typename?: 'House'; id: string } | null | undefined;
};

export type AssignHouseMutationVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
	houseId?: Types.Maybe<Types.Scalars['String']>;
	roomId?: Types.Maybe<Types.Scalars['String']>;
	status: Types.YoungPersonStatus;
	moveIn?: Types.Maybe<Types.Scalars['AWSDateTime']>;
}>;

export type AssignHouseMutation = {
	__typename?: 'Mutation';
	updateRoom?: { __typename?: 'Room'; id: string } | null | undefined;
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				status?: Types.YoungPersonStatus | null | undefined;
				house?: { __typename?: 'House'; id: string } | null | undefined;
		  }
		| null
		| undefined;
};

export type ListHousesSelectQueryVariables = Types.Exact<{
	[key: string]: never;
}>;

export type ListHousesSelectQuery = {
	__typename?: 'Query';
	listHouses?:
		| Array<
				{ __typename?: 'House'; id: string; title: string } | null | undefined
		  >
		| null
		| undefined;
};

export type ListRoomsSelectQueryVariables = Types.Exact<{
	[key: string]: never;
}>;

export type ListRoomsSelectQuery = {
	__typename?: 'Query';
	listRooms?:
		| Array<
				| {
						__typename?: 'Room';
						id: string;
						name: string;
						houseId: string;
						youngPersonId?: string | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const YoungPersonFragmentDoc = gql`
	fragment YoungPerson on YoungPerson {
		id
		status
		house {
			id
		}
	}
`;
export const AssignHouseDocument = gql`
	mutation AssignHouse(
		$youngPersonId: String!
		$houseId: String
		$roomId: String
		$status: YoungPersonStatus!
		$moveIn: AWSDateTime
	) {
		updateRoom(
			where: { id: $roomId }
			data: { youngPersonId: $youngPersonId }
		) {
			id
		}
		updateYoungPerson(
			where: { id: $youngPersonId }
			data: { houseId: $houseId, status: $status, moveInDate: $moveIn }
		) {
			...YoungPerson
		}
	}
	${YoungPersonFragmentDoc}
`;
export type AssignHouseMutationFn = Apollo.MutationFunction<
	AssignHouseMutation,
	AssignHouseMutationVariables
>;

/**
 * __useAssignHouseMutation__
 *
 * To run a mutation, you first call `useAssignHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignHouseMutation, { data, loading, error }] = useAssignHouseMutation({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      houseId: // value for 'houseId'
 *      roomId: // value for 'roomId'
 *      status: // value for 'status'
 *      moveIn: // value for 'moveIn'
 *   },
 * });
 */
export function useAssignHouseMutation(
	baseOptions?: Apollo.MutationHookOptions<
		AssignHouseMutation,
		AssignHouseMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<AssignHouseMutation, AssignHouseMutationVariables>(
		AssignHouseDocument,
		options
	);
}
export type AssignHouseMutationHookResult = ReturnType<
	typeof useAssignHouseMutation
>;
export type AssignHouseMutationResult =
	Apollo.MutationResult<AssignHouseMutation>;
export type AssignHouseMutationOptions = Apollo.BaseMutationOptions<
	AssignHouseMutation,
	AssignHouseMutationVariables
>;
export const ListHousesSelectDocument = gql`
	query ListHousesSelect {
		listHouses(
			where: {
				AND: [
					{ preMoveInHouse: { equals: false } }
					{ isMoveOutHouse: { not: { equals: true } } }
				]
			}
		) {
			id
			title
		}
	}
`;

/**
 * __useListHousesSelectQuery__
 *
 * To run a query within a React component, call `useListHousesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHousesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHousesSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useListHousesSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListHousesSelectQuery,
		ListHousesSelectQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ListHousesSelectQuery, ListHousesSelectQueryVariables>(
		ListHousesSelectDocument,
		options
	);
}
export function useListHousesSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListHousesSelectQuery,
		ListHousesSelectQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ListHousesSelectQuery,
		ListHousesSelectQueryVariables
	>(ListHousesSelectDocument, options);
}
export type ListHousesSelectQueryHookResult = ReturnType<
	typeof useListHousesSelectQuery
>;
export type ListHousesSelectLazyQueryHookResult = ReturnType<
	typeof useListHousesSelectLazyQuery
>;
export type ListHousesSelectQueryResult = Apollo.QueryResult<
	ListHousesSelectQuery,
	ListHousesSelectQueryVariables
>;
export const ListRoomsSelectDocument = gql`
	query ListRoomsSelect {
		listRooms(where: { youngPersonId: { equals: null } }) {
			id
			name
			houseId
			youngPersonId
		}
	}
`;

/**
 * __useListRoomsSelectQuery__
 *
 * To run a query within a React component, call `useListRoomsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRoomsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRoomsSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useListRoomsSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListRoomsSelectQuery,
		ListRoomsSelectQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ListRoomsSelectQuery, ListRoomsSelectQueryVariables>(
		ListRoomsSelectDocument,
		options
	);
}
export function useListRoomsSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListRoomsSelectQuery,
		ListRoomsSelectQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ListRoomsSelectQuery,
		ListRoomsSelectQueryVariables
	>(ListRoomsSelectDocument, options);
}
export type ListRoomsSelectQueryHookResult = ReturnType<
	typeof useListRoomsSelectQuery
>;
export type ListRoomsSelectLazyQueryHookResult = ReturnType<
	typeof useListRoomsSelectLazyQuery
>;
export type ListRoomsSelectQueryResult = Apollo.QueryResult<
	ListRoomsSelectQuery,
	ListRoomsSelectQueryVariables
>;
