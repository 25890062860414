import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			marginBottom: theme.spacing('sm'),
		},
	})
);

const ListTitleComponent: React.FC = (props) => {
	const styles = useStyles();

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="space-between"
			className={styles.container}
		>
			{props.children}
		</Grid>
	);
};

export default ListTitleComponent;
