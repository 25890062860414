import React from 'react';
import { createStyles, makeStyles, Theme, Box } from '@material-ui/core';

// Components
import FallbackComponent, { ErrorType } from '../FallbackComponent';
import ModalTitleComponent, {
	ModalTitleComponentProps,
} from '../ModalTitleComponent';
import ModalButtonsComponent, {
	ModalButtonsComponentProps,
} from '../ModalButtonsComponent';
// Contexts
import ModalContext from '../../contexts/ModalContext';

export type ModalContentComponentProps = {
	modalTitle: ModalTitleComponentProps;
	modalButtons?: ModalButtonsComponentProps;
	isLoading?: boolean;
	isEmpty?: boolean;
	emptyOverride?: { title?: string; copy?: string; type?: ErrorType };
	error?: { title?: string; copy?: string; type?: ErrorType };
	warnOnEscape?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		contentWrapper: {
			height: '105%',
			overflowY: 'scroll',
			padding: `0 ${theme.spacing('lg')}`,
		},
		wrapper: {
			height: '100vh',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
		},
	})
);

const ModalContentComponent: React.FC<ModalContentComponentProps> = (props) => {
	const classes = useStyles();
	const { setShouldWarnOnEscape, shouldWarnOnEscape } =
		React.useContext(ModalContext);

	React.useEffect(() => {
		if (props.warnOnEscape && !shouldWarnOnEscape) {
			setShouldWarnOnEscape(true);
		}
		return () => {
			if (shouldWarnOnEscape) {
				setShouldWarnOnEscape(false);
			}
		};
	}, [shouldWarnOnEscape, props.warnOnEscape, setShouldWarnOnEscape]);

	// If there is an error or is empty then disable the primary button as there can not be a primary action
	const buttonProps =
		props.isEmpty || props.error
			? {
					primaryAction: undefined,
			  }
			: props.modalButtons;

	return (
		<Box className={classes.wrapper}>
			<ModalTitleComponent {...props.modalTitle} />
			<Box className={classes.contentWrapper} data-component="content-wrapper">
				<FallbackComponent
					isEmpty={props.isEmpty}
					emptyTitle={props?.emptyOverride?.title ?? 'No Content'}
					emptyCopy={
						props?.emptyOverride?.copy ??
						'We had a little trouble fetching the content. If the problem persists, please contact your system administrator.'
					}
					isLoading={Boolean(props.isLoading)}
					loadingType="circularProgress"
					hasError={Boolean(props.error)}
					errorTitle={props?.error?.title}
					errorCopy={props?.error?.copy}
				>
					{props.children}
				</FallbackComponent>
			</Box>
			<ModalButtonsComponent {...buttonProps} />
		</Box>
	);
};

export default ModalContentComponent;
