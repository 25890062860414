import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Select,
	Grid,
	createStyles,
	makeStyles,
	MenuItem,
	Theme,
} from '@material-ui/core';

// Views
import ReportKidSnapshotView from '../../views/ReportKidSnapshotView';
import TrmChatView from '../../views/TrmChartView';
import ExportDataModalView from '../../views/ExportDataModalView';

// Query
import { useFormListYoungPeopleQuery } from '../../graphql/hooks';
import FallbackComponent from '../../components/FallbackComponent';

// Contexts
import ModalContext, { ModalType } from '../../contexts/ModalContext';

type KidsLayoutProps = {};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(3),
		},
		header: {
			display: 'flex',
			gap: theme.spacing(2),
			justifyContent: 'flex-end',
		},
		select: {
			minWidth: '200px',
		},
	})
);

const KidsLayout: React.FC<KidsLayoutProps> = (props) => {
	const classes = useStyles();
	const [youngPersonId, setYoungPersonId] = useState<string>('');
	const { open } = useContext(ModalContext);

	const { data, error, loading } = useFormListYoungPeopleQuery();

	useEffect(() => {
		if (error || loading) return;
		setYoungPersonId(data?.listYoungPeople?.[0]?.id ?? '');
	}, [data, error, loading]);

	const onExportDataClick = () => {
		open({
			type: ModalType.JSX,
			content: <ExportDataModalView youngPersonId={youngPersonId} />,
		});
	};

	return (
		<FallbackComponent
			isLoading={loading}
			loadingType="circularProgress"
			hasError={Boolean(error)}
			errorTitle="Error Loading Report"
			errorCopy="Unable to load the list of young people, please try again later"
		>
			<Box className={classes.wrapper}>
				<Box className={classes.header}>
					<Button
						variant="contained"
						color="primary"
						onClick={onExportDataClick}
					>
						Export Data
					</Button>
					<Select
						value={youngPersonId}
						label="Select a Kid"
						className={classes.select}
						onChange={(event) => {
							// @ts-ignore
							setYoungPersonId(event?.target?.value ?? '');
						}}
					>
						{data?.listYoungPeople?.map((yp) => (
							<MenuItem value={yp?.id}>{yp?.name}</MenuItem>
						))}
					</Select>
				</Box>
				<Grid container spacing={3}>
					<Grid item md={7}>
						<TrmChatView id={youngPersonId} />
					</Grid>
					<Grid item md={5}>
						<ReportKidSnapshotView id={youngPersonId} />
					</Grid>
				</Grid>
			</Box>
		</FallbackComponent>
	);
};

export default KidsLayout;
