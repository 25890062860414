import React, { useState, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { Control, useFieldArray, UseFormWatch } from 'react-hook-form';

// Components
import FormFieldsComponent, {
	FormFieldTypes,
} from '../../components/FormFieldsComponent';
import AddAnotherButton from './AddAnotherButton';

// Configs
import { coachingSessionCategories } from './config';

// Types
import { FormListYoungPeopleQuery } from '../../graphql/types';
import { FormField } from '../../components/FormFieldsComponent/FormFieldsComponent';

type StepOneFormProps = {
	youngPeopleData: FormListYoungPeopleQuery['listYoungPeople'];
	control: Control;
	watch: UseFormWatch<object>;
	reset: (values: object) => void;
};

const StepOneForm: React.FC<StepOneFormProps> = (props) => {
	// Create YoungPerson Input Field
	const youngPeopleOptions = props.youngPeopleData?.map((youngPerson) => {
		return {
			value: youngPerson?.id ?? '',
			copy: youngPerson?.name ?? '',
		};
	});

	const youngPersonSelect: FormField = {
		id: 'youngPerson1',
		type: FormFieldTypes.SELECT,
		options: youngPeopleOptions ?? [],
		label: 'Select Kid',
		config: {},
	};

	// // This is a count so that when the user tries to add another YP even though that haven't filled the previous one in it will block them
	const [totalYpFields, setTotalYpFields] = useState<number>(1);
	const [totalFilledYpFields, setTotalFilledYpFields] = useState<number>(0);

	const [ypFields, setYpFields] = useState<FormField[]>([youngPersonSelect]);
	const [value, setValue] = useState<object>({});
	const [formFields, setFormFields] = useState<FormField[]>([
		{
			id: 'date',
			type: FormFieldTypes.DATE_PICKER,
			validation: {
				required: true,
			},
			config: {
				label: 'Date:',
				defaultValue: dayjs(Date.now()).format('YYYY-MM-DD'),
			},
		},
		{
			id: 'category',
			type: FormFieldTypes.SELECT,
			validation: {
				required: true,
			},
			config: {},
			options: coachingSessionCategories.map((category) => {
				return {
					value: category.label,
					copy: category.label,
				};
			}),
			label: 'Category:',
		},
	]);

	// Use Effect to watch for changes that should affect the form
	useEffect(() => {
		const subscription = props.watch((value, { name }) => {
			setValue(value);
			if (name === 'category') {
				// @ts-expect-error
				if (value.category === 'Other') addCustomCategory();
				// @ts-expect-error
				else addSubCategory(value.category);
			}
			// @ts-expect-error
			if (typeof name === 'string' && name?.includes('youngPerson')) {
				setTotalFilledYpFields(totalFilledYpFields + 1);
			}
		});
		return () => subscription.unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.watch, formFields, ypFields]);

	const addAnotherYp = () => {
		const tempFields = [...ypFields];
		const newTotal = totalYpFields + 1;

		const tempYoungPersonSelect = { ...youngPersonSelect };
		tempYoungPersonSelect.id = `youngPerson${newTotal}`;

		tempFields.push(tempYoungPersonSelect);
		setYpFields(tempFields);
		setTotalYpFields(newTotal);
		props.reset(value);
	};

	/**
	 * When the category is set or changes update the possible options for the subcategory
	 * @param categoryName
	 * @returns
	 */
	const addSubCategory = (categoryName: string) => {
		const tempFields = [...formFields];

		const categoryIndex = coachingSessionCategories.findIndex(
			(category) => category.label === categoryName
		);

		if (categoryIndex === -1) return;

		const subCategoryOptions = coachingSessionCategories?.[
			categoryIndex
		].subCategories.map((subCategory: string) => {
			return {
				value: subCategory,
				copy: subCategory,
			};
		});

		const existingSubCategory = tempFields.findIndex(
			(field) => field.id === 'subCategory'
		);
		const existingCustomSubCategory = tempFields.findIndex(
			(field) => field.id === 'customSubCategory'
		);

		if (existingSubCategory >= 0) {
			// @ts-ignore
			tempFields[existingSubCategory].options = subCategoryOptions;
		} else {
			tempFields.push({
				id: 'subCategory',
				type: FormFieldTypes.SELECT,
				validation: {
					required: true,
				},
				config: {},
				options: subCategoryOptions,
				label: 'Sub-Category:',
			});
		}

		if (existingCustomSubCategory >= 0) {
			tempFields.splice(existingCustomSubCategory, 1);
		}

		setFormFields(tempFields);
	};

	/**
	 * When the category is set or changes update the possible options for the subcategory
	 * @param categoryName
	 * @returns
	 */
	const addCustomCategory = () => {
		const tempFields = [...formFields];

		const existingField = tempFields.findIndex(
			(field) => field.id === 'customSubCategory'
		);

		const existingSubCategory = tempFields.findIndex(
			(field) => field.id === 'subCategory'
		);

		if (existingField < 0) {
			tempFields.push({
				id: 'customSubCategory',
				type: FormFieldTypes.INPUT,
				validation: {
					required: true,
				},
				config: {
					label: 'Sub-Category:',
				},
			});
		}

		if (existingSubCategory >= 0) {
			tempFields.splice(existingSubCategory, 1);
		}

		setFormFields(tempFields);
	};

	return (
		<div>
			<FormFieldsComponent control={props.control} fields={ypFields} />
			<AddAnotherButton
				onClick={addAnotherYp}
				disabled={totalYpFields !== totalFilledYpFields}
			/>
			<FormFieldsComponent control={props.control} fields={formFields} />
		</div>
	);
};

export default StepOneForm;
