import React from 'react';
import FallbackContainer from './common/FallbackContainer';
import FallbackCopy from './common/FallbackCopy';
import FallbackIconContainer from './common/FallbackIconContainer';
import { useTheme } from '@material-ui/core';

import {
	WarningIconComponent,
	NotFoundIconComponent,
	NoDataIconComponent,
	UnderConstructionIconComponent,
} from '../icons';

export type ErrorComponentProps = {
	title?: string;
	copy?: string;
	type?: ErrorType;
	hideIcon?: boolean;
};

export enum ErrorType {
	'WARNING' = 'warning',
	'NOT_FOUND' = 'notFound',
	'UNDER_CONSTRUCTION' = 'underConstruction',
	'NO_DATA' = 'noData',
}

export const ErrorComponent: React.FC<ErrorComponentProps> = ({
	title = 'Warning',
	copy = 'There was an issue, please try again later.',
	...props
}) => {
	const theme = useTheme();

	let Icon;

	switch (props.type) {
		case ErrorType.NOT_FOUND:
			Icon = NotFoundIconComponent;
			break;
		case ErrorType.NO_DATA:
			Icon = NoDataIconComponent;
			break;
		case ErrorType.UNDER_CONSTRUCTION:
			Icon = UnderConstructionIconComponent;
			break;
		default:
			Icon = WarningIconComponent;
	}

	return (
		<FallbackContainer>
			<FallbackIconContainer>
				{Icon && !props.hideIcon && <Icon color={theme.palette.primary.main} />}
			</FallbackIconContainer>
			<FallbackCopy title={title} copy={copy} />
		</FallbackContainer>
	);
};

export default ErrorComponent;
