import dayjs from 'dayjs';

// Query
import {
	// Get Activity
	GetActivityLogDocument,
	GetActivityLogQuery,
	GetActivityLogQueryVariables,
	// List YoungPerson
	FormListYoungPeopleDocument,
	FormListYoungPeopleQuery,
	FormListYoungPeopleQueryVariables,
	// Create Mutation
	CreateActivityLogDocument,
	CreateActivityLogMutation,
	CreateActivityLogMutationVariables,
	// Update Mutation
	UpdateActivityLogDocument,
	UpdateActivityLogMutation,
	UpdateActivityLogMutationVariables,
} from '../logActivity/query.generated';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { LogContactArgs } from '../../types';
import { LogType } from '../../../../graphql/types';
import { ContactLogData } from '../../../../types';

export type LogContactFormValues = {
	note: string;
	youngPersonId?: string;
	date: string;
	type: string;
	to?: string;
	from?: string;
};

const PreFetch = async (args: LogContactArgs, client: ApolloClient<object>) => {
	let log: ApolloQueryResult<GetActivityLogQuery> | undefined = undefined;
	let yp: ApolloQueryResult<FormListYoungPeopleQuery> | undefined = undefined;

	if (args.id) {
		log = await client.query<GetActivityLogQuery, GetActivityLogQueryVariables>(
			{
				query: GetActivityLogDocument,
				variables: {
					id: args.id,
				},
			}
		);

		if (!log.data.getLog || log.error)
			throw new Error('Pre-fetched Data Failed');
	}

	if (!args.youngPersonId) {
		const where = args.houseId
			? {
					house: {
						id: {
							equals: args.houseId,
						},
					},
			  }
			: {};

		yp = await client.query<
			FormListYoungPeopleQuery,
			FormListYoungPeopleQueryVariables
		>({
			query: FormListYoungPeopleDocument,
			variables: {
				where,
			},
		});

		if (!yp.data.listYoungPeople || yp.error)
			throw new Error('Pre-fetched Data Failed');
	}

	return {
		incident: log?.data.getLog ?? undefined,
		youngPerson: yp?.data.listYoungPeople ?? undefined,
	};
};

type Data = {
	incident: GetActivityLogQuery['getLog'] | undefined;
	youngPerson: FormListYoungPeopleQuery['listYoungPeople'] | undefined;
};

const FormFields = (
	args: LogContactArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const formData: ContactLogData | undefined = data.incident?.data
		? JSON.parse(data.incident?.data)
		: undefined;

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'date',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Date:',
				defaultValue:
					dayjs(formData?.date).format('YYYY-MM-DD') ??
					dayjs(Date.now()).format('YYYY-MM-DD'),
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'type',
			type: FormFieldTypes.SELECT,
			label: 'Type',
			config: {
				defaultValue: formData?.type ?? [],
			},
			validation: {
				required: true,
			},
			options: [
				{
					value: 'Email',
					copy: 'Email',
				},
				{
					value: 'Phone',
					copy: 'Phone',
				},
				{
					value: 'In Person Visit',
					copy: 'In Person Visit',
				},
				{
					value: 'Letter',
					copy: 'Letter',
				},
				{
					value: 'Text Message',
					copy: 'Text Message',
				},
				{
					value: 'Other',
					copy: 'Other (Please state in Note)',
				},
			],
		},
		{
			id: 'to',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'To:',
				defaultValue: formData?.to ?? '',
			},
			validation: {},
		},
		{
			id: 'from',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'From:',
				defaultValue: formData?.from ?? '',
			},
			validation: {},
		},
		{
			id: 'note',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Note:',
				multiline: true,
				rows: 4,
				defaultValue: formData?.note ?? '',
			},
			validation: {
				required: true,
			},
		},
	];

	if (!args.youngPersonId && !args.id) {
		const options = data.youngPerson?.map((yp) => {
			return { value: yp?.id ?? '', copy: yp?.name ?? '' };
		});

		fields.unshift({
			id: 'youngPersonId',
			type: FormFieldTypes.SELECT,
			label: 'Kid:',
			config: {},
			validation: {
				required: true,
			},
			options: options ?? [],
		});
	}

	return [fields];
};

const GenerateOnSubmit = async (
	args: LogContactArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: LogContactFormValues): Promise<any> => {
		if (args.id) {
			const updateContactLog = await client.mutate<
				UpdateActivityLogMutation,
				UpdateActivityLogMutationVariables
			>({
				mutation: UpdateActivityLogDocument,
				variables: {
					id: args.id ?? '',
					data: {
						data: JSON.stringify({
							note: formValues.note,
							date: dayjs(formValues.date).toISOString(),
							to: formValues.to,
							from: formValues.from,
							type: formValues.type,
						}),
					},
				},
			});
			return updateContactLog;
		} else {
			const createContactLog = await client.mutate<
				CreateActivityLogMutation,
				CreateActivityLogMutationVariables
			>({
				mutation: CreateActivityLogDocument,
				variables: {
					data: {
						type: LogType.Contact,
						youngPersonId: args.youngPersonId ?? formValues.youngPersonId ?? '',
						createdBy: userId,
						data: JSON.stringify({
							note: formValues.note,
							date: dayjs(formValues.date).toISOString(),
							to: formValues.to,
							from: formValues.from,
							type: formValues.type,
						}),
					},
				},
			});

			return createContactLog;
		}
	};
	return onSubmit;
};

const LogContactForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default LogContactForm;
