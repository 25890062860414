import { useListUsersForSelectLazyQuery } from '../../graphql/hooks';
import { UserWhereFilterInput } from '../../graphql/types';
import UserSelectView from './UserSelectView';

type UserSelectViewContainerProps = {
	onSelect?: (userId: string) => void;
	houseId?: string;
};

const UserSelectViewContainer: React.FC<UserSelectViewContainerProps> = (
	props
) => {
	const whereFilter: UserWhereFilterInput = props.houseId
		? {
				houseId: { equals: props.houseId },
		  }
		: {};

	const [fetchUsers, { data, loading, error }] = useListUsersForSelectLazyQuery(
		{
			variables: {
				where: whereFilter,
			},
		}
	);

	const onOpen = async () => {
		await fetchUsers();
	};

	const onSelect = (userId: string) => {
		props?.onSelect?.(userId);
	};

	return (
		<UserSelectView
			onSelect={onSelect}
			onOpen={onOpen}
			data={data}
			loading={loading}
			error={error}
		/>
	);
};

export default UserSelectViewContainer;
