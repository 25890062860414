import {
	DataGrid,
	GridCellEditCommitParams,
	GridColDef,
} from '@material-ui/data-grid';
import * as React from 'react';

import { useListLocationStatusesQuery } from '../../graphql/hooks';
import { useUpdateLocationStatusMutation } from './mutations.generated';

const columns: GridColDef[] = [
	// { field: 'id', headerName: 'ID', width: 90 },
	{
		field: 'title',
		headerName: 'Title',
		width: 150,
		editable: true,
	},
	{
		field: 'type',
		headerName: 'Type',
		width: 150,
		editable: false,
	},
	{
		field: 'priority',
		headerName: 'Priority',
		type: 'number',
		width: 100,
		editable: true,
	},
];

type YoungPersonLocationStatusTableProps = {};

const YoungPersonLocationStatusTable = (
	props: YoungPersonLocationStatusTableProps
) => {
	const { data, loading, error } = useListLocationStatusesQuery();

	const [updateStatus] = useUpdateLocationStatusMutation();

	const handleChange = async (params: GridCellEditCommitParams) => {
		const id = params.id;
		const field = params.field as 'title' | 'priority';
		const value = params.value;

		try {
			await updateStatus({
				variables: {
					id: id.toLocaleString(),
					data: {
						[field]: value,
					},
				},
			});
		} catch (error) {
			console.log(`error`, error);
		}
	};

	return (
		<div style={{ height: 400, width: '100%' }}>
			<DataGrid
				error={error}
				loading={loading}
				// @ts-expect-error
				rows={data?.listYoungPersonLocationStatuses ?? []}
				columns={columns}
				pageSize={5}
				disableSelectionOnClick
				onCellEditCommit={handleChange}
			/>
		</div>
	);
};

export default YoungPersonLocationStatusTable;
