import React from 'react';
import {
	Box,
	Tab,
	Tabs,
	Typography,
	createStyles,
	makeStyles,
	Theme,
} from '@material-ui/core';

// Layouts
import KidsLayout from './KidsLayout';
import HouseLayout from './HouseLayout';

// Components
import FallbackComponent, {
	ErrorType,
} from '../../components/FallbackComponent';

// Context
import { Can } from '../../contexts/CanContext';

type ReportScreenProps = {
	tab: number;
	onChangeTab: (event: React.ChangeEvent<{}>, newValue: number) => void;
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(3),
		},
	})
);

const ReportingScreen: React.FC<ReportScreenProps> = (props) => {
	const classes = useStyles();

	return (
		<Can I={'read'} a="Reports">
			<Box className={classes.wrapper}>
				<Typography variant="h1">Reporting</Typography>
				<Tabs
					value={props.tab}
					indicatorColor="primary"
					textColor="primary"
					onChange={props.onChangeTab}
					aria-label="Choose between displaying the kids or houses routes."
					// className={classes.tabs}
				>
					<Tab label="Kids" />
					<Tab label="Houses" />
				</Tabs>
				<Box>{props.tab === 0 ? <KidsLayout /> : <HouseLayout />}</Box>
			</Box>
		</Can>
	);
};

export default ReportingScreen;
