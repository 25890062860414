import dayjs from 'dayjs';

// Query
import {
	// Get Activity
	GetActivityLogDocument,
	GetActivityLogQuery,
	GetActivityLogQueryVariables,
	// List YoungPerson
	FormListYoungPeopleDocument,
	FormListYoungPeopleQuery,
	FormListYoungPeopleQueryVariables,
	// Create Mutation
	CreateActivityLogDocument,
	CreateActivityLogMutation,
	CreateActivityLogMutationVariables,
	// Update Mutation
	UpdateActivityLogDocument,
	UpdateActivityLogMutation,
	UpdateActivityLogMutationVariables,
} from '../logActivity/query.generated';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { LogKidPaymentArgs } from '../../types';
import { LogType } from '../../../../graphql/types';
import { KidPaymentLogData } from '../../../../types';

export type LogKidPaymentFormValues = {
	youngPersonId?: string;
	date: string;
	amountPayed: string;
};

const PreFetch = async (
	args: LogKidPaymentArgs,
	client: ApolloClient<object>
) => {
	let log: ApolloQueryResult<GetActivityLogQuery> | undefined = undefined;
	let yp: ApolloQueryResult<FormListYoungPeopleQuery> | undefined = undefined;

	if (args.id) {
		log = await client.query<GetActivityLogQuery, GetActivityLogQueryVariables>(
			{
				query: GetActivityLogDocument,
				variables: {
					id: args.id,
				},
			}
		);

		if (!log.data.getLog || log.error)
			throw new Error('Pre-fetched Data Failed');
	}

	if (!args.youngPersonId) {
		const where = args.houseId
			? {
					house: {
						id: {
							equals: args.houseId,
						},
					},
			  }
			: {};

		yp = await client.query<
			FormListYoungPeopleQuery,
			FormListYoungPeopleQueryVariables
		>({
			query: FormListYoungPeopleDocument,
			variables: {
				where,
			},
		});

		if (!yp.data.listYoungPeople || yp.error)
			throw new Error('Pre-fetched Data Failed');
	}

	return {
		payment: log?.data.getLog ?? undefined,
		youngPerson: yp?.data.listYoungPeople ?? undefined,
	};
};

type Data = {
	payment: GetActivityLogQuery['getLog'] | undefined;
	youngPerson: FormListYoungPeopleQuery['listYoungPeople'] | undefined;
};

const FormFields = (
	args: LogKidPaymentArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const formData: KidPaymentLogData | undefined = data.payment?.data
		? JSON.parse(data.payment?.data)
		: undefined;

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'date',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Date:',
				defaultValue:
					dayjs(formData?.date).format('YYYY-MM-DD') ??
					dayjs(Date.now()).format('YYYY-MM-DD'),
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'amountPayed',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Amount Paid:',
				defaultValue: formData?.amountPayed ?? '',
			},
			validation: {
				required: true,
			},
		},
	];

	if (!args.youngPersonId && !args.id) {
		const options = data.youngPerson?.map((yp) => {
			return { value: yp?.id ?? '', copy: yp?.name ?? '' };
		});

		fields.unshift({
			id: 'youngPersonId',
			type: FormFieldTypes.SELECT,
			label: 'Kid:',
			config: {},
			validation: {
				required: true,
			},
			options: options ?? [],
		});
	}

	return [fields];
};

const GenerateOnSubmit = async (
	args: LogKidPaymentArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (
		formValues: LogKidPaymentFormValues
	): Promise<any> => {
		const logData = JSON.stringify({
			amountPayed: formValues.amountPayed,
			date: dayjs(formValues.date).toISOString(),
		});

		if (args.id) {
			const updateKidPayment = await client.mutate<
				UpdateActivityLogMutation,
				UpdateActivityLogMutationVariables
			>({
				mutation: UpdateActivityLogDocument,
				variables: {
					id: args.id ?? '',
					data: {
						data: logData,
					},
				},
			});
			return updateKidPayment;
		} else {
			const createKidPayment = await client.mutate<
				CreateActivityLogMutation,
				CreateActivityLogMutationVariables
			>({
				mutation: CreateActivityLogDocument,
				variables: {
					data: {
						type: LogType.KidPayment,
						youngPersonId: args.youngPersonId ?? formValues.youngPersonId ?? '',
						createdBy: userId,
						data: logData,
					},
				},
			});

			return createKidPayment;
		}
	};
	return onSubmit;
};

const LogKidPaymentForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default LogKidPaymentForm;
