import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import FallbackComponent from '../../components/FallbackComponent';
import ListComponent from '../../components/ListComponent';
import ListTitleContainerComponent from '../../components/ListTitleContainerComponent';
import ListTitleComponent from '../../components/ListTitleComponent';
import ListFooterComponent from '../../components/ListFooterComponent';
import PaginationControlsComponent from '../../components/PaginationControlsComponent';
import PaginationCountFeedbackComponent from '../../components/PaginationCountFeedbackComponent';
import TaskCardComponent from '../../components/TaskCardComponent';
import { ListTasksQueryHookResult } from './query.generated';

export type TasksViewProps = {
	data: ListTasksQueryHookResult['data'];
	loading: ListTasksQueryHookResult['loading'];
	error: ListTasksQueryHookResult['error'];
	onOpenTask: (id: string) => void;
	countTasks: number;
	handlePaginate: (i: number) => void;
	pageCount: number;
	currentPage: number;
	isFirstPage: boolean;
	isLastPage: boolean;
	title?: string;
};

type TasksViewState = 'error' | 'loading' | 'empty' | 'data';

type TasksViewComponentProps = TasksViewProps & { state: TasksViewState };

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		emptyContainer: {
			backgroundColor: theme.palette.grey[100],
			height: 200,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			borderRadius: theme.shape.borderRadius,
		},
		container: {
			width: '100%',
		},
	})
);

const TasksView: React.FC<TasksViewProps> = (props) => {
	const tasks = props.data?.listTasks ?? [];

	const state: TasksViewState = (() => {
		if (props.error) return 'error';
		if (props.loading) return 'loading';
		if (tasks.length === 0) return 'empty';
		return 'data';
	})();

	return (
		<Container>
			<ListTitleContainerComponent>
				<ListTitleComponent label={props.title ?? 'All Tasks'} />
			</ListTitleContainerComponent>
			<FallbackComponent
				hasError={Boolean(props.error)}
				errorTitle="An Error Occurred"
				errorCopy="We had a little trouble fetching the tasks. If the problem persists, please contact your system administrator."
				isLoading={Boolean(props.loading)}
				loadingType="skeletonListMd"
				isEmpty={tasks.length === 0}
				emptyTitle="No Tasks"
				emptyCopy="If there are outstanding tasks try creating one. Otherwise, kick back and relax."
			>
				<ListComponent size="sm">
					{(props.data?.listTasks ?? []).map((item) => (
						<TaskCardComponent
							{...item}
							onClick={() => props.onOpenTask(item?.id ?? '')}
						/>
					))}
				</ListComponent>
			</FallbackComponent>
			<Footer {...props} state={state} />
		</Container>
	);
};

/* ----- Tasks View Components ------ */

const Container: React.FC = (props) => {
	const styles = useStyles();
	return <Box className={styles.container}>{props.children}</Box>;
};

const Footer: React.FC<TasksViewComponentProps> = (props) => {
	const disabledControls = (() => {
		if (props.error || props.loading) return 'both';
		if (props.isFirstPage && props.isLastPage) return 'both';
		if (props.isFirstPage) return 'prev';
		if (props.isLastPage) return 'next';
		// default
		return;
	})();

	return (
		<ListFooterComponent>
			<PaginationCountFeedbackComponent
				listLength={props.countTasks}
				current={props.data?.listTasks?.length ?? 0}
				empty={props.state === 'empty'}
				loading={props.state === 'loading'}
				error={props.state === 'error'}
				suffix="Tasks"
			/>
			<PaginationControlsComponent
				handleClickPrev={() => props.handlePaginate(props.currentPage - 1)}
				handleClickNext={() => props.handlePaginate(props.currentPage + 1)}
				disabled={disabledControls}
			/>
		</ListFooterComponent>
	);
};

export default TasksView;
