import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetMeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetMeQuery = {
	__typename?: 'Query';
	me?:
		| {
				__typename?: 'User';
				id: string;
				name?: string | null | undefined;
				firstName: string;
				lastName: string;
				email: any;
				role: Types.Role;
				houseId?: string | null | undefined;
		  }
		| null
		| undefined;
};

export const GetMeDocument = gql`
	query GetMe {
		me {
			id
			name
			firstName
			lastName
			email
			role
			houseId
		}
	}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
	baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(
		GetMeDocument,
		options
	);
}
export function useGetMeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(
		GetMeDocument,
		options
	);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<
	GetMeQuery,
	GetMeQueryVariables
>;
