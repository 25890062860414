import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { useApolloClient } from '@apollo/client';

// View
import CreateSpinSessionView from './CreateSpinSessionView';

// Context
import AuthContext from '../../contexts/authContext';

// Query
import { useFormListYoungPeopleQuery } from '../../graphql/hooks';
import {
	CreateSpinSessionDocument,
	CreateSpinSessionMutation,
	CreateSpinSessionMutationVariables,
} from './query.generated';

// Types
import { SpinSessionData, InitialData } from './types';
import { LogCreateInput, LogType } from '../../graphql/types';

type CreateSpinSessionViewContainerProps = {
	houseId?: string;
	youngPersonId?: string;
};

const CreateSpinSessionViewContainer: React.FC<CreateSpinSessionViewContainerProps> =
	(props) => {
		const { user } = useContext(AuthContext);
		const client = useApolloClient();
		const { enqueueSnackbar } = useSnackbar();

		// Create Where filter for the List YP Query based on if the house id has been passed in
		const whereFilter = props?.houseId
			? {
					house: {
						id: {
							equals: props?.houseId ?? '',
						},
					},
			  }
			: undefined;

		const { data, error, loading } = useFormListYoungPeopleQuery({
			variables: {
				where: whereFilter,
			},
		});

		const onFormSubmit = async (
			initialData: InitialData,
			spinData: SpinSessionData[]
		) => {
			if (!initialData) throw new Error('No initialData!');
			if (!spinData) throw new Error('No spinData!');

			try {
				const data: LogCreateInput = {
					type: LogType.SpinSession,
					youngPersonId: props.youngPersonId ?? initialData.youngPersonId ?? '',
					createdBy: user?.id ?? '',
					data: JSON.stringify({
						trmLevel: initialData.trmLevel,
						date: dayjs(initialData?.date).toISOString(),
						type: initialData.type,
						spin: spinData.map((spin) => {
							return { ...spin, anotherRound: undefined };
						}),
					}),
				};
				const res = await client.mutate<
					CreateSpinSessionMutation,
					CreateSpinSessionMutationVariables
				>({
					mutation: CreateSpinSessionDocument,
					variables: {
						data,
					},
				});

				enqueueSnackbar('Spin Session have been successfully logged.', {
					variant: 'success',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});

				return Promise.resolve(res);
			} catch (error) {
				console.log('error', error);
				enqueueSnackbar('Something went wrong, please try again later.', {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
				return Promise.reject(error);
			}
		};

		return (
			<CreateSpinSessionView
				youngPeopleData={props.youngPersonId ? [] : data?.listYoungPeople}
				pageLoading={loading}
				error={!!error}
				onFormSubmit={onFormSubmit}
			/>
		);
	};

export default CreateSpinSessionViewContainer;
