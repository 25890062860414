import React from 'react';
import { ApolloError } from '@apollo/client';
import { useHistory } from 'react-router-dom';
// Components
import FallbackComponent from '../../components/FallbackComponent';
import CardWithAdornmentComponent from '../../components/CardWithAdornmentComponent';
import PaginationCountFeedbackComponent from '../../components/PaginationCountFeedbackComponent';
import PaginationComponent from '../../components/PaginationComponent';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core';
import { ListYoungPeopleQueryHookResult } from './query.generated';
import ListResourceGridComponent from '../../components/ListResourceGridComponent';

export type YoungPeopleViewProps = {
	data: ListYoungPeopleQueryHookResult['data'];
	loading?: boolean;
	error?: ApolloError;
	handlePaginate: (page: number) => void;
	pageCount: number;
	currentPage: number;
	count: number;
};

/**
 * This will list the young people
 * @param {YoungPeopleViewProps} props
 * @return {=>}
 */

const YoungPeopleView = (props: YoungPeopleViewProps) => {
	const history = useHistory();
	const theme = useTheme();

	const goTo = (id: string) => {
		history.push(`/kids/${id}`);
	};

	return (
		<Box minHeight="7.5rem">
			<FallbackComponent
				hasError={Boolean(props.error)}
				isLoading={Boolean(props.loading)}
				loadingType="skeletonGridMd"
				isEmpty={!props.data?.listYoungPeople?.length}
				emptyTitle="No Young People"
				emptyCopy="Try adding a young person to this house and try again."
				errorTitle={'An Error Occurred'}
				errorCopy="There was an error fetching the young people. If the problem persists, please contact your service administrator."
			>
				<ListResourceGridComponent>
					{props.data?.listYoungPeople?.map(
						(item) =>
							item && (
								<CardWithAdornmentComponent
									key={item.id}
									label={item.name ?? ''}
									subLabel={item.house?.title ?? ''}
									adornmentColor={item.house?.color ?? undefined}
									avatarSrc={
										item.avatar ??
										`https://avatars.dicebear.com/api/avataaars/${item.id}.svg`
									}
									avatarAlt={`${item.name} profile picture`}
									onClick={() => goTo(item.id)}
								/>
							)
					)}
				</ListResourceGridComponent>
				<Box
					display="flex"
					justifyContent="space-between"
					paddingTop={theme.spacing('md')}
				>
					<PaginationComponent
						count={props.pageCount}
						page={props.currentPage}
						handleChange={(i: number) => props.handlePaginate(i)}
					/>
					<PaginationCountFeedbackComponent
						current={props.data?.listYoungPeople?.length ?? 0}
						listLength={props.count}
						suffix="Fabric Kids"
					/>
				</Box>
			</FallbackComponent>
		</Box>
	);
};

export default YoungPeopleView;
