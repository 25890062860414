import * as React from 'react';
// Global css variables can be defined here.

export const CSS_VARS = {
	'--amplify-primary-color': '#0AB472',
	'--amplify-primary-tint': '#0AB472',
	'--amplify-primary-shade': '#0AB472',
	'--amplify-secondary-color': '#741FD8',
	'--amplify-tertiary-color': '#1E73F4',
} as React.CSSProperties;
