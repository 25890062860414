import React from 'react';

import { useTitle } from 'react-use';
import AdminListYoungPeopleScreen from './AdminListYoungPeopleScreen';

type AdminListYoungPeopleScreenContainerProps = {};

const AdminListYoungPeopleScreenContainer: React.FC<AdminListYoungPeopleScreenContainerProps> =
	(props) => {
		useTitle('STELLA | Kids');

		return <AdminListYoungPeopleScreen />;
	};

export default AdminListYoungPeopleScreenContainer;
