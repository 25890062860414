import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type YoungPersonRiskAssessmentQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
}>;

export type YoungPersonRiskAssessmentQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| { __typename?: 'YoungPerson'; riskAssessment?: any | null | undefined }
		| null
		| undefined;
};

export type UpdateYoungPersonRiskAssessmentMutationVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
	riskAssessment: Types.Scalars['AWSJSON'];
	createdBy: Types.Scalars['String'];
}>;

export type UpdateYoungPersonRiskAssessmentMutation = {
	__typename?: 'Mutation';
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				riskAssessment?: any | null | undefined;
		  }
		| null
		| undefined;
	createLog?:
		| { __typename?: 'Log'; id: string; data: any; createdBy: string }
		| null
		| undefined;
};

export const YoungPersonRiskAssessmentDocument = gql`
	query YoungPersonRiskAssessment($id: String) {
		getYoungPerson(where: { id: $id }) {
			riskAssessment
		}
	}
`;

/**
 * __useYoungPersonRiskAssessmentQuery__
 *
 * To run a query within a React component, call `useYoungPersonRiskAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useYoungPersonRiskAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYoungPersonRiskAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useYoungPersonRiskAssessmentQuery(
	baseOptions?: Apollo.QueryHookOptions<
		YoungPersonRiskAssessmentQuery,
		YoungPersonRiskAssessmentQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		YoungPersonRiskAssessmentQuery,
		YoungPersonRiskAssessmentQueryVariables
	>(YoungPersonRiskAssessmentDocument, options);
}
export function useYoungPersonRiskAssessmentLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		YoungPersonRiskAssessmentQuery,
		YoungPersonRiskAssessmentQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		YoungPersonRiskAssessmentQuery,
		YoungPersonRiskAssessmentQueryVariables
	>(YoungPersonRiskAssessmentDocument, options);
}
export type YoungPersonRiskAssessmentQueryHookResult = ReturnType<
	typeof useYoungPersonRiskAssessmentQuery
>;
export type YoungPersonRiskAssessmentLazyQueryHookResult = ReturnType<
	typeof useYoungPersonRiskAssessmentLazyQuery
>;
export type YoungPersonRiskAssessmentQueryResult = Apollo.QueryResult<
	YoungPersonRiskAssessmentQuery,
	YoungPersonRiskAssessmentQueryVariables
>;
export const UpdateYoungPersonRiskAssessmentDocument = gql`
	mutation UpdateYoungPersonRiskAssessment(
		$youngPersonId: String!
		$riskAssessment: AWSJSON!
		$createdBy: String!
	) {
		updateYoungPerson(
			where: { id: $youngPersonId }
			data: { riskAssessment: $riskAssessment }
		) {
			id
			riskAssessment
		}
		createLog(
			data: {
				createdBy: $createdBy
				type: RISK_ASSESSMENT
				data: $riskAssessment
				youngPersonId: $youngPersonId
			}
		) {
			id
			data
			createdBy
		}
	}
`;
export type UpdateYoungPersonRiskAssessmentMutationFn = Apollo.MutationFunction<
	UpdateYoungPersonRiskAssessmentMutation,
	UpdateYoungPersonRiskAssessmentMutationVariables
>;

/**
 * __useUpdateYoungPersonRiskAssessmentMutation__
 *
 * To run a mutation, you first call `useUpdateYoungPersonRiskAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoungPersonRiskAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoungPersonRiskAssessmentMutation, { data, loading, error }] = useUpdateYoungPersonRiskAssessmentMutation({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      riskAssessment: // value for 'riskAssessment'
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useUpdateYoungPersonRiskAssessmentMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateYoungPersonRiskAssessmentMutation,
		UpdateYoungPersonRiskAssessmentMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UpdateYoungPersonRiskAssessmentMutation,
		UpdateYoungPersonRiskAssessmentMutationVariables
	>(UpdateYoungPersonRiskAssessmentDocument, options);
}
export type UpdateYoungPersonRiskAssessmentMutationHookResult = ReturnType<
	typeof useUpdateYoungPersonRiskAssessmentMutation
>;
export type UpdateYoungPersonRiskAssessmentMutationResult =
	Apollo.MutationResult<UpdateYoungPersonRiskAssessmentMutation>;
export type UpdateYoungPersonRiskAssessmentMutationOptions =
	Apollo.BaseMutationOptions<
		UpdateYoungPersonRiskAssessmentMutation,
		UpdateYoungPersonRiskAssessmentMutationVariables
	>;
