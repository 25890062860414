import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type UserWithHouseFragment = {
	__typename?: 'User';
	id: string;
	name?: string | null | undefined;
	role: Types.Role;
	house?:
		| {
				__typename?: 'House';
				id: string;
				title: string;
				color?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type ListUsersWithHouseQueryVariables = Types.Exact<{
	where?: Types.Maybe<Types.UserWhereFilterInput>;
	take?: Types.Maybe<Types.Scalars['Int']>;
	skip?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type ListUsersWithHouseQuery = {
	__typename?: 'Query';
	countUsers?: number | null | undefined;
	listUsers?:
		| Array<
				| {
						__typename?: 'User';
						id: string;
						name?: string | null | undefined;
						role: Types.Role;
						house?:
							| {
									__typename?: 'House';
									id: string;
									title: string;
									color?: string | null | undefined;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const UserWithHouseFragmentDoc = gql`
	fragment UserWithHouse on User {
		id
		name
		role
		house {
			id
			title
			color
		}
	}
`;
export const ListUsersWithHouseDocument = gql`
	query ListUsersWithHouse(
		$where: UserWhereFilterInput
		$take: Int
		$skip: Int
	) {
		listUsers(where: $where, take: $take, skip: $skip) {
			...UserWithHouse
		}
		countUsers(where: $where)
	}
	${UserWithHouseFragmentDoc}
`;

/**
 * __useListUsersWithHouseQuery__
 *
 * To run a query within a React component, call `useListUsersWithHouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersWithHouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersWithHouseQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useListUsersWithHouseQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ListUsersWithHouseQuery,
		ListUsersWithHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		ListUsersWithHouseQuery,
		ListUsersWithHouseQueryVariables
	>(ListUsersWithHouseDocument, options);
}
export function useListUsersWithHouseLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListUsersWithHouseQuery,
		ListUsersWithHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ListUsersWithHouseQuery,
		ListUsersWithHouseQueryVariables
	>(ListUsersWithHouseDocument, options);
}
export type ListUsersWithHouseQueryHookResult = ReturnType<
	typeof useListUsersWithHouseQuery
>;
export type ListUsersWithHouseLazyQueryHookResult = ReturnType<
	typeof useListUsersWithHouseLazyQuery
>;
export type ListUsersWithHouseQueryResult = Apollo.QueryResult<
	ListUsersWithHouseQuery,
	ListUsersWithHouseQueryVariables
>;
