import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type FileFragment = {
	__typename?: 'File';
	id?: string | null | undefined;
	lastModified?: string | null | undefined;
	key?: string | null | undefined;
};

export type GetYoungPersonFilesQueryVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
	houseId?: Types.Maybe<Types.Scalars['String']>;
	folderKey?: Types.Maybe<Types.Scalars['String']>;
}>;

export type GetYoungPersonFilesQuery = {
	__typename?: 'Query';
	listYoungPersonFiles?:
		| {
				__typename?: 'ListFilesResponse';
				folders?: Array<string | null | undefined> | null | undefined;
				files?:
					| Array<
							| {
									__typename?: 'File';
									id?: string | null | undefined;
									lastModified?: string | null | undefined;
									key?: string | null | undefined;
							  }
							| null
							| undefined
					  >
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type GetHouseFilesQueryVariables = Types.Exact<{
	houseId: Types.Scalars['String'];
	folderKey?: Types.Maybe<Types.Scalars['String']>;
}>;

export type GetHouseFilesQuery = {
	__typename?: 'Query';
	listHouseFiles?:
		| {
				__typename?: 'ListFilesResponse';
				folders?: Array<string | null | undefined> | null | undefined;
				files?:
					| Array<
							| {
									__typename?: 'File';
									id?: string | null | undefined;
									lastModified?: string | null | undefined;
									key?: string | null | undefined;
							  }
							| null
							| undefined
					  >
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const FileFragmentDoc = gql`
	fragment File on File {
		id
		lastModified
		key
	}
`;
export const GetYoungPersonFilesDocument = gql`
	query GetYoungPersonFiles(
		$youngPersonId: String!
		$houseId: String
		$folderKey: String
	) {
		listYoungPersonFiles(
			input: {
				youngPersonId: $youngPersonId
				houseId: $houseId
				folderKey: $folderKey
			}
		) {
			folders
			files {
				...File
			}
		}
	}
	${FileFragmentDoc}
`;

/**
 * __useGetYoungPersonFilesQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonFilesQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      houseId: // value for 'houseId'
 *      folderKey: // value for 'folderKey'
 *   },
 * });
 */
export function useGetYoungPersonFilesQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYoungPersonFilesQuery,
		GetYoungPersonFilesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetYoungPersonFilesQuery,
		GetYoungPersonFilesQueryVariables
	>(GetYoungPersonFilesDocument, options);
}
export function useGetYoungPersonFilesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYoungPersonFilesQuery,
		GetYoungPersonFilesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetYoungPersonFilesQuery,
		GetYoungPersonFilesQueryVariables
	>(GetYoungPersonFilesDocument, options);
}
export type GetYoungPersonFilesQueryHookResult = ReturnType<
	typeof useGetYoungPersonFilesQuery
>;
export type GetYoungPersonFilesLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonFilesLazyQuery
>;
export type GetYoungPersonFilesQueryResult = Apollo.QueryResult<
	GetYoungPersonFilesQuery,
	GetYoungPersonFilesQueryVariables
>;
export const GetHouseFilesDocument = gql`
	query GetHouseFiles($houseId: String!, $folderKey: String) {
		listHouseFiles(input: { houseId: $houseId, folderKey: $folderKey }) {
			folders
			files {
				...File
			}
		}
	}
	${FileFragmentDoc}
`;

/**
 * __useGetHouseFilesQuery__
 *
 * To run a query within a React component, call `useGetHouseFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseFilesQuery({
 *   variables: {
 *      houseId: // value for 'houseId'
 *      folderKey: // value for 'folderKey'
 *   },
 * });
 */
export function useGetHouseFilesQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetHouseFilesQuery,
		GetHouseFilesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetHouseFilesQuery, GetHouseFilesQueryVariables>(
		GetHouseFilesDocument,
		options
	);
}
export function useGetHouseFilesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetHouseFilesQuery,
		GetHouseFilesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetHouseFilesQuery, GetHouseFilesQueryVariables>(
		GetHouseFilesDocument,
		options
	);
}
export type GetHouseFilesQueryHookResult = ReturnType<
	typeof useGetHouseFilesQuery
>;
export type GetHouseFilesLazyQueryHookResult = ReturnType<
	typeof useGetHouseFilesLazyQuery
>;
export type GetHouseFilesQueryResult = Apollo.QueryResult<
	GetHouseFilesQuery,
	GetHouseFilesQueryVariables
>;
