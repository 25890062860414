import React from 'react';
import Alert, { AlertProps } from '@material-ui/lab/Alert';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import InfoCardLabelComponent from '../InfoCardLabelComponent';
import InfoCardSubLabelComponent from '../InfoCardSubLabelComponent';
import InfoCardAvatarComponent from '../InfoCardAvatarComponent';
import InfoCardLabelContainerComponent from '../InfoCardLabelContainerComponent';

const useStyles = makeStyles<Theme, InfoCardContainerProps>((theme) =>
	createStyles({
		container: {
			width: '100%',
			padding: (props) =>
				props.disableHorizontalPadding
					? `${theme.spacing('xs')} 0`
					: `${theme.spacing('xs')}`,
			borderRadius: theme.shape.borderRadius,
			cursor: (props) => (props.onClick ? 'pointer' : 'initial'),
			'&:hover': {
				opacity: (props) => (props.onClick ? 0.75 : 1),
			},
			backgroundColor: (props) =>
				props.severity ??
				props.defaultBackgroundColor ??
				theme.palette.grey[100],
			'& [class*="MuiAlert-message"]': {
				width: '100%',
				display: 'flex',
				padding: 'unset',
			},
			boxShadow: (props) => (props.hasShadow ? theme.shadows[4] : 'none'),
		},
	})
);

export type InfoCardProps = {
	label: string;
	subLabel: string;
	avatarAlt: string;
	avatarUrl?: string;
	trailingIcon?: React.ReactNode;
	labelIcon?: React.ReactNode;
	severity?: AlertProps['severity'];
};

type InfoCardContainerProps = {
	onClick?: () => void;
	severity?: AlertProps['severity'];
	defaultBackgroundColor?: string;
	disableHorizontalPadding?: boolean;
	hasShadow?: boolean;
};

/** Top level component (InfoCard) renders the container, all other sub-components are properties of InfoCard
 *
 * @example
 *
 *  const MyComponent = (props) => (
    <InfoCard severity={props.severity} defaultBackgroundColor="your-favorite-color">
      <InfoCard.Avatar avatarAlt={props.avatarAlt} avatarUrl={props.avatarUrl} />
      <InfoCard.LabelContainer>
        <InfoCard.Label
          trailingIcon={props.trailingIcon}
          labelIcon={props.labelIcon}
        >
          {props.label}
        </InfoCard.Label>
        <InfoCard.SubLabel>{props.subLabel}</InfoCard.SubLabel>
      </InfoCard.LabelContainer>
  </InfoCard>
  )
 *
 */

const InfoCardContainer: React.FC<InfoCardContainerProps> = ({
	onClick,
	severity,
	defaultBackgroundColor,
	disableHorizontalPadding,
	hasShadow,
	children,
}) => {
	const styles = useStyles({
		severity,
		defaultBackgroundColor,
		disableHorizontalPadding,
		onClick,
		hasShadow,
	});

	return (
		<Alert
			onClick={onClick}
			icon={false}
			role="presentation"
			className={styles.container}
			severity={severity}
		>
			{children}
		</Alert>
	);
};

export type InfoCardType = React.FC<InfoCardContainerProps> & {
	Label: typeof InfoCardLabelComponent;
	SubLabel: typeof InfoCardSubLabelComponent;
	Avatar: typeof InfoCardAvatarComponent;
	LabelContainer: typeof InfoCardLabelContainerComponent;
};

const InfoCardComponent: InfoCardType = Object.assign(InfoCardContainer, {
	Label: InfoCardLabelComponent,
	SubLabel: InfoCardSubLabelComponent,
	LabelContainer: InfoCardLabelContainerComponent,
	Avatar: InfoCardAvatarComponent,
});

export default InfoCardComponent;
