import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Select,
	Grid,
	createStyles,
	makeStyles,
	MenuItem,
	Theme,
} from '@material-ui/core';

// Views
import OccupancyChartView from '../../views/OccupancyChartView';

// Query
import { useListHousesQuery } from '../../graphql/hooks';
import FallbackComponent from '../../components/FallbackComponent';

// Contexts
import ModalContext, { ModalType } from '../../contexts/ModalContext';

type HouseLayoutProps = {};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(3),
		},
		header: {
			display: 'flex',
			gap: theme.spacing(2),
			justifyContent: 'flex-end',
		},
		select: {
			minWidth: '200px',
		},
	})
);

const HouseLayout: React.FC<HouseLayoutProps> = (props) => {
	const classes = useStyles();
	const [houseId, setHouseId] = useState<string>('');

	const { data, error, loading } = useListHousesQuery({
		variables: {
			where: {
				AND: [
					{ preMoveInHouse: { equals: false } },
					{ isMoveOutHouse: { not: { equals: true } } },
				],
			},
		},
	});

	useEffect(() => {
		if (error || loading) return;
		setHouseId(data?.listHouses?.[0]?.id ?? '');
	}, [data, error, loading]);

	return (
		<FallbackComponent
			isLoading={loading}
			loadingType="circularProgress"
			hasError={Boolean(error)}
			errorTitle="Error Loading Report"
			errorCopy="Unable to load the list of young people, please try again later"
		>
			<Box className={classes.wrapper}>
				<Box className={classes.header}>
					<Select
						value={houseId}
						label="Select a House"
						className={classes.select}
						onChange={(event) => {
							// @ts-expect-error
							setHouseId(event?.target?.value ?? '');
						}}
					>
						{data?.listHouses?.map((house) => (
							<MenuItem value={house?.id}>{house?.title}</MenuItem>
						))}
					</Select>
				</Box>
				<OccupancyChartView id={houseId} />
			</Box>
		</FallbackComponent>
	);
};

export default HouseLayout;
