import { FormComponentProps } from '../../components/FormComponent';
import { FormFieldTypes } from '../../components/FormFieldsComponent';

const FormBase = (
	onSubmit: FormComponentProps['onSubmit']
): FormComponentProps => {
	return {
		title: 'Change Password',
		defaultValues: {
			newPassword: '',
		},
		fields: [
			{
				id: 'newPassword',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'New Password:',
					type: 'password',
					autoComplete: 'new-password',
				},
				validation: {
					required: true,
					pattern: {
						value:
							/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\[\]{}()\\\/"'<>|~`=+#?!@$:%^&*\-_;.,]).{8,}$/,
						message:
							'Password require 1 lower and upper case letter, 1 number and 1 special character.',
					},
					defaultErrorMessage: 'Please enter a valid password.',
				},
			},
		],
		successCopy: "You're password has been successfully changed.",
		submitCopy: 'Change Password',
		onSubmit,
	};
};

export default FormBase;
