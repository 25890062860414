import { GetYoungPersonQueryHookResult } from '../query.generated';
import { Detail } from '../../../components/DetailsCardComponent';
import { LegalStatusName } from '../../../utils/legalStatus';

const LegalDetailsCard = (args: GetYoungPersonQueryHookResult['data']) => {
	const legalDetails: Detail[] = [
		{
			id: 'ypLocalAuthority',
			title: 'Local Authority',
			copy: args?.getYoungPerson?.localAuthority ?? '-',
		},
		{
			id: 'ypLegalStatus',
			title: 'Legal Status',
			copy: LegalStatusName(args?.getYoungPerson?.legalStatus ?? ''),
		},
		{
			id: 'ypNi',
			title: 'National Insurance Number',
			copy: args?.getYoungPerson?.niNumber ?? '-',
		},
		{
			id: 'ypNhs',
			title: 'NHS Number',
			copy: args?.getYoungPerson?.nhsNumber ?? '-',
		},
		{
			id: 'ypGp',
			title: 'Registered GP Details',
			copy: args?.getYoungPerson?.registeredGP ?? '-',
		},
		{
			id: 'ypDentist',
			title: 'Registered Dentist Details',
			copy: args?.getYoungPerson?.registeredDentist ?? '-',
		},
		{
			id: 'ypOptician',
			title: 'Registered Optician Details',
			copy: args?.getYoungPerson?.registeredOptician ?? '-',
		},
		{
			id: 'ypSocialWorker',
			title: 'Social Worker Details',
			copy: args?.getYoungPerson?.socialWorker ?? '-',
		},
		{
			id: 'ypAdvisor',
			title: 'Young Person Advisor Details',
			copy: args?.getYoungPerson?.ypAdvisor ?? '-',
		},
	];

	return legalDetails;
};

export default LegalDetailsCard;
