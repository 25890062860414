import dayjs from 'dayjs';
import { GetYoungPersonQueryHookResult } from '../query.generated';
import { Detail } from '../../../components/DetailsCardComponent';

const BasicDetailsCard = (args: GetYoungPersonQueryHookResult['data']) => {
	const basicDetails: Detail[] = [
		{
			id: 'ypName',
			title: 'Name',
			copy: args?.getYoungPerson?.name,
		},
		{
			id: 'ypPreferredName',
			title: 'Preferred Name',
			copy: args?.getYoungPerson?.preferredName ?? '-',
		},
		{
			id: 'ypGender',
			title: 'Gender',
			copy: args?.getYoungPerson?.gender ?? '-',
		},
		{
			id: 'ypEmail',
			title: 'Email',
			copy: args?.getYoungPerson?.email,
		},
		{
			id: 'ypPhone',
			title: 'Contact No',
			copy: args?.getYoungPerson?.phone,
		},
		{
			id: 'ypDoB',
			title: 'Date of Birth',
			copy: dayjs(args?.getYoungPerson?.dateOfBirth).format('DD/MM/YYYY'),
		},
		{
			id: 'ethnicity',
			title: 'Ethnicity',
			copy: args?.getYoungPerson?.ethnicity ?? '-',
		},
		{
			id: 'religion',
			title: 'Religion',
			copy: args?.getYoungPerson?.religion ?? '-',
		},
		{
			id: 'spokenLanguage',
			title: 'Spoken Language',
			copy: args?.getYoungPerson?.spokenLanguage ?? '-',
		},
		{
			id: 'safePlace',
			title: 'When im missing I normally go',
			copy: args?.getYoungPerson?.safePlace ?? '-',
		},
		{
			id: 'ypPhysicalDescription',
			title: 'Appearance',
			copy: args?.getYoungPerson?.physicalDescription ?? '-',
		},
		{
			id: 'ypAddress',
			title: 'Most Recent Address',
			copy: args?.getYoungPerson?.mostRecentAddress ?? '-',
		},
	];

	return basicDetails;
};

export default BasicDetailsCard;
