import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import { useApolloClient } from '@apollo/client';

// View
import CreateCoachingSessionView from './CreateCoachingSessionView';

// Context
import AuthContext from '../../contexts/authContext';

// Query
import { useFormListYoungPeopleQuery } from '../../graphql/hooks';

// Types
import { CoachingSession, SharedCoachingData } from './types';
import {
	CreateCoachingSessionsDocument,
	CreateCoachingSessionsMutation,
	CreateCoachingSessionsMutationVariables,
} from './query.generated';
import { LogCreateManyInput, LogType } from '../../graphql/types';

type CreateCoachingSessionViewContainerProps = {
	houseId?: string;
};

const CreateCoachingSessionViewContainer: React.FC<CreateCoachingSessionViewContainerProps> =
	(props) => {
		const { user } = useContext(AuthContext);
		const client = useApolloClient();
		const { enqueueSnackbar } = useSnackbar();

		// Create Where filter for the List YP Query based on if the house id has been passed in
		const whereFilter = props?.houseId
			? {
					house: {
						id: {
							equals: props?.houseId ?? '',
						},
					},
			  }
			: undefined;

		const {
			data: ypData,
			error: ypError,
			loading: ypLoading,
		} = useFormListYoungPeopleQuery({
			variables: {
				where: whereFilter,
			},
		});

		const onFormSubmit = async (
			sharedData: SharedCoachingData | undefined,
			coachingData: CoachingSession[]
		): Promise<any> => {
			try {
				if (!sharedData) throw new Error('No sharedData!');

				const data: LogCreateManyInput[] = coachingData.map((session) => {
					return {
						data: JSON.stringify({
							...session,
							youngPersonId: undefined,
							category: sharedData.category,
							subCategory: sharedData.subCategory,
							date: dayjs(sharedData?.date).toISOString(),
						}),
						type: LogType.CoachingSession,
						youngPersonId: session.youngPersonId,
						createdBy: user?.id ?? '',
					};
				});

				if (data.length === 0) throw new Error('No data!');

				const res = await client.mutate<
					CreateCoachingSessionsMutation,
					CreateCoachingSessionsMutationVariables
				>({
					mutation: CreateCoachingSessionsDocument,
					variables: {
						data,
					},
				});
				enqueueSnackbar('Coaching Sessions have been successfully logged.', {
					variant: 'success',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});

				return Promise.resolve(res);
			} catch (error) {
				console.log('error', error);
				enqueueSnackbar('Something went wrong, please try again later.', {
					variant: 'error',
					anchorOrigin: { vertical: 'top', horizontal: 'right' },
				});
				return Promise.reject(error);
			}
		};

		return (
			<CreateCoachingSessionView
				youngPeopleData={ypData?.listYoungPeople}
				pageLoading={ypLoading}
				error={!!ypError}
				onFormSubmit={onFormSubmit}
			/>
		);
	};

export default CreateCoachingSessionViewContainer;
