import React from 'react';
import {
	Box,
	makeStyles,
	createStyles,
	Theme,
	Typography,
	LinearProgress,
} from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DoneIcon from '@material-ui/icons/Done';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { UploadState } from '../../hooks/useUploadFile/useUploadFile';

export type UploadFileProgressComponentProps = {
	progress: number;
	fileName?: string;
	state: UploadState;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			gap: theme.spacing('sm'),
			flexDirection: 'column',
			width: '100%',
		},
		upper: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		},
		fileName: {
			display: 'flex',
			alignItems: 'center',
			gap: theme.spacing('sm'),
		},
	})
);

const UploadFileProgressComponent: React.FC<UploadFileProgressComponentProps> =
	(props) => {
		const classes = useStyles();

		if (props.state === UploadState.IDLE && !props.fileName) {
			return null;
		}

		let ProgressElement: React.ReactNode = null;
		switch (props.state) {
			case UploadState.LOADING:
				ProgressElement = (
					<Typography variant="body2" color="primary">
						{props.progress}%
					</Typography>
				);
				break;
			case UploadState.SUCCESS:
				ProgressElement = <DoneIcon color="primary" />;
				break;
			case UploadState.ERROR:
				ProgressElement = <ErrorOutlineIcon color="error" />;
				break;
		}

		return (
			<Box className={classes.wrapper}>
				<Box className={classes.upper}>
					<Box className={classes.fileName}>
						<InsertDriveFileIcon fontSize="large" />{' '}
						<Typography variant="body1">{props.fileName}</Typography>
					</Box>
					{ProgressElement}
				</Box>
				{props.state === UploadState.LOADING ? (
					<LinearProgress variant="determinate" value={props.progress} />
				) : null}
			</Box>
		);
	};

export default UploadFileProgressComponent;
