import dayjs from 'dayjs';
import { Typography } from '@material-ui/core';

// Query
import {
	// Get Activity
	GetActivityLogDocument,
	GetActivityLogQuery,
	GetActivityLogQueryVariables,
	// List YoungPerson
	FormListYoungPeopleDocument,
	FormListYoungPeopleQuery,
	FormListYoungPeopleQueryVariables,
	// Create Mutation
	CreateActivityLogDocument,
	CreateActivityLogMutation,
	CreateActivityLogMutationVariables,
	// Update Mutation
	UpdateActivityLogDocument,
	UpdateActivityLogMutation,
	UpdateActivityLogMutationVariables,
} from '../logActivity/query.generated';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { LogIncidentArgs } from '../../types';
import { LogType } from '../../../../graphql/types';
import { IncidentLogData } from '../../../../types';

export type LogIncidentFormValues = {
	note: string;
	youngPersonId?: string;
	date: string;
	incidentNumber?: string;
	witnesses?: string;
	location: string;
	category: string[];
	contacted: string[];
};

const PreFetch = async (
	args: LogIncidentArgs,
	client: ApolloClient<object>
) => {
	let log: ApolloQueryResult<GetActivityLogQuery> | undefined = undefined;
	let yp: ApolloQueryResult<FormListYoungPeopleQuery> | undefined = undefined;

	if (args.id) {
		log = await client.query<GetActivityLogQuery, GetActivityLogQueryVariables>(
			{
				query: GetActivityLogDocument,
				variables: {
					id: args.id,
				},
			}
		);

		if (!log.data.getLog || log.error)
			throw new Error('Pre-fetched Data Failed');
	}

	if (!args.youngPersonId) {
		const where = args.houseId
			? {
					house: {
						id: {
							equals: args.houseId,
						},
					},
			  }
			: {};

		yp = await client.query<
			FormListYoungPeopleQuery,
			FormListYoungPeopleQueryVariables
		>({
			query: FormListYoungPeopleDocument,
			variables: {
				where,
			},
		});

		if (!yp.data.listYoungPeople || yp.error)
			throw new Error('Pre-fetched Data Failed');
	}

	return {
		incident: log?.data.getLog ?? undefined,
		youngPerson: yp?.data.listYoungPeople ?? undefined,
	};
};

type Data = {
	incident: GetActivityLogQuery['getLog'] | undefined;
	youngPerson: FormListYoungPeopleQuery['listYoungPeople'] | undefined;
};

const FormFields = (
	args: LogIncidentArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const formData: IncidentLogData | undefined = data.incident?.data
		? JSON.parse(data.incident?.data)
		: undefined;

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'date',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Date:',
				defaultValue:
					dayjs(formData?.date).format('YYYY-MM-DD') ??
					dayjs(Date.now()).format('YYYY-MM-DD'),
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'location',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Location:',
				defaultValue: formData?.location ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'category',
			type: FormFieldTypes.SELECT,
			label: 'Incident Category: (Multiple Selection Possible)',
			config: {
				defaultValue: formData?.category ?? [],
				multiple: true,
			},
			validation: {
				required: true,
			},
			options: [
				{
					value: 'Physical Aggression',
					copy: 'Physical Aggression',
				},
				{
					value: 'Medication/ Overdose',
					copy: 'Medication/ Overdose',
				},
				{
					value: 'Absconding',
					copy: 'Absconding',
				},
				{
					value: 'Safeguarding',
					copy: 'Safeguarding',
				},
				{
					value: 'Verbal Aggression',
					copy: 'Verbal Aggression',
				},
				{
					value: 'Property Damage',
					copy: 'Property Damage',
				},
				{
					value: 'Suicidal Thoughts',
					copy: 'Suicidal Thoughts',
				},
				{
					value: 'Self-harm',
					copy: 'Self-harm',
				},
				{
					value: 'Well-being concerns',
					copy: 'Well-being concerns',
				},
				{
					value: 'Accident / injury',
					copy: 'Accident / injury',
				},
				{
					value: 'Low mood',
					copy: 'Low mood',
				},
				{
					value: 'Friends/ family',
					copy: 'Friends/ family',
				},
				{
					value: 'Other',
					copy: 'Other (Please state in Note)',
				},
			],
		},
		{
			id: 'witnesses',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Witnesses:',
				defaultValue: formData?.witnesses ?? '',
			},
			validation: {},
		},
		{
			id: 'contacted',
			type: FormFieldTypes.SELECT,
			label: 'Who was contacted: (Multiple Selection Possible)',
			config: {
				defaultValue: formData?.contacted ?? [],
				multiple: true,
			},
			validation: {},
			options: [
				{ value: 'On-call Member', copy: 'On-call Member' },
				{ value: 'EDT', copy: 'EDT' },
				{ value: 'NHS Direct', copy: 'NHS Direct' },
				{ value: 'Social Worker or YPA', copy: 'Social Worker or YPA' },
				{ value: '101', copy: '101 (Non-emergency police)' },
				{ value: 'GP', copy: 'GP' },
				{ value: 'Crisis team', copy: 'Crisis team' },
				{ value: 'Other', copy: 'Other (Please state in Note)' },
			],
		},
		{
			id: 'incidentNumber',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Police Incident Report Number:',
				defaultValue: formData?.incidentNumber ?? '',
			},
			validation: {},
		},
		{
			id: 'prompts',
			type: FormFieldTypes.CUSTOM,
			render: (
				<div>
					<Typography
						variant="h4"
						color="textSecondary"
						style={{ paddingBottom: '16px', textAlign: 'left' }}
					>
						Incident details:
					</Typography>
					<ol style={{ margin: 0, listStyle: 'none', padding: 0 }}>
						{[
							`What happened before? Was there a build- up, any known triggers to the incident?`,
							`Describe the incident? What did they say/how did they act? What did you to do manage this and what advice were you given?`,
							'What happened after the incident?',
						].map((prompt, i) => (
							<li
								style={{
									marginLeft: 0,
									textDecoration: 'none',
									padding: 0,
									marginBottom: '8px',
								}}
							>
								<Typography align="left" color="textSecondary">
									{i + 1 + ' . ' + prompt}
								</Typography>
							</li>
						))}
					</ol>
				</div>
			),
		},
		{
			id: 'note',
			type: FormFieldTypes.INPUT,
			config: {
				placeholder:
					'Please add as much detail as to what happened before, during and after including who else was involved.',
				label: 'Note:',
				multiline: true,
				rows: 4,
				defaultValue: formData?.note ?? '',
			},
			validation: {
				required: true,
			},
		},
	];

	if (!args.youngPersonId && !args.id) {
		const options = data.youngPerson?.map((yp) => {
			return { value: yp?.id ?? '', copy: yp?.name ?? '' };
		});

		fields.unshift({
			id: 'youngPersonId',
			type: FormFieldTypes.SELECT,
			label: 'Kid:',
			config: {},
			validation: {
				required: true,
			},
			options: options ?? [],
		});
	}

	return [fields];
};

const GenerateOnSubmit = async (
	args: LogIncidentArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: LogIncidentFormValues): Promise<any> => {
		if (args.id) {
			const updateIncident = await client.mutate<
				UpdateActivityLogMutation,
				UpdateActivityLogMutationVariables
			>({
				mutation: UpdateActivityLogDocument,
				variables: {
					id: args.id ?? '',
					data: {
						data: JSON.stringify({
							note: formValues.note,
							date: dayjs(formValues.date).toISOString(),
							incidentNumber: formValues.incidentNumber,
						}),
					},
				},
			});
			return updateIncident;
		} else {
			const createIncident = await client.mutate<
				CreateActivityLogMutation,
				CreateActivityLogMutationVariables
			>({
				mutation: CreateActivityLogDocument,
				variables: {
					data: {
						type: LogType.Incident,
						youngPersonId: args.youngPersonId ?? formValues.youngPersonId ?? '',
						createdBy: userId,
						data: JSON.stringify({
							note: formValues.note,
							date: dayjs(formValues.date).toISOString(),
							incidentNumber: formValues.incidentNumber,
							location: formValues.location,
							witnesses: formValues.witnesses,
							category: formValues.category,
							contacted: formValues.contacted,
						}),
					},
				},
			});

			return createIncident;
		}
	};
	return onSubmit;
};

const LogIncidentForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default LogIncidentForm;
