import React, { useState, useContext, useEffect } from 'react';
import { useTitle } from 'react-use';

// Queries
import { useListUsersWithHouseQuery } from './query.generated';

// Contexts / Hooks
import ModalContext from '../../contexts/ModalContext';
import { usePagination } from '../../hooks';

// Screens
import AdminListUsersScreen from './AdminListUsersScreen';

type AdminListUsersScreenContainerProps = {};

const AdminListUsersScreenContainer: React.FC<AdminListUsersScreenContainerProps> =
	(props) => {
		useTitle('STELLA | Users');
		const [searchTerm, setSearchTerm] = useState('');
		const { isOpen: isModalOpen } = useContext(ModalContext);
		const [skip, setSkip] = useState<number>(0);
		const take = 12;

		const { data, loading, error, refetch } = useListUsersWithHouseQuery({
			variables: {
				where: {
					name: { contains: searchTerm },
					disabled: { not: { equals: true } },
				},
				skip,
				take,
			},
		});

		const {
			currentPage,
			handlePaginate: hookHandlePaginate,
			pageCount,
			setCount,
		} = usePagination({ take, count: data?.countUsers ?? 0 });

		useEffect(() => {
			if (!loading) {
				setCount(data?.countUsers ?? 0);
			}
		}, [data, loading]);

		const handlePaginate = (page: number) => {
			hookHandlePaginate(page);
			setSkip((page - 1) * take);
		};

		// TODO: [STLL-310] Avoid using refetch once modal is closed when creating a house
		useEffect(() => {
			if (!isModalOpen && !loading) refetch();
		}, [isModalOpen]);

		const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
			setSearchTerm(e.target.value);

		return (
			<AdminListUsersScreen
				data={data}
				loading={loading}
				error={error}
				onSearch={handleSearch}
				onPaginate={handlePaginate}
				currentPage={currentPage}
				pageCount={pageCount}
				count={data?.countUsers ?? 0}
			/>
		);
	};

export default AdminListUsersScreenContainer;
