export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	AWSDate: any;
	AWSDateTime: any;
	AWSEmail: any;
	AWSIPAddress: any;
	AWSJSON: any;
	AWSPhone: any;
	AWSTime: any;
	AWSTimestamp: any;
	AWSURL: any;
};

export type AwsDateTimeFilterInput = {
	equals?: Maybe<Scalars['AWSDateTime']>;
	gt?: Maybe<Scalars['AWSDateTime']>;
	gte?: Maybe<Scalars['AWSDateTime']>;
	in?: Maybe<Array<Scalars['AWSDateTime']>>;
	lt?: Maybe<Scalars['AWSDateTime']>;
	lte?: Maybe<Scalars['AWSDateTime']>;
	not?: Maybe<AwsDateTimeFilterInput>;
	notIn?: Maybe<Array<Scalars['AWSDateTime']>>;
};

export type AwsEmailFilterInput = {
	contains?: Maybe<Scalars['String']>;
	endsWith?: Maybe<Scalars['String']>;
	equals?: Maybe<Scalars['AWSEmail']>;
	in?: Maybe<Array<Scalars['AWSEmail']>>;
	not?: Maybe<AwsEmailFilterInput>;
	notIn?: Maybe<Array<Scalars['AWSEmail']>>;
	startsWith?: Maybe<Scalars['String']>;
};

export type AwsjsonFilterInput = {
	contains?: Maybe<Scalars['String']>;
	endsWith?: Maybe<Scalars['String']>;
	equals?: Maybe<Scalars['AWSJSON']>;
	in?: Maybe<Array<Scalars['AWSJSON']>>;
	not?: Maybe<AwsjsonFilterInput>;
	notIn?: Maybe<Array<Scalars['AWSJSON']>>;
	startsWith?: Maybe<Scalars['String']>;
};

export type AwsurlFilterInput = {
	contains?: Maybe<Scalars['String']>;
	endsWith?: Maybe<Scalars['String']>;
	equals?: Maybe<Scalars['AWSURL']>;
	in?: Maybe<Array<Scalars['AWSURL']>>;
	not?: Maybe<AwsurlFilterInput>;
	notIn?: Maybe<Array<Scalars['AWSURL']>>;
	startsWith?: Maybe<Scalars['String']>;
};

export enum AvatarUserType {
	User = 'USER',
	YoungPerson = 'YOUNG_PERSON',
}

export type BatchPayload = {
	__typename?: 'BatchPayload';
	count?: Maybe<Scalars['Int']>;
};

export type BooleanFilterInput = {
	equals?: Maybe<Scalars['Boolean']>;
	not?: Maybe<BooleanFilterInput>;
};

export type CreateUploadAvatarUrlInput = {
	fileType: Scalars['String'];
	userId: Scalars['String'];
	userType: AvatarUserType;
};

export type DeleteFileInput = {
	fileKey: Scalars['String'];
};

export type File = {
	__typename?: 'File';
	id?: Maybe<Scalars['String']>;
	key?: Maybe<Scalars['String']>;
	lastModified?: Maybe<Scalars['String']>;
};

export enum FileType {
	AssessmentOutcome = 'ASSESSMENT_OUTCOME',
	AssessmentUpdates = 'ASSESSMENT_UPDATES',
	Checklist = 'CHECKLIST',
	Crf = 'CRF',
	InitialAssessment = 'INITIAL_ASSESSMENT',
	KeyConsentForm = 'KEY_CONSENT_FORM',
	LicenceAgreement = 'LICENCE_AGREEMENT',
	MedicalForm = 'MEDICAL_FORM',
	Other = 'OTHER',
	PhotoConsentForm = 'PHOTO_CONSENT_FORM',
	RiskAssessment = 'RISK_ASSESSMENT',
	SafetyMoveIn = 'SAFETY_MOVE_IN',
	SharingInfoConsentForm = 'SHARING_INFO_CONSENT_FORM',
	WrittenAgreement = 'WRITTEN_AGREEMENT',
}

export type FileTypeEnumFilterInput = {
	equals?: Maybe<FileType>;
	in?: Maybe<Array<FileType>>;
	not?: Maybe<FileTypeEnumFilterInput>;
	notIn?: Maybe<Array<FileType>>;
};

export type FloatFilterInput = {
	equals?: Maybe<Scalars['Float']>;
	gt?: Maybe<Scalars['Float']>;
	gte?: Maybe<Scalars['Float']>;
	in?: Maybe<Array<Scalars['Float']>>;
	lt?: Maybe<Scalars['Float']>;
	lte?: Maybe<Scalars['Float']>;
	not?: Maybe<FloatFilterInput>;
	notIn?: Maybe<Array<Scalars['Float']>>;
};

export enum FormType {
	CreateAlert = 'CREATE_ALERT',
	HouseCreate = 'HOUSE_CREATE',
	InviteUser = 'INVITE_USER',
	LogActivity = 'LOG_ACTIVITY',
	LogCoaching = 'LOG_COACHING',
	LogContact = 'LOG_CONTACT',
	LogIncident = 'LOG_INCIDENT',
	LogKidPayment = 'LOG_KID_PAYMENT',
	LogLocation = 'LOG_LOCATION',
	LogManagerOnDuty = 'LOG_MANAGER_ON_DUTY',
	LogNote = 'LOG_NOTE',
	LogSpin = 'LOG_SPIN',
	Room = 'ROOM',
	TaskCreate = 'TASK_CREATE',
	UserUpdate = 'USER_UPDATE',
	YpAboutMe = 'YP_ABOUT_ME',
	YpAdditionalDetails = 'YP_ADDITIONAL_DETAILS',
	YpAssignHouse = 'YP_ASSIGN_HOUSE',
	YpBasicDetails = 'YP_BASIC_DETAILS',
	YpCreate = 'YP_CREATE',
	YpInitialAssessment = 'YP_INITIAL_ASSESSMENT',
	YpLegalDetails = 'YP_LEGAL_DETAILS',
	YpMedicalDetails = 'YP_MEDICAL_DETAILS',
	YpMoveOut = 'YP_MOVE_OUT',
	YpMoveRoom = 'YP_MOVE_ROOM',
	YpNextOfKin = 'YP_NEXT_OF_KIN',
	YpRiskAssessment = 'YP_RISK_ASSESSMENT',
}

export type FormTypeEnumFilterInput = {
	equals?: Maybe<FormType>;
	in?: Maybe<Array<FormType>>;
	not?: Maybe<FormTypeEnumFilterInput>;
	notIn?: Maybe<Array<FormType>>;
};

export type GetFileInput = {
	fileKey: Scalars['String'];
};

export type House = {
	__typename?: 'House';
	address?: Maybe<Scalars['String']>;
	color?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	files?: Maybe<ListFilesResponse>;
	id: Scalars['String'];
	isMoveOutHouse?: Maybe<Scalars['Boolean']>;
	lat?: Maybe<Scalars['String']>;
	logs?: Maybe<Array<Log>>;
	long?: Maybe<Scalars['String']>;
	moveOutHouseId?: Maybe<Scalars['String']>;
	notifications?: Maybe<Array<Notification>>;
	organisation?: Maybe<Organisation>;
	organisationId?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	preMoveInHouse?: Maybe<Scalars['Boolean']>;
	rooms?: Maybe<Array<Room>>;
	tasks?: Maybe<Array<Task>>;
	timedEvents?: Maybe<Array<TimedEvent>>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	users?: Maybe<Array<User>>;
	youngPeople?: Maybe<Array<YoungPerson>>;
};

export type HouseConnectOrCreateInput = {
	create: HouseCreateInput;
	where: HouseWhereUniqueInput;
};

export type HouseCreateInput = {
	address?: Maybe<Scalars['String']>;
	color?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	isMoveOutHouse?: Maybe<Scalars['Boolean']>;
	lat?: Maybe<Scalars['String']>;
	logs?: Maybe<HouseLogsCreateRelationsInput>;
	long?: Maybe<Scalars['String']>;
	moveOutHouseId?: Maybe<Scalars['String']>;
	notifications?: Maybe<HouseNotificationsCreateRelationsInput>;
	organisation?: Maybe<HouseOrganisationCreateRelationsInput>;
	organisationId?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	preMoveInHouse?: Maybe<Scalars['Boolean']>;
	rooms?: Maybe<HouseRoomsCreateRelationsInput>;
	tasks?: Maybe<HouseTasksCreateRelationsInput>;
	timedEvents?: Maybe<HouseTimedEventsCreateRelationsInput>;
	title: Scalars['String'];
	users?: Maybe<HouseUsersCreateRelationsInput>;
	youngPeople?: Maybe<HouseYoungPeopleCreateRelationsInput>;
};

export type HouseCreateManyInput = {
	address?: Maybe<Scalars['String']>;
	color?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	isMoveOutHouse?: Maybe<Scalars['Boolean']>;
	lat?: Maybe<Scalars['String']>;
	long?: Maybe<Scalars['String']>;
	moveOutHouseId?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	preMoveInHouse?: Maybe<Scalars['Boolean']>;
	title: Scalars['String'];
};

export type HouseDeleteManyInput = {
	where: HouseWhereFilterInput;
};

export type HouseDeleteUniqueInput = {
	where: HouseWhereUniqueInput;
};

export type HouseFilterRelationsInput = {
	every?: Maybe<HouseWhereFilterInput>;
	none?: Maybe<HouseWhereFilterInput>;
	some?: Maybe<HouseWhereFilterInput>;
};

export type HouseLogsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<LogConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<LogCreateInput>>>;
};

export type HouseLogsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<LogConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<LogCreateInput>>>;
	delete?: Maybe<Array<Maybe<LogDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<LogDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<LogUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<LogUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<LogUpsertUniqueInput>>>;
};

export type HouseNotificationsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<NotificationConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<NotificationCreateInput>>>;
};

export type HouseNotificationsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<NotificationConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<NotificationCreateInput>>>;
	delete?: Maybe<Array<Maybe<NotificationDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<NotificationDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<NotificationUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<NotificationUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<NotificationUpsertUniqueInput>>>;
};

export type HouseOrderByInput = {
	address?: Maybe<OrderByArg>;
	color?: Maybe<OrderByArg>;
	createdAt?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	isMoveOutHouse?: Maybe<OrderByArg>;
	lat?: Maybe<OrderByArg>;
	long?: Maybe<OrderByArg>;
	moveOutHouseId?: Maybe<OrderByArg>;
	organisationId?: Maybe<OrderByArg>;
	phone?: Maybe<OrderByArg>;
	preMoveInHouse?: Maybe<OrderByArg>;
	title?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
};

export type HouseOrganisationCreateRelationsInput = {
	connect?: Maybe<OrganisationWhereUniqueInput>;
	connectOrCreate?: Maybe<OrganisationConnectOrCreateInput>;
	create?: Maybe<OrganisationCreateInput>;
};

export type HouseOrganisationUpdateRelationsInput = {
	connect?: Maybe<OrganisationWhereUniqueInput>;
	connectOrCreate?: Maybe<OrganisationConnectOrCreateInput>;
	create?: Maybe<OrganisationCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<OrganisationUpdateInput>;
	upsert?: Maybe<OrganisationUpsertInput>;
};

export type HouseRoomsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<RoomWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<RoomConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<RoomCreateInput>>>;
};

export type HouseRoomsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<RoomWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<RoomConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<RoomCreateInput>>>;
	delete?: Maybe<Array<Maybe<RoomDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<RoomDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<RoomWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<RoomWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<RoomUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<RoomUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<RoomUpsertUniqueInput>>>;
};

export type HouseTasksCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TaskConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TaskCreateInput>>>;
};

export type HouseTasksUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TaskConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TaskCreateInput>>>;
	delete?: Maybe<Array<Maybe<TaskDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<TaskDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<TaskUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<TaskUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<TaskUpsertUniqueInput>>>;
};

export type HouseTimedEventsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TimedEventConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TimedEventCreateInput>>>;
};

export type HouseTimedEventsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TimedEventConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TimedEventCreateInput>>>;
	delete?: Maybe<Array<Maybe<TimedEventDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<TimedEventDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<TimedEventUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<TimedEventUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<TimedEventUpsertUniqueInput>>>;
};

export type HouseUpdateInput = {
	address?: Maybe<Scalars['String']>;
	color?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	isMoveOutHouse?: Maybe<Scalars['Boolean']>;
	lat?: Maybe<Scalars['String']>;
	logs?: Maybe<HouseLogsUpdateRelationsInput>;
	long?: Maybe<Scalars['String']>;
	moveOutHouseId?: Maybe<Scalars['String']>;
	notifications?: Maybe<HouseNotificationsUpdateRelationsInput>;
	organisation?: Maybe<HouseOrganisationUpdateRelationsInput>;
	organisationId?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	preMoveInHouse?: Maybe<Scalars['Boolean']>;
	rooms?: Maybe<HouseRoomsUpdateRelationsInput>;
	tasks?: Maybe<HouseTasksUpdateRelationsInput>;
	timedEvents?: Maybe<HouseTimedEventsUpdateRelationsInput>;
	title?: Maybe<Scalars['String']>;
	users?: Maybe<HouseUsersUpdateRelationsInput>;
	youngPeople?: Maybe<HouseYoungPeopleUpdateRelationsInput>;
};

export type HouseUpdateManyInput = {
	data: HouseUpdateInput;
	where: HouseWhereFilterInput;
};

export type HouseUpdateUniqueInput = {
	data: HouseUpdateInput;
	where: HouseWhereUniqueInput;
};

export type HouseUpsertInput = {
	create: HouseCreateInput;
	update: HouseUpdateInput;
};

export type HouseUpsertUniqueInput = {
	create: HouseCreateInput;
	update: HouseUpdateInput;
	where: HouseWhereUniqueInput;
};

export type HouseUsersCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<UserConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<UserCreateInput>>>;
};

export type HouseUsersUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<UserConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<UserCreateInput>>>;
	delete?: Maybe<Array<Maybe<UserDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<UserDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<UserUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<UserUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<UserUpsertUniqueInput>>>;
};

export type HouseWhereFilterInput = {
	AND?: Maybe<Array<Maybe<HouseWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<HouseWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<HouseWhereFilterInput>>>;
	address?: Maybe<StringFilterInput>;
	color?: Maybe<StringFilterInput>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	id?: Maybe<StringFilterInput>;
	isMoveOutHouse?: Maybe<BooleanFilterInput>;
	lat?: Maybe<StringFilterInput>;
	logs?: Maybe<LogFilterRelationsInput>;
	long?: Maybe<StringFilterInput>;
	moveOutHouseId?: Maybe<StringFilterInput>;
	notifications?: Maybe<NotificationFilterRelationsInput>;
	organisation?: Maybe<OrganisationWhereFilterInput>;
	organisationId?: Maybe<StringFilterInput>;
	phone?: Maybe<StringFilterInput>;
	preMoveInHouse?: Maybe<BooleanFilterInput>;
	rooms?: Maybe<RoomFilterRelationsInput>;
	tasks?: Maybe<TaskFilterRelationsInput>;
	timedEvents?: Maybe<TimedEventFilterRelationsInput>;
	title?: Maybe<StringFilterInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	users?: Maybe<UserFilterRelationsInput>;
	youngPeople?: Maybe<YoungPersonFilterRelationsInput>;
};

export type HouseWhereUniqueInput = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type HouseYoungPeopleCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<YoungPersonConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<YoungPersonCreateInput>>>;
};

export type HouseYoungPeopleUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<YoungPersonConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<YoungPersonCreateInput>>>;
	delete?: Maybe<Array<Maybe<YoungPersonDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<YoungPersonDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<YoungPersonUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<YoungPersonUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<YoungPersonUpsertUniqueInput>>>;
};

export type IntFilterInput = {
	equals?: Maybe<Scalars['Int']>;
	gt?: Maybe<Scalars['Int']>;
	gte?: Maybe<Scalars['Int']>;
	in?: Maybe<Array<Scalars['Int']>>;
	lt?: Maybe<Scalars['Int']>;
	lte?: Maybe<Scalars['Int']>;
	not?: Maybe<IntFilterInput>;
	notIn?: Maybe<Array<Scalars['Int']>>;
};

export type InviteUserInput = {
	email: Scalars['String'];
	firstName: Scalars['String'];
	houseId: Scalars['String'];
	lastName: Scalars['String'];
	organisationId: Scalars['String'];
};

export type InviteUserResponse = {
	__typename?: 'InviteUserResponse';
	email: Scalars['String'];
	statusCode?: Maybe<Scalars['Int']>;
};

export type ListFilesResponse = {
	__typename?: 'ListFilesResponse';
	count?: Maybe<Scalars['Int']>;
	files?: Maybe<Array<Maybe<File>>>;
	folders?: Maybe<Array<Maybe<Scalars['String']>>>;
	nextToken?: Maybe<Scalars['String']>;
};

export type ListHouseFilesInput = {
	folderKey?: Maybe<Scalars['String']>;
	houseId: Scalars['String'];
	nextToken?: Maybe<Scalars['String']>;
};

export type ListYoungPersonFilesInput = {
	folderKey?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	nextToken?: Maybe<Scalars['String']>;
	youngPersonId: Scalars['String'];
};

export type Log = {
	__typename?: 'Log';
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	createdBy: Scalars['String'];
	createdByUser?: Maybe<User>;
	data: Scalars['AWSJSON'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	house?: Maybe<House>;
	houseId?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	type: LogType;
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	youngPerson?: Maybe<YoungPerson>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type LogConnectOrCreateInput = {
	create: LogCreateInput;
	where: LogWhereUniqueInput;
};

export type LogCreateInput = {
	createdBy: Scalars['String'];
	createdByUser?: Maybe<LogCreatedByUserCreateRelationsInput>;
	data: Scalars['AWSJSON'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	house?: Maybe<LogHouseCreateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	type: LogType;
	youngPerson?: Maybe<LogYoungPersonCreateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type LogCreateManyInput = {
	createdBy: Scalars['String'];
	data: Scalars['AWSJSON'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	houseId?: Maybe<Scalars['String']>;
	type: LogType;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type LogCreatedByUserCreateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
};

export type LogCreatedByUserUpdateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<UserUpdateInput>;
	upsert?: Maybe<UserUpsertInput>;
};

export type LogDeleteManyInput = {
	where: LogWhereFilterInput;
};

export type LogDeleteUniqueInput = {
	where: LogWhereUniqueInput;
};

export type LogFilterRelationsInput = {
	every?: Maybe<LogWhereFilterInput>;
	none?: Maybe<LogWhereFilterInput>;
	some?: Maybe<LogWhereFilterInput>;
};

export type LogHouseCreateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
};

export type LogHouseUpdateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<HouseUpdateInput>;
	upsert?: Maybe<HouseUpsertInput>;
};

export type LogOrderByInput = {
	createdAt?: Maybe<OrderByArg>;
	createdBy?: Maybe<OrderByArg>;
	data?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	houseId?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	type?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
	youngPersonId?: Maybe<OrderByArg>;
};

export enum LogType {
	Activity = 'ACTIVITY',
	CoachingSession = 'COACHING_SESSION',
	Contact = 'CONTACT',
	Incident = 'INCIDENT',
	KidPayment = 'KID_PAYMENT',
	Location = 'LOCATION',
	MangerOnDuty = 'MANGER_ON_DUTY',
	Note = 'NOTE',
	RiskAssessment = 'RISK_ASSESSMENT',
	SpinSession = 'SPIN_SESSION',
}

export type LogTypeEnumFilterInput = {
	equals?: Maybe<LogType>;
	in?: Maybe<Array<LogType>>;
	not?: Maybe<LogTypeEnumFilterInput>;
	notIn?: Maybe<Array<LogType>>;
};

export type LogUpdateInput = {
	createdBy?: Maybe<Scalars['String']>;
	createdByUser?: Maybe<LogCreatedByUserUpdateRelationsInput>;
	data?: Maybe<Scalars['AWSJSON']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	house?: Maybe<LogHouseUpdateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	type?: Maybe<LogType>;
	youngPerson?: Maybe<LogYoungPersonUpdateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type LogUpdateManyInput = {
	data: LogUpdateInput;
	where: LogWhereFilterInput;
};

export type LogUpdateUniqueInput = {
	data: LogUpdateInput;
	where: LogWhereUniqueInput;
};

export type LogUpsertInput = {
	create: LogCreateInput;
	update: LogUpdateInput;
};

export type LogUpsertUniqueInput = {
	create: LogCreateInput;
	update: LogUpdateInput;
	where: LogWhereUniqueInput;
};

export type LogWhereFilterInput = {
	AND?: Maybe<Array<Maybe<LogWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<LogWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<LogWhereFilterInput>>>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	createdBy?: Maybe<StringFilterInput>;
	createdByUser?: Maybe<UserWhereFilterInput>;
	data?: Maybe<AwsjsonFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	house?: Maybe<HouseWhereFilterInput>;
	houseId?: Maybe<StringFilterInput>;
	id?: Maybe<StringFilterInput>;
	type?: Maybe<LogTypeEnumFilterInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	youngPerson?: Maybe<YoungPersonWhereFilterInput>;
	youngPersonId?: Maybe<StringFilterInput>;
};

export type LogWhereUniqueInput = {
	createdBy?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type LogYoungPersonCreateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
};

export type LogYoungPersonUpdateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<YoungPersonUpdateInput>;
	upsert?: Maybe<YoungPersonUpsertInput>;
};

export type MedicalCondition = {
	__typename?: 'MedicalCondition';
	administrationDetails?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	createdBy: Scalars['String'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	id: Scalars['String'];
	inEmergency?: Maybe<Scalars['String']>;
	medication?: Maybe<Scalars['String']>;
	symptoms?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	triggers?: Maybe<Scalars['String']>;
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	youngPerson?: Maybe<YoungPerson>;
	youngPersonId: Scalars['String'];
};

export type MedicalConditionConnectOrCreateInput = {
	create: MedicalConditionCreateInput;
	where: MedicalConditionWhereUniqueInput;
};

export type MedicalConditionCreateInput = {
	administrationDetails?: Maybe<Scalars['String']>;
	createdBy: Scalars['String'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	inEmergency?: Maybe<Scalars['String']>;
	medication?: Maybe<Scalars['String']>;
	symptoms?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	triggers?: Maybe<Scalars['String']>;
	youngPerson?: Maybe<MedicalConditionYoungPersonCreateRelationsInput>;
	youngPersonId: Scalars['String'];
};

export type MedicalConditionCreateManyInput = {
	administrationDetails?: Maybe<Scalars['String']>;
	createdBy: Scalars['String'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	inEmergency?: Maybe<Scalars['String']>;
	medication?: Maybe<Scalars['String']>;
	symptoms?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	triggers?: Maybe<Scalars['String']>;
	youngPersonId: Scalars['String'];
};

export type MedicalConditionDeleteManyInput = {
	where: MedicalConditionWhereFilterInput;
};

export type MedicalConditionDeleteUniqueInput = {
	where: MedicalConditionWhereUniqueInput;
};

export type MedicalConditionFilterRelationsInput = {
	every?: Maybe<MedicalConditionWhereFilterInput>;
	none?: Maybe<MedicalConditionWhereFilterInput>;
	some?: Maybe<MedicalConditionWhereFilterInput>;
};

export type MedicalConditionOrderByInput = {
	administrationDetails?: Maybe<OrderByArg>;
	createdAt?: Maybe<OrderByArg>;
	createdBy?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	inEmergency?: Maybe<OrderByArg>;
	medication?: Maybe<OrderByArg>;
	symptoms?: Maybe<OrderByArg>;
	title?: Maybe<OrderByArg>;
	triggers?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
	youngPersonId?: Maybe<OrderByArg>;
};

export type MedicalConditionUpdateInput = {
	administrationDetails?: Maybe<Scalars['String']>;
	createdBy?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	inEmergency?: Maybe<Scalars['String']>;
	medication?: Maybe<Scalars['String']>;
	symptoms?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	triggers?: Maybe<Scalars['String']>;
	youngPerson?: Maybe<MedicalConditionYoungPersonUpdateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type MedicalConditionUpdateManyInput = {
	data: MedicalConditionUpdateInput;
	where: MedicalConditionWhereFilterInput;
};

export type MedicalConditionUpdateUniqueInput = {
	data: MedicalConditionUpdateInput;
	where: MedicalConditionWhereUniqueInput;
};

export type MedicalConditionUpsertInput = {
	create: MedicalConditionCreateInput;
	update: MedicalConditionUpdateInput;
};

export type MedicalConditionUpsertUniqueInput = {
	create: MedicalConditionCreateInput;
	update: MedicalConditionUpdateInput;
	where: MedicalConditionWhereUniqueInput;
};

export type MedicalConditionWhereFilterInput = {
	AND?: Maybe<Array<Maybe<MedicalConditionWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<MedicalConditionWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<MedicalConditionWhereFilterInput>>>;
	administrationDetails?: Maybe<StringFilterInput>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	createdBy?: Maybe<StringFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	id?: Maybe<StringFilterInput>;
	inEmergency?: Maybe<StringFilterInput>;
	medication?: Maybe<StringFilterInput>;
	symptoms?: Maybe<StringFilterInput>;
	title?: Maybe<StringFilterInput>;
	triggers?: Maybe<StringFilterInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	youngPerson?: Maybe<YoungPersonWhereFilterInput>;
	youngPersonId?: Maybe<StringFilterInput>;
};

export type MedicalConditionWhereUniqueInput = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type MedicalConditionYoungPersonCreateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
};

export type MedicalConditionYoungPersonUpdateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<YoungPersonUpdateInput>;
	upsert?: Maybe<YoungPersonUpsertInput>;
};

export type Mutation = {
	__typename?: 'Mutation';
	createDownloadUrl?: Maybe<UrlResponse>;
	createFileUploadUrl?: Maybe<UrlResponse>;
	createHouse?: Maybe<House>;
	createLog?: Maybe<Log>;
	createManyHouses?: Maybe<BatchPayload>;
	createManyLogs?: Maybe<BatchPayload>;
	createManyMedicalConditions?: Maybe<BatchPayload>;
	createManyNextOfKins?: Maybe<BatchPayload>;
	createManyNotifications?: Maybe<BatchPayload>;
	createManyOccupancyLogs?: Maybe<BatchPayload>;
	createManyOrganisations?: Maybe<BatchPayload>;
	createManyRooms?: Maybe<BatchPayload>;
	createManyTasks?: Maybe<BatchPayload>;
	createManyTimedEvents?: Maybe<BatchPayload>;
	createManyUsers?: Maybe<BatchPayload>;
	createManyYoungPeople?: Maybe<BatchPayload>;
	createManyYoungPersonLocationStatuses?: Maybe<BatchPayload>;
	createMedicalCondition?: Maybe<MedicalCondition>;
	createNextOfKin?: Maybe<NextOfKin>;
	createNotification?: Maybe<Notification>;
	createOccupancyLog?: Maybe<OccupancyLog>;
	createOrganisation?: Maybe<Organisation>;
	createRoom?: Maybe<Room>;
	createTask?: Maybe<Task>;
	createTimedEvent?: Maybe<TimedEvent>;
	createUploadAvatarUrl?: Maybe<UrlResponse>;
	createUser?: Maybe<User>;
	createYoungPerson?: Maybe<YoungPerson>;
	createYoungPersonLocationStatus?: Maybe<YoungPersonLocationStatus>;
	deleteFile?: Maybe<UrlResponse>;
	deleteHouse?: Maybe<House>;
	deleteLog?: Maybe<Log>;
	deleteManyHouses?: Maybe<BatchPayload>;
	deleteManyLogs?: Maybe<BatchPayload>;
	deleteManyMedicalConditions?: Maybe<BatchPayload>;
	deleteManyNextOfKins?: Maybe<BatchPayload>;
	deleteManyNotifications?: Maybe<BatchPayload>;
	deleteManyOccupancyLogs?: Maybe<BatchPayload>;
	deleteManyOrganisations?: Maybe<BatchPayload>;
	deleteManyRooms?: Maybe<BatchPayload>;
	deleteManyTasks?: Maybe<BatchPayload>;
	deleteManyTimedEvents?: Maybe<BatchPayload>;
	deleteManyUsers?: Maybe<BatchPayload>;
	deleteManyYoungPeople?: Maybe<BatchPayload>;
	deleteManyYoungPersonLocationStatuses?: Maybe<BatchPayload>;
	deleteMedicalCondition?: Maybe<MedicalCondition>;
	deleteNextOfKin?: Maybe<NextOfKin>;
	deleteNotification?: Maybe<Notification>;
	deleteOccupancyLog?: Maybe<OccupancyLog>;
	deleteOrganisation?: Maybe<Organisation>;
	deleteRoom?: Maybe<Room>;
	deleteTask?: Maybe<Task>;
	deleteTimedEvent?: Maybe<TimedEvent>;
	deleteUser?: Maybe<User>;
	deleteYoungPerson?: Maybe<YoungPerson>;
	deleteYoungPersonLocationStatus?: Maybe<YoungPersonLocationStatus>;
	inviteUser?: Maybe<InviteUserResponse>;
	updateHouse?: Maybe<House>;
	updateLog?: Maybe<Log>;
	updateManyHouses?: Maybe<BatchPayload>;
	updateManyLogs?: Maybe<BatchPayload>;
	updateManyMedicalConditions?: Maybe<BatchPayload>;
	updateManyNextOfKins?: Maybe<BatchPayload>;
	updateManyNotifications?: Maybe<BatchPayload>;
	updateManyOccupancyLogs?: Maybe<BatchPayload>;
	updateManyOrganisations?: Maybe<BatchPayload>;
	updateManyRooms?: Maybe<BatchPayload>;
	updateManyTasks?: Maybe<BatchPayload>;
	updateManyTimedEvents?: Maybe<BatchPayload>;
	updateManyUsers?: Maybe<BatchPayload>;
	updateManyYoungPeople?: Maybe<BatchPayload>;
	updateManyYoungPersonLocationStatuses?: Maybe<BatchPayload>;
	updateMedicalCondition?: Maybe<MedicalCondition>;
	updateNextOfKin?: Maybe<NextOfKin>;
	updateNotification?: Maybe<Notification>;
	updateOccupancyLog?: Maybe<OccupancyLog>;
	updateOrganisation?: Maybe<Organisation>;
	updateRoom?: Maybe<Room>;
	updateTask?: Maybe<Task>;
	updateTimedEvent?: Maybe<TimedEvent>;
	updateUser?: Maybe<User>;
	updateYoungPerson?: Maybe<YoungPerson>;
	updateYoungPersonLocationStatus?: Maybe<YoungPersonLocationStatus>;
	upsertHouse?: Maybe<House>;
	upsertLog?: Maybe<Log>;
	upsertMedicalCondition?: Maybe<MedicalCondition>;
	upsertNextOfKin?: Maybe<NextOfKin>;
	upsertNotification?: Maybe<Notification>;
	upsertOccupancyLog?: Maybe<OccupancyLog>;
	upsertOrganisation?: Maybe<Organisation>;
	upsertRoom?: Maybe<Room>;
	upsertTask?: Maybe<Task>;
	upsertTimedEvent?: Maybe<TimedEvent>;
	upsertUser?: Maybe<User>;
	upsertYoungPerson?: Maybe<YoungPerson>;
	upsertYoungPersonLocationStatus?: Maybe<YoungPersonLocationStatus>;
};

export type MutationCreateDownloadUrlArgs = {
	fileKey: Scalars['String'];
};

export type MutationCreateFileUploadUrlArgs = {
	input: UploadFileUrlInput;
};

export type MutationCreateHouseArgs = {
	data: HouseCreateInput;
};

export type MutationCreateLogArgs = {
	data: LogCreateInput;
};

export type MutationCreateManyHousesArgs = {
	data?: Maybe<Array<HouseCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyLogsArgs = {
	data?: Maybe<Array<LogCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyMedicalConditionsArgs = {
	data?: Maybe<Array<MedicalConditionCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyNextOfKinsArgs = {
	data?: Maybe<Array<NextOfKinCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyNotificationsArgs = {
	data?: Maybe<Array<NotificationCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyOccupancyLogsArgs = {
	data?: Maybe<Array<OccupancyLogCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyOrganisationsArgs = {
	data?: Maybe<Array<OrganisationCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyRoomsArgs = {
	data?: Maybe<Array<RoomCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyTasksArgs = {
	data?: Maybe<Array<TaskCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyTimedEventsArgs = {
	data?: Maybe<Array<TimedEventCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyUsersArgs = {
	data?: Maybe<Array<UserCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyYoungPeopleArgs = {
	data?: Maybe<Array<YoungPersonCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateManyYoungPersonLocationStatusesArgs = {
	data?: Maybe<Array<YoungPersonLocationStatusCreateManyInput>>;
	skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type MutationCreateMedicalConditionArgs = {
	data: MedicalConditionCreateInput;
};

export type MutationCreateNextOfKinArgs = {
	data: NextOfKinCreateInput;
};

export type MutationCreateNotificationArgs = {
	data: NotificationCreateInput;
};

export type MutationCreateOccupancyLogArgs = {
	data: OccupancyLogCreateInput;
};

export type MutationCreateOrganisationArgs = {
	data: OrganisationCreateInput;
};

export type MutationCreateRoomArgs = {
	data: RoomCreateInput;
};

export type MutationCreateTaskArgs = {
	data: TaskCreateInput;
};

export type MutationCreateTimedEventArgs = {
	data: TimedEventCreateInput;
};

export type MutationCreateUploadAvatarUrlArgs = {
	input?: Maybe<CreateUploadAvatarUrlInput>;
};

export type MutationCreateUserArgs = {
	data: UserCreateInput;
};

export type MutationCreateYoungPersonArgs = {
	data: YoungPersonCreateInput;
};

export type MutationCreateYoungPersonLocationStatusArgs = {
	data: YoungPersonLocationStatusCreateInput;
};

export type MutationDeleteFileArgs = {
	input: DeleteFileInput;
};

export type MutationDeleteHouseArgs = {
	where: HouseWhereUniqueInput;
};

export type MutationDeleteLogArgs = {
	where: LogWhereUniqueInput;
};

export type MutationDeleteManyHousesArgs = {
	where: HouseWhereFilterInput;
};

export type MutationDeleteManyLogsArgs = {
	where: LogWhereFilterInput;
};

export type MutationDeleteManyMedicalConditionsArgs = {
	where: MedicalConditionWhereFilterInput;
};

export type MutationDeleteManyNextOfKinsArgs = {
	where: NextOfKinWhereFilterInput;
};

export type MutationDeleteManyNotificationsArgs = {
	where: NotificationWhereFilterInput;
};

export type MutationDeleteManyOccupancyLogsArgs = {
	where: OccupancyLogWhereFilterInput;
};

export type MutationDeleteManyOrganisationsArgs = {
	where: OrganisationWhereFilterInput;
};

export type MutationDeleteManyRoomsArgs = {
	where: RoomWhereFilterInput;
};

export type MutationDeleteManyTasksArgs = {
	where: TaskWhereFilterInput;
};

export type MutationDeleteManyTimedEventsArgs = {
	where: TimedEventWhereFilterInput;
};

export type MutationDeleteManyUsersArgs = {
	where: UserWhereFilterInput;
};

export type MutationDeleteManyYoungPeopleArgs = {
	where: YoungPersonWhereFilterInput;
};

export type MutationDeleteManyYoungPersonLocationStatusesArgs = {
	where: YoungPersonLocationStatusWhereFilterInput;
};

export type MutationDeleteMedicalConditionArgs = {
	where: MedicalConditionWhereUniqueInput;
};

export type MutationDeleteNextOfKinArgs = {
	where: NextOfKinWhereUniqueInput;
};

export type MutationDeleteNotificationArgs = {
	where: NotificationWhereUniqueInput;
};

export type MutationDeleteOccupancyLogArgs = {
	where: OccupancyLogWhereUniqueInput;
};

export type MutationDeleteOrganisationArgs = {
	where: OrganisationWhereUniqueInput;
};

export type MutationDeleteRoomArgs = {
	where: RoomWhereUniqueInput;
};

export type MutationDeleteTaskArgs = {
	where: TaskWhereUniqueInput;
};

export type MutationDeleteTimedEventArgs = {
	where: TimedEventWhereUniqueInput;
};

export type MutationDeleteUserArgs = {
	where: UserWhereUniqueInput;
};

export type MutationDeleteYoungPersonArgs = {
	where: YoungPersonWhereUniqueInput;
};

export type MutationDeleteYoungPersonLocationStatusArgs = {
	where: YoungPersonLocationStatusWhereUniqueInput;
};

export type MutationInviteUserArgs = {
	input?: Maybe<InviteUserInput>;
};

export type MutationUpdateHouseArgs = {
	data: HouseUpdateInput;
	where: HouseWhereUniqueInput;
};

export type MutationUpdateLogArgs = {
	data: LogUpdateInput;
	where: LogWhereUniqueInput;
};

export type MutationUpdateManyHousesArgs = {
	data: HouseUpdateInput;
	where: HouseWhereFilterInput;
};

export type MutationUpdateManyLogsArgs = {
	data: LogUpdateInput;
	where: LogWhereFilterInput;
};

export type MutationUpdateManyMedicalConditionsArgs = {
	data: MedicalConditionUpdateInput;
	where: MedicalConditionWhereFilterInput;
};

export type MutationUpdateManyNextOfKinsArgs = {
	data: NextOfKinUpdateInput;
	where: NextOfKinWhereFilterInput;
};

export type MutationUpdateManyNotificationsArgs = {
	data: NotificationUpdateInput;
	where: NotificationWhereFilterInput;
};

export type MutationUpdateManyOccupancyLogsArgs = {
	data: OccupancyLogUpdateInput;
	where: OccupancyLogWhereFilterInput;
};

export type MutationUpdateManyOrganisationsArgs = {
	data: OrganisationUpdateInput;
	where: OrganisationWhereFilterInput;
};

export type MutationUpdateManyRoomsArgs = {
	data: RoomUpdateInput;
	where: RoomWhereFilterInput;
};

export type MutationUpdateManyTasksArgs = {
	data: TaskUpdateInput;
	where: TaskWhereFilterInput;
};

export type MutationUpdateManyTimedEventsArgs = {
	data: TimedEventUpdateInput;
	where: TimedEventWhereFilterInput;
};

export type MutationUpdateManyUsersArgs = {
	data: UserUpdateInput;
	where: UserWhereFilterInput;
};

export type MutationUpdateManyYoungPeopleArgs = {
	data: YoungPersonUpdateInput;
	where: YoungPersonWhereFilterInput;
};

export type MutationUpdateManyYoungPersonLocationStatusesArgs = {
	data: YoungPersonLocationStatusUpdateInput;
	where: YoungPersonLocationStatusWhereFilterInput;
};

export type MutationUpdateMedicalConditionArgs = {
	data: MedicalConditionUpdateInput;
	where: MedicalConditionWhereUniqueInput;
};

export type MutationUpdateNextOfKinArgs = {
	data: NextOfKinUpdateInput;
	where: NextOfKinWhereUniqueInput;
};

export type MutationUpdateNotificationArgs = {
	data: NotificationUpdateInput;
	where: NotificationWhereUniqueInput;
};

export type MutationUpdateOccupancyLogArgs = {
	data: OccupancyLogUpdateInput;
	where: OccupancyLogWhereUniqueInput;
};

export type MutationUpdateOrganisationArgs = {
	data: OrganisationUpdateInput;
	where: OrganisationWhereUniqueInput;
};

export type MutationUpdateRoomArgs = {
	data: RoomUpdateInput;
	where: RoomWhereUniqueInput;
};

export type MutationUpdateTaskArgs = {
	data: TaskUpdateInput;
	where: TaskWhereUniqueInput;
};

export type MutationUpdateTimedEventArgs = {
	data: TimedEventUpdateInput;
	where: TimedEventWhereUniqueInput;
};

export type MutationUpdateUserArgs = {
	data: UserUpdateInput;
	where: UserWhereUniqueInput;
};

export type MutationUpdateYoungPersonArgs = {
	data: YoungPersonUpdateInput;
	where: YoungPersonWhereUniqueInput;
};

export type MutationUpdateYoungPersonLocationStatusArgs = {
	data: YoungPersonLocationStatusUpdateInput;
	where: YoungPersonLocationStatusWhereUniqueInput;
};

export type MutationUpsertHouseArgs = {
	data: HouseUpdateInput;
	where: HouseWhereUniqueInput;
};

export type MutationUpsertLogArgs = {
	data: LogUpdateInput;
	where: LogWhereUniqueInput;
};

export type MutationUpsertMedicalConditionArgs = {
	data: MedicalConditionUpdateInput;
	where: MedicalConditionWhereUniqueInput;
};

export type MutationUpsertNextOfKinArgs = {
	data: NextOfKinUpdateInput;
	where: NextOfKinWhereUniqueInput;
};

export type MutationUpsertNotificationArgs = {
	data: NotificationUpdateInput;
	where: NotificationWhereUniqueInput;
};

export type MutationUpsertOccupancyLogArgs = {
	data: OccupancyLogUpdateInput;
	where: OccupancyLogWhereUniqueInput;
};

export type MutationUpsertOrganisationArgs = {
	data: OrganisationUpdateInput;
	where: OrganisationWhereUniqueInput;
};

export type MutationUpsertRoomArgs = {
	data: RoomUpdateInput;
	where: RoomWhereUniqueInput;
};

export type MutationUpsertTaskArgs = {
	data: TaskUpdateInput;
	where: TaskWhereUniqueInput;
};

export type MutationUpsertTimedEventArgs = {
	data: TimedEventUpdateInput;
	where: TimedEventWhereUniqueInput;
};

export type MutationUpsertUserArgs = {
	data: UserUpdateInput;
	where: UserWhereUniqueInput;
};

export type MutationUpsertYoungPersonArgs = {
	data: YoungPersonUpdateInput;
	where: YoungPersonWhereUniqueInput;
};

export type MutationUpsertYoungPersonLocationStatusArgs = {
	data: YoungPersonLocationStatusUpdateInput;
	where: YoungPersonLocationStatusWhereUniqueInput;
};

export type NextOfKin = {
	__typename?: 'NextOfKin';
	contactable?: Maybe<Scalars['Boolean']>;
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	createdBy: Scalars['String'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	id: Scalars['String'];
	name: Scalars['String'];
	note?: Maybe<Scalars['String']>;
	phone: Scalars['String'];
	relation: Scalars['String'];
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	visitable?: Maybe<Scalars['Boolean']>;
	youngPerson?: Maybe<YoungPerson>;
	youngPersonId: Scalars['String'];
};

export type NextOfKinConnectOrCreateInput = {
	create: NextOfKinCreateInput;
	where: NextOfKinWhereUniqueInput;
};

export type NextOfKinCreateInput = {
	contactable?: Maybe<Scalars['Boolean']>;
	createdBy: Scalars['String'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	name: Scalars['String'];
	note?: Maybe<Scalars['String']>;
	phone: Scalars['String'];
	relation: Scalars['String'];
	visitable?: Maybe<Scalars['Boolean']>;
	youngPerson?: Maybe<NextOfKinYoungPersonCreateRelationsInput>;
	youngPersonId: Scalars['String'];
};

export type NextOfKinCreateManyInput = {
	contactable?: Maybe<Scalars['Boolean']>;
	createdBy: Scalars['String'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	name: Scalars['String'];
	note?: Maybe<Scalars['String']>;
	phone: Scalars['String'];
	relation: Scalars['String'];
	visitable?: Maybe<Scalars['Boolean']>;
	youngPersonId: Scalars['String'];
};

export type NextOfKinDeleteManyInput = {
	where: NextOfKinWhereFilterInput;
};

export type NextOfKinDeleteUniqueInput = {
	where: NextOfKinWhereUniqueInput;
};

export type NextOfKinFilterRelationsInput = {
	every?: Maybe<NextOfKinWhereFilterInput>;
	none?: Maybe<NextOfKinWhereFilterInput>;
	some?: Maybe<NextOfKinWhereFilterInput>;
};

export type NextOfKinOrderByInput = {
	contactable?: Maybe<OrderByArg>;
	createdAt?: Maybe<OrderByArg>;
	createdBy?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	name?: Maybe<OrderByArg>;
	note?: Maybe<OrderByArg>;
	phone?: Maybe<OrderByArg>;
	relation?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
	visitable?: Maybe<OrderByArg>;
	youngPersonId?: Maybe<OrderByArg>;
};

export type NextOfKinUpdateInput = {
	contactable?: Maybe<Scalars['Boolean']>;
	createdBy?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	name?: Maybe<Scalars['String']>;
	note?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	relation?: Maybe<Scalars['String']>;
	visitable?: Maybe<Scalars['Boolean']>;
	youngPerson?: Maybe<NextOfKinYoungPersonUpdateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type NextOfKinUpdateManyInput = {
	data: NextOfKinUpdateInput;
	where: NextOfKinWhereFilterInput;
};

export type NextOfKinUpdateUniqueInput = {
	data: NextOfKinUpdateInput;
	where: NextOfKinWhereUniqueInput;
};

export type NextOfKinUpsertInput = {
	create: NextOfKinCreateInput;
	update: NextOfKinUpdateInput;
};

export type NextOfKinUpsertUniqueInput = {
	create: NextOfKinCreateInput;
	update: NextOfKinUpdateInput;
	where: NextOfKinWhereUniqueInput;
};

export type NextOfKinWhereFilterInput = {
	AND?: Maybe<Array<Maybe<NextOfKinWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<NextOfKinWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<NextOfKinWhereFilterInput>>>;
	contactable?: Maybe<BooleanFilterInput>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	createdBy?: Maybe<StringFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	id?: Maybe<StringFilterInput>;
	name?: Maybe<StringFilterInput>;
	note?: Maybe<StringFilterInput>;
	phone?: Maybe<StringFilterInput>;
	relation?: Maybe<StringFilterInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	visitable?: Maybe<BooleanFilterInput>;
	youngPerson?: Maybe<YoungPersonWhereFilterInput>;
	youngPersonId?: Maybe<StringFilterInput>;
};

export type NextOfKinWhereUniqueInput = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type NextOfKinYoungPersonCreateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
};

export type NextOfKinYoungPersonUpdateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<YoungPersonUpdateInput>;
	upsert?: Maybe<YoungPersonUpsertInput>;
};

export type Notification = {
	__typename?: 'Notification';
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	description?: Maybe<Scalars['String']>;
	house?: Maybe<House>;
	houseId?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	link?: Maybe<Scalars['String']>;
	readAt?: Maybe<Scalars['AWSDateTime']>;
	ref?: Maybe<Scalars['String']>;
	severity?: Maybe<SeverityLevel>;
	task?: Maybe<Task>;
	taskId?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	user?: Maybe<User>;
	userId?: Maybe<Scalars['String']>;
	youngPerson?: Maybe<YoungPerson>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type NotificationConnectOrCreateInput = {
	create: NotificationCreateInput;
	where: NotificationWhereUniqueInput;
};

export type NotificationCreateInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	description?: Maybe<Scalars['String']>;
	house?: Maybe<NotificationHouseCreateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	link?: Maybe<Scalars['String']>;
	readAt?: Maybe<Scalars['AWSDateTime']>;
	ref?: Maybe<Scalars['String']>;
	severity?: Maybe<SeverityLevel>;
	task?: Maybe<NotificationTaskCreateRelationsInput>;
	taskId?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	user?: Maybe<NotificationUserCreateRelationsInput>;
	userId?: Maybe<Scalars['String']>;
	youngPerson?: Maybe<NotificationYoungPersonCreateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type NotificationCreateManyInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	description?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	link?: Maybe<Scalars['String']>;
	readAt?: Maybe<Scalars['AWSDateTime']>;
	ref?: Maybe<Scalars['String']>;
	severity?: Maybe<SeverityLevel>;
	taskId?: Maybe<Scalars['String']>;
	title: Scalars['String'];
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type NotificationDeleteManyInput = {
	where: NotificationWhereFilterInput;
};

export type NotificationDeleteUniqueInput = {
	where: NotificationWhereUniqueInput;
};

export type NotificationFilterRelationsInput = {
	every?: Maybe<NotificationWhereFilterInput>;
	none?: Maybe<NotificationWhereFilterInput>;
	some?: Maybe<NotificationWhereFilterInput>;
};

export type NotificationHouseCreateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
};

export type NotificationHouseUpdateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<HouseUpdateInput>;
	upsert?: Maybe<HouseUpsertInput>;
};

export type NotificationOrderByInput = {
	createdAt?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	description?: Maybe<OrderByArg>;
	houseId?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	link?: Maybe<OrderByArg>;
	readAt?: Maybe<OrderByArg>;
	ref?: Maybe<OrderByArg>;
	severity?: Maybe<OrderByArg>;
	taskId?: Maybe<OrderByArg>;
	title?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
	userId?: Maybe<OrderByArg>;
	youngPersonId?: Maybe<OrderByArg>;
};

export type NotificationTaskCreateRelationsInput = {
	connect?: Maybe<TaskWhereUniqueInput>;
	connectOrCreate?: Maybe<TaskConnectOrCreateInput>;
	create?: Maybe<TaskCreateInput>;
};

export type NotificationTaskUpdateRelationsInput = {
	connect?: Maybe<TaskWhereUniqueInput>;
	connectOrCreate?: Maybe<TaskConnectOrCreateInput>;
	create?: Maybe<TaskCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<TaskUpdateInput>;
	upsert?: Maybe<TaskUpsertInput>;
};

export type NotificationUpdateInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	description?: Maybe<Scalars['String']>;
	house?: Maybe<NotificationHouseUpdateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	link?: Maybe<Scalars['String']>;
	readAt?: Maybe<Scalars['AWSDateTime']>;
	ref?: Maybe<Scalars['String']>;
	severity?: Maybe<SeverityLevel>;
	task?: Maybe<NotificationTaskUpdateRelationsInput>;
	taskId?: Maybe<Scalars['String']>;
	title?: Maybe<Scalars['String']>;
	user?: Maybe<NotificationUserUpdateRelationsInput>;
	userId?: Maybe<Scalars['String']>;
	youngPerson?: Maybe<NotificationYoungPersonUpdateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type NotificationUpdateManyInput = {
	data: NotificationUpdateInput;
	where: NotificationWhereFilterInput;
};

export type NotificationUpdateUniqueInput = {
	data: NotificationUpdateInput;
	where: NotificationWhereUniqueInput;
};

export type NotificationUpsertInput = {
	create: NotificationCreateInput;
	update: NotificationUpdateInput;
};

export type NotificationUpsertUniqueInput = {
	create: NotificationCreateInput;
	update: NotificationUpdateInput;
	where: NotificationWhereUniqueInput;
};

export type NotificationUserCreateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
};

export type NotificationUserUpdateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<UserUpdateInput>;
	upsert?: Maybe<UserUpsertInput>;
};

export type NotificationWhereFilterInput = {
	AND?: Maybe<Array<Maybe<NotificationWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<NotificationWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<NotificationWhereFilterInput>>>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	description?: Maybe<StringFilterInput>;
	house?: Maybe<HouseWhereFilterInput>;
	houseId?: Maybe<StringFilterInput>;
	id?: Maybe<StringFilterInput>;
	link?: Maybe<StringFilterInput>;
	readAt?: Maybe<AwsDateTimeFilterInput>;
	ref?: Maybe<StringFilterInput>;
	severity?: Maybe<SeverityLevelEnumFilterInput>;
	task?: Maybe<TaskWhereFilterInput>;
	taskId?: Maybe<StringFilterInput>;
	title?: Maybe<StringFilterInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	user?: Maybe<UserWhereFilterInput>;
	userId?: Maybe<StringFilterInput>;
	youngPerson?: Maybe<YoungPersonWhereFilterInput>;
	youngPersonId?: Maybe<StringFilterInput>;
};

export type NotificationWhereUniqueInput = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	taskId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type NotificationYoungPersonCreateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
};

export type NotificationYoungPersonUpdateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<YoungPersonUpdateInput>;
	upsert?: Maybe<YoungPersonUpsertInput>;
};

export type OccupancyLog = {
	__typename?: 'OccupancyLog';
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	endDate?: Maybe<Scalars['AWSDateTime']>;
	id: Scalars['String'];
	room?: Maybe<Room>;
	roomId: Scalars['String'];
	startDate?: Maybe<Scalars['AWSDateTime']>;
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	youngPerson?: Maybe<YoungPerson>;
	youngPersonId: Scalars['String'];
};

export type OccupancyLogConnectOrCreateInput = {
	create: OccupancyLogCreateInput;
	where: OccupancyLogWhereUniqueInput;
};

export type OccupancyLogCreateInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	endDate?: Maybe<Scalars['AWSDateTime']>;
	room?: Maybe<OccupancyLogRoomCreateRelationsInput>;
	roomId: Scalars['String'];
	startDate?: Maybe<Scalars['AWSDateTime']>;
	youngPerson?: Maybe<OccupancyLogYoungPersonCreateRelationsInput>;
	youngPersonId: Scalars['String'];
};

export type OccupancyLogCreateManyInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	endDate?: Maybe<Scalars['AWSDateTime']>;
	roomId: Scalars['String'];
	startDate?: Maybe<Scalars['AWSDateTime']>;
	youngPersonId: Scalars['String'];
};

export type OccupancyLogDeleteManyInput = {
	where: OccupancyLogWhereFilterInput;
};

export type OccupancyLogDeleteUniqueInput = {
	where: OccupancyLogWhereUniqueInput;
};

export type OccupancyLogFilterRelationsInput = {
	every?: Maybe<OccupancyLogWhereFilterInput>;
	none?: Maybe<OccupancyLogWhereFilterInput>;
	some?: Maybe<OccupancyLogWhereFilterInput>;
};

export type OccupancyLogOrderByInput = {
	createdAt?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	endDate?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	roomId?: Maybe<OrderByArg>;
	startDate?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
	youngPersonId?: Maybe<OrderByArg>;
};

export type OccupancyLogRoomCreateRelationsInput = {
	connect?: Maybe<RoomWhereUniqueInput>;
	connectOrCreate?: Maybe<RoomConnectOrCreateInput>;
	create?: Maybe<RoomCreateInput>;
};

export type OccupancyLogRoomUpdateRelationsInput = {
	connect?: Maybe<RoomWhereUniqueInput>;
	connectOrCreate?: Maybe<RoomConnectOrCreateInput>;
	create?: Maybe<RoomCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<RoomUpdateInput>;
	upsert?: Maybe<RoomUpsertInput>;
};

export type OccupancyLogUpdateInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	endDate?: Maybe<Scalars['AWSDateTime']>;
	room?: Maybe<OccupancyLogRoomUpdateRelationsInput>;
	roomId?: Maybe<Scalars['String']>;
	startDate?: Maybe<Scalars['AWSDateTime']>;
	youngPerson?: Maybe<OccupancyLogYoungPersonUpdateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type OccupancyLogUpdateManyInput = {
	data: OccupancyLogUpdateInput;
	where: OccupancyLogWhereFilterInput;
};

export type OccupancyLogUpdateUniqueInput = {
	data: OccupancyLogUpdateInput;
	where: OccupancyLogWhereUniqueInput;
};

export type OccupancyLogUpsertInput = {
	create: OccupancyLogCreateInput;
	update: OccupancyLogUpdateInput;
};

export type OccupancyLogUpsertUniqueInput = {
	create: OccupancyLogCreateInput;
	update: OccupancyLogUpdateInput;
	where: OccupancyLogWhereUniqueInput;
};

export type OccupancyLogWhereFilterInput = {
	AND?: Maybe<Array<Maybe<OccupancyLogWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<OccupancyLogWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<OccupancyLogWhereFilterInput>>>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	endDate?: Maybe<AwsDateTimeFilterInput>;
	id?: Maybe<StringFilterInput>;
	room?: Maybe<RoomWhereFilterInput>;
	roomId?: Maybe<StringFilterInput>;
	startDate?: Maybe<AwsDateTimeFilterInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	youngPerson?: Maybe<YoungPersonWhereFilterInput>;
	youngPersonId?: Maybe<StringFilterInput>;
};

export type OccupancyLogWhereUniqueInput = {
	id?: Maybe<Scalars['String']>;
	roomId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type OccupancyLogYoungPersonCreateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
};

export type OccupancyLogYoungPersonUpdateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<YoungPersonUpdateInput>;
	upsert?: Maybe<YoungPersonUpsertInput>;
};

export enum OrderByArg {
	Asc = 'ASC',
	Desc = 'DESC',
}

export type Organisation = {
	__typename?: 'Organisation';
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	houses?: Maybe<Array<House>>;
	id: Scalars['String'];
	name: Scalars['String'];
	slug: Scalars['String'];
	timedEvents?: Maybe<Array<TimedEvent>>;
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	users?: Maybe<Array<User>>;
	youngPersonLocationStatuses?: Maybe<Array<YoungPersonLocationStatus>>;
};

export type OrganisationConnectOrCreateInput = {
	create: OrganisationCreateInput;
	where: OrganisationWhereUniqueInput;
};

export type OrganisationCreateInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	houses?: Maybe<OrganisationHousesCreateRelationsInput>;
	name: Scalars['String'];
	slug: Scalars['String'];
	timedEvents?: Maybe<OrganisationTimedEventsCreateRelationsInput>;
	users?: Maybe<OrganisationUsersCreateRelationsInput>;
	youngPersonLocationStatuses?: Maybe<OrganisationYoungPersonLocationStatusesCreateRelationsInput>;
};

export type OrganisationCreateManyInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	name: Scalars['String'];
	slug: Scalars['String'];
};

export type OrganisationDeleteManyInput = {
	where: OrganisationWhereFilterInput;
};

export type OrganisationDeleteUniqueInput = {
	where: OrganisationWhereUniqueInput;
};

export type OrganisationFilterRelationsInput = {
	every?: Maybe<OrganisationWhereFilterInput>;
	none?: Maybe<OrganisationWhereFilterInput>;
	some?: Maybe<OrganisationWhereFilterInput>;
};

export type OrganisationHousesCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<HouseWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<HouseConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<HouseCreateInput>>>;
};

export type OrganisationHousesUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<HouseWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<HouseConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<HouseCreateInput>>>;
	delete?: Maybe<Array<Maybe<HouseDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<HouseDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<HouseWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<HouseWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<HouseUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<HouseUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<HouseUpsertUniqueInput>>>;
};

export type OrganisationOrderByInput = {
	createdAt?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	name?: Maybe<OrderByArg>;
	slug?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
};

export type OrganisationTimedEventsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TimedEventConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TimedEventCreateInput>>>;
};

export type OrganisationTimedEventsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TimedEventConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TimedEventCreateInput>>>;
	delete?: Maybe<Array<Maybe<TimedEventDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<TimedEventDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<TimedEventUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<TimedEventUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<TimedEventUpsertUniqueInput>>>;
};

export type OrganisationUpdateInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	houses?: Maybe<OrganisationHousesUpdateRelationsInput>;
	name?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
	timedEvents?: Maybe<OrganisationTimedEventsUpdateRelationsInput>;
	users?: Maybe<OrganisationUsersUpdateRelationsInput>;
	youngPersonLocationStatuses?: Maybe<OrganisationYoungPersonLocationStatusesUpdateRelationsInput>;
};

export type OrganisationUpdateManyInput = {
	data: OrganisationUpdateInput;
	where: OrganisationWhereFilterInput;
};

export type OrganisationUpdateUniqueInput = {
	data: OrganisationUpdateInput;
	where: OrganisationWhereUniqueInput;
};

export type OrganisationUpsertInput = {
	create: OrganisationCreateInput;
	update: OrganisationUpdateInput;
};

export type OrganisationUpsertUniqueInput = {
	create: OrganisationCreateInput;
	update: OrganisationUpdateInput;
	where: OrganisationWhereUniqueInput;
};

export type OrganisationUsersCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<UserConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<UserCreateInput>>>;
};

export type OrganisationUsersUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<UserConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<UserCreateInput>>>;
	delete?: Maybe<Array<Maybe<UserDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<UserDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<UserWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<UserUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<UserUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<UserUpsertUniqueInput>>>;
};

export type OrganisationWhereFilterInput = {
	AND?: Maybe<Array<Maybe<OrganisationWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<OrganisationWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<OrganisationWhereFilterInput>>>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	houses?: Maybe<HouseFilterRelationsInput>;
	id?: Maybe<StringFilterInput>;
	name?: Maybe<StringFilterInput>;
	slug?: Maybe<StringFilterInput>;
	timedEvents?: Maybe<TimedEventFilterRelationsInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	users?: Maybe<UserFilterRelationsInput>;
	youngPersonLocationStatuses?: Maybe<YoungPersonLocationStatusFilterRelationsInput>;
};

export type OrganisationWhereUniqueInput = {
	id?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
};

export type OrganisationYoungPersonLocationStatusesCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<YoungPersonLocationStatusWhereUniqueInput>>>;
	connectOrCreate?: Maybe<
		Array<Maybe<YoungPersonLocationStatusConnectOrCreateInput>>
	>;
	create?: Maybe<Array<Maybe<YoungPersonLocationStatusCreateInput>>>;
};

export type OrganisationYoungPersonLocationStatusesUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<YoungPersonLocationStatusWhereUniqueInput>>>;
	connectOrCreate?: Maybe<
		Array<Maybe<YoungPersonLocationStatusConnectOrCreateInput>>
	>;
	create?: Maybe<Array<Maybe<YoungPersonLocationStatusCreateInput>>>;
	delete?: Maybe<Array<Maybe<YoungPersonLocationStatusDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<YoungPersonLocationStatusDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<YoungPersonLocationStatusWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<YoungPersonLocationStatusWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<YoungPersonLocationStatusUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<YoungPersonLocationStatusUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<YoungPersonLocationStatusUpsertUniqueInput>>>;
};

export type Query = {
	__typename?: 'Query';
	countHouses?: Maybe<Scalars['Int']>;
	countLogs?: Maybe<Scalars['Int']>;
	countMedicalConditions?: Maybe<Scalars['Int']>;
	countNextOfKins?: Maybe<Scalars['Int']>;
	countNotifications?: Maybe<Scalars['Int']>;
	countOccupancyLogs?: Maybe<Scalars['Int']>;
	countOrganisations?: Maybe<Scalars['Int']>;
	countRooms?: Maybe<Scalars['Int']>;
	countTasks?: Maybe<Scalars['Int']>;
	countTimedEvents?: Maybe<Scalars['Int']>;
	countUsers?: Maybe<Scalars['Int']>;
	countYoungPeople?: Maybe<Scalars['Int']>;
	countYoungPersonLocationStatuses?: Maybe<Scalars['Int']>;
	getFile?: Maybe<File>;
	getHouse?: Maybe<House>;
	getLog?: Maybe<Log>;
	getMedicalCondition?: Maybe<MedicalCondition>;
	getNextOfKin?: Maybe<NextOfKin>;
	getNotification?: Maybe<Notification>;
	getOccupancyLog?: Maybe<OccupancyLog>;
	getOrganisation?: Maybe<Organisation>;
	getRoom?: Maybe<Room>;
	getTask?: Maybe<Task>;
	getTimedEvent?: Maybe<TimedEvent>;
	getUser?: Maybe<User>;
	getYoungPerson?: Maybe<YoungPerson>;
	getYoungPersonLocationStatus?: Maybe<YoungPersonLocationStatus>;
	isLoggedIn?: Maybe<Scalars['Boolean']>;
	listHouseFiles?: Maybe<ListFilesResponse>;
	listHouses?: Maybe<Array<Maybe<House>>>;
	listLogs?: Maybe<Array<Maybe<Log>>>;
	listMedicalConditions?: Maybe<Array<Maybe<MedicalCondition>>>;
	listNextOfKins?: Maybe<Array<Maybe<NextOfKin>>>;
	listNotifications?: Maybe<Array<Maybe<Notification>>>;
	listOccupancyLogs?: Maybe<Array<Maybe<OccupancyLog>>>;
	listOrganisations?: Maybe<Array<Maybe<Organisation>>>;
	listRooms?: Maybe<Array<Maybe<Room>>>;
	listTasks?: Maybe<Array<Maybe<Task>>>;
	listTimedEvents?: Maybe<Array<Maybe<TimedEvent>>>;
	listUsers?: Maybe<Array<Maybe<User>>>;
	listYoungPeople?: Maybe<Array<Maybe<YoungPerson>>>;
	listYoungPersonFiles?: Maybe<ListFilesResponse>;
	listYoungPersonLocationStatuses?: Maybe<
		Array<Maybe<YoungPersonLocationStatus>>
	>;
	me?: Maybe<User>;
};

export type QueryCountHousesArgs = {
	orderBy?: Maybe<Array<Maybe<HouseOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<HouseWhereFilterInput>;
};

export type QueryCountLogsArgs = {
	orderBy?: Maybe<Array<Maybe<LogOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<LogWhereFilterInput>;
};

export type QueryCountMedicalConditionsArgs = {
	orderBy?: Maybe<Array<Maybe<MedicalConditionOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<MedicalConditionWhereFilterInput>;
};

export type QueryCountNextOfKinsArgs = {
	orderBy?: Maybe<Array<Maybe<NextOfKinOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<NextOfKinWhereFilterInput>;
};

export type QueryCountNotificationsArgs = {
	orderBy?: Maybe<Array<Maybe<NotificationOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<NotificationWhereFilterInput>;
};

export type QueryCountOccupancyLogsArgs = {
	orderBy?: Maybe<Array<Maybe<OccupancyLogOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<OccupancyLogWhereFilterInput>;
};

export type QueryCountOrganisationsArgs = {
	orderBy?: Maybe<Array<Maybe<OrganisationOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<OrganisationWhereFilterInput>;
};

export type QueryCountRoomsArgs = {
	orderBy?: Maybe<Array<Maybe<RoomOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<RoomWhereFilterInput>;
};

export type QueryCountTasksArgs = {
	orderBy?: Maybe<Array<Maybe<TaskOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<TaskWhereFilterInput>;
};

export type QueryCountTimedEventsArgs = {
	orderBy?: Maybe<Array<Maybe<TimedEventOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<TimedEventWhereFilterInput>;
};

export type QueryCountUsersArgs = {
	orderBy?: Maybe<Array<Maybe<UserOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<UserWhereFilterInput>;
};

export type QueryCountYoungPeopleArgs = {
	orderBy?: Maybe<Array<Maybe<YoungPersonOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<YoungPersonWhereFilterInput>;
};

export type QueryCountYoungPersonLocationStatusesArgs = {
	orderBy?: Maybe<Array<Maybe<YoungPersonLocationStatusOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<YoungPersonLocationStatusWhereFilterInput>;
};

export type QueryGetFileArgs = {
	input: GetFileInput;
};

export type QueryGetHouseArgs = {
	where: HouseWhereUniqueInput;
};

export type QueryGetLogArgs = {
	where: LogWhereUniqueInput;
};

export type QueryGetMedicalConditionArgs = {
	where: MedicalConditionWhereUniqueInput;
};

export type QueryGetNextOfKinArgs = {
	where: NextOfKinWhereUniqueInput;
};

export type QueryGetNotificationArgs = {
	where: NotificationWhereUniqueInput;
};

export type QueryGetOccupancyLogArgs = {
	where: OccupancyLogWhereUniqueInput;
};

export type QueryGetOrganisationArgs = {
	where: OrganisationWhereUniqueInput;
};

export type QueryGetRoomArgs = {
	where: RoomWhereUniqueInput;
};

export type QueryGetTaskArgs = {
	where: TaskWhereUniqueInput;
};

export type QueryGetTimedEventArgs = {
	where: TimedEventWhereUniqueInput;
};

export type QueryGetUserArgs = {
	where: UserWhereUniqueInput;
};

export type QueryGetYoungPersonArgs = {
	where: YoungPersonWhereUniqueInput;
};

export type QueryGetYoungPersonLocationStatusArgs = {
	where: YoungPersonLocationStatusWhereUniqueInput;
};

export type QueryListHouseFilesArgs = {
	input: ListHouseFilesInput;
};

export type QueryListHousesArgs = {
	orderBy?: Maybe<Array<Maybe<HouseOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<HouseWhereFilterInput>;
};

export type QueryListLogsArgs = {
	orderBy?: Maybe<Array<Maybe<LogOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<LogWhereFilterInput>;
};

export type QueryListMedicalConditionsArgs = {
	orderBy?: Maybe<Array<Maybe<MedicalConditionOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<MedicalConditionWhereFilterInput>;
};

export type QueryListNextOfKinsArgs = {
	orderBy?: Maybe<Array<Maybe<NextOfKinOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<NextOfKinWhereFilterInput>;
};

export type QueryListNotificationsArgs = {
	orderBy?: Maybe<Array<Maybe<NotificationOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<NotificationWhereFilterInput>;
};

export type QueryListOccupancyLogsArgs = {
	orderBy?: Maybe<Array<Maybe<OccupancyLogOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<OccupancyLogWhereFilterInput>;
};

export type QueryListOrganisationsArgs = {
	orderBy?: Maybe<Array<Maybe<OrganisationOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<OrganisationWhereFilterInput>;
};

export type QueryListRoomsArgs = {
	orderBy?: Maybe<Array<Maybe<RoomOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<RoomWhereFilterInput>;
};

export type QueryListTasksArgs = {
	orderBy?: Maybe<Array<Maybe<TaskOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<TaskWhereFilterInput>;
};

export type QueryListTimedEventsArgs = {
	orderBy?: Maybe<Array<Maybe<TimedEventOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<TimedEventWhereFilterInput>;
};

export type QueryListUsersArgs = {
	orderBy?: Maybe<Array<Maybe<UserOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<UserWhereFilterInput>;
};

export type QueryListYoungPeopleArgs = {
	orderBy?: Maybe<Array<Maybe<YoungPersonOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<YoungPersonWhereFilterInput>;
};

export type QueryListYoungPersonFilesArgs = {
	input: ListYoungPersonFilesInput;
};

export type QueryListYoungPersonLocationStatusesArgs = {
	orderBy?: Maybe<Array<Maybe<YoungPersonLocationStatusOrderByInput>>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
	where?: Maybe<YoungPersonLocationStatusWhereFilterInput>;
};

export enum RepeatOption {
	Daily = 'DAILY',
	Monthly = 'MONTHLY',
	Quarterly = 'QUARTERLY',
	Weekly = 'WEEKLY',
	Yearly = 'YEARLY',
}

export type RepeatOptionEnumFilterInput = {
	equals?: Maybe<RepeatOption>;
	in?: Maybe<Array<RepeatOption>>;
	not?: Maybe<RepeatOptionEnumFilterInput>;
	notIn?: Maybe<Array<RepeatOption>>;
};

export enum Role {
	Admin = 'ADMIN',
	HouseManager = 'HOUSE_MANAGER',
	SeniorUser = 'SENIOR_USER',
	User = 'USER',
}

export type RoleEnumFilterInput = {
	equals?: Maybe<Role>;
	in?: Maybe<Array<Role>>;
	not?: Maybe<RoleEnumFilterInput>;
	notIn?: Maybe<Array<Role>>;
};

export type Room = {
	__typename?: 'Room';
	color: Scalars['String'];
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	house?: Maybe<House>;
	houseId: Scalars['String'];
	id: Scalars['String'];
	name: Scalars['String'];
	occupancy?: Maybe<Array<OccupancyLog>>;
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	youngPerson?: Maybe<YoungPerson>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type RoomConnectOrCreateInput = {
	create: RoomCreateInput;
	where: RoomWhereUniqueInput;
};

export type RoomCreateInput = {
	color: Scalars['String'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	house?: Maybe<RoomHouseCreateRelationsInput>;
	houseId: Scalars['String'];
	name: Scalars['String'];
	occupancy?: Maybe<RoomOccupancyCreateRelationsInput>;
	youngPerson?: Maybe<RoomYoungPersonCreateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type RoomCreateManyInput = {
	color: Scalars['String'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	houseId: Scalars['String'];
	name: Scalars['String'];
	youngPersonId?: Maybe<Scalars['String']>;
};

export type RoomDeleteManyInput = {
	where: RoomWhereFilterInput;
};

export type RoomDeleteUniqueInput = {
	where: RoomWhereUniqueInput;
};

export type RoomFilterRelationsInput = {
	every?: Maybe<RoomWhereFilterInput>;
	none?: Maybe<RoomWhereFilterInput>;
	some?: Maybe<RoomWhereFilterInput>;
};

export type RoomHouseCreateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
};

export type RoomHouseUpdateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<HouseUpdateInput>;
	upsert?: Maybe<HouseUpsertInput>;
};

export type RoomOccupancyCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<OccupancyLogWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<OccupancyLogConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<OccupancyLogCreateInput>>>;
};

export type RoomOccupancyUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<OccupancyLogWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<OccupancyLogConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<OccupancyLogCreateInput>>>;
	delete?: Maybe<Array<Maybe<OccupancyLogDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<OccupancyLogDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<OccupancyLogWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<OccupancyLogWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<OccupancyLogUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<OccupancyLogUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<OccupancyLogUpsertUniqueInput>>>;
};

export type RoomOrderByInput = {
	color?: Maybe<OrderByArg>;
	createdAt?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	houseId?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	name?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
	youngPersonId?: Maybe<OrderByArg>;
};

export type RoomUpdateInput = {
	color?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	house?: Maybe<RoomHouseUpdateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	occupancy?: Maybe<RoomOccupancyUpdateRelationsInput>;
	youngPerson?: Maybe<RoomYoungPersonUpdateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type RoomUpdateManyInput = {
	data: RoomUpdateInput;
	where: RoomWhereFilterInput;
};

export type RoomUpdateUniqueInput = {
	data: RoomUpdateInput;
	where: RoomWhereUniqueInput;
};

export type RoomUpsertInput = {
	create: RoomCreateInput;
	update: RoomUpdateInput;
};

export type RoomUpsertUniqueInput = {
	create: RoomCreateInput;
	update: RoomUpdateInput;
	where: RoomWhereUniqueInput;
};

export type RoomWhereFilterInput = {
	AND?: Maybe<Array<Maybe<RoomWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<RoomWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<RoomWhereFilterInput>>>;
	color?: Maybe<StringFilterInput>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	house?: Maybe<HouseWhereFilterInput>;
	houseId?: Maybe<StringFilterInput>;
	id?: Maybe<StringFilterInput>;
	name?: Maybe<StringFilterInput>;
	occupancy?: Maybe<OccupancyLogFilterRelationsInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	youngPerson?: Maybe<YoungPersonWhereFilterInput>;
	youngPersonId?: Maybe<StringFilterInput>;
};

export type RoomWhereUniqueInput = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type RoomYoungPersonCreateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
};

export type RoomYoungPersonUpdateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<YoungPersonUpdateInput>;
	upsert?: Maybe<YoungPersonUpsertInput>;
};

export enum SeverityLevel {
	High = 'HIGH',
	Low = 'LOW',
	Medium = 'MEDIUM',
}

export type SeverityLevelEnumFilterInput = {
	equals?: Maybe<SeverityLevel>;
	in?: Maybe<Array<SeverityLevel>>;
	not?: Maybe<SeverityLevelEnumFilterInput>;
	notIn?: Maybe<Array<SeverityLevel>>;
};

export type StringFilterInput = {
	contains?: Maybe<Scalars['String']>;
	endsWith?: Maybe<Scalars['String']>;
	equals?: Maybe<Scalars['String']>;
	in?: Maybe<Array<Scalars['String']>>;
	not?: Maybe<StringFilterInput>;
	notIn?: Maybe<Array<Scalars['String']>>;
	startsWith?: Maybe<Scalars['String']>;
};

export type Subscription = {
	__typename?: 'Subscription';
	onCreatedHouse?: Maybe<House>;
	onCreatedLog?: Maybe<Log>;
	onCreatedManyHouses?: Maybe<BatchPayload>;
	onCreatedManyLogs?: Maybe<BatchPayload>;
	onCreatedManyMedicalConditions?: Maybe<BatchPayload>;
	onCreatedManyNextOfKins?: Maybe<BatchPayload>;
	onCreatedManyNotifications?: Maybe<BatchPayload>;
	onCreatedManyOccupancyLogs?: Maybe<BatchPayload>;
	onCreatedManyOrganisations?: Maybe<BatchPayload>;
	onCreatedManyRooms?: Maybe<BatchPayload>;
	onCreatedManyTasks?: Maybe<BatchPayload>;
	onCreatedManyTimedEvents?: Maybe<BatchPayload>;
	onCreatedManyUsers?: Maybe<BatchPayload>;
	onCreatedManyYoungPeople?: Maybe<BatchPayload>;
	onCreatedManyYoungPersonLocationStatuses?: Maybe<BatchPayload>;
	onCreatedMedicalCondition?: Maybe<MedicalCondition>;
	onCreatedNextOfKin?: Maybe<NextOfKin>;
	onCreatedNotification?: Maybe<Notification>;
	onCreatedOccupancyLog?: Maybe<OccupancyLog>;
	onCreatedOrganisation?: Maybe<Organisation>;
	onCreatedRoom?: Maybe<Room>;
	onCreatedTask?: Maybe<Task>;
	onCreatedTimedEvent?: Maybe<TimedEvent>;
	onCreatedUser?: Maybe<User>;
	onCreatedYoungPerson?: Maybe<YoungPerson>;
	onCreatedYoungPersonLocationStatus?: Maybe<YoungPersonLocationStatus>;
	onDeletedHouse?: Maybe<House>;
	onDeletedLog?: Maybe<Log>;
	onDeletedManyHouses?: Maybe<BatchPayload>;
	onDeletedManyLogs?: Maybe<BatchPayload>;
	onDeletedManyMedicalConditions?: Maybe<BatchPayload>;
	onDeletedManyNextOfKins?: Maybe<BatchPayload>;
	onDeletedManyNotifications?: Maybe<BatchPayload>;
	onDeletedManyOccupancyLogs?: Maybe<BatchPayload>;
	onDeletedManyOrganisations?: Maybe<BatchPayload>;
	onDeletedManyRooms?: Maybe<BatchPayload>;
	onDeletedManyTasks?: Maybe<BatchPayload>;
	onDeletedManyTimedEvents?: Maybe<BatchPayload>;
	onDeletedManyUsers?: Maybe<BatchPayload>;
	onDeletedManyYoungPeople?: Maybe<BatchPayload>;
	onDeletedManyYoungPersonLocationStatuses?: Maybe<BatchPayload>;
	onDeletedMedicalCondition?: Maybe<MedicalCondition>;
	onDeletedNextOfKin?: Maybe<NextOfKin>;
	onDeletedNotification?: Maybe<Notification>;
	onDeletedOccupancyLog?: Maybe<OccupancyLog>;
	onDeletedOrganisation?: Maybe<Organisation>;
	onDeletedRoom?: Maybe<Room>;
	onDeletedTask?: Maybe<Task>;
	onDeletedTimedEvent?: Maybe<TimedEvent>;
	onDeletedUser?: Maybe<User>;
	onDeletedYoungPerson?: Maybe<YoungPerson>;
	onDeletedYoungPersonLocationStatus?: Maybe<YoungPersonLocationStatus>;
	onMutatedHouse?: Maybe<House>;
	onMutatedLog?: Maybe<Log>;
	onMutatedManyHouses?: Maybe<BatchPayload>;
	onMutatedManyLogs?: Maybe<BatchPayload>;
	onMutatedManyMedicalConditions?: Maybe<BatchPayload>;
	onMutatedManyNextOfKins?: Maybe<BatchPayload>;
	onMutatedManyNotifications?: Maybe<BatchPayload>;
	onMutatedManyOccupancyLogs?: Maybe<BatchPayload>;
	onMutatedManyOrganisations?: Maybe<BatchPayload>;
	onMutatedManyRooms?: Maybe<BatchPayload>;
	onMutatedManyTasks?: Maybe<BatchPayload>;
	onMutatedManyTimedEvents?: Maybe<BatchPayload>;
	onMutatedManyUsers?: Maybe<BatchPayload>;
	onMutatedManyYoungPeople?: Maybe<BatchPayload>;
	onMutatedManyYoungPersonLocationStatuses?: Maybe<BatchPayload>;
	onMutatedMedicalCondition?: Maybe<MedicalCondition>;
	onMutatedNextOfKin?: Maybe<NextOfKin>;
	onMutatedNotification?: Maybe<Notification>;
	onMutatedOccupancyLog?: Maybe<OccupancyLog>;
	onMutatedOrganisation?: Maybe<Organisation>;
	onMutatedRoom?: Maybe<Room>;
	onMutatedTask?: Maybe<Task>;
	onMutatedTimedEvent?: Maybe<TimedEvent>;
	onMutatedUser?: Maybe<User>;
	onMutatedYoungPerson?: Maybe<YoungPerson>;
	onMutatedYoungPersonLocationStatus?: Maybe<YoungPersonLocationStatus>;
	onUpdatedHouse?: Maybe<House>;
	onUpdatedLog?: Maybe<Log>;
	onUpdatedManyHouses?: Maybe<BatchPayload>;
	onUpdatedManyLogs?: Maybe<BatchPayload>;
	onUpdatedManyMedicalConditions?: Maybe<BatchPayload>;
	onUpdatedManyNextOfKins?: Maybe<BatchPayload>;
	onUpdatedManyNotifications?: Maybe<BatchPayload>;
	onUpdatedManyOccupancyLogs?: Maybe<BatchPayload>;
	onUpdatedManyOrganisations?: Maybe<BatchPayload>;
	onUpdatedManyRooms?: Maybe<BatchPayload>;
	onUpdatedManyTasks?: Maybe<BatchPayload>;
	onUpdatedManyTimedEvents?: Maybe<BatchPayload>;
	onUpdatedManyUsers?: Maybe<BatchPayload>;
	onUpdatedManyYoungPeople?: Maybe<BatchPayload>;
	onUpdatedManyYoungPersonLocationStatuses?: Maybe<BatchPayload>;
	onUpdatedMedicalCondition?: Maybe<MedicalCondition>;
	onUpdatedNextOfKin?: Maybe<NextOfKin>;
	onUpdatedNotification?: Maybe<Notification>;
	onUpdatedOccupancyLog?: Maybe<OccupancyLog>;
	onUpdatedOrganisation?: Maybe<Organisation>;
	onUpdatedRoom?: Maybe<Room>;
	onUpdatedTask?: Maybe<Task>;
	onUpdatedTimedEvent?: Maybe<TimedEvent>;
	onUpdatedUser?: Maybe<User>;
	onUpdatedYoungPerson?: Maybe<YoungPerson>;
	onUpdatedYoungPersonLocationStatus?: Maybe<YoungPersonLocationStatus>;
	onUpsertedHouse?: Maybe<House>;
	onUpsertedLog?: Maybe<Log>;
	onUpsertedMedicalCondition?: Maybe<MedicalCondition>;
	onUpsertedNextOfKin?: Maybe<NextOfKin>;
	onUpsertedNotification?: Maybe<Notification>;
	onUpsertedOccupancyLog?: Maybe<OccupancyLog>;
	onUpsertedOrganisation?: Maybe<Organisation>;
	onUpsertedRoom?: Maybe<Room>;
	onUpsertedTask?: Maybe<Task>;
	onUpsertedTimedEvent?: Maybe<TimedEvent>;
	onUpsertedUser?: Maybe<User>;
	onUpsertedYoungPerson?: Maybe<YoungPerson>;
	onUpsertedYoungPersonLocationStatus?: Maybe<YoungPersonLocationStatus>;
};

export type SubscriptionOnCreatedHouseArgs = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedLogArgs = {
	createdBy?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedMedicalConditionArgs = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedNextOfKinArgs = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedNotificationArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	taskId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedOccupancyLogArgs = {
	id?: Maybe<Scalars['String']>;
	roomId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedOrganisationArgs = {
	id?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedRoomArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedTaskArgs = {
	createdUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	ref?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedTimedEventArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedUserArgs = {
	email?: Maybe<Scalars['AWSEmail']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	sub?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedYoungPersonArgs = {
	createdByUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	locationStatusId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnCreatedYoungPersonLocationStatusArgs = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedHouseArgs = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedLogArgs = {
	createdBy?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedMedicalConditionArgs = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedNextOfKinArgs = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedNotificationArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	taskId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedOccupancyLogArgs = {
	id?: Maybe<Scalars['String']>;
	roomId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedOrganisationArgs = {
	id?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedRoomArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedTaskArgs = {
	createdUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	ref?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedTimedEventArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedUserArgs = {
	email?: Maybe<Scalars['AWSEmail']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	sub?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedYoungPersonArgs = {
	createdByUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	locationStatusId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnDeletedYoungPersonLocationStatusArgs = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedHouseArgs = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedLogArgs = {
	createdBy?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedMedicalConditionArgs = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedNextOfKinArgs = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedNotificationArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	taskId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedOccupancyLogArgs = {
	id?: Maybe<Scalars['String']>;
	roomId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedOrganisationArgs = {
	id?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedRoomArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedTaskArgs = {
	createdUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	ref?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedTimedEventArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedUserArgs = {
	email?: Maybe<Scalars['AWSEmail']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	sub?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedYoungPersonArgs = {
	createdByUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	locationStatusId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnMutatedYoungPersonLocationStatusArgs = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedHouseArgs = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedLogArgs = {
	createdBy?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedMedicalConditionArgs = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedNextOfKinArgs = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedNotificationArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	taskId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedOccupancyLogArgs = {
	id?: Maybe<Scalars['String']>;
	roomId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedOrganisationArgs = {
	id?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedRoomArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedTaskArgs = {
	createdUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	ref?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedTimedEventArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedUserArgs = {
	email?: Maybe<Scalars['AWSEmail']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	sub?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedYoungPersonArgs = {
	createdByUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	locationStatusId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpdatedYoungPersonLocationStatusArgs = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedHouseArgs = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedLogArgs = {
	createdBy?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedMedicalConditionArgs = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedNextOfKinArgs = {
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedNotificationArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	taskId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedOccupancyLogArgs = {
	id?: Maybe<Scalars['String']>;
	roomId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedOrganisationArgs = {
	id?: Maybe<Scalars['String']>;
	slug?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedRoomArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedTaskArgs = {
	createdUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	ref?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedTimedEventArgs = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedUserArgs = {
	email?: Maybe<Scalars['AWSEmail']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	sub?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedYoungPersonArgs = {
	createdByUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	locationStatusId?: Maybe<Scalars['String']>;
};

export type SubscriptionOnUpsertedYoungPersonLocationStatusArgs = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type Task = {
	__typename?: 'Task';
	assignedTo?: Maybe<User>;
	assignedUserId?: Maybe<Scalars['String']>;
	completedAt?: Maybe<Scalars['AWSDateTime']>;
	completedBy?: Maybe<User>;
	completedUserId?: Maybe<Scalars['String']>;
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	createdBy?: Maybe<User>;
	createdUserId?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	description?: Maybe<Scalars['String']>;
	dueDate?: Maybe<Scalars['AWSDateTime']>;
	fileTemplateKey?: Maybe<Scalars['String']>;
	fileType?: Maybe<FileType>;
	formType?: Maybe<FormType>;
	group?: Maybe<TaskGroup>;
	house?: Maybe<House>;
	houseId?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	isAdmin?: Maybe<Scalars['Boolean']>;
	isAutomated?: Maybe<Scalars['Boolean']>;
	isPrivate?: Maybe<Scalars['Boolean']>;
	notifications?: Maybe<Array<Notification>>;
	ref?: Maybe<Scalars['String']>;
	repeat?: Maybe<RepeatOption>;
	repeatEnd?: Maybe<Scalars['AWSDateTime']>;
	title: Scalars['String'];
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	youngPerson?: Maybe<YoungPerson>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type TaskAssignedToCreateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
};

export type TaskAssignedToUpdateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<UserUpdateInput>;
	upsert?: Maybe<UserUpsertInput>;
};

export type TaskCompletedByCreateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
};

export type TaskCompletedByUpdateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<UserUpdateInput>;
	upsert?: Maybe<UserUpsertInput>;
};

export type TaskConnectOrCreateInput = {
	create: TaskCreateInput;
	where: TaskWhereUniqueInput;
};

export type TaskCreateInput = {
	assignedTo?: Maybe<TaskAssignedToCreateRelationsInput>;
	assignedUserId?: Maybe<Scalars['String']>;
	completedAt?: Maybe<Scalars['AWSDateTime']>;
	completedBy?: Maybe<TaskCompletedByCreateRelationsInput>;
	completedUserId?: Maybe<Scalars['String']>;
	createdBy?: Maybe<TaskCreatedByCreateRelationsInput>;
	createdUserId?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	description?: Maybe<Scalars['String']>;
	dueDate?: Maybe<Scalars['AWSDateTime']>;
	fileTemplateKey?: Maybe<Scalars['String']>;
	fileType?: Maybe<FileType>;
	formType?: Maybe<FormType>;
	group?: Maybe<TaskGroup>;
	house?: Maybe<TaskHouseCreateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	isAdmin?: Maybe<Scalars['Boolean']>;
	isAutomated?: Maybe<Scalars['Boolean']>;
	isPrivate?: Maybe<Scalars['Boolean']>;
	notifications?: Maybe<TaskNotificationsCreateRelationsInput>;
	ref?: Maybe<Scalars['String']>;
	repeat?: Maybe<RepeatOption>;
	repeatEnd?: Maybe<Scalars['AWSDateTime']>;
	title: Scalars['String'];
	youngPerson?: Maybe<TaskYoungPersonCreateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type TaskCreateManyInput = {
	assignedUserId?: Maybe<Scalars['String']>;
	completedAt?: Maybe<Scalars['AWSDateTime']>;
	completedUserId?: Maybe<Scalars['String']>;
	createdUserId?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	description?: Maybe<Scalars['String']>;
	dueDate?: Maybe<Scalars['AWSDateTime']>;
	fileTemplateKey?: Maybe<Scalars['String']>;
	fileType?: Maybe<FileType>;
	formType?: Maybe<FormType>;
	group?: Maybe<TaskGroup>;
	houseId?: Maybe<Scalars['String']>;
	isAdmin?: Maybe<Scalars['Boolean']>;
	isAutomated?: Maybe<Scalars['Boolean']>;
	isPrivate?: Maybe<Scalars['Boolean']>;
	ref?: Maybe<Scalars['String']>;
	repeat?: Maybe<RepeatOption>;
	repeatEnd?: Maybe<Scalars['AWSDateTime']>;
	title: Scalars['String'];
	youngPersonId?: Maybe<Scalars['String']>;
};

export type TaskCreatedByCreateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
};

export type TaskCreatedByUpdateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<UserUpdateInput>;
	upsert?: Maybe<UserUpsertInput>;
};

export type TaskDeleteManyInput = {
	where: TaskWhereFilterInput;
};

export type TaskDeleteUniqueInput = {
	where: TaskWhereUniqueInput;
};

export type TaskFilterRelationsInput = {
	every?: Maybe<TaskWhereFilterInput>;
	none?: Maybe<TaskWhereFilterInput>;
	some?: Maybe<TaskWhereFilterInput>;
};

export enum TaskGroup {
	MoveIn = 'MOVE_IN',
	MoveOut = 'MOVE_OUT',
	PreMoveIn = 'PRE_MOVE_IN',
}

export type TaskGroupEnumFilterInput = {
	equals?: Maybe<TaskGroup>;
	in?: Maybe<Array<TaskGroup>>;
	not?: Maybe<TaskGroupEnumFilterInput>;
	notIn?: Maybe<Array<TaskGroup>>;
};

export type TaskHouseCreateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
};

export type TaskHouseUpdateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<HouseUpdateInput>;
	upsert?: Maybe<HouseUpsertInput>;
};

export type TaskNotificationsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<NotificationConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<NotificationCreateInput>>>;
};

export type TaskNotificationsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<NotificationConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<NotificationCreateInput>>>;
	delete?: Maybe<Array<Maybe<NotificationDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<NotificationDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<NotificationUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<NotificationUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<NotificationUpsertUniqueInput>>>;
};

export type TaskOrderByInput = {
	assignedUserId?: Maybe<OrderByArg>;
	completedAt?: Maybe<OrderByArg>;
	completedUserId?: Maybe<OrderByArg>;
	createdAt?: Maybe<OrderByArg>;
	createdUserId?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	description?: Maybe<OrderByArg>;
	dueDate?: Maybe<OrderByArg>;
	fileTemplateKey?: Maybe<OrderByArg>;
	fileType?: Maybe<OrderByArg>;
	formType?: Maybe<OrderByArg>;
	group?: Maybe<OrderByArg>;
	houseId?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	isAdmin?: Maybe<OrderByArg>;
	isAutomated?: Maybe<OrderByArg>;
	isPrivate?: Maybe<OrderByArg>;
	ref?: Maybe<OrderByArg>;
	repeat?: Maybe<OrderByArg>;
	repeatEnd?: Maybe<OrderByArg>;
	title?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
	youngPersonId?: Maybe<OrderByArg>;
};

export type TaskUpdateInput = {
	assignedTo?: Maybe<TaskAssignedToUpdateRelationsInput>;
	assignedUserId?: Maybe<Scalars['String']>;
	completedAt?: Maybe<Scalars['AWSDateTime']>;
	completedBy?: Maybe<TaskCompletedByUpdateRelationsInput>;
	completedUserId?: Maybe<Scalars['String']>;
	createdBy?: Maybe<TaskCreatedByUpdateRelationsInput>;
	createdUserId?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	description?: Maybe<Scalars['String']>;
	dueDate?: Maybe<Scalars['AWSDateTime']>;
	fileTemplateKey?: Maybe<Scalars['String']>;
	fileType?: Maybe<FileType>;
	formType?: Maybe<FormType>;
	group?: Maybe<TaskGroup>;
	house?: Maybe<TaskHouseUpdateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	isAdmin?: Maybe<Scalars['Boolean']>;
	isAutomated?: Maybe<Scalars['Boolean']>;
	isPrivate?: Maybe<Scalars['Boolean']>;
	notifications?: Maybe<TaskNotificationsUpdateRelationsInput>;
	ref?: Maybe<Scalars['String']>;
	repeat?: Maybe<RepeatOption>;
	repeatEnd?: Maybe<Scalars['AWSDateTime']>;
	title?: Maybe<Scalars['String']>;
	youngPerson?: Maybe<TaskYoungPersonUpdateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type TaskUpdateManyInput = {
	data: TaskUpdateInput;
	where: TaskWhereFilterInput;
};

export type TaskUpdateUniqueInput = {
	data: TaskUpdateInput;
	where: TaskWhereUniqueInput;
};

export type TaskUpsertInput = {
	create: TaskCreateInput;
	update: TaskUpdateInput;
};

export type TaskUpsertUniqueInput = {
	create: TaskCreateInput;
	update: TaskUpdateInput;
	where: TaskWhereUniqueInput;
};

export type TaskWhereFilterInput = {
	AND?: Maybe<Array<Maybe<TaskWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<TaskWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<TaskWhereFilterInput>>>;
	assignedTo?: Maybe<UserWhereFilterInput>;
	assignedUserId?: Maybe<StringFilterInput>;
	completedAt?: Maybe<AwsDateTimeFilterInput>;
	completedBy?: Maybe<UserWhereFilterInput>;
	completedUserId?: Maybe<StringFilterInput>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	createdBy?: Maybe<UserWhereFilterInput>;
	createdUserId?: Maybe<StringFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	description?: Maybe<StringFilterInput>;
	dueDate?: Maybe<AwsDateTimeFilterInput>;
	fileTemplateKey?: Maybe<StringFilterInput>;
	fileType?: Maybe<FileTypeEnumFilterInput>;
	formType?: Maybe<FormTypeEnumFilterInput>;
	group?: Maybe<TaskGroupEnumFilterInput>;
	house?: Maybe<HouseWhereFilterInput>;
	houseId?: Maybe<StringFilterInput>;
	id?: Maybe<StringFilterInput>;
	isAdmin?: Maybe<BooleanFilterInput>;
	isAutomated?: Maybe<BooleanFilterInput>;
	isPrivate?: Maybe<BooleanFilterInput>;
	notifications?: Maybe<NotificationFilterRelationsInput>;
	ref?: Maybe<StringFilterInput>;
	repeat?: Maybe<RepeatOptionEnumFilterInput>;
	repeatEnd?: Maybe<AwsDateTimeFilterInput>;
	title?: Maybe<StringFilterInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	youngPerson?: Maybe<YoungPersonWhereFilterInput>;
	youngPersonId?: Maybe<StringFilterInput>;
};

export type TaskWhereUniqueInput = {
	assignedUserId?: Maybe<Scalars['String']>;
	completedUserId?: Maybe<Scalars['String']>;
	createdUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	ref?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type TaskYoungPersonCreateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
};

export type TaskYoungPersonUpdateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<YoungPersonUpdateInput>;
	upsert?: Maybe<YoungPersonUpsertInput>;
};

export type TimedEvent = {
	__typename?: 'TimedEvent';
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	eventType: TimedEventType;
	house?: Maybe<House>;
	houseId?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	message: Scalars['String'];
	meta?: Maybe<Scalars['AWSJSON']>;
	organisation?: Maybe<Organisation>;
	organisationId?: Maybe<Scalars['String']>;
	repeat?: Maybe<RepeatOption>;
	triggerAt: Scalars['AWSDateTime'];
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	user?: Maybe<User>;
	userId?: Maybe<Scalars['String']>;
	youngPerson?: Maybe<YoungPerson>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type TimedEventConnectOrCreateInput = {
	create: TimedEventCreateInput;
	where: TimedEventWhereUniqueInput;
};

export type TimedEventCreateInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	eventType: TimedEventType;
	house?: Maybe<TimedEventHouseCreateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	message: Scalars['String'];
	meta?: Maybe<Scalars['AWSJSON']>;
	organisation?: Maybe<TimedEventOrganisationCreateRelationsInput>;
	organisationId?: Maybe<Scalars['String']>;
	repeat?: Maybe<RepeatOption>;
	triggerAt: Scalars['AWSDateTime'];
	user?: Maybe<TimedEventUserCreateRelationsInput>;
	userId?: Maybe<Scalars['String']>;
	youngPerson?: Maybe<TimedEventYoungPersonCreateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type TimedEventCreateManyInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	eventType: TimedEventType;
	houseId?: Maybe<Scalars['String']>;
	message: Scalars['String'];
	meta?: Maybe<Scalars['AWSJSON']>;
	organisationId?: Maybe<Scalars['String']>;
	repeat?: Maybe<RepeatOption>;
	triggerAt: Scalars['AWSDateTime'];
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type TimedEventDeleteManyInput = {
	where: TimedEventWhereFilterInput;
};

export type TimedEventDeleteUniqueInput = {
	where: TimedEventWhereUniqueInput;
};

export type TimedEventFilterRelationsInput = {
	every?: Maybe<TimedEventWhereFilterInput>;
	none?: Maybe<TimedEventWhereFilterInput>;
	some?: Maybe<TimedEventWhereFilterInput>;
};

export type TimedEventHouseCreateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
};

export type TimedEventHouseUpdateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<HouseUpdateInput>;
	upsert?: Maybe<HouseUpsertInput>;
};

export type TimedEventOrderByInput = {
	createdAt?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	eventType?: Maybe<OrderByArg>;
	houseId?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	message?: Maybe<OrderByArg>;
	meta?: Maybe<OrderByArg>;
	organisationId?: Maybe<OrderByArg>;
	repeat?: Maybe<OrderByArg>;
	triggerAt?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
	userId?: Maybe<OrderByArg>;
	youngPersonId?: Maybe<OrderByArg>;
};

export type TimedEventOrganisationCreateRelationsInput = {
	connect?: Maybe<OrganisationWhereUniqueInput>;
	connectOrCreate?: Maybe<OrganisationConnectOrCreateInput>;
	create?: Maybe<OrganisationCreateInput>;
};

export type TimedEventOrganisationUpdateRelationsInput = {
	connect?: Maybe<OrganisationWhereUniqueInput>;
	connectOrCreate?: Maybe<OrganisationConnectOrCreateInput>;
	create?: Maybe<OrganisationCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<OrganisationUpdateInput>;
	upsert?: Maybe<OrganisationUpsertInput>;
};

export enum TimedEventType {
	Birthday = 'BIRTHDAY',
}

export type TimedEventTypeEnumFilterInput = {
	equals?: Maybe<TimedEventType>;
	in?: Maybe<Array<TimedEventType>>;
	not?: Maybe<TimedEventTypeEnumFilterInput>;
	notIn?: Maybe<Array<TimedEventType>>;
};

export type TimedEventUpdateInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	eventType?: Maybe<TimedEventType>;
	house?: Maybe<TimedEventHouseUpdateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	message?: Maybe<Scalars['String']>;
	meta?: Maybe<Scalars['AWSJSON']>;
	organisation?: Maybe<TimedEventOrganisationUpdateRelationsInput>;
	organisationId?: Maybe<Scalars['String']>;
	repeat?: Maybe<RepeatOption>;
	triggerAt?: Maybe<Scalars['AWSDateTime']>;
	user?: Maybe<TimedEventUserUpdateRelationsInput>;
	userId?: Maybe<Scalars['String']>;
	youngPerson?: Maybe<TimedEventYoungPersonUpdateRelationsInput>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type TimedEventUpdateManyInput = {
	data: TimedEventUpdateInput;
	where: TimedEventWhereFilterInput;
};

export type TimedEventUpdateUniqueInput = {
	data: TimedEventUpdateInput;
	where: TimedEventWhereUniqueInput;
};

export type TimedEventUpsertInput = {
	create: TimedEventCreateInput;
	update: TimedEventUpdateInput;
};

export type TimedEventUpsertUniqueInput = {
	create: TimedEventCreateInput;
	update: TimedEventUpdateInput;
	where: TimedEventWhereUniqueInput;
};

export type TimedEventUserCreateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
};

export type TimedEventUserUpdateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<UserUpdateInput>;
	upsert?: Maybe<UserUpsertInput>;
};

export type TimedEventWhereFilterInput = {
	AND?: Maybe<Array<Maybe<TimedEventWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<TimedEventWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<TimedEventWhereFilterInput>>>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	eventType?: Maybe<TimedEventTypeEnumFilterInput>;
	house?: Maybe<HouseWhereFilterInput>;
	houseId?: Maybe<StringFilterInput>;
	id?: Maybe<StringFilterInput>;
	message?: Maybe<StringFilterInput>;
	meta?: Maybe<AwsjsonFilterInput>;
	organisation?: Maybe<OrganisationWhereFilterInput>;
	organisationId?: Maybe<StringFilterInput>;
	repeat?: Maybe<RepeatOptionEnumFilterInput>;
	triggerAt?: Maybe<AwsDateTimeFilterInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	user?: Maybe<UserWhereFilterInput>;
	userId?: Maybe<StringFilterInput>;
	youngPerson?: Maybe<YoungPersonWhereFilterInput>;
	youngPersonId?: Maybe<StringFilterInput>;
};

export type TimedEventWhereUniqueInput = {
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	userId?: Maybe<Scalars['String']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type TimedEventYoungPersonCreateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
};

export type TimedEventYoungPersonUpdateRelationsInput = {
	connect?: Maybe<YoungPersonWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonConnectOrCreateInput>;
	create?: Maybe<YoungPersonCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<YoungPersonUpdateInput>;
	upsert?: Maybe<YoungPersonUpsertInput>;
};

export type UploadFileUrlInput = {
	fileName?: Maybe<Scalars['String']>;
	fileType: FileType;
	folderName?: Maybe<Scalars['String']>;
	houseId: Scalars['String'];
	templateId?: Maybe<Scalars['ID']>;
	youngPersonId?: Maybe<Scalars['String']>;
};

export type UrlResponse = {
	__typename?: 'UrlResponse';
	statusCode?: Maybe<Scalars['Int']>;
	url: Scalars['String'];
};

export type User = {
	__typename?: 'User';
	Log?: Maybe<Array<Log>>;
	address?: Maybe<Scalars['String']>;
	assignedTasks?: Maybe<Array<Task>>;
	avatar?: Maybe<Scalars['String']>;
	completedTasks?: Maybe<Array<Task>>;
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	createdTasks?: Maybe<Array<Task>>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	disabled?: Maybe<Scalars['Boolean']>;
	email: Scalars['AWSEmail'];
	firstName: Scalars['String'];
	hideAccount?: Maybe<Scalars['Boolean']>;
	house?: Maybe<House>;
	houseId?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	lastName: Scalars['String'];
	name?: Maybe<Scalars['String']>;
	notifcations?: Maybe<Array<Notification>>;
	organisation?: Maybe<Organisation>;
	organisationId?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	role: Role;
	sub?: Maybe<Scalars['String']>;
	supervisorId?: Maybe<Scalars['String']>;
	timedEvents?: Maybe<Array<TimedEvent>>;
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	youngPeopleCreated?: Maybe<Array<YoungPerson>>;
};

export type UserAssignedTasksCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TaskConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TaskCreateInput>>>;
};

export type UserAssignedTasksUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TaskConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TaskCreateInput>>>;
	delete?: Maybe<Array<Maybe<TaskDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<TaskDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<TaskUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<TaskUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<TaskUpsertUniqueInput>>>;
};

export type UserCompletedTasksCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TaskConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TaskCreateInput>>>;
};

export type UserCompletedTasksUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TaskConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TaskCreateInput>>>;
	delete?: Maybe<Array<Maybe<TaskDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<TaskDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<TaskUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<TaskUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<TaskUpsertUniqueInput>>>;
};

export type UserConnectOrCreateInput = {
	create: UserCreateInput;
	where: UserWhereUniqueInput;
};

export type UserCreateInput = {
	Log?: Maybe<UserLogCreateRelationsInput>;
	address?: Maybe<Scalars['String']>;
	assignedTasks?: Maybe<UserAssignedTasksCreateRelationsInput>;
	avatar?: Maybe<Scalars['String']>;
	completedTasks?: Maybe<UserCompletedTasksCreateRelationsInput>;
	createdTasks?: Maybe<UserCreatedTasksCreateRelationsInput>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	disabled?: Maybe<Scalars['Boolean']>;
	email: Scalars['AWSEmail'];
	firstName: Scalars['String'];
	hideAccount?: Maybe<Scalars['Boolean']>;
	house?: Maybe<UserHouseCreateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	lastName: Scalars['String'];
	name?: Maybe<Scalars['String']>;
	notifcations?: Maybe<UserNotifcationsCreateRelationsInput>;
	organisation?: Maybe<UserOrganisationCreateRelationsInput>;
	organisationId?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	role: Role;
	sub?: Maybe<Scalars['String']>;
	supervisorId?: Maybe<Scalars['String']>;
	timedEvents?: Maybe<UserTimedEventsCreateRelationsInput>;
	youngPeopleCreated?: Maybe<UserYoungPeopleCreatedCreateRelationsInput>;
};

export type UserCreateManyInput = {
	address?: Maybe<Scalars['String']>;
	avatar?: Maybe<Scalars['String']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	disabled?: Maybe<Scalars['Boolean']>;
	email: Scalars['AWSEmail'];
	firstName: Scalars['String'];
	hideAccount?: Maybe<Scalars['Boolean']>;
	houseId?: Maybe<Scalars['String']>;
	lastName: Scalars['String'];
	name?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	role: Role;
	sub?: Maybe<Scalars['String']>;
	supervisorId?: Maybe<Scalars['String']>;
};

export type UserCreatedTasksCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TaskConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TaskCreateInput>>>;
};

export type UserCreatedTasksUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TaskConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TaskCreateInput>>>;
	delete?: Maybe<Array<Maybe<TaskDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<TaskDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<TaskUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<TaskUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<TaskUpsertUniqueInput>>>;
};

export type UserDeleteManyInput = {
	where: UserWhereFilterInput;
};

export type UserDeleteUniqueInput = {
	where: UserWhereUniqueInput;
};

export type UserFilterRelationsInput = {
	every?: Maybe<UserWhereFilterInput>;
	none?: Maybe<UserWhereFilterInput>;
	some?: Maybe<UserWhereFilterInput>;
};

export type UserHouseCreateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
};

export type UserHouseUpdateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<HouseUpdateInput>;
	upsert?: Maybe<HouseUpsertInput>;
};

export type UserLogCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<LogConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<LogCreateInput>>>;
};

export type UserLogUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<LogConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<LogCreateInput>>>;
	delete?: Maybe<Array<Maybe<LogDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<LogDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<LogUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<LogUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<LogUpsertUniqueInput>>>;
};

export type UserNotifcationsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<NotificationConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<NotificationCreateInput>>>;
};

export type UserNotifcationsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<NotificationConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<NotificationCreateInput>>>;
	delete?: Maybe<Array<Maybe<NotificationDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<NotificationDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<NotificationUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<NotificationUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<NotificationUpsertUniqueInput>>>;
};

export type UserOrderByInput = {
	address?: Maybe<OrderByArg>;
	avatar?: Maybe<OrderByArg>;
	createdAt?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	disabled?: Maybe<OrderByArg>;
	email?: Maybe<OrderByArg>;
	firstName?: Maybe<OrderByArg>;
	hideAccount?: Maybe<OrderByArg>;
	houseId?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	lastName?: Maybe<OrderByArg>;
	name?: Maybe<OrderByArg>;
	organisationId?: Maybe<OrderByArg>;
	phone?: Maybe<OrderByArg>;
	role?: Maybe<OrderByArg>;
	sub?: Maybe<OrderByArg>;
	supervisorId?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
};

export type UserOrganisationCreateRelationsInput = {
	connect?: Maybe<OrganisationWhereUniqueInput>;
	connectOrCreate?: Maybe<OrganisationConnectOrCreateInput>;
	create?: Maybe<OrganisationCreateInput>;
};

export type UserOrganisationUpdateRelationsInput = {
	connect?: Maybe<OrganisationWhereUniqueInput>;
	connectOrCreate?: Maybe<OrganisationConnectOrCreateInput>;
	create?: Maybe<OrganisationCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<OrganisationUpdateInput>;
	upsert?: Maybe<OrganisationUpsertInput>;
};

export type UserTimedEventsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TimedEventConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TimedEventCreateInput>>>;
};

export type UserTimedEventsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TimedEventConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TimedEventCreateInput>>>;
	delete?: Maybe<Array<Maybe<TimedEventDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<TimedEventDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<TimedEventUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<TimedEventUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<TimedEventUpsertUniqueInput>>>;
};

export type UserUpdateInput = {
	Log?: Maybe<UserLogUpdateRelationsInput>;
	address?: Maybe<Scalars['String']>;
	assignedTasks?: Maybe<UserAssignedTasksUpdateRelationsInput>;
	avatar?: Maybe<Scalars['String']>;
	completedTasks?: Maybe<UserCompletedTasksUpdateRelationsInput>;
	createdTasks?: Maybe<UserCreatedTasksUpdateRelationsInput>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	disabled?: Maybe<Scalars['Boolean']>;
	email?: Maybe<Scalars['AWSEmail']>;
	firstName?: Maybe<Scalars['String']>;
	hideAccount?: Maybe<Scalars['Boolean']>;
	house?: Maybe<UserHouseUpdateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	lastName?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	notifcations?: Maybe<UserNotifcationsUpdateRelationsInput>;
	organisation?: Maybe<UserOrganisationUpdateRelationsInput>;
	organisationId?: Maybe<Scalars['String']>;
	phone?: Maybe<Scalars['String']>;
	role?: Maybe<Role>;
	sub?: Maybe<Scalars['String']>;
	supervisorId?: Maybe<Scalars['String']>;
	timedEvents?: Maybe<UserTimedEventsUpdateRelationsInput>;
	youngPeopleCreated?: Maybe<UserYoungPeopleCreatedUpdateRelationsInput>;
};

export type UserUpdateManyInput = {
	data: UserUpdateInput;
	where: UserWhereFilterInput;
};

export type UserUpdateUniqueInput = {
	data: UserUpdateInput;
	where: UserWhereUniqueInput;
};

export type UserUpsertInput = {
	create: UserCreateInput;
	update: UserUpdateInput;
};

export type UserUpsertUniqueInput = {
	create: UserCreateInput;
	update: UserUpdateInput;
	where: UserWhereUniqueInput;
};

export type UserWhereFilterInput = {
	AND?: Maybe<Array<Maybe<UserWhereFilterInput>>>;
	Log?: Maybe<LogFilterRelationsInput>;
	NOT?: Maybe<Array<Maybe<UserWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<UserWhereFilterInput>>>;
	address?: Maybe<StringFilterInput>;
	assignedTasks?: Maybe<TaskFilterRelationsInput>;
	avatar?: Maybe<StringFilterInput>;
	completedTasks?: Maybe<TaskFilterRelationsInput>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	createdTasks?: Maybe<TaskFilterRelationsInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	disabled?: Maybe<BooleanFilterInput>;
	email?: Maybe<AwsEmailFilterInput>;
	firstName?: Maybe<StringFilterInput>;
	hideAccount?: Maybe<BooleanFilterInput>;
	house?: Maybe<HouseWhereFilterInput>;
	houseId?: Maybe<StringFilterInput>;
	id?: Maybe<StringFilterInput>;
	lastName?: Maybe<StringFilterInput>;
	name?: Maybe<StringFilterInput>;
	notifcations?: Maybe<NotificationFilterRelationsInput>;
	organisation?: Maybe<OrganisationWhereFilterInput>;
	organisationId?: Maybe<StringFilterInput>;
	phone?: Maybe<StringFilterInput>;
	role?: Maybe<RoleEnumFilterInput>;
	sub?: Maybe<StringFilterInput>;
	supervisorId?: Maybe<StringFilterInput>;
	timedEvents?: Maybe<TimedEventFilterRelationsInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	youngPeopleCreated?: Maybe<YoungPersonFilterRelationsInput>;
};

export type UserWhereUniqueInput = {
	email?: Maybe<Scalars['AWSEmail']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
	sub?: Maybe<Scalars['String']>;
};

export type UserYoungPeopleCreatedCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<YoungPersonConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<YoungPersonCreateInput>>>;
};

export type UserYoungPeopleCreatedUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<YoungPersonConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<YoungPersonCreateInput>>>;
	delete?: Maybe<Array<Maybe<YoungPersonDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<YoungPersonDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<YoungPersonUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<YoungPersonUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<YoungPersonUpsertUniqueInput>>>;
};

export type YoungPerson = {
	__typename?: 'YoungPerson';
	aboutMe?: Maybe<Scalars['AWSJSON']>;
	avatar?: Maybe<Scalars['String']>;
	birthDateDay?: Maybe<Scalars['Int']>;
	birthDateMonth?: Maybe<Scalars['Int']>;
	birthDateYear?: Maybe<Scalars['Int']>;
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	createdBy?: Maybe<User>;
	createdByUserId?: Maybe<Scalars['String']>;
	dateOfBirth: Scalars['AWSDateTime'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	email?: Maybe<Scalars['AWSEmail']>;
	ethnicity?: Maybe<Scalars['String']>;
	files?: Maybe<ListFilesResponse>;
	gender: Scalars['String'];
	house?: Maybe<House>;
	houseId?: Maybe<Scalars['String']>;
	id: Scalars['String'];
	initalAssessment?: Maybe<Scalars['AWSJSON']>;
	legalStatus?: Maybe<Scalars['String']>;
	localAuthority?: Maybe<Scalars['String']>;
	locationStatus?: Maybe<YoungPersonLocationStatus>;
	locationStatusId?: Maybe<Scalars['String']>;
	logs?: Maybe<Array<Log>>;
	medicalConditions?: Maybe<Array<MedicalCondition>>;
	missingAt?: Maybe<Scalars['AWSDateTime']>;
	mostRecentAddress?: Maybe<Scalars['String']>;
	moveInDate?: Maybe<Scalars['AWSDateTime']>;
	moveOutDate?: Maybe<Scalars['AWSDateTime']>;
	moveOutNote?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	nextOfKin?: Maybe<Array<NextOfKin>>;
	nhsNumber?: Maybe<Scalars['String']>;
	niNumber?: Maybe<Scalars['String']>;
	notifications?: Maybe<Array<Notification>>;
	occupancy?: Maybe<Array<OccupancyLog>>;
	phone: Scalars['String'];
	photoConcept?: Maybe<Scalars['Boolean']>;
	physicalDescription?: Maybe<Scalars['String']>;
	preferredName?: Maybe<Scalars['String']>;
	registeredDentist?: Maybe<Scalars['String']>;
	registeredGP?: Maybe<Scalars['String']>;
	registeredOptician?: Maybe<Scalars['String']>;
	religion?: Maybe<Scalars['String']>;
	riskAssessment?: Maybe<Scalars['AWSJSON']>;
	room?: Maybe<Room>;
	safePlace?: Maybe<Scalars['String']>;
	socialWorker?: Maybe<Scalars['String']>;
	spokenLanguage?: Maybe<Scalars['String']>;
	status?: Maybe<YoungPersonStatus>;
	tasks?: Maybe<Array<Task>>;
	timedEvents?: Maybe<Array<TimedEvent>>;
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	ypAdvisor?: Maybe<Scalars['String']>;
};

export type YoungPersonConnectOrCreateInput = {
	create: YoungPersonCreateInput;
	where: YoungPersonWhereUniqueInput;
};

export type YoungPersonCreateInput = {
	aboutMe?: Maybe<Scalars['AWSJSON']>;
	avatar?: Maybe<Scalars['String']>;
	birthDateDay?: Maybe<Scalars['Int']>;
	birthDateMonth?: Maybe<Scalars['Int']>;
	birthDateYear?: Maybe<Scalars['Int']>;
	createdBy?: Maybe<YoungPersonCreatedByCreateRelationsInput>;
	createdByUserId?: Maybe<Scalars['String']>;
	dateOfBirth: Scalars['AWSDateTime'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	email?: Maybe<Scalars['AWSEmail']>;
	ethnicity?: Maybe<Scalars['String']>;
	gender: Scalars['String'];
	house?: Maybe<YoungPersonHouseCreateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	initalAssessment?: Maybe<Scalars['AWSJSON']>;
	legalStatus?: Maybe<Scalars['String']>;
	localAuthority?: Maybe<Scalars['String']>;
	locationStatus?: Maybe<YoungPersonLocationStatusCreateRelationsInput>;
	locationStatusId?: Maybe<Scalars['String']>;
	logs?: Maybe<YoungPersonLogsCreateRelationsInput>;
	medicalConditions?: Maybe<YoungPersonMedicalConditionsCreateRelationsInput>;
	missingAt?: Maybe<Scalars['AWSDateTime']>;
	mostRecentAddress?: Maybe<Scalars['String']>;
	moveInDate?: Maybe<Scalars['AWSDateTime']>;
	moveOutDate?: Maybe<Scalars['AWSDateTime']>;
	moveOutNote?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	nextOfKin?: Maybe<YoungPersonNextOfKinCreateRelationsInput>;
	nhsNumber?: Maybe<Scalars['String']>;
	niNumber?: Maybe<Scalars['String']>;
	notifications?: Maybe<YoungPersonNotificationsCreateRelationsInput>;
	occupancy?: Maybe<YoungPersonOccupancyCreateRelationsInput>;
	phone: Scalars['String'];
	photoConcept?: Maybe<Scalars['Boolean']>;
	physicalDescription?: Maybe<Scalars['String']>;
	preferredName?: Maybe<Scalars['String']>;
	registeredDentist?: Maybe<Scalars['String']>;
	registeredGP?: Maybe<Scalars['String']>;
	registeredOptician?: Maybe<Scalars['String']>;
	religion?: Maybe<Scalars['String']>;
	riskAssessment?: Maybe<Scalars['AWSJSON']>;
	room?: Maybe<YoungPersonRoomCreateRelationsInput>;
	safePlace?: Maybe<Scalars['String']>;
	socialWorker?: Maybe<Scalars['String']>;
	spokenLanguage?: Maybe<Scalars['String']>;
	status?: Maybe<YoungPersonStatus>;
	tasks?: Maybe<YoungPersonTasksCreateRelationsInput>;
	timedEvents?: Maybe<YoungPersonTimedEventsCreateRelationsInput>;
	ypAdvisor?: Maybe<Scalars['String']>;
};

export type YoungPersonCreateManyInput = {
	aboutMe?: Maybe<Scalars['AWSJSON']>;
	avatar?: Maybe<Scalars['String']>;
	birthDateDay?: Maybe<Scalars['Int']>;
	birthDateMonth?: Maybe<Scalars['Int']>;
	birthDateYear?: Maybe<Scalars['Int']>;
	createdByUserId?: Maybe<Scalars['String']>;
	dateOfBirth: Scalars['AWSDateTime'];
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	email?: Maybe<Scalars['AWSEmail']>;
	ethnicity?: Maybe<Scalars['String']>;
	gender: Scalars['String'];
	houseId?: Maybe<Scalars['String']>;
	initalAssessment?: Maybe<Scalars['AWSJSON']>;
	legalStatus?: Maybe<Scalars['String']>;
	localAuthority?: Maybe<Scalars['String']>;
	locationStatusId?: Maybe<Scalars['String']>;
	missingAt?: Maybe<Scalars['AWSDateTime']>;
	mostRecentAddress?: Maybe<Scalars['String']>;
	moveInDate?: Maybe<Scalars['AWSDateTime']>;
	moveOutDate?: Maybe<Scalars['AWSDateTime']>;
	moveOutNote?: Maybe<Scalars['String']>;
	name: Scalars['String'];
	nhsNumber?: Maybe<Scalars['String']>;
	niNumber?: Maybe<Scalars['String']>;
	phone: Scalars['String'];
	photoConcept?: Maybe<Scalars['Boolean']>;
	physicalDescription?: Maybe<Scalars['String']>;
	preferredName?: Maybe<Scalars['String']>;
	registeredDentist?: Maybe<Scalars['String']>;
	registeredGP?: Maybe<Scalars['String']>;
	registeredOptician?: Maybe<Scalars['String']>;
	religion?: Maybe<Scalars['String']>;
	riskAssessment?: Maybe<Scalars['AWSJSON']>;
	safePlace?: Maybe<Scalars['String']>;
	socialWorker?: Maybe<Scalars['String']>;
	spokenLanguage?: Maybe<Scalars['String']>;
	status?: Maybe<YoungPersonStatus>;
	ypAdvisor?: Maybe<Scalars['String']>;
};

export type YoungPersonCreatedByCreateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
};

export type YoungPersonCreatedByUpdateRelationsInput = {
	connect?: Maybe<UserWhereUniqueInput>;
	connectOrCreate?: Maybe<UserConnectOrCreateInput>;
	create?: Maybe<UserCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<UserUpdateInput>;
	upsert?: Maybe<UserUpsertInput>;
};

export type YoungPersonDeleteManyInput = {
	where: YoungPersonWhereFilterInput;
};

export type YoungPersonDeleteUniqueInput = {
	where: YoungPersonWhereUniqueInput;
};

export type YoungPersonFilterRelationsInput = {
	every?: Maybe<YoungPersonWhereFilterInput>;
	none?: Maybe<YoungPersonWhereFilterInput>;
	some?: Maybe<YoungPersonWhereFilterInput>;
};

export type YoungPersonHouseCreateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
};

export type YoungPersonHouseUpdateRelationsInput = {
	connect?: Maybe<HouseWhereUniqueInput>;
	connectOrCreate?: Maybe<HouseConnectOrCreateInput>;
	create?: Maybe<HouseCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<HouseUpdateInput>;
	upsert?: Maybe<HouseUpsertInput>;
};

export type YoungPersonLocationStatus = {
	__typename?: 'YoungPersonLocationStatus';
	createdAt?: Maybe<Scalars['AWSDateTime']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	id: Scalars['String'];
	organisation: Organisation;
	organisationId: Scalars['String'];
	priority?: Maybe<Scalars['Int']>;
	title: Scalars['String'];
	type: YoungPersonLocationStatusType;
	updatedAt?: Maybe<Scalars['AWSDateTime']>;
	youngPeople?: Maybe<Array<YoungPerson>>;
};

export type YoungPersonLocationStatusConnectOrCreateInput = {
	create: YoungPersonLocationStatusCreateInput;
	where: YoungPersonLocationStatusWhereUniqueInput;
};

export type YoungPersonLocationStatusCreateInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	organisation: YoungPersonLocationStatusOrganisationCreateRelationsInput;
	organisationId: Scalars['String'];
	priority?: Maybe<Scalars['Int']>;
	title: Scalars['String'];
	type: YoungPersonLocationStatusType;
	youngPeople?: Maybe<YoungPersonLocationStatusYoungPeopleCreateRelationsInput>;
};

export type YoungPersonLocationStatusCreateManyInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	organisationId: Scalars['String'];
	priority?: Maybe<Scalars['Int']>;
	title: Scalars['String'];
	type: YoungPersonLocationStatusType;
};

export type YoungPersonLocationStatusCreateRelationsInput = {
	connect?: Maybe<YoungPersonLocationStatusWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonLocationStatusConnectOrCreateInput>;
	create?: Maybe<YoungPersonLocationStatusCreateInput>;
};

export type YoungPersonLocationStatusDeleteManyInput = {
	where: YoungPersonLocationStatusWhereFilterInput;
};

export type YoungPersonLocationStatusDeleteUniqueInput = {
	where: YoungPersonLocationStatusWhereUniqueInput;
};

export type YoungPersonLocationStatusFilterRelationsInput = {
	every?: Maybe<YoungPersonLocationStatusWhereFilterInput>;
	none?: Maybe<YoungPersonLocationStatusWhereFilterInput>;
	some?: Maybe<YoungPersonLocationStatusWhereFilterInput>;
};

export type YoungPersonLocationStatusOrderByInput = {
	createdAt?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	organisationId?: Maybe<OrderByArg>;
	priority?: Maybe<OrderByArg>;
	title?: Maybe<OrderByArg>;
	type?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
};

export type YoungPersonLocationStatusOrganisationCreateRelationsInput = {
	connect?: Maybe<OrganisationWhereUniqueInput>;
	connectOrCreate?: Maybe<OrganisationConnectOrCreateInput>;
	create?: Maybe<OrganisationCreateInput>;
};

export type YoungPersonLocationStatusOrganisationUpdateRelationsInput = {
	connect?: Maybe<OrganisationWhereUniqueInput>;
	connectOrCreate?: Maybe<OrganisationConnectOrCreateInput>;
	create?: Maybe<OrganisationCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<OrganisationUpdateInput>;
	upsert?: Maybe<OrganisationUpsertInput>;
};

export enum YoungPersonLocationStatusType {
	Authorised = 'AUTHORISED',
	Custom = 'CUSTOM',
	Home = 'HOME',
	Missing = 'MISSING',
	Out = 'OUT',
	Unauthorised = 'UNAUTHORISED',
}

export type YoungPersonLocationStatusTypeEnumFilterInput = {
	equals?: Maybe<YoungPersonLocationStatusType>;
	in?: Maybe<Array<YoungPersonLocationStatusType>>;
	not?: Maybe<YoungPersonLocationStatusTypeEnumFilterInput>;
	notIn?: Maybe<Array<YoungPersonLocationStatusType>>;
};

export type YoungPersonLocationStatusUpdateInput = {
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	organisation?: Maybe<YoungPersonLocationStatusOrganisationUpdateRelationsInput>;
	organisationId?: Maybe<Scalars['String']>;
	priority?: Maybe<Scalars['Int']>;
	title?: Maybe<Scalars['String']>;
	type?: Maybe<YoungPersonLocationStatusType>;
	youngPeople?: Maybe<YoungPersonLocationStatusYoungPeopleUpdateRelationsInput>;
};

export type YoungPersonLocationStatusUpdateManyInput = {
	data: YoungPersonLocationStatusUpdateInput;
	where: YoungPersonLocationStatusWhereFilterInput;
};

export type YoungPersonLocationStatusUpdateRelationsInput = {
	connect?: Maybe<YoungPersonLocationStatusWhereUniqueInput>;
	connectOrCreate?: Maybe<YoungPersonLocationStatusConnectOrCreateInput>;
	create?: Maybe<YoungPersonLocationStatusCreateInput>;
	delete?: Maybe<Scalars['Boolean']>;
	disconnect?: Maybe<Scalars['Boolean']>;
	update?: Maybe<YoungPersonLocationStatusUpdateInput>;
	upsert?: Maybe<YoungPersonLocationStatusUpsertInput>;
};

export type YoungPersonLocationStatusUpdateUniqueInput = {
	data: YoungPersonLocationStatusUpdateInput;
	where: YoungPersonLocationStatusWhereUniqueInput;
};

export type YoungPersonLocationStatusUpsertInput = {
	create: YoungPersonLocationStatusCreateInput;
	update: YoungPersonLocationStatusUpdateInput;
};

export type YoungPersonLocationStatusUpsertUniqueInput = {
	create: YoungPersonLocationStatusCreateInput;
	update: YoungPersonLocationStatusUpdateInput;
	where: YoungPersonLocationStatusWhereUniqueInput;
};

export type YoungPersonLocationStatusWhereFilterInput = {
	AND?: Maybe<Array<Maybe<YoungPersonLocationStatusWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<YoungPersonLocationStatusWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<YoungPersonLocationStatusWhereFilterInput>>>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	id?: Maybe<StringFilterInput>;
	organisation?: Maybe<OrganisationWhereFilterInput>;
	organisationId?: Maybe<StringFilterInput>;
	priority?: Maybe<IntFilterInput>;
	title?: Maybe<StringFilterInput>;
	type?: Maybe<YoungPersonLocationStatusTypeEnumFilterInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	youngPeople?: Maybe<YoungPersonFilterRelationsInput>;
};

export type YoungPersonLocationStatusWhereUniqueInput = {
	id?: Maybe<Scalars['String']>;
	organisationId?: Maybe<Scalars['String']>;
};

export type YoungPersonLocationStatusYoungPeopleCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<YoungPersonConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<YoungPersonCreateInput>>>;
};

export type YoungPersonLocationStatusYoungPeopleUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<YoungPersonConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<YoungPersonCreateInput>>>;
	delete?: Maybe<Array<Maybe<YoungPersonDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<YoungPersonDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<YoungPersonWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<YoungPersonUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<YoungPersonUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<YoungPersonUpsertUniqueInput>>>;
};

export type YoungPersonLogsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<LogConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<LogCreateInput>>>;
};

export type YoungPersonLogsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<LogConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<LogCreateInput>>>;
	delete?: Maybe<Array<Maybe<LogDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<LogDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<LogWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<LogUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<LogUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<LogUpsertUniqueInput>>>;
};

export type YoungPersonMedicalConditionsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<MedicalConditionWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<MedicalConditionConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<MedicalConditionCreateInput>>>;
};

export type YoungPersonMedicalConditionsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<MedicalConditionWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<MedicalConditionConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<MedicalConditionCreateInput>>>;
	delete?: Maybe<Array<Maybe<MedicalConditionDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<MedicalConditionDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<MedicalConditionWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<MedicalConditionWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<MedicalConditionUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<MedicalConditionUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<MedicalConditionUpsertUniqueInput>>>;
};

export type YoungPersonNextOfKinCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<NextOfKinWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<NextOfKinConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<NextOfKinCreateInput>>>;
};

export type YoungPersonNextOfKinUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<NextOfKinWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<NextOfKinConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<NextOfKinCreateInput>>>;
	delete?: Maybe<Array<Maybe<NextOfKinDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<NextOfKinDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<NextOfKinWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<NextOfKinWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<NextOfKinUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<NextOfKinUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<NextOfKinUpsertUniqueInput>>>;
};

export type YoungPersonNotificationsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<NotificationConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<NotificationCreateInput>>>;
};

export type YoungPersonNotificationsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<NotificationConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<NotificationCreateInput>>>;
	delete?: Maybe<Array<Maybe<NotificationDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<NotificationDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<NotificationWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<NotificationUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<NotificationUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<NotificationUpsertUniqueInput>>>;
};

export type YoungPersonOccupancyCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<OccupancyLogWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<OccupancyLogConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<OccupancyLogCreateInput>>>;
};

export type YoungPersonOccupancyUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<OccupancyLogWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<OccupancyLogConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<OccupancyLogCreateInput>>>;
	delete?: Maybe<Array<Maybe<OccupancyLogDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<OccupancyLogDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<OccupancyLogWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<OccupancyLogWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<OccupancyLogUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<OccupancyLogUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<OccupancyLogUpsertUniqueInput>>>;
};

export type YoungPersonOrderByInput = {
	aboutMe?: Maybe<OrderByArg>;
	avatar?: Maybe<OrderByArg>;
	birthDateDay?: Maybe<OrderByArg>;
	birthDateMonth?: Maybe<OrderByArg>;
	birthDateYear?: Maybe<OrderByArg>;
	createdAt?: Maybe<OrderByArg>;
	createdByUserId?: Maybe<OrderByArg>;
	dateOfBirth?: Maybe<OrderByArg>;
	deletedAt?: Maybe<OrderByArg>;
	email?: Maybe<OrderByArg>;
	ethnicity?: Maybe<OrderByArg>;
	gender?: Maybe<OrderByArg>;
	houseId?: Maybe<OrderByArg>;
	id?: Maybe<OrderByArg>;
	initalAssessment?: Maybe<OrderByArg>;
	legalStatus?: Maybe<OrderByArg>;
	localAuthority?: Maybe<OrderByArg>;
	locationStatusId?: Maybe<OrderByArg>;
	missingAt?: Maybe<OrderByArg>;
	mostRecentAddress?: Maybe<OrderByArg>;
	moveInDate?: Maybe<OrderByArg>;
	moveOutDate?: Maybe<OrderByArg>;
	moveOutNote?: Maybe<OrderByArg>;
	name?: Maybe<OrderByArg>;
	nhsNumber?: Maybe<OrderByArg>;
	niNumber?: Maybe<OrderByArg>;
	phone?: Maybe<OrderByArg>;
	photoConcept?: Maybe<OrderByArg>;
	physicalDescription?: Maybe<OrderByArg>;
	preferredName?: Maybe<OrderByArg>;
	registeredDentist?: Maybe<OrderByArg>;
	registeredGP?: Maybe<OrderByArg>;
	registeredOptician?: Maybe<OrderByArg>;
	religion?: Maybe<OrderByArg>;
	riskAssessment?: Maybe<OrderByArg>;
	safePlace?: Maybe<OrderByArg>;
	socialWorker?: Maybe<OrderByArg>;
	spokenLanguage?: Maybe<OrderByArg>;
	status?: Maybe<OrderByArg>;
	updatedAt?: Maybe<OrderByArg>;
	ypAdvisor?: Maybe<OrderByArg>;
};

export type YoungPersonRoomCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<RoomWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<RoomConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<RoomCreateInput>>>;
};

export type YoungPersonRoomUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<RoomWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<RoomConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<RoomCreateInput>>>;
	delete?: Maybe<Array<Maybe<RoomDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<RoomDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<RoomWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<RoomWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<RoomUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<RoomUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<RoomUpsertUniqueInput>>>;
};

export enum YoungPersonStatus {
	Declined = 'DECLINED',
	InHome = 'IN_HOME',
	MovedOut = 'MOVED_OUT',
	MovingOut = 'MOVING_OUT',
	New = 'NEW',
}

export type YoungPersonStatusEnumFilterInput = {
	equals?: Maybe<YoungPersonStatus>;
	in?: Maybe<Array<YoungPersonStatus>>;
	not?: Maybe<YoungPersonStatusEnumFilterInput>;
	notIn?: Maybe<Array<YoungPersonStatus>>;
};

export type YoungPersonTasksCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TaskConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TaskCreateInput>>>;
};

export type YoungPersonTasksUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TaskConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TaskCreateInput>>>;
	delete?: Maybe<Array<Maybe<TaskDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<TaskDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<TaskWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<TaskUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<TaskUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<TaskUpsertUniqueInput>>>;
};

export type YoungPersonTimedEventsCreateRelationsInput = {
	connect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TimedEventConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TimedEventCreateInput>>>;
};

export type YoungPersonTimedEventsUpdateRelationsInput = {
	connect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	connectOrCreate?: Maybe<Array<Maybe<TimedEventConnectOrCreateInput>>>;
	create?: Maybe<Array<Maybe<TimedEventCreateInput>>>;
	delete?: Maybe<Array<Maybe<TimedEventDeleteUniqueInput>>>;
	deleteMany?: Maybe<Array<Maybe<TimedEventDeleteManyInput>>>;
	disconnect?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	set?: Maybe<Array<Maybe<TimedEventWhereUniqueInput>>>;
	update?: Maybe<Array<Maybe<TimedEventUpdateUniqueInput>>>;
	updateMany?: Maybe<Array<Maybe<TimedEventUpdateManyInput>>>;
	upsert?: Maybe<Array<Maybe<TimedEventUpsertUniqueInput>>>;
};

export type YoungPersonUpdateInput = {
	aboutMe?: Maybe<Scalars['AWSJSON']>;
	avatar?: Maybe<Scalars['String']>;
	birthDateDay?: Maybe<Scalars['Int']>;
	birthDateMonth?: Maybe<Scalars['Int']>;
	birthDateYear?: Maybe<Scalars['Int']>;
	createdBy?: Maybe<YoungPersonCreatedByUpdateRelationsInput>;
	createdByUserId?: Maybe<Scalars['String']>;
	dateOfBirth?: Maybe<Scalars['AWSDateTime']>;
	deletedAt?: Maybe<Scalars['AWSDateTime']>;
	email?: Maybe<Scalars['AWSEmail']>;
	ethnicity?: Maybe<Scalars['String']>;
	gender?: Maybe<Scalars['String']>;
	house?: Maybe<YoungPersonHouseUpdateRelationsInput>;
	houseId?: Maybe<Scalars['String']>;
	initalAssessment?: Maybe<Scalars['AWSJSON']>;
	legalStatus?: Maybe<Scalars['String']>;
	localAuthority?: Maybe<Scalars['String']>;
	locationStatus?: Maybe<YoungPersonLocationStatusUpdateRelationsInput>;
	locationStatusId?: Maybe<Scalars['String']>;
	logs?: Maybe<YoungPersonLogsUpdateRelationsInput>;
	medicalConditions?: Maybe<YoungPersonMedicalConditionsUpdateRelationsInput>;
	missingAt?: Maybe<Scalars['AWSDateTime']>;
	mostRecentAddress?: Maybe<Scalars['String']>;
	moveInDate?: Maybe<Scalars['AWSDateTime']>;
	moveOutDate?: Maybe<Scalars['AWSDateTime']>;
	moveOutNote?: Maybe<Scalars['String']>;
	name?: Maybe<Scalars['String']>;
	nextOfKin?: Maybe<YoungPersonNextOfKinUpdateRelationsInput>;
	nhsNumber?: Maybe<Scalars['String']>;
	niNumber?: Maybe<Scalars['String']>;
	notifications?: Maybe<YoungPersonNotificationsUpdateRelationsInput>;
	occupancy?: Maybe<YoungPersonOccupancyUpdateRelationsInput>;
	phone?: Maybe<Scalars['String']>;
	photoConcept?: Maybe<Scalars['Boolean']>;
	physicalDescription?: Maybe<Scalars['String']>;
	preferredName?: Maybe<Scalars['String']>;
	registeredDentist?: Maybe<Scalars['String']>;
	registeredGP?: Maybe<Scalars['String']>;
	registeredOptician?: Maybe<Scalars['String']>;
	religion?: Maybe<Scalars['String']>;
	riskAssessment?: Maybe<Scalars['AWSJSON']>;
	room?: Maybe<YoungPersonRoomUpdateRelationsInput>;
	safePlace?: Maybe<Scalars['String']>;
	socialWorker?: Maybe<Scalars['String']>;
	spokenLanguage?: Maybe<Scalars['String']>;
	status?: Maybe<YoungPersonStatus>;
	tasks?: Maybe<YoungPersonTasksUpdateRelationsInput>;
	timedEvents?: Maybe<YoungPersonTimedEventsUpdateRelationsInput>;
	ypAdvisor?: Maybe<Scalars['String']>;
};

export type YoungPersonUpdateManyInput = {
	data: YoungPersonUpdateInput;
	where: YoungPersonWhereFilterInput;
};

export type YoungPersonUpdateUniqueInput = {
	data: YoungPersonUpdateInput;
	where: YoungPersonWhereUniqueInput;
};

export type YoungPersonUpsertInput = {
	create: YoungPersonCreateInput;
	update: YoungPersonUpdateInput;
};

export type YoungPersonUpsertUniqueInput = {
	create: YoungPersonCreateInput;
	update: YoungPersonUpdateInput;
	where: YoungPersonWhereUniqueInput;
};

export type YoungPersonWhereFilterInput = {
	AND?: Maybe<Array<Maybe<YoungPersonWhereFilterInput>>>;
	NOT?: Maybe<Array<Maybe<YoungPersonWhereFilterInput>>>;
	OR?: Maybe<Array<Maybe<YoungPersonWhereFilterInput>>>;
	aboutMe?: Maybe<AwsjsonFilterInput>;
	avatar?: Maybe<StringFilterInput>;
	birthDateDay?: Maybe<IntFilterInput>;
	birthDateMonth?: Maybe<IntFilterInput>;
	birthDateYear?: Maybe<IntFilterInput>;
	createdAt?: Maybe<AwsDateTimeFilterInput>;
	createdBy?: Maybe<UserWhereFilterInput>;
	createdByUserId?: Maybe<StringFilterInput>;
	dateOfBirth?: Maybe<AwsDateTimeFilterInput>;
	deletedAt?: Maybe<AwsDateTimeFilterInput>;
	email?: Maybe<AwsEmailFilterInput>;
	ethnicity?: Maybe<StringFilterInput>;
	gender?: Maybe<StringFilterInput>;
	house?: Maybe<HouseWhereFilterInput>;
	houseId?: Maybe<StringFilterInput>;
	id?: Maybe<StringFilterInput>;
	initalAssessment?: Maybe<AwsjsonFilterInput>;
	legalStatus?: Maybe<StringFilterInput>;
	localAuthority?: Maybe<StringFilterInput>;
	locationStatus?: Maybe<YoungPersonLocationStatusWhereFilterInput>;
	locationStatusId?: Maybe<StringFilterInput>;
	logs?: Maybe<LogFilterRelationsInput>;
	medicalConditions?: Maybe<MedicalConditionFilterRelationsInput>;
	missingAt?: Maybe<AwsDateTimeFilterInput>;
	mostRecentAddress?: Maybe<StringFilterInput>;
	moveInDate?: Maybe<AwsDateTimeFilterInput>;
	moveOutDate?: Maybe<AwsDateTimeFilterInput>;
	moveOutNote?: Maybe<StringFilterInput>;
	name?: Maybe<StringFilterInput>;
	nextOfKin?: Maybe<NextOfKinFilterRelationsInput>;
	nhsNumber?: Maybe<StringFilterInput>;
	niNumber?: Maybe<StringFilterInput>;
	notifications?: Maybe<NotificationFilterRelationsInput>;
	occupancy?: Maybe<OccupancyLogFilterRelationsInput>;
	phone?: Maybe<StringFilterInput>;
	photoConcept?: Maybe<BooleanFilterInput>;
	physicalDescription?: Maybe<StringFilterInput>;
	preferredName?: Maybe<StringFilterInput>;
	registeredDentist?: Maybe<StringFilterInput>;
	registeredGP?: Maybe<StringFilterInput>;
	registeredOptician?: Maybe<StringFilterInput>;
	religion?: Maybe<StringFilterInput>;
	riskAssessment?: Maybe<AwsjsonFilterInput>;
	room?: Maybe<RoomFilterRelationsInput>;
	safePlace?: Maybe<StringFilterInput>;
	socialWorker?: Maybe<StringFilterInput>;
	spokenLanguage?: Maybe<StringFilterInput>;
	status?: Maybe<YoungPersonStatusEnumFilterInput>;
	tasks?: Maybe<TaskFilterRelationsInput>;
	timedEvents?: Maybe<TimedEventFilterRelationsInput>;
	updatedAt?: Maybe<AwsDateTimeFilterInput>;
	ypAdvisor?: Maybe<StringFilterInput>;
};

export type YoungPersonWhereUniqueInput = {
	createdByUserId?: Maybe<Scalars['String']>;
	houseId?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['String']>;
	locationStatusId?: Maybe<Scalars['String']>;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
	__typename?: 'Query';
	me?:
		| {
				__typename?: 'User';
				id: string;
				name?: string | null | undefined;
				firstName: string;
				lastName: string;
				email: any;
				role: Role;
				houseId?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type GetUploadUrlMutationVariables = Exact<{
	userId: Scalars['String'];
	fileType: Scalars['String'];
	userType: AvatarUserType;
}>;

export type GetUploadUrlMutation = {
	__typename?: 'Mutation';
	createUploadAvatarUrl?:
		| {
				__typename?: 'UrlResponse';
				url: string;
				statusCode?: number | null | undefined;
		  }
		| null
		| undefined;
};

export type CreateDownloadLinkMutationVariables = Exact<{
	fileKey: Scalars['String'];
}>;

export type CreateDownloadLinkMutation = {
	__typename?: 'Mutation';
	createDownloadUrl?:
		| {
				__typename?: 'UrlResponse';
				url: string;
				statusCode?: number | null | undefined;
		  }
		| null
		| undefined;
};

export type CreateAlertMutationVariables = Exact<{
	data: NotificationCreateInput;
}>;

export type CreateAlertMutation = {
	__typename?: 'Mutation';
	createNotification?:
		| {
				__typename?: 'Notification';
				id: string;
				title: string;
				severity?: SeverityLevel | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				readAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type CreateHouseMutationVariables = Exact<{
	data: HouseCreateInput;
}>;

export type CreateHouseMutation = {
	__typename?: 'Mutation';
	createHouse?:
		| {
				__typename?: 'House';
				id: string;
				title: string;
				address?: string | null | undefined;
				color?: string | null | undefined;
				preMoveInHouse?: boolean | null | undefined;
		  }
		| null
		| undefined;
};

export type InviteUserMutationVariables = Exact<{
	data: UserCreateInput;
}>;

export type InviteUserMutation = {
	__typename?: 'Mutation';
	createUser?: { __typename?: 'User'; id: string } | null | undefined;
};

export type CheckUserWithEmailQueryVariables = Exact<{
	email?: Maybe<Scalars['AWSEmail']>;
}>;

export type CheckUserWithEmailQuery = {
	__typename?: 'Query';
	getUser?: { __typename?: 'User'; id: string } | null | undefined;
};

export type ActivityFragmentFragment = {
	__typename?: 'Log';
	id: string;
	type: LogType;
	data: any;
	createdAt?: any | null | undefined;
	createdBy: string;
	youngPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				avatar?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type GetActivityLogQueryVariables = Exact<{
	id: Scalars['String'];
}>;

export type GetActivityLogQuery = {
	__typename?: 'Query';
	getLog?:
		| {
				__typename?: 'Log';
				id: string;
				type: LogType;
				data: any;
				createdAt?: any | null | undefined;
				createdBy: string;
				youngPerson?:
					| {
							__typename?: 'YoungPerson';
							id: string;
							name: string;
							avatar?: string | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type FormListYoungPeopleQueryVariables = Exact<{
	where?: Maybe<YoungPersonWhereFilterInput>;
}>;

export type FormListYoungPeopleQuery = {
	__typename?: 'Query';
	listYoungPeople?:
		| Array<
				| { __typename?: 'YoungPerson'; id: string; name: string }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type UpdateActivityLogMutationVariables = Exact<{
	id: Scalars['String'];
	data: LogUpdateInput;
}>;

export type UpdateActivityLogMutation = {
	__typename?: 'Mutation';
	updateLog?: { __typename?: 'Log'; id: string } | null | undefined;
};

export type CreateActivityLogMutationVariables = Exact<{
	data: LogCreateInput;
}>;

export type CreateActivityLogMutation = {
	__typename?: 'Mutation';
	createLog?: { __typename?: 'Log'; id: string } | null | undefined;
};

export type CreateLocationLogMutationVariables = Exact<{
	logData: LogCreateInput;
	locationStatusId: Scalars['String'];
	youngPersonId: Scalars['String'];
	missingAt?: Maybe<Scalars['AWSDateTime']>;
}>;

export type CreateLocationLogMutation = {
	__typename?: 'Mutation';
	createLog?: { __typename?: 'Log'; id: string } | null | undefined;
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				avatar?: string | null | undefined;
				locationStatusId?: string | null | undefined;
				missingAt?: any | null | undefined;
				locationStatus?:
					| {
							__typename?: 'YoungPersonLocationStatus';
							id: string;
							title: string;
							type: YoungPersonLocationStatusType;
							priority?: number | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type ListAdminUsersQueryVariables = Exact<{ [key: string]: never }>;

export type ListAdminUsersQuery = {
	__typename?: 'Query';
	listUsers?:
		| Array<
				| { __typename?: 'User'; id: string; name?: string | null | undefined }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type MedicalConditionFragmentFragment = {
	__typename?: 'MedicalCondition';
	id: string;
	title: string;
	symptoms?: string | null | undefined;
	triggers?: string | null | undefined;
	medication?: string | null | undefined;
	administrationDetails?: string | null | undefined;
	inEmergency?: string | null | undefined;
};

export type GetYoungPersonsMedicalConditionQueryVariables = Exact<{
	medicalConditionId: Scalars['String'];
}>;

export type GetYoungPersonsMedicalConditionQuery = {
	__typename?: 'Query';
	getMedicalCondition?:
		| {
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				inEmergency?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type UpdateMedicalConditionMutationVariables = Exact<{
	medicalConditionId: Scalars['String'];
	data: MedicalConditionUpdateInput;
}>;

export type UpdateMedicalConditionMutation = {
	__typename?: 'Mutation';
	updateMedicalCondition?:
		| {
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				inEmergency?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type CreateMedicalConditionMutationVariables = Exact<{
	data: MedicalConditionCreateInput;
}>;

export type CreateMedicalConditionMutation = {
	__typename?: 'Mutation';
	createMedicalCondition?:
		| {
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				inEmergency?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type NextOfKinFragmentFragment = {
	__typename?: 'NextOfKin';
	id: string;
	name: string;
	relation: string;
	phone: string;
	contactable?: boolean | null | undefined;
	visitable?: boolean | null | undefined;
	note?: string | null | undefined;
};

export type GetNextOfKinQueryVariables = Exact<{
	nextOfKinId: Scalars['String'];
}>;

export type GetNextOfKinQuery = {
	__typename?: 'Query';
	getNextOfKin?:
		| {
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
				contactable?: boolean | null | undefined;
				visitable?: boolean | null | undefined;
				note?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type UpdateNextOfKinMutationVariables = Exact<{
	nextOfKinId: Scalars['String'];
	data: NextOfKinUpdateInput;
}>;

export type UpdateNextOfKinMutation = {
	__typename?: 'Mutation';
	updateNextOfKin?:
		| {
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
				contactable?: boolean | null | undefined;
				visitable?: boolean | null | undefined;
				note?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type CreateNextOfKinMutationVariables = Exact<{
	data: NextOfKinCreateInput;
}>;

export type CreateNextOfKinMutation = {
	__typename?: 'Mutation';
	createNextOfKin?:
		| {
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
				contactable?: boolean | null | undefined;
				visitable?: boolean | null | undefined;
				note?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type SimpleRoomFragment = {
	__typename?: 'Room';
	id: string;
	name: string;
	color: string;
	youngPerson?: { __typename: 'YoungPerson'; name: string } | null | undefined;
};

export type CreateRoomMutationVariables = Exact<{
	data: RoomCreateInput;
}>;

export type CreateRoomMutation = {
	__typename?: 'Mutation';
	createRoom?:
		| {
				__typename?: 'Room';
				id: string;
				name: string;
				color: string;
				youngPerson?:
					| { __typename: 'YoungPerson'; name: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UpdateRoomMutationVariables = Exact<{
	id?: Maybe<Scalars['String']>;
	data: RoomUpdateInput;
}>;

export type UpdateRoomMutation = {
	__typename?: 'Mutation';
	updateRoom?:
		| {
				__typename?: 'Room';
				id: string;
				name: string;
				color: string;
				youngPerson?:
					| { __typename: 'YoungPerson'; name: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type GetRoomQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
}>;

export type GetRoomQuery = {
	__typename?: 'Query';
	getRoom?:
		| {
				__typename?: 'Room';
				id: string;
				name: string;
				color: string;
				youngPerson?:
					| { __typename: 'YoungPerson'; name: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type HouseWithUsersFragment = {
	__typename?: 'House';
	id: string;
	title: string;
	users?:
		| Array<{
				__typename?: 'User';
				id: string;
				name?: string | null | undefined;
		  }>
		| null
		| undefined;
};

export type BasicYoungPersonIdentityFragment = {
	__typename?: 'YoungPerson';
	id: string;
	name: string;
};

export type GetTaskCreateFormDataByHouseIdQueryVariables = Exact<{
	houseId: Scalars['String'];
}>;

export type GetTaskCreateFormDataByHouseIdQuery = {
	__typename?: 'Query';
	getHouse?:
		| {
				__typename?: 'House';
				id: string;
				title: string;
				youngPeople?:
					| Array<{ __typename?: 'YoungPerson'; id: string; name: string }>
					| null
					| undefined;
				users?:
					| Array<{
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
					  }>
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type GetTaskCreateFormDataByYoungPersonIdQueryVariables = Exact<{
	youngPersonId: Scalars['String'];
}>;

export type GetTaskCreateFormDataByYoungPersonIdQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				house?:
					| {
							__typename?: 'House';
							id: string;
							title: string;
							users?:
								| Array<{
										__typename?: 'User';
										id: string;
										name?: string | null | undefined;
								  }>
								| null
								| undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type CreateNewTaskMutationVariables = Exact<{
	data: TaskCreateInput;
}>;

export type CreateNewTaskMutation = {
	__typename?: 'Mutation';
	createTask?:
		| {
				__typename?: 'Task';
				id: string;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				title: string;
				description?: string | null | undefined;
				group?: TaskGroup | null | undefined;
				youngPersonId?: string | null | undefined;
				completedAt?: any | null | undefined;
				repeat?: RepeatOption | null | undefined;
				dueDate?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				createdAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UpdateTaskMutationVariables = Exact<{
	data: TaskUpdateInput;
	id?: Maybe<Scalars['String']>;
}>;

export type UpdateTaskMutation = {
	__typename?: 'Mutation';
	updateTask?:
		| {
				__typename?: 'Task';
				id: string;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				title: string;
				description?: string | null | undefined;
				group?: TaskGroup | null | undefined;
				youngPersonId?: string | null | undefined;
				completedAt?: any | null | undefined;
				repeat?: RepeatOption | null | undefined;
				dueDate?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				createdAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UserDetailsFragment = {
	__typename?: 'User';
	id: string;
	firstName: string;
	lastName: string;
	phone?: string | null | undefined;
	address?: string | null | undefined;
	email: any;
	houseId?: string | null | undefined;
	disabled?: boolean | null | undefined;
	role: Role;
};

export type GetUserQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
}>;

export type GetUserQuery = {
	__typename?: 'Query';
	getUser?:
		| {
				__typename?: 'User';
				id: string;
				firstName: string;
				lastName: string;
				phone?: string | null | undefined;
				address?: string | null | undefined;
				email: any;
				houseId?: string | null | undefined;
				disabled?: boolean | null | undefined;
				role: Role;
		  }
		| null
		| undefined;
};

export type UpdateUserMutationVariables = Exact<{
	id?: Maybe<Scalars['String']>;
	data: UserUpdateInput;
}>;

export type UpdateUserMutation = {
	__typename?: 'Mutation';
	updateUser?:
		| {
				__typename?: 'User';
				id: string;
				firstName: string;
				lastName: string;
				phone?: string | null | undefined;
				address?: string | null | undefined;
				email: any;
				houseId?: string | null | undefined;
				disabled?: boolean | null | undefined;
				role: Role;
		  }
		| null
		| undefined;
};

export type YoungPersonAboutMeFragment = {
	__typename?: 'YoungPerson';
	id: string;
	aboutMe?: any | null | undefined;
};

export type GetYoungPersonAboutMeDetailsQueryVariables = Exact<{
	youngPersonId: Scalars['String'];
}>;

export type GetYoungPersonAboutMeDetailsQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				aboutMe?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type YoungPersonBasicDetailsFragment = {
	__typename?: 'YoungPerson';
	id: string;
	name: string;
	preferredName?: string | null | undefined;
	email?: any | null | undefined;
	phone: string;
	dateOfBirth: any;
	gender: string;
	mostRecentAddress?: string | null | undefined;
	physicalDescription?: string | null | undefined;
	ethnicity?: string | null | undefined;
	spokenLanguage?: string | null | undefined;
	safePlace?: string | null | undefined;
	religion?: string | null | undefined;
};

export type GetYoungPersonBasicDetailsQueryVariables = Exact<{
	youngPersonId: Scalars['String'];
}>;

export type GetYoungPersonBasicDetailsQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				physicalDescription?: string | null | undefined;
				ethnicity?: string | null | undefined;
				spokenLanguage?: string | null | undefined;
				safePlace?: string | null | undefined;
				religion?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type UpdateYoungPersonProfileMutationVariables = Exact<{
	youngPersonId: Scalars['String'];
	data: YoungPersonUpdateInput;
}>;

export type UpdateYoungPersonProfileMutation = {
	__typename?: 'Mutation';
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				ethnicity?: string | null | undefined;
				spokenLanguage?: string | null | undefined;
				safePlace?: string | null | undefined;
				religion?: string | null | undefined;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				registeredOptician?: string | null | undefined;
				registeredDentist?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				photoConcept?: boolean | null | undefined;
				riskAssessment?: any | null | undefined;
				legalStatus?: string | null | undefined;
				ypAdvisor?: string | null | undefined;
				localAuthority?: string | null | undefined;
				physicalDescription?: string | null | undefined;
				aboutMe?: any | null | undefined;
				status?: YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				nextOfKin?:
					| Array<{
							__typename?: 'NextOfKin';
							id: string;
							name: string;
							relation: string;
							phone: string;
							youngPersonId: string;
							contactable?: boolean | null | undefined;
							visitable?: boolean | null | undefined;
							note?: string | null | undefined;
					  }>
					| null
					| undefined;
				medicalConditions?:
					| Array<{
							__typename?: 'MedicalCondition';
							id: string;
							title: string;
							medication?: string | null | undefined;
							administrationDetails?: string | null | undefined;
							symptoms?: string | null | undefined;
							triggers?: string | null | undefined;
							inEmergency?: string | null | undefined;
							youngPersonId: string;
					  }>
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type HouseIdDetailsFragment = {
	__typename?: 'House';
	id: string;
	title: string;
};

export type ListHouseIdsQueryVariables = Exact<{ [key: string]: never }>;

export type ListHouseIdsQuery = {
	__typename?: 'Query';
	listHouses?:
		| Array<
				{ __typename?: 'House'; id: string; title: string } | null | undefined
		  >
		| null
		| undefined;
};

export type CreateYoungPersonProfileMutationVariables = Exact<{
	data: YoungPersonCreateInput;
}>;

export type CreateYoungPersonProfileMutation = {
	__typename?: 'Mutation';
	createYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				ethnicity?: string | null | undefined;
				spokenLanguage?: string | null | undefined;
				safePlace?: string | null | undefined;
				religion?: string | null | undefined;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				registeredOptician?: string | null | undefined;
				registeredDentist?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				photoConcept?: boolean | null | undefined;
				riskAssessment?: any | null | undefined;
				legalStatus?: string | null | undefined;
				ypAdvisor?: string | null | undefined;
				localAuthority?: string | null | undefined;
				physicalDescription?: string | null | undefined;
				aboutMe?: any | null | undefined;
				status?: YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				nextOfKin?:
					| Array<{
							__typename?: 'NextOfKin';
							id: string;
							name: string;
							relation: string;
							phone: string;
							youngPersonId: string;
							contactable?: boolean | null | undefined;
							visitable?: boolean | null | undefined;
							note?: string | null | undefined;
					  }>
					| null
					| undefined;
				medicalConditions?:
					| Array<{
							__typename?: 'MedicalCondition';
							id: string;
							title: string;
							medication?: string | null | undefined;
							administrationDetails?: string | null | undefined;
							symptoms?: string | null | undefined;
							triggers?: string | null | undefined;
							inEmergency?: string | null | undefined;
							youngPersonId: string;
					  }>
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type YoungPersonLegalDetailsFragment = {
	__typename?: 'YoungPerson';
	id: string;
	niNumber?: string | null | undefined;
	registeredGP?: string | null | undefined;
	registeredOptician?: string | null | undefined;
	registeredDentist?: string | null | undefined;
	nhsNumber?: string | null | undefined;
	socialWorker?: string | null | undefined;
	legalStatus?: string | null | undefined;
	ypAdvisor?: string | null | undefined;
	localAuthority?: string | null | undefined;
};

export type GetYoungPersonLegalDetailsQueryVariables = Exact<{
	youngPersonId: Scalars['String'];
}>;

export type GetYoungPersonLegalDetailsQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				registeredOptician?: string | null | undefined;
				registeredDentist?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				legalStatus?: string | null | undefined;
				ypAdvisor?: string | null | undefined;
				localAuthority?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type GetMoveOutHouseQueryVariables = Exact<{
	ypId: Scalars['String'];
}>;

export type GetMoveOutHouseQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				house?:
					| { __typename?: 'House'; moveOutHouseId?: string | null | undefined }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UpdateYoungPersonMoveOutMutationVariables = Exact<{
	ypId: Scalars['String'];
	date: Scalars['AWSDateTime'];
	note: Scalars['String'];
	houseId: Scalars['String'];
}>;

export type UpdateYoungPersonMoveOutMutation = {
	__typename?: 'Mutation';
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				moveOutNote?: string | null | undefined;
				moveOutDate?: any | null | undefined;
				houseId?: string | null | undefined;
		  }
		| null
		| undefined;
	updateRoom?:
		| {
				__typename?: 'Room';
				id: string;
				youngPerson?:
					| { __typename?: 'YoungPerson'; id: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type ListEmptyRoomsQueryVariables = Exact<{
	houseId: Scalars['String'];
}>;

export type ListEmptyRoomsQuery = {
	__typename?: 'Query';
	listRooms?:
		| Array<
				{ __typename?: 'Room'; id: string; name: string } | null | undefined
		  >
		| null
		| undefined;
};

export type UpdateYoungPersonRoomMutationVariables = Exact<{
	roomId: Scalars['String'];
	data: RoomUpdateInput;
}>;

export type UpdateYoungPersonRoomMutation = {
	__typename?: 'Mutation';
	updateRoom?:
		| {
				__typename?: 'Room';
				id: string;
				youngPerson?:
					| { __typename?: 'YoungPerson'; id: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UpdateYoungPersonHouseMutationVariables = Exact<{
	youngPersonId: Scalars['String'];
	data: YoungPersonUpdateInput;
}>;

export type UpdateYoungPersonHouseMutation = {
	__typename?: 'Mutation';
	updateYoungPerson?:
		| { __typename?: 'YoungPerson'; id: string }
		| null
		| undefined;
};

export type YoungPersonRiskAssessmentQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
}>;

export type YoungPersonRiskAssessmentQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| { __typename?: 'YoungPerson'; riskAssessment?: any | null | undefined }
		| null
		| undefined;
};

export type UpdateYoungPersonRiskAssessmentMutationVariables = Exact<{
	youngPersonId: Scalars['String'];
	riskAssessment: Scalars['AWSJSON'];
	createdBy: Scalars['String'];
}>;

export type UpdateYoungPersonRiskAssessmentMutation = {
	__typename?: 'Mutation';
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				riskAssessment?: any | null | undefined;
		  }
		| null
		| undefined;
	createLog?:
		| { __typename?: 'Log'; id: string; data: any; createdBy: string }
		| null
		| undefined;
};

export type CreateFileUrlMutationVariables = Exact<{
	fileType: FileType;
	houseId: Scalars['String'];
	youngPersonId?: Maybe<Scalars['String']>;
	fileName?: Maybe<Scalars['String']>;
	folderName?: Maybe<Scalars['String']>;
}>;

export type CreateFileUrlMutation = {
	__typename?: 'Mutation';
	createFileUploadUrl?:
		| {
				__typename?: 'UrlResponse';
				url: string;
				statusCode?: number | null | undefined;
		  }
		| null
		| undefined;
};

export type IsLoggedInQueryVariables = Exact<{ [key: string]: never }>;

export type IsLoggedInQuery = {
	__typename?: 'Query';
	isLoggedIn?: boolean | null | undefined;
};

export type GetNavDataQueryVariables = Exact<{
	userId: Scalars['String'];
}>;

export type GetNavDataQuery = {
	__typename?: 'Query';
	getUser?:
		| {
				__typename?: 'User';
				id: string;
				firstName: string;
				lastName: string;
				role: Role;
		  }
		| null
		| undefined;
};

export type HouseCardFragment = {
	__typename?: 'House';
	id: string;
	title: string;
	address?: string | null | undefined;
	color?: string | null | undefined;
	preMoveInHouse?: boolean | null | undefined;
};

export type ListHousesWithYoungPeopleQueryVariables = Exact<{
	term?: Maybe<Scalars['String']>;
	skip?: Maybe<Scalars['Int']>;
	take: Scalars['Int'];
}>;

export type ListHousesWithYoungPeopleQuery = {
	__typename?: 'Query';
	countHouses?: number | null | undefined;
	listHouses?:
		| Array<
				| {
						__typename?: 'House';
						id: string;
						title: string;
						address?: string | null | undefined;
						color?: string | null | undefined;
						preMoveInHouse?: boolean | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type UserWithHouseFragment = {
	__typename?: 'User';
	id: string;
	name?: string | null | undefined;
	role: Role;
	house?:
		| {
				__typename?: 'House';
				id: string;
				title: string;
				color?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type ListUsersWithHouseQueryVariables = Exact<{
	where?: Maybe<UserWhereFilterInput>;
	take?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
}>;

export type ListUsersWithHouseQuery = {
	__typename?: 'Query';
	countUsers?: number | null | undefined;
	listUsers?:
		| Array<
				| {
						__typename?: 'User';
						id: string;
						name?: string | null | undefined;
						role: Role;
						house?:
							| {
									__typename?: 'House';
									id: string;
									title: string;
									color?: string | null | undefined;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type OnTaskCreatedSubscriptionVariables = Exact<{
	youngPersonId: Scalars['String'];
}>;

export type OnTaskCreatedSubscription = {
	__typename?: 'Subscription';
	onMutatedTask?:
		| {
				__typename?: 'Task';
				id: string;
				title: string;
				isAdmin?: boolean | null | undefined;
				isPrivate?: boolean | null | undefined;
		  }
		| null
		| undefined;
};

export type UpdateLocationStatusMutationVariables = Exact<{
	id: Scalars['String'];
	data: YoungPersonLocationStatusUpdateInput;
}>;

export type UpdateLocationStatusMutation = {
	__typename?: 'Mutation';
	updateYoungPersonLocationStatus?:
		| {
				__typename?: 'YoungPersonLocationStatus';
				id: string;
				title: string;
				priority?: number | null | undefined;
				type: YoungPersonLocationStatusType;
				updatedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type GetHouseQueryVariables = Exact<{
	houseId: Scalars['String'];
}>;

export type GetHouseQuery = {
	__typename?: 'Query';
	getHouse?:
		| {
				__typename?: 'House';
				id: string;
				title: string;
				address?: string | null | undefined;
				color?: string | null | undefined;
				isMoveOutHouse?: boolean | null | undefined;
		  }
		| null
		| undefined;
};

export type YoungPersonScreenDataFragment = {
	__typename?: 'YoungPerson';
	id: string;
	name: string;
	status?: YoungPersonStatus | null | undefined;
	houseId?: string | null | undefined;
	dateOfBirth: any;
	phone: string;
	avatar?: string | null | undefined;
	missingAt?: any | null | undefined;
	house?:
		| { __typename?: 'House'; id: string; title: string }
		| null
		| undefined;
	room?:
		| { __typename?: 'Room'; id: string; name: string; color: string }
		| null
		| undefined;
	locationStatus?:
		| {
				__typename?: 'YoungPersonLocationStatus';
				id: string;
				title: string;
				type: YoungPersonLocationStatusType;
				priority?: number | null | undefined;
		  }
		| null
		| undefined;
};

export type GetYoungPersonByIdQueryVariables = Exact<{
	id: Scalars['String'];
}>;

export type GetYoungPersonByIdQuery = {
	__typename?: 'Query';
	listYoungPersonLocationStatuses?:
		| Array<
				| {
						__typename?: 'YoungPersonLocationStatus';
						id: string;
						title: string;
						type: YoungPersonLocationStatusType;
						priority?: number | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				status?: YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				dateOfBirth: any;
				phone: string;
				avatar?: string | null | undefined;
				missingAt?: any | null | undefined;
				house?:
					| { __typename?: 'House'; id: string; title: string }
					| null
					| undefined;
				room?:
					| { __typename?: 'Room'; id: string; name: string; color: string }
					| null
					| undefined;
				locationStatus?:
					| {
							__typename?: 'YoungPersonLocationStatus';
							id: string;
							title: string;
							type: YoungPersonLocationStatusType;
							priority?: number | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type GetYoungPersonByIdSubSubscriptionVariables = Exact<{
	id: Scalars['String'];
}>;

export type GetYoungPersonByIdSubSubscription = {
	__typename?: 'Subscription';
	onMutatedYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				status?: YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				dateOfBirth: any;
				phone: string;
				avatar?: string | null | undefined;
				missingAt?: any | null | undefined;
				house?:
					| { __typename?: 'House'; id: string; title: string }
					| null
					| undefined;
				room?:
					| { __typename?: 'Room'; id: string; name: string; color: string }
					| null
					| undefined;
				locationStatus?:
					| {
							__typename?: 'YoungPersonLocationStatus';
							id: string;
							title: string;
							type: YoungPersonLocationStatusType;
							priority?: number | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type LogFragmentFragment = {
	__typename?: 'Log';
	id: string;
	type: LogType;
	data: any;
	createdAt?: any | null | undefined;
	youngPerson?:
		| {
				__typename?: 'YoungPerson';
				name: string;
				house?: { __typename?: 'House'; title: string } | null | undefined;
		  }
		| null
		| undefined;
};

export type ListLogsQueryVariables = Exact<{
	skip?: Maybe<Scalars['Int']>;
	take: Scalars['Int'];
	where?: Maybe<LogWhereFilterInput>;
}>;

export type ListLogsQuery = {
	__typename?: 'Query';
	countLogs?: number | null | undefined;
	listLogs?:
		| Array<
				| {
						__typename?: 'Log';
						id: string;
						type: LogType;
						data: any;
						createdAt?: any | null | undefined;
						youngPerson?:
							| {
									__typename?: 'YoungPerson';
									name: string;
									house?:
										| { __typename?: 'House'; title: string }
										| null
										| undefined;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type ListAlertsQueryVariables = Exact<{
	where: NotificationWhereFilterInput;
	take?: Maybe<Scalars['Int']>;
	skip?: Maybe<Scalars['Int']>;
	orderBy?: Maybe<
		Array<Maybe<NotificationOrderByInput>> | Maybe<NotificationOrderByInput>
	>;
}>;

export type ListAlertsQuery = {
	__typename?: 'Query';
	countNotifications?: number | null | undefined;
	listNotifications?:
		| Array<
				| {
						__typename?: 'Notification';
						id: string;
						title: string;
						severity?: SeverityLevel | null | undefined;
						houseId?: string | null | undefined;
						createdAt?: any | null | undefined;
						readAt?: any | null | undefined;
						youngPerson?:
							| { __typename?: 'YoungPerson'; id: string; name: string }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type DismissAlertMutationVariables = Exact<{
	id?: Maybe<Scalars['String']>;
	date?: Maybe<Scalars['AWSDateTime']>;
}>;

export type DismissAlertMutation = {
	__typename?: 'Mutation';
	updateNotification?:
		| {
				__typename?: 'Notification';
				id: string;
				title: string;
				severity?: SeverityLevel | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				readAt?: any | null | undefined;
				youngPerson?:
					| { __typename?: 'YoungPerson'; id: string; name: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type GetYoungPersonForCoachingQueryVariables = Exact<{
	id: Scalars['String'];
}>;

export type GetYoungPersonForCoachingQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				avatar?: string | null | undefined;
				house?:
					| { __typename?: 'House'; id: string; title: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type CreateCoachingSessionsMutationVariables = Exact<{
	data?: Maybe<Array<LogCreateManyInput> | LogCreateManyInput>;
}>;

export type CreateCoachingSessionsMutation = {
	__typename?: 'Mutation';
	createManyLogs?:
		| { __typename?: 'BatchPayload'; count?: number | null | undefined }
		| null
		| undefined;
};

export type CreateSpinSessionMutationVariables = Exact<{
	data: LogCreateInput;
}>;

export type CreateSpinSessionMutation = {
	__typename?: 'Mutation';
	createLog?: { __typename?: 'Log'; id: string } | null | undefined;
};

export type ExportTrmDataQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
	startDate?: Maybe<Scalars['AWSDateTime']>;
	endDate?: Maybe<Scalars['AWSDateTime']>;
}>;

export type ExportTrmDataQuery = {
	__typename?: 'Query';
	listLogs?:
		| Array<
				| {
						__typename?: 'Log';
						id: string;
						data: any;
						type: LogType;
						createdByUser?:
							| { __typename?: 'User'; name?: string | null | undefined }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type ExportLogDataQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
	startDate?: Maybe<Scalars['AWSDateTime']>;
	endDate?: Maybe<Scalars['AWSDateTime']>;
}>;

export type ExportLogDataQuery = {
	__typename?: 'Query';
	listLogs?:
		| Array<
				| {
						__typename?: 'Log';
						id: string;
						data: any;
						type: LogType;
						createdByUser?:
							| { __typename?: 'User'; name?: string | null | undefined }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type FileFragment = {
	__typename?: 'File';
	id?: string | null | undefined;
	lastModified?: string | null | undefined;
	key?: string | null | undefined;
};

export type GetYoungPersonFilesQueryVariables = Exact<{
	youngPersonId: Scalars['String'];
	houseId?: Maybe<Scalars['String']>;
	folderKey?: Maybe<Scalars['String']>;
}>;

export type GetYoungPersonFilesQuery = {
	__typename?: 'Query';
	listYoungPersonFiles?:
		| {
				__typename?: 'ListFilesResponse';
				folders?: Array<string | null | undefined> | null | undefined;
				files?:
					| Array<
							| {
									__typename?: 'File';
									id?: string | null | undefined;
									lastModified?: string | null | undefined;
									key?: string | null | undefined;
							  }
							| null
							| undefined
					  >
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type GetHouseFilesQueryVariables = Exact<{
	houseId: Scalars['String'];
	folderKey?: Maybe<Scalars['String']>;
}>;

export type GetHouseFilesQuery = {
	__typename?: 'Query';
	listHouseFiles?:
		| {
				__typename?: 'ListFilesResponse';
				folders?: Array<string | null | undefined> | null | undefined;
				files?:
					| Array<
							| {
									__typename?: 'File';
									id?: string | null | undefined;
									lastModified?: string | null | undefined;
									key?: string | null | undefined;
							  }
							| null
							| undefined
					  >
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type HouseLocationDetailsQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
}>;

export type HouseLocationDetailsQuery = {
	__typename?: 'Query';
	getHouse?:
		| {
				__typename?: 'House';
				id: string;
				lat?: string | null | undefined;
				long?: string | null | undefined;
				address?: string | null | undefined;
				phone?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type ListHousesQueryVariables = Exact<{
	where?: Maybe<HouseWhereFilterInput>;
}>;

export type ListHousesQuery = {
	__typename?: 'Query';
	listHouses?:
		| Array<
				{ __typename?: 'House'; id: string; title: string } | null | undefined
		  >
		| null
		| undefined;
};

export type GetLogQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
}>;

export type GetLogQuery = {
	__typename?: 'Query';
	getLog?:
		| {
				__typename?: 'Log';
				data: any;
				type: LogType;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				youngPerson?:
					| {
							__typename?: 'YoungPerson';
							id: string;
							name: string;
							avatar?: string | null | undefined;
							room?: { __typename?: 'Room'; color: string } | null | undefined;
							house?:
								| { __typename?: 'House'; title: string }
								| null
								| undefined;
					  }
					| null
					| undefined;
				createdByUser?:
					| { __typename?: 'User'; name?: string | null | undefined }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type DeleteLogMutationVariables = Exact<{
	id?: Maybe<Scalars['String']>;
}>;

export type DeleteLogMutation = {
	__typename?: 'Mutation';
	deleteLog?: { __typename?: 'Log'; id: string } | null | undefined;
};

export type ListMyNotificationsQueryVariables = Exact<{
	limit?: Maybe<Scalars['Int']>;
	filter?: Maybe<NotificationWhereFilterInput>;
}>;

export type ListMyNotificationsQuery = {
	__typename?: 'Query';
	listNotifications?:
		| Array<
				| {
						__typename?: 'Notification';
						id: string;
						title: string;
						createdAt?: any | null | undefined;
						readAt?: any | null | undefined;
						houseId?: string | null | undefined;
						youngPersonId?: string | null | undefined;
						taskId?: string | null | undefined;
						userId?: string | null | undefined;
						task?:
							| {
									__typename?: 'Task';
									id: string;
									houseId?: string | null | undefined;
									youngPersonId?: string | null | undefined;
									title: string;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type NotifyMeSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NotifyMeSubscription = {
	__typename?: 'Subscription';
	onCreatedManyNotifications?:
		| { __typename?: 'BatchPayload'; count?: number | null | undefined }
		| null
		| undefined;
};

export type MarkAsReadMutationVariables = Exact<{
	readAt: Scalars['AWSDateTime'];
	id: Scalars['String'];
}>;

export type MarkAsReadMutation = {
	__typename?: 'Mutation';
	updateNotification?:
		| {
				__typename?: 'Notification';
				id: string;
				readAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type MarkAllAsReadMutationVariables = Exact<{
	readAt: Scalars['AWSDateTime'];
	ids: Array<Scalars['String']> | Scalars['String'];
}>;

export type MarkAllAsReadMutation = {
	__typename?: 'Mutation';
	updateManyNotifications?:
		| { __typename?: 'BatchPayload'; count?: number | null | undefined }
		| null
		| undefined;
};

export type OccupancyChartQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
	startDate?: Maybe<Scalars['AWSDateTime']>;
	endDate?: Maybe<Scalars['AWSDateTime']>;
}>;

export type OccupancyChartQuery = {
	__typename?: 'Query';
	listOccupancyLogs?:
		| Array<
				| {
						__typename?: 'OccupancyLog';
						id: string;
						startDate?: any | null | undefined;
						endDate?: any | null | undefined;
						room?:
							| { __typename?: 'Room'; id: string; name: string }
							| null
							| undefined;
						youngPerson?:
							| { __typename?: 'YoungPerson'; id: string; name: string }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
	listRooms?:
		| Array<
				| { __typename?: 'Room'; id: string; name: string; color: string }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type GetFileQueryVariables = Exact<{
	key: Scalars['String'];
}>;

export type GetFileQuery = {
	__typename?: 'Query';
	getFile?:
		| { __typename?: 'File'; key?: string | null | undefined }
		| null
		| undefined;
};

export type DeleteFileMutationVariables = Exact<{
	key: Scalars['String'];
}>;

export type DeleteFileMutation = {
	__typename?: 'Mutation';
	deleteFile?:
		| { __typename?: 'UrlResponse'; statusCode?: number | null | undefined }
		| null
		| undefined;
};

export type GetTaskWithUserQueryVariables = Exact<{
	id: Scalars['String'];
}>;

export type GetTaskWithUserQuery = {
	__typename?: 'Query';
	getTask?:
		| {
				__typename?: 'Task';
				id: string;
				ref?: string | null | undefined;
				dueDate?: any | null | undefined;
				title: string;
				description?: string | null | undefined;
				createdAt?: any | null | undefined;
				repeat?: RepeatOption | null | undefined;
				repeatEnd?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				isAdmin?: boolean | null | undefined;
				houseId?: string | null | undefined;
				completedAt?: any | null | undefined;
				youngPerson?:
					| { __typename?: 'YoungPerson'; id: string; name: string }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type CompleteTaskMutationVariables = Exact<{
	taskId: Scalars['String'];
	completedAt: Scalars['AWSDateTime'];
	completedBy: Scalars['String'];
}>;

export type CompleteTaskMutation = {
	__typename?: 'Mutation';
	updateTask?:
		| {
				__typename?: 'Task';
				id: string;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				title: string;
				description?: string | null | undefined;
				group?: TaskGroup | null | undefined;
				youngPersonId?: string | null | undefined;
				completedAt?: any | null | undefined;
				repeat?: RepeatOption | null | undefined;
				dueDate?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				createdAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UnCompleteTaskMutationVariables = Exact<{
	taskId: Scalars['String'];
}>;

export type UnCompleteTaskMutation = {
	__typename?: 'Mutation';
	updateTask?:
		| {
				__typename?: 'Task';
				id: string;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				title: string;
				description?: string | null | undefined;
				group?: TaskGroup | null | undefined;
				youngPersonId?: string | null | undefined;
				completedAt?: any | null | undefined;
				repeat?: RepeatOption | null | undefined;
				dueDate?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				createdAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type DeleteTaskMutationVariables = Exact<{
	taskId: Scalars['String'];
}>;

export type DeleteTaskMutation = {
	__typename?: 'Mutation';
	deleteTask?: { __typename?: 'Task'; title: string } | null | undefined;
};

export type KidReportSnapshotQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
	startDate?: Maybe<Scalars['AWSDateTime']>;
	endDate?: Maybe<Scalars['AWSDateTime']>;
}>;

export type KidReportSnapshotQuery = {
	__typename?: 'Query';
	countLogs?: number | null | undefined;
	listLogs?:
		| Array<{ __typename?: 'Log'; type: LogType; data: any } | null | undefined>
		| null
		| undefined;
};

export type ListRoomsQueryVariables = Exact<{
	houseId?: Maybe<Scalars['String']>;
}>;

export type ListRoomsQuery = {
	__typename?: 'Query';
	listRooms?:
		| Array<
				| {
						__typename?: 'Room';
						id: string;
						name: string;
						color: string;
						youngPerson?:
							| { __typename?: 'YoungPerson'; name: string }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type GroupTaskFragment = {
	__typename?: 'Task';
	id: string;
	title: string;
	isAdmin?: boolean | null | undefined;
	isPrivate?: boolean | null | undefined;
	youngPersonId?: string | null | undefined;
	group?: TaskGroup | null | undefined;
	completedAt?: any | null | undefined;
	updatedAt?: any | null | undefined;
};

export type GetYoungPersonAdminTasksQueryVariables = Exact<{
	youngPersonId: Scalars['String'];
}>;

export type GetYoungPersonAdminTasksQuery = {
	__typename?: 'Query';
	listTasks?:
		| Array<
				| {
						__typename?: 'Task';
						id: string;
						title: string;
						isAdmin?: boolean | null | undefined;
						isPrivate?: boolean | null | undefined;
						youngPersonId?: string | null | undefined;
						group?: TaskGroup | null | undefined;
						completedAt?: any | null | undefined;
						updatedAt?: any | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type OnYoungPersonTaskCreatedSubscriptionVariables = Exact<{
	youngPersonId: Scalars['String'];
}>;

export type OnYoungPersonTaskCreatedSubscription = {
	__typename?: 'Subscription';
	onMutatedTask?:
		| {
				__typename?: 'Task';
				id: string;
				title: string;
				isAdmin?: boolean | null | undefined;
				isPrivate?: boolean | null | undefined;
				youngPersonId?: string | null | undefined;
				group?: TaskGroup | null | undefined;
				completedAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type GetYpTasksByGroupQueryVariables = Exact<{
	youngPersonId: Scalars['String'];
	group?: Maybe<TaskGroup>;
}>;

export type GetYpTasksByGroupQuery = {
	__typename?: 'Query';
	listTasks?:
		| Array<
				| {
						__typename?: 'Task';
						id: string;
						title: string;
						group?: TaskGroup | null | undefined;
						fileType?: FileType | null | undefined;
						formType?: FormType | null | undefined;
						completedAt?: any | null | undefined;
						youngPerson?:
							| {
									__typename?: 'YoungPerson';
									id: string;
									houseId?: string | null | undefined;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type MutatedYoungPersonTasksSubscriptionVariables = Exact<{
	youngPersonId: Scalars['String'];
}>;

export type MutatedYoungPersonTasksSubscription = {
	__typename?: 'Subscription';
	onMutatedTask?:
		| {
				__typename?: 'Task';
				id: string;
				ref?: string | null | undefined;
				completedAt?: any | null | undefined;
				description?: string | null | undefined;
				fileType?: FileType | null | undefined;
				youngPersonId?: string | null | undefined;
				title: string;
				formType?: FormType | null | undefined;
				group?: TaskGroup | null | undefined;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				isPrivate?: boolean | null | undefined;
				dueDate?: any | null | undefined;
				repeat?: RepeatOption | null | undefined;
				repeatEnd?: any | null | undefined;
				houseId?: string | null | undefined;
				assignedUserId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type BasicUserFragment = {
	__typename?: 'User';
	id: string;
	name?: string | null | undefined;
	email: any;
	avatar?: string | null | undefined;
	firstName: string;
	lastName: string;
	role: Role;
};

export type BasicTaskFragment = {
	__typename?: 'Task';
	id: string;
	isAdmin?: boolean | null | undefined;
	isAutomated?: boolean | null | undefined;
	title: string;
	description?: string | null | undefined;
	group?: TaskGroup | null | undefined;
	youngPersonId?: string | null | undefined;
	completedAt?: any | null | undefined;
	repeat?: RepeatOption | null | undefined;
	dueDate?: any | null | undefined;
	isPrivate?: boolean | null | undefined;
	createdAt?: any | null | undefined;
	deletedAt?: any | null | undefined;
	assignedTo?:
		| {
				__typename?: 'User';
				id: string;
				name?: string | null | undefined;
				email: any;
				avatar?: string | null | undefined;
				firstName: string;
				lastName: string;
				role: Role;
		  }
		| null
		| undefined;
	completedBy?:
		| {
				__typename?: 'User';
				id: string;
				name?: string | null | undefined;
				email: any;
				avatar?: string | null | undefined;
				firstName: string;
				lastName: string;
				role: Role;
		  }
		| null
		| undefined;
	createdBy?:
		| {
				__typename?: 'User';
				id: string;
				name?: string | null | undefined;
				email: any;
				avatar?: string | null | undefined;
				firstName: string;
				lastName: string;
				role: Role;
		  }
		| null
		| undefined;
};

export type ListTasksQueryVariables = Exact<{
	where?: Maybe<TaskWhereFilterInput>;
	orderBy?: Maybe<Array<Maybe<TaskOrderByInput>> | Maybe<TaskOrderByInput>>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
}>;

export type ListTasksQuery = {
	__typename?: 'Query';
	countTasks?: number | null | undefined;
	listTasks?:
		| Array<
				| {
						__typename?: 'Task';
						id: string;
						isAdmin?: boolean | null | undefined;
						isAutomated?: boolean | null | undefined;
						title: string;
						description?: string | null | undefined;
						group?: TaskGroup | null | undefined;
						youngPersonId?: string | null | undefined;
						completedAt?: any | null | undefined;
						repeat?: RepeatOption | null | undefined;
						dueDate?: any | null | undefined;
						isPrivate?: boolean | null | undefined;
						createdAt?: any | null | undefined;
						deletedAt?: any | null | undefined;
						assignedTo?:
							| {
									__typename?: 'User';
									id: string;
									name?: string | null | undefined;
									email: any;
									avatar?: string | null | undefined;
									firstName: string;
									lastName: string;
									role: Role;
							  }
							| null
							| undefined;
						completedBy?:
							| {
									__typename?: 'User';
									id: string;
									name?: string | null | undefined;
									email: any;
									avatar?: string | null | undefined;
									firstName: string;
									lastName: string;
									role: Role;
							  }
							| null
							| undefined;
						createdBy?:
							| {
									__typename?: 'User';
									id: string;
									name?: string | null | undefined;
									email: any;
									avatar?: string | null | undefined;
									firstName: string;
									lastName: string;
									role: Role;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type OnHouseTaskMutatedSubscriptionVariables = Exact<{
	houseId?: Maybe<Scalars['String']>;
}>;

export type OnHouseTaskMutatedSubscription = {
	__typename?: 'Subscription';
	onMutatedTask?:
		| {
				__typename?: 'Task';
				id: string;
				ref?: string | null | undefined;
				completedAt?: any | null | undefined;
				description?: string | null | undefined;
				fileType?: FileType | null | undefined;
				youngPersonId?: string | null | undefined;
				title: string;
				formType?: FormType | null | undefined;
				group?: TaskGroup | null | undefined;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				isPrivate?: boolean | null | undefined;
				dueDate?: any | null | undefined;
				repeat?: RepeatOption | null | undefined;
				repeatEnd?: any | null | undefined;
				houseId?: string | null | undefined;
				assignedUserId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type OnYoungPersonTaskMutatedSubscriptionVariables = Exact<{
	youngPersonId?: Maybe<Scalars['String']>;
}>;

export type OnYoungPersonTaskMutatedSubscription = {
	__typename?: 'Subscription';
	onMutatedTask?:
		| {
				__typename?: 'Task';
				id: string;
				ref?: string | null | undefined;
				completedAt?: any | null | undefined;
				description?: string | null | undefined;
				fileType?: FileType | null | undefined;
				youngPersonId?: string | null | undefined;
				title: string;
				formType?: FormType | null | undefined;
				group?: TaskGroup | null | undefined;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				isPrivate?: boolean | null | undefined;
				dueDate?: any | null | undefined;
				repeat?: RepeatOption | null | undefined;
				repeatEnd?: any | null | undefined;
				houseId?: string | null | undefined;
				assignedUserId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type TrmGraphDataQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
	startDate?: Maybe<Scalars['AWSDateTime']>;
	endDate?: Maybe<Scalars['AWSDateTime']>;
}>;

export type TrmGraphDataQuery = {
	__typename?: 'Query';
	listLogs?:
		| Array<
				| {
						__typename?: 'Log';
						id: string;
						data: any;
						createdByUser?:
							| { __typename?: 'User'; name?: string | null | undefined }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type GetStaffMemberQueryVariables = Exact<{
	id?: Maybe<Scalars['String']>;
}>;

export type GetStaffMemberQuery = {
	__typename?: 'Query';
	getUser?:
		| {
				__typename?: 'User';
				name?: string | null | undefined;
				avatar?: string | null | undefined;
				role: Role;
				createdAt?: any | null | undefined;
				id: string;
				firstName: string;
				lastName: string;
				phone?: string | null | undefined;
				address?: string | null | undefined;
				email: any;
				houseId?: string | null | undefined;
				disabled?: boolean | null | undefined;
				house?:
					| {
							__typename?: 'House';
							title: string;
							color?: string | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type ListUsersForSelectQueryVariables = Exact<{
	where?: Maybe<UserWhereFilterInput>;
}>;

export type ListUsersForSelectQuery = {
	__typename?: 'Query';
	listUsers?:
		| Array<
				| {
						__typename?: 'User';
						id: string;
						name?: string | null | undefined;
						firstName: string;
						lastName: string;
						email: any;
						role: Role;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type ListYoungPeopleQueryVariables = Exact<{
	where: YoungPersonWhereFilterInput;
	sortBy?: Maybe<OrderByArg>;
	skip?: Maybe<Scalars['Int']>;
	take?: Maybe<Scalars['Int']>;
}>;

export type ListYoungPeopleQuery = {
	__typename?: 'Query';
	countYoungPeople?: number | null | undefined;
	listYoungPeople?:
		| Array<
				| {
						__typename?: 'YoungPerson';
						id: string;
						name: string;
						dateOfBirth: any;
						preferredName?: string | null | undefined;
						avatar?: string | null | undefined;
						house?:
							| {
									__typename?: 'House';
									title: string;
									color?: string | null | undefined;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type YoungPersonFragment = {
	__typename?: 'YoungPerson';
	id: string;
	status?: YoungPersonStatus | null | undefined;
	house?: { __typename?: 'House'; id: string } | null | undefined;
};

export type AssignHouseMutationVariables = Exact<{
	youngPersonId: Scalars['String'];
	houseId?: Maybe<Scalars['String']>;
	roomId?: Maybe<Scalars['String']>;
	status: YoungPersonStatus;
	moveIn?: Maybe<Scalars['AWSDateTime']>;
}>;

export type AssignHouseMutation = {
	__typename?: 'Mutation';
	updateRoom?: { __typename?: 'Room'; id: string } | null | undefined;
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				status?: YoungPersonStatus | null | undefined;
				house?: { __typename?: 'House'; id: string } | null | undefined;
		  }
		| null
		| undefined;
};

export type ListHousesSelectQueryVariables = Exact<{ [key: string]: never }>;

export type ListHousesSelectQuery = {
	__typename?: 'Query';
	listHouses?:
		| Array<
				{ __typename?: 'House'; id: string; title: string } | null | undefined
		  >
		| null
		| undefined;
};

export type ListRoomsSelectQueryVariables = Exact<{ [key: string]: never }>;

export type ListRoomsSelectQuery = {
	__typename?: 'Query';
	listRooms?:
		| Array<
				| {
						__typename?: 'Room';
						id: string;
						name: string;
						houseId: string;
						youngPersonId?: string | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type ListLocationStatusesQueryVariables = Exact<{
	[key: string]: never;
}>;

export type ListLocationStatusesQuery = {
	__typename?: 'Query';
	listYoungPersonLocationStatuses?:
		| Array<
				| {
						__typename?: 'YoungPersonLocationStatus';
						id: string;
						title: string;
						type: YoungPersonLocationStatusType;
						priority?: number | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type MoveYoungPersonMutationVariables = Exact<{
	roomId: Scalars['String'];
	data: RoomUpdateInput;
}>;

export type MoveYoungPersonMutation = {
	__typename?: 'Mutation';
	updateRoom?:
		| {
				__typename?: 'Room';
				id: string;
				youngPerson?:
					| {
							__typename?: 'YoungPerson';
							id: string;
							house?: { __typename?: 'House'; id: string } | null | undefined;
							room?: { __typename?: 'Room'; id: string } | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type GetYoungPersonLocationQueryVariables = Exact<{
	youngPersonId: Scalars['String'];
}>;

export type GetYoungPersonLocationQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				houseId?: string | null | undefined;
				room?: { __typename?: 'Room'; id: string } | null | undefined;
		  }
		| null
		| undefined;
};

export type YoungPersonProfileFragment = {
	__typename?: 'YoungPerson';
	id: string;
	name: string;
	preferredName?: string | null | undefined;
	email?: any | null | undefined;
	phone: string;
	dateOfBirth: any;
	gender: string;
	mostRecentAddress?: string | null | undefined;
	ethnicity?: string | null | undefined;
	spokenLanguage?: string | null | undefined;
	safePlace?: string | null | undefined;
	religion?: string | null | undefined;
	niNumber?: string | null | undefined;
	registeredGP?: string | null | undefined;
	registeredOptician?: string | null | undefined;
	registeredDentist?: string | null | undefined;
	nhsNumber?: string | null | undefined;
	socialWorker?: string | null | undefined;
	photoConcept?: boolean | null | undefined;
	riskAssessment?: any | null | undefined;
	legalStatus?: string | null | undefined;
	ypAdvisor?: string | null | undefined;
	localAuthority?: string | null | undefined;
	physicalDescription?: string | null | undefined;
	aboutMe?: any | null | undefined;
	status?: YoungPersonStatus | null | undefined;
	houseId?: string | null | undefined;
	createdAt?: any | null | undefined;
	updatedAt?: any | null | undefined;
	deletedAt?: any | null | undefined;
	nextOfKin?:
		| Array<{
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
				youngPersonId: string;
				contactable?: boolean | null | undefined;
				visitable?: boolean | null | undefined;
				note?: string | null | undefined;
		  }>
		| null
		| undefined;
	medicalConditions?:
		| Array<{
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				inEmergency?: string | null | undefined;
				youngPersonId: string;
		  }>
		| null
		| undefined;
};

export type GetYoungPersonQueryVariables = Exact<{
	youngPersonId: Scalars['String'];
}>;

export type GetYoungPersonQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				ethnicity?: string | null | undefined;
				spokenLanguage?: string | null | undefined;
				safePlace?: string | null | undefined;
				religion?: string | null | undefined;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				registeredOptician?: string | null | undefined;
				registeredDentist?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				photoConcept?: boolean | null | undefined;
				riskAssessment?: any | null | undefined;
				legalStatus?: string | null | undefined;
				ypAdvisor?: string | null | undefined;
				localAuthority?: string | null | undefined;
				physicalDescription?: string | null | undefined;
				aboutMe?: any | null | undefined;
				status?: YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				nextOfKin?:
					| Array<{
							__typename?: 'NextOfKin';
							id: string;
							name: string;
							relation: string;
							phone: string;
							youngPersonId: string;
							contactable?: boolean | null | undefined;
							visitable?: boolean | null | undefined;
							note?: string | null | undefined;
					  }>
					| null
					| undefined;
				medicalConditions?:
					| Array<{
							__typename?: 'MedicalCondition';
							id: string;
							title: string;
							medication?: string | null | undefined;
							administrationDetails?: string | null | undefined;
							symptoms?: string | null | undefined;
							triggers?: string | null | undefined;
							inEmergency?: string | null | undefined;
							youngPersonId: string;
					  }>
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type DeleteNextOfKinMutationVariables = Exact<{
	id: Scalars['String'];
}>;

export type DeleteNextOfKinMutation = {
	__typename?: 'Mutation';
	deleteNextOfKin?:
		| {
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				youngPersonId: string;
		  }
		| null
		| undefined;
};

export type DeleteMedicalConditionMutationVariables = Exact<{
	id: Scalars['String'];
}>;

export type DeleteMedicalConditionMutation = {
	__typename?: 'Mutation';
	deleteMedicalCondition?:
		| {
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				inEmergency?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				youngPersonId: string;
		  }
		| null
		| undefined;
};

export type YoungPersonProfileSubscriptionSubscriptionVariables = Exact<{
	id: Scalars['String'];
}>;

export type YoungPersonProfileSubscriptionSubscription = {
	__typename?: 'Subscription';
	onMutatedYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				preferredName?: string | null | undefined;
				email?: any | null | undefined;
				phone: string;
				dateOfBirth: any;
				gender: string;
				mostRecentAddress?: string | null | undefined;
				niNumber?: string | null | undefined;
				registeredGP?: string | null | undefined;
				nhsNumber?: string | null | undefined;
				socialWorker?: string | null | undefined;
				photoConcept?: boolean | null | undefined;
				status?: YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export type NextOfKinSubscriptionSubscriptionVariables = Exact<{
	[key: string]: never;
}>;

export type NextOfKinSubscriptionSubscription = {
	__typename?: 'Subscription';
	onMutatedNextOfKin?:
		| {
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
		  }
		| null
		| undefined;
};

export type MedicalConditionsSubscriptionSubscriptionVariables = Exact<{
	[key: string]: never;
}>;

export type MedicalConditionsSubscriptionSubscription = {
	__typename?: 'Subscription';
	onMutatedMedicalCondition?:
		| {
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				inEmergency?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type YoungPersonWithLocationStatusFragment = {
	__typename?: 'YoungPerson';
	id: string;
	name: string;
	avatar?: string | null | undefined;
	locationStatusId?: string | null | undefined;
	missingAt?: any | null | undefined;
	locationStatus?:
		| {
				__typename?: 'YoungPersonLocationStatus';
				id: string;
				title: string;
				type: YoungPersonLocationStatusType;
				priority?: number | null | undefined;
		  }
		| null
		| undefined;
};

export type GetYoungPeopleAndLocationStatusQueryVariables = Exact<{
	take: Scalars['Int'];
	skip: Scalars['Int'];
	where: YoungPersonWhereFilterInput;
}>;

export type GetYoungPeopleAndLocationStatusQuery = {
	__typename?: 'Query';
	countYoungPeople?: number | null | undefined;
	listYoungPersonLocationStatuses?:
		| Array<
				| {
						__typename?: 'YoungPersonLocationStatus';
						id: string;
						title: string;
						type: YoungPersonLocationStatusType;
						priority?: number | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
	listYoungPeople?:
		| Array<
				| {
						__typename?: 'YoungPerson';
						id: string;
						name: string;
						avatar?: string | null | undefined;
						locationStatusId?: string | null | undefined;
						missingAt?: any | null | undefined;
						locationStatus?:
							| {
									__typename?: 'YoungPersonLocationStatus';
									id: string;
									title: string;
									type: YoungPersonLocationStatusType;
									priority?: number | null | undefined;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type UpdateYpStatusMutationVariables = Exact<{
	id: Scalars['String'];
	locationStatusId: Scalars['String'];
	missingAt?: Maybe<Scalars['AWSDateTime']>;
}>;

export type UpdateYpStatusMutation = {
	__typename?: 'Mutation';
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				avatar?: string | null | undefined;
				locationStatusId?: string | null | undefined;
				missingAt?: any | null | undefined;
				locationStatus?:
					| {
							__typename?: 'YoungPersonLocationStatus';
							id: string;
							title: string;
							type: YoungPersonLocationStatusType;
							priority?: number | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};
