import React, { useContext } from 'react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './styles';
import { theme } from './styles/theme';
import DateUtils from '@date-io/dayjs';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import Amplify, { Auth } from 'aws-amplify';
import { AUTH_TYPE, createAppSyncLink } from 'aws-appsync';
import * as dayjs from 'dayjs';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import {
	ApolloClient,
	ApolloProvider,
	createHttpLink,
	InMemoryCache,
} from '@apollo/client';

// Screens
import AdminDashboardScreen from './screens/AdminDashboardScreen';
import AdminListHousesScreen from './screens/AdminListHousesScreen';
import AdminListUsersScreen from './screens/AdminListUsersScreen';
import AdminListYoungPeopleScreen from './screens/AdminListYoungPeopleScreen';
import HomeScreen from './screens/HomeScreen';
import HouseScreen from './screens/HouseScreen';
import YoungPersonScreen from './screens/YoungPersonScreen';
import ActivityLogScreen from './screens/ActivityLogScreen';
import LoginScreen from './screens/LoginScreen';
import ReportingScreen from './screens/ReportingScreen';

// Import Layouts
import ModalLayout from './layouts/ModalLayout';

// Components
import MainLayoutComponent from './layouts/MainLayoutComponent';

// Contexts
import { ModalContextProvider } from './contexts/ModalContext';
import AuthContext, { AuthContextProvider } from './contexts/authContext';

// Configs
import awsconfig from './aws-exports';
import * as serviceWorker from './serviceWorker';

import { AuthState } from '@aws-amplify/ui-components';

import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import { AbilityContext } from './contexts/CanContext';
import defineAbilityFor from './defineAbility';
import AdminOrgSettingsScreen from './screens/AdminOrgSettingsScreen/AdminOrgSettingsScreen';
import FullScreenLoadingComponent from './components/FullScreenLoadingComponent';
import FallbackComponent, { ErrorType } from './components/FallbackComponent';

dayjs.extend(calendar);
dayjs.extend(relativeTime);

// Configure GTM for production only!
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
	if (!process.env.REACT_APP_GTM_ID) console.log('NO GTM ID!');
	else TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
}

//  Setup Sentry
Sentry.init({
	dsn: 'https://41e75dae62864191a3a4f649b4d849c1@o135041.ingest.sentry.io/6161086',
	integrations: [new Integrations.BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,
});

// Configure Amplify / Apollo Client
Amplify.configure(awsconfig);
const httpLink = createHttpLink({
	uri: awsconfig.aws_appsync_graphqlEndpoint,
});
const jwtDecode = (b: string) =>
	JSON.parse(Buffer.from(b.split('.')[1], 'base64').toString('binary'));

const shouldBeAdmin = false;

const awsLink = createAppSyncLink({
	url: awsconfig.aws_appsync_graphqlEndpoint,
	region: awsconfig.aws_appsync_region,
	auth: {
		type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
		jwtToken: async () => {
			try {
				const session = await Auth.currentSession();
				const token = session.getIdToken().getJwtToken();
				const decodedToken = jwtDecode(token);

				const group = decodedToken?.['cognito:groups']?.[0];
				if (group !== 'Admin' && shouldBeAdmin) {
					throw new Error('You are not an admin');
				}
				return token;
			} catch (e) {
				console.log('error at auth stuff', e);
			}
			return '';
		},
	},
	complexObjectsCredentials: async () => await Auth.currentCredentials(),
});

const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				getLog: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: 'Log',
							id: args?.where?.id ?? '',
						});
					},
				},
				getHouse: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: 'House',
							id: args?.where?.id ?? '',
						});
					},
				},
				getTask: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: 'Task',
							id: args?.where?.id ?? '',
						});
					},
				},
				getUser: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: 'User',
							id: args?.where?.id ?? '',
						});
					},
				},
				getYoungPerson: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: 'YoungPerson',
							id: args?.where?.id ?? '',
						});
					},
				},
			},
		},
	},
});

const client = new ApolloClient({
	// @ts-expect-error
	link: awsLink.concat(httpLink),
	cache: cache,
});

export const App = () => {
	return (
		<ApolloProvider client={client}>
			<AuthContextProvider>
				<ThemeProvider theme={theme}>
					<MuiPickersUtilsProvider utils={DateUtils}>
						<CssBaseline />
						<SnackbarProvider>
							<ModalContextProvider>
								<Routes />
							</ModalContextProvider>
						</SnackbarProvider>
					</MuiPickersUtilsProvider>
				</ThemeProvider>
			</AuthContextProvider>
		</ApolloProvider>
	);
};

const Routes = () => {
	const { currentState, user, loading, cognitoUser } = useContext(AuthContext);

	if (loading) {
		return <FullScreenLoadingComponent />;
	}

	if (currentState !== AuthState.SignedIn) return <LoginScreen />;
	// This is to fix when the user object is not present but AuthState says they are logged in
	if (currentState === AuthState.SignedIn && !cognitoUser) {
		return (
			<FallbackComponent
				hasError
				errorType={ErrorType.WARNING}
				errorTitle="An Error Occurred"
				errorCopy="We had a little trouble fetching your user profile. If the problem persists, please contact your system administrator."
			/>
		);
	}
	if (
		(currentState === AuthState.SignedIn && !cognitoUser) ||
		(!cognitoUser && loading)
	) {
		return <FullScreenLoadingComponent />;
	}

	if (currentState === AuthState.SignedIn)
		return (
			<AbilityContext.Provider
				value={defineAbilityFor({
					dbUser: user,
					cognitoUser,
				})}
			>
				<Router>
					<MainLayoutComponent>
						<Switch>
							<Route path="/homes/:houseId" component={HouseScreen} />
							<Route
								path="/activities/:youngPersonId"
								component={ActivityLogScreen}
							/>
							<Route
								path="/kids/:youngPersonId"
								component={YoungPersonScreen}
							/>
							{/* Admin Routes */}
							<Route
								exact
								path="/admin/kids"
								component={AdminListYoungPeopleScreen}
							/>
							<Route
								exact
								path="/admin/homes"
								component={AdminListHousesScreen}
							/>
							<Route
								exact
								path="/admin/users"
								component={AdminListUsersScreen}
							/>
							<Route
								exact
								path="/admin/settings"
								component={AdminOrgSettingsScreen}
							/>
							<Route exact path="/admin/reports" component={ReportingScreen} />
							<Route exact path="/admin" component={AdminDashboardScreen} />

							<Route exact path="/" component={HomeScreen} />
						</Switch>
						<ModalLayout />
					</MainLayoutComponent>
				</Router>
			</AbilityContext.Provider>
		);

	// If Using a different State then don't show anything
	return null;
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
