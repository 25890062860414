import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type TrmGraphDataQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
	startDate?: Types.Maybe<Types.Scalars['AWSDateTime']>;
	endDate?: Types.Maybe<Types.Scalars['AWSDateTime']>;
}>;

export type TrmGraphDataQuery = {
	__typename?: 'Query';
	listLogs?:
		| Array<
				| {
						__typename?: 'Log';
						id: string;
						data: any;
						createdByUser?:
							| { __typename?: 'User'; name?: string | null | undefined }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const TrmGraphDataDocument = gql`
	query TrmGraphData(
		$id: String
		$startDate: AWSDateTime
		$endDate: AWSDateTime
	) {
		listLogs(
			where: {
				AND: [
					{ youngPerson: { id: { equals: $id } } }
					{ type: { equals: SPIN_SESSION } }
					{ createdAt: { gte: $startDate } }
					{ createdAt: { lte: $endDate } }
				]
			}
		) {
			id
			data
			createdByUser {
				name
			}
		}
	}
`;

/**
 * __useTrmGraphDataQuery__
 *
 * To run a query within a React component, call `useTrmGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrmGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrmGraphDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTrmGraphDataQuery(
	baseOptions?: Apollo.QueryHookOptions<
		TrmGraphDataQuery,
		TrmGraphDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<TrmGraphDataQuery, TrmGraphDataQueryVariables>(
		TrmGraphDataDocument,
		options
	);
}
export function useTrmGraphDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		TrmGraphDataQuery,
		TrmGraphDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<TrmGraphDataQuery, TrmGraphDataQueryVariables>(
		TrmGraphDataDocument,
		options
	);
}
export type TrmGraphDataQueryHookResult = ReturnType<
	typeof useTrmGraphDataQuery
>;
export type TrmGraphDataLazyQueryHookResult = ReturnType<
	typeof useTrmGraphDataLazyQuery
>;
export type TrmGraphDataQueryResult = Apollo.QueryResult<
	TrmGraphDataQuery,
	TrmGraphDataQueryVariables
>;
