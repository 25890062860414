import React, { useContext } from 'react';

import {
	Box,
	Button,
	Theme,
	CircularProgress,
	createStyles,
	makeStyles,
} from '@material-ui/core';

// Context
import ModalContext from '../../contexts/ModalContext';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		wrapper: {
			display: 'flex',
			padding: theme.spacing('lg'),
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		secondaryText: {
			color: 'lightGray',
		},
	})
);

export type ModalButtonsComponentProps = {
	primaryAction?: Action;
	cancelAction?: {
		onClick?: () => void;
		disabled?: boolean;
	};
};

export type Action = {
	label: string;
	onClick: () => void;
	disabled?: boolean;
	loading?: boolean;
};

const ModalButtonsComponent: React.FC<ModalButtonsComponentProps> = (props) => {
	const classes = useStyles();
	const { close } = useContext(ModalContext);

	const onCancelClick = () => {
		close();
		if (props.cancelAction?.onClick) props.cancelAction?.onClick();
	};

	return (
		<Box className={classes.wrapper} data-component="modal-buttons">
			<Button onClick={onCancelClick}>Cancel</Button>
			<Button onClick={() => window?.print()}>Print</Button>
			{props.primaryAction ? (
				<Button
					color="primary"
					variant="contained"
					onClick={props.primaryAction?.onClick}
					disabled={props.primaryAction?.disabled}
				>
					{props.primaryAction?.loading ? (
						<CircularProgress size={24} />
					) : (
						props.primaryAction?.label
					)}
				</Button>
			) : null}
		</Box>
	);
};

export default ModalButtonsComponent;
