import { CoachingSessionCategory } from './types';
import { FormFieldsComponentProps } from '../../components/FormFieldsComponent';

export const coachingSessionCategories: CoachingSessionCategory[] = [
	{
		label: 'Travel Training',
		subCategories: [
			'Bus/Train timetables',
			'Getting on public transport',
			'Road safety',
			'Crossing the road',
			'Cycling',
			'Planning routes ',
		],
	},
	{
		label: 'Appointments',
		subCategories: [
			'Booking appointments (Doctors, dentist, eyes, hair etc.)',
			'Planning how to get there',
			'Scripting what to say/role play',
			'Taking notes',
			'Keeping a diary',
		],
	},
	{
		label: 'Domestic Living skills',
		subCategories: [
			'Washing clothing',
			'Drying clothing',
			'Changing plugs',
			'Changing lights',
			'Recycling',
			'Boiler, heating electrics',
			'Fridge hygiene',
			'Washing dishes',
			'Cooking & Food dates',
			'Defrosting freezer',
		],
	},
	{
		label: 'Day to Day',
		subCategories: [
			'Paying bills',
			'Budgeting',
			'Setting up a bank account/ online',
			'Banking',
			'Sending/ receiving parcels',
			'Using PayPal',
			'Healthy Relationships',
			'Communication',
		],
	},
	{
		label: 'Employability',
		subCategories: [
			'Interview skills',
			'Application forms',
			'Creating a CV/cover letter Job searching',
			'Time keeping',
			'Time sheets Understanding payslips',
			'Understanding a rota',
			'Careers wales',
		],
	},
	{
		label: 'Health & Wellbeing',
		subCategories: [
			'Personal hygiene',
			'Medication',
			'Sexual health',
			'Nutrition/diet',
			'Sleeping',
			'Eating disorders',
			'Mental health',
			'Age perception',
			'About me files',
		],
	},
	{
		label: 'Online Safety',
		subCategories: [
			'Information Safety',
			'Social media',
			'Images',
			'Secure passwords',
			'Blocking',
			'Meeting people',
			'Stranger danger',
		],
	},
	{
		label: 'Driving theory',
		subCategories: [
			'Driving theory',
			'Revision',
			'Generic reading, writing and speaking',
			'Speech therapy',
			'Homework',
			'College/university applications',
			'Libraries',
		],
	},
	{
		label: 'Other',
		subCategories: [],
	},
];
