import * as React from 'react';
import Typography from '@material-ui/core/Typography';

export type ListTitleComponentProps = {
	label: string;
};

const ListTitleComponent: React.FC<ListTitleComponentProps> = ({ label }) => {
	return (
		<Typography color="textPrimary" variant="h3">
			{label}
		</Typography>
	);
};

export default ListTitleComponent;
