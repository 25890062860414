import { Auth } from 'aws-amplify';
import { Redirect } from 'react-router-dom';
import { useAsync } from 'react-use';
import FallbackComponent from '../../components/FallbackComponent';
import HomeScreen from './HomeScreen';

type HomeScreenContainerProps = {};

const HomeScreenContainer = (props: HomeScreenContainerProps) => {
	const { loading, value, error } = useAsync(async () => {
		try {
			const result = await Auth.currentAuthenticatedUser();

			const groups: string[] = result?.signInUserSession?.accessToken
				?.payload?.['cognito:groups'] ?? [''];
			// Route the admins to the house list scene
			if (groups && groups.findIndex((group) => group === 'Admin') > -1) {
				return `/admin`;
			}

			// This is to account for the fact that House Managers now have multiple House IDs in their attributes due to the fact
			// they can manage the Moved Out - House
			const houseId = result?.attributes?.['custom:houseId'].split('|||');
			// Route support workers to the house they are assigned
			if (houseId) {
				return `/homes/${houseId[0]}`;
			}
			// This should show an error to the user that something has gone wrong with their account
			return null;
		} catch (error) {
			console.log(`error`, error);
			return null;
		}
	}, []);

	return (
		<FallbackComponent
			isLoading={loading}
			hasError={Boolean(error)}
			errorTitle={'An Error Occurred'}
			errorCopy="Please try reloading the page. If the problem persists, contact your system administrator."
		>
			{value ? <Redirect to={value} /> : <HomeScreen />}
		</FallbackComponent>
	);
};

export default HomeScreenContainer;
