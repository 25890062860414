import * as React from 'react';
import Pagination from '@material-ui/lab/Pagination';

export type PaginationComponentProps = {
	count: number;
	handleChange: (i: number) => void;
	page?: number;
};

const PaginationComponent: React.FC<PaginationComponentProps> = (props) => (
	<Pagination
		count={props.count}
		page={props.page}
		size="small"
		onChange={(e, index) => props.handleChange(index)}
	/>
);

export default PaginationComponent;
