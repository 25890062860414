import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type MedicalConditionFragmentFragment = {
	__typename?: 'MedicalCondition';
	id: string;
	title: string;
	symptoms?: string | null | undefined;
	triggers?: string | null | undefined;
	medication?: string | null | undefined;
	administrationDetails?: string | null | undefined;
	inEmergency?: string | null | undefined;
};

export type GetYoungPersonsMedicalConditionQueryVariables = Types.Exact<{
	medicalConditionId: Types.Scalars['String'];
}>;

export type GetYoungPersonsMedicalConditionQuery = {
	__typename?: 'Query';
	getMedicalCondition?:
		| {
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				inEmergency?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type UpdateMedicalConditionMutationVariables = Types.Exact<{
	medicalConditionId: Types.Scalars['String'];
	data: Types.MedicalConditionUpdateInput;
}>;

export type UpdateMedicalConditionMutation = {
	__typename?: 'Mutation';
	updateMedicalCondition?:
		| {
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				inEmergency?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type CreateMedicalConditionMutationVariables = Types.Exact<{
	data: Types.MedicalConditionCreateInput;
}>;

export type CreateMedicalConditionMutation = {
	__typename?: 'Mutation';
	createMedicalCondition?:
		| {
				__typename?: 'MedicalCondition';
				id: string;
				title: string;
				symptoms?: string | null | undefined;
				triggers?: string | null | undefined;
				medication?: string | null | undefined;
				administrationDetails?: string | null | undefined;
				inEmergency?: string | null | undefined;
		  }
		| null
		| undefined;
};

export const MedicalConditionFragmentFragmentDoc = gql`
	fragment MedicalConditionFragment on MedicalCondition {
		id
		title
		symptoms
		triggers
		medication
		administrationDetails
		inEmergency
	}
`;
export const GetYoungPersonsMedicalConditionDocument = gql`
	query GetYoungPersonsMedicalCondition($medicalConditionId: String!) {
		getMedicalCondition(where: { id: $medicalConditionId }) {
			...MedicalConditionFragment
		}
	}
	${MedicalConditionFragmentFragmentDoc}
`;

/**
 * __useGetYoungPersonsMedicalConditionQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonsMedicalConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonsMedicalConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonsMedicalConditionQuery({
 *   variables: {
 *      medicalConditionId: // value for 'medicalConditionId'
 *   },
 * });
 */
export function useGetYoungPersonsMedicalConditionQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYoungPersonsMedicalConditionQuery,
		GetYoungPersonsMedicalConditionQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetYoungPersonsMedicalConditionQuery,
		GetYoungPersonsMedicalConditionQueryVariables
	>(GetYoungPersonsMedicalConditionDocument, options);
}
export function useGetYoungPersonsMedicalConditionLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYoungPersonsMedicalConditionQuery,
		GetYoungPersonsMedicalConditionQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetYoungPersonsMedicalConditionQuery,
		GetYoungPersonsMedicalConditionQueryVariables
	>(GetYoungPersonsMedicalConditionDocument, options);
}
export type GetYoungPersonsMedicalConditionQueryHookResult = ReturnType<
	typeof useGetYoungPersonsMedicalConditionQuery
>;
export type GetYoungPersonsMedicalConditionLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonsMedicalConditionLazyQuery
>;
export type GetYoungPersonsMedicalConditionQueryResult = Apollo.QueryResult<
	GetYoungPersonsMedicalConditionQuery,
	GetYoungPersonsMedicalConditionQueryVariables
>;
export const UpdateMedicalConditionDocument = gql`
	mutation UpdateMedicalCondition(
		$medicalConditionId: String!
		$data: MedicalConditionUpdateInput!
	) {
		updateMedicalCondition(where: { id: $medicalConditionId }, data: $data) {
			...MedicalConditionFragment
		}
	}
	${MedicalConditionFragmentFragmentDoc}
`;
export type UpdateMedicalConditionMutationFn = Apollo.MutationFunction<
	UpdateMedicalConditionMutation,
	UpdateMedicalConditionMutationVariables
>;

/**
 * __useUpdateMedicalConditionMutation__
 *
 * To run a mutation, you first call `useUpdateMedicalConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMedicalConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMedicalConditionMutation, { data, loading, error }] = useUpdateMedicalConditionMutation({
 *   variables: {
 *      medicalConditionId: // value for 'medicalConditionId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMedicalConditionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateMedicalConditionMutation,
		UpdateMedicalConditionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UpdateMedicalConditionMutation,
		UpdateMedicalConditionMutationVariables
	>(UpdateMedicalConditionDocument, options);
}
export type UpdateMedicalConditionMutationHookResult = ReturnType<
	typeof useUpdateMedicalConditionMutation
>;
export type UpdateMedicalConditionMutationResult =
	Apollo.MutationResult<UpdateMedicalConditionMutation>;
export type UpdateMedicalConditionMutationOptions = Apollo.BaseMutationOptions<
	UpdateMedicalConditionMutation,
	UpdateMedicalConditionMutationVariables
>;
export const CreateMedicalConditionDocument = gql`
	mutation CreateMedicalCondition($data: MedicalConditionCreateInput!) {
		createMedicalCondition(data: $data) {
			...MedicalConditionFragment
		}
	}
	${MedicalConditionFragmentFragmentDoc}
`;
export type CreateMedicalConditionMutationFn = Apollo.MutationFunction<
	CreateMedicalConditionMutation,
	CreateMedicalConditionMutationVariables
>;

/**
 * __useCreateMedicalConditionMutation__
 *
 * To run a mutation, you first call `useCreateMedicalConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMedicalConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMedicalConditionMutation, { data, loading, error }] = useCreateMedicalConditionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMedicalConditionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateMedicalConditionMutation,
		CreateMedicalConditionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CreateMedicalConditionMutation,
		CreateMedicalConditionMutationVariables
	>(CreateMedicalConditionDocument, options);
}
export type CreateMedicalConditionMutationHookResult = ReturnType<
	typeof useCreateMedicalConditionMutation
>;
export type CreateMedicalConditionMutationResult =
	Apollo.MutationResult<CreateMedicalConditionMutation>;
export type CreateMedicalConditionMutationOptions = Apollo.BaseMutationOptions<
	CreateMedicalConditionMutation,
	CreateMedicalConditionMutationVariables
>;
