import React from 'react';
import {
	createStyles,
	makeStyles,
	Theme,
	ListItemText,
	ListItemIcon,
	Typography,
	Box,
	Grid,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles<
	Theme,
	{
		hasLabelIcon: boolean;
		hasTrailingIcon: boolean;
		disablePadding: boolean;
	}
>((theme) =>
	createStyles({
		labelIconContainer: {
			paddingRight: theme.spacing('xs3'),
			display: (props) => (props.hasLabelIcon ? 'grid' : 'none'),
			placeItems: 'center',
		},
		trailingIcon: {
			paddingRight: (props) =>
				!props.disablePadding ? theme.spacing('xs2') : '0',
			paddingLeft: theme.spacing('xs2'),
			display: (props) => (props.hasTrailingIcon ? 'grid' : 'none'),
			placeItems: 'center',
			minWidth: 'unset',
		},
	})
);

export type InfoCardProps = {
	trailingIcon?: React.ReactNode;
	disablePadding?: boolean;
	labelIcon?: React.ReactNode;
	linkUrl?: string;
};

/* Potentially has trailing icon and label icon; if none is passed their containers are set to
display: none. Children passes the label text node. */

export const InfoCardLabelComponent: React.FC<InfoCardProps> = ({
	children,
	trailingIcon = null,
	labelIcon = null,
	disablePadding = false,
	linkUrl,
}) => {
	const styles = useStyles({
		hasLabelIcon: Boolean(labelIcon),
		hasTrailingIcon: Boolean(trailingIcon),
		disablePadding,
	});

	return (
		<Grid item container alignItems="center" wrap="nowrap">
			<Box className={styles.labelIconContainer}>{labelIcon}</Box>
			<ListItemText>
				<Typography
					component={linkUrl ? Link : Typography}
					to={linkUrl}
					variant="h3"
					color={'textPrimary'}
					style={{ textDecoration: 'none' }}
				>
					{children}
				</Typography>
			</ListItemText>
			<ListItemIcon className={styles.trailingIcon}>
				{trailingIcon}
			</ListItemIcon>
		</Grid>
	);
};

export default InfoCardLabelComponent;
