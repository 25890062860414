import React from 'react';

type HomeScreenProps = {
	children?: React.ReactNode;
};

const HomeScreen = (props: HomeScreenProps) => {
	return (
		<>
			<h2>Hmm something is wrong with your account. Please get in touch</h2>
			<p>
				Tell a dev... this is likely because the customer:houseId is missing on
				the cognito user
			</p>
		</>
	);
};

export default HomeScreen;
