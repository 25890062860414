import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetLogQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
}>;

export type GetLogQuery = {
	__typename?: 'Query';
	getLog?:
		| {
				__typename?: 'Log';
				data: any;
				type: Types.LogType;
				createdAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
				youngPerson?:
					| {
							__typename?: 'YoungPerson';
							id: string;
							name: string;
							avatar?: string | null | undefined;
							room?: { __typename?: 'Room'; color: string } | null | undefined;
							house?:
								| { __typename?: 'House'; title: string }
								| null
								| undefined;
					  }
					| null
					| undefined;
				createdByUser?:
					| { __typename?: 'User'; name?: string | null | undefined }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type DeleteLogMutationVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
}>;

export type DeleteLogMutation = {
	__typename?: 'Mutation';
	deleteLog?: { __typename?: 'Log'; id: string } | null | undefined;
};

export const GetLogDocument = gql`
	query GetLog($id: String) {
		getLog(where: { id: $id }) {
			data
			type
			createdAt
			updatedAt
			youngPerson {
				id
				name
				avatar
				room {
					color
				}
				house {
					title
				}
			}
			createdByUser {
				name
			}
		}
	}
`;

/**
 * __useGetLogQuery__
 *
 * To run a query within a React component, call `useGetLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLogQuery(
	baseOptions?: Apollo.QueryHookOptions<GetLogQuery, GetLogQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetLogQuery, GetLogQueryVariables>(
		GetLogDocument,
		options
	);
}
export function useGetLogLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetLogQuery, GetLogQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetLogQuery, GetLogQueryVariables>(
		GetLogDocument,
		options
	);
}
export type GetLogQueryHookResult = ReturnType<typeof useGetLogQuery>;
export type GetLogLazyQueryHookResult = ReturnType<typeof useGetLogLazyQuery>;
export type GetLogQueryResult = Apollo.QueryResult<
	GetLogQuery,
	GetLogQueryVariables
>;
export const DeleteLogDocument = gql`
	mutation DeleteLog($id: String) {
		deleteLog(where: { id: $id }) {
			id
		}
	}
`;
export type DeleteLogMutationFn = Apollo.MutationFunction<
	DeleteLogMutation,
	DeleteLogMutationVariables
>;

/**
 * __useDeleteLogMutation__
 *
 * To run a mutation, you first call `useDeleteLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogMutation, { data, loading, error }] = useDeleteLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLogMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteLogMutation,
		DeleteLogMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DeleteLogMutation, DeleteLogMutationVariables>(
		DeleteLogDocument,
		options
	);
}
export type DeleteLogMutationHookResult = ReturnType<
	typeof useDeleteLogMutation
>;
export type DeleteLogMutationResult = Apollo.MutationResult<DeleteLogMutation>;
export type DeleteLogMutationOptions = Apollo.BaseMutationOptions<
	DeleteLogMutation,
	DeleteLogMutationVariables
>;
