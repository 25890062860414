import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetYoungPersonForCoachingQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetYoungPersonForCoachingQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				avatar?: string | null | undefined;
				house?:
					| { __typename?: 'House'; id: string; title: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type CreateCoachingSessionsMutationVariables = Types.Exact<{
	data?: Types.Maybe<
		Array<Types.LogCreateManyInput> | Types.LogCreateManyInput
	>;
}>;

export type CreateCoachingSessionsMutation = {
	__typename?: 'Mutation';
	createManyLogs?:
		| { __typename?: 'BatchPayload'; count?: number | null | undefined }
		| null
		| undefined;
};

export const GetYoungPersonForCoachingDocument = gql`
	query GetYoungPersonForCoaching($id: String!) {
		getYoungPerson(where: { id: $id }) {
			id
			name
			avatar
			house {
				id
				title
			}
		}
	}
`;

/**
 * __useGetYoungPersonForCoachingQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonForCoachingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonForCoachingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonForCoachingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetYoungPersonForCoachingQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYoungPersonForCoachingQuery,
		GetYoungPersonForCoachingQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetYoungPersonForCoachingQuery,
		GetYoungPersonForCoachingQueryVariables
	>(GetYoungPersonForCoachingDocument, options);
}
export function useGetYoungPersonForCoachingLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYoungPersonForCoachingQuery,
		GetYoungPersonForCoachingQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetYoungPersonForCoachingQuery,
		GetYoungPersonForCoachingQueryVariables
	>(GetYoungPersonForCoachingDocument, options);
}
export type GetYoungPersonForCoachingQueryHookResult = ReturnType<
	typeof useGetYoungPersonForCoachingQuery
>;
export type GetYoungPersonForCoachingLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonForCoachingLazyQuery
>;
export type GetYoungPersonForCoachingQueryResult = Apollo.QueryResult<
	GetYoungPersonForCoachingQuery,
	GetYoungPersonForCoachingQueryVariables
>;
export const CreateCoachingSessionsDocument = gql`
	mutation CreateCoachingSessions($data: [LogCreateManyInput!]) {
		createManyLogs(data: $data) {
			count
		}
	}
`;
export type CreateCoachingSessionsMutationFn = Apollo.MutationFunction<
	CreateCoachingSessionsMutation,
	CreateCoachingSessionsMutationVariables
>;

/**
 * __useCreateCoachingSessionsMutation__
 *
 * To run a mutation, you first call `useCreateCoachingSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoachingSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoachingSessionsMutation, { data, loading, error }] = useCreateCoachingSessionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCoachingSessionsMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateCoachingSessionsMutation,
		CreateCoachingSessionsMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CreateCoachingSessionsMutation,
		CreateCoachingSessionsMutationVariables
	>(CreateCoachingSessionsDocument, options);
}
export type CreateCoachingSessionsMutationHookResult = ReturnType<
	typeof useCreateCoachingSessionsMutation
>;
export type CreateCoachingSessionsMutationResult =
	Apollo.MutationResult<CreateCoachingSessionsMutation>;
export type CreateCoachingSessionsMutationOptions = Apollo.BaseMutationOptions<
	CreateCoachingSessionsMutation,
	CreateCoachingSessionsMutationVariables
>;
