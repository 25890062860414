import * as React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		select: {
			background: 'transparent',
			border: '2px solid #C4C4C4',
			borderRadius: theme.spacing(4),
			height: 'min-content',
			minWidth: theme.spacing(32),
			'& input': {
				padding: theme.spacing(1.5),
				paddingLeft: 'unset',
			},
			'& [class*="MuiOutlinedInput-input"]': {
				padding: theme.spacing(1.5),
				border: 'none',
			},
			'& [class*="MuiSelect-select"]:focus': {
				background: 'none',
			},
			'& .MuiInputBase-input:focus': {
				backgroundColor: 'none',
			},
			'&&& [class*="MuiOutlinedInput-notchedOutline"]': {
				border: 'none',
			},
		},
	})
);

export type MenuItemType = {
	label: string;
	value: string | number;
	disabled?: boolean;
};

export type SelectComponentProps = {
	onSelect: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
	placeholder?: string;
	items: MenuItemType[];
	label?: string;
	value: string | number;
	id?: string;
	disabled?: boolean;
};

const SelectComponent: React.FC<SelectComponentProps> = (props) => {
	const styles = useStyles();

	return (
		<Select
			className={styles.select}
			variant="outlined"
			placeholder={props.placeholder}
			onChange={props.onSelect}
			value={props.value}
			id={props.id}
			disabled={props.disabled}
			label={props.label}
		>
			{props.items.map((item) => (
				<MenuItem value={item.value} disabled={item.disabled}>
					{item.label}
				</MenuItem>
			))}
		</Select>
	);
};

export default SelectComponent;
