import dayjs from 'dayjs';

type Granularity = 'day' | 'month' | 'week';

export const dateDifference = (
	startDate: any,
	endDate: any,
	secondStartDate?: any
): number => {
	if (secondStartDate && startDate.isBefore(secondStartDate)) {
		return dateDifference(secondStartDate, endDate);
	}

	return endDate.diff(startDate, 'day');
};

export const dateRange = (startDate: any, endDate: any): string[] => {
	const dateRange: string[] = [];
	while (!startDate.isAfter(endDate)) {
		dateRange.push(startDate.format('YYYY-MM-DD'));
		startDate = startDate.add(1, 'day');
	}
	return dateRange;
};

/**
 * Set the level of granularity, 1 month = days, 3 months = weeks, 6 months = months
 * @param startDate
 * @param endDate
 * @returns
 */
export const getGranularity = (startDate: any, endDate: any): Granularity => {
	const searchDateDifference = dateDifference(startDate, endDate);

	let granularity: Granularity = 'day';
	if (searchDateDifference > 31 && searchDateDifference < 90)
		granularity = 'week';
	if (searchDateDifference > 180) granularity = 'month';
	return granularity;
};
