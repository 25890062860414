import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type InviteUserMutationVariables = Types.Exact<{
	data: Types.UserCreateInput;
}>;

export type InviteUserMutation = {
	__typename?: 'Mutation';
	createUser?: { __typename?: 'User'; id: string } | null | undefined;
};

export type CheckUserWithEmailQueryVariables = Types.Exact<{
	email?: Types.Maybe<Types.Scalars['AWSEmail']>;
}>;

export type CheckUserWithEmailQuery = {
	__typename?: 'Query';
	getUser?: { __typename?: 'User'; id: string } | null | undefined;
};

export const InviteUserDocument = gql`
	mutation InviteUser($data: UserCreateInput!) {
		createUser(data: $data) {
			id
		}
	}
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
	InviteUserMutation,
	InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInviteUserMutation(
	baseOptions?: Apollo.MutationHookOptions<
		InviteUserMutation,
		InviteUserMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(
		InviteUserDocument,
		options
	);
}
export type InviteUserMutationHookResult = ReturnType<
	typeof useInviteUserMutation
>;
export type InviteUserMutationResult =
	Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
	InviteUserMutation,
	InviteUserMutationVariables
>;
export const CheckUserWithEmailDocument = gql`
	query CheckUserWithEmail($email: AWSEmail) {
		getUser(where: { email: $email }) {
			id
		}
	}
`;

/**
 * __useCheckUserWithEmailQuery__
 *
 * To run a query within a React component, call `useCheckUserWithEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserWithEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserWithEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckUserWithEmailQuery(
	baseOptions?: Apollo.QueryHookOptions<
		CheckUserWithEmailQuery,
		CheckUserWithEmailQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		CheckUserWithEmailQuery,
		CheckUserWithEmailQueryVariables
	>(CheckUserWithEmailDocument, options);
}
export function useCheckUserWithEmailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		CheckUserWithEmailQuery,
		CheckUserWithEmailQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		CheckUserWithEmailQuery,
		CheckUserWithEmailQueryVariables
	>(CheckUserWithEmailDocument, options);
}
export type CheckUserWithEmailQueryHookResult = ReturnType<
	typeof useCheckUserWithEmailQuery
>;
export type CheckUserWithEmailLazyQueryHookResult = ReturnType<
	typeof useCheckUserWithEmailLazyQuery
>;
export type CheckUserWithEmailQueryResult = Apollo.QueryResult<
	CheckUserWithEmailQuery,
	CheckUserWithEmailQueryVariables
>;
