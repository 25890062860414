import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type NextOfKinFragmentFragment = {
	__typename?: 'NextOfKin';
	id: string;
	name: string;
	relation: string;
	phone: string;
	contactable?: boolean | null | undefined;
	visitable?: boolean | null | undefined;
	note?: string | null | undefined;
};

export type GetNextOfKinQueryVariables = Types.Exact<{
	nextOfKinId: Types.Scalars['String'];
}>;

export type GetNextOfKinQuery = {
	__typename?: 'Query';
	getNextOfKin?:
		| {
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
				contactable?: boolean | null | undefined;
				visitable?: boolean | null | undefined;
				note?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type UpdateNextOfKinMutationVariables = Types.Exact<{
	nextOfKinId: Types.Scalars['String'];
	data: Types.NextOfKinUpdateInput;
}>;

export type UpdateNextOfKinMutation = {
	__typename?: 'Mutation';
	updateNextOfKin?:
		| {
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
				contactable?: boolean | null | undefined;
				visitable?: boolean | null | undefined;
				note?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type CreateNextOfKinMutationVariables = Types.Exact<{
	data: Types.NextOfKinCreateInput;
}>;

export type CreateNextOfKinMutation = {
	__typename?: 'Mutation';
	createNextOfKin?:
		| {
				__typename?: 'NextOfKin';
				id: string;
				name: string;
				relation: string;
				phone: string;
				contactable?: boolean | null | undefined;
				visitable?: boolean | null | undefined;
				note?: string | null | undefined;
		  }
		| null
		| undefined;
};

export const NextOfKinFragmentFragmentDoc = gql`
	fragment NextOfKinFragment on NextOfKin {
		id
		name
		relation
		phone
		contactable
		visitable
		note
	}
`;
export const GetNextOfKinDocument = gql`
	query GetNextOfKin($nextOfKinId: String!) {
		getNextOfKin(where: { id: $nextOfKinId }) {
			...NextOfKinFragment
		}
	}
	${NextOfKinFragmentFragmentDoc}
`;

/**
 * __useGetNextOfKinQuery__
 *
 * To run a query within a React component, call `useGetNextOfKinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextOfKinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextOfKinQuery({
 *   variables: {
 *      nextOfKinId: // value for 'nextOfKinId'
 *   },
 * });
 */
export function useGetNextOfKinQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetNextOfKinQuery,
		GetNextOfKinQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetNextOfKinQuery, GetNextOfKinQueryVariables>(
		GetNextOfKinDocument,
		options
	);
}
export function useGetNextOfKinLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetNextOfKinQuery,
		GetNextOfKinQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetNextOfKinQuery, GetNextOfKinQueryVariables>(
		GetNextOfKinDocument,
		options
	);
}
export type GetNextOfKinQueryHookResult = ReturnType<
	typeof useGetNextOfKinQuery
>;
export type GetNextOfKinLazyQueryHookResult = ReturnType<
	typeof useGetNextOfKinLazyQuery
>;
export type GetNextOfKinQueryResult = Apollo.QueryResult<
	GetNextOfKinQuery,
	GetNextOfKinQueryVariables
>;
export const UpdateNextOfKinDocument = gql`
	mutation UpdateNextOfKin(
		$nextOfKinId: String!
		$data: NextOfKinUpdateInput!
	) {
		updateNextOfKin(where: { id: $nextOfKinId }, data: $data) {
			...NextOfKinFragment
		}
	}
	${NextOfKinFragmentFragmentDoc}
`;
export type UpdateNextOfKinMutationFn = Apollo.MutationFunction<
	UpdateNextOfKinMutation,
	UpdateNextOfKinMutationVariables
>;

/**
 * __useUpdateNextOfKinMutation__
 *
 * To run a mutation, you first call `useUpdateNextOfKinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNextOfKinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNextOfKinMutation, { data, loading, error }] = useUpdateNextOfKinMutation({
 *   variables: {
 *      nextOfKinId: // value for 'nextOfKinId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNextOfKinMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateNextOfKinMutation,
		UpdateNextOfKinMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UpdateNextOfKinMutation,
		UpdateNextOfKinMutationVariables
	>(UpdateNextOfKinDocument, options);
}
export type UpdateNextOfKinMutationHookResult = ReturnType<
	typeof useUpdateNextOfKinMutation
>;
export type UpdateNextOfKinMutationResult =
	Apollo.MutationResult<UpdateNextOfKinMutation>;
export type UpdateNextOfKinMutationOptions = Apollo.BaseMutationOptions<
	UpdateNextOfKinMutation,
	UpdateNextOfKinMutationVariables
>;
export const CreateNextOfKinDocument = gql`
	mutation CreateNextOfKin($data: NextOfKinCreateInput!) {
		createNextOfKin(data: $data) {
			...NextOfKinFragment
		}
	}
	${NextOfKinFragmentFragmentDoc}
`;
export type CreateNextOfKinMutationFn = Apollo.MutationFunction<
	CreateNextOfKinMutation,
	CreateNextOfKinMutationVariables
>;

/**
 * __useCreateNextOfKinMutation__
 *
 * To run a mutation, you first call `useCreateNextOfKinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNextOfKinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNextOfKinMutation, { data, loading, error }] = useCreateNextOfKinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNextOfKinMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateNextOfKinMutation,
		CreateNextOfKinMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CreateNextOfKinMutation,
		CreateNextOfKinMutationVariables
	>(CreateNextOfKinDocument, options);
}
export type CreateNextOfKinMutationHookResult = ReturnType<
	typeof useCreateNextOfKinMutation
>;
export type CreateNextOfKinMutationResult =
	Apollo.MutationResult<CreateNextOfKinMutation>;
export type CreateNextOfKinMutationOptions = Apollo.BaseMutationOptions<
	CreateNextOfKinMutation,
	CreateNextOfKinMutationVariables
>;
