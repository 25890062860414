import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ActivityFragmentFragment = {
	__typename?: 'Log';
	id: string;
	type: Types.LogType;
	data: any;
	createdAt?: any | null | undefined;
	createdBy: string;
	youngPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				avatar?: string | null | undefined;
		  }
		| null
		| undefined;
};

export type GetActivityLogQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetActivityLogQuery = {
	__typename?: 'Query';
	getLog?:
		| {
				__typename?: 'Log';
				id: string;
				type: Types.LogType;
				data: any;
				createdAt?: any | null | undefined;
				createdBy: string;
				youngPerson?:
					| {
							__typename?: 'YoungPerson';
							id: string;
							name: string;
							avatar?: string | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type FormListYoungPeopleQueryVariables = Types.Exact<{
	where?: Types.Maybe<Types.YoungPersonWhereFilterInput>;
}>;

export type FormListYoungPeopleQuery = {
	__typename?: 'Query';
	listYoungPeople?:
		| Array<
				| { __typename?: 'YoungPerson'; id: string; name: string }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type UpdateActivityLogMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	data: Types.LogUpdateInput;
}>;

export type UpdateActivityLogMutation = {
	__typename?: 'Mutation';
	updateLog?: { __typename?: 'Log'; id: string } | null | undefined;
};

export type CreateActivityLogMutationVariables = Types.Exact<{
	data: Types.LogCreateInput;
}>;

export type CreateActivityLogMutation = {
	__typename?: 'Mutation';
	createLog?: { __typename?: 'Log'; id: string } | null | undefined;
};

export const ActivityFragmentFragmentDoc = gql`
	fragment ActivityFragment on Log {
		id
		type
		data
		createdAt
		createdBy
		youngPerson {
			id
			name
			avatar
		}
	}
`;
export const GetActivityLogDocument = gql`
	query GetActivityLog($id: String!) {
		getLog(where: { id: $id }) {
			...ActivityFragment
		}
	}
	${ActivityFragmentFragmentDoc}
`;

/**
 * __useGetActivityLogQuery__
 *
 * To run a query within a React component, call `useGetActivityLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityLogQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetActivityLogQuery,
		GetActivityLogQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetActivityLogQuery, GetActivityLogQueryVariables>(
		GetActivityLogDocument,
		options
	);
}
export function useGetActivityLogLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetActivityLogQuery,
		GetActivityLogQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetActivityLogQuery, GetActivityLogQueryVariables>(
		GetActivityLogDocument,
		options
	);
}
export type GetActivityLogQueryHookResult = ReturnType<
	typeof useGetActivityLogQuery
>;
export type GetActivityLogLazyQueryHookResult = ReturnType<
	typeof useGetActivityLogLazyQuery
>;
export type GetActivityLogQueryResult = Apollo.QueryResult<
	GetActivityLogQuery,
	GetActivityLogQueryVariables
>;
export const FormListYoungPeopleDocument = gql`
	query FormListYoungPeople($where: YoungPersonWhereFilterInput) {
		listYoungPeople(where: $where) {
			id
			name
		}
	}
`;

/**
 * __useFormListYoungPeopleQuery__
 *
 * To run a query within a React component, call `useFormListYoungPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormListYoungPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormListYoungPeopleQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFormListYoungPeopleQuery(
	baseOptions?: Apollo.QueryHookOptions<
		FormListYoungPeopleQuery,
		FormListYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		FormListYoungPeopleQuery,
		FormListYoungPeopleQueryVariables
	>(FormListYoungPeopleDocument, options);
}
export function useFormListYoungPeopleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		FormListYoungPeopleQuery,
		FormListYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		FormListYoungPeopleQuery,
		FormListYoungPeopleQueryVariables
	>(FormListYoungPeopleDocument, options);
}
export type FormListYoungPeopleQueryHookResult = ReturnType<
	typeof useFormListYoungPeopleQuery
>;
export type FormListYoungPeopleLazyQueryHookResult = ReturnType<
	typeof useFormListYoungPeopleLazyQuery
>;
export type FormListYoungPeopleQueryResult = Apollo.QueryResult<
	FormListYoungPeopleQuery,
	FormListYoungPeopleQueryVariables
>;
export const UpdateActivityLogDocument = gql`
	mutation updateActivityLog($id: String!, $data: LogUpdateInput!) {
		updateLog(where: { id: $id }, data: $data) {
			id
		}
	}
`;
export type UpdateActivityLogMutationFn = Apollo.MutationFunction<
	UpdateActivityLogMutation,
	UpdateActivityLogMutationVariables
>;

/**
 * __useUpdateActivityLogMutation__
 *
 * To run a mutation, you first call `useUpdateActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityLogMutation, { data, loading, error }] = useUpdateActivityLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateActivityLogMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateActivityLogMutation,
		UpdateActivityLogMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UpdateActivityLogMutation,
		UpdateActivityLogMutationVariables
	>(UpdateActivityLogDocument, options);
}
export type UpdateActivityLogMutationHookResult = ReturnType<
	typeof useUpdateActivityLogMutation
>;
export type UpdateActivityLogMutationResult =
	Apollo.MutationResult<UpdateActivityLogMutation>;
export type UpdateActivityLogMutationOptions = Apollo.BaseMutationOptions<
	UpdateActivityLogMutation,
	UpdateActivityLogMutationVariables
>;
export const CreateActivityLogDocument = gql`
	mutation createActivityLog($data: LogCreateInput!) {
		createLog(data: $data) {
			id
		}
	}
`;
export type CreateActivityLogMutationFn = Apollo.MutationFunction<
	CreateActivityLogMutation,
	CreateActivityLogMutationVariables
>;

/**
 * __useCreateActivityLogMutation__
 *
 * To run a mutation, you first call `useCreateActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityLogMutation, { data, loading, error }] = useCreateActivityLogMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateActivityLogMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateActivityLogMutation,
		CreateActivityLogMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CreateActivityLogMutation,
		CreateActivityLogMutationVariables
	>(CreateActivityLogDocument, options);
}
export type CreateActivityLogMutationHookResult = ReturnType<
	typeof useCreateActivityLogMutation
>;
export type CreateActivityLogMutationResult =
	Apollo.MutationResult<CreateActivityLogMutation>;
export type CreateActivityLogMutationOptions = Apollo.BaseMutationOptions<
	CreateActivityLogMutation,
	CreateActivityLogMutationVariables
>;
