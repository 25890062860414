import React from 'react';
import { Box, Tooltip, useTheme } from '@material-ui/core';
import { Repeat, Warning, VerifiedUser, Lock, Check } from '@material-ui/icons';
import { AlertProps } from '@material-ui/lab';
import dayjs from 'dayjs';

import { Maybe, Task } from '../../graphql/types';
import InfoCard from '../InfoCardComponent';

export type TaskCardProps = {
	repeat?: Task['repeat'];
	title?: Task['title'];
	dueDate?: Maybe<Task['dueDate']>;
	assignedTo?: Maybe<Task['assignedTo']>;
	description?: Maybe<Task['description']>;
	isPrivate?: Maybe<Task['isPrivate']>;
	isAdmin?: Maybe<Task['isAdmin']>;
	completedAt?: Maybe<Task['completedAt']>;
	completedBy?: Maybe<Task['completedBy']>;
	avatar?: Maybe<string>;
	onClick?: () => void;
};

const TaskCardComponent: React.FC<TaskCardProps> = (props) => {
	const theme = useTheme();

	let isOverDue: boolean = false;
	let isAlmostOverDue: boolean = false;

	let trailingIcon: React.ReactNode | undefined = undefined;
	let labelIcons: React.ReactNode[] | undefined = [];
	let severity: AlertProps['severity'] = undefined;

	if (props.dueDate) {
		const today = dayjs();
		const dueDate = dayjs(props.dueDate);

		isAlmostOverDue = dueDate.add(3, 'days').isBefore(today);
		isOverDue = dueDate.isBefore(today, 'day');
	}

	if (props.assignedTo) {
	}

	if (props.repeat) {
		labelIcons.push(
			<Tooltip title="Repeated Task">
				<Repeat color="primary" fontSize={'small'} />
			</Tooltip>
		);
	}

	if (isAlmostOverDue && !isOverDue) {
		severity = 'warning';

		labelIcons.push(<Warning fontSize={'small'} />);
	}

	if (isOverDue) {
		severity = 'error';
		trailingIcon = (
			<Tooltip title={`Due ${dayjs(props.dueDate).fromNow()}`}>
				<Warning fontSize={'small'} />
			</Tooltip>
		);
	}

	if (props.isAdmin) {
		labelIcons.push(
			<Tooltip title="Admin Task">
				<VerifiedUser fontSize={'small'} />
			</Tooltip>
		);
	}

	if (props.isPrivate) {
		labelIcons.push(
			<Tooltip title="Private Task">
				<Lock fontSize={'small'} />
			</Tooltip>
		);
	}

	if (props.completedAt) {
		severity = 'success';
	}

	const avatar = props.assignedTo?.avatar;

	const icons = labelIcons.length && (
		<Box display="grid" gridAutoFlow="column" gridGap={theme.spacing(0.25)}>
			{labelIcons?.map?.((Icon) => (Icon ? Icon : null))}
		</Box>
	);

	return (
		<InfoCard severity={severity} onClick={props.onClick}>
			<InfoCard.Avatar avatarAlt={'avatar'} avatarUrl={avatar ?? undefined}>
				{!avatar && <Check color={props.completedAt ? 'primary' : 'action'} />}
			</InfoCard.Avatar>
			<InfoCard.LabelContainer>
				<InfoCard.Label trailingIcon={trailingIcon} labelIcon={icons}>
					{props.title}
				</InfoCard.Label>
				<InfoCard.SubLabel>{props.description}</InfoCard.SubLabel>
			</InfoCard.LabelContainer>
		</InfoCard>
	);
};

export default TaskCardComponent;
