import {
	Box,
	createStyles,
	FormControl,
	Input,
	InputLabel,
	makeStyles,
	MenuItem,
	Select,
	Theme,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';

import DownloadFileComponent from '../../components/DownloadFileComponent';
import ModalContentComponent from '../../components/ModalContentComponent';
import UploadFileComponent from '../../components/UploadFileComponent';
import UploadFileProgressComponent from '../../components/UploadFileProgressComponent';
import ModalContext from '../../contexts/ModalContext';
import { FileType, Maybe } from '../../graphql/types';
import useFileDownload from '../../hooks/useFileDownload';
import useUploadFile from '../../hooks/useUploadFile';
import { UploadState } from '../../hooks/useUploadFile/useUploadFile';
import YoungPersonPickerView from '../../views/YoungPersonPickerView';

// Components
// Context
// Hooks
// Types
// Utils
export type UploadModalLayoutProps = {
	fileType?: FileType;
	youngPersonId?: string;
	houseId?: string;
	groupName?: Maybe<string>;
};

const FILE_TYPES = Object.values(FileType) as FileType[];

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing('lg'),
		},
		wrapper: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing('lg'),
		},
		hiddenInput: {
			display: 'none',
		},
	})
);

const UploadModalLayout: React.FC<UploadModalLayoutProps> = (props) => {
	// Modal Values
	const title = props.fileType
		? `Upload ${sentenceCase(props.fileType)}`
		: 'Upload File';
	const [disableSubmit, setDisableSubmit] = useState<boolean>(true);

	const [fileType, setFileType] = useState<FileType | undefined>(
		props.fileType
	);

	// Hooks and Contexts
	const { upload, uploadProgress, uploadState } = useUploadFile();
	const { downloadTemplate } = useFileDownload();
	const { register, watch } = useForm();

	const { close } = useContext(ModalContext);
	const classes = useStyles();

	// Watch changes in the form
	const selectedFile: File = watch('file')?.item(0);
	const fileName: string = watch('fileName');

	// When all the required fields have been entered allow for the upload button to be pressed
	useEffect(() => {
		if (selectedFile && props.houseId && fileType) {
			setDisableSubmit(false);
		}
	}, [selectedFile, props.youngPersonId, props.houseId, fileType]);

	useEffect(() => {
		if (uploadState === UploadState.SUCCESS) {
			close();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadState]);

	const onSubmitClick = () => {
		if (!props.houseId || !fileType) return;

		upload({
			file: selectedFile,
			youngPersonId: props.youngPersonId ?? '',
			houseId: props.houseId,
			fileType: fileType,
			fileName: fileName ? fileName : selectedFile.name,
			folderName: props.groupName ?? undefined,
		});
	};

	const onDownloadTemplate = () => {
		if (fileType) downloadTemplate(fileType);
	};

	return (
		<ModalContentComponent
			modalTitle={{
				title,
			}}
			modalButtons={{
				primaryAction: {
					label: 'Upload',
					onClick: onSubmitClick,
					disabled: disableSubmit,
				},
			}}
		>
			<Box className={classes.wrapper}>
				<DownloadFileComponent
					onClick={onDownloadTemplate}
					disabled={!fileType || fileType === FileType.Other}
				/>
				<form className={classes.form}>
					<input
						className={classes.hiddenInput}
						id="file"
						{...register('file')}
						type="file"
						accept=".pdf, .doc, .docx, .xls"
					/>
					<UploadFileComponent htmlFor="file" />
					<UploadFileProgressComponent
						state={uploadState}
						progress={uploadProgress}
						fileName={selectedFile?.name ?? ''}
					/>

					{/* If there is no YP passed through the props then allow them to select one */}

					{/* BUG: [STLL-285] How does the youngPersonId get to the onSubmit value */}
					{!props.youngPersonId ? (
						<YoungPersonPickerView houseId={props.houseId ?? ''} />
					) : null}

					{/* If there is no File Type passed through the props then allow them to select one */}
					{!props.fileType ? (
						<FormControl style={{ width: '100%' }}>
							<InputLabel id="file-upload-type">Select a File type</InputLabel>
							<Select
								labelId="file-upload-type"
								id="file-upload-type-select"
								value={fileType}
								onChange={({ target: { value } }) => {
									const type = value as FileType;
									setFileType(type);
								}}
							>
								{FILE_TYPES.map((type) => {
									return <MenuItem value={type}>{sentenceCase(type)}</MenuItem>;
								})}
							</Select>
						</FormControl>
					) : null}

					{/* If the user selects other give them the option to add a file name */}
					{fileType === FileType.Other ? (
						<FormControl style={{ width: '100%' }}>
							<InputLabel id="file-upload-type">
								Enter the File's name
							</InputLabel>
							<Input
								id="file-upload-type-name"
								type="text"
								{...register('fileName')}
							/>
						</FormControl>
					) : null}
				</form>
			</Box>
		</ModalContentComponent>
	);
};

export default UploadModalLayout;
