import NavigationView from './NavigationView';

type NavigationViewContainerProps = {};

const NavigationViewContainer: React.FC<NavigationViewContainerProps> = (
	props
) => {
	return <NavigationView />;
};

export default NavigationViewContainer;
