import * as Types from '../../../../graphql/types';

import { gql } from '@apollo/client';
import { BasicTaskFragmentDoc } from '../../../../views/TasksView/query.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type HouseWithUsersFragment = {
	__typename?: 'House';
	id: string;
	title: string;
	users?:
		| Array<{
				__typename?: 'User';
				id: string;
				name?: string | null | undefined;
		  }>
		| null
		| undefined;
};

export type BasicYoungPersonIdentityFragment = {
	__typename?: 'YoungPerson';
	id: string;
	name: string;
};

export type GetTaskCreateFormDataByHouseIdQueryVariables = Types.Exact<{
	houseId: Types.Scalars['String'];
}>;

export type GetTaskCreateFormDataByHouseIdQuery = {
	__typename?: 'Query';
	getHouse?:
		| {
				__typename?: 'House';
				id: string;
				title: string;
				youngPeople?:
					| Array<{ __typename?: 'YoungPerson'; id: string; name: string }>
					| null
					| undefined;
				users?:
					| Array<{
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
					  }>
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type GetTaskCreateFormDataByYoungPersonIdQueryVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
}>;

export type GetTaskCreateFormDataByYoungPersonIdQuery = {
	__typename?: 'Query';
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				house?:
					| {
							__typename?: 'House';
							id: string;
							title: string;
							users?:
								| Array<{
										__typename?: 'User';
										id: string;
										name?: string | null | undefined;
								  }>
								| null
								| undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type CreateNewTaskMutationVariables = Types.Exact<{
	data: Types.TaskCreateInput;
}>;

export type CreateNewTaskMutation = {
	__typename?: 'Mutation';
	createTask?:
		| {
				__typename?: 'Task';
				id: string;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				title: string;
				description?: string | null | undefined;
				group?: Types.TaskGroup | null | undefined;
				youngPersonId?: string | null | undefined;
				completedAt?: any | null | undefined;
				repeat?: Types.RepeatOption | null | undefined;
				dueDate?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				createdAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type UpdateTaskMutationVariables = Types.Exact<{
	data: Types.TaskUpdateInput;
	id?: Types.Maybe<Types.Scalars['String']>;
}>;

export type UpdateTaskMutation = {
	__typename?: 'Mutation';
	updateTask?:
		| {
				__typename?: 'Task';
				id: string;
				isAdmin?: boolean | null | undefined;
				isAutomated?: boolean | null | undefined;
				title: string;
				description?: string | null | undefined;
				group?: Types.TaskGroup | null | undefined;
				youngPersonId?: string | null | undefined;
				completedAt?: any | null | undefined;
				repeat?: Types.RepeatOption | null | undefined;
				dueDate?: any | null | undefined;
				isPrivate?: boolean | null | undefined;
				createdAt?: any | null | undefined;
				deletedAt?: any | null | undefined;
				assignedTo?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				completedBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
				createdBy?:
					| {
							__typename?: 'User';
							id: string;
							name?: string | null | undefined;
							email: any;
							avatar?: string | null | undefined;
							firstName: string;
							lastName: string;
							role: Types.Role;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const HouseWithUsersFragmentDoc = gql`
	fragment HouseWithUsers on House {
		id
		title
		users {
			id
			name
		}
	}
`;
export const BasicYoungPersonIdentityFragmentDoc = gql`
	fragment BasicYoungPersonIdentity on YoungPerson {
		id
		name
	}
`;
export const GetTaskCreateFormDataByHouseIdDocument = gql`
	query GetTaskCreateFormDataByHouseId($houseId: String!) {
		getHouse(where: { id: $houseId }) {
			...HouseWithUsers
			youngPeople {
				...BasicYoungPersonIdentity
			}
		}
	}
	${HouseWithUsersFragmentDoc}
	${BasicYoungPersonIdentityFragmentDoc}
`;

/**
 * __useGetTaskCreateFormDataByHouseIdQuery__
 *
 * To run a query within a React component, call `useGetTaskCreateFormDataByHouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCreateFormDataByHouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCreateFormDataByHouseIdQuery({
 *   variables: {
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useGetTaskCreateFormDataByHouseIdQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetTaskCreateFormDataByHouseIdQuery,
		GetTaskCreateFormDataByHouseIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetTaskCreateFormDataByHouseIdQuery,
		GetTaskCreateFormDataByHouseIdQueryVariables
	>(GetTaskCreateFormDataByHouseIdDocument, options);
}
export function useGetTaskCreateFormDataByHouseIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetTaskCreateFormDataByHouseIdQuery,
		GetTaskCreateFormDataByHouseIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetTaskCreateFormDataByHouseIdQuery,
		GetTaskCreateFormDataByHouseIdQueryVariables
	>(GetTaskCreateFormDataByHouseIdDocument, options);
}
export type GetTaskCreateFormDataByHouseIdQueryHookResult = ReturnType<
	typeof useGetTaskCreateFormDataByHouseIdQuery
>;
export type GetTaskCreateFormDataByHouseIdLazyQueryHookResult = ReturnType<
	typeof useGetTaskCreateFormDataByHouseIdLazyQuery
>;
export type GetTaskCreateFormDataByHouseIdQueryResult = Apollo.QueryResult<
	GetTaskCreateFormDataByHouseIdQuery,
	GetTaskCreateFormDataByHouseIdQueryVariables
>;
export const GetTaskCreateFormDataByYoungPersonIdDocument = gql`
	query GetTaskCreateFormDataByYoungPersonId($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			...BasicYoungPersonIdentity
			house {
				...HouseWithUsers
			}
		}
	}
	${BasicYoungPersonIdentityFragmentDoc}
	${HouseWithUsersFragmentDoc}
`;

/**
 * __useGetTaskCreateFormDataByYoungPersonIdQuery__
 *
 * To run a query within a React component, call `useGetTaskCreateFormDataByYoungPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCreateFormDataByYoungPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCreateFormDataByYoungPersonIdQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetTaskCreateFormDataByYoungPersonIdQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetTaskCreateFormDataByYoungPersonIdQuery,
		GetTaskCreateFormDataByYoungPersonIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetTaskCreateFormDataByYoungPersonIdQuery,
		GetTaskCreateFormDataByYoungPersonIdQueryVariables
	>(GetTaskCreateFormDataByYoungPersonIdDocument, options);
}
export function useGetTaskCreateFormDataByYoungPersonIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetTaskCreateFormDataByYoungPersonIdQuery,
		GetTaskCreateFormDataByYoungPersonIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetTaskCreateFormDataByYoungPersonIdQuery,
		GetTaskCreateFormDataByYoungPersonIdQueryVariables
	>(GetTaskCreateFormDataByYoungPersonIdDocument, options);
}
export type GetTaskCreateFormDataByYoungPersonIdQueryHookResult = ReturnType<
	typeof useGetTaskCreateFormDataByYoungPersonIdQuery
>;
export type GetTaskCreateFormDataByYoungPersonIdLazyQueryHookResult =
	ReturnType<typeof useGetTaskCreateFormDataByYoungPersonIdLazyQuery>;
export type GetTaskCreateFormDataByYoungPersonIdQueryResult =
	Apollo.QueryResult<
		GetTaskCreateFormDataByYoungPersonIdQuery,
		GetTaskCreateFormDataByYoungPersonIdQueryVariables
	>;
export const CreateNewTaskDocument = gql`
	mutation CreateNewTask($data: TaskCreateInput!) {
		createTask(data: $data) {
			...BasicTask
		}
	}
	${BasicTaskFragmentDoc}
`;
export type CreateNewTaskMutationFn = Apollo.MutationFunction<
	CreateNewTaskMutation,
	CreateNewTaskMutationVariables
>;

/**
 * __useCreateNewTaskMutation__
 *
 * To run a mutation, you first call `useCreateNewTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewTaskMutation, { data, loading, error }] = useCreateNewTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNewTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		CreateNewTaskMutation,
		CreateNewTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		CreateNewTaskMutation,
		CreateNewTaskMutationVariables
	>(CreateNewTaskDocument, options);
}
export type CreateNewTaskMutationHookResult = ReturnType<
	typeof useCreateNewTaskMutation
>;
export type CreateNewTaskMutationResult =
	Apollo.MutationResult<CreateNewTaskMutation>;
export type CreateNewTaskMutationOptions = Apollo.BaseMutationOptions<
	CreateNewTaskMutation,
	CreateNewTaskMutationVariables
>;
export const UpdateTaskDocument = gql`
	mutation UpdateTask($data: TaskUpdateInput!, $id: String) {
		updateTask(where: { id: $id }, data: $data) {
			...BasicTask
		}
	}
	${BasicTaskFragmentDoc}
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
	UpdateTaskMutation,
	UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateTaskMutation,
		UpdateTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
		UpdateTaskDocument,
		options
	);
}
export type UpdateTaskMutationHookResult = ReturnType<
	typeof useUpdateTaskMutation
>;
export type UpdateTaskMutationResult =
	Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
	UpdateTaskMutation,
	UpdateTaskMutationVariables
>;
