import React, { useContext } from 'react';

import {
	Box,
	makeStyles,
	MenuItem,
	Select,
	Theme,
	FormControl,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import AddButtonComponent from '../../components/AddButtonComponent';
import SearchComponent from '../../components/SearchComponent';
import FallbackComponent from '../../components/FallbackComponent';
import ModalContext, { ModalType } from '../../contexts/ModalContext';
import ListResourceGridComponent from '../../components/ListResourceGridComponent';
import { FormType, YoungPersonStatus } from '../../graphql/types';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => ({
	title: {
		marginBottom: theme.spacing(8),
		marginLeft: theme.spacing(1),
	},
	topBar: {
		marginBottom: theme.spacing(4),
	},
	sideBarContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		minHeight: '100%',
		minWidth: theme.spacing(48),
		padding: theme.spacing(4),
	},
	topBarActions: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap-reverse',
		justifyContent: 'space-between',
		gap: theme.spacing('md'),
		margin: theme.spacing(2, 0),
	},
	formControl: {
		minHeight: theme.spacing(5),
		minWidth: theme.spacing(18),
		background: theme.palette.grey[100],
		padding: theme.spacing(0, 2),
		borderRadius: theme.spacing(3),
		marginRight: theme.spacing('md'),
	},
	select: {
		minHeight: theme.spacing(5),
		background: theme.palette.grey[100],
		marginLeft: theme.spacing(1),
		border: 'none',
		'&[class*="MuiInput-underline"]::before': {
			display: 'none',
		},
		'&[class*="MuiInput-underline"]::after': {
			display: 'none',
		},
		'& [class*="MuiSelect-select"]:focus': {
			backgroundColor: 'transparent',
		},
	},
	search: {
		background: theme.palette.grey[100],
		border: 'none',
		borderRadius: theme.spacing(3),
		width: `max(40%, ${theme.spacing(32)})`,
		'& [class*="MuiOutlinedInput-notchedOutline"]': {
			borderRadius: '0',
			borderColor: 'transparent',
		},
		'&&& [class*="MuiOutlinedInput-root.Mui-focused] [class*="MuiOutlinedInput-notchedOutline"]':
			{
				border: 'none',
			},
	},
	searchIcon: {
		color: theme.palette.grey[600],
		marginRight: theme.spacing(1),
	},
}));

type FilterOption = {
	label: string;
	value?: YoungPersonStatus | 'All Statuses';
};
type AdminLayoutProps = {
	title: string;
	loading?: boolean;
	showEmpty?: boolean;
	emptyTitle?: string;
	emptyCopy?: string;
	filter?: YoungPersonStatus | 'All Statuses';
	onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onFilter?: (
		e: React.ChangeEvent<{
			name?: string | undefined;
			value: YoungPersonStatus;
		}>
	) => void;
	filterOptions?: FilterOption[];
	createForm?: FormType;
};

const AdminLayout: React.FC<AdminLayoutProps> = (props) => {
	const classes = useStyles();
	const { open } = useContext(ModalContext);
	const theme = useTheme<Theme>();
	const isLargeOrHigher = useMediaQuery(theme.breakpoints.up('lg'));

	return (
		<Box minHeight="75vh" display="flex" flexDirection="column">
			<Typography className={classes.title} color="textPrimary" variant="h1">
				{props.title}
			</Typography>
			<Box paddingBottom={8}>
				<ListResourceGridComponent>
					{props.onSearch && <SearchComponent onSearch={props.onSearch} />}
					{isLargeOrHigher ? <Spacer /> : null}
					<Box display="flex" justifyContent="flex-end" alignItems="center">
						{props.onFilter && (
							<FormControl className={classes.formControl}>
								<Select
									labelId="filter-young-people"
									id="filter-young-people"
									value={props.filter}
									className={classes.select}
									// @ts-ignore TYPE: Fix me
									onChange={props.onFilter}
								>
									{props?.filterOptions?.map((option) => (
										<MenuItem value={option.value}>{option.label}</MenuItem>
									))}
								</Select>
							</FormControl>
						)}
						{props.createForm && (
							<AddButtonComponent
								onClick={() => {
									if (props.createForm)
										open({
											type: ModalType.FORM,
											args: {
												// @ts-expect-error
												type: props.createForm as FormType,
											},
										});
								}}
								disabled={!props.createForm}
							/>
						)}
					</Box>
				</ListResourceGridComponent>
			</Box>
			<Box flex="1" display="flex" flexDirection="column" minHeight="7.5rem">
				<FallbackComponent
					isLoading={Boolean(props.loading)}
					isEmpty={props.showEmpty}
					emptyTitle={props.emptyTitle ?? 'No items found.'}
					emptyCopy={props.emptyCopy}
					loadingType="skeletonGridMd"
				>
					{props.children}
				</FallbackComponent>
			</Box>
		</Box>
	);
};

const Spacer = () => <div aria-hidden="true" />;

export default AdminLayout;
