import { Box, createStyles, Grid, makeStyles, Theme } from '@material-ui/core';

import React, { useContext } from 'react';
import AuthContext from '../../contexts/authContext';

import { OrderByArg } from '../../graphql/types';
import AlertView from '../AlertView';
import HouseDetailsView from '../HouseDetailsView';
import TasksView from '../TasksView';

export type HouseProfileViewProps = {
	houseId: string;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			borderRadius: theme.shape.borderRadius,
			backgroundColor: theme.palette.grey[50],
			padding: theme.spacing(4),
		},
		left: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing('lg'),
			'@media screen and (max-width: 960px)': {
				order: 2,
			},
		},
		right: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing('lg'),
			'@media screen and (max-width: 960px)': {
				order: 1,
			},
		},
	})
);

const HouseDashboardView: React.FC<HouseProfileViewProps> = (props) => {
	const classes = useStyles();
	const { user } = useContext(AuthContext);
	return (
		<Grid container spacing={8}>
			<Grid className={classes.left} item sm={12} md={6}>
				{/* <AlertView houseId={props.houseId} /> */}
				<HouseDetailsView houseId={props.houseId} />
			</Grid>
			<Grid className={classes.right} item sm={12} md={6}>
				<Box>
					<TasksView
						title="Your Tasks"
						limit={10}
						sort={{ dueDate: OrderByArg.Desc }}
						filter={{
							assignedTo: { id: { equals: user?.id ?? '' } },
							completedAt: null,
						}}
					/>
				</Box>
			</Grid>
		</Grid>
	);
};

export default HouseDashboardView;
