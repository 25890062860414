import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export const ActivityFragmentFragmentDoc = gql`
	fragment ActivityFragment on Log {
		id
		type
		data
		createdAt
		createdBy
		youngPerson {
			id
			name
			avatar
		}
	}
`;
export const MedicalConditionFragmentFragmentDoc = gql`
	fragment MedicalConditionFragment on MedicalCondition {
		id
		title
		symptoms
		triggers
		medication
		administrationDetails
		inEmergency
	}
`;
export const NextOfKinFragmentFragmentDoc = gql`
	fragment NextOfKinFragment on NextOfKin {
		id
		name
		relation
		phone
		contactable
		visitable
		note
	}
`;
export const SimpleRoomFragmentDoc = gql`
	fragment SimpleRoom on Room {
		id
		name
		color
		youngPerson {
			__typename
			name
		}
	}
`;
export const HouseWithUsersFragmentDoc = gql`
	fragment HouseWithUsers on House {
		id
		title
		users {
			id
			name
		}
	}
`;
export const BasicYoungPersonIdentityFragmentDoc = gql`
	fragment BasicYoungPersonIdentity on YoungPerson {
		id
		name
	}
`;
export const UserDetailsFragmentDoc = gql`
	fragment UserDetails on User {
		id
		firstName
		lastName
		phone
		address
		email
		houseId
		disabled
		role
	}
`;
export const YoungPersonAboutMeFragmentDoc = gql`
	fragment YoungPersonAboutMe on YoungPerson {
		id
		aboutMe
	}
`;
export const YoungPersonBasicDetailsFragmentDoc = gql`
	fragment YoungPersonBasicDetails on YoungPerson {
		id
		name
		preferredName
		email
		phone
		dateOfBirth
		gender
		mostRecentAddress
		physicalDescription
		ethnicity
		spokenLanguage
		safePlace
		religion
	}
`;
export const HouseIdDetailsFragmentDoc = gql`
	fragment HouseIdDetails on House {
		id
		title
	}
`;
export const YoungPersonLegalDetailsFragmentDoc = gql`
	fragment YoungPersonLegalDetails on YoungPerson {
		id
		niNumber
		registeredGP
		registeredOptician
		registeredDentist
		nhsNumber
		socialWorker
		legalStatus
		ypAdvisor
		localAuthority
	}
`;
export const HouseCardFragmentDoc = gql`
	fragment HouseCard on House {
		id
		title
		address
		color
		preMoveInHouse
	}
`;
export const UserWithHouseFragmentDoc = gql`
	fragment UserWithHouse on User {
		id
		name
		role
		house {
			id
			title
			color
		}
	}
`;
export const YoungPersonScreenDataFragmentDoc = gql`
	fragment YoungPersonScreenData on YoungPerson {
		id
		name
		status
		houseId
		house {
			id
			title
		}
		room {
			id
			name
			color
		}
		dateOfBirth
		phone
		avatar
		missingAt
		locationStatus {
			id
			title
			type
			priority
		}
	}
`;
export const LogFragmentFragmentDoc = gql`
	fragment LogFragment on Log {
		id
		type
		data
		createdAt
		youngPerson {
			name
			house {
				title
			}
		}
	}
`;
export const FileFragmentDoc = gql`
	fragment File on File {
		id
		lastModified
		key
	}
`;
export const GroupTaskFragmentDoc = gql`
	fragment GroupTask on Task {
		id
		title
		isAdmin
		isPrivate
		youngPersonId
		group
		completedAt
		updatedAt
	}
`;
export const BasicUserFragmentDoc = gql`
	fragment BasicUser on User {
		id
		name
		email
		avatar
		firstName
		lastName
		role
	}
`;
export const BasicTaskFragmentDoc = gql`
	fragment BasicTask on Task {
		id
		isAdmin
		isAutomated
		title
		description
		group
		youngPersonId
		completedAt
		repeat
		dueDate
		isPrivate
		assignedTo {
			...BasicUser
		}
		completedBy {
			...BasicUser
		}
		createdBy {
			...BasicUser
		}
		createdAt
		deletedAt
	}
	${BasicUserFragmentDoc}
`;
export const YoungPersonFragmentDoc = gql`
	fragment YoungPerson on YoungPerson {
		id
		status
		house {
			id
		}
	}
`;
export const YoungPersonProfileFragmentDoc = gql`
	fragment YoungPersonProfile on YoungPerson {
		id
		name
		preferredName
		email
		phone
		dateOfBirth
		gender
		mostRecentAddress
		ethnicity
		spokenLanguage
		safePlace
		religion
		niNumber
		registeredGP
		registeredOptician
		registeredDentist
		nhsNumber
		socialWorker
		photoConcept
		riskAssessment
		legalStatus
		ypAdvisor
		localAuthority
		physicalDescription
		aboutMe
		status
		houseId
		createdAt
		updatedAt
		deletedAt
		nextOfKin {
			id
			name
			relation
			phone
			youngPersonId
			contactable
			visitable
			note
		}
		medicalConditions {
			id
			title
			medication
			administrationDetails
			symptoms
			triggers
			inEmergency
			youngPersonId
		}
	}
`;
export const YoungPersonWithLocationStatusFragmentDoc = gql`
	fragment YoungPersonWithLocationStatus on YoungPerson {
		id
		name
		avatar
		locationStatusId
		missingAt
		locationStatus {
			id
			title
			type
			priority
		}
	}
`;
export const GetMeDocument = gql`
	query GetMe {
		me {
			id
			name
			firstName
			lastName
			email
			role
			houseId
		}
	}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetMeQuery,
		Types.GetMeQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.GetMeQuery, Types.GetMeQueryVariables>(
		GetMeDocument,
		options
	);
}
export function useGetMeLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetMeQuery,
		Types.GetMeQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<Types.GetMeQuery, Types.GetMeQueryVariables>(
		GetMeDocument,
		options
	);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<
	Types.GetMeQuery,
	Types.GetMeQueryVariables
>;
export const GetUploadUrlDocument = gql`
	mutation GetUploadURL(
		$userId: String!
		$fileType: String!
		$userType: AvatarUserType!
	) {
		createUploadAvatarUrl(
			input: { userId: $userId, fileType: $fileType, userType: $userType }
		) {
			url
			statusCode
		}
	}
`;
export type GetUploadUrlMutationFn = Apollo.MutationFunction<
	Types.GetUploadUrlMutation,
	Types.GetUploadUrlMutationVariables
>;

/**
 * __useGetUploadUrlMutation__
 *
 * To run a mutation, you first call `useGetUploadUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetUploadUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getUploadUrlMutation, { data, loading, error }] = useGetUploadUrlMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      fileType: // value for 'fileType'
 *      userType: // value for 'userType'
 *   },
 * });
 */
export function useGetUploadUrlMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.GetUploadUrlMutation,
		Types.GetUploadUrlMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.GetUploadUrlMutation,
		Types.GetUploadUrlMutationVariables
	>(GetUploadUrlDocument, options);
}
export type GetUploadUrlMutationHookResult = ReturnType<
	typeof useGetUploadUrlMutation
>;
export type GetUploadUrlMutationResult =
	Apollo.MutationResult<Types.GetUploadUrlMutation>;
export type GetUploadUrlMutationOptions = Apollo.BaseMutationOptions<
	Types.GetUploadUrlMutation,
	Types.GetUploadUrlMutationVariables
>;
export const CreateDownloadLinkDocument = gql`
	mutation CreateDownloadLink($fileKey: String!) {
		createDownloadUrl(fileKey: $fileKey) {
			url
			statusCode
		}
	}
`;
export type CreateDownloadLinkMutationFn = Apollo.MutationFunction<
	Types.CreateDownloadLinkMutation,
	Types.CreateDownloadLinkMutationVariables
>;

/**
 * __useCreateDownloadLinkMutation__
 *
 * To run a mutation, you first call `useCreateDownloadLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDownloadLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDownloadLinkMutation, { data, loading, error }] = useCreateDownloadLinkMutation({
 *   variables: {
 *      fileKey: // value for 'fileKey'
 *   },
 * });
 */
export function useCreateDownloadLinkMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateDownloadLinkMutation,
		Types.CreateDownloadLinkMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateDownloadLinkMutation,
		Types.CreateDownloadLinkMutationVariables
	>(CreateDownloadLinkDocument, options);
}
export type CreateDownloadLinkMutationHookResult = ReturnType<
	typeof useCreateDownloadLinkMutation
>;
export type CreateDownloadLinkMutationResult =
	Apollo.MutationResult<Types.CreateDownloadLinkMutation>;
export type CreateDownloadLinkMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateDownloadLinkMutation,
	Types.CreateDownloadLinkMutationVariables
>;
export const CreateAlertDocument = gql`
	mutation CreateAlert($data: NotificationCreateInput!) {
		createNotification(data: $data) {
			id
			title
			severity
			houseId
			createdAt
			readAt
		}
	}
`;
export type CreateAlertMutationFn = Apollo.MutationFunction<
	Types.CreateAlertMutation,
	Types.CreateAlertMutationVariables
>;

/**
 * __useCreateAlertMutation__
 *
 * To run a mutation, you first call `useCreateAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAlertMutation, { data, loading, error }] = useCreateAlertMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateAlertMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateAlertMutation,
		Types.CreateAlertMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateAlertMutation,
		Types.CreateAlertMutationVariables
	>(CreateAlertDocument, options);
}
export type CreateAlertMutationHookResult = ReturnType<
	typeof useCreateAlertMutation
>;
export type CreateAlertMutationResult =
	Apollo.MutationResult<Types.CreateAlertMutation>;
export type CreateAlertMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateAlertMutation,
	Types.CreateAlertMutationVariables
>;
export const CreateHouseDocument = gql`
	mutation CreateHouse($data: HouseCreateInput!) {
		createHouse(data: $data) {
			...HouseCard
		}
	}
	${HouseCardFragmentDoc}
`;
export type CreateHouseMutationFn = Apollo.MutationFunction<
	Types.CreateHouseMutation,
	Types.CreateHouseMutationVariables
>;

/**
 * __useCreateHouseMutation__
 *
 * To run a mutation, you first call `useCreateHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHouseMutation, { data, loading, error }] = useCreateHouseMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateHouseMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateHouseMutation,
		Types.CreateHouseMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateHouseMutation,
		Types.CreateHouseMutationVariables
	>(CreateHouseDocument, options);
}
export type CreateHouseMutationHookResult = ReturnType<
	typeof useCreateHouseMutation
>;
export type CreateHouseMutationResult =
	Apollo.MutationResult<Types.CreateHouseMutation>;
export type CreateHouseMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateHouseMutation,
	Types.CreateHouseMutationVariables
>;
export const InviteUserDocument = gql`
	mutation InviteUser($data: UserCreateInput!) {
		createUser(data: $data) {
			id
		}
	}
`;
export type InviteUserMutationFn = Apollo.MutationFunction<
	Types.InviteUserMutation,
	Types.InviteUserMutationVariables
>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInviteUserMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.InviteUserMutation,
		Types.InviteUserMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.InviteUserMutation,
		Types.InviteUserMutationVariables
	>(InviteUserDocument, options);
}
export type InviteUserMutationHookResult = ReturnType<
	typeof useInviteUserMutation
>;
export type InviteUserMutationResult =
	Apollo.MutationResult<Types.InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<
	Types.InviteUserMutation,
	Types.InviteUserMutationVariables
>;
export const CheckUserWithEmailDocument = gql`
	query CheckUserWithEmail($email: AWSEmail) {
		getUser(where: { email: $email }) {
			id
		}
	}
`;

/**
 * __useCheckUserWithEmailQuery__
 *
 * To run a query within a React component, call `useCheckUserWithEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserWithEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserWithEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCheckUserWithEmailQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.CheckUserWithEmailQuery,
		Types.CheckUserWithEmailQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.CheckUserWithEmailQuery,
		Types.CheckUserWithEmailQueryVariables
	>(CheckUserWithEmailDocument, options);
}
export function useCheckUserWithEmailLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.CheckUserWithEmailQuery,
		Types.CheckUserWithEmailQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.CheckUserWithEmailQuery,
		Types.CheckUserWithEmailQueryVariables
	>(CheckUserWithEmailDocument, options);
}
export type CheckUserWithEmailQueryHookResult = ReturnType<
	typeof useCheckUserWithEmailQuery
>;
export type CheckUserWithEmailLazyQueryHookResult = ReturnType<
	typeof useCheckUserWithEmailLazyQuery
>;
export type CheckUserWithEmailQueryResult = Apollo.QueryResult<
	Types.CheckUserWithEmailQuery,
	Types.CheckUserWithEmailQueryVariables
>;
export const GetActivityLogDocument = gql`
	query GetActivityLog($id: String!) {
		getLog(where: { id: $id }) {
			...ActivityFragment
		}
	}
	${ActivityFragmentFragmentDoc}
`;

/**
 * __useGetActivityLogQuery__
 *
 * To run a query within a React component, call `useGetActivityLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActivityLogQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetActivityLogQuery,
		Types.GetActivityLogQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetActivityLogQuery,
		Types.GetActivityLogQueryVariables
	>(GetActivityLogDocument, options);
}
export function useGetActivityLogLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetActivityLogQuery,
		Types.GetActivityLogQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetActivityLogQuery,
		Types.GetActivityLogQueryVariables
	>(GetActivityLogDocument, options);
}
export type GetActivityLogQueryHookResult = ReturnType<
	typeof useGetActivityLogQuery
>;
export type GetActivityLogLazyQueryHookResult = ReturnType<
	typeof useGetActivityLogLazyQuery
>;
export type GetActivityLogQueryResult = Apollo.QueryResult<
	Types.GetActivityLogQuery,
	Types.GetActivityLogQueryVariables
>;
export const FormListYoungPeopleDocument = gql`
	query FormListYoungPeople($where: YoungPersonWhereFilterInput) {
		listYoungPeople(where: $where) {
			id
			name
		}
	}
`;

/**
 * __useFormListYoungPeopleQuery__
 *
 * To run a query within a React component, call `useFormListYoungPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormListYoungPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormListYoungPeopleQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFormListYoungPeopleQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.FormListYoungPeopleQuery,
		Types.FormListYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.FormListYoungPeopleQuery,
		Types.FormListYoungPeopleQueryVariables
	>(FormListYoungPeopleDocument, options);
}
export function useFormListYoungPeopleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.FormListYoungPeopleQuery,
		Types.FormListYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.FormListYoungPeopleQuery,
		Types.FormListYoungPeopleQueryVariables
	>(FormListYoungPeopleDocument, options);
}
export type FormListYoungPeopleQueryHookResult = ReturnType<
	typeof useFormListYoungPeopleQuery
>;
export type FormListYoungPeopleLazyQueryHookResult = ReturnType<
	typeof useFormListYoungPeopleLazyQuery
>;
export type FormListYoungPeopleQueryResult = Apollo.QueryResult<
	Types.FormListYoungPeopleQuery,
	Types.FormListYoungPeopleQueryVariables
>;
export const UpdateActivityLogDocument = gql`
	mutation updateActivityLog($id: String!, $data: LogUpdateInput!) {
		updateLog(where: { id: $id }, data: $data) {
			id
		}
	}
`;
export type UpdateActivityLogMutationFn = Apollo.MutationFunction<
	Types.UpdateActivityLogMutation,
	Types.UpdateActivityLogMutationVariables
>;

/**
 * __useUpdateActivityLogMutation__
 *
 * To run a mutation, you first call `useUpdateActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityLogMutation, { data, loading, error }] = useUpdateActivityLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateActivityLogMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateActivityLogMutation,
		Types.UpdateActivityLogMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateActivityLogMutation,
		Types.UpdateActivityLogMutationVariables
	>(UpdateActivityLogDocument, options);
}
export type UpdateActivityLogMutationHookResult = ReturnType<
	typeof useUpdateActivityLogMutation
>;
export type UpdateActivityLogMutationResult =
	Apollo.MutationResult<Types.UpdateActivityLogMutation>;
export type UpdateActivityLogMutationOptions = Apollo.BaseMutationOptions<
	Types.UpdateActivityLogMutation,
	Types.UpdateActivityLogMutationVariables
>;
export const CreateActivityLogDocument = gql`
	mutation createActivityLog($data: LogCreateInput!) {
		createLog(data: $data) {
			id
		}
	}
`;
export type CreateActivityLogMutationFn = Apollo.MutationFunction<
	Types.CreateActivityLogMutation,
	Types.CreateActivityLogMutationVariables
>;

/**
 * __useCreateActivityLogMutation__
 *
 * To run a mutation, you first call `useCreateActivityLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActivityLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActivityLogMutation, { data, loading, error }] = useCreateActivityLogMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateActivityLogMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateActivityLogMutation,
		Types.CreateActivityLogMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateActivityLogMutation,
		Types.CreateActivityLogMutationVariables
	>(CreateActivityLogDocument, options);
}
export type CreateActivityLogMutationHookResult = ReturnType<
	typeof useCreateActivityLogMutation
>;
export type CreateActivityLogMutationResult =
	Apollo.MutationResult<Types.CreateActivityLogMutation>;
export type CreateActivityLogMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateActivityLogMutation,
	Types.CreateActivityLogMutationVariables
>;
export const CreateLocationLogDocument = gql`
	mutation createLocationLog(
		$logData: LogCreateInput!
		$locationStatusId: String!
		$youngPersonId: String!
		$missingAt: AWSDateTime
	) {
		createLog(data: $logData) {
			id
		}
		updateYoungPerson(
			where: { id: $youngPersonId }
			data: { locationStatusId: $locationStatusId, missingAt: $missingAt }
		) {
			...YoungPersonWithLocationStatus
		}
	}
	${YoungPersonWithLocationStatusFragmentDoc}
`;
export type CreateLocationLogMutationFn = Apollo.MutationFunction<
	Types.CreateLocationLogMutation,
	Types.CreateLocationLogMutationVariables
>;

/**
 * __useCreateLocationLogMutation__
 *
 * To run a mutation, you first call `useCreateLocationLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationLogMutation, { data, loading, error }] = useCreateLocationLogMutation({
 *   variables: {
 *      logData: // value for 'logData'
 *      locationStatusId: // value for 'locationStatusId'
 *      youngPersonId: // value for 'youngPersonId'
 *      missingAt: // value for 'missingAt'
 *   },
 * });
 */
export function useCreateLocationLogMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateLocationLogMutation,
		Types.CreateLocationLogMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateLocationLogMutation,
		Types.CreateLocationLogMutationVariables
	>(CreateLocationLogDocument, options);
}
export type CreateLocationLogMutationHookResult = ReturnType<
	typeof useCreateLocationLogMutation
>;
export type CreateLocationLogMutationResult =
	Apollo.MutationResult<Types.CreateLocationLogMutation>;
export type CreateLocationLogMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateLocationLogMutation,
	Types.CreateLocationLogMutationVariables
>;
export const ListAdminUsersDocument = gql`
	query ListAdminUsers {
		listUsers(
			where: {
				AND: [
					{ role: { equals: ADMIN } }
					{ hideAccount: { not: { equals: true } } }
				]
			}
		) {
			id
			name
		}
	}
`;

/**
 * __useListAdminUsersQuery__
 *
 * To run a query within a React component, call `useListAdminUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminUsersQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListAdminUsersQuery,
		Types.ListAdminUsersQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListAdminUsersQuery,
		Types.ListAdminUsersQueryVariables
	>(ListAdminUsersDocument, options);
}
export function useListAdminUsersLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListAdminUsersQuery,
		Types.ListAdminUsersQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListAdminUsersQuery,
		Types.ListAdminUsersQueryVariables
	>(ListAdminUsersDocument, options);
}
export type ListAdminUsersQueryHookResult = ReturnType<
	typeof useListAdminUsersQuery
>;
export type ListAdminUsersLazyQueryHookResult = ReturnType<
	typeof useListAdminUsersLazyQuery
>;
export type ListAdminUsersQueryResult = Apollo.QueryResult<
	Types.ListAdminUsersQuery,
	Types.ListAdminUsersQueryVariables
>;
export const GetYoungPersonsMedicalConditionDocument = gql`
	query GetYoungPersonsMedicalCondition($medicalConditionId: String!) {
		getMedicalCondition(where: { id: $medicalConditionId }) {
			...MedicalConditionFragment
		}
	}
	${MedicalConditionFragmentFragmentDoc}
`;

/**
 * __useGetYoungPersonsMedicalConditionQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonsMedicalConditionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonsMedicalConditionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonsMedicalConditionQuery({
 *   variables: {
 *      medicalConditionId: // value for 'medicalConditionId'
 *   },
 * });
 */
export function useGetYoungPersonsMedicalConditionQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPersonsMedicalConditionQuery,
		Types.GetYoungPersonsMedicalConditionQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPersonsMedicalConditionQuery,
		Types.GetYoungPersonsMedicalConditionQueryVariables
	>(GetYoungPersonsMedicalConditionDocument, options);
}
export function useGetYoungPersonsMedicalConditionLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPersonsMedicalConditionQuery,
		Types.GetYoungPersonsMedicalConditionQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPersonsMedicalConditionQuery,
		Types.GetYoungPersonsMedicalConditionQueryVariables
	>(GetYoungPersonsMedicalConditionDocument, options);
}
export type GetYoungPersonsMedicalConditionQueryHookResult = ReturnType<
	typeof useGetYoungPersonsMedicalConditionQuery
>;
export type GetYoungPersonsMedicalConditionLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonsMedicalConditionLazyQuery
>;
export type GetYoungPersonsMedicalConditionQueryResult = Apollo.QueryResult<
	Types.GetYoungPersonsMedicalConditionQuery,
	Types.GetYoungPersonsMedicalConditionQueryVariables
>;
export const UpdateMedicalConditionDocument = gql`
	mutation UpdateMedicalCondition(
		$medicalConditionId: String!
		$data: MedicalConditionUpdateInput!
	) {
		updateMedicalCondition(where: { id: $medicalConditionId }, data: $data) {
			...MedicalConditionFragment
		}
	}
	${MedicalConditionFragmentFragmentDoc}
`;
export type UpdateMedicalConditionMutationFn = Apollo.MutationFunction<
	Types.UpdateMedicalConditionMutation,
	Types.UpdateMedicalConditionMutationVariables
>;

/**
 * __useUpdateMedicalConditionMutation__
 *
 * To run a mutation, you first call `useUpdateMedicalConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMedicalConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMedicalConditionMutation, { data, loading, error }] = useUpdateMedicalConditionMutation({
 *   variables: {
 *      medicalConditionId: // value for 'medicalConditionId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMedicalConditionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateMedicalConditionMutation,
		Types.UpdateMedicalConditionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateMedicalConditionMutation,
		Types.UpdateMedicalConditionMutationVariables
	>(UpdateMedicalConditionDocument, options);
}
export type UpdateMedicalConditionMutationHookResult = ReturnType<
	typeof useUpdateMedicalConditionMutation
>;
export type UpdateMedicalConditionMutationResult =
	Apollo.MutationResult<Types.UpdateMedicalConditionMutation>;
export type UpdateMedicalConditionMutationOptions = Apollo.BaseMutationOptions<
	Types.UpdateMedicalConditionMutation,
	Types.UpdateMedicalConditionMutationVariables
>;
export const CreateMedicalConditionDocument = gql`
	mutation CreateMedicalCondition($data: MedicalConditionCreateInput!) {
		createMedicalCondition(data: $data) {
			...MedicalConditionFragment
		}
	}
	${MedicalConditionFragmentFragmentDoc}
`;
export type CreateMedicalConditionMutationFn = Apollo.MutationFunction<
	Types.CreateMedicalConditionMutation,
	Types.CreateMedicalConditionMutationVariables
>;

/**
 * __useCreateMedicalConditionMutation__
 *
 * To run a mutation, you first call `useCreateMedicalConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMedicalConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMedicalConditionMutation, { data, loading, error }] = useCreateMedicalConditionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateMedicalConditionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateMedicalConditionMutation,
		Types.CreateMedicalConditionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateMedicalConditionMutation,
		Types.CreateMedicalConditionMutationVariables
	>(CreateMedicalConditionDocument, options);
}
export type CreateMedicalConditionMutationHookResult = ReturnType<
	typeof useCreateMedicalConditionMutation
>;
export type CreateMedicalConditionMutationResult =
	Apollo.MutationResult<Types.CreateMedicalConditionMutation>;
export type CreateMedicalConditionMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateMedicalConditionMutation,
	Types.CreateMedicalConditionMutationVariables
>;
export const GetNextOfKinDocument = gql`
	query GetNextOfKin($nextOfKinId: String!) {
		getNextOfKin(where: { id: $nextOfKinId }) {
			...NextOfKinFragment
		}
	}
	${NextOfKinFragmentFragmentDoc}
`;

/**
 * __useGetNextOfKinQuery__
 *
 * To run a query within a React component, call `useGetNextOfKinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextOfKinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextOfKinQuery({
 *   variables: {
 *      nextOfKinId: // value for 'nextOfKinId'
 *   },
 * });
 */
export function useGetNextOfKinQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetNextOfKinQuery,
		Types.GetNextOfKinQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetNextOfKinQuery,
		Types.GetNextOfKinQueryVariables
	>(GetNextOfKinDocument, options);
}
export function useGetNextOfKinLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetNextOfKinQuery,
		Types.GetNextOfKinQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetNextOfKinQuery,
		Types.GetNextOfKinQueryVariables
	>(GetNextOfKinDocument, options);
}
export type GetNextOfKinQueryHookResult = ReturnType<
	typeof useGetNextOfKinQuery
>;
export type GetNextOfKinLazyQueryHookResult = ReturnType<
	typeof useGetNextOfKinLazyQuery
>;
export type GetNextOfKinQueryResult = Apollo.QueryResult<
	Types.GetNextOfKinQuery,
	Types.GetNextOfKinQueryVariables
>;
export const UpdateNextOfKinDocument = gql`
	mutation UpdateNextOfKin(
		$nextOfKinId: String!
		$data: NextOfKinUpdateInput!
	) {
		updateNextOfKin(where: { id: $nextOfKinId }, data: $data) {
			...NextOfKinFragment
		}
	}
	${NextOfKinFragmentFragmentDoc}
`;
export type UpdateNextOfKinMutationFn = Apollo.MutationFunction<
	Types.UpdateNextOfKinMutation,
	Types.UpdateNextOfKinMutationVariables
>;

/**
 * __useUpdateNextOfKinMutation__
 *
 * To run a mutation, you first call `useUpdateNextOfKinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNextOfKinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNextOfKinMutation, { data, loading, error }] = useUpdateNextOfKinMutation({
 *   variables: {
 *      nextOfKinId: // value for 'nextOfKinId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateNextOfKinMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateNextOfKinMutation,
		Types.UpdateNextOfKinMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateNextOfKinMutation,
		Types.UpdateNextOfKinMutationVariables
	>(UpdateNextOfKinDocument, options);
}
export type UpdateNextOfKinMutationHookResult = ReturnType<
	typeof useUpdateNextOfKinMutation
>;
export type UpdateNextOfKinMutationResult =
	Apollo.MutationResult<Types.UpdateNextOfKinMutation>;
export type UpdateNextOfKinMutationOptions = Apollo.BaseMutationOptions<
	Types.UpdateNextOfKinMutation,
	Types.UpdateNextOfKinMutationVariables
>;
export const CreateNextOfKinDocument = gql`
	mutation CreateNextOfKin($data: NextOfKinCreateInput!) {
		createNextOfKin(data: $data) {
			...NextOfKinFragment
		}
	}
	${NextOfKinFragmentFragmentDoc}
`;
export type CreateNextOfKinMutationFn = Apollo.MutationFunction<
	Types.CreateNextOfKinMutation,
	Types.CreateNextOfKinMutationVariables
>;

/**
 * __useCreateNextOfKinMutation__
 *
 * To run a mutation, you first call `useCreateNextOfKinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNextOfKinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNextOfKinMutation, { data, loading, error }] = useCreateNextOfKinMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNextOfKinMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateNextOfKinMutation,
		Types.CreateNextOfKinMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateNextOfKinMutation,
		Types.CreateNextOfKinMutationVariables
	>(CreateNextOfKinDocument, options);
}
export type CreateNextOfKinMutationHookResult = ReturnType<
	typeof useCreateNextOfKinMutation
>;
export type CreateNextOfKinMutationResult =
	Apollo.MutationResult<Types.CreateNextOfKinMutation>;
export type CreateNextOfKinMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateNextOfKinMutation,
	Types.CreateNextOfKinMutationVariables
>;
export const CreateRoomDocument = gql`
	mutation CreateRoom($data: RoomCreateInput!) {
		createRoom(data: $data) {
			...SimpleRoom
		}
	}
	${SimpleRoomFragmentDoc}
`;
export type CreateRoomMutationFn = Apollo.MutationFunction<
	Types.CreateRoomMutation,
	Types.CreateRoomMutationVariables
>;

/**
 * __useCreateRoomMutation__
 *
 * To run a mutation, you first call `useCreateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoomMutation, { data, loading, error }] = useCreateRoomMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateRoomMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateRoomMutation,
		Types.CreateRoomMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateRoomMutation,
		Types.CreateRoomMutationVariables
	>(CreateRoomDocument, options);
}
export type CreateRoomMutationHookResult = ReturnType<
	typeof useCreateRoomMutation
>;
export type CreateRoomMutationResult =
	Apollo.MutationResult<Types.CreateRoomMutation>;
export type CreateRoomMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateRoomMutation,
	Types.CreateRoomMutationVariables
>;
export const UpdateRoomDocument = gql`
	mutation UpdateRoom($id: String, $data: RoomUpdateInput!) {
		updateRoom(where: { id: $id }, data: $data) {
			...SimpleRoom
		}
	}
	${SimpleRoomFragmentDoc}
`;
export type UpdateRoomMutationFn = Apollo.MutationFunction<
	Types.UpdateRoomMutation,
	Types.UpdateRoomMutationVariables
>;

/**
 * __useUpdateRoomMutation__
 *
 * To run a mutation, you first call `useUpdateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomMutation, { data, loading, error }] = useUpdateRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateRoomMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateRoomMutation,
		Types.UpdateRoomMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateRoomMutation,
		Types.UpdateRoomMutationVariables
	>(UpdateRoomDocument, options);
}
export type UpdateRoomMutationHookResult = ReturnType<
	typeof useUpdateRoomMutation
>;
export type UpdateRoomMutationResult =
	Apollo.MutationResult<Types.UpdateRoomMutation>;
export type UpdateRoomMutationOptions = Apollo.BaseMutationOptions<
	Types.UpdateRoomMutation,
	Types.UpdateRoomMutationVariables
>;
export const GetRoomDocument = gql`
	query GetRoom($id: String) {
		getRoom(where: { id: $id }) {
			...SimpleRoom
		}
	}
	${SimpleRoomFragmentDoc}
`;

/**
 * __useGetRoomQuery__
 *
 * To run a query within a React component, call `useGetRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoomQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetRoomQuery,
		Types.GetRoomQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.GetRoomQuery, Types.GetRoomQueryVariables>(
		GetRoomDocument,
		options
	);
}
export function useGetRoomLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetRoomQuery,
		Types.GetRoomQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<Types.GetRoomQuery, Types.GetRoomQueryVariables>(
		GetRoomDocument,
		options
	);
}
export type GetRoomQueryHookResult = ReturnType<typeof useGetRoomQuery>;
export type GetRoomLazyQueryHookResult = ReturnType<typeof useGetRoomLazyQuery>;
export type GetRoomQueryResult = Apollo.QueryResult<
	Types.GetRoomQuery,
	Types.GetRoomQueryVariables
>;
export const GetTaskCreateFormDataByHouseIdDocument = gql`
	query GetTaskCreateFormDataByHouseId($houseId: String!) {
		getHouse(where: { id: $houseId }) {
			...HouseWithUsers
			youngPeople {
				...BasicYoungPersonIdentity
			}
		}
	}
	${HouseWithUsersFragmentDoc}
	${BasicYoungPersonIdentityFragmentDoc}
`;

/**
 * __useGetTaskCreateFormDataByHouseIdQuery__
 *
 * To run a query within a React component, call `useGetTaskCreateFormDataByHouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCreateFormDataByHouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCreateFormDataByHouseIdQuery({
 *   variables: {
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useGetTaskCreateFormDataByHouseIdQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetTaskCreateFormDataByHouseIdQuery,
		Types.GetTaskCreateFormDataByHouseIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetTaskCreateFormDataByHouseIdQuery,
		Types.GetTaskCreateFormDataByHouseIdQueryVariables
	>(GetTaskCreateFormDataByHouseIdDocument, options);
}
export function useGetTaskCreateFormDataByHouseIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetTaskCreateFormDataByHouseIdQuery,
		Types.GetTaskCreateFormDataByHouseIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetTaskCreateFormDataByHouseIdQuery,
		Types.GetTaskCreateFormDataByHouseIdQueryVariables
	>(GetTaskCreateFormDataByHouseIdDocument, options);
}
export type GetTaskCreateFormDataByHouseIdQueryHookResult = ReturnType<
	typeof useGetTaskCreateFormDataByHouseIdQuery
>;
export type GetTaskCreateFormDataByHouseIdLazyQueryHookResult = ReturnType<
	typeof useGetTaskCreateFormDataByHouseIdLazyQuery
>;
export type GetTaskCreateFormDataByHouseIdQueryResult = Apollo.QueryResult<
	Types.GetTaskCreateFormDataByHouseIdQuery,
	Types.GetTaskCreateFormDataByHouseIdQueryVariables
>;
export const GetTaskCreateFormDataByYoungPersonIdDocument = gql`
	query GetTaskCreateFormDataByYoungPersonId($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			...BasicYoungPersonIdentity
			house {
				...HouseWithUsers
			}
		}
	}
	${BasicYoungPersonIdentityFragmentDoc}
	${HouseWithUsersFragmentDoc}
`;

/**
 * __useGetTaskCreateFormDataByYoungPersonIdQuery__
 *
 * To run a query within a React component, call `useGetTaskCreateFormDataByYoungPersonIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskCreateFormDataByYoungPersonIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskCreateFormDataByYoungPersonIdQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetTaskCreateFormDataByYoungPersonIdQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetTaskCreateFormDataByYoungPersonIdQuery,
		Types.GetTaskCreateFormDataByYoungPersonIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetTaskCreateFormDataByYoungPersonIdQuery,
		Types.GetTaskCreateFormDataByYoungPersonIdQueryVariables
	>(GetTaskCreateFormDataByYoungPersonIdDocument, options);
}
export function useGetTaskCreateFormDataByYoungPersonIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetTaskCreateFormDataByYoungPersonIdQuery,
		Types.GetTaskCreateFormDataByYoungPersonIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetTaskCreateFormDataByYoungPersonIdQuery,
		Types.GetTaskCreateFormDataByYoungPersonIdQueryVariables
	>(GetTaskCreateFormDataByYoungPersonIdDocument, options);
}
export type GetTaskCreateFormDataByYoungPersonIdQueryHookResult = ReturnType<
	typeof useGetTaskCreateFormDataByYoungPersonIdQuery
>;
export type GetTaskCreateFormDataByYoungPersonIdLazyQueryHookResult =
	ReturnType<typeof useGetTaskCreateFormDataByYoungPersonIdLazyQuery>;
export type GetTaskCreateFormDataByYoungPersonIdQueryResult =
	Apollo.QueryResult<
		Types.GetTaskCreateFormDataByYoungPersonIdQuery,
		Types.GetTaskCreateFormDataByYoungPersonIdQueryVariables
	>;
export const CreateNewTaskDocument = gql`
	mutation CreateNewTask($data: TaskCreateInput!) {
		createTask(data: $data) {
			...BasicTask
		}
	}
	${BasicTaskFragmentDoc}
`;
export type CreateNewTaskMutationFn = Apollo.MutationFunction<
	Types.CreateNewTaskMutation,
	Types.CreateNewTaskMutationVariables
>;

/**
 * __useCreateNewTaskMutation__
 *
 * To run a mutation, you first call `useCreateNewTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewTaskMutation, { data, loading, error }] = useCreateNewTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateNewTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateNewTaskMutation,
		Types.CreateNewTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateNewTaskMutation,
		Types.CreateNewTaskMutationVariables
	>(CreateNewTaskDocument, options);
}
export type CreateNewTaskMutationHookResult = ReturnType<
	typeof useCreateNewTaskMutation
>;
export type CreateNewTaskMutationResult =
	Apollo.MutationResult<Types.CreateNewTaskMutation>;
export type CreateNewTaskMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateNewTaskMutation,
	Types.CreateNewTaskMutationVariables
>;
export const UpdateTaskDocument = gql`
	mutation UpdateTask($data: TaskUpdateInput!, $id: String) {
		updateTask(where: { id: $id }, data: $data) {
			...BasicTask
		}
	}
	${BasicTaskFragmentDoc}
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<
	Types.UpdateTaskMutation,
	Types.UpdateTaskMutationVariables
>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateTaskMutation,
		Types.UpdateTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateTaskMutation,
		Types.UpdateTaskMutationVariables
	>(UpdateTaskDocument, options);
}
export type UpdateTaskMutationHookResult = ReturnType<
	typeof useUpdateTaskMutation
>;
export type UpdateTaskMutationResult =
	Apollo.MutationResult<Types.UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
	Types.UpdateTaskMutation,
	Types.UpdateTaskMutationVariables
>;
export const GetUserDocument = gql`
	query GetUser($id: String) {
		getUser(where: { id: $id }) {
			...UserDetails
		}
	}
	${UserDetailsFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetUserQuery,
		Types.GetUserQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(
		GetUserDocument,
		options
	);
}
export function useGetUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetUserQuery,
		Types.GetUserQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(
		GetUserDocument,
		options
	);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
	Types.GetUserQuery,
	Types.GetUserQueryVariables
>;
export const UpdateUserDocument = gql`
	mutation UpdateUser($id: String, $data: UserUpdateInput!) {
		updateUser(where: { id: $id }, data: $data) {
			...UserDetails
		}
	}
	${UserDetailsFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
	Types.UpdateUserMutation,
	Types.UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateUserMutation,
		Types.UpdateUserMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateUserMutation,
		Types.UpdateUserMutationVariables
	>(UpdateUserDocument, options);
}
export type UpdateUserMutationHookResult = ReturnType<
	typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
	Apollo.MutationResult<Types.UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
	Types.UpdateUserMutation,
	Types.UpdateUserMutationVariables
>;
export const GetYoungPersonAboutMeDetailsDocument = gql`
	query GetYoungPersonAboutMeDetails($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			...YoungPersonAboutMe
		}
	}
	${YoungPersonAboutMeFragmentDoc}
`;

/**
 * __useGetYoungPersonAboutMeDetailsQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonAboutMeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonAboutMeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonAboutMeDetailsQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonAboutMeDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPersonAboutMeDetailsQuery,
		Types.GetYoungPersonAboutMeDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPersonAboutMeDetailsQuery,
		Types.GetYoungPersonAboutMeDetailsQueryVariables
	>(GetYoungPersonAboutMeDetailsDocument, options);
}
export function useGetYoungPersonAboutMeDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPersonAboutMeDetailsQuery,
		Types.GetYoungPersonAboutMeDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPersonAboutMeDetailsQuery,
		Types.GetYoungPersonAboutMeDetailsQueryVariables
	>(GetYoungPersonAboutMeDetailsDocument, options);
}
export type GetYoungPersonAboutMeDetailsQueryHookResult = ReturnType<
	typeof useGetYoungPersonAboutMeDetailsQuery
>;
export type GetYoungPersonAboutMeDetailsLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonAboutMeDetailsLazyQuery
>;
export type GetYoungPersonAboutMeDetailsQueryResult = Apollo.QueryResult<
	Types.GetYoungPersonAboutMeDetailsQuery,
	Types.GetYoungPersonAboutMeDetailsQueryVariables
>;
export const GetYoungPersonBasicDetailsDocument = gql`
	query GetYoungPersonBasicDetails($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			...YoungPersonBasicDetails
		}
	}
	${YoungPersonBasicDetailsFragmentDoc}
`;

/**
 * __useGetYoungPersonBasicDetailsQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonBasicDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonBasicDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonBasicDetailsQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonBasicDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPersonBasicDetailsQuery,
		Types.GetYoungPersonBasicDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPersonBasicDetailsQuery,
		Types.GetYoungPersonBasicDetailsQueryVariables
	>(GetYoungPersonBasicDetailsDocument, options);
}
export function useGetYoungPersonBasicDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPersonBasicDetailsQuery,
		Types.GetYoungPersonBasicDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPersonBasicDetailsQuery,
		Types.GetYoungPersonBasicDetailsQueryVariables
	>(GetYoungPersonBasicDetailsDocument, options);
}
export type GetYoungPersonBasicDetailsQueryHookResult = ReturnType<
	typeof useGetYoungPersonBasicDetailsQuery
>;
export type GetYoungPersonBasicDetailsLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonBasicDetailsLazyQuery
>;
export type GetYoungPersonBasicDetailsQueryResult = Apollo.QueryResult<
	Types.GetYoungPersonBasicDetailsQuery,
	Types.GetYoungPersonBasicDetailsQueryVariables
>;
export const UpdateYoungPersonProfileDocument = gql`
	mutation UpdateYoungPersonProfile(
		$youngPersonId: String!
		$data: YoungPersonUpdateInput!
	) {
		updateYoungPerson(where: { id: $youngPersonId }, data: $data) {
			...YoungPersonProfile
		}
	}
	${YoungPersonProfileFragmentDoc}
`;
export type UpdateYoungPersonProfileMutationFn = Apollo.MutationFunction<
	Types.UpdateYoungPersonProfileMutation,
	Types.UpdateYoungPersonProfileMutationVariables
>;

/**
 * __useUpdateYoungPersonProfileMutation__
 *
 * To run a mutation, you first call `useUpdateYoungPersonProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoungPersonProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoungPersonProfileMutation, { data, loading, error }] = useUpdateYoungPersonProfileMutation({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateYoungPersonProfileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateYoungPersonProfileMutation,
		Types.UpdateYoungPersonProfileMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateYoungPersonProfileMutation,
		Types.UpdateYoungPersonProfileMutationVariables
	>(UpdateYoungPersonProfileDocument, options);
}
export type UpdateYoungPersonProfileMutationHookResult = ReturnType<
	typeof useUpdateYoungPersonProfileMutation
>;
export type UpdateYoungPersonProfileMutationResult =
	Apollo.MutationResult<Types.UpdateYoungPersonProfileMutation>;
export type UpdateYoungPersonProfileMutationOptions =
	Apollo.BaseMutationOptions<
		Types.UpdateYoungPersonProfileMutation,
		Types.UpdateYoungPersonProfileMutationVariables
	>;
export const ListHouseIdsDocument = gql`
	query ListHouseIds {
		listHouses(
			where: {
				preMoveInHouse: { equals: false }
				isMoveOutHouse: { not: { equals: true } }
			}
		) {
			...HouseIdDetails
		}
	}
	${HouseIdDetailsFragmentDoc}
`;

/**
 * __useListHouseIdsQuery__
 *
 * To run a query within a React component, call `useListHouseIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHouseIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHouseIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListHouseIdsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListHouseIdsQuery,
		Types.ListHouseIdsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListHouseIdsQuery,
		Types.ListHouseIdsQueryVariables
	>(ListHouseIdsDocument, options);
}
export function useListHouseIdsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListHouseIdsQuery,
		Types.ListHouseIdsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListHouseIdsQuery,
		Types.ListHouseIdsQueryVariables
	>(ListHouseIdsDocument, options);
}
export type ListHouseIdsQueryHookResult = ReturnType<
	typeof useListHouseIdsQuery
>;
export type ListHouseIdsLazyQueryHookResult = ReturnType<
	typeof useListHouseIdsLazyQuery
>;
export type ListHouseIdsQueryResult = Apollo.QueryResult<
	Types.ListHouseIdsQuery,
	Types.ListHouseIdsQueryVariables
>;
export const CreateYoungPersonProfileDocument = gql`
	mutation CreateYoungPersonProfile($data: YoungPersonCreateInput!) {
		createYoungPerson(data: $data) {
			...YoungPersonProfile
		}
	}
	${YoungPersonProfileFragmentDoc}
`;
export type CreateYoungPersonProfileMutationFn = Apollo.MutationFunction<
	Types.CreateYoungPersonProfileMutation,
	Types.CreateYoungPersonProfileMutationVariables
>;

/**
 * __useCreateYoungPersonProfileMutation__
 *
 * To run a mutation, you first call `useCreateYoungPersonProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateYoungPersonProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createYoungPersonProfileMutation, { data, loading, error }] = useCreateYoungPersonProfileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateYoungPersonProfileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateYoungPersonProfileMutation,
		Types.CreateYoungPersonProfileMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateYoungPersonProfileMutation,
		Types.CreateYoungPersonProfileMutationVariables
	>(CreateYoungPersonProfileDocument, options);
}
export type CreateYoungPersonProfileMutationHookResult = ReturnType<
	typeof useCreateYoungPersonProfileMutation
>;
export type CreateYoungPersonProfileMutationResult =
	Apollo.MutationResult<Types.CreateYoungPersonProfileMutation>;
export type CreateYoungPersonProfileMutationOptions =
	Apollo.BaseMutationOptions<
		Types.CreateYoungPersonProfileMutation,
		Types.CreateYoungPersonProfileMutationVariables
	>;
export const GetYoungPersonLegalDetailsDocument = gql`
	query GetYoungPersonLegalDetails($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			...YoungPersonLegalDetails
		}
	}
	${YoungPersonLegalDetailsFragmentDoc}
`;

/**
 * __useGetYoungPersonLegalDetailsQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonLegalDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonLegalDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonLegalDetailsQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonLegalDetailsQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPersonLegalDetailsQuery,
		Types.GetYoungPersonLegalDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPersonLegalDetailsQuery,
		Types.GetYoungPersonLegalDetailsQueryVariables
	>(GetYoungPersonLegalDetailsDocument, options);
}
export function useGetYoungPersonLegalDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPersonLegalDetailsQuery,
		Types.GetYoungPersonLegalDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPersonLegalDetailsQuery,
		Types.GetYoungPersonLegalDetailsQueryVariables
	>(GetYoungPersonLegalDetailsDocument, options);
}
export type GetYoungPersonLegalDetailsQueryHookResult = ReturnType<
	typeof useGetYoungPersonLegalDetailsQuery
>;
export type GetYoungPersonLegalDetailsLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonLegalDetailsLazyQuery
>;
export type GetYoungPersonLegalDetailsQueryResult = Apollo.QueryResult<
	Types.GetYoungPersonLegalDetailsQuery,
	Types.GetYoungPersonLegalDetailsQueryVariables
>;
export const GetMoveOutHouseDocument = gql`
	query getMoveOutHouse($ypId: String!) {
		getYoungPerson(where: { id: $ypId }) {
			house {
				moveOutHouseId
			}
		}
	}
`;

/**
 * __useGetMoveOutHouseQuery__
 *
 * To run a query within a React component, call `useGetMoveOutHouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoveOutHouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoveOutHouseQuery({
 *   variables: {
 *      ypId: // value for 'ypId'
 *   },
 * });
 */
export function useGetMoveOutHouseQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetMoveOutHouseQuery,
		Types.GetMoveOutHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetMoveOutHouseQuery,
		Types.GetMoveOutHouseQueryVariables
	>(GetMoveOutHouseDocument, options);
}
export function useGetMoveOutHouseLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetMoveOutHouseQuery,
		Types.GetMoveOutHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetMoveOutHouseQuery,
		Types.GetMoveOutHouseQueryVariables
	>(GetMoveOutHouseDocument, options);
}
export type GetMoveOutHouseQueryHookResult = ReturnType<
	typeof useGetMoveOutHouseQuery
>;
export type GetMoveOutHouseLazyQueryHookResult = ReturnType<
	typeof useGetMoveOutHouseLazyQuery
>;
export type GetMoveOutHouseQueryResult = Apollo.QueryResult<
	Types.GetMoveOutHouseQuery,
	Types.GetMoveOutHouseQueryVariables
>;
export const UpdateYoungPersonMoveOutDocument = gql`
	mutation updateYoungPersonMoveOut(
		$ypId: String!
		$date: AWSDateTime!
		$note: String!
		$houseId: String!
	) {
		updateYoungPerson(
			where: { id: $ypId }
			data: {
				moveOutDate: $date
				moveOutNote: $note
				status: MOVED_OUT
				houseId: $houseId
			}
		) {
			id
			moveOutNote
			moveOutDate
			houseId
		}
		updateRoom(where: { youngPersonId: $ypId }, data: { youngPersonId: null }) {
			id
			youngPerson {
				id
			}
		}
	}
`;
export type UpdateYoungPersonMoveOutMutationFn = Apollo.MutationFunction<
	Types.UpdateYoungPersonMoveOutMutation,
	Types.UpdateYoungPersonMoveOutMutationVariables
>;

/**
 * __useUpdateYoungPersonMoveOutMutation__
 *
 * To run a mutation, you first call `useUpdateYoungPersonMoveOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoungPersonMoveOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoungPersonMoveOutMutation, { data, loading, error }] = useUpdateYoungPersonMoveOutMutation({
 *   variables: {
 *      ypId: // value for 'ypId'
 *      date: // value for 'date'
 *      note: // value for 'note'
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useUpdateYoungPersonMoveOutMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateYoungPersonMoveOutMutation,
		Types.UpdateYoungPersonMoveOutMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateYoungPersonMoveOutMutation,
		Types.UpdateYoungPersonMoveOutMutationVariables
	>(UpdateYoungPersonMoveOutDocument, options);
}
export type UpdateYoungPersonMoveOutMutationHookResult = ReturnType<
	typeof useUpdateYoungPersonMoveOutMutation
>;
export type UpdateYoungPersonMoveOutMutationResult =
	Apollo.MutationResult<Types.UpdateYoungPersonMoveOutMutation>;
export type UpdateYoungPersonMoveOutMutationOptions =
	Apollo.BaseMutationOptions<
		Types.UpdateYoungPersonMoveOutMutation,
		Types.UpdateYoungPersonMoveOutMutationVariables
	>;
export const ListEmptyRoomsDocument = gql`
	query ListEmptyRooms($houseId: String!) {
		listRooms(
			where: {
				AND: [
					{ house: { id: { equals: $houseId } } }
					{ youngPersonId: { equals: null } }
				]
			}
		) {
			id
			name
		}
	}
`;

/**
 * __useListEmptyRoomsQuery__
 *
 * To run a query within a React component, call `useListEmptyRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmptyRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmptyRoomsQuery({
 *   variables: {
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useListEmptyRoomsQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.ListEmptyRoomsQuery,
		Types.ListEmptyRoomsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListEmptyRoomsQuery,
		Types.ListEmptyRoomsQueryVariables
	>(ListEmptyRoomsDocument, options);
}
export function useListEmptyRoomsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListEmptyRoomsQuery,
		Types.ListEmptyRoomsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListEmptyRoomsQuery,
		Types.ListEmptyRoomsQueryVariables
	>(ListEmptyRoomsDocument, options);
}
export type ListEmptyRoomsQueryHookResult = ReturnType<
	typeof useListEmptyRoomsQuery
>;
export type ListEmptyRoomsLazyQueryHookResult = ReturnType<
	typeof useListEmptyRoomsLazyQuery
>;
export type ListEmptyRoomsQueryResult = Apollo.QueryResult<
	Types.ListEmptyRoomsQuery,
	Types.ListEmptyRoomsQueryVariables
>;
export const UpdateYoungPersonRoomDocument = gql`
	mutation updateYoungPersonRoom($roomId: String!, $data: RoomUpdateInput!) {
		updateRoom(where: { id: $roomId }, data: $data) {
			id
			youngPerson {
				id
			}
		}
	}
`;
export type UpdateYoungPersonRoomMutationFn = Apollo.MutationFunction<
	Types.UpdateYoungPersonRoomMutation,
	Types.UpdateYoungPersonRoomMutationVariables
>;

/**
 * __useUpdateYoungPersonRoomMutation__
 *
 * To run a mutation, you first call `useUpdateYoungPersonRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoungPersonRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoungPersonRoomMutation, { data, loading, error }] = useUpdateYoungPersonRoomMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateYoungPersonRoomMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateYoungPersonRoomMutation,
		Types.UpdateYoungPersonRoomMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateYoungPersonRoomMutation,
		Types.UpdateYoungPersonRoomMutationVariables
	>(UpdateYoungPersonRoomDocument, options);
}
export type UpdateYoungPersonRoomMutationHookResult = ReturnType<
	typeof useUpdateYoungPersonRoomMutation
>;
export type UpdateYoungPersonRoomMutationResult =
	Apollo.MutationResult<Types.UpdateYoungPersonRoomMutation>;
export type UpdateYoungPersonRoomMutationOptions = Apollo.BaseMutationOptions<
	Types.UpdateYoungPersonRoomMutation,
	Types.UpdateYoungPersonRoomMutationVariables
>;
export const UpdateYoungPersonHouseDocument = gql`
	mutation updateYoungPersonHouse(
		$youngPersonId: String!
		$data: YoungPersonUpdateInput!
	) {
		updateYoungPerson(where: { id: $youngPersonId }, data: $data) {
			id
		}
	}
`;
export type UpdateYoungPersonHouseMutationFn = Apollo.MutationFunction<
	Types.UpdateYoungPersonHouseMutation,
	Types.UpdateYoungPersonHouseMutationVariables
>;

/**
 * __useUpdateYoungPersonHouseMutation__
 *
 * To run a mutation, you first call `useUpdateYoungPersonHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoungPersonHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoungPersonHouseMutation, { data, loading, error }] = useUpdateYoungPersonHouseMutation({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateYoungPersonHouseMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateYoungPersonHouseMutation,
		Types.UpdateYoungPersonHouseMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateYoungPersonHouseMutation,
		Types.UpdateYoungPersonHouseMutationVariables
	>(UpdateYoungPersonHouseDocument, options);
}
export type UpdateYoungPersonHouseMutationHookResult = ReturnType<
	typeof useUpdateYoungPersonHouseMutation
>;
export type UpdateYoungPersonHouseMutationResult =
	Apollo.MutationResult<Types.UpdateYoungPersonHouseMutation>;
export type UpdateYoungPersonHouseMutationOptions = Apollo.BaseMutationOptions<
	Types.UpdateYoungPersonHouseMutation,
	Types.UpdateYoungPersonHouseMutationVariables
>;
export const YoungPersonRiskAssessmentDocument = gql`
	query YoungPersonRiskAssessment($id: String) {
		getYoungPerson(where: { id: $id }) {
			riskAssessment
		}
	}
`;

/**
 * __useYoungPersonRiskAssessmentQuery__
 *
 * To run a query within a React component, call `useYoungPersonRiskAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useYoungPersonRiskAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYoungPersonRiskAssessmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useYoungPersonRiskAssessmentQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.YoungPersonRiskAssessmentQuery,
		Types.YoungPersonRiskAssessmentQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.YoungPersonRiskAssessmentQuery,
		Types.YoungPersonRiskAssessmentQueryVariables
	>(YoungPersonRiskAssessmentDocument, options);
}
export function useYoungPersonRiskAssessmentLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.YoungPersonRiskAssessmentQuery,
		Types.YoungPersonRiskAssessmentQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.YoungPersonRiskAssessmentQuery,
		Types.YoungPersonRiskAssessmentQueryVariables
	>(YoungPersonRiskAssessmentDocument, options);
}
export type YoungPersonRiskAssessmentQueryHookResult = ReturnType<
	typeof useYoungPersonRiskAssessmentQuery
>;
export type YoungPersonRiskAssessmentLazyQueryHookResult = ReturnType<
	typeof useYoungPersonRiskAssessmentLazyQuery
>;
export type YoungPersonRiskAssessmentQueryResult = Apollo.QueryResult<
	Types.YoungPersonRiskAssessmentQuery,
	Types.YoungPersonRiskAssessmentQueryVariables
>;
export const UpdateYoungPersonRiskAssessmentDocument = gql`
	mutation UpdateYoungPersonRiskAssessment(
		$youngPersonId: String!
		$riskAssessment: AWSJSON!
		$createdBy: String!
	) {
		updateYoungPerson(
			where: { id: $youngPersonId }
			data: { riskAssessment: $riskAssessment }
		) {
			id
			riskAssessment
		}
		createLog(
			data: {
				createdBy: $createdBy
				type: RISK_ASSESSMENT
				data: $riskAssessment
				youngPersonId: $youngPersonId
			}
		) {
			id
			data
			createdBy
		}
	}
`;
export type UpdateYoungPersonRiskAssessmentMutationFn = Apollo.MutationFunction<
	Types.UpdateYoungPersonRiskAssessmentMutation,
	Types.UpdateYoungPersonRiskAssessmentMutationVariables
>;

/**
 * __useUpdateYoungPersonRiskAssessmentMutation__
 *
 * To run a mutation, you first call `useUpdateYoungPersonRiskAssessmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoungPersonRiskAssessmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoungPersonRiskAssessmentMutation, { data, loading, error }] = useUpdateYoungPersonRiskAssessmentMutation({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      riskAssessment: // value for 'riskAssessment'
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useUpdateYoungPersonRiskAssessmentMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateYoungPersonRiskAssessmentMutation,
		Types.UpdateYoungPersonRiskAssessmentMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateYoungPersonRiskAssessmentMutation,
		Types.UpdateYoungPersonRiskAssessmentMutationVariables
	>(UpdateYoungPersonRiskAssessmentDocument, options);
}
export type UpdateYoungPersonRiskAssessmentMutationHookResult = ReturnType<
	typeof useUpdateYoungPersonRiskAssessmentMutation
>;
export type UpdateYoungPersonRiskAssessmentMutationResult =
	Apollo.MutationResult<Types.UpdateYoungPersonRiskAssessmentMutation>;
export type UpdateYoungPersonRiskAssessmentMutationOptions =
	Apollo.BaseMutationOptions<
		Types.UpdateYoungPersonRiskAssessmentMutation,
		Types.UpdateYoungPersonRiskAssessmentMutationVariables
	>;
export const CreateFileUrlDocument = gql`
	mutation CreateFileURL(
		$fileType: FileType!
		$houseId: String!
		$youngPersonId: String
		$fileName: String
		$folderName: String
	) {
		createFileUploadUrl(
			input: {
				fileType: $fileType
				houseId: $houseId
				youngPersonId: $youngPersonId
				fileName: $fileName
				folderName: $folderName
			}
		) {
			url
			statusCode
		}
	}
`;
export type CreateFileUrlMutationFn = Apollo.MutationFunction<
	Types.CreateFileUrlMutation,
	Types.CreateFileUrlMutationVariables
>;

/**
 * __useCreateFileUrlMutation__
 *
 * To run a mutation, you first call `useCreateFileUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileUrlMutation, { data, loading, error }] = useCreateFileUrlMutation({
 *   variables: {
 *      fileType: // value for 'fileType'
 *      houseId: // value for 'houseId'
 *      youngPersonId: // value for 'youngPersonId'
 *      fileName: // value for 'fileName'
 *      folderName: // value for 'folderName'
 *   },
 * });
 */
export function useCreateFileUrlMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateFileUrlMutation,
		Types.CreateFileUrlMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateFileUrlMutation,
		Types.CreateFileUrlMutationVariables
	>(CreateFileUrlDocument, options);
}
export type CreateFileUrlMutationHookResult = ReturnType<
	typeof useCreateFileUrlMutation
>;
export type CreateFileUrlMutationResult =
	Apollo.MutationResult<Types.CreateFileUrlMutation>;
export type CreateFileUrlMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateFileUrlMutation,
	Types.CreateFileUrlMutationVariables
>;
export const IsLoggedInDocument = gql`
	query isLoggedIn {
		isLoggedIn @client
	}
`;

/**
 * __useIsLoggedInQuery__
 *
 * To run a query within a React component, call `useIsLoggedInQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsLoggedInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsLoggedInQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsLoggedInQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.IsLoggedInQuery,
		Types.IsLoggedInQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.IsLoggedInQuery, Types.IsLoggedInQueryVariables>(
		IsLoggedInDocument,
		options
	);
}
export function useIsLoggedInLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.IsLoggedInQuery,
		Types.IsLoggedInQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.IsLoggedInQuery,
		Types.IsLoggedInQueryVariables
	>(IsLoggedInDocument, options);
}
export type IsLoggedInQueryHookResult = ReturnType<typeof useIsLoggedInQuery>;
export type IsLoggedInLazyQueryHookResult = ReturnType<
	typeof useIsLoggedInLazyQuery
>;
export type IsLoggedInQueryResult = Apollo.QueryResult<
	Types.IsLoggedInQuery,
	Types.IsLoggedInQueryVariables
>;
export const GetNavDataDocument = gql`
	query GetNavData($userId: String!) {
		getUser(where: { sub: $userId }) {
			id
			firstName
			lastName
			role
		}
	}
`;

/**
 * __useGetNavDataQuery__
 *
 * To run a query within a React component, call `useGetNavDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNavDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNavDataQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNavDataQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetNavDataQuery,
		Types.GetNavDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.GetNavDataQuery, Types.GetNavDataQueryVariables>(
		GetNavDataDocument,
		options
	);
}
export function useGetNavDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetNavDataQuery,
		Types.GetNavDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetNavDataQuery,
		Types.GetNavDataQueryVariables
	>(GetNavDataDocument, options);
}
export type GetNavDataQueryHookResult = ReturnType<typeof useGetNavDataQuery>;
export type GetNavDataLazyQueryHookResult = ReturnType<
	typeof useGetNavDataLazyQuery
>;
export type GetNavDataQueryResult = Apollo.QueryResult<
	Types.GetNavDataQuery,
	Types.GetNavDataQueryVariables
>;
export const ListHousesWithYoungPeopleDocument = gql`
	query ListHousesWithYoungPeople($term: String, $skip: Int, $take: Int!) {
		listHouses(
			where: {
				AND: [
					{ title: { contains: $term } }
					{ preMoveInHouse: { equals: false } }
				]
			}
			skip: $skip
			take: $take
		) {
			...HouseCard
		}
		countHouses(
			where: {
				AND: [
					{ title: { contains: $term } }
					{ preMoveInHouse: { equals: false } }
				]
			}
		)
	}
	${HouseCardFragmentDoc}
`;

/**
 * __useListHousesWithYoungPeopleQuery__
 *
 * To run a query within a React component, call `useListHousesWithYoungPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHousesWithYoungPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHousesWithYoungPeopleQuery({
 *   variables: {
 *      term: // value for 'term'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListHousesWithYoungPeopleQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.ListHousesWithYoungPeopleQuery,
		Types.ListHousesWithYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListHousesWithYoungPeopleQuery,
		Types.ListHousesWithYoungPeopleQueryVariables
	>(ListHousesWithYoungPeopleDocument, options);
}
export function useListHousesWithYoungPeopleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListHousesWithYoungPeopleQuery,
		Types.ListHousesWithYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListHousesWithYoungPeopleQuery,
		Types.ListHousesWithYoungPeopleQueryVariables
	>(ListHousesWithYoungPeopleDocument, options);
}
export type ListHousesWithYoungPeopleQueryHookResult = ReturnType<
	typeof useListHousesWithYoungPeopleQuery
>;
export type ListHousesWithYoungPeopleLazyQueryHookResult = ReturnType<
	typeof useListHousesWithYoungPeopleLazyQuery
>;
export type ListHousesWithYoungPeopleQueryResult = Apollo.QueryResult<
	Types.ListHousesWithYoungPeopleQuery,
	Types.ListHousesWithYoungPeopleQueryVariables
>;
export const ListUsersWithHouseDocument = gql`
	query ListUsersWithHouse(
		$where: UserWhereFilterInput
		$take: Int
		$skip: Int
	) {
		listUsers(where: $where, take: $take, skip: $skip) {
			...UserWithHouse
		}
		countUsers(where: $where)
	}
	${UserWithHouseFragmentDoc}
`;

/**
 * __useListUsersWithHouseQuery__
 *
 * To run a query within a React component, call `useListUsersWithHouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersWithHouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersWithHouseQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useListUsersWithHouseQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListUsersWithHouseQuery,
		Types.ListUsersWithHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListUsersWithHouseQuery,
		Types.ListUsersWithHouseQueryVariables
	>(ListUsersWithHouseDocument, options);
}
export function useListUsersWithHouseLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListUsersWithHouseQuery,
		Types.ListUsersWithHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListUsersWithHouseQuery,
		Types.ListUsersWithHouseQueryVariables
	>(ListUsersWithHouseDocument, options);
}
export type ListUsersWithHouseQueryHookResult = ReturnType<
	typeof useListUsersWithHouseQuery
>;
export type ListUsersWithHouseLazyQueryHookResult = ReturnType<
	typeof useListUsersWithHouseLazyQuery
>;
export type ListUsersWithHouseQueryResult = Apollo.QueryResult<
	Types.ListUsersWithHouseQuery,
	Types.ListUsersWithHouseQueryVariables
>;
export const OnTaskCreatedDocument = gql`
	subscription onTaskCreated($youngPersonId: String!) {
		onMutatedTask(youngPersonId: $youngPersonId) {
			id
			title
			isAdmin
			isPrivate
		}
	}
`;

/**
 * __useOnTaskCreatedSubscription__
 *
 * To run a query within a React component, call `useOnTaskCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnTaskCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnTaskCreatedSubscription({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useOnTaskCreatedSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		Types.OnTaskCreatedSubscription,
		Types.OnTaskCreatedSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		Types.OnTaskCreatedSubscription,
		Types.OnTaskCreatedSubscriptionVariables
	>(OnTaskCreatedDocument, options);
}
export type OnTaskCreatedSubscriptionHookResult = ReturnType<
	typeof useOnTaskCreatedSubscription
>;
export type OnTaskCreatedSubscriptionResult =
	Apollo.SubscriptionResult<Types.OnTaskCreatedSubscription>;
export const UpdateLocationStatusDocument = gql`
	mutation UpdateLocationStatus(
		$id: String!
		$data: YoungPersonLocationStatusUpdateInput!
	) {
		updateYoungPersonLocationStatus(where: { id: $id }, data: $data) {
			id
			title
			priority
			type
			updatedAt
		}
	}
`;
export type UpdateLocationStatusMutationFn = Apollo.MutationFunction<
	Types.UpdateLocationStatusMutation,
	Types.UpdateLocationStatusMutationVariables
>;

/**
 * __useUpdateLocationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateLocationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationStatusMutation, { data, loading, error }] = useUpdateLocationStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateLocationStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateLocationStatusMutation,
		Types.UpdateLocationStatusMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateLocationStatusMutation,
		Types.UpdateLocationStatusMutationVariables
	>(UpdateLocationStatusDocument, options);
}
export type UpdateLocationStatusMutationHookResult = ReturnType<
	typeof useUpdateLocationStatusMutation
>;
export type UpdateLocationStatusMutationResult =
	Apollo.MutationResult<Types.UpdateLocationStatusMutation>;
export type UpdateLocationStatusMutationOptions = Apollo.BaseMutationOptions<
	Types.UpdateLocationStatusMutation,
	Types.UpdateLocationStatusMutationVariables
>;
export const GetHouseDocument = gql`
	query GetHouse($houseId: String!) {
		getHouse(where: { id: $houseId }) {
			id
			title
			address
			color
			isMoveOutHouse
		}
	}
`;

/**
 * __useGetHouseQuery__
 *
 * To run a query within a React component, call `useGetHouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseQuery({
 *   variables: {
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useGetHouseQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetHouseQuery,
		Types.GetHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.GetHouseQuery, Types.GetHouseQueryVariables>(
		GetHouseDocument,
		options
	);
}
export function useGetHouseLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetHouseQuery,
		Types.GetHouseQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<Types.GetHouseQuery, Types.GetHouseQueryVariables>(
		GetHouseDocument,
		options
	);
}
export type GetHouseQueryHookResult = ReturnType<typeof useGetHouseQuery>;
export type GetHouseLazyQueryHookResult = ReturnType<
	typeof useGetHouseLazyQuery
>;
export type GetHouseQueryResult = Apollo.QueryResult<
	Types.GetHouseQuery,
	Types.GetHouseQueryVariables
>;
export const GetYoungPersonByIdDocument = gql`
	query GetYoungPersonById($id: String!) {
		listYoungPersonLocationStatuses {
			id
			title
			type
			priority
		}
		getYoungPerson(where: { id: $id }) {
			...YoungPersonScreenData
		}
	}
	${YoungPersonScreenDataFragmentDoc}
`;

/**
 * __useGetYoungPersonByIdQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetYoungPersonByIdQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPersonByIdQuery,
		Types.GetYoungPersonByIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPersonByIdQuery,
		Types.GetYoungPersonByIdQueryVariables
	>(GetYoungPersonByIdDocument, options);
}
export function useGetYoungPersonByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPersonByIdQuery,
		Types.GetYoungPersonByIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPersonByIdQuery,
		Types.GetYoungPersonByIdQueryVariables
	>(GetYoungPersonByIdDocument, options);
}
export type GetYoungPersonByIdQueryHookResult = ReturnType<
	typeof useGetYoungPersonByIdQuery
>;
export type GetYoungPersonByIdLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonByIdLazyQuery
>;
export type GetYoungPersonByIdQueryResult = Apollo.QueryResult<
	Types.GetYoungPersonByIdQuery,
	Types.GetYoungPersonByIdQueryVariables
>;
export const GetYoungPersonByIdSubDocument = gql`
	subscription GetYoungPersonByIdSub($id: String!) {
		onMutatedYoungPerson(id: $id) {
			...YoungPersonScreenData
		}
	}
	${YoungPersonScreenDataFragmentDoc}
`;

/**
 * __useGetYoungPersonByIdSubSubscription__
 *
 * To run a query within a React component, call `useGetYoungPersonByIdSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonByIdSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonByIdSubSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetYoungPersonByIdSubSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		Types.GetYoungPersonByIdSubSubscription,
		Types.GetYoungPersonByIdSubSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		Types.GetYoungPersonByIdSubSubscription,
		Types.GetYoungPersonByIdSubSubscriptionVariables
	>(GetYoungPersonByIdSubDocument, options);
}
export type GetYoungPersonByIdSubSubscriptionHookResult = ReturnType<
	typeof useGetYoungPersonByIdSubSubscription
>;
export type GetYoungPersonByIdSubSubscriptionResult =
	Apollo.SubscriptionResult<Types.GetYoungPersonByIdSubSubscription>;
export const ListLogsDocument = gql`
	query ListLogs($skip: Int, $take: Int!, $where: LogWhereFilterInput) {
		listLogs(
			skip: $skip
			take: $take
			where: $where
			orderBy: [{ createdAt: DESC }]
		) {
			...LogFragment
		}
		countLogs(where: $where)
	}
	${LogFragmentFragmentDoc}
`;

/**
 * __useListLogsQuery__
 *
 * To run a query within a React component, call `useListLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLogsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListLogsQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.ListLogsQuery,
		Types.ListLogsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.ListLogsQuery, Types.ListLogsQueryVariables>(
		ListLogsDocument,
		options
	);
}
export function useListLogsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListLogsQuery,
		Types.ListLogsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<Types.ListLogsQuery, Types.ListLogsQueryVariables>(
		ListLogsDocument,
		options
	);
}
export type ListLogsQueryHookResult = ReturnType<typeof useListLogsQuery>;
export type ListLogsLazyQueryHookResult = ReturnType<
	typeof useListLogsLazyQuery
>;
export type ListLogsQueryResult = Apollo.QueryResult<
	Types.ListLogsQuery,
	Types.ListLogsQueryVariables
>;
export const ListAlertsDocument = gql`
	query ListAlerts(
		$where: NotificationWhereFilterInput!
		$take: Int
		$skip: Int
		$orderBy: [NotificationOrderByInput]
	) {
		listNotifications(
			where: $where
			skip: $skip
			take: $take
			orderBy: $orderBy
		) {
			id
			title
			severity
			houseId
			createdAt
			readAt
			youngPerson {
				id
				name
			}
		}
		countNotifications(where: $where)
	}
`;

/**
 * __useListAlertsQuery__
 *
 * To run a query within a React component, call `useListAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAlertsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListAlertsQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.ListAlertsQuery,
		Types.ListAlertsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.ListAlertsQuery, Types.ListAlertsQueryVariables>(
		ListAlertsDocument,
		options
	);
}
export function useListAlertsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListAlertsQuery,
		Types.ListAlertsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListAlertsQuery,
		Types.ListAlertsQueryVariables
	>(ListAlertsDocument, options);
}
export type ListAlertsQueryHookResult = ReturnType<typeof useListAlertsQuery>;
export type ListAlertsLazyQueryHookResult = ReturnType<
	typeof useListAlertsLazyQuery
>;
export type ListAlertsQueryResult = Apollo.QueryResult<
	Types.ListAlertsQuery,
	Types.ListAlertsQueryVariables
>;
export const DismissAlertDocument = gql`
	mutation DismissAlert($id: String, $date: AWSDateTime) {
		updateNotification(where: { id: $id }, data: { readAt: $date }) {
			id
			title
			severity
			houseId
			createdAt
			readAt
			youngPerson {
				id
				name
			}
		}
	}
`;
export type DismissAlertMutationFn = Apollo.MutationFunction<
	Types.DismissAlertMutation,
	Types.DismissAlertMutationVariables
>;

/**
 * __useDismissAlertMutation__
 *
 * To run a mutation, you first call `useDismissAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissAlertMutation, { data, loading, error }] = useDismissAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDismissAlertMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.DismissAlertMutation,
		Types.DismissAlertMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.DismissAlertMutation,
		Types.DismissAlertMutationVariables
	>(DismissAlertDocument, options);
}
export type DismissAlertMutationHookResult = ReturnType<
	typeof useDismissAlertMutation
>;
export type DismissAlertMutationResult =
	Apollo.MutationResult<Types.DismissAlertMutation>;
export type DismissAlertMutationOptions = Apollo.BaseMutationOptions<
	Types.DismissAlertMutation,
	Types.DismissAlertMutationVariables
>;
export const GetYoungPersonForCoachingDocument = gql`
	query GetYoungPersonForCoaching($id: String!) {
		getYoungPerson(where: { id: $id }) {
			id
			name
			avatar
			house {
				id
				title
			}
		}
	}
`;

/**
 * __useGetYoungPersonForCoachingQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonForCoachingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonForCoachingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonForCoachingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetYoungPersonForCoachingQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPersonForCoachingQuery,
		Types.GetYoungPersonForCoachingQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPersonForCoachingQuery,
		Types.GetYoungPersonForCoachingQueryVariables
	>(GetYoungPersonForCoachingDocument, options);
}
export function useGetYoungPersonForCoachingLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPersonForCoachingQuery,
		Types.GetYoungPersonForCoachingQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPersonForCoachingQuery,
		Types.GetYoungPersonForCoachingQueryVariables
	>(GetYoungPersonForCoachingDocument, options);
}
export type GetYoungPersonForCoachingQueryHookResult = ReturnType<
	typeof useGetYoungPersonForCoachingQuery
>;
export type GetYoungPersonForCoachingLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonForCoachingLazyQuery
>;
export type GetYoungPersonForCoachingQueryResult = Apollo.QueryResult<
	Types.GetYoungPersonForCoachingQuery,
	Types.GetYoungPersonForCoachingQueryVariables
>;
export const CreateCoachingSessionsDocument = gql`
	mutation CreateCoachingSessions($data: [LogCreateManyInput!]) {
		createManyLogs(data: $data) {
			count
		}
	}
`;
export type CreateCoachingSessionsMutationFn = Apollo.MutationFunction<
	Types.CreateCoachingSessionsMutation,
	Types.CreateCoachingSessionsMutationVariables
>;

/**
 * __useCreateCoachingSessionsMutation__
 *
 * To run a mutation, you first call `useCreateCoachingSessionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCoachingSessionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCoachingSessionsMutation, { data, loading, error }] = useCreateCoachingSessionsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCoachingSessionsMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateCoachingSessionsMutation,
		Types.CreateCoachingSessionsMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateCoachingSessionsMutation,
		Types.CreateCoachingSessionsMutationVariables
	>(CreateCoachingSessionsDocument, options);
}
export type CreateCoachingSessionsMutationHookResult = ReturnType<
	typeof useCreateCoachingSessionsMutation
>;
export type CreateCoachingSessionsMutationResult =
	Apollo.MutationResult<Types.CreateCoachingSessionsMutation>;
export type CreateCoachingSessionsMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateCoachingSessionsMutation,
	Types.CreateCoachingSessionsMutationVariables
>;
export const CreateSpinSessionDocument = gql`
	mutation CreateSpinSession($data: LogCreateInput!) {
		createLog(data: $data) {
			id
		}
	}
`;
export type CreateSpinSessionMutationFn = Apollo.MutationFunction<
	Types.CreateSpinSessionMutation,
	Types.CreateSpinSessionMutationVariables
>;

/**
 * __useCreateSpinSessionMutation__
 *
 * To run a mutation, you first call `useCreateSpinSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpinSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpinSessionMutation, { data, loading, error }] = useCreateSpinSessionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSpinSessionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CreateSpinSessionMutation,
		Types.CreateSpinSessionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CreateSpinSessionMutation,
		Types.CreateSpinSessionMutationVariables
	>(CreateSpinSessionDocument, options);
}
export type CreateSpinSessionMutationHookResult = ReturnType<
	typeof useCreateSpinSessionMutation
>;
export type CreateSpinSessionMutationResult =
	Apollo.MutationResult<Types.CreateSpinSessionMutation>;
export type CreateSpinSessionMutationOptions = Apollo.BaseMutationOptions<
	Types.CreateSpinSessionMutation,
	Types.CreateSpinSessionMutationVariables
>;
export const ExportTrmDataDocument = gql`
	query ExportTrmData(
		$id: String
		$startDate: AWSDateTime
		$endDate: AWSDateTime
	) {
		listLogs(
			where: {
				AND: [
					{ youngPerson: { id: { equals: $id } } }
					{ type: { equals: SPIN_SESSION } }
					{ createdAt: { gte: $startDate } }
					{ createdAt: { lte: $endDate } }
				]
			}
		) {
			id
			data
			type
			createdByUser {
				name
			}
		}
	}
`;

/**
 * __useExportTrmDataQuery__
 *
 * To run a query within a React component, call `useExportTrmDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportTrmDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportTrmDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportTrmDataQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ExportTrmDataQuery,
		Types.ExportTrmDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ExportTrmDataQuery,
		Types.ExportTrmDataQueryVariables
	>(ExportTrmDataDocument, options);
}
export function useExportTrmDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ExportTrmDataQuery,
		Types.ExportTrmDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ExportTrmDataQuery,
		Types.ExportTrmDataQueryVariables
	>(ExportTrmDataDocument, options);
}
export type ExportTrmDataQueryHookResult = ReturnType<
	typeof useExportTrmDataQuery
>;
export type ExportTrmDataLazyQueryHookResult = ReturnType<
	typeof useExportTrmDataLazyQuery
>;
export type ExportTrmDataQueryResult = Apollo.QueryResult<
	Types.ExportTrmDataQuery,
	Types.ExportTrmDataQueryVariables
>;
export const ExportLogDataDocument = gql`
	query ExportLogData(
		$id: String
		$startDate: AWSDateTime
		$endDate: AWSDateTime
	) {
		listLogs(
			where: {
				AND: [
					{ youngPerson: { id: { equals: $id } } }
					{ NOT: { type: { equals: SPIN_SESSION } } }
					{ createdAt: { gte: $startDate } }
					{ createdAt: { lte: $endDate } }
				]
			}
		) {
			id
			data
			type
			createdByUser {
				name
			}
		}
	}
`;

/**
 * __useExportLogDataQuery__
 *
 * To run a query within a React component, call `useExportLogDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportLogDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportLogDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportLogDataQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ExportLogDataQuery,
		Types.ExportLogDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ExportLogDataQuery,
		Types.ExportLogDataQueryVariables
	>(ExportLogDataDocument, options);
}
export function useExportLogDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ExportLogDataQuery,
		Types.ExportLogDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ExportLogDataQuery,
		Types.ExportLogDataQueryVariables
	>(ExportLogDataDocument, options);
}
export type ExportLogDataQueryHookResult = ReturnType<
	typeof useExportLogDataQuery
>;
export type ExportLogDataLazyQueryHookResult = ReturnType<
	typeof useExportLogDataLazyQuery
>;
export type ExportLogDataQueryResult = Apollo.QueryResult<
	Types.ExportLogDataQuery,
	Types.ExportLogDataQueryVariables
>;
export const GetYoungPersonFilesDocument = gql`
	query GetYoungPersonFiles(
		$youngPersonId: String!
		$houseId: String
		$folderKey: String
	) {
		listYoungPersonFiles(
			input: {
				youngPersonId: $youngPersonId
				houseId: $houseId
				folderKey: $folderKey
			}
		) {
			folders
			files {
				...File
			}
		}
	}
	${FileFragmentDoc}
`;

/**
 * __useGetYoungPersonFilesQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonFilesQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      houseId: // value for 'houseId'
 *      folderKey: // value for 'folderKey'
 *   },
 * });
 */
export function useGetYoungPersonFilesQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPersonFilesQuery,
		Types.GetYoungPersonFilesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPersonFilesQuery,
		Types.GetYoungPersonFilesQueryVariables
	>(GetYoungPersonFilesDocument, options);
}
export function useGetYoungPersonFilesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPersonFilesQuery,
		Types.GetYoungPersonFilesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPersonFilesQuery,
		Types.GetYoungPersonFilesQueryVariables
	>(GetYoungPersonFilesDocument, options);
}
export type GetYoungPersonFilesQueryHookResult = ReturnType<
	typeof useGetYoungPersonFilesQuery
>;
export type GetYoungPersonFilesLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonFilesLazyQuery
>;
export type GetYoungPersonFilesQueryResult = Apollo.QueryResult<
	Types.GetYoungPersonFilesQuery,
	Types.GetYoungPersonFilesQueryVariables
>;
export const GetHouseFilesDocument = gql`
	query GetHouseFiles($houseId: String!, $folderKey: String) {
		listHouseFiles(input: { houseId: $houseId, folderKey: $folderKey }) {
			folders
			files {
				...File
			}
		}
	}
	${FileFragmentDoc}
`;

/**
 * __useGetHouseFilesQuery__
 *
 * To run a query within a React component, call `useGetHouseFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHouseFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHouseFilesQuery({
 *   variables: {
 *      houseId: // value for 'houseId'
 *      folderKey: // value for 'folderKey'
 *   },
 * });
 */
export function useGetHouseFilesQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetHouseFilesQuery,
		Types.GetHouseFilesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetHouseFilesQuery,
		Types.GetHouseFilesQueryVariables
	>(GetHouseFilesDocument, options);
}
export function useGetHouseFilesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetHouseFilesQuery,
		Types.GetHouseFilesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetHouseFilesQuery,
		Types.GetHouseFilesQueryVariables
	>(GetHouseFilesDocument, options);
}
export type GetHouseFilesQueryHookResult = ReturnType<
	typeof useGetHouseFilesQuery
>;
export type GetHouseFilesLazyQueryHookResult = ReturnType<
	typeof useGetHouseFilesLazyQuery
>;
export type GetHouseFilesQueryResult = Apollo.QueryResult<
	Types.GetHouseFilesQuery,
	Types.GetHouseFilesQueryVariables
>;
export const HouseLocationDetailsDocument = gql`
	query HouseLocationDetails($id: String) {
		getHouse(where: { id: $id }) {
			id
			lat
			long
			address
			phone
		}
	}
`;

/**
 * __useHouseLocationDetailsQuery__
 *
 * To run a query within a React component, call `useHouseLocationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHouseLocationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHouseLocationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHouseLocationDetailsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.HouseLocationDetailsQuery,
		Types.HouseLocationDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.HouseLocationDetailsQuery,
		Types.HouseLocationDetailsQueryVariables
	>(HouseLocationDetailsDocument, options);
}
export function useHouseLocationDetailsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.HouseLocationDetailsQuery,
		Types.HouseLocationDetailsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.HouseLocationDetailsQuery,
		Types.HouseLocationDetailsQueryVariables
	>(HouseLocationDetailsDocument, options);
}
export type HouseLocationDetailsQueryHookResult = ReturnType<
	typeof useHouseLocationDetailsQuery
>;
export type HouseLocationDetailsLazyQueryHookResult = ReturnType<
	typeof useHouseLocationDetailsLazyQuery
>;
export type HouseLocationDetailsQueryResult = Apollo.QueryResult<
	Types.HouseLocationDetailsQuery,
	Types.HouseLocationDetailsQueryVariables
>;
export const ListHousesDocument = gql`
	query ListHouses($where: HouseWhereFilterInput) {
		listHouses(orderBy: { title: ASC }, where: $where) {
			id
			title
		}
	}
`;

/**
 * __useListHousesQuery__
 *
 * To run a query within a React component, call `useListHousesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHousesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHousesQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListHousesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListHousesQuery,
		Types.ListHousesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.ListHousesQuery, Types.ListHousesQueryVariables>(
		ListHousesDocument,
		options
	);
}
export function useListHousesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListHousesQuery,
		Types.ListHousesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListHousesQuery,
		Types.ListHousesQueryVariables
	>(ListHousesDocument, options);
}
export type ListHousesQueryHookResult = ReturnType<typeof useListHousesQuery>;
export type ListHousesLazyQueryHookResult = ReturnType<
	typeof useListHousesLazyQuery
>;
export type ListHousesQueryResult = Apollo.QueryResult<
	Types.ListHousesQuery,
	Types.ListHousesQueryVariables
>;
export const GetLogDocument = gql`
	query GetLog($id: String) {
		getLog(where: { id: $id }) {
			data
			type
			createdAt
			updatedAt
			youngPerson {
				id
				name
				avatar
				room {
					color
				}
				house {
					title
				}
			}
			createdByUser {
				name
			}
		}
	}
`;

/**
 * __useGetLogQuery__
 *
 * To run a query within a React component, call `useGetLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLogQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLogQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetLogQuery,
		Types.GetLogQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.GetLogQuery, Types.GetLogQueryVariables>(
		GetLogDocument,
		options
	);
}
export function useGetLogLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetLogQuery,
		Types.GetLogQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<Types.GetLogQuery, Types.GetLogQueryVariables>(
		GetLogDocument,
		options
	);
}
export type GetLogQueryHookResult = ReturnType<typeof useGetLogQuery>;
export type GetLogLazyQueryHookResult = ReturnType<typeof useGetLogLazyQuery>;
export type GetLogQueryResult = Apollo.QueryResult<
	Types.GetLogQuery,
	Types.GetLogQueryVariables
>;
export const DeleteLogDocument = gql`
	mutation DeleteLog($id: String) {
		deleteLog(where: { id: $id }) {
			id
		}
	}
`;
export type DeleteLogMutationFn = Apollo.MutationFunction<
	Types.DeleteLogMutation,
	Types.DeleteLogMutationVariables
>;

/**
 * __useDeleteLogMutation__
 *
 * To run a mutation, you first call `useDeleteLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLogMutation, { data, loading, error }] = useDeleteLogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLogMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.DeleteLogMutation,
		Types.DeleteLogMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.DeleteLogMutation,
		Types.DeleteLogMutationVariables
	>(DeleteLogDocument, options);
}
export type DeleteLogMutationHookResult = ReturnType<
	typeof useDeleteLogMutation
>;
export type DeleteLogMutationResult =
	Apollo.MutationResult<Types.DeleteLogMutation>;
export type DeleteLogMutationOptions = Apollo.BaseMutationOptions<
	Types.DeleteLogMutation,
	Types.DeleteLogMutationVariables
>;
export const ListMyNotificationsDocument = gql`
	query ListMyNotifications(
		$limit: Int
		$filter: NotificationWhereFilterInput
	) {
		listNotifications(
			take: $limit
			where: $filter
			orderBy: { createdAt: DESC }
		) {
			id
			title
			createdAt
			readAt
			houseId
			youngPersonId
			task {
				id
				houseId
				youngPersonId
				title
			}
			taskId
			userId
		}
	}
`;

/**
 * __useListMyNotificationsQuery__
 *
 * To run a query within a React component, call `useListMyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyNotificationsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useListMyNotificationsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListMyNotificationsQuery,
		Types.ListMyNotificationsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListMyNotificationsQuery,
		Types.ListMyNotificationsQueryVariables
	>(ListMyNotificationsDocument, options);
}
export function useListMyNotificationsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListMyNotificationsQuery,
		Types.ListMyNotificationsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListMyNotificationsQuery,
		Types.ListMyNotificationsQueryVariables
	>(ListMyNotificationsDocument, options);
}
export type ListMyNotificationsQueryHookResult = ReturnType<
	typeof useListMyNotificationsQuery
>;
export type ListMyNotificationsLazyQueryHookResult = ReturnType<
	typeof useListMyNotificationsLazyQuery
>;
export type ListMyNotificationsQueryResult = Apollo.QueryResult<
	Types.ListMyNotificationsQuery,
	Types.ListMyNotificationsQueryVariables
>;
export const NotifyMeDocument = gql`
	subscription NotifyMe {
		onCreatedManyNotifications {
			count
		}
	}
`;

/**
 * __useNotifyMeSubscription__
 *
 * To run a query within a React component, call `useNotifyMeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotifyMeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifyMeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotifyMeSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		Types.NotifyMeSubscription,
		Types.NotifyMeSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		Types.NotifyMeSubscription,
		Types.NotifyMeSubscriptionVariables
	>(NotifyMeDocument, options);
}
export type NotifyMeSubscriptionHookResult = ReturnType<
	typeof useNotifyMeSubscription
>;
export type NotifyMeSubscriptionResult =
	Apollo.SubscriptionResult<Types.NotifyMeSubscription>;
export const MarkAsReadDocument = gql`
	mutation MarkAsRead($readAt: AWSDateTime!, $id: String!) {
		updateNotification(where: { id: $id }, data: { readAt: $readAt }) {
			id
			readAt
		}
	}
`;
export type MarkAsReadMutationFn = Apollo.MutationFunction<
	Types.MarkAsReadMutation,
	Types.MarkAsReadMutationVariables
>;

/**
 * __useMarkAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadMutation, { data, loading, error }] = useMarkAsReadMutation({
 *   variables: {
 *      readAt: // value for 'readAt'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkAsReadMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.MarkAsReadMutation,
		Types.MarkAsReadMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.MarkAsReadMutation,
		Types.MarkAsReadMutationVariables
	>(MarkAsReadDocument, options);
}
export type MarkAsReadMutationHookResult = ReturnType<
	typeof useMarkAsReadMutation
>;
export type MarkAsReadMutationResult =
	Apollo.MutationResult<Types.MarkAsReadMutation>;
export type MarkAsReadMutationOptions = Apollo.BaseMutationOptions<
	Types.MarkAsReadMutation,
	Types.MarkAsReadMutationVariables
>;
export const MarkAllAsReadDocument = gql`
	mutation MarkAllAsRead($readAt: AWSDateTime!, $ids: [String!]!) {
		updateManyNotifications(
			where: { id: { in: $ids } }
			data: { readAt: $readAt }
		) {
			count
		}
	}
`;
export type MarkAllAsReadMutationFn = Apollo.MutationFunction<
	Types.MarkAllAsReadMutation,
	Types.MarkAllAsReadMutationVariables
>;

/**
 * __useMarkAllAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllAsReadMutation, { data, loading, error }] = useMarkAllAsReadMutation({
 *   variables: {
 *      readAt: // value for 'readAt'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMarkAllAsReadMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.MarkAllAsReadMutation,
		Types.MarkAllAsReadMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.MarkAllAsReadMutation,
		Types.MarkAllAsReadMutationVariables
	>(MarkAllAsReadDocument, options);
}
export type MarkAllAsReadMutationHookResult = ReturnType<
	typeof useMarkAllAsReadMutation
>;
export type MarkAllAsReadMutationResult =
	Apollo.MutationResult<Types.MarkAllAsReadMutation>;
export type MarkAllAsReadMutationOptions = Apollo.BaseMutationOptions<
	Types.MarkAllAsReadMutation,
	Types.MarkAllAsReadMutationVariables
>;
export const OccupancyChartDocument = gql`
	query OccupancyChart(
		$id: String
		$startDate: AWSDateTime
		$endDate: AWSDateTime
	) {
		listOccupancyLogs(
			where: {
				AND: [
					{ room: { house: { id: { equals: $id } } } }
					{ startDate: { lte: $endDate } }
					{
						OR: [
							{ endDate: { gte: $startDate } }
							{ endDate: { equals: null } }
						]
					}
				]
			}
		) {
			id
			startDate
			endDate
			room {
				id
				name
			}
			youngPerson {
				id
				name
			}
		}
		listRooms(where: { house: { id: { equals: $id } } }) {
			id
			name
			color
		}
	}
`;

/**
 * __useOccupancyChartQuery__
 *
 * To run a query within a React component, call `useOccupancyChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useOccupancyChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOccupancyChartQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useOccupancyChartQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.OccupancyChartQuery,
		Types.OccupancyChartQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.OccupancyChartQuery,
		Types.OccupancyChartQueryVariables
	>(OccupancyChartDocument, options);
}
export function useOccupancyChartLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.OccupancyChartQuery,
		Types.OccupancyChartQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.OccupancyChartQuery,
		Types.OccupancyChartQueryVariables
	>(OccupancyChartDocument, options);
}
export type OccupancyChartQueryHookResult = ReturnType<
	typeof useOccupancyChartQuery
>;
export type OccupancyChartLazyQueryHookResult = ReturnType<
	typeof useOccupancyChartLazyQuery
>;
export type OccupancyChartQueryResult = Apollo.QueryResult<
	Types.OccupancyChartQuery,
	Types.OccupancyChartQueryVariables
>;
export const GetFileDocument = gql`
	query GetFile($key: String!) {
		getFile(input: { fileKey: $key }) {
			key
		}
	}
`;

/**
 * __useGetFileQuery__
 *
 * To run a query within a React component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetFileQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetFileQuery,
		Types.GetFileQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.GetFileQuery, Types.GetFileQueryVariables>(
		GetFileDocument,
		options
	);
}
export function useGetFileLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetFileQuery,
		Types.GetFileQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<Types.GetFileQuery, Types.GetFileQueryVariables>(
		GetFileDocument,
		options
	);
}
export type GetFileQueryHookResult = ReturnType<typeof useGetFileQuery>;
export type GetFileLazyQueryHookResult = ReturnType<typeof useGetFileLazyQuery>;
export type GetFileQueryResult = Apollo.QueryResult<
	Types.GetFileQuery,
	Types.GetFileQueryVariables
>;
export const DeleteFileDocument = gql`
	mutation DeleteFile($key: String!) {
		deleteFile(input: { fileKey: $key }) {
			statusCode
		}
	}
`;
export type DeleteFileMutationFn = Apollo.MutationFunction<
	Types.DeleteFileMutation,
	Types.DeleteFileMutationVariables
>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteFileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.DeleteFileMutation,
		Types.DeleteFileMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.DeleteFileMutation,
		Types.DeleteFileMutationVariables
	>(DeleteFileDocument, options);
}
export type DeleteFileMutationHookResult = ReturnType<
	typeof useDeleteFileMutation
>;
export type DeleteFileMutationResult =
	Apollo.MutationResult<Types.DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<
	Types.DeleteFileMutation,
	Types.DeleteFileMutationVariables
>;
export const GetTaskWithUserDocument = gql`
	query GetTaskWithUser($id: String!) {
		getTask(where: { id: $id }) {
			id
			ref
			dueDate
			title
			description
			createdAt
			repeat
			repeatEnd
			isPrivate
			isAdmin
			houseId
			youngPerson {
				id
				name
			}
			createdBy {
				...BasicUser
			}
			completedAt
			completedBy {
				...BasicUser
			}
			assignedTo {
				...BasicUser
			}
		}
	}
	${BasicUserFragmentDoc}
`;

/**
 * __useGetTaskWithUserQuery__
 *
 * To run a query within a React component, call `useGetTaskWithUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskWithUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskWithUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskWithUserQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetTaskWithUserQuery,
		Types.GetTaskWithUserQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetTaskWithUserQuery,
		Types.GetTaskWithUserQueryVariables
	>(GetTaskWithUserDocument, options);
}
export function useGetTaskWithUserLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetTaskWithUserQuery,
		Types.GetTaskWithUserQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetTaskWithUserQuery,
		Types.GetTaskWithUserQueryVariables
	>(GetTaskWithUserDocument, options);
}
export type GetTaskWithUserQueryHookResult = ReturnType<
	typeof useGetTaskWithUserQuery
>;
export type GetTaskWithUserLazyQueryHookResult = ReturnType<
	typeof useGetTaskWithUserLazyQuery
>;
export type GetTaskWithUserQueryResult = Apollo.QueryResult<
	Types.GetTaskWithUserQuery,
	Types.GetTaskWithUserQueryVariables
>;
export const CompleteTaskDocument = gql`
	mutation CompleteTask(
		$taskId: String!
		$completedAt: AWSDateTime!
		$completedBy: String!
	) {
		updateTask(
			data: {
				completedAt: $completedAt
				completedBy: { connect: { id: $completedBy } }
			}
			where: { id: $taskId }
		) {
			...BasicTask
		}
	}
	${BasicTaskFragmentDoc}
`;
export type CompleteTaskMutationFn = Apollo.MutationFunction<
	Types.CompleteTaskMutation,
	Types.CompleteTaskMutationVariables
>;

/**
 * __useCompleteTaskMutation__
 *
 * To run a mutation, you first call `useCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTaskMutation, { data, loading, error }] = useCompleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *      completedAt: // value for 'completedAt'
 *      completedBy: // value for 'completedBy'
 *   },
 * });
 */
export function useCompleteTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.CompleteTaskMutation,
		Types.CompleteTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.CompleteTaskMutation,
		Types.CompleteTaskMutationVariables
	>(CompleteTaskDocument, options);
}
export type CompleteTaskMutationHookResult = ReturnType<
	typeof useCompleteTaskMutation
>;
export type CompleteTaskMutationResult =
	Apollo.MutationResult<Types.CompleteTaskMutation>;
export type CompleteTaskMutationOptions = Apollo.BaseMutationOptions<
	Types.CompleteTaskMutation,
	Types.CompleteTaskMutationVariables
>;
export const UnCompleteTaskDocument = gql`
	mutation UnCompleteTask($taskId: String!) {
		updateTask(
			data: { completedAt: null, completedBy: { disconnect: true } }
			where: { id: $taskId }
		) {
			...BasicTask
		}
	}
	${BasicTaskFragmentDoc}
`;
export type UnCompleteTaskMutationFn = Apollo.MutationFunction<
	Types.UnCompleteTaskMutation,
	Types.UnCompleteTaskMutationVariables
>;

/**
 * __useUnCompleteTaskMutation__
 *
 * To run a mutation, you first call `useUnCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unCompleteTaskMutation, { data, loading, error }] = useUnCompleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useUnCompleteTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UnCompleteTaskMutation,
		Types.UnCompleteTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UnCompleteTaskMutation,
		Types.UnCompleteTaskMutationVariables
	>(UnCompleteTaskDocument, options);
}
export type UnCompleteTaskMutationHookResult = ReturnType<
	typeof useUnCompleteTaskMutation
>;
export type UnCompleteTaskMutationResult =
	Apollo.MutationResult<Types.UnCompleteTaskMutation>;
export type UnCompleteTaskMutationOptions = Apollo.BaseMutationOptions<
	Types.UnCompleteTaskMutation,
	Types.UnCompleteTaskMutationVariables
>;
export const DeleteTaskDocument = gql`
	mutation DeleteTask($taskId: String!) {
		deleteTask(where: { id: $taskId }) {
			title
		}
	}
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
	Types.DeleteTaskMutation,
	Types.DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteTaskMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.DeleteTaskMutation,
		Types.DeleteTaskMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.DeleteTaskMutation,
		Types.DeleteTaskMutationVariables
	>(DeleteTaskDocument, options);
}
export type DeleteTaskMutationHookResult = ReturnType<
	typeof useDeleteTaskMutation
>;
export type DeleteTaskMutationResult =
	Apollo.MutationResult<Types.DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
	Types.DeleteTaskMutation,
	Types.DeleteTaskMutationVariables
>;
export const KidReportSnapshotDocument = gql`
	query KidReportSnapshot(
		$id: String
		$startDate: AWSDateTime
		$endDate: AWSDateTime
	) {
		listLogs(
			where: {
				AND: [
					{ youngPerson: { id: { equals: $id } } }
					{
						OR: [
							{ type: { equals: LOCATION } }
							{ type: { equals: COACHING_SESSION } }
							{ createdAt: { gte: $startDate } }
							{ createdAt: { lte: $endDate } }
						]
					}
				]
			}
		) {
			type
			data
		}
		countLogs(
			where: {
				AND: [
					{ youngPerson: { id: { equals: $id } } }
					{ type: { equals: INCIDENT } }
				]
			}
		)
	}
`;

/**
 * __useKidReportSnapshotQuery__
 *
 * To run a query within a React component, call `useKidReportSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useKidReportSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKidReportSnapshotQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useKidReportSnapshotQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.KidReportSnapshotQuery,
		Types.KidReportSnapshotQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.KidReportSnapshotQuery,
		Types.KidReportSnapshotQueryVariables
	>(KidReportSnapshotDocument, options);
}
export function useKidReportSnapshotLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.KidReportSnapshotQuery,
		Types.KidReportSnapshotQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.KidReportSnapshotQuery,
		Types.KidReportSnapshotQueryVariables
	>(KidReportSnapshotDocument, options);
}
export type KidReportSnapshotQueryHookResult = ReturnType<
	typeof useKidReportSnapshotQuery
>;
export type KidReportSnapshotLazyQueryHookResult = ReturnType<
	typeof useKidReportSnapshotLazyQuery
>;
export type KidReportSnapshotQueryResult = Apollo.QueryResult<
	Types.KidReportSnapshotQuery,
	Types.KidReportSnapshotQueryVariables
>;
export const ListRoomsDocument = gql`
	query ListRooms($houseId: String) {
		listRooms(where: { houseId: { equals: $houseId } }) {
			id
			name
			color
			youngPerson {
				name
			}
		}
	}
`;

/**
 * __useListRoomsQuery__
 *
 * To run a query within a React component, call `useListRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRoomsQuery({
 *   variables: {
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useListRoomsQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListRoomsQuery,
		Types.ListRoomsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.ListRoomsQuery, Types.ListRoomsQueryVariables>(
		ListRoomsDocument,
		options
	);
}
export function useListRoomsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListRoomsQuery,
		Types.ListRoomsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListRoomsQuery,
		Types.ListRoomsQueryVariables
	>(ListRoomsDocument, options);
}
export type ListRoomsQueryHookResult = ReturnType<typeof useListRoomsQuery>;
export type ListRoomsLazyQueryHookResult = ReturnType<
	typeof useListRoomsLazyQuery
>;
export type ListRoomsQueryResult = Apollo.QueryResult<
	Types.ListRoomsQuery,
	Types.ListRoomsQueryVariables
>;
export const GetYoungPersonAdminTasksDocument = gql`
	query GetYoungPersonAdminTasks($youngPersonId: String!) {
		listTasks(
			where: {
				youngPersonId: { equals: $youngPersonId }
				group: { in: [PRE_MOVE_IN, MOVE_IN, MOVE_OUT] }
				isAdmin: { equals: true }
			}
		) {
			...GroupTask
		}
	}
	${GroupTaskFragmentDoc}
`;

/**
 * __useGetYoungPersonAdminTasksQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonAdminTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonAdminTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonAdminTasksQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonAdminTasksQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPersonAdminTasksQuery,
		Types.GetYoungPersonAdminTasksQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPersonAdminTasksQuery,
		Types.GetYoungPersonAdminTasksQueryVariables
	>(GetYoungPersonAdminTasksDocument, options);
}
export function useGetYoungPersonAdminTasksLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPersonAdminTasksQuery,
		Types.GetYoungPersonAdminTasksQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPersonAdminTasksQuery,
		Types.GetYoungPersonAdminTasksQueryVariables
	>(GetYoungPersonAdminTasksDocument, options);
}
export type GetYoungPersonAdminTasksQueryHookResult = ReturnType<
	typeof useGetYoungPersonAdminTasksQuery
>;
export type GetYoungPersonAdminTasksLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonAdminTasksLazyQuery
>;
export type GetYoungPersonAdminTasksQueryResult = Apollo.QueryResult<
	Types.GetYoungPersonAdminTasksQuery,
	Types.GetYoungPersonAdminTasksQueryVariables
>;
export const OnYoungPersonTaskCreatedDocument = gql`
	subscription onYoungPersonTaskCreated($youngPersonId: String!) {
		onMutatedTask(youngPersonId: $youngPersonId) {
			...GroupTask
		}
	}
	${GroupTaskFragmentDoc}
`;

/**
 * __useOnYoungPersonTaskCreatedSubscription__
 *
 * To run a query within a React component, call `useOnYoungPersonTaskCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnYoungPersonTaskCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnYoungPersonTaskCreatedSubscription({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useOnYoungPersonTaskCreatedSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		Types.OnYoungPersonTaskCreatedSubscription,
		Types.OnYoungPersonTaskCreatedSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		Types.OnYoungPersonTaskCreatedSubscription,
		Types.OnYoungPersonTaskCreatedSubscriptionVariables
	>(OnYoungPersonTaskCreatedDocument, options);
}
export type OnYoungPersonTaskCreatedSubscriptionHookResult = ReturnType<
	typeof useOnYoungPersonTaskCreatedSubscription
>;
export type OnYoungPersonTaskCreatedSubscriptionResult =
	Apollo.SubscriptionResult<Types.OnYoungPersonTaskCreatedSubscription>;
export const GetYpTasksByGroupDocument = gql`
	query GetYPTasksByGroup($youngPersonId: String!, $group: TaskGroup) {
		listTasks(
			where: {
				group: { equals: $group }
				youngPersonId: { equals: $youngPersonId }
			}
		) {
			id
			title
			group
			fileType
			formType
			youngPerson {
				id
				houseId
			}
			completedAt
		}
	}
`;

/**
 * __useGetYpTasksByGroupQuery__
 *
 * To run a query within a React component, call `useGetYpTasksByGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYpTasksByGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYpTasksByGroupQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      group: // value for 'group'
 *   },
 * });
 */
export function useGetYpTasksByGroupQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYpTasksByGroupQuery,
		Types.GetYpTasksByGroupQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYpTasksByGroupQuery,
		Types.GetYpTasksByGroupQueryVariables
	>(GetYpTasksByGroupDocument, options);
}
export function useGetYpTasksByGroupLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYpTasksByGroupQuery,
		Types.GetYpTasksByGroupQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYpTasksByGroupQuery,
		Types.GetYpTasksByGroupQueryVariables
	>(GetYpTasksByGroupDocument, options);
}
export type GetYpTasksByGroupQueryHookResult = ReturnType<
	typeof useGetYpTasksByGroupQuery
>;
export type GetYpTasksByGroupLazyQueryHookResult = ReturnType<
	typeof useGetYpTasksByGroupLazyQuery
>;
export type GetYpTasksByGroupQueryResult = Apollo.QueryResult<
	Types.GetYpTasksByGroupQuery,
	Types.GetYpTasksByGroupQueryVariables
>;
export const MutatedYoungPersonTasksDocument = gql`
	subscription MutatedYoungPersonTasks($youngPersonId: String!) {
		onMutatedTask(youngPersonId: $youngPersonId) {
			id
			ref
			completedAt
			description
			fileType
			youngPersonId
			title
			formType
			group
			isAdmin
			isAutomated
			isPrivate
			dueDate
			repeat
			repeatEnd
			houseId
			assignedUserId
			createdAt
			updatedAt
			deletedAt
		}
	}
`;

/**
 * __useMutatedYoungPersonTasksSubscription__
 *
 * To run a query within a React component, call `useMutatedYoungPersonTasksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMutatedYoungPersonTasksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMutatedYoungPersonTasksSubscription({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useMutatedYoungPersonTasksSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		Types.MutatedYoungPersonTasksSubscription,
		Types.MutatedYoungPersonTasksSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		Types.MutatedYoungPersonTasksSubscription,
		Types.MutatedYoungPersonTasksSubscriptionVariables
	>(MutatedYoungPersonTasksDocument, options);
}
export type MutatedYoungPersonTasksSubscriptionHookResult = ReturnType<
	typeof useMutatedYoungPersonTasksSubscription
>;
export type MutatedYoungPersonTasksSubscriptionResult =
	Apollo.SubscriptionResult<Types.MutatedYoungPersonTasksSubscription>;
export const ListTasksDocument = gql`
	query ListTasks(
		$where: TaskWhereFilterInput = { isAdmin: { equals: false } }
		$orderBy: [TaskOrderByInput] = [{ createdAt: ASC }]
		$skip: Int
		$take: Int = 10
	) {
		listTasks(where: $where, orderBy: $orderBy, skip: $skip, take: $take) {
			...BasicTask
		}
		countTasks(where: $where)
	}
	${BasicTaskFragmentDoc}
`;

/**
 * __useListTasksQuery__
 *
 * To run a query within a React component, call `useListTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListTasksQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListTasksQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListTasksQuery,
		Types.ListTasksQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<Types.ListTasksQuery, Types.ListTasksQueryVariables>(
		ListTasksDocument,
		options
	);
}
export function useListTasksLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListTasksQuery,
		Types.ListTasksQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListTasksQuery,
		Types.ListTasksQueryVariables
	>(ListTasksDocument, options);
}
export type ListTasksQueryHookResult = ReturnType<typeof useListTasksQuery>;
export type ListTasksLazyQueryHookResult = ReturnType<
	typeof useListTasksLazyQuery
>;
export type ListTasksQueryResult = Apollo.QueryResult<
	Types.ListTasksQuery,
	Types.ListTasksQueryVariables
>;
export const OnHouseTaskMutatedDocument = gql`
	subscription onHouseTaskMutated($houseId: String) {
		onMutatedTask(houseId: $houseId) {
			id
			ref
			completedAt
			description
			fileType
			youngPersonId
			title
			formType
			group
			isAdmin
			isAutomated
			isPrivate
			dueDate
			repeat
			repeatEnd
			houseId
			assignedUserId
			createdAt
			updatedAt
			deletedAt
		}
	}
`;

/**
 * __useOnHouseTaskMutatedSubscription__
 *
 * To run a query within a React component, call `useOnHouseTaskMutatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnHouseTaskMutatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnHouseTaskMutatedSubscription({
 *   variables: {
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useOnHouseTaskMutatedSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		Types.OnHouseTaskMutatedSubscription,
		Types.OnHouseTaskMutatedSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		Types.OnHouseTaskMutatedSubscription,
		Types.OnHouseTaskMutatedSubscriptionVariables
	>(OnHouseTaskMutatedDocument, options);
}
export type OnHouseTaskMutatedSubscriptionHookResult = ReturnType<
	typeof useOnHouseTaskMutatedSubscription
>;
export type OnHouseTaskMutatedSubscriptionResult =
	Apollo.SubscriptionResult<Types.OnHouseTaskMutatedSubscription>;
export const OnYoungPersonTaskMutatedDocument = gql`
	subscription onYoungPersonTaskMutated($youngPersonId: String) {
		onMutatedTask(youngPersonId: $youngPersonId) {
			id
			ref
			completedAt
			description
			fileType
			youngPersonId
			title
			formType
			group
			isAdmin
			isAutomated
			isPrivate
			dueDate
			repeat
			repeatEnd
			houseId
			assignedUserId
			createdAt
			updatedAt
			deletedAt
		}
	}
`;

/**
 * __useOnYoungPersonTaskMutatedSubscription__
 *
 * To run a query within a React component, call `useOnYoungPersonTaskMutatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnYoungPersonTaskMutatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnYoungPersonTaskMutatedSubscription({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useOnYoungPersonTaskMutatedSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		Types.OnYoungPersonTaskMutatedSubscription,
		Types.OnYoungPersonTaskMutatedSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		Types.OnYoungPersonTaskMutatedSubscription,
		Types.OnYoungPersonTaskMutatedSubscriptionVariables
	>(OnYoungPersonTaskMutatedDocument, options);
}
export type OnYoungPersonTaskMutatedSubscriptionHookResult = ReturnType<
	typeof useOnYoungPersonTaskMutatedSubscription
>;
export type OnYoungPersonTaskMutatedSubscriptionResult =
	Apollo.SubscriptionResult<Types.OnYoungPersonTaskMutatedSubscription>;
export const TrmGraphDataDocument = gql`
	query TrmGraphData(
		$id: String
		$startDate: AWSDateTime
		$endDate: AWSDateTime
	) {
		listLogs(
			where: {
				AND: [
					{ youngPerson: { id: { equals: $id } } }
					{ type: { equals: SPIN_SESSION } }
					{ createdAt: { gte: $startDate } }
					{ createdAt: { lte: $endDate } }
				]
			}
		) {
			id
			data
			createdByUser {
				name
			}
		}
	}
`;

/**
 * __useTrmGraphDataQuery__
 *
 * To run a query within a React component, call `useTrmGraphDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrmGraphDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrmGraphDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useTrmGraphDataQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.TrmGraphDataQuery,
		Types.TrmGraphDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.TrmGraphDataQuery,
		Types.TrmGraphDataQueryVariables
	>(TrmGraphDataDocument, options);
}
export function useTrmGraphDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.TrmGraphDataQuery,
		Types.TrmGraphDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.TrmGraphDataQuery,
		Types.TrmGraphDataQueryVariables
	>(TrmGraphDataDocument, options);
}
export type TrmGraphDataQueryHookResult = ReturnType<
	typeof useTrmGraphDataQuery
>;
export type TrmGraphDataLazyQueryHookResult = ReturnType<
	typeof useTrmGraphDataLazyQuery
>;
export type TrmGraphDataQueryResult = Apollo.QueryResult<
	Types.TrmGraphDataQuery,
	Types.TrmGraphDataQueryVariables
>;
export const GetStaffMemberDocument = gql`
	query GetStaffMember($id: String) {
		getUser(where: { id: $id }) {
			...UserDetails
			name
			avatar
			role
			createdAt
			house {
				title
				color
			}
		}
	}
	${UserDetailsFragmentDoc}
`;

/**
 * __useGetStaffMemberQuery__
 *
 * To run a query within a React component, call `useGetStaffMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaffMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaffMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStaffMemberQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.GetStaffMemberQuery,
		Types.GetStaffMemberQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetStaffMemberQuery,
		Types.GetStaffMemberQueryVariables
	>(GetStaffMemberDocument, options);
}
export function useGetStaffMemberLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetStaffMemberQuery,
		Types.GetStaffMemberQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetStaffMemberQuery,
		Types.GetStaffMemberQueryVariables
	>(GetStaffMemberDocument, options);
}
export type GetStaffMemberQueryHookResult = ReturnType<
	typeof useGetStaffMemberQuery
>;
export type GetStaffMemberLazyQueryHookResult = ReturnType<
	typeof useGetStaffMemberLazyQuery
>;
export type GetStaffMemberQueryResult = Apollo.QueryResult<
	Types.GetStaffMemberQuery,
	Types.GetStaffMemberQueryVariables
>;
export const ListUsersForSelectDocument = gql`
	query ListUsersForSelect($where: UserWhereFilterInput) {
		listUsers(where: $where) {
			id
			name
			firstName
			lastName
			email
			role
		}
	}
`;

/**
 * __useListUsersForSelectQuery__
 *
 * To run a query within a React component, call `useListUsersForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersForSelectQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListUsersForSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListUsersForSelectQuery,
		Types.ListUsersForSelectQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListUsersForSelectQuery,
		Types.ListUsersForSelectQueryVariables
	>(ListUsersForSelectDocument, options);
}
export function useListUsersForSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListUsersForSelectQuery,
		Types.ListUsersForSelectQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListUsersForSelectQuery,
		Types.ListUsersForSelectQueryVariables
	>(ListUsersForSelectDocument, options);
}
export type ListUsersForSelectQueryHookResult = ReturnType<
	typeof useListUsersForSelectQuery
>;
export type ListUsersForSelectLazyQueryHookResult = ReturnType<
	typeof useListUsersForSelectLazyQuery
>;
export type ListUsersForSelectQueryResult = Apollo.QueryResult<
	Types.ListUsersForSelectQuery,
	Types.ListUsersForSelectQueryVariables
>;
export const ListYoungPeopleDocument = gql`
	query ListYoungPeople(
		$where: YoungPersonWhereFilterInput!
		$sortBy: OrderByArg = ASC
		$skip: Int = 0
		$take: Int = 20
	) {
		listYoungPeople(
			skip: $skip
			take: $take
			where: $where
			orderBy: { name: $sortBy }
		) {
			id
			name
			dateOfBirth
			preferredName
			avatar
			house {
				title
				color
			}
		}
		countYoungPeople(where: $where)
	}
`;

/**
 * __useListYoungPeopleQuery__
 *
 * To run a query within a React component, call `useListYoungPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useListYoungPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListYoungPeopleQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sortBy: // value for 'sortBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListYoungPeopleQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.ListYoungPeopleQuery,
		Types.ListYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListYoungPeopleQuery,
		Types.ListYoungPeopleQueryVariables
	>(ListYoungPeopleDocument, options);
}
export function useListYoungPeopleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListYoungPeopleQuery,
		Types.ListYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListYoungPeopleQuery,
		Types.ListYoungPeopleQueryVariables
	>(ListYoungPeopleDocument, options);
}
export type ListYoungPeopleQueryHookResult = ReturnType<
	typeof useListYoungPeopleQuery
>;
export type ListYoungPeopleLazyQueryHookResult = ReturnType<
	typeof useListYoungPeopleLazyQuery
>;
export type ListYoungPeopleQueryResult = Apollo.QueryResult<
	Types.ListYoungPeopleQuery,
	Types.ListYoungPeopleQueryVariables
>;
export const AssignHouseDocument = gql`
	mutation AssignHouse(
		$youngPersonId: String!
		$houseId: String
		$roomId: String
		$status: YoungPersonStatus!
		$moveIn: AWSDateTime
	) {
		updateRoom(
			where: { id: $roomId }
			data: { youngPersonId: $youngPersonId }
		) {
			id
		}
		updateYoungPerson(
			where: { id: $youngPersonId }
			data: { houseId: $houseId, status: $status, moveInDate: $moveIn }
		) {
			...YoungPerson
		}
	}
	${YoungPersonFragmentDoc}
`;
export type AssignHouseMutationFn = Apollo.MutationFunction<
	Types.AssignHouseMutation,
	Types.AssignHouseMutationVariables
>;

/**
 * __useAssignHouseMutation__
 *
 * To run a mutation, you first call `useAssignHouseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignHouseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignHouseMutation, { data, loading, error }] = useAssignHouseMutation({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *      houseId: // value for 'houseId'
 *      roomId: // value for 'roomId'
 *      status: // value for 'status'
 *      moveIn: // value for 'moveIn'
 *   },
 * });
 */
export function useAssignHouseMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.AssignHouseMutation,
		Types.AssignHouseMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.AssignHouseMutation,
		Types.AssignHouseMutationVariables
	>(AssignHouseDocument, options);
}
export type AssignHouseMutationHookResult = ReturnType<
	typeof useAssignHouseMutation
>;
export type AssignHouseMutationResult =
	Apollo.MutationResult<Types.AssignHouseMutation>;
export type AssignHouseMutationOptions = Apollo.BaseMutationOptions<
	Types.AssignHouseMutation,
	Types.AssignHouseMutationVariables
>;
export const ListHousesSelectDocument = gql`
	query ListHousesSelect {
		listHouses(
			where: {
				AND: [
					{ preMoveInHouse: { equals: false } }
					{ isMoveOutHouse: { not: { equals: true } } }
				]
			}
		) {
			id
			title
		}
	}
`;

/**
 * __useListHousesSelectQuery__
 *
 * To run a query within a React component, call `useListHousesSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHousesSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHousesSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useListHousesSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListHousesSelectQuery,
		Types.ListHousesSelectQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListHousesSelectQuery,
		Types.ListHousesSelectQueryVariables
	>(ListHousesSelectDocument, options);
}
export function useListHousesSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListHousesSelectQuery,
		Types.ListHousesSelectQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListHousesSelectQuery,
		Types.ListHousesSelectQueryVariables
	>(ListHousesSelectDocument, options);
}
export type ListHousesSelectQueryHookResult = ReturnType<
	typeof useListHousesSelectQuery
>;
export type ListHousesSelectLazyQueryHookResult = ReturnType<
	typeof useListHousesSelectLazyQuery
>;
export type ListHousesSelectQueryResult = Apollo.QueryResult<
	Types.ListHousesSelectQuery,
	Types.ListHousesSelectQueryVariables
>;
export const ListRoomsSelectDocument = gql`
	query ListRoomsSelect {
		listRooms(where: { youngPersonId: { equals: null } }) {
			id
			name
			houseId
			youngPersonId
		}
	}
`;

/**
 * __useListRoomsSelectQuery__
 *
 * To run a query within a React component, call `useListRoomsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRoomsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRoomsSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useListRoomsSelectQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListRoomsSelectQuery,
		Types.ListRoomsSelectQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListRoomsSelectQuery,
		Types.ListRoomsSelectQueryVariables
	>(ListRoomsSelectDocument, options);
}
export function useListRoomsSelectLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListRoomsSelectQuery,
		Types.ListRoomsSelectQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListRoomsSelectQuery,
		Types.ListRoomsSelectQueryVariables
	>(ListRoomsSelectDocument, options);
}
export type ListRoomsSelectQueryHookResult = ReturnType<
	typeof useListRoomsSelectQuery
>;
export type ListRoomsSelectLazyQueryHookResult = ReturnType<
	typeof useListRoomsSelectLazyQuery
>;
export type ListRoomsSelectQueryResult = Apollo.QueryResult<
	Types.ListRoomsSelectQuery,
	Types.ListRoomsSelectQueryVariables
>;
export const ListLocationStatusesDocument = gql`
	query ListLocationStatuses {
		listYoungPersonLocationStatuses {
			id
			title
			type
			priority
		}
	}
`;

/**
 * __useListLocationStatusesQuery__
 *
 * To run a query within a React component, call `useListLocationStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLocationStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLocationStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLocationStatusesQuery(
	baseOptions?: Apollo.QueryHookOptions<
		Types.ListLocationStatusesQuery,
		Types.ListLocationStatusesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.ListLocationStatusesQuery,
		Types.ListLocationStatusesQueryVariables
	>(ListLocationStatusesDocument, options);
}
export function useListLocationStatusesLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.ListLocationStatusesQuery,
		Types.ListLocationStatusesQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.ListLocationStatusesQuery,
		Types.ListLocationStatusesQueryVariables
	>(ListLocationStatusesDocument, options);
}
export type ListLocationStatusesQueryHookResult = ReturnType<
	typeof useListLocationStatusesQuery
>;
export type ListLocationStatusesLazyQueryHookResult = ReturnType<
	typeof useListLocationStatusesLazyQuery
>;
export type ListLocationStatusesQueryResult = Apollo.QueryResult<
	Types.ListLocationStatusesQuery,
	Types.ListLocationStatusesQueryVariables
>;
export const MoveYoungPersonDocument = gql`
	mutation moveYoungPerson($roomId: String!, $data: RoomUpdateInput!) {
		updateRoom(where: { id: $roomId }, data: $data) {
			id
			youngPerson {
				id
				house {
					id
				}
				room {
					id
				}
			}
		}
	}
`;
export type MoveYoungPersonMutationFn = Apollo.MutationFunction<
	Types.MoveYoungPersonMutation,
	Types.MoveYoungPersonMutationVariables
>;

/**
 * __useMoveYoungPersonMutation__
 *
 * To run a mutation, you first call `useMoveYoungPersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveYoungPersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveYoungPersonMutation, { data, loading, error }] = useMoveYoungPersonMutation({
 *   variables: {
 *      roomId: // value for 'roomId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useMoveYoungPersonMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.MoveYoungPersonMutation,
		Types.MoveYoungPersonMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.MoveYoungPersonMutation,
		Types.MoveYoungPersonMutationVariables
	>(MoveYoungPersonDocument, options);
}
export type MoveYoungPersonMutationHookResult = ReturnType<
	typeof useMoveYoungPersonMutation
>;
export type MoveYoungPersonMutationResult =
	Apollo.MutationResult<Types.MoveYoungPersonMutation>;
export type MoveYoungPersonMutationOptions = Apollo.BaseMutationOptions<
	Types.MoveYoungPersonMutation,
	Types.MoveYoungPersonMutationVariables
>;
export const GetYoungPersonLocationDocument = gql`
	query GetYoungPersonLocation($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			houseId
			room {
				id
			}
		}
	}
`;

/**
 * __useGetYoungPersonLocationQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonLocationQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonLocationQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPersonLocationQuery,
		Types.GetYoungPersonLocationQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPersonLocationQuery,
		Types.GetYoungPersonLocationQueryVariables
	>(GetYoungPersonLocationDocument, options);
}
export function useGetYoungPersonLocationLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPersonLocationQuery,
		Types.GetYoungPersonLocationQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPersonLocationQuery,
		Types.GetYoungPersonLocationQueryVariables
	>(GetYoungPersonLocationDocument, options);
}
export type GetYoungPersonLocationQueryHookResult = ReturnType<
	typeof useGetYoungPersonLocationQuery
>;
export type GetYoungPersonLocationLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonLocationLazyQuery
>;
export type GetYoungPersonLocationQueryResult = Apollo.QueryResult<
	Types.GetYoungPersonLocationQuery,
	Types.GetYoungPersonLocationQueryVariables
>;
export const GetYoungPersonDocument = gql`
	query GetYoungPerson($youngPersonId: String!) {
		getYoungPerson(where: { id: $youngPersonId }) {
			...YoungPersonProfile
		}
	}
	${YoungPersonProfileFragmentDoc}
`;

/**
 * __useGetYoungPersonQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPersonQuery,
		Types.GetYoungPersonQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPersonQuery,
		Types.GetYoungPersonQueryVariables
	>(GetYoungPersonDocument, options);
}
export function useGetYoungPersonLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPersonQuery,
		Types.GetYoungPersonQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPersonQuery,
		Types.GetYoungPersonQueryVariables
	>(GetYoungPersonDocument, options);
}
export type GetYoungPersonQueryHookResult = ReturnType<
	typeof useGetYoungPersonQuery
>;
export type GetYoungPersonLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonLazyQuery
>;
export type GetYoungPersonQueryResult = Apollo.QueryResult<
	Types.GetYoungPersonQuery,
	Types.GetYoungPersonQueryVariables
>;
export const DeleteNextOfKinDocument = gql`
	mutation DeleteNextOfKin($id: String!) {
		deleteNextOfKin(where: { id: $id }) {
			id
			name
			relation
			phone
			createdAt
			updatedAt
			deletedAt
			youngPersonId
		}
	}
`;
export type DeleteNextOfKinMutationFn = Apollo.MutationFunction<
	Types.DeleteNextOfKinMutation,
	Types.DeleteNextOfKinMutationVariables
>;

/**
 * __useDeleteNextOfKinMutation__
 *
 * To run a mutation, you first call `useDeleteNextOfKinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNextOfKinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNextOfKinMutation, { data, loading, error }] = useDeleteNextOfKinMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNextOfKinMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.DeleteNextOfKinMutation,
		Types.DeleteNextOfKinMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.DeleteNextOfKinMutation,
		Types.DeleteNextOfKinMutationVariables
	>(DeleteNextOfKinDocument, options);
}
export type DeleteNextOfKinMutationHookResult = ReturnType<
	typeof useDeleteNextOfKinMutation
>;
export type DeleteNextOfKinMutationResult =
	Apollo.MutationResult<Types.DeleteNextOfKinMutation>;
export type DeleteNextOfKinMutationOptions = Apollo.BaseMutationOptions<
	Types.DeleteNextOfKinMutation,
	Types.DeleteNextOfKinMutationVariables
>;
export const DeleteMedicalConditionDocument = gql`
	mutation DeleteMedicalCondition($id: String!) {
		deleteMedicalCondition(where: { id: $id }) {
			id
			title
			medication
			administrationDetails
			symptoms
			triggers
			inEmergency
			createdAt
			updatedAt
			deletedAt
			youngPersonId
		}
	}
`;
export type DeleteMedicalConditionMutationFn = Apollo.MutationFunction<
	Types.DeleteMedicalConditionMutation,
	Types.DeleteMedicalConditionMutationVariables
>;

/**
 * __useDeleteMedicalConditionMutation__
 *
 * To run a mutation, you first call `useDeleteMedicalConditionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMedicalConditionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMedicalConditionMutation, { data, loading, error }] = useDeleteMedicalConditionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMedicalConditionMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.DeleteMedicalConditionMutation,
		Types.DeleteMedicalConditionMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.DeleteMedicalConditionMutation,
		Types.DeleteMedicalConditionMutationVariables
	>(DeleteMedicalConditionDocument, options);
}
export type DeleteMedicalConditionMutationHookResult = ReturnType<
	typeof useDeleteMedicalConditionMutation
>;
export type DeleteMedicalConditionMutationResult =
	Apollo.MutationResult<Types.DeleteMedicalConditionMutation>;
export type DeleteMedicalConditionMutationOptions = Apollo.BaseMutationOptions<
	Types.DeleteMedicalConditionMutation,
	Types.DeleteMedicalConditionMutationVariables
>;
export const YoungPersonProfileSubscriptionDocument = gql`
	subscription YoungPersonProfileSubscription($id: String!) {
		onMutatedYoungPerson(id: $id) {
			id
			name
			preferredName
			email
			phone
			dateOfBirth
			gender
			mostRecentAddress
			niNumber
			registeredGP
			nhsNumber
			socialWorker
			photoConcept
			status
			houseId
			createdAt
			updatedAt
			deletedAt
		}
	}
`;

/**
 * __useYoungPersonProfileSubscriptionSubscription__
 *
 * To run a query within a React component, call `useYoungPersonProfileSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useYoungPersonProfileSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useYoungPersonProfileSubscriptionSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useYoungPersonProfileSubscriptionSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		Types.YoungPersonProfileSubscriptionSubscription,
		Types.YoungPersonProfileSubscriptionSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		Types.YoungPersonProfileSubscriptionSubscription,
		Types.YoungPersonProfileSubscriptionSubscriptionVariables
	>(YoungPersonProfileSubscriptionDocument, options);
}
export type YoungPersonProfileSubscriptionSubscriptionHookResult = ReturnType<
	typeof useYoungPersonProfileSubscriptionSubscription
>;
export type YoungPersonProfileSubscriptionSubscriptionResult =
	Apollo.SubscriptionResult<Types.YoungPersonProfileSubscriptionSubscription>;
export const NextOfKinSubscriptionDocument = gql`
	subscription NextOfKinSubscription {
		onMutatedNextOfKin {
			id
			name
			relation
			phone
		}
	}
`;

/**
 * __useNextOfKinSubscriptionSubscription__
 *
 * To run a query within a React component, call `useNextOfKinSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNextOfKinSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNextOfKinSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNextOfKinSubscriptionSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		Types.NextOfKinSubscriptionSubscription,
		Types.NextOfKinSubscriptionSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		Types.NextOfKinSubscriptionSubscription,
		Types.NextOfKinSubscriptionSubscriptionVariables
	>(NextOfKinSubscriptionDocument, options);
}
export type NextOfKinSubscriptionSubscriptionHookResult = ReturnType<
	typeof useNextOfKinSubscriptionSubscription
>;
export type NextOfKinSubscriptionSubscriptionResult =
	Apollo.SubscriptionResult<Types.NextOfKinSubscriptionSubscription>;
export const MedicalConditionsSubscriptionDocument = gql`
	subscription MedicalConditionsSubscription {
		onMutatedMedicalCondition {
			id
			title
			medication
			administrationDetails
			symptoms
			triggers
			inEmergency
		}
	}
`;

/**
 * __useMedicalConditionsSubscriptionSubscription__
 *
 * To run a query within a React component, call `useMedicalConditionsSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useMedicalConditionsSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMedicalConditionsSubscriptionSubscription({
 *   variables: {
 *   },
 * });
 */
export function useMedicalConditionsSubscriptionSubscription(
	baseOptions?: Apollo.SubscriptionHookOptions<
		Types.MedicalConditionsSubscriptionSubscription,
		Types.MedicalConditionsSubscriptionSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		Types.MedicalConditionsSubscriptionSubscription,
		Types.MedicalConditionsSubscriptionSubscriptionVariables
	>(MedicalConditionsSubscriptionDocument, options);
}
export type MedicalConditionsSubscriptionSubscriptionHookResult = ReturnType<
	typeof useMedicalConditionsSubscriptionSubscription
>;
export type MedicalConditionsSubscriptionSubscriptionResult =
	Apollo.SubscriptionResult<Types.MedicalConditionsSubscriptionSubscription>;
export const GetYoungPeopleAndLocationStatusDocument = gql`
	query GetYoungPeopleAndLocationStatus(
		$take: Int!
		$skip: Int!
		$where: YoungPersonWhereFilterInput!
	) {
		listYoungPersonLocationStatuses {
			id
			title
			type
			priority
		}
		listYoungPeople(take: $take, skip: $skip, where: $where) {
			...YoungPersonWithLocationStatus
		}
		countYoungPeople(where: $where)
	}
	${YoungPersonWithLocationStatusFragmentDoc}
`;

/**
 * __useGetYoungPeopleAndLocationStatusQuery__
 *
 * To run a query within a React component, call `useGetYoungPeopleAndLocationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPeopleAndLocationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPeopleAndLocationStatusQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetYoungPeopleAndLocationStatusQuery(
	baseOptions: Apollo.QueryHookOptions<
		Types.GetYoungPeopleAndLocationStatusQuery,
		Types.GetYoungPeopleAndLocationStatusQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		Types.GetYoungPeopleAndLocationStatusQuery,
		Types.GetYoungPeopleAndLocationStatusQueryVariables
	>(GetYoungPeopleAndLocationStatusDocument, options);
}
export function useGetYoungPeopleAndLocationStatusLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		Types.GetYoungPeopleAndLocationStatusQuery,
		Types.GetYoungPeopleAndLocationStatusQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		Types.GetYoungPeopleAndLocationStatusQuery,
		Types.GetYoungPeopleAndLocationStatusQueryVariables
	>(GetYoungPeopleAndLocationStatusDocument, options);
}
export type GetYoungPeopleAndLocationStatusQueryHookResult = ReturnType<
	typeof useGetYoungPeopleAndLocationStatusQuery
>;
export type GetYoungPeopleAndLocationStatusLazyQueryHookResult = ReturnType<
	typeof useGetYoungPeopleAndLocationStatusLazyQuery
>;
export type GetYoungPeopleAndLocationStatusQueryResult = Apollo.QueryResult<
	Types.GetYoungPeopleAndLocationStatusQuery,
	Types.GetYoungPeopleAndLocationStatusQueryVariables
>;
export const UpdateYpStatusDocument = gql`
	mutation UpdateYPStatus(
		$id: String!
		$locationStatusId: String!
		$missingAt: AWSDateTime
	) {
		updateYoungPerson(
			where: { id: $id }
			data: { locationStatusId: $locationStatusId, missingAt: $missingAt }
		) {
			...YoungPersonWithLocationStatus
		}
	}
	${YoungPersonWithLocationStatusFragmentDoc}
`;
export type UpdateYpStatusMutationFn = Apollo.MutationFunction<
	Types.UpdateYpStatusMutation,
	Types.UpdateYpStatusMutationVariables
>;

/**
 * __useUpdateYpStatusMutation__
 *
 * To run a mutation, you first call `useUpdateYpStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYpStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYpStatusMutation, { data, loading, error }] = useUpdateYpStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      locationStatusId: // value for 'locationStatusId'
 *      missingAt: // value for 'missingAt'
 *   },
 * });
 */
export function useUpdateYpStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<
		Types.UpdateYpStatusMutation,
		Types.UpdateYpStatusMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		Types.UpdateYpStatusMutation,
		Types.UpdateYpStatusMutationVariables
	>(UpdateYpStatusDocument, options);
}
export type UpdateYpStatusMutationHookResult = ReturnType<
	typeof useUpdateYpStatusMutation
>;
export type UpdateYpStatusMutationResult =
	Apollo.MutationResult<Types.UpdateYpStatusMutation>;
export type UpdateYpStatusMutationOptions = Apollo.BaseMutationOptions<
	Types.UpdateYpStatusMutation,
	Types.UpdateYpStatusMutationVariables
>;
