import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type HouseCardFragment = {
	__typename?: 'House';
	id: string;
	title: string;
	address?: string | null | undefined;
	color?: string | null | undefined;
	preMoveInHouse?: boolean | null | undefined;
};

export type ListHousesWithYoungPeopleQueryVariables = Types.Exact<{
	term?: Types.Maybe<Types.Scalars['String']>;
	skip?: Types.Maybe<Types.Scalars['Int']>;
	take: Types.Scalars['Int'];
}>;

export type ListHousesWithYoungPeopleQuery = {
	__typename?: 'Query';
	countHouses?: number | null | undefined;
	listHouses?:
		| Array<
				| {
						__typename?: 'House';
						id: string;
						title: string;
						address?: string | null | undefined;
						color?: string | null | undefined;
						preMoveInHouse?: boolean | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const HouseCardFragmentDoc = gql`
	fragment HouseCard on House {
		id
		title
		address
		color
		preMoveInHouse
	}
`;
export const ListHousesWithYoungPeopleDocument = gql`
	query ListHousesWithYoungPeople($term: String, $skip: Int, $take: Int!) {
		listHouses(
			where: {
				AND: [
					{ title: { contains: $term } }
					{ preMoveInHouse: { equals: false } }
				]
			}
			skip: $skip
			take: $take
		) {
			...HouseCard
		}
		countHouses(
			where: {
				AND: [
					{ title: { contains: $term } }
					{ preMoveInHouse: { equals: false } }
				]
			}
		)
	}
	${HouseCardFragmentDoc}
`;

/**
 * __useListHousesWithYoungPeopleQuery__
 *
 * To run a query within a React component, call `useListHousesWithYoungPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHousesWithYoungPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHousesWithYoungPeopleQuery({
 *   variables: {
 *      term: // value for 'term'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListHousesWithYoungPeopleQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListHousesWithYoungPeopleQuery,
		ListHousesWithYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		ListHousesWithYoungPeopleQuery,
		ListHousesWithYoungPeopleQueryVariables
	>(ListHousesWithYoungPeopleDocument, options);
}
export function useListHousesWithYoungPeopleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListHousesWithYoungPeopleQuery,
		ListHousesWithYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ListHousesWithYoungPeopleQuery,
		ListHousesWithYoungPeopleQueryVariables
	>(ListHousesWithYoungPeopleDocument, options);
}
export type ListHousesWithYoungPeopleQueryHookResult = ReturnType<
	typeof useListHousesWithYoungPeopleQuery
>;
export type ListHousesWithYoungPeopleLazyQueryHookResult = ReturnType<
	typeof useListHousesWithYoungPeopleLazyQuery
>;
export type ListHousesWithYoungPeopleQueryResult = Apollo.QueryResult<
	ListHousesWithYoungPeopleQuery,
	ListHousesWithYoungPeopleQueryVariables
>;
