import React, { useEffect, useState } from 'react';

import {
	Button,
	CircularProgress,
	createStyles,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import FormFieldsComponent, {
	FormFieldsComponentProps,
} from '../FormFieldsComponent';

export type FormComponentProps = {
	title: string;
	fields: FormFieldsComponentProps['fields'];
	onSubmit: <FormValues>(value: FormValues) => Promise<FormValues>;
	onChange?: <FormValues>() => { values: FormValues; errors: object };
	defaultValues?: object;
	submitCopy?: string;
	successCopy?: string;
	onSuccess?: () => void;
	onFail?: (error: Error) => void;
	disableErrorReset?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			textAlign: 'center',
			width: '320px',
		},
		errorMessage: {
			color: theme.palette.error.main,
			marginBottom: theme.spacing('md'),
		},
		text: {
			marginBottom: theme.spacing('md'),
		},
	})
);

const FormComponent: React.FC<FormComponentProps> = (props) => {
	const { handleSubmit, reset, control } = useForm({
		defaultValues: props.defaultValues,
	});
	const [submitLoading, setSubmitLoading] = useState<boolean>(false);
	const [formError, setFormError] = useState<Error | undefined>(undefined);
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		// If these change then the error should reset when fields change but only if the disableErrorReset allows for it
		if (!props.disableErrorReset) setFormError(undefined);
		// Reset the form
		reset(props.defaultValues);
		setSubmitLoading(false);
	}, [props.fields, props.defaultValues, props.disableErrorReset, reset]);

	const onSubmit = (value: any) => {
		setSubmitLoading(true);

		props
			.onSubmit(value)
			.then(() => {
				enqueueSnackbar(
					props.successCopy ?? 'The form was completed successfully!',
					{
						variant: 'success',
						anchorOrigin: { vertical: 'top', horizontal: 'right' },
					}
				);
				if (props.onSuccess) props.onSuccess();
			})
			.catch((error) => {
				setFormError(error);
				if (props.onFail) props.onFail(error);
			})
			.finally(() => setSubmitLoading(false));
	};

	return (
		<>
			<form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
				<Typography className={classes.text} component="h5" variant="h5">
					{props.title}
				</Typography>
				<FormFieldsComponent control={control} fields={props.fields} />
				{formError ? (
					<Typography className={classes.errorMessage} component="body">
						{formError.message}
					</Typography>
				) : null}
				<Button
					color="primary"
					variant="contained"
					type="submit"
					disabled={submitLoading}
				>
					{!submitLoading ? (
						props.submitCopy ?? 'Submit'
					) : (
						<CircularProgress size={24} />
					)}
				</Button>
			</form>
		</>
	);
};

export default FormComponent;
