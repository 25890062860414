import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ListRoomsQueryVariables = Types.Exact<{
	houseId?: Types.Maybe<Types.Scalars['String']>;
}>;

export type ListRoomsQuery = {
	__typename?: 'Query';
	listRooms?:
		| Array<
				| {
						__typename?: 'Room';
						id: string;
						name: string;
						color: string;
						youngPerson?:
							| { __typename?: 'YoungPerson'; name: string }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const ListRoomsDocument = gql`
	query ListRooms($houseId: String) {
		listRooms(where: { houseId: { equals: $houseId } }) {
			id
			name
			color
			youngPerson {
				name
			}
		}
	}
`;

/**
 * __useListRoomsQuery__
 *
 * To run a query within a React component, call `useListRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRoomsQuery({
 *   variables: {
 *      houseId: // value for 'houseId'
 *   },
 * });
 */
export function useListRoomsQuery(
	baseOptions?: Apollo.QueryHookOptions<ListRoomsQuery, ListRoomsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ListRoomsQuery, ListRoomsQueryVariables>(
		ListRoomsDocument,
		options
	);
}
export function useListRoomsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListRoomsQuery,
		ListRoomsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ListRoomsQuery, ListRoomsQueryVariables>(
		ListRoomsDocument,
		options
	);
}
export type ListRoomsQueryHookResult = ReturnType<typeof useListRoomsQuery>;
export type ListRoomsLazyQueryHookResult = ReturnType<
	typeof useListRoomsLazyQuery
>;
export type ListRoomsQueryResult = Apollo.QueryResult<
	ListRoomsQuery,
	ListRoomsQueryVariables
>;
