import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type YoungPersonWithLocationStatusFragment = {
	__typename?: 'YoungPerson';
	id: string;
	name: string;
	avatar?: string | null | undefined;
	locationStatusId?: string | null | undefined;
	missingAt?: any | null | undefined;
	locationStatus?:
		| {
				__typename?: 'YoungPersonLocationStatus';
				id: string;
				title: string;
				type: Types.YoungPersonLocationStatusType;
				priority?: number | null | undefined;
		  }
		| null
		| undefined;
};

export type GetYoungPeopleAndLocationStatusQueryVariables = Types.Exact<{
	take: Types.Scalars['Int'];
	skip: Types.Scalars['Int'];
	where: Types.YoungPersonWhereFilterInput;
}>;

export type GetYoungPeopleAndLocationStatusQuery = {
	__typename?: 'Query';
	countYoungPeople?: number | null | undefined;
	listYoungPersonLocationStatuses?:
		| Array<
				| {
						__typename?: 'YoungPersonLocationStatus';
						id: string;
						title: string;
						type: Types.YoungPersonLocationStatusType;
						priority?: number | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
	listYoungPeople?:
		| Array<
				| {
						__typename?: 'YoungPerson';
						id: string;
						name: string;
						avatar?: string | null | undefined;
						locationStatusId?: string | null | undefined;
						missingAt?: any | null | undefined;
						locationStatus?:
							| {
									__typename?: 'YoungPersonLocationStatus';
									id: string;
									title: string;
									type: Types.YoungPersonLocationStatusType;
									priority?: number | null | undefined;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type UpdateYpStatusMutationVariables = Types.Exact<{
	id: Types.Scalars['String'];
	locationStatusId: Types.Scalars['String'];
	missingAt?: Types.Maybe<Types.Scalars['AWSDateTime']>;
}>;

export type UpdateYpStatusMutation = {
	__typename?: 'Mutation';
	updateYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				avatar?: string | null | undefined;
				locationStatusId?: string | null | undefined;
				missingAt?: any | null | undefined;
				locationStatus?:
					| {
							__typename?: 'YoungPersonLocationStatus';
							id: string;
							title: string;
							type: Types.YoungPersonLocationStatusType;
							priority?: number | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const YoungPersonWithLocationStatusFragmentDoc = gql`
	fragment YoungPersonWithLocationStatus on YoungPerson {
		id
		name
		avatar
		locationStatusId
		missingAt
		locationStatus {
			id
			title
			type
			priority
		}
	}
`;
export const GetYoungPeopleAndLocationStatusDocument = gql`
	query GetYoungPeopleAndLocationStatus(
		$take: Int!
		$skip: Int!
		$where: YoungPersonWhereFilterInput!
	) {
		listYoungPersonLocationStatuses {
			id
			title
			type
			priority
		}
		listYoungPeople(take: $take, skip: $skip, where: $where) {
			...YoungPersonWithLocationStatus
		}
		countYoungPeople(where: $where)
	}
	${YoungPersonWithLocationStatusFragmentDoc}
`;

/**
 * __useGetYoungPeopleAndLocationStatusQuery__
 *
 * To run a query within a React component, call `useGetYoungPeopleAndLocationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPeopleAndLocationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPeopleAndLocationStatusQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetYoungPeopleAndLocationStatusQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYoungPeopleAndLocationStatusQuery,
		GetYoungPeopleAndLocationStatusQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetYoungPeopleAndLocationStatusQuery,
		GetYoungPeopleAndLocationStatusQueryVariables
	>(GetYoungPeopleAndLocationStatusDocument, options);
}
export function useGetYoungPeopleAndLocationStatusLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYoungPeopleAndLocationStatusQuery,
		GetYoungPeopleAndLocationStatusQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetYoungPeopleAndLocationStatusQuery,
		GetYoungPeopleAndLocationStatusQueryVariables
	>(GetYoungPeopleAndLocationStatusDocument, options);
}
export type GetYoungPeopleAndLocationStatusQueryHookResult = ReturnType<
	typeof useGetYoungPeopleAndLocationStatusQuery
>;
export type GetYoungPeopleAndLocationStatusLazyQueryHookResult = ReturnType<
	typeof useGetYoungPeopleAndLocationStatusLazyQuery
>;
export type GetYoungPeopleAndLocationStatusQueryResult = Apollo.QueryResult<
	GetYoungPeopleAndLocationStatusQuery,
	GetYoungPeopleAndLocationStatusQueryVariables
>;
export const UpdateYpStatusDocument = gql`
	mutation UpdateYPStatus(
		$id: String!
		$locationStatusId: String!
		$missingAt: AWSDateTime
	) {
		updateYoungPerson(
			where: { id: $id }
			data: { locationStatusId: $locationStatusId, missingAt: $missingAt }
		) {
			...YoungPersonWithLocationStatus
		}
	}
	${YoungPersonWithLocationStatusFragmentDoc}
`;
export type UpdateYpStatusMutationFn = Apollo.MutationFunction<
	UpdateYpStatusMutation,
	UpdateYpStatusMutationVariables
>;

/**
 * __useUpdateYpStatusMutation__
 *
 * To run a mutation, you first call `useUpdateYpStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYpStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYpStatusMutation, { data, loading, error }] = useUpdateYpStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      locationStatusId: // value for 'locationStatusId'
 *      missingAt: // value for 'missingAt'
 *   },
 * });
 */
export function useUpdateYpStatusMutation(
	baseOptions?: Apollo.MutationHookOptions<
		UpdateYpStatusMutation,
		UpdateYpStatusMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		UpdateYpStatusMutation,
		UpdateYpStatusMutationVariables
	>(UpdateYpStatusDocument, options);
}
export type UpdateYpStatusMutationHookResult = ReturnType<
	typeof useUpdateYpStatusMutation
>;
export type UpdateYpStatusMutationResult =
	Apollo.MutationResult<UpdateYpStatusMutation>;
export type UpdateYpStatusMutationOptions = Apollo.BaseMutationOptions<
	UpdateYpStatusMutation,
	UpdateYpStatusMutationVariables
>;
