import React from 'react';
import { ApolloError } from '@apollo/client';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	makeStyles,
	Theme,
	useTheme,
	Box,
} from '@material-ui/core';

// Components
import DetailsCardComponent, {
	DetailsType,
} from '../../components/DetailsCardComponent';
import FallbackComponent from '../../components/FallbackComponent';

// Queries
import { GetYoungPersonQueryHookResult } from './query.generated';

// Configs
import BasicDetailsCard from './config/BasicDetails';
import LegalDetailsCard from './config/LegalDetails';
import NextOfKinDetailsCard from './config/NextOfKinDetails';
import MedicalDetailsCard from './config/MedicalDetails';
import RiskAssessmentDetailsCard from './config/RiskAssessmentDetails';
import AboutMeDetailsCard from './config/AboutMeDetails';

// Types
import { FormType } from '../../graphql/types';

// Contexts
import { Can } from '../../contexts/CanContext';

// Styles
import Masonry from 'react-masonry-css';
import { RiskAssessmentData } from '../../types';
const useStyles = makeStyles((theme: Theme) => ({
	masonry: {
		display: 'flex',
		marginLeft: 0,
		gap: theme.spacing('lg'),
	},
	masonryCol: {
		backgroundClip: 'padding-box',
	},
	masonryItem: {
		marginBottom: theme.spacing('lg'),
	},
}));

export type PopupContent = {
	title: string;
	copy: string;
	primaryAction: PopupButton;
	secondaryAction: PopupButton;
};

type PopupButton = {
	copy: string;
	onClick: () => void;
};

export type YoungPersonProfileViewProps = {
	data: GetYoungPersonQueryHookResult['data'];
	loading?: boolean;
	error?: ApolloError;
	popupContent?: PopupContent;
	openPopup?: boolean;
	setOpenPopup?: (open: boolean) => void;
	onActionClick: (
		formType: FormType,
		actionType: 'ADD' | 'EDIT' | 'DELETE',
		id?: string
	) => void;
	deleteNextOfKin: (id: string) => void;
	deleteMedicalCondition: (id: string) => void;
};

const YoungPersonProfileView: React.FC<YoungPersonProfileViewProps> = (
	props
) => {
	const classes = useStyles();
	const theme = useTheme();

	const youngPerson = props?.data?.getYoungPerson;
	let riskAssessment: RiskAssessmentData = undefined;
	if (youngPerson?.riskAssessment) {
		riskAssessment = JSON.parse(youngPerson?.riskAssessment);
	}

	const activityHref = `/kids/${youngPerson?.id}/activity?type=RISK_ASSESSMENT`;

	return (
		<Box minHeight="25rem">
			<FallbackComponent
				isLoading={Boolean(props.loading)}
				hasError={Boolean(props.error)}
				errorTitle="An Error Occurred"
				errorCopy="We had a little trouble fetching the young person's details. If the problem persists, please contact your system administrator"
				isEmpty={!youngPerson}
				emptyTitle="No Details Found"
				emptyCopy="We had a little trouble fetching the young person's details. If the problem persists, please contact your system administrator"
			>
				{youngPerson && (
					<Can I="read" this={youngPerson}>
						<Masonry
							columnClassName={classes.masonryCol}
							className={classes.masonry}
							breakpointCols={{
								default: 2,
								[theme.breakpoints.values.lg]: 1,
							}}
						>
							<MasonryItem>
								<DetailsCardComponent
									type={DetailsType.SINGLE}
									title="Basic Information"
									details={BasicDetailsCard(props.data)}
									canEdit={true}
									onEditClick={() =>
										props.onActionClick(FormType.YpBasicDetails, 'EDIT')
									}
								/>
							</MasonryItem>

							<MasonryItem>
								<DetailsCardComponent
									type={DetailsType.SINGLE}
									title="About Me"
									details={AboutMeDetailsCard(props.data)}
									canEdit={true}
									onEditClick={() =>
										props.onActionClick(FormType.YpAboutMe, 'EDIT')
									}
								/>
							</MasonryItem>
							<MasonryItem>
								<DetailsCardComponent
									type={DetailsType.SINGLE}
									title="Legal Details"
									details={LegalDetailsCard(props.data)}
									canEdit={true}
									onEditClick={() =>
										props.onActionClick(FormType.YpLegalDetails, 'EDIT')
									}
								/>
							</MasonryItem>
							<MasonryItem>
								<DetailsCardComponent
									title="Contacts"
									noDataTitle={`No Contacts Recorded`}
									noDataCopy=""
									type={DetailsType.ACCORDION}
									details={NextOfKinDetailsCard(props.data)}
									canAdd={true}
									onAddClick={() =>
										props.onActionClick(FormType.YpNextOfKin, 'ADD')
									}
									onDeleteClick={(id) =>
										props.onActionClick(FormType.YpNextOfKin, 'DELETE', id)
									}
									onEditClick={(id) =>
										props.onActionClick(FormType.YpNextOfKin, 'EDIT', id)
									}
								/>
							</MasonryItem>
							<MasonryItem>
								<DetailsCardComponent
									title="Medical Conditions"
									noDataTitle={`No Medical Conditions Recorded`}
									noDataCopy=""
									type={DetailsType.ACCORDION}
									details={MedicalDetailsCard(props.data)}
									canAdd={true}
									onAddClick={() =>
										props.onActionClick(FormType.YpMedicalDetails, 'ADD')
									}
									onDeleteClick={(id) =>
										props.onActionClick(FormType.YpMedicalDetails, 'DELETE', id)
									}
									onEditClick={(id) =>
										props.onActionClick(FormType.YpMedicalDetails, 'EDIT', id)
									}
								/>
							</MasonryItem>
							<MasonryItem>
								<DetailsCardComponent
									type={DetailsType.SINGLE}
									title="Risk Assessment"
									headerLink={{
										text: '(See previous assessments)',
										href: activityHref,
									}}
									details={RiskAssessmentDetailsCard(riskAssessment)}
									canEdit={true}
									onEditClick={() =>
										props.onActionClick(FormType.YpRiskAssessment, 'EDIT')
									}
								/>
							</MasonryItem>
						</Masonry>
						<Dialog
							open={props.openPopup ?? false}
							onClose={() => props?.setOpenPopup?.(false)}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">
								{props.popupContent?.title}
							</DialogTitle>
							<DialogContent>
								<DialogContentText id="alert-dialog-description">
									{props.popupContent?.copy}
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button onClick={props.popupContent?.secondaryAction.onClick}>
									{props.popupContent?.secondaryAction.copy}
								</Button>
								<Button
									onClick={props.popupContent?.primaryAction.onClick}
									variant="contained"
									color="primary"
								>
									{props.popupContent?.primaryAction.copy}
								</Button>
							</DialogActions>
						</Dialog>
					</Can>
				)}
			</FallbackComponent>
		</Box>
	);
};

const MasonryItem: React.FC = (props) => {
	const { masonryItem } = useStyles();

	return (
		<div role="presentation" className={masonryItem}>
			{props.children}
		</div>
	);
};

export default YoungPersonProfileView;
