import { useContext, useEffect, useState } from 'react';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { useTitle } from 'react-use';

import ModalContext, { ModalType } from '../../contexts/ModalContext';

import { YoungPersonLocationStatusType, FormType } from '../../graphql/types';
import {
	GetYoungPersonByIdSubDocument,
	useGetYoungPersonByIdQuery,
} from './query.generated';
import { useUpdateYpStatusMutation } from '../../views/YoungPersonWhereaboutView/query.generated';
import YoungPersonScreen from './YoungPersonScreen';

type HomeScreenContainerProps = {};

type Params = {
	youngPersonId: string;
	houseId: string;
};
const YoungPersonScreenContainer = (props: HomeScreenContainerProps) => {
	const { youngPersonId } = useParams<Params>();
	const { url } = useRouteMatch();
	const { isOpen: isModalOpen } = useContext(ModalContext);
	const history = useHistory();

	// Get current location to manage the tabs
	let defaultTab: number = 0;
	const href = window.location.href;
	if (href.includes('tasks')) defaultTab = 0;
	else if (href.includes('details')) defaultTab = 1;
	else if (href.includes('activity')) defaultTab = 2;
	else if (href.includes('files')) defaultTab = 3;

	const [tab, setTab] = useState(defaultTab);

	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setTab(newValue);
		switch (newValue) {
			case 0:
				history.push(`${url}/tasks`);
				break;
			case 1:
				history.push(`${url}/details`);
				break;
			case 2:
				history.push(`${url}/activity`);
				break;
			case 3:
				history.push(`${url}/files`);
				break;
		}
	};
	const modal = useContext(ModalContext);
	const {
		data,
		loading,
		error,
		refetch,
		subscribeToMore = () => null,
	} = useGetYoungPersonByIdQuery({
		variables: {
			id: youngPersonId,
		},
	});

	useEffect(() => {
		if (!isModalOpen && !loading) refetch();
	}, [isModalOpen]);

	useEffect(() => {
		subscribeToMore({
			document: GetYoungPersonByIdSubDocument,
			variables: {
				id: youngPersonId,
			},
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [youngPersonId]);

	useTitle(`STELLA | ${data?.getYoungPerson?.name}` ?? 'STELLA | Kid');

	const [updateLocationStatus] = useUpdateYpStatusMutation();

	/**
	 * @description updates the young person location for whereabout service
	 * @author Mike Rudge
	 * @date 28/10/2021
	 */
	const handleOnChangeStatus = async (statusId: string) => {
		if (!youngPersonId || !statusId) return;

		const newStatus = data?.listYoungPersonLocationStatuses?.find(
			(status) => status?.id === statusId
		);

		if (!newStatus?.id) {
			console.error('No status id');
			return;
		}

		// Don't trigger a status change if there is no change in their status
		if (newStatus?.id === data?.getYoungPerson?.locationStatus?.id) return;

		// If the young person changes to Out / Unauthorized / Home / Authorized open to make a note of this before changing status
		if (
			newStatus?.type === YoungPersonLocationStatusType.Out ||
			newStatus?.type === YoungPersonLocationStatusType.Authorised ||
			newStatus?.type === YoungPersonLocationStatusType.Home ||
			newStatus?.type === YoungPersonLocationStatusType.Unauthorised ||
			newStatus?.type === YoungPersonLocationStatusType.Missing ||
			data?.getYoungPerson?.locationStatus?.title === 'Missing'
		) {
			return modal.open({
				type: ModalType.FORM,
				args: {
					type: FormType.LogLocation,
					youngPersonId: youngPersonId,
					updatedStatusId: newStatus?.id ?? '',
					updatedStatusType: newStatus?.type ?? '',
				},
			});
		} else {
			// if they are home, log them as such
			const didFetchName = typeof data?.getYoungPerson?.name === 'string';
			const didFetchId =
				typeof data?.getYoungPerson?.id === 'string' &&
				data?.getYoungPerson?.id.length > 1;

			if (!didFetchName || !didFetchId) {
				console.error('No id or name found for young person');
				return;
			}

			await updateLocationStatus({
				variables: {
					id: youngPersonId,
					locationStatusId: newStatus?.id,
				},
				optimisticResponse: {
					updateYoungPerson: {
						...data?.getYoungPerson,
						id: youngPersonId,
						name: data?.getYoungPerson?.name ?? '',
						locationStatus: newStatus,
						missingAt: null,
					},
				},
			});
		}
	};

	return (
		<YoungPersonScreen
			data={data}
			loading={loading}
			error={error}
			tab={tab}
			onChangeTab={handleTabChange}
			onChangeLocationStatus={handleOnChangeStatus}
		/>
	);
};

export default YoungPersonScreenContainer;
