import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import FallbackComponent, { ErrorType } from '../FallbackComponent';
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core';
import DetailsBlockComponent, {
	DetailsBlockContainer,
} from '../DetailsBlockComponent';

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.primary.pale,
		},
		map: {
			borderRadius: theme.shape.borderRadius,
			width: '100%',
		},
	})
);

export type MapCardProps = {
	address: string;
	tel: string;
	coords: {
		lat: number | null;
		long: number | null;
	};
};

const MapCardComponent: React.FC<MapCardProps> = (props) => {
	const styles = useStyles();
	const theme = useTheme();

	const pinColour = theme.palette.primary.main.replace('#', '');

	const coordsAreValid = props.coords.lat && props.coords.long;

	return (
		<Paper className={styles.root}>
			<FallbackComponent
				hasError={!coordsAreValid}
				errorType={ErrorType.NOT_FOUND}
				errorTitle="An Error Occurred"
				errorCopy={
					'We had a little trouble fetching the map. Please contact your system administrator if the problem persists.'
				}
			>
				<img
					className={styles.map}
					src={`https://api.mapbox.com/styles/v1/mapbox/light-v10/static/pin-s+${pinColour}(${props.coords.long},${props.coords.lat})/${props.coords.long},${props.coords.lat},12.93,0/800x400?access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`}
					alt="map"
				/>
			</FallbackComponent>
			<Box padding={theme.spacing('sm')}>
				<DetailsBlockContainer>
					<DetailsBlockComponent label="Address">
						<address style={{ fontStyle: 'normal' }}>{props.address}</address>
					</DetailsBlockComponent>
					<DetailsBlockComponent label="Contact No.">
						<Link color="primary" href={`tel:${props.tel}`}>
							{props.tel}
						</Link>
					</DetailsBlockComponent>
				</DetailsBlockContainer>
			</Box>
		</Paper>
	);
};

export default MapCardComponent;
