import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type LogFragmentFragment = {
	__typename?: 'Log';
	id: string;
	type: Types.LogType;
	data: any;
	createdAt?: any | null | undefined;
	youngPerson?:
		| {
				__typename?: 'YoungPerson';
				name: string;
				house?: { __typename?: 'House'; title: string } | null | undefined;
		  }
		| null
		| undefined;
};

export type ListLogsQueryVariables = Types.Exact<{
	skip?: Types.Maybe<Types.Scalars['Int']>;
	take: Types.Scalars['Int'];
	where?: Types.Maybe<Types.LogWhereFilterInput>;
}>;

export type ListLogsQuery = {
	__typename?: 'Query';
	countLogs?: number | null | undefined;
	listLogs?:
		| Array<
				| {
						__typename?: 'Log';
						id: string;
						type: Types.LogType;
						data: any;
						createdAt?: any | null | undefined;
						youngPerson?:
							| {
									__typename?: 'YoungPerson';
									name: string;
									house?:
										| { __typename?: 'House'; title: string }
										| null
										| undefined;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const LogFragmentFragmentDoc = gql`
	fragment LogFragment on Log {
		id
		type
		data
		createdAt
		youngPerson {
			name
			house {
				title
			}
		}
	}
`;
export const ListLogsDocument = gql`
	query ListLogs($skip: Int, $take: Int!, $where: LogWhereFilterInput) {
		listLogs(
			skip: $skip
			take: $take
			where: $where
			orderBy: [{ createdAt: DESC }]
		) {
			...LogFragment
		}
		countLogs(where: $where)
	}
	${LogFragmentFragmentDoc}
`;

/**
 * __useListLogsQuery__
 *
 * To run a query within a React component, call `useListLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLogsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListLogsQuery(
	baseOptions: Apollo.QueryHookOptions<ListLogsQuery, ListLogsQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ListLogsQuery, ListLogsQueryVariables>(
		ListLogsDocument,
		options
	);
}
export function useListLogsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListLogsQuery,
		ListLogsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ListLogsQuery, ListLogsQueryVariables>(
		ListLogsDocument,
		options
	);
}
export type ListLogsQueryHookResult = ReturnType<typeof useListLogsQuery>;
export type ListLogsLazyQueryHookResult = ReturnType<
	typeof useListLogsLazyQuery
>;
export type ListLogsQueryResult = Apollo.QueryResult<
	ListLogsQuery,
	ListLogsQueryVariables
>;
