import React from 'react';
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core';

export type DialogComponentProps = {
	isOpen: boolean;
	onGoBack?: () => void;
	onClose?: () => void;
};

const DialogComponent: React.FC<DialogComponentProps> = ({
	isOpen,
	onGoBack,
	onClose,
}) => (
	<Dialog open={isOpen}>
		<DialogTitle>Close the form?</DialogTitle>
		<DialogContent>
			<DialogContentText>
				Any information you have entered will be lost.
			</DialogContentText>
			<DialogActions>
				<Button variant="text" onClick={onGoBack}>
					Go Back
				</Button>
				<Button variant="outlined" color="primary" onClick={onClose}>
					Close
				</Button>
			</DialogActions>
		</DialogContent>
	</Dialog>
);

export default DialogComponent;
