import dayjs from 'dayjs';

// Query
import { ListAdminUsersDocument, ListAdminUsersQuery } from './query.generated';
import {
	LogFragmentFragmentDoc,
	UpdateActivityLogDocument,
	CreateActivityLogDocument,
	FormListYoungPeopleDocument,
	GetActivityLogDocument,
} from '../../../../graphql/hooks';
import {
	GetActivityLogQuery,
	GetActivityLogQueryVariables,
	FormListYoungPeopleQuery,
	FormListYoungPeopleQueryVariables,
	CreateActivityLogMutation,
	CreateActivityLogMutationVariables,
	UpdateActivityLogMutation,
	UpdateActivityLogMutationVariables,
	ListAdminUsersQueryVariables,
} from '../../../../graphql/types';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { ManagerOnDutyArgs } from '../../types';
import { LogType } from '../../../../graphql/types';
import { ManagerOnDutyLogData } from '../../../../types';

export type ManagerOnDutyFormValues = {
	youngPersonId: string;
	managerId: string;
	date: string;
	reason: string;
	adviceGiven?: string;
	note?: string;
};

const PreFetch = async (
	args: ManagerOnDutyArgs,
	client: ApolloClient<object>
) => {
	let log: ApolloQueryResult<GetActivityLogQuery> | undefined = undefined;
	let yp: ApolloQueryResult<FormListYoungPeopleQuery> | undefined = undefined;
	let admins: ApolloQueryResult<ListAdminUsersQuery> | undefined = undefined;

	if (args.id) {
		log = await client.query<GetActivityLogQuery, GetActivityLogQueryVariables>(
			{
				query: GetActivityLogDocument,
				variables: {
					id: args.id,
				},
			}
		);

		if (!log.data.getLog || log.error)
			throw new Error('Pre-fetched Data Failed');
	}

	if (args.houseId) {
		const where = args.houseId
			? {
					house: {
						id: {
							equals: args.houseId,
						},
					},
			  }
			: {};

		yp = await client.query<
			FormListYoungPeopleQuery,
			FormListYoungPeopleQueryVariables
		>({
			query: FormListYoungPeopleDocument,
			variables: {
				where,
			},
		});

		if (!yp.data.listYoungPeople || yp.error)
			throw new Error('Pre-fetched Data Failed');
	}

	admins = await client.query<
		ListAdminUsersQuery,
		ListAdminUsersQueryVariables
	>({
		query: ListAdminUsersDocument,
	});

	if (!admins.data.listUsers || admins.error)
		throw new Error('Pre-fetched Data Failed');

	return {
		log: log?.data.getLog ?? undefined,
		youngPerson: yp?.data.listYoungPeople ?? undefined,
		admins: admins?.data.listUsers ?? undefined,
	};
};

type Data = {
	log: GetActivityLogQuery['getLog'] | undefined;
	youngPerson: FormListYoungPeopleQuery['listYoungPeople'] | undefined;
	admins: ListAdminUsersQuery['listUsers'] | undefined;
};

const FormFields = (
	args: ManagerOnDutyArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const formData: ManagerOnDutyLogData | undefined = data.log?.data
		? JSON.parse(data.log?.data)
		: undefined;

	const ypOptions = data.youngPerson?.map((yp) => {
		return { value: yp?.id ?? '', copy: yp?.name ?? '' };
	});
	const mangerOptions = data.admins?.map((manager: any) => {
		return {
			value: `${manager?.id}||${manager?.name}` ?? '',
			copy: manager?.name ?? '',
		};
	});

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'youngPersonId',
			type: FormFieldTypes.SELECT,
			label: 'Kid:',
			config: {
				defaultValue: data.log?.youngPerson?.id ?? '',
			},
			validation: {
				required: true,
			},
			options: ypOptions ?? [],
		},
		{
			id: 'date',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Date:',
				defaultValue:
					dayjs(formData?.date).format('YYYY-MM-DD') ??
					dayjs(Date.now()).format('YYYY-MM-DD'),
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'managerId',
			type: FormFieldTypes.SELECT,
			label: 'Manager:',
			config: {
				defaultValue: '',
			},
			validation: {
				required: true,
			},
			options: mangerOptions ?? [],
		},
		{
			id: 'reason',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Reason for Callout:',
				multiline: true,
				rowsMax: 5,
				defaultValue: formData?.reason ?? '',
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'adviceGiven',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Advice Given:',
				multiline: true,
				rowsMax: 5,
				defaultValue: formData?.adviceGiven ?? '',
			},
			validation: {},
		},
		{
			id: 'note',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Note:',
				multiline: true,
				rowsMax: 5,
				defaultValue: formData?.note ?? '',
			},
			validation: {},
		},
	];

	return [fields];
};

const GenerateOnSubmit = async (
	args: ManagerOnDutyArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (
		formValues: ManagerOnDutyFormValues
	): Promise<any> => {
		if (args.id) {
			const updateLog = await client.mutate<
				UpdateActivityLogMutation,
				UpdateActivityLogMutationVariables
			>({
				mutation: UpdateActivityLogDocument,
				variables: {
					id: args.id ?? '',
					data: {
						data: JSON.stringify({
							note: formValues.note,
							date: dayjs(formValues.date).toISOString(),
						}),
					},
				},
			});
			return updateLog;
		} else {
			const createLog = await client.mutate<
				CreateActivityLogMutation,
				CreateActivityLogMutationVariables
			>({
				mutation: CreateActivityLogDocument,
				variables: {
					data: {
						type: LogType.MangerOnDuty,
						youngPersonId: formValues.youngPersonId ?? '',
						createdBy: userId,
						data: JSON.stringify({
							...{
								...formValues,
								youngPersonId: undefined,
								managerId: formValues.managerId.split('||')[0],
								managerName: formValues.managerId.split('||')[1],
							},
						}),
					},
				},
				refetchQueries: [LogFragmentFragmentDoc, 'ListLogs'],
			});

			return createLog;
		}
	};
	return onSubmit;
};

const ManagerOnDutyForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default ManagerOnDutyForm;
