import React from 'react';
import { CloudDownload } from '@material-ui/icons';
import {
	createStyles,
	makeStyles,
	Paper,
	Theme,
	Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		downloadTemplate: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: theme.spacing('xs'),
			width: '100%',
			cursor: 'pointer',
			transition: 'background 0.3s',
			background: theme.palette.grey[200],
			'&:hover': {
				background: theme.palette.grey[100],
			},
		},
		disabled: {
			cursor: 'not-allowed',
			background: theme.palette.grey[100],
			'&:hover': {
				background: theme.palette.grey[100],
			},
		},
	})
);

export type DownloadFileComponentProps = {
	onClick: () => void;
	disabled?: boolean;
	downloadCopy?: string;
};

const DownloadFileComponent: React.FC<DownloadFileComponentProps> = (props) => {
	const classes = useStyles();

	const onClick = () => {
		if (props.disabled) return;
		props.onClick();
	};

	return (
		<Paper
			className={`${classes.downloadTemplate} ${
				props.disabled ? classes.disabled : ''
			}`}
			elevation={0}
			onClick={onClick}
		>
			<Typography variant="body1" color="textSecondary">
				{props.downloadCopy ?? 'Download template'}
			</Typography>
			<CloudDownload />
		</Paper>
	);
};

export default DownloadFileComponent;
