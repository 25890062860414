import { useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import dayjs from 'dayjs';

// Views
import YoungPersonAssignHouseModalView, {
	FormValues,
} from './YoungPersonAssignHouseModalView';

// Queries
import {
	useAssignHouseMutation,
	useListHousesSelectQuery,
	useListRoomsSelectQuery,
} from './query.generated';

type YoungPersonAssignHouseModalViewContainerProps = {
	youngPersonId?: string;
};

const YoungPersonAssignHouseModalViewContainer: React.FC<YoungPersonAssignHouseModalViewContainerProps> =
	(props) => {
		const [loading, setLoading] = useState<boolean>(true);
		const [error, setError] = useState<ApolloError>();
		const [updateYoungPerson] = useAssignHouseMutation();

		const {
			data: houseData,
			error: houseError,
			loading: houseLoading,
		} = useListHousesSelectQuery();

		const {
			data: roomData,
			error: roomError,
			loading: roomLoading,
		} = useListRoomsSelectQuery();

		useEffect(() => {
			if (!houseLoading && !roomLoading) setLoading(false);
		}, [houseLoading, roomLoading]);

		useEffect(() => {
			if (roomError) setError(roomError);
			if (houseError) setError(houseError);
		}, [houseError, roomError]);

		const onFormSubmit = async (values: FormValues) => {
			updateYoungPerson({
				variables: {
					youngPersonId: props.youngPersonId ?? '',
					status: values.status,
					houseId: values.house,
					roomId: values.room,
					moveIn: values.moveInDate
						? dayjs(values.moveInDate).format('YYYY-MM-DDThh:mm:ss.sssZ')
						: undefined,
				},
			});
		};

		return (
			<YoungPersonAssignHouseModalView
				rooms={roomData?.listRooms}
				houses={houseData?.listHouses}
				onFormSubmit={onFormSubmit}
				loading={loading}
				error={error}
			/>
		);
	};

export default YoungPersonAssignHouseModalViewContainer;
