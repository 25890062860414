import * as React from 'react';
import SelectCardComponent from '../SelectCardComponent';

export type FileCardProps = {
	label: string;
	onClick?: () => void;
	isUploaded: boolean;
};

const FileCardComponent: React.FC<FileCardProps> = (props) => (
	<SelectCardComponent
		checked={props.isUploaded}
		label={props.label}
		onClick={props.onClick}
	/>
);

export default FileCardComponent;
