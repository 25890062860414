// Query
import {
	GetUserDocument,
	GetUserQuery,
	GetUserQueryVariables,
	UpdateUserDocument,
	UpdateUserMutation,
	UpdateUserMutationVariables,
	UserDetailsFragmentDoc,
} from './query.generated';

import { ListHouseIdsDocument } from '../../../../graphql/hooks';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient } from '@apollo/client';
import { UserUpdateArgs } from '../../types';
import {
	ListHouseIdsQuery,
	ListHouseIdsQueryVariables,
	Role,
} from '../../../../graphql/types';

export type UserFormValues = {
	firstName?: string;
	lastName?: string;
	phone?: string;
	address?: string;
	houseId?: string;
	role?: Role;
	currentPassword?: string;
	newPassword?: string;
	repeatNewPassword?: string;
};

const PreFetch = async (args: UserUpdateArgs, client: ApolloClient<object>) => {
	if (args.userId) {
		const user = await client.query<GetUserQuery, GetUserQueryVariables>({
			query: GetUserDocument,
			variables: {
				id: args.userId,
			},
		});

		const houses = await client.query<
			ListHouseIdsQuery,
			ListHouseIdsQueryVariables
		>({
			query: ListHouseIdsDocument,
		});

		if (!user.data.getUser || user.error)
			throw new Error('Pre-fetched Data Failed');

		return { user: user?.data.getUser, houses: houses?.data.listHouses };
	}

	return undefined;
};

type Data =
	| {
			user: GetUserQuery['getUser'];
			houses: ListHouseIdsQuery['listHouses'];
	  }
	| undefined;

const FormFields = (
	args: UserUpdateArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	let fields: FormFieldsComponentProps['fields'] = [];

	if (args.isAdmin) {
		const houseOptions = data?.houses?.map((house) => {
			return { value: house?.id ?? '', copy: house?.title ?? '' };
		});

		fields = [
			{
				id: 'role',
				type: FormFieldTypes.SELECT,
				label: 'Role:',
				config: {
					defaultValue: data?.user?.role,
				},
				validation: {
					required: true,
				},
				options: [
					{
						value: Role.Admin,
						copy: 'Admin',
					},
					{
						value: Role.HouseManager,
						copy: 'House Manager',
					},
					{
						value: Role.SeniorUser,
						copy: 'Senior Support Worker',
					},
					{
						value: Role.User,
						copy: 'Support Worker',
					},
				],
			},
			{
				id: 'houseId',
				type: FormFieldTypes.SELECT,
				label: 'House:',
				config: {
					defaultValue: data?.user?.houseId,
				},
				validation: {
					required: true,
				},
				options: houseOptions ?? [],
			},
		];
	} else {
		fields = [
			{
				id: 'firstName',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'First Name:',
					defaultValue: data?.user?.firstName ?? '',
				},
				validation: {
					required: true,
				},
			},
			{
				id: 'lastName',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Last Name:',
					defaultValue: data?.user?.lastName,
				},
				validation: {
					required: true,
				},
			},
			{
				id: 'phone',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Phone Number:',
					defaultValue: data?.user?.phone,
				},
				validation: {
					required: true,
				},
			},
			{
				id: 'address',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Address:',
					defaultValue: data?.user?.address,
					multiline: true,
					rows: 4,
				},
				validation: {
					required: true,
				},
			},
			{
				id: 'divider',
				type: FormFieldTypes.DIVIDER,
				label: 'Update Password',
			},
			{
				id: 'currentPassword',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Current password:',
					type: 'password',
				},

				validation: {
					pattern: {
						value: new RegExp(
							'^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-;;,]).{8,}$'
						),
						message:
							'Password require 1 lower and upper case letter, 1 number and 1 special character.',
					},
				},
			},
			{
				id: 'newPassword',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'New password:',
					type: 'password',
				},
				// TODO: [STLL-239] Figure out validation which will verify both passwords are the same
				validation: {
					pattern: {
						value: new RegExp(
							'^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-;;,]).{8,}$'
						),
						message:
							'Password require 1 lower and upper case letter, 1 number and 1 special character.',
					},
				},
			},
			{
				id: 'repeatNewPassword',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Repeat password:',
					type: 'password',
				},
				validation: {
					pattern: {
						value: new RegExp(
							'^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-;;,]).{8,}$'
						),
						message:
							'Password require 1 lower and upper case letter, 1 number and 1 special character.',
					},
				},
			},
		];
	}

	return [fields];
};

const GenerateOnSubmit = async (
	args: UserUpdateArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: UserFormValues): Promise<any> => {
		if (formValues.newPassword !== formValues.repeatNewPassword)
			throw new Error('New passwords do not match each other.');

		// If the user has entered a new password and their current password reset them
		if (formValues.newPassword && formValues.currentPassword) {
			if (args.changePassword) {
				await args.changePassword({
					newPassword: formValues.newPassword,
					oldPassword: formValues.currentPassword,
				});
			}
		}

		let data = args.isAdmin
			? { role: formValues.role, houseId: formValues.houseId }
			: {
					firstName: formValues.firstName,
					lastName: formValues.lastName,
					name: `${formValues.firstName} ${formValues.lastName}`,
					phone: formValues.phone,
					address: formValues.address,
			  };

		// Update the users details in the Mongo DB
		const user = await client.mutate<
			UpdateUserMutation,
			UpdateUserMutationVariables
		>({
			mutation: UpdateUserDocument,
			variables: {
				id: args.userId ?? '',
				data,
			},
			refetchQueries: [UserDetailsFragmentDoc, 'GetUser'],
		});

		return user;
	};
	return onSubmit;
};

const LogIncidentForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default LogIncidentForm;
