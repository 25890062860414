import * as React from 'react';
import Typography from '@material-ui/core/Typography';

export type PaginationCountFeedbackProps = {
	listLength: number;
	current: number;
	suffix?: string;
	loading?: boolean;
	empty?: boolean;
	error?: boolean;
};

const PaginationCountFeedbackComponent: React.FC<PaginationCountFeedbackProps> =
	({ suffix = '', listLength, current, loading, empty, error }) => {
		const feedback = loading
			? `Loading ${suffix} . . .`
			: empty || error
			? ``
			: `Displaying ${current} of ${listLength} ${suffix}`;

		return (
			<Typography variant="caption" color="textSecondary">
				{feedback}
			</Typography>
		);
	};

export default PaginationCountFeedbackComponent;
