import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ExportTrmDataQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
	startDate?: Types.Maybe<Types.Scalars['AWSDateTime']>;
	endDate?: Types.Maybe<Types.Scalars['AWSDateTime']>;
}>;

export type ExportTrmDataQuery = {
	__typename?: 'Query';
	listLogs?:
		| Array<
				| {
						__typename?: 'Log';
						id: string;
						data: any;
						type: Types.LogType;
						createdByUser?:
							| { __typename?: 'User'; name?: string | null | undefined }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type ExportLogDataQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
	startDate?: Types.Maybe<Types.Scalars['AWSDateTime']>;
	endDate?: Types.Maybe<Types.Scalars['AWSDateTime']>;
}>;

export type ExportLogDataQuery = {
	__typename?: 'Query';
	listLogs?:
		| Array<
				| {
						__typename?: 'Log';
						id: string;
						data: any;
						type: Types.LogType;
						createdByUser?:
							| { __typename?: 'User'; name?: string | null | undefined }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const ExportTrmDataDocument = gql`
	query ExportTrmData(
		$id: String
		$startDate: AWSDateTime
		$endDate: AWSDateTime
	) {
		listLogs(
			where: {
				AND: [
					{ youngPerson: { id: { equals: $id } } }
					{ type: { equals: SPIN_SESSION } }
					{ createdAt: { gte: $startDate } }
					{ createdAt: { lte: $endDate } }
				]
			}
		) {
			id
			data
			type
			createdByUser {
				name
			}
		}
	}
`;

/**
 * __useExportTrmDataQuery__
 *
 * To run a query within a React component, call `useExportTrmDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportTrmDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportTrmDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportTrmDataQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ExportTrmDataQuery,
		ExportTrmDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExportTrmDataQuery, ExportTrmDataQueryVariables>(
		ExportTrmDataDocument,
		options
	);
}
export function useExportTrmDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ExportTrmDataQuery,
		ExportTrmDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExportTrmDataQuery, ExportTrmDataQueryVariables>(
		ExportTrmDataDocument,
		options
	);
}
export type ExportTrmDataQueryHookResult = ReturnType<
	typeof useExportTrmDataQuery
>;
export type ExportTrmDataLazyQueryHookResult = ReturnType<
	typeof useExportTrmDataLazyQuery
>;
export type ExportTrmDataQueryResult = Apollo.QueryResult<
	ExportTrmDataQuery,
	ExportTrmDataQueryVariables
>;
export const ExportLogDataDocument = gql`
	query ExportLogData(
		$id: String
		$startDate: AWSDateTime
		$endDate: AWSDateTime
	) {
		listLogs(
			where: {
				AND: [
					{ youngPerson: { id: { equals: $id } } }
					{ NOT: { type: { equals: SPIN_SESSION } } }
					{ createdAt: { gte: $startDate } }
					{ createdAt: { lte: $endDate } }
				]
			}
		) {
			id
			data
			type
			createdByUser {
				name
			}
		}
	}
`;

/**
 * __useExportLogDataQuery__
 *
 * To run a query within a React component, call `useExportLogDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportLogDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportLogDataQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExportLogDataQuery(
	baseOptions?: Apollo.QueryHookOptions<
		ExportLogDataQuery,
		ExportLogDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ExportLogDataQuery, ExportLogDataQueryVariables>(
		ExportLogDataDocument,
		options
	);
}
export function useExportLogDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ExportLogDataQuery,
		ExportLogDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ExportLogDataQuery, ExportLogDataQueryVariables>(
		ExportLogDataDocument,
		options
	);
}
export type ExportLogDataQueryHookResult = ReturnType<
	typeof useExportLogDataQuery
>;
export type ExportLogDataLazyQueryHookResult = ReturnType<
	typeof useExportLogDataLazyQuery
>;
export type ExportLogDataQueryResult = Apollo.QueryResult<
	ExportLogDataQuery,
	ExportLogDataQueryVariables
>;
