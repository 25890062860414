import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ListAlertsQueryVariables = Types.Exact<{
	where: Types.NotificationWhereFilterInput;
	take?: Types.Maybe<Types.Scalars['Int']>;
	skip?: Types.Maybe<Types.Scalars['Int']>;
	orderBy?: Types.Maybe<
		| Array<Types.Maybe<Types.NotificationOrderByInput>>
		| Types.Maybe<Types.NotificationOrderByInput>
	>;
}>;

export type ListAlertsQuery = {
	__typename?: 'Query';
	countNotifications?: number | null | undefined;
	listNotifications?:
		| Array<
				| {
						__typename?: 'Notification';
						id: string;
						title: string;
						severity?: Types.SeverityLevel | null | undefined;
						houseId?: string | null | undefined;
						createdAt?: any | null | undefined;
						readAt?: any | null | undefined;
						youngPerson?:
							| { __typename?: 'YoungPerson'; id: string; name: string }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type DismissAlertMutationVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
	date?: Types.Maybe<Types.Scalars['AWSDateTime']>;
}>;

export type DismissAlertMutation = {
	__typename?: 'Mutation';
	updateNotification?:
		| {
				__typename?: 'Notification';
				id: string;
				title: string;
				severity?: Types.SeverityLevel | null | undefined;
				houseId?: string | null | undefined;
				createdAt?: any | null | undefined;
				readAt?: any | null | undefined;
				youngPerson?:
					| { __typename?: 'YoungPerson'; id: string; name: string }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const ListAlertsDocument = gql`
	query ListAlerts(
		$where: NotificationWhereFilterInput!
		$take: Int
		$skip: Int
		$orderBy: [NotificationOrderByInput]
	) {
		listNotifications(
			where: $where
			skip: $skip
			take: $take
			orderBy: $orderBy
		) {
			id
			title
			severity
			houseId
			createdAt
			readAt
			youngPerson {
				id
				name
			}
		}
		countNotifications(where: $where)
	}
`;

/**
 * __useListAlertsQuery__
 *
 * To run a query within a React component, call `useListAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAlertsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useListAlertsQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListAlertsQuery,
		ListAlertsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ListAlertsQuery, ListAlertsQueryVariables>(
		ListAlertsDocument,
		options
	);
}
export function useListAlertsLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListAlertsQuery,
		ListAlertsQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<ListAlertsQuery, ListAlertsQueryVariables>(
		ListAlertsDocument,
		options
	);
}
export type ListAlertsQueryHookResult = ReturnType<typeof useListAlertsQuery>;
export type ListAlertsLazyQueryHookResult = ReturnType<
	typeof useListAlertsLazyQuery
>;
export type ListAlertsQueryResult = Apollo.QueryResult<
	ListAlertsQuery,
	ListAlertsQueryVariables
>;
export const DismissAlertDocument = gql`
	mutation DismissAlert($id: String, $date: AWSDateTime) {
		updateNotification(where: { id: $id }, data: { readAt: $date }) {
			id
			title
			severity
			houseId
			createdAt
			readAt
			youngPerson {
				id
				name
			}
		}
	}
`;
export type DismissAlertMutationFn = Apollo.MutationFunction<
	DismissAlertMutation,
	DismissAlertMutationVariables
>;

/**
 * __useDismissAlertMutation__
 *
 * To run a mutation, you first call `useDismissAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDismissAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [dismissAlertMutation, { data, loading, error }] = useDismissAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useDismissAlertMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DismissAlertMutation,
		DismissAlertMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<
		DismissAlertMutation,
		DismissAlertMutationVariables
	>(DismissAlertDocument, options);
}
export type DismissAlertMutationHookResult = ReturnType<
	typeof useDismissAlertMutation
>;
export type DismissAlertMutationResult =
	Apollo.MutationResult<DismissAlertMutation>;
export type DismissAlertMutationOptions = Apollo.BaseMutationOptions<
	DismissAlertMutation,
	DismissAlertMutationVariables
>;
