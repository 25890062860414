import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type OccupancyChartQueryVariables = Types.Exact<{
	id?: Types.Maybe<Types.Scalars['String']>;
	startDate?: Types.Maybe<Types.Scalars['AWSDateTime']>;
	endDate?: Types.Maybe<Types.Scalars['AWSDateTime']>;
}>;

export type OccupancyChartQuery = {
	__typename?: 'Query';
	listOccupancyLogs?:
		| Array<
				| {
						__typename?: 'OccupancyLog';
						id: string;
						startDate?: any | null | undefined;
						endDate?: any | null | undefined;
						room?:
							| { __typename?: 'Room'; id: string; name: string }
							| null
							| undefined;
						youngPerson?:
							| { __typename?: 'YoungPerson'; id: string; name: string }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
	listRooms?:
		| Array<
				| { __typename?: 'Room'; id: string; name: string; color: string }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const OccupancyChartDocument = gql`
	query OccupancyChart(
		$id: String
		$startDate: AWSDateTime
		$endDate: AWSDateTime
	) {
		listOccupancyLogs(
			where: {
				AND: [
					{ room: { house: { id: { equals: $id } } } }
					{ startDate: { lte: $endDate } }
					{
						OR: [
							{ endDate: { gte: $startDate } }
							{ endDate: { equals: null } }
						]
					}
				]
			}
		) {
			id
			startDate
			endDate
			room {
				id
				name
			}
			youngPerson {
				id
				name
			}
		}
		listRooms(where: { house: { id: { equals: $id } } }) {
			id
			name
			color
		}
	}
`;

/**
 * __useOccupancyChartQuery__
 *
 * To run a query within a React component, call `useOccupancyChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useOccupancyChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOccupancyChartQuery({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useOccupancyChartQuery(
	baseOptions?: Apollo.QueryHookOptions<
		OccupancyChartQuery,
		OccupancyChartQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<OccupancyChartQuery, OccupancyChartQueryVariables>(
		OccupancyChartDocument,
		options
	);
}
export function useOccupancyChartLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		OccupancyChartQuery,
		OccupancyChartQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<OccupancyChartQuery, OccupancyChartQueryVariables>(
		OccupancyChartDocument,
		options
	);
}
export type OccupancyChartQueryHookResult = ReturnType<
	typeof useOccupancyChartQuery
>;
export type OccupancyChartLazyQueryHookResult = ReturnType<
	typeof useOccupancyChartLazyQuery
>;
export type OccupancyChartQueryResult = Apollo.QueryResult<
	OccupancyChartQuery,
	OccupancyChartQueryVariables
>;
