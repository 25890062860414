import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GetFileQueryVariables = Types.Exact<{
	key: Types.Scalars['String'];
}>;

export type GetFileQuery = {
	__typename?: 'Query';
	getFile?:
		| { __typename?: 'File'; key?: string | null | undefined }
		| null
		| undefined;
};

export type DeleteFileMutationVariables = Types.Exact<{
	key: Types.Scalars['String'];
}>;

export type DeleteFileMutation = {
	__typename?: 'Mutation';
	deleteFile?:
		| { __typename?: 'UrlResponse'; statusCode?: number | null | undefined }
		| null
		| undefined;
};

export const GetFileDocument = gql`
	query GetFile($key: String!) {
		getFile(input: { fileKey: $key }) {
			key
		}
	}
`;

/**
 * __useGetFileQuery__
 *
 * To run a query within a React component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetFileQuery(
	baseOptions: Apollo.QueryHookOptions<GetFileQuery, GetFileQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetFileQuery, GetFileQueryVariables>(
		GetFileDocument,
		options
	);
}
export function useGetFileLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<GetFileQuery, GetFileQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetFileQuery, GetFileQueryVariables>(
		GetFileDocument,
		options
	);
}
export type GetFileQueryHookResult = ReturnType<typeof useGetFileQuery>;
export type GetFileLazyQueryHookResult = ReturnType<typeof useGetFileLazyQuery>;
export type GetFileQueryResult = Apollo.QueryResult<
	GetFileQuery,
	GetFileQueryVariables
>;
export const DeleteFileDocument = gql`
	mutation DeleteFile($key: String!) {
		deleteFile(input: { fileKey: $key }) {
			statusCode
		}
	}
`;
export type DeleteFileMutationFn = Apollo.MutationFunction<
	DeleteFileMutation,
	DeleteFileMutationVariables
>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useDeleteFileMutation(
	baseOptions?: Apollo.MutationHookOptions<
		DeleteFileMutation,
		DeleteFileMutationVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(
		DeleteFileDocument,
		options
	);
}
export type DeleteFileMutationHookResult = ReturnType<
	typeof useDeleteFileMutation
>;
export type DeleteFileMutationResult =
	Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<
	DeleteFileMutation,
	DeleteFileMutationVariables
>;
