import * as React from 'react';
import { Fade, CircularProgress, Box } from '@material-ui/core';
import FallbackContainerComponent from './common/FallbackContainer';
import { Skeleton } from '@material-ui/lab';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ListComponent from '../../ListComponent';
import FallbackCopyComponent from './common/FallbackCopy';

export type LoadingType =
	| 'circularProgress'
	| 'skeletonCard'
	| 'skeletonGridSm'
	| 'skeletonGridMd'
	| 'skeletonListSm'
	| 'skeletonListMd';

export type LoadingComponentProps = {
	type?: LoadingType;
	copy?: string;
	title?: string;
};

const SkeletonComponent = () => (
	<Skeleton
		height="100%"
		width="100%"
		animation="wave"
		style={{
			transform: 'unset',
			minHeight: 'inherit',
		}}
	/>
);

export const LoadingComponent: React.FC<LoadingComponentProps> = (props) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));
	const hasText = props.title || props.copy;

	let Loader;

	switch (props.type) {
		case 'skeletonCard':
			Loader = () => <SkeletonComponent />;
			break;
		case 'skeletonGridSm':
			Loader = () => (
				<Box minHeight="inherit" display="flex" gridColumnGap={'2.5rem'}>
					{Array(isMobile ? 2 : isTablet ? 3 : 4)
						.fill(null)
						.map(() => (
							<SkeletonComponent />
						))}
				</Box>
			);
			break;
		case 'skeletonGridMd':
			Loader = () => (
				<Box minHeight="inherit" display="flex" gridColumnGap={'2rem'}>
					{Array(isMobile ? 1 : isTablet ? 2 : 3)
						.fill(null)
						.map(() => (
							<Skeleton
								width="100%"
								height="7.5rem"
								style={{ transform: 'unset' }}
							/>
						))}
				</Box>
			);
			break;
		case 'skeletonListSm':
			Loader = () => (
				<ListComponent size="sm">
					{Array(5)
						.fill(null)
						.map(() => (
							<Skeleton
								height="4rem"
								width="100%"
								animation="wave"
								style={{ transform: 'unset' }}
							/>
						))}
				</ListComponent>
			);
			break;
		case 'skeletonListMd':
			Loader = () => (
				<ListComponent size="sm">
					{Array(5)
						.fill(null)
						.map(() => (
							<Skeleton
								width="100%"
								height="6rem"
								style={{ transform: 'unset' }}
							/>
						))}
				</ListComponent>
			);
			break;
		default:
			Loader = () => (
				<FallbackContainerComponent>
					<CircularProgress />
					{hasText && (
						<FallbackCopyComponent copy={props.copy} title={props.title} />
					)}
				</FallbackContainerComponent>
			);
	}

	return (
		<Fade in={true} style={{ transitionDelay: '500ms' }} unmountOnExit>
			<Loader />
		</Fade>
	);
};

export default LoadingComponent;
