import dayjs from 'dayjs';
// Query
import {
	// Get Details
	ListHouseIdsDocument,
	ListHouseIdsQuery,
	ListHouseIdsQueryVariables,
	// Update Details Mutation
	CreateYoungPersonProfileDocument,
	CreateYoungPersonProfileMutation,
	CreateYoungPersonProfileMutationVariables,
} from './query.generated';
import { ListYoungPeopleDocument } from '../../../../graphql/hooks';
import { YoungPersonProfileFragment } from '../../../../graphql/types';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient } from '@apollo/client';
import { YpCreateArgs } from '../../types';

export type YpCreateFormValues = {
	name: string;
	preferredName: string;
	gender: string;
	email: string;
	phone: string;
	dob: string;
	mostRecentAddress?: string;
	houseId?: string;
};

const PreFetch = async (args: YpCreateArgs, client: ApolloClient<object>) => {
	const houses = await client.query<
		ListHouseIdsQuery,
		ListHouseIdsQueryVariables
	>({
		query: ListHouseIdsDocument,
	});

	if (!houses.data.listHouses || houses.error)
		throw new Error('Pre-fetched Data Failed');

	return houses.data;
};

const FormFields = (
	args: YpCreateArgs,
	data: ListHouseIdsQuery
): FormFieldsComponentProps['fields'][] => {
	const houseOptions = data.listHouses?.map((house) => {
		return { value: house?.id ?? '', copy: house?.title ?? '' };
	});

	return [
		[
			{
				id: 'name',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Name:',
				},
				validation: {
					required: true,
				},
			},
			{
				id: 'preferredName',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Preferred Name:',
				},
				validation: {
					required: true,
				},
			},
			{
				id: 'gender',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Gender:',
				},
				validation: {
					required: true,
				},
			},
			{
				id: 'email',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Email:',
				},
				validation: {
					required: false,
				},
			},
			{
				id: 'phone',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Phone:',
				},
				validation: {
					required: true,
				},
			},

			{
				id: 'dob',
				type: FormFieldTypes.DATE_PICKER,
				config: {
					label: 'Date of Birth:',
					InputLabelProps: {
						shrink: true,
					},
				},
				validation: {
					required: true,
				},
			},
			{
				id: 'mostRecentAddress',
				type: FormFieldTypes.INPUT,
				config: {
					label: 'Most Recent Address:',
					multiline: true,
					rows: 4,
				},
			},
			// {
			// 	id: 'houseId',
			// 	type: FormFieldTypes.SELECT,
			// 	label: 'House:',
			// 	config: {},
			// 	options: houseOptions ?? [],
			// },
		],
	];
};

const GenerateOnSubmit = async (
	args: YpCreateArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: YpCreateFormValues): Promise<any> => {
		const createYpData = await client.mutate<
			CreateYoungPersonProfileMutation,
			CreateYoungPersonProfileMutationVariables
		>({
			mutation: CreateYoungPersonProfileDocument,
			variables: {
				data: {
					name: formValues.name,
					preferredName: formValues.preferredName,
					dateOfBirth: dayjs(formValues.dob).toISOString(),
					phone: formValues.phone,
					email: formValues.email,
					gender: formValues.gender,
					createdByUserId: userId,
					mostRecentAddress: formValues.mostRecentAddress,
					houseId: formValues.houseId ?? '60dc514083d1a2072af0aed7',
					// Set YP as in House by default
					locationStatusId: '616eaace767556b7290a0cf6',
				},
			},
			refetchQueries: [ListYoungPeopleDocument],
		});

		return createYpData;
	};

	return onSubmit;
};

const YpCreateForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default YpCreateForm;
