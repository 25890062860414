import dayjs from 'dayjs';

// Query
import {
	// Get Activity
	GetActivityLogQuery,
	GetActivityLogQueryVariables,
	// List YoungPerson
	FormListYoungPeopleQuery,
	FormListYoungPeopleQueryVariables,
	// Create Mutation
	CreateActivityLogMutation,
	CreateActivityLogMutationVariables,
	// Update Mutation
	UpdateActivityLogMutation,
	UpdateActivityLogMutationVariables,
	GetHouseQuery,
	GetHouseQueryVariables,
} from '../../../../graphql/types';
import {
	LogFragmentFragmentDoc,
	UpdateActivityLogDocument,
	CreateActivityLogDocument,
	FormListYoungPeopleDocument,
	GetActivityLogDocument,
	GetHouseDocument,
} from '../../../../graphql/hooks';

// Types
import {
	FormFieldTypes,
	FormFieldsComponentProps,
} from '../../../../components/FormFieldsComponent';
import { ApolloClient, ApolloQueryResult } from '@apollo/client';
import { LogNoteArgs } from '../../types';
import { LogType } from '../../../../graphql/types';
import { ActivityLogData } from '../../../../types';
import { Typography } from '@material-ui/core';

export type LogActivityFormValues = {
	note: string;
	youngPersonId?: string;
	houseId?: string;
	date: string;
	reopen?: boolean;
};

const PreFetch = async (args: LogNoteArgs, client: ApolloClient<object>) => {
	let log: ApolloQueryResult<GetActivityLogQuery> | undefined = undefined;
	let yp: ApolloQueryResult<FormListYoungPeopleQuery> | undefined = undefined;
	let house: ApolloQueryResult<GetHouseQuery> | undefined = undefined;

	if (args.id) {
		log = await client.query<GetActivityLogQuery, GetActivityLogQueryVariables>(
			{
				query: GetActivityLogDocument,
				variables: {
					id: args.id,
				},
			}
		);

		if (!log.data.getLog || log.error)
			throw new Error('Pre-fetched Data Failed');
	}

	if (!args.youngPersonId) {
		const where = args.houseId
			? {
					house: {
						id: {
							equals: args.houseId,
						},
					},
			  }
			: {};

		yp = await client.query<
			FormListYoungPeopleQuery,
			FormListYoungPeopleQueryVariables
		>({
			query: FormListYoungPeopleDocument,
			variables: {
				where,
			},
		});

		if (!yp.data.listYoungPeople || yp.error)
			throw new Error('Pre-fetched Data Failed');
	}

	if (args.houseId) {
		house = await client.query<GetHouseQuery, GetHouseQueryVariables>({
			query: GetHouseDocument,
			variables: {
				houseId: args.houseId,
			},
		});

		if (!house.data) {
			throw new Error('Pre-fetched Data Failed');
		}
	}

	return {
		house: house?.data ?? undefined,
		activity: log?.data.getLog ?? undefined,
		youngPerson: yp?.data.listYoungPeople ?? undefined,
	};
};

type Data = {
	house: GetHouseQuery | undefined;
	activity: GetActivityLogQuery['getLog'] | undefined;
	youngPerson: FormListYoungPeopleQuery['listYoungPeople'] | undefined;
};

const FormFields = (
	args: LogNoteArgs,
	data: Data
): FormFieldsComponentProps['fields'][] => {
	const formData: ActivityLogData | undefined = data.activity?.data
		? JSON.parse(data.activity?.data)
		: undefined;

	const fields: FormFieldsComponentProps['fields'] = [
		{
			id: 'date',
			type: FormFieldTypes.DATE_PICKER,
			config: {
				label: 'Date:',
				defaultValue:
					dayjs(formData?.date).format('YYYY-MM-DD') ??
					dayjs(Date.now()).format('YYYY-MM-DD'),
			},
			validation: {
				required: true,
			},
		},
		{
			id: 'note',
			type: FormFieldTypes.INPUT,
			config: {
				label: 'Note:',
				multiline: true,
				rowsMax: 5,
				defaultValue: formData?.note ?? '',
			},
			validation: {
				required: true,
			},
		},
	];

	if (!args.youngPersonId && !args.id) {
		const options = data.youngPerson?.map((yp) => {
			return { value: yp?.id ?? '', copy: yp?.name ?? '' };
		});

		fields.unshift({
			id: 'youngPersonId',
			type: FormFieldTypes.SELECT,
			label: 'Kid:',
			validation: {
				required: !args.houseId,
			},
			config: {},
			options: options ?? [],
		});
	}

	if (data.house?.getHouse?.title) {
		fields.push({
			id: 'house',
			type: FormFieldTypes.CUSTOM,
			render: (
				<Typography
					style={{ textAlign: 'left' }}
					variant="body1"
				>{`If no kid is selected, a note will be added against ${data?.house?.getHouse?.title}.`}</Typography>
			),
		});
	}

	return [fields];
};

const GenerateOnSubmit = async (
	args: LogNoteArgs,
	client: ApolloClient<object>,
	userId: string
) => {
	const onSubmit = async (formValues: LogActivityFormValues): Promise<any> => {
		console.log('house id', args.houseId);
		if (args.id) {
			const updateNote = await client.mutate<
				UpdateActivityLogMutation,
				UpdateActivityLogMutationVariables
			>({
				mutation: UpdateActivityLogDocument,
				variables: {
					id: args.id ?? '',
					data: {
						data: JSON.stringify({
							note: formValues.note,
							date: dayjs(formValues.date).toISOString(),
						}),
					},
				},
			});
			return updateNote;
		} else {
			const createNote = await client.mutate<
				CreateActivityLogMutation,
				CreateActivityLogMutationVariables
			>({
				mutation: CreateActivityLogDocument,
				variables: {
					data: {
						type: LogType.Note,
						youngPersonId:
							args.youngPersonId ?? formValues?.youngPersonId ?? undefined,
						houseId: args.houseId ?? undefined,
						createdBy: userId,
						data: JSON.stringify({
							note: formValues.note,
							date: dayjs(formValues.date).toISOString(),
						}),
					},
				},
				refetchQueries: [LogFragmentFragmentDoc, 'ListLogs'],
			});

			return createNote;
		}
	};
	return onSubmit;
};

const LogNoteForm = {
	preFetch: PreFetch,
	formFields: FormFields,
	generateOnSubmit: GenerateOnSubmit,
};

export default LogNoteForm;
