import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type GroupTaskFragment = {
	__typename?: 'Task';
	id: string;
	title: string;
	isAdmin?: boolean | null | undefined;
	isPrivate?: boolean | null | undefined;
	youngPersonId?: string | null | undefined;
	group?: Types.TaskGroup | null | undefined;
	completedAt?: any | null | undefined;
	updatedAt?: any | null | undefined;
};

export type GetYoungPersonAdminTasksQueryVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
}>;

export type GetYoungPersonAdminTasksQuery = {
	__typename?: 'Query';
	listTasks?:
		| Array<
				| {
						__typename?: 'Task';
						id: string;
						title: string;
						isAdmin?: boolean | null | undefined;
						isPrivate?: boolean | null | undefined;
						youngPersonId?: string | null | undefined;
						group?: Types.TaskGroup | null | undefined;
						completedAt?: any | null | undefined;
						updatedAt?: any | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export type OnYoungPersonTaskCreatedSubscriptionVariables = Types.Exact<{
	youngPersonId: Types.Scalars['String'];
}>;

export type OnYoungPersonTaskCreatedSubscription = {
	__typename?: 'Subscription';
	onMutatedTask?:
		| {
				__typename?: 'Task';
				id: string;
				title: string;
				isAdmin?: boolean | null | undefined;
				isPrivate?: boolean | null | undefined;
				youngPersonId?: string | null | undefined;
				group?: Types.TaskGroup | null | undefined;
				completedAt?: any | null | undefined;
				updatedAt?: any | null | undefined;
		  }
		| null
		| undefined;
};

export const GroupTaskFragmentDoc = gql`
	fragment GroupTask on Task {
		id
		title
		isAdmin
		isPrivate
		youngPersonId
		group
		completedAt
		updatedAt
	}
`;
export const GetYoungPersonAdminTasksDocument = gql`
	query GetYoungPersonAdminTasks($youngPersonId: String!) {
		listTasks(
			where: {
				youngPersonId: { equals: $youngPersonId }
				group: { in: [PRE_MOVE_IN, MOVE_IN, MOVE_OUT] }
				isAdmin: { equals: true }
			}
		) {
			...GroupTask
		}
	}
	${GroupTaskFragmentDoc}
`;

/**
 * __useGetYoungPersonAdminTasksQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonAdminTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonAdminTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonAdminTasksQuery({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useGetYoungPersonAdminTasksQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYoungPersonAdminTasksQuery,
		GetYoungPersonAdminTasksQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetYoungPersonAdminTasksQuery,
		GetYoungPersonAdminTasksQueryVariables
	>(GetYoungPersonAdminTasksDocument, options);
}
export function useGetYoungPersonAdminTasksLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYoungPersonAdminTasksQuery,
		GetYoungPersonAdminTasksQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetYoungPersonAdminTasksQuery,
		GetYoungPersonAdminTasksQueryVariables
	>(GetYoungPersonAdminTasksDocument, options);
}
export type GetYoungPersonAdminTasksQueryHookResult = ReturnType<
	typeof useGetYoungPersonAdminTasksQuery
>;
export type GetYoungPersonAdminTasksLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonAdminTasksLazyQuery
>;
export type GetYoungPersonAdminTasksQueryResult = Apollo.QueryResult<
	GetYoungPersonAdminTasksQuery,
	GetYoungPersonAdminTasksQueryVariables
>;
export const OnYoungPersonTaskCreatedDocument = gql`
	subscription onYoungPersonTaskCreated($youngPersonId: String!) {
		onMutatedTask(youngPersonId: $youngPersonId) {
			...GroupTask
		}
	}
	${GroupTaskFragmentDoc}
`;

/**
 * __useOnYoungPersonTaskCreatedSubscription__
 *
 * To run a query within a React component, call `useOnYoungPersonTaskCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnYoungPersonTaskCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnYoungPersonTaskCreatedSubscription({
 *   variables: {
 *      youngPersonId: // value for 'youngPersonId'
 *   },
 * });
 */
export function useOnYoungPersonTaskCreatedSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		OnYoungPersonTaskCreatedSubscription,
		OnYoungPersonTaskCreatedSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		OnYoungPersonTaskCreatedSubscription,
		OnYoungPersonTaskCreatedSubscriptionVariables
	>(OnYoungPersonTaskCreatedDocument, options);
}
export type OnYoungPersonTaskCreatedSubscriptionHookResult = ReturnType<
	typeof useOnYoungPersonTaskCreatedSubscription
>;
export type OnYoungPersonTaskCreatedSubscriptionResult =
	Apollo.SubscriptionResult<OnYoungPersonTaskCreatedSubscription>;
