import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type YoungPersonScreenDataFragment = {
	__typename?: 'YoungPerson';
	id: string;
	name: string;
	status?: Types.YoungPersonStatus | null | undefined;
	houseId?: string | null | undefined;
	dateOfBirth: any;
	phone: string;
	avatar?: string | null | undefined;
	missingAt?: any | null | undefined;
	house?:
		| { __typename?: 'House'; id: string; title: string }
		| null
		| undefined;
	room?:
		| { __typename?: 'Room'; id: string; name: string; color: string }
		| null
		| undefined;
	locationStatus?:
		| {
				__typename?: 'YoungPersonLocationStatus';
				id: string;
				title: string;
				type: Types.YoungPersonLocationStatusType;
				priority?: number | null | undefined;
		  }
		| null
		| undefined;
};

export type GetYoungPersonByIdQueryVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetYoungPersonByIdQuery = {
	__typename?: 'Query';
	listYoungPersonLocationStatuses?:
		| Array<
				| {
						__typename?: 'YoungPersonLocationStatus';
						id: string;
						title: string;
						type: Types.YoungPersonLocationStatusType;
						priority?: number | null | undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
	getYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				status?: Types.YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				dateOfBirth: any;
				phone: string;
				avatar?: string | null | undefined;
				missingAt?: any | null | undefined;
				house?:
					| { __typename?: 'House'; id: string; title: string }
					| null
					| undefined;
				room?:
					| { __typename?: 'Room'; id: string; name: string; color: string }
					| null
					| undefined;
				locationStatus?:
					| {
							__typename?: 'YoungPersonLocationStatus';
							id: string;
							title: string;
							type: Types.YoungPersonLocationStatusType;
							priority?: number | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export type GetYoungPersonByIdSubSubscriptionVariables = Types.Exact<{
	id: Types.Scalars['String'];
}>;

export type GetYoungPersonByIdSubSubscription = {
	__typename?: 'Subscription';
	onMutatedYoungPerson?:
		| {
				__typename?: 'YoungPerson';
				id: string;
				name: string;
				status?: Types.YoungPersonStatus | null | undefined;
				houseId?: string | null | undefined;
				dateOfBirth: any;
				phone: string;
				avatar?: string | null | undefined;
				missingAt?: any | null | undefined;
				house?:
					| { __typename?: 'House'; id: string; title: string }
					| null
					| undefined;
				room?:
					| { __typename?: 'Room'; id: string; name: string; color: string }
					| null
					| undefined;
				locationStatus?:
					| {
							__typename?: 'YoungPersonLocationStatus';
							id: string;
							title: string;
							type: Types.YoungPersonLocationStatusType;
							priority?: number | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
};

export const YoungPersonScreenDataFragmentDoc = gql`
	fragment YoungPersonScreenData on YoungPerson {
		id
		name
		status
		houseId
		house {
			id
			title
		}
		room {
			id
			name
			color
		}
		dateOfBirth
		phone
		avatar
		missingAt
		locationStatus {
			id
			title
			type
			priority
		}
	}
`;
export const GetYoungPersonByIdDocument = gql`
	query GetYoungPersonById($id: String!) {
		listYoungPersonLocationStatuses {
			id
			title
			type
			priority
		}
		getYoungPerson(where: { id: $id }) {
			...YoungPersonScreenData
		}
	}
	${YoungPersonScreenDataFragmentDoc}
`;

/**
 * __useGetYoungPersonByIdQuery__
 *
 * To run a query within a React component, call `useGetYoungPersonByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetYoungPersonByIdQuery(
	baseOptions: Apollo.QueryHookOptions<
		GetYoungPersonByIdQuery,
		GetYoungPersonByIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<
		GetYoungPersonByIdQuery,
		GetYoungPersonByIdQueryVariables
	>(GetYoungPersonByIdDocument, options);
}
export function useGetYoungPersonByIdLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetYoungPersonByIdQuery,
		GetYoungPersonByIdQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		GetYoungPersonByIdQuery,
		GetYoungPersonByIdQueryVariables
	>(GetYoungPersonByIdDocument, options);
}
export type GetYoungPersonByIdQueryHookResult = ReturnType<
	typeof useGetYoungPersonByIdQuery
>;
export type GetYoungPersonByIdLazyQueryHookResult = ReturnType<
	typeof useGetYoungPersonByIdLazyQuery
>;
export type GetYoungPersonByIdQueryResult = Apollo.QueryResult<
	GetYoungPersonByIdQuery,
	GetYoungPersonByIdQueryVariables
>;
export const GetYoungPersonByIdSubDocument = gql`
	subscription GetYoungPersonByIdSub($id: String!) {
		onMutatedYoungPerson(id: $id) {
			...YoungPersonScreenData
		}
	}
	${YoungPersonScreenDataFragmentDoc}
`;

/**
 * __useGetYoungPersonByIdSubSubscription__
 *
 * To run a query within a React component, call `useGetYoungPersonByIdSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetYoungPersonByIdSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoungPersonByIdSubSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetYoungPersonByIdSubSubscription(
	baseOptions: Apollo.SubscriptionHookOptions<
		GetYoungPersonByIdSubSubscription,
		GetYoungPersonByIdSubSubscriptionVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useSubscription<
		GetYoungPersonByIdSubSubscription,
		GetYoungPersonByIdSubSubscriptionVariables
	>(GetYoungPersonByIdSubDocument, options);
}
export type GetYoungPersonByIdSubSubscriptionHookResult = ReturnType<
	typeof useGetYoungPersonByIdSubSubscription
>;
export type GetYoungPersonByIdSubSubscriptionResult =
	Apollo.SubscriptionResult<GetYoungPersonByIdSubSubscription>;
