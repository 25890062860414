import React from 'react';

import { Button } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

import { createInitialsFallback } from '../../utils';
import InfoCard, { InfoCardProps } from '../InfoCardComponent';

export type NotificationProps = Omit<InfoCardProps, 'trailingIcon'> & {
	avatarIcon?: React.ReactNode;
	to?: string | null | undefined;
	notificationId?: string;
	onMarkAsRead?: (notificationId: string) => {};
};

export const NotificationComponent: React.FC<NotificationProps> = (props) => {
	const history = useHistory();

	const onMarkAsRead = (id?: string) => {
		if (id) {
			props.onMarkAsRead?.(id);
		}
	};

	const goToLink = () => {
		if (props.to) {
			history.push(props.to);
		}
	};

	return (
		<>
			<InfoCard
				onClick={goToLink}
				severity={props.severity}
				defaultBackgroundColor="#fff"
			>
				<InfoCard.Avatar
					avatarAlt={props.avatarAlt}
					avatarUrl={props.avatarUrl}
				>
					{props.avatarIcon ?? createInitialsFallback(props.label)}
				</InfoCard.Avatar>
				<InfoCard.LabelContainer>
					<InfoCard.Label labelIcon={props.labelIcon}>
						{props.label}
					</InfoCard.Label>
					<InfoCard.SubLabel>{props.subLabel}</InfoCard.SubLabel>
				</InfoCard.LabelContainer>
				<Button onClick={() => onMarkAsRead(props.notificationId)}>
					<Check />
				</Button>
			</InfoCard>
		</>
	);
};

export default NotificationComponent;
