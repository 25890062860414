import { useParams } from 'react-router-dom';

import { TaskGroup } from '../../graphql/types';
import useFileDownload from '../../hooks/useFileDownload';
import {
	useGetYpTasksByGroupQuery,
	useMutatedYoungPersonTasksSubscription,
} from './query.generated';
import TaskGroupView from './TaskGroupView';

type TaskGroupViewContainerProps = {
	youngPersonId?: string;
	group?: TaskGroup;
};

type Params = {
	youngPersonId?: string;
	group?: TaskGroup;
};

const TaskGroupViewContainer: React.FC<TaskGroupViewContainerProps> = (
	props
) => {
	const { youngPersonId, group } = useParams<Params>();
	const { downloadTemplate } = useFileDownload();
	const { data, loading, error, refetch } = useGetYpTasksByGroupQuery({
		variables: {
			youngPersonId: props.youngPersonId ?? youngPersonId ?? '',
			group: props.group ?? group,
		},
	});

	useMutatedYoungPersonTasksSubscription({
		variables: { youngPersonId: props.youngPersonId ?? youngPersonId ?? '' },
	});

	return (
		<>
			<TaskGroupView
				data={data}
				loading={loading}
				error={error}
				onDownloadTemplate={downloadTemplate}
				youngPersonId={props.youngPersonId ?? youngPersonId ?? ''}
				refetch={refetch}
			/>
		</>
	);
};

export default TaskGroupViewContainer;
