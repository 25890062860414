import React, { useState, useRef, useEffect, useContext } from 'react';
import { useForm as useReactHookForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

// Components
import ModalContentComponent, {
	ModalContentComponentProps,
} from '../../components/ModalContentComponent';
import FormFieldsComponent, {
	FormFieldsComponentProps,
	FormFieldTypes,
} from '../../components/FormFieldsComponent';

// Hooks
import useForm, { FormArgs } from '../../hooks/useForm';

// Contexts
import ModalContext from '../../contexts/ModalContext';

import { ErrorType } from '../../components/FallbackComponent';
import { SecondaryItemType } from '../../components/ModalTitleComponent';
export type FormModalLayoutProps = {
	args: FormArgs;
	onCompleteCallback?: () => void;
	onErrorCallback?: () => void;
};

const FormModalLayout: React.FC<FormModalLayoutProps> = (props) => {
	const [step, setStep] = useState<number>(1);
	const [formLoading, setFormLoading] = useState<boolean>(false);
	const [primaryAction, setPrimaryAction] = useState<string>('submit');
	const [maxStep, setMaxStep] = useState<number>(0);
	const formRef = useRef(null);
	const [currentFields, setCurrentFields] = useState<
		FormFieldsComponentProps['fields']
	>([]);

	// Refs for managing the forms
	const formResults = useRef([]);

	const { close } = useContext(ModalContext);
	const { enqueueSnackbar } = useSnackbar();

	// Use Custom Form Hook which will get the values of the form
	const { title, form, error, loading } = useForm(props.args);
	const { handleSubmit, reset, control } = useReactHookForm({
		mode: 'onBlur',
		shouldFocusError: true,
	});

	useEffect(() => {
		if (loading || !form) return;

		// Check if this is a multi-step form;
		if (form.fields.length > 1) {
			setPrimaryAction('next');
			setMaxStep(form.fields.length);
		}

		setCurrentFields(form.fields[0]);
		reset();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loading, form]);

	/**
	 * Handle the Modal Next / Submit button based on if there are more pages to traverse
	 */
	const onFormSubmit = () => {
		// Handle checking if the form should be submitted or continue to the next step
		const shouldSubmit = primaryAction === 'submit';

		if (shouldSubmit) {
			setFormLoading(true);

			handleSubmit(
				async (values) => {
					// Check if the form hook has a submit function provided
					if (form?.onSubmit) {
						try {
							// If not a multi step form treat this normally
							// If it's a multi step form then pass through the formResults object with the new values
							if (maxStep === 0) {
								await form?.onSubmit(values);
							} else {
								formResults.current = { ...formResults.current, ...values };
								await form?.onSubmit(formResults.current);
							}

							// TODO: Allow for custom form success messages to be added
							enqueueSnackbar('Form was successfully submitted.', {
								variant: 'success',
								anchorOrigin: { vertical: 'top', horizontal: 'right' },
							});
							if (props.onCompleteCallback) {
								props.onCompleteCallback();
							}
							setFormLoading(false);
							close();
						} catch (error) {
							let errorMessage = 'Sorry something went wrong.';
							// Attempt to get the error message, if not use the default
							if (error instanceof Error) {
								errorMessage = error?.message;
							}

							enqueueSnackbar(errorMessage, {
								variant: 'error',
								anchorOrigin: { vertical: 'top', horizontal: 'right' },
							});

							if (props.onErrorCallback) {
								props.onErrorCallback();
							}
							setFormLoading(false);
						}
					}
				},
				// If the form didn't validate
				() => {
					setFormLoading(false);
				}
			)();
		} else {
			setFormLoading(true);
			handleSubmit(
				(values) => {
					// Increment the step counter
					const newStep = step + 1;
					formResults.current = { ...formResults.current, ...values };

					// As step is the previous step it's the equivalent of newStep - 1 which is needed to get the correct values in the array
					setCurrentFields(form?.fields[step] ?? []);
					setStep(newStep);

					if (newStep === maxStep) setPrimaryAction('submit');

					// In order for the default values to be honered on page change we need to create a defaultValues object before we reset;
					const defaultValues = {};
					form?.fields[step].forEach((field) => {
						// @ts-expect-error
						let defaultValue = field?.config?.defaultValue ?? '';

						// @ts-expect-error
						defaultValues[field.id] = defaultValue;
					});

					reset(defaultValues);
					// @ts-expect-error
					formRef?.current?.scrollIntoView();

					setFormLoading(false);
				},
				// If the form didn't validate
				() => {
					setFormLoading(false);
				}
			)();
		}
	};

	const onPreviousPageClick = () => {
		const newStep = step - 1;
		setCurrentFields(form?.fields[newStep] ?? []);
		setStep(newStep);
		reset();
	};

	const modalHeader: ModalContentComponentProps['modalTitle'] = {
		title: title,
	};

	// If the max steps is greater than 0, it means that it's a multistep form and the UI needs to update
	if (maxStep > 0) {
		modalHeader.secondaryItem = {
			type: SecondaryItemType.TEXT,
			copy: `Step ${step} of ${maxStep}`,
		};
	}

	// If it's a multi step form and on the second+ step add the back button
	if (maxStep > 0 && step > 1) {
		modalHeader.onBackClick = onPreviousPageClick;
	}

	const modalButtons: ModalContentComponentProps['modalButtons'] = {
		primaryAction: {
			label: primaryAction,
			disabled: formLoading || loading,
			loading: formLoading,
			onClick: onFormSubmit,
		},
	};

	return (
		<ModalContentComponent
			modalTitle={modalHeader}
			modalButtons={modalButtons}
			isLoading={loading}
			error={
				error
					? {
							title: 'An Error Occurred',
							copy: 'There was an issue loading this form, please try again later.',
							type: ErrorType.WARNING,
					  }
					: undefined
			}
		>
			<form ref={formRef}>
				<FormFieldsComponent control={control} fields={currentFields} />
			</form>
		</ModalContentComponent>
	);
};

export default FormModalLayout;
