import React, { useContext, useEffect, useState } from 'react';
import { useTitle } from 'react-use';

// Query
import { useListHousesWithYoungPeopleQuery } from './query.generated';

// Context / Hooks
import ModalContext from '../../contexts/ModalContext';
import { usePagination } from '../../hooks';

// Screen
import AdminListHousesScreen from './AdminListHousesScreen';

type AdminListHousesScreenContainerProps = {};

const AdminListHousesScreenContainer: React.FC<AdminListHousesScreenContainerProps> =
	(props) => {
		useTitle('STELLA | Houses');
		const [searchTerm, setSearchTerm] = useState<string>('');
		const { isOpen: isModalOpen } = useContext(ModalContext);

		const { skip, take, currentPage, handlePaginate, pageCount, setCount } =
			usePagination({ take: 12, count: 0 });

		const { data, loading, error, refetch } = useListHousesWithYoungPeopleQuery(
			{
				variables: {
					term: searchTerm,
					skip,
					take,
				},
			}
		);

		useEffect(() => {
			setCount(data?.countHouses ?? 0);
		}, [data, setCount]);

		// TODO: [STLL-310] Avoid using refetch once modal is closed when creating a house
		useEffect(() => {
			if (!isModalOpen && !loading) refetch();
		}, [isModalOpen]);

		return (
			<AdminListHousesScreen
				loading={loading}
				error={error}
				data={data}
				setSearchTerm={setSearchTerm}
				setCurrentPage={handlePaginate}
				pageCount={pageCount}
				currentPage={currentPage}
			/>
		);
	};

export default AdminListHousesScreenContainer;
