import React from 'react';
import { createInitialsFallback } from '../../utils';
import {
	Avatar,
	Box,
	createStyles,
	makeStyles,
	Paper,
	Theme,
	Typography,
	Badge,
	PaperProps,
	ThemeProvider,
} from '@material-ui/core';
import { getColoredTheme } from '../../styles/palettes';

export type CardWithAdornmentComponentProps = {
	label: string;
	subLabel: string;
	adornmentColor?: string;
	notificationCount?: number;
	onClick?: () => void;
	avatarAlt?: string;
	avatarSrc?: string;
	avatarIcon?: React.ReactElement;
	elevation?: PaperProps['elevation'];
	disableShadow?: boolean;
	disablePadding?: boolean;
	useSublabelForFallback?: boolean;
};

const useStyles = makeStyles<
	Theme,
	{
		disableShadow: boolean;
		disablePadding: boolean;
		isColored: boolean;
	}
>((theme) =>
	createStyles({
		root: {
			width: '100%',
			cursor: 'pointer',
			borderRadius: theme.spacing(1),
			padding: (props) => (props.disablePadding ? 0 : theme.spacing(2.5)),
			border: 'unset',
			display: 'flex',
			alignItems: 'center',
			boxShadow: (props) => (!props.disableShadow ? theme.shadows[2] : 'none'),
			minWidth: theme.spacing(30),
			justifyContent: 'flex-start',
			background: (props) =>
				props.isColored
					? `linear-gradient(90deg, ${
							theme.palette.primary.main
					  } ${theme.spacing(7.5)}, rgba(255,255,255,1) ${theme.spacing(7.5)})`
					: 'transparent',
			backgroundRepeat: 'no-repeat',
			'&:hover': {
				opacity: 0.75,
			},
		},
		avatar: {
			height: theme.spacing(10),
			width: theme.spacing(10),
			backgroundColor: (props) =>
				props.isColored ? theme.palette.primary.pale : theme.palette.grey[400],
			color: (props) =>
				props.isColored ? theme.palette.primary.dark : theme.palette.grey[50],
		},
		typographyContainer: {
			paddingLeft: theme.spacing('sm'),
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			gap: theme.spacing('xs3'),
		},
		badge: {
			'& > .MuiBadge-badge': {
				top: theme.spacing('xs2'),
				right: theme.spacing('xs2'),
				minWidth: theme.spacing('sm'),
				minHeight: theme.spacing('sm'),
				padding: 'unset',
				borderRadius: '50%',
				backgroundColor: theme.palette.info.main,
			},
		},
	})
);

const CardWithAdornmentComponent: React.FC<CardWithAdornmentComponentProps> = ({
	disableShadow = false,
	disablePadding = false,
	useSublabelForFallback = false,
	adornmentColor,
	...props
}) => {
	const styles = useStyles({
		disableShadow,
		disablePadding,
		isColored: Boolean(adornmentColor),
	});

	return (
		<Paper
			variant="outlined"
			elevation={props.elevation}
			className={styles.root}
			onClick={props.onClick}
		>
			<Badge
				className={styles.badge}
				color="primary"
				badgeContent={props.notificationCount}
				anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
			>
				<Avatar
					src={props.avatarSrc}
					alt={props.avatarAlt}
					className={styles.avatar}
				>
					{props.avatarIcon ??
						createInitialsFallback(
							useSublabelForFallback ? props.subLabel : props.label
						)}
				</Avatar>
			</Badge>
			<Box className={styles.typographyContainer}>
				<Typography variant={'h3'} color={'textPrimary'}>
					{props.label}
				</Typography>
				<Typography variant={'caption'} color={'textSecondary'}>
					{props.subLabel}
				</Typography>
			</Box>
		</Paper>
	);
};

const ThemedCardWithAdornment: React.FC<CardWithAdornmentComponentProps> = (
	props
) => {
	const youngPersonTheme = getColoredTheme(props.adornmentColor);

	return (
		<ThemeProvider theme={youngPersonTheme}>
			<CardWithAdornmentComponent {...props} />{' '}
		</ThemeProvider>
	);
};

export default ThemedCardWithAdornment;
