import HouseDetailsView from './HouseDetailsView';
import { useHouseLocationDetailsQuery } from './query.generated';

type HouseDetailsViewContainerProps = {
	houseId: string;
};

const HouseDetailsViewContainer: React.FC<HouseDetailsViewContainerProps> = (
	props
) => {
	const { loading, error, data } = useHouseLocationDetailsQuery({
		variables: {
			id: props.houseId,
		},
	});

	return <HouseDetailsView data={data} error={error} loading={loading} />;
};

export default HouseDetailsViewContainer;
