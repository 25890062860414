import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';

export type DetailBlockProps = {
	label: string;
};

const useStyles = makeStyles<Theme>((theme) =>
	createStyles({
		body: {
			color: theme.palette.primary.main,
		},
		label: {
			color: theme.palette.primary.light,
			opacity: 0.5,
		},
	})
);

export const DetailsBlockContainer: React.FC = (props) => {
	const theme = useTheme();

	return (
		<Box display="flex" flexDirection="column" gridGap={theme.spacing('sm')}>
			{props.children}
		</Box>
	);
};

const DetailsBlockComponent: React.FC<DetailBlockProps> = (props) => {
	const theme = useTheme();
	const styles = useStyles();
	return (
		<Box display="flex" flexDirection="column" gridGap={theme.spacing('xs3')}>
			<Typography variant="h3" component="p" className={styles.body}>
				{props.children}
			</Typography>
			<Box
				display="flex"
				flexDirection="column"
				gridGap={theme.spacing('sm')}
				className={styles.label}
			>
				{props.label}
			</Box>
		</Box>
	);
};

export default DetailsBlockComponent;
