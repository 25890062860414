import * as Types from '../../graphql/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};
export type ListYoungPeopleQueryVariables = Types.Exact<{
	where: Types.YoungPersonWhereFilterInput;
	sortBy?: Types.Maybe<Types.OrderByArg>;
	skip?: Types.Maybe<Types.Scalars['Int']>;
	take?: Types.Maybe<Types.Scalars['Int']>;
}>;

export type ListYoungPeopleQuery = {
	__typename?: 'Query';
	countYoungPeople?: number | null | undefined;
	listYoungPeople?:
		| Array<
				| {
						__typename?: 'YoungPerson';
						id: string;
						name: string;
						dateOfBirth: any;
						preferredName?: string | null | undefined;
						avatar?: string | null | undefined;
						house?:
							| {
									__typename?: 'House';
									title: string;
									color?: string | null | undefined;
							  }
							| null
							| undefined;
				  }
				| null
				| undefined
		  >
		| null
		| undefined;
};

export const ListYoungPeopleDocument = gql`
	query ListYoungPeople(
		$where: YoungPersonWhereFilterInput!
		$sortBy: OrderByArg = ASC
		$skip: Int = 0
		$take: Int = 20
	) {
		listYoungPeople(
			skip: $skip
			take: $take
			where: $where
			orderBy: { name: $sortBy }
		) {
			id
			name
			dateOfBirth
			preferredName
			avatar
			house {
				title
				color
			}
		}
		countYoungPeople(where: $where)
	}
`;

/**
 * __useListYoungPeopleQuery__
 *
 * To run a query within a React component, call `useListYoungPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useListYoungPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListYoungPeopleQuery({
 *   variables: {
 *      where: // value for 'where'
 *      sortBy: // value for 'sortBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListYoungPeopleQuery(
	baseOptions: Apollo.QueryHookOptions<
		ListYoungPeopleQuery,
		ListYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<ListYoungPeopleQuery, ListYoungPeopleQueryVariables>(
		ListYoungPeopleDocument,
		options
	);
}
export function useListYoungPeopleLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		ListYoungPeopleQuery,
		ListYoungPeopleQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<
		ListYoungPeopleQuery,
		ListYoungPeopleQueryVariables
	>(ListYoungPeopleDocument, options);
}
export type ListYoungPeopleQueryHookResult = ReturnType<
	typeof useListYoungPeopleQuery
>;
export type ListYoungPeopleLazyQueryHookResult = ReturnType<
	typeof useListYoungPeopleLazyQuery
>;
export type ListYoungPeopleQueryResult = Apollo.QueryResult<
	ListYoungPeopleQuery,
	ListYoungPeopleQueryVariables
>;
